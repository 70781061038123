import React, { useEffect, useState } from "react";
import Meta from "components/common/Meta";
import { useSelector } from "react-redux";
import { AppContentContainer } from "components/common/layouts/appLayout/AppContentContainer";
import UserDetail from "components/users/common/detail/UserDetail";
import { canAdminEdit } from "utils/authUtils";
import { PERMISSIONS } from "models/user/Permissions";
import { TUser } from "models/user/User";
import { authService } from "services/AuthService";
import { coreService } from "services/CoreService";
import { currentUserSelector } from "store/selectors/core.selectors";
import { permissionsSelector } from "store/selectors/auth.selectors";
import { useMutateUser } from "components/users/hooks/useMutateUserDetail";
import { USER_ROUTES } from "constants/routes/user";
import MiniLoader from "components/common/ui/loader/MiniLoader";

const ViewMyUserDetail = () => {
  const [isSubmitting, setSubmitting] = useState(false);
  const currentUserPermissions = useSelector(permissionsSelector);
  const user = useSelector(currentUserSelector);
  const updateUser = useMutateUser();

  const onUpdateAttributes = async (attributes: Partial<TUser>) => {
    setSubmitting(true);
    await authService.updateCurrentUserAttributes(attributes);
    setSubmitting(false);
    return await coreService.getCurrentUser();
  };
  const onUpdatePermission = async (permissions: PERMISSIONS[]) => {
    if (canAdminEdit(currentUserPermissions)) {
      updateUser.mutate(
        {
          newUser: { id: user.id, roles: permissions },
          oldUser: user,
        },
        { onSettled: () => coreService.getCurrentUser() }
      );
    }
  };

  useEffect(() => {
    setSubmitting(updateUser.isLoading);
  }, [updateUser.isLoading]);

  return (
    <AppContentContainer spacing="none" backgroundColor="light-grey">
      <Meta title="My details" />
      {user.id ? (
        <UserDetail
          user={user}
          canChangeUserAttributes={true}
          canChangePermissions={canAdminEdit(currentUserPermissions)}
          onUpdateAttributes={onUpdateAttributes}
          onUpdatePermission={onUpdatePermission}
          isSubmitting={isSubmitting}
          editLink={coreService.getRoute(USER_ROUTES.EDIT_MY_PROFILE)}
        />
      ) : (
        <MiniLoader theme="secondary" />
      )}
    </AppContentContainer>
  );
};

export default ViewMyUserDetail;
