import * as React from "react";
import throttle from "lodash.throttle";
import { useRef } from "react";

// To support SSR
const defaultWidth = typeof window !== "undefined" ? window.innerWidth : 1920;
const defaultHeight = typeof window !== "undefined" ? window.innerHeight : 1080;

function useWindowSize() {
  const [size, setSize] = React.useState({
    width: defaultWidth,
    height: defaultHeight,
  });
  const heightRef = useRef(0);
  const widthRef = useRef(0);

  React.useEffect(() => {
    const resizeHandler = throttle(function () {
      if (
        window.innerHeight !== heightRef.current ||
        window.innerWidth !== widthRef.current
      ) {
        heightRef.current = window.innerHeight;
        widthRef.current = window.innerWidth;
        setSize({ width: window.innerWidth, height: window.innerHeight });
      }
    }, 200);

    window.addEventListener("resize", resizeHandler);
    return () => window.removeEventListener("resize", resizeHandler);
  }, []);

  return size;
}

export { useWindowSize };
