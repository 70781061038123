import { createContext, useContext } from "react";
import { UseComboboxGetItemPropsOptions } from "downshift";
import { LayerProps } from "react-laag/dist/useLayer";
import { LayerSide } from "react-laag/dist/types";

interface IMenuContext<Item> {
  name: string;
  isOpen: boolean;
  isMultiselect: boolean;
  getItemProps: (options: UseComboboxGetItemPropsOptions<Item>) => any;
  highlightedIndex: number;
  selectedItem?: Item | null | Item[];
  menuProps: LayerProps;
  triggerBounds: ClientRect | null;
  layerSide: LayerSide;
  offset: number;
  query?: string;
  items: Item[];
}

export const MenuContext = createContext<IMenuContext<any>>({
  name: "",
  isOpen: false,
  isMultiselect: false,
  getItemProps: () => {},
  highlightedIndex: -1,
  selectedItem: null,
  menuProps: { style: {}, ref: () => {} },
  triggerBounds: null,
  layerSide: "center",
  offset: 8,
  items: [],
});

export const useMenuContext = () => {
  return useContext(MenuContext);
};
