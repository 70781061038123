import Gantt, { GanttRow, Month } from "components/common/ui/timeline/Gantt";
import { Activity } from "models/cpd/Activity";
import {
  calcMonthsSinceStart,
  getCategoryLabel,
  getOverdueStatus,
  isFutureActivity,
} from "components/cpd/utils/utils";
import CategoryIcon from "components/cpd/common/CategoryIcon";
import React from "react";
import { format, differenceInCalendarDays } from "date-fns";
import { ActivityEditState } from "components/cpd/activity/ActivityDetail";
import IconLock from "components/common/icons/IconLock";
import IconPaperclip from "components/common/icons/IconPaperclip";
import IconAsterisk from "components/common/icons/IconAsterisk";

interface Props extends DefaultProps {
  activities: Record<string, Activity>;
  groups: { id: string; label?: string; keys: string[] }[];
  startDate: Date;
  setEditting: (S: ActivityEditState | undefined) => void;
  locked?: boolean;
}

const ActivityGantt = ({
  activities,
  groups,
  startDate,
  setEditting,
  locked,
  ...rest
}: Props) => {
  const start = format(startDate, "MMM") as Month;
  const rows = groups.map(({ id, label, keys }) => {
    return {
      label: label || (id === "__undefined__" ? <i>Unassigned</i> : id),
      items: keys.map((k) => {
        const a = activities[k];
        const aStart = getStart(a);
        const isPlanned = !a.waive_req && !a.completed;
        return (
          a && {
            id: a.id,
            start: calcMonthsSinceStart(aStart, startDate),
            // end:
            //   i % 3 > 0
            //     ? calcMonthsSinceStart(
            //         a.clinical_start_date || a.completed_at || a.planned_at,
            //         startDate
            //       ) + 1
            //     : undefined,

            end: getEnd(a, startDate),
            // a.start_date || a.planned_started_at
            //   ? calcMonthsSinceStart(aEnd, startDate)
            //   : undefined,
            label: getCategoryLabel(a),
            badges: getBadges(a, locked),
            striped: a.waive_req,
            theme: a.waive_req
              ? "info"
              : a.is_required
              ? getOverdueStatus(a).fill
              : a.completed
              ? "success"
              : isFutureActivity(a)
              ? "warning"
              : ("grey" as ThemeVariants),
            outlineTheme: getOverdueStatus(a).level,
            fillVariant: isPlanned ? "lightFilled" : ("filled" as FillVariants),
            icon: a.category && <CategoryIcon type={a.category} />,
            onClick: () =>
              setEditting({
                activity: a,
                editing: false,
              }),
          }
        );
      }),
    };
  });

  return (
    <Gantt startMonth={start} {...rest}>
      {rows.map((r, i) => (
        <GanttRow {...r} key={i} />
      ))}
    </Gantt>
  );
};

const getStart = (activity: Activity) => {
  if (activity.started_at) {
    return activity.started_at;
  }
  if (activity.planned_started_at) {
    return activity.planned_started_at;
  }
  return activity.completed_at || activity.planned_at;
};

const getEnd = (activity: Activity, startDate: Date) => {
  const start = activity.started_at || activity.planned_started_at;
  const end =
    activity.completed_at || activity.planned_at || activity.required_by;
  if (start && end) {
    const checkDifferenceStartEndDate = differenceInCalendarDays(end, start);
    // const checkDifferenceStartEndDate = (end?.getTime()||0) - (start?.getTime()||0);

    const checkSameMonth = end?.getMonth() === start?.getMonth();

    //   if (checkDifferenceStartEndDate <(30 * 60 * 60 *24) && checkSameMonth) {
    //     return undefined;
    // }

    //   console.log(test, checkSameMonth, activity)
    if (checkDifferenceStartEndDate < 16 && checkSameMonth) {
      return undefined;
    }
    // const diff = calcMonthsSinceStart(start, end);
    return calcMonthsSinceStart(end, startDate);
  }
  return undefined;
};

const getBadges = (activity: Activity, locked?: boolean) => {
  const badges = [];
  if (activity.locked || locked) {
    badges.push({
      icon: <IconLock />,
      theme: "danger" as ThemeVariants,
    });
  }
  if (activity.is_required) {
    badges.push({
      icon: <IconAsterisk />,
      theme: getOverdueStatus(activity).level,
    });
  }
  if (activity.hasAttachments) {
    badges.push({
      icon: <IconPaperclip />,
      theme: "secondary" as ThemeVariants,
    });
  }

  return badges.length > 0 ? badges : undefined;
};

export default ActivityGantt;
