import * as Yup from "yup";
import {
  clinicalUsersConditional,
  potentialSupervisorConditional,
  nonGeneralUsersConditional,
  UserAttribute,
  userTypeKey,
  userTypeOptions,
} from "models/user/attributes/common";
import { CombinedUserTypes } from "models/user/attributes/userType";
import { facilityTypeOptions } from "models/facility/attributes";
import { getDefaultValues, getValidations } from "utils/formUtils";

export const APPROVED_TO_SUPERVISE = [
  { value: "yes", label: "Yes" },
  { value: "no", label: "No" },
];
export const core: UserAttribute[] = [
  // {
  //   name: "id",
  //   type: "uuid",
  //   required: true,
  //   readOnly: true,
  // },
  {
    name: "email",
    type: "email",
    label: "Preferred Email",
    required: true,
    validation: Yup.string()
      .required("Please enter an email address")
      .email("Please enter a valid email address"),
  },
  {
    name: "given_name",
    type: "string",
    label: "First Name",
    required: true,
    validation: Yup.string().required("Please enter a first name"),
  },
  {
    name: "family_name",
    type: "string",
    label: "Last Name",
    required: true,
    validation: Yup.string().required("Please enter a last name"),
  },
  {
    name: userTypeKey,
    type: "enum",
    label: "User Type",
    required: true,
    adminOnly: true,
    defaultValue: "general",
    options: userTypeOptions,
    validation: Yup.string().required("Please select a user type"),
  },
  {
    name: "mcnz",
    type: "string",
    label: "MCNZ or User ID",
    required: true,
    adminOnly: true,
    conditional: nonGeneralUsersConditional,
    validation: Yup.string().when(userTypeKey, {
      is: (field: any) => CombinedUserTypes.nonGeneral.includes(field),
      then: Yup.string().required("Please enter a MCNZ or User ID"),
    }),
  },
  {
    name: "phone",
    label: "Mobile Number",
    type: "phone",
  },
  {
    name: "preferred_username",
    label: "User handle",
    type: "string",
  },
  {
    name: "nickname",
    label: "Preferred Name",
    type: "string",
  },
  {
    name: "approved_to_supervise",
    adminOnly: true,
    label: "Are you approved to be a supervisor for RNZCUC?",
    type: "enum",
    options: APPROVED_TO_SUPERVISE,
    defaultValue: "no",
    conditional: potentialSupervisorConditional,
    // conditional: confirmedClinicalConditional,
    tracked: true,
  },
  {
    name: "supervisor_approval_date",
    adminOnly: true,
    label: "When was your supervision status approved?",
    type: "date",
    conditional: [
      ...potentialSupervisorConditional,
      {
        field: "approved_to_supervise",
        values: ["yes"],
      },
    ],
  },
  {
    name: "facilities",
    label: "Urgent Care Workplace(s)",
    description: "Search for a facility",
    type: "facility",
    tracked: true,
    conditional: clinicalUsersConditional,
    options: [
      { value: "facility_name", label: "Facility", type: "string" },
      {
        value: "facility_type",
        label: "Type",
        type: "enum",
        options: facilityTypeOptions,
      },
      // { value: "fte", label: "Approximate FTE", type: "string" },
    ],
    layout: {
      display: "fieldArray",
    },
  },
];

export const defaultValuesCore = getDefaultValues(core);
export const userCoreValidations = getValidations(core);
