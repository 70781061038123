import React from "react";
import { TUser } from "models/user/User";
import { PERMISSIONS } from "models/user/Permissions";
import UserEditForm from "components/users/common/UserEditForm";
import styles from "components/users/common/User.module.scss";

interface Props {
  user: TUser;
  isAdmin: boolean;
  onUpdateAttributes: (attributes: Partial<TUser>) => void;
  onUpdatePermission: (permissions: PERMISSIONS[]) => void;
  onCancel: (attributes: Partial<TUser>) => void;
  isSubmitting?: boolean;
}

const UserEdit = ({
  user,
  isAdmin = false,
  onUpdateAttributes,
  onCancel,
  isSubmitting,
}: Props) => {
  const onDetailUpdate = async (att: any) => {
    onUpdateAttributes(att);
  };

  return (
    <div className={styles.root}>
      <UserEditForm
        user={user}
        onSubmit={onDetailUpdate}
        onCancel={onCancel}
        isSubmitting={isSubmitting}
        isAdmin={isAdmin}
        saveText={!isAdmin ? "Update" : "Update User"}
      />
    </div>
  );
};

export default UserEdit;
