import { TBucket } from "models/Dimension";
import { IUserListDimensions, TUser } from "models/user/User";
import { UserPage, userPages } from "models/user/UserForm";
import { checkConditionals } from "utils/formUtils";
import { Facility } from "models/facility/Facility";

export const extractFiltersFromDimensions = (dim: IUserListDimensions) => {
  return Object.values(dim).reduce(
    (o, d) => (d.buckets ? { ...o, [d.key]: d.buckets } : o),
    {}
  ) as Record<string, TBucket>;
};

export interface PageTab extends UserPage {
  index: number;
}
export const getTabs = (user: TUser) => {
  const tabs = [] as PageTab[];
  let index = 0;
  userPages.forEach(({ conditional, ...p }) => {
    if (checkConditionals(user, conditional)) {
      tabs.push({ index, ...p });
      index++;
    }
  });

  return tabs;
};

export const diffFacilities = (
  newFacilities: (Facility | undefined)[] = [],
  oldFacilities: Facility[] = []
) => {
  // console.log(newFacilities, oldFacilities);
  const toAdd = newFacilities.filter(
    (n) => !oldFacilities.some((o) => o.facility_id === n?.facility_id)
  );
  const toRemove = oldFacilities.filter(
    (o) => !newFacilities.some((n) => n?.facility_id === o.facility_id)
  );
  return {
    toAdd,
    toRemove,
  };
};
