import React from "react";
import cx from "classnames";
import { HookFormProperties, Section as ISection } from "models/common/Form";
import { DataAttribute } from "components/common/ui/form-elements/formElements";
import Question from "components/common/ui/form-elements/form-layout/FormQuestion";
import ConditionalLogicWrapper from "components/common/ui/form-elements/hook-form-elements/ConditionalLogicWrapperHookForm";
import styles from "components/common/ui/form-elements/form-layout/SubSection.module.scss";

interface Props<Def = DataAttribute> extends ISection, DefaultProps {
  isAdmin?: boolean;
  questionMap: Record<string, Def>;
  hookForm: HookFormProperties;

  key: string | number;
}

const FormSubSection = (props: Props) => createSubSection(props);

// We use a constructor function here so that we can filter out null values in the parent Page
export const createSubSection = ({
  title,
  description,
  questions,
  questionMap,
  isAdmin,
  hookForm,
  conditional,
  className,
  ...rest
}: Props) => {
  const qElements = questions.map((k) => {
    const q = questionMap[k];
    return (
      q &&
      !(q.adminOnly && !isAdmin) && (
        <ConditionalLogicWrapper
          key={k}
          control={hookForm.control}
          conditional={q.conditional}
          className={
            q?.layout?.width === "half" ? styles.halfWidth : styles.fullWidth
          }
        >
          <Question
            question={q}
            {...hookForm}
            disabled={q.adminOnly && !isAdmin}
          />
        </ConditionalLogicWrapper>
      )
    );
  });

  return qElements.filter(Boolean).length > 0 ? ( // if no questions in subSection then don't draw it
    <ConditionalLogicWrapper
      control={hookForm.control}
      conditional={conditional}
      className={cx(styles.root, className)}
      {...rest}
    >
      <h4 className={styles.title}>{title}</h4>
      {description && <p>{description}</p>}
      <div className={styles.questions}>{qElements}</div>
    </ConditionalLogicWrapper>
  ) : null;
};

export default FormSubSection;
