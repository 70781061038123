import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import { peerGroupQueryKeys } from "queries/constants";
import { MutationOptions } from "queries/queries";
import { useToast } from "components/common/ui/toast/useToast";
import { PeerGroupMeeting } from "models/peergroup/meeting";
import { savePeerGroupMeeting } from "api/PeerGroupApi";

export const useMutatePeerGroupMeeting = (options: MutationOptions = {}) => {
  const queryClient = useQueryClient();
  const { addErrorToast, addToast } = useToast();

  return useMutation(
    ({
      newPeerGroupMeeting,
      oldPeerGroupMeeting,
      peergroupId,
      meetingId,
    }: useMutatePeerGroupMeetingProps) =>
      savePeerGroupMeeting(
        newPeerGroupMeeting,
        oldPeerGroupMeeting,
        peergroupId,
        meetingId
      ),
    {
      onSuccess: (result, inputs) => {
        addToast(
          !inputs.oldPeerGroupMeeting.id
            ? "Peer group was successfully updated!"
            : "Peer group was successfully created!",
          {
            theme: "success",
          }
        );

        queryClient.invalidateQueries(
          peerGroupQueryKeys.meetings(inputs.peergroupId)
        );
        // queryClient.invalidateQueries(
        //     [...peerGroupQueryKeys.meetings(inputs.peergroupId), inputs.oldPeerGroupMeeting.id]
        // );
      },
      onError: (e, inputs) => {
        if (axios.isAxiosError(e) && e.response?.status === 500) {
          addToast(`Error: Unable to create peer group meeting `, {
            theme: "warning",
            delay: 6000,
          });
        } else {
          addErrorToast(e, "Error: Unable to save peer group meeting details");
        }
      },
      ...options,
    }
  );
};

interface useMutatePeerGroupMeetingProps {
  newPeerGroupMeeting: Partial<PeerGroupMeeting>;
  oldPeerGroupMeeting: PeerGroupMeeting;
  peergroupId: string;
  meetingId?: string;
}
