import React, { useEffect } from "react";
import Meta from "components/common/Meta";
import { useHistory, useParams } from "react-router";
import { AppContentContainer } from "components/common/layouts/appLayout/AppContentContainer";
import Container from "components/common/layouts/Container";
import { coreService } from "services/CoreService";
import MiniLoader from "components/common/ui/loader/MiniLoader";
import {
  createPeerGroup as blankPeerGroup,
  PeerGroup,
} from "models/peergroup/PeerGroup";
import { useMutatePeerGroup } from "components/peergroup/hooks/useMutatePeerGroup";
import { usePeerGroupDetail } from "components/peergroup/hooks/usePeerGroupDetails";
import { PEERGROUP_ROUTES } from "constants/routes/peergroup";
import PeerGroupEditForm from "components/peergroup/edit/PeerGroupEditForm";

const ViewAdminEditPeerGroup = () => {
  const { peergroupId } = useParams<{ peergroupId: string; orgId: string }>();
  const history = useHistory();
  const { isLoading: loadingDetails, data: peergroup } =
    usePeerGroupDetail(peergroupId);

  const {
    mutate,
    isLoading: isSubmitting,
    isSuccess,
    data,
  } = useMutatePeerGroup();

  useEffect(() => {
    if (isSuccess && data && data.id) {
      history.push(
        coreService.getRoute(PEERGROUP_ROUTES.PEERGROUP_DETAIL, {
          peergroupId: data.id,
        })
      );
    }
  }, [isSuccess, data, history]);

  const onSave = async (newPeerGroup: Partial<PeerGroup>) => {
    await mutate({
      newPeerGroup,
      oldPeerGroup: blankPeerGroup(),
    });
  };

  const onCancel = () => {
    history.push(
      coreService.getRoute(PEERGROUP_ROUTES.PEERGROUP_DETAIL, {
        peergroupId: peergroupId,
      })
    );
  };

  return (
    <AppContentContainer
      spacing="none"
      backButton={{
        to: coreService.getRoute(PEERGROUP_ROUTES.PEERGROUP_DETAIL, {
          peergroupId: peergroupId,
        }),
        label: "Back to Peer Group Detail",
      }}
    >
      <Meta title={`Edit - ${peergroup?.peergroup_name}`} />
      <br />
      <br />
      <br />
      <Container>
        <h1 style={{ marginBottom: 0 }}>Edit Peer Group</h1>
        {loadingDetails || !peergroup ? (
          <MiniLoader theme="secondary" />
        ) : (
          <PeerGroupEditForm
            onSubmit={onSave}
            peergroup={peergroup}
            onCancel={onCancel}
            isSubmitting={isSubmitting}
          />
        )}
      </Container>
    </AppContentContainer>
  );
};

export default ViewAdminEditPeerGroup;
