import { RouterState } from "connected-react-router";
import { create as createUser, userListDimensions } from "models/user/User";
import { IToastStateElement } from "components/common/ui/toast/Toast";
import { PERMISSIONS } from "models/user/Permissions";
import { facilityListDimensions } from "models/facility/Facility";
import { planListDimensions } from "models/cpd/Plan";
import { peergroupDimensions } from "models/peergroup/PeerGroup";

export const initialState = {
  auth: {
    isLoading: false,
    loggedIn: false,
    error: null as AuthError,
    id: "" as string,
    permissions: [] as PERMISSIONS[],
  },
  core: {
    isAppLoaded: false as boolean,
    user: createUser({}),
    error: null as null | Error,
  },
  layout: {
    isLoading: true,
    isExpanded: false,
    isMobileOpened: false,
    isDesktop: false,
    toasts: [] as IToastStateElement[],
  },
  dimensions: {
    isLoading: false,
    users: userListDimensions,
    facilities: facilityListDimensions,
    plans: planListDimensions,
    peergroups: peergroupDimensions,
  },
};

export type IAppState = typeof initialState;
export interface IState {
  app: IAppState;
  internal: TObjectAny;
  router: RouterState;
}
