import React from "react";
import Meta from "components/common/Meta";
import AppContentContainer from "components/common/layouts/appLayout/AppContentContainer";
import Container from "components/common/layouts/Container";
import { useFacilityList } from "components/facilities/hooks/useFacilityList";
import { useSelector } from "react-redux";
import {
  facilityListDimSelector,
  isDimensionsLoadingSelector,
} from "store/selectors/dimensions.selectors";
import { isAppLoadedSelector } from "store/selectors/core.selectors";
import Table from "components/common/ui/table/Table";
import styles from "./viewFacilities.module.scss";
import { coreService } from "services/CoreService";
import { FACILITY_ROUTES } from "constants/routes/facility";
import { Facility } from "models/facility/Facility";
import { IconEye } from "components/common/icons/twoToneIcons";
import { IColumn } from "components/common/ui/table/utils";
import Button from "components/common/ui/button/Button";
import { verifyPermisisons } from "utils/authUtils";
import { permissionsSelector } from "store/selectors/auth.selectors";
import { FacilityType } from "components/facilities/common/FacilityType";
import MiniLoader from "components/common/ui/loader/MiniLoader";

const ViewFacilities = () => {
  const isAppLoaded = useSelector(isAppLoadedSelector);
  const isDimLoading = useSelector(isDimensionsLoadingSelector);
  const dimensions = useSelector(facilityListDimSelector);
  const enabled = isAppLoaded && !isDimLoading;
  const permissions = useSelector(permissionsSelector);
  const canEdit = verifyPermisisons(
    permissions,
    FACILITY_ROUTES.ADMIN_CREATE_FACILITY.requiredRole
  );

  const { isLoading, data: facilities } = useFacilityList(dimensions, {
    enabled,
  });

  const columns: IColumn<Facility>[] = [
    // {
    //   id: "icon",
    //   label: " ",
    //   width: "50px",
    //   type: "icon",
    //   className: styles.iconCol,
    //
    // },
    {
      id: "facility_name",
      label: "Name",
      cell: (c, row) => `${row.facility_name} `,
      className: styles.clientColumn,
    },
    {
      label: "Type",
      id: "facility_type",
      cell: (c, row) => <FacilityType facilityType={row.facility_type} />,
    },
    {
      id: "facility_status",
      label: "Status",
      cell: (c, row) => `${row.facility_status} `,
      className: styles.statusContainer,
    },
    {
      id: "services_provided",
      label: "Service",
      cell: (c, row) => `${row.services_provided} `,
      className: styles.statusContainer,
    },
    {
      id: "link",
      label: " ",
      width: "50px",
      type: "icon",
      className: styles.iconCol,
      cell: () => <IconEye />,
    },
  ];

  //    {/*Test*/}
  //   const showClick = () => {
  //     console.log(facilities);
  // }

  return (
    <AppContentContainer spacing="none">
      <Meta title={`Facilities`} />

      <Container spacing={"extra"} spacingTopOnly>
        <div className={styles.header}>
          <h1>Facilities</h1>
          {/*          <ListFilters*/}
          {/*  filters={USER_LIST_FILTER_KEYS}*/}
          {/*  dimensions={dimensions}*/}
          {/*  setActive={setActive}*/}
          {/*  setManyActive={setManyActive}*/}
          {/*  isLoading={!isAppLoaded}*/}
          {/*  isUpdating={isDimLoading}*/}
          {/*/>*/}

          {canEdit && (
            <Button
              rounded
              theme="primary"
              to={coreService.getRoute(FACILITY_ROUTES.ADMIN_CREATE_FACILITY)}
            >
              Create New Facility
            </Button>
          )}
        </div>
      </Container>
      {isLoading ? (
        <MiniLoader theme="secondary" />
      ) : (
        <Table
          columns={columns}
          data={facilities}
          getRowProps={(row) => ({
            to: coreService.getRoute(FACILITY_ROUTES.ADMIN_FACILITY_DETAIL, {
              facilityId: row.facility_id,
            }),
          })}
          loading={isLoading}
          // loading={true}
          placeholder={{ noDataLabel: "No Facilities Found", loadingRows: 5 }}
        />
      )}
    </AppContentContainer>
  );
};

export default ViewFacilities;
