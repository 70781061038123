import React from "react";
import createIcon from "components/common/icons/common/createIcon";

const IconMedBook = createIcon(
  <React.Fragment>
    <path d="M16.9973 3.99947H4V2H16.9973C18.6453 2 20 3.34897 20 5.00187V18.9981C20 21.0136 18.9813 22 16.9973 22H4V6.00427H16.9973C17.5467 6.00427 18 5.55639 18 5.00187C18 4.44735 17.552 3.99947 16.9973 3.99947ZM14.9973 12.9997H12.9973V11.0003H10.9973V12.9997H8.99733V14.9992H10.9973V16.9987H12.9973V14.9992H14.9973V12.9997Z" />
  </React.Fragment>,
  "MedBook"
);

export default IconMedBook;
