import React from "react";
import cx from "classnames";
import { TButton } from "components/common/ui/button/Button";
import LinkWrapper from "components/common/ui/link-wrapper/LinkWrapper";
import styles from "./HeroButton.module.scss";

interface Props extends TButton {
  /** Should a coloured dot appear to the left of the text */
  dot?: boolean;

  /** The direction of the underlying color gradient  */
  gradientDirection?: "top" | "bottom" | "left" | "right";
}

const HeroButton = (props: Props) => (
  <LinkWrapper to={props.to} href={props.href} target={props.target}>
    <HeroButtonBase {...props} />
  </LinkWrapper>
);

const HeroButtonBase = ({
  dot = false,
  altBg,
  className,
  theme = "primary",
  variant = "filled",
  onClick,
  disabled,
  type,
  rounded = true,
  gradientDirection = "bottom",
  children,
}: Props) => (
  <button
    className={cx(
      className,
      styles.button,
      styles.wrap,
      theme && styles[theme],
      variant && styles[variant],
      {
        [styles["alt-bg"]]: altBg,
        [styles.rounded]: rounded,
      }
    )}
    onClick={onClick ? (e) => onClick(e) : () => {}}
    disabled={disabled}
    type={type}
  >
    <div
      className={cx(
        styles.border,
        gradientDirection && styles[gradientDirection]
      )}
    />
    <div
      className={cx(
        styles.chip,
        theme && styles[theme],
        variant && styles[variant],
        {
          [styles["alt-bg"]]: altBg,
          [styles.dotButton]: dot,
        }
      )}
      style={{ background: altBg }}
    >
      {dot && <div className={styles.dot} />}
      {children}
    </div>
  </button>
);

export default HeroButton;
