import React from "react";
import Chip from "components/common/ui/chip/Chip";
import { Activity } from "models/cpd/Activity";
import { activityAttributes } from "components/cpd/utils/activityForm";
import { Category, gradeResultOptions } from "models/cpd/common/common";
import FormattedQuestion from "components/common/ui/form-elements/display-elements/FormattedQuestion";
import HintBox from "components/common/ui/hint-box/HintBox";
import {
  IconDanger,
  IconError,
  IconSuccess,
} from "components/common/icons/twoToneIcons";
import styles from "./AuditResults.module.scss";

interface Props {
  activity: Activity;
  isAdmin?: boolean;
}

const GradeResults = ({ activity, isAdmin }: Props) => {
  const grade = gradeResultOptions.find(
    (g) =>
      g.value ===
      (activity.admin_grade ??
        activity.grade_result ??
        activity.admin_grade_result)
  );
  const score =
    activity.letter_grade ||
    activity.admin_letter_grade ||
    formatGradeScore(activity, isAdmin);
  return grade ? (
    <HintBox
      theme={grade.theme}
      className={styles.root}
      icon={getIcon(grade.theme)}
    >
      <h4>Grade Results</h4>
      <Chip theme={grade.theme} variant="filled">
        {score && (
          <>
            <b>{score}</b>
            <span className={styles.spacer}>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
          </>
        )}
        {grade.label}
      </Chip>
      {activity.admin_comments && (
        <FormattedQuestion
          def={{
            ...activityAttributes.adminComments,
            label: activityAttributes.adminComments.label || "",
          }}
          questions={activity}
        />
      )}
    </HintBox>
  ) : (
    <></>
  );
};

const formatGradeScore = (activity: Activity, isAdmin?: boolean) =>
  !isAdmin && activity.category === Category.tp_ucpex
    ? undefined
    : activity.admin_grade_score
    ? activity.category === Category.tp_case_studies
      ? activity.admin_grade_score / 10
      : `${activity.admin_grade_score}%`
    : undefined;

const getIcon = (theme?: ThemeVariants) => {
  switch (theme) {
    case "success":
      return <IconSuccess />;
    case "warning":
      return <IconError />;
    case "danger":
      return <IconDanger />;
    default:
      return undefined;
  }
};

export default GradeResults;
