import {
  Category,
  PATHWAY,
  subCategoryOptions,
  tpCaseStudiesSubcategory,
  tpCoreSkillsSubcategory,
  tpUcCourseSubcategory,
} from "models/cpd/common/common";
import {
  DataAttribute,
  FieldOption,
} from "components/common/ui/form-elements/formElements";

export { PATHWAY } from "models/cpd/common/common";

export enum RequirementType {
  any = "any",
  exact = "exact",
  multi = "multi",
  distinct = "distinct",
}
type TBaseReq = {
  credits: number;
  label?: string;

  progress?: number;
  year_progress?: number[];

  planned_progress?: number;
  planned_year_progress?: number[];

  waived_progress?: number;
  waived_year_progress?: number[];
};
type TAnyRequirement = TBaseReq & {
  type: RequirementType.any;
};
type TExactRequirement = TBaseReq & {
  type: RequirementType.exact;
  option: string;
};
type TMultiRequirement = TBaseReq & {
  type: RequirementType.multi;
  options: string[];
};
type TDistinctRequirement = TBaseReq & {
  type: RequirementType.distinct;
  options: string[];
};

interface requirementOption extends FieldOption<RequirementType> {}

export const requirementTypeOptions: requirementOption[] = [
  { value: RequirementType.any, label: "Any activity in selected category" },
  { value: RequirementType.exact, label: "Specific sub-type of activity" },
  { value: RequirementType.multi, label: "Any sub-type from list" },
  {
    value: RequirementType.distinct,
    label: "Unique/distinct sub-type from list",
  },
];

export type TRequirement =
  | TAnyRequirement
  | TExactRequirement
  | TMultiRequirement
  | TDistinctRequirement;
// export type TRequirement = {
//   type: RequirementType;
//   credits: number;
//   label?:string;
//   options?: string[];
// };

export type TProgressItem = TRequirement & {
  category: Category;
  label?: string;
  unitType?: "hourly" | "event";
  url?: string;
};

export type PathwayCategoryRequirements = {
  triennial?: TRequirement[];
  annual?: TRequirement[];
};

export type PathwayRequirements = {
  [K in Category]?: PathwayCategoryRequirements;
};

const urgentCarePathwayRequirements: PathwayRequirements = {
  [Category.hours]: {
    triennial: [
      {
        type: RequirementType.any,
        credits: 600,
      },
    ],
  },
  [Category.edu]: {
    annual: [
      {
        type: RequirementType.any,
        credits: 5,
      },
    ],
  },
  [Category.peer]: {
    annual: [
      {
        label: "Peer Group Activites",
        type: RequirementType.any,
        credits: 6,
      },
    ],
  },
  [Category.resus]: {
    annual: [
      {
        type: RequirementType.any,
        credits: 1,
      },
    ],
    triennial: [
      {
        type: RequirementType.exact,
        option: "full",
        credits: 1,
      },
    ],
  },
  [Category.audit]: {
    annual: [
      {
        type: RequirementType.any,
        credits: 1,
      },
    ],
    triennial: [
      {
        type: RequirementType.exact,
        option: "patient",
        credits: 1,
      },
      {
        type: RequirementType.exact,
        option: "clinical",
        credits: 1,
      },
      {
        type: RequirementType.multi,
        label: "Approved Audit",
        options: [
          "tikanga",
          "ref_data",
          "ref_patient",
          "fracture",
          "suture",
          "ces",
          "vital_signs",
          "other",
        ],
        credits: 1,
      },
    ],
  },
  [Category.quiz]: {
    annual: [
      {
        type: RequirementType.any,
        credits: 1,
      },
    ],
  },
  [Category.sac]: {
    annual: [
      {
        type: RequirementType.any,
        credits: 1,
      },
    ],
  },
  [Category.pdp]: {
    annual: [
      {
        type: RequirementType.any,
        credits: 1,
      },
    ],
  },
  [Category.additional]: {
    triennial: [{ type: RequirementType.any, credits: 60 }],
  },
};

const annualTpsRequirements: Partial<Record<Category, TRequirement[]>> = {
  [Category.hours]: [
    {
      type: RequirementType.any,
      credits: 400,
    },
  ],
  [Category.tp_supervision]: [
    {
      type: RequirementType.exact,
      option: "supervision_meeting",
      label: "Quarterly Meetings",
      credits: 4,
    },
  ],
  [Category.peer]: [
    {
      label: "Peer Group Activites",
      type: RequirementType.any,
      credits: 6,
    },
  ],
  [Category.resus]: [
    {
      type: RequirementType.any,
      credits: 1,
    },
  ],

  [Category.audit]: [
    {
      type: RequirementType.any,
      credits: 1,
    },
  ],
  [Category.quiz]: [
    {
      type: RequirementType.any,
      credits: 1,
    },
  ],

  [Category.pdp]: [
    {
      type: RequirementType.any,
      credits: 1,
    },
  ],
};

const triennialTpsRequirements: Partial<Record<Category, TRequirement[]>> = {
  [Category.hours]: [
    {
      type: RequirementType.any,
      credits: 3000,
    },
  ],
  [Category.resus]: [
    {
      type: RequirementType.exact,
      option: "full",
      credits: 2,
    },
  ],
  [Category.tp_ucpex]: [
    {
      type: RequirementType.exact,
      option: "upex_written",
      credits: 1,
    },
    {
      type: RequirementType.exact,
      option: "upex_osce",
      credits: 1,
    },
  ],

  [Category.tp_practical]: [
    {
      type: RequirementType.exact,
      option: "pals_practical",
      credits: 1,
    },
    {
      type: RequirementType.exact,
      option: "trauma_practical",
      credits: 1,
    },
    {
      type: RequirementType.exact,
      option: "comms_practical",
      credits: 1,
    },
  ],
  [Category.tp_uc_course]: [
    {
      type: RequirementType.any,
      credits: 1,
    },
  ],

  [Category.tp_core_skills]: [
    {
      type: RequirementType.distinct,
      options: tpCoreSkillsSubcategory.map((d) => d.value),
      credits: tpCoreSkillsSubcategory.length,
    },
  ],

  [Category.tp_case_studies]: [
    {
      type: RequirementType.distinct,
      options: tpCaseStudiesSubcategory.map((d) => d.value),
      credits: tpCaseStudiesSubcategory.length,
    },
  ],
  [Category.tp_uc_course]: [
    {
      type: RequirementType.distinct,
      options: tpUcCourseSubcategory.map((d) => d.value),
      credits: tpUcCourseSubcategory.length,
    },
  ],

  [Category.tp_uni_papers]: [
    {
      type: RequirementType.exact,
      option: "paediatrics_paper",
      credits: 1,
    },
    {
      type: RequirementType.exact,
      option: "epidemiology_paper",
      credits: 1,
    },
    {
      type: RequirementType.exact,
      option: "other",
      credits: 1,
    },
  ],

  [Category.tp_med_lit]: [
    {
      type: RequirementType.any,
      credits: 1,
    },
  ],

  [Category.audit]: [
    {
      type: RequirementType.exact,
      option: "patient",
      credits: 1,
    },
    {
      type: RequirementType.exact,
      option: "clinical",
      credits: 2,
    },
    {
      type: RequirementType.multi,
      label: "College-approved audit",
      options: [
        "tikanga",
        "ref_data",
        "ref_patient",
        "fracture",
        "suture",
        "ces",
        "vital_signs",
        "other",
      ],
      credits: 1,
    },
  ],
};

const trainingPathwayRequirements: PathwayRequirements = Array.from(
  new Set([
    ...(Object.keys(triennialTpsRequirements) as Category[]),
    ...(Object.keys(annualTpsRequirements) as Category[]),
  ])
).reduce(
  (p, k) => ({
    ...p,
    [k]: {
      annual: annualTpsRequirements[k],
      triennial: triennialTpsRequirements[k],
    },
  }),
  {}
);

const itapPathwayRequirements: PathwayRequirements = (
  Object.keys(annualTpsRequirements) as Category[]
).reduce(
  (p, k) => ({
    ...p,
    [k]: {
      annual: annualTpsRequirements[k],
    },
  }),
  {}
);

const alternativeClinicalPathwayRequirements: PathwayRequirements = {
  [Category.edu]: {
    annual: [
      {
        type: RequirementType.any,
        credits: 9,
      },
    ],
  },
  [Category.peer]: {
    annual: [
      {
        label: "Peer Group Activites",
        type: RequirementType.any,
        credits: 6,
      },
    ],
  },
  [Category.audit]: {
    annual: [
      {
        type: RequirementType.any,
        credits: 1,
      },
    ],
    triennial: [
      {
        type: RequirementType.exact,
        option: "patient",
        credits: 1,
      },
      {
        type: RequirementType.exact,
        option: "clinical",
        credits: 1,
      },
      {
        type: RequirementType.multi,
        label: "Approved Audit",
        options: [
          "tikanga",
          "ref_data",
          "ref_patient",
          "fracture",
          "suture",
          "ces",
          "vital_signs",
          "other",
        ],
        credits: 1,
      },
    ],
  },
  [Category.quiz]: {
    annual: [
      {
        type: RequirementType.any,
        credits: 1,
      },
    ],
  },
  [Category.sac]: {
    annual: [
      {
        type: RequirementType.any,
        credits: 1,
      },
    ],
  },
  [Category.pdp]: {
    annual: [
      {
        type: RequirementType.any,
        credits: 1,
      },
    ],
  },
  // [Category.additional]: {
  //   triennial: [{ type: RequirementType.any, credits: 60 }],
  // },
};

const nonClinicalPathwayRequirements: PathwayRequirements = {
  [Category.edu]: {
    annual: [
      {
        type: RequirementType.any,
        credits: 9,
      },
    ],
  },
  [Category.peer]: {
    annual: [
      {
        label: "Peer Group Activites",
        type: RequirementType.any,
        credits: 6,
      },
    ],
  },
  [Category.audit]: {
    annual: [
      {
        type: RequirementType.any,
        credits: 1,
      },
    ],
  },
  [Category.quiz]: {
    annual: [
      {
        type: RequirementType.any,
        credits: 1,
      },
    ],
  },
  [Category.sac]: {
    annual: [
      {
        type: RequirementType.any,
        credits: 1,
      },
    ],
  },
  [Category.pdp]: {
    annual: [
      {
        type: RequirementType.any,
        credits: 1,
      },
    ],
  },
};

const specialCircumstancesPathwayRequirements: PathwayRequirements = {};

export const pathwayDefaultRequirements = {
  [PATHWAY.urgent_care]: urgentCarePathwayRequirements,
  [PATHWAY.alternative_clinical]: alternativeClinicalPathwayRequirements,
  [PATHWAY.non_clinical]: nonClinicalPathwayRequirements,
  [PATHWAY.special_circumstance]: specialCircumstancesPathwayRequirements,
  [PATHWAY.training]: trainingPathwayRequirements,
  [PATHWAY.itap]: itapPathwayRequirements,
};

interface RequirementAttribute extends DataAttribute {}

export const generateRequirementOptions = (
  category: Category
): FieldOption<"type" | "option" | "options" | "credits">[] => [
  {
    value: "type",
    type: "enum",
    label: "Requirement Type",
    options: subCategoryOptions[category]
      ? requirementTypeOptions
      : [requirementTypeOptions[0]],
    defaultValue: RequirementType.any,
  },
  {
    value: "option",
    label: "Activity Sub-Type",
    type: "enum",
    conditional: [
      {
        field: "type",
        values: [RequirementType.exact],
      },
    ],
    options: subCategoryOptions[category] || [],
  },
  {
    value: "options",
    label: "Activity Sub-Types",
    type: "enum[]",
    conditional: [
      {
        field: "type",
        values: [RequirementType.multi, RequirementType.distinct],
      },
    ],
    options: subCategoryOptions[category] || [],
    layout: {
      type: "select",
    },
  },
  {
    value: "credits",
    type: "number",
    defaultValue: "1",
    label: "Required points",
  },
];

export const requirementAttributes: (
  category: Category
) => RequirementAttribute[] = (category) => [
  // {
  //   name: "category",
  //   type: "enum",
  //   label: "Category",
  //   options: activityCategoryOptions,
  //   validation: Yup.string().required("Please select a Category"),
  // },
  {
    name: "annual",
    type: "object[]",
    label: "Annual Requirements",
    options: generateRequirementOptions(category),
  },
  {
    name: "triennial",
    type: "object[]",
    label: "Programme period Requirements",
    options: generateRequirementOptions(category),
  },

  // {
  //   name: "subcategory_requirement",
  //   label: "Subcategory",
  //   type: "facility",
  //   conditional: clinicalUsersConditional,
  //   options: [
  //     { value: "facility_name", label: "Facility", type: "string" },
  //     {
  //       value: "facility_type",
  //       label: "Type",
  //       type: "enum",
  //       options: facilityTypeOptions,
  //     },
  //     // { value: "fte", label: "Approximate FTE", type: "string" },
  //   ],
  //   layout: {
  //     display: "fieldArray",
  //   },
  // },
];
