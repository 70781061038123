import React from "react";
import SignOut from "components/common/auth/SignOut";
import { IconSettings, IconUser } from "components/common/icons/twoToneIcons";
import { USER_ROUTES } from "constants/routes/user";
import AppSettingsMenu from "./AppSettingsMenu";
import { useSelector } from "react-redux";
import { currentUserSelector } from "store/selectors/core.selectors";
import styles from "./AppSettingsMenu.module.scss";

const UserSettings = () => (
  <AppSettingsMenu
    options={[
      {
        key: "details",
        children: "My Details",
        to: USER_ROUTES.MY_PROFILE.path,
      },
      {
        key: "signout",
        children: <SignOut className={styles.menuItemButton} />,
      },
    ]}
    aria-label={"User Settings"}
    header={<UserHeader />}
  >
    <IconSettings />
  </AppSettingsMenu>
);

const UserHeader = () => {
  const user = useSelector(currentUserSelector);
  return (
    <div className={styles.header}>
      <IconUser className={styles.icon} />
      <div className={styles.title}>
        {`${user.given_name} ${user.family_name}`}
        <br />
        <span>{user.email}</span>
      </div>
    </div>
  );
};

export default UserSettings;
