import React from "react";
import { useParams } from "react-router";
import { coreService } from "services/CoreService";
import Meta from "components/common/Meta";
import MiniLoader from "components/common/ui/loader/MiniLoader";
import { AppContentContainer } from "components/common/layouts/appLayout/AppContentContainer";
import PeerGroupDetail from "components/peergroup/detail/PeerGroupDetail";
import { PEERGROUP_ROUTES } from "constants/routes/peergroup";
import { usePeerGroupDetail } from "components/peergroup/hooks/usePeerGroupDetails";
import { useSelector } from "react-redux";
import { currentUserSelector } from "store/selectors/core.selectors";
import { ROUTE_PARAMS } from "constants/routes/common";
import { permissionsSelector } from "store/selectors/auth.selectors";
import { canAdminEdit } from "utils/authUtils";

const ViewPeerGroupDetails = () => {
  const { peergroupId } = useParams<{ peergroupId: string; orgId: string }>();
  const currentUser = useSelector(currentUserSelector);
  const permissions = useSelector(permissionsSelector);
  const isAdmin = canAdminEdit(permissions);

  const { isLoading, data: peergroupDetail } = usePeerGroupDetail(peergroupId);

  return (
    <AppContentContainer
      spacing="none"
      // backgroundColor="light-grey"
      // backButton={{
      //   to: coreService.getRoute(PEERGROUP_ROUTES.ADMIN_PEERGROUPS),
      //   label: "Back to Peer Group",
      // }}
      backButton={{
        to: coreService.getRoute(
          isAdmin
            ? PEERGROUP_ROUTES.ADMIN_PEERGROUPS
            : PEERGROUP_ROUTES.USER_PEERGROUPS,
          { [ROUTE_PARAMS.USER]: currentUser.id }
        ),
        label: "Back to Peer Groups",
      }}
    >
      <Meta
        title={`${
          peergroupDetail ? `${peergroupDetail.peergroup_name} ` : "Peergroup"
        } details`}
      />
      <br />
      <br />

      {isLoading || !peergroupDetail ? (
        <MiniLoader theme="secondary" />
      ) : (
        <PeerGroupDetail
          peergroupDetail={peergroupDetail}
          currentUser={currentUser}
          editLink={""}
        />
      )}
    </AppContentContainer>
  );
};

export default ViewPeerGroupDetails;
