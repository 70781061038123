import qs from "query-string";
import { appGetState, appNextState, appUpdateState } from "store";
import { TRoute } from "constants/routes";
import { TRouteParamOptions } from "constants/routes/common";
import { layoutService } from "services/LayoutService";
import { captureError } from "utils/errorUtils";
import { fetchCurrentUser } from "api/UserApi";
import { create } from "models/user/User";
import { extractCurrentPermissions, verifyPermisisons } from "utils/authUtils";
import { PERMISSIONS, TRequiredPermission } from "models/user/Permissions";
import { setRouteParams } from "utils/routeUtils";

const comment = (m: string) => `CoreService::${m}`;

class CoreService {
  private appGetState = appGetState;
  private appNextState = appNextState;
  private appUpdateState = appUpdateState;

  /**
   */
  public getRoute = (
    url: TRoute,
    match?: TRouteParamOptions,
    params?: Record<string, any>
  ) => {
    const {
      core: { user },
    } = this.appGetState();
    const p = params
      ? `?${qs.stringify(params, { arrayFormat: "comma" })}`
      : "";
    return setRouteParams(url.path, { id: user.id }, match).concat(p);
  };

  public getUserPermissions = () =>
    extractCurrentPermissions(this.appGetState());

  public verifyUserPermissions = (
    requiredRole?: TRequiredPermission,
    permissions?: PERMISSIONS[]
  ) => {
    const p = permissions || this.getUserPermissions();
    return verifyPermisisons(p, requiredRole);
  };

  public getCurrentUser = async () => {
    try {
      layoutService.setIsLoading(true);
      const { isLoading, error, loggedIn, permissions, ...authUser } =
        this.appGetState().auth;
      let apiUser = {};
      try {
        apiUser = await fetchCurrentUser();
      } catch (e) {
        console.log(e);
      }

      const user = create({
        active: true,
        roles: permissions,
        ...authUser,
        ...apiUser,
      });

      this.appUpdateState(
        (s) => (s.core.user = user),
        comment("getCurrentUser")
      );
      layoutService.setIsLoading(false);
      return true;
    } catch (e) {
      this.setAppError(e);
      layoutService.setIsLoading(false);
      return false;
    }
  };

  public setAppLoaded = (isLoaded: boolean): void => {
    this.appUpdateState(
      (s) => (s.core.isAppLoaded = isLoaded),
      comment("setAppLoaded")
    );
  };

  public setAppError = (
    e: unknown,
    defaultMessage = "Critcal Error: Unable to load Application"
  ) => {
    if (e instanceof Error) {
      captureError(e);
      layoutService.error(e, defaultMessage);
      this.appUpdateState((s) => (s.core.error = e), comment("setAppError"));
    }
  };
}

const coreService = new CoreService();
export { coreService };
