import React from "react";
import Meta from "components/common/Meta";
import AppContentContainer from "components/common/layouts/appLayout/AppContentContainer";
import Container from "components/common/layouts/Container";
import { useSelector } from "react-redux";
import {
  isDimensionsLoadingSelector,
  peergroupDimSelector,
} from "store/selectors/dimensions.selectors";
import { isAppLoadedSelector } from "store/selectors/core.selectors";
import { usePeerGroupList } from "components/peergroup/hooks/usePeerGroupLists";
import { PeerGroupList } from "components/peergroup/list/PeerGroupList";
import Spacer from "components/common/ui/spacer/Spacer";
import PageHeader from "components/common/layouts/appLayout/PageHeader";

const ViewUserPeerGroups = () => {
  const isAppLoaded = useSelector(isAppLoadedSelector);
  const isDimLoading = useSelector(isDimensionsLoadingSelector);
  const enabled = isAppLoaded && !isDimLoading;
  const dimensions = useSelector(peergroupDimSelector);

  const { isLoading, data: peergroups } = usePeerGroupList(dimensions, {
    enabled,
  });

  return (
    <AppContentContainer spacing="none">
      <Meta title={`Peer Groups`} />
      <PageHeader spacing="extra" spacingTopOnly header={"Peer Groups"} />
      <Container spacing="none">
        <p>
          Peer group activities are part of the requirement to review and
          reflect on practice. Peer review groups meet regularly to discuss case
          management and clinical problems, with the aim of improving the
          performance of individuals within the group.
          <br />
          <br />
          For more information,{" "}
          <a
            href="https://rnzcuc.org.nz/recertification-2021/peer-group-activities/"
            target="_blank"
            rel="noreferrer"
          >
            view the requirements & guidance for Peer Group activities
          </a>
        </p>
      </Container>
      <hr />
      <Spacer height={20} />
      <PeerGroupList
        isLoading={!enabled || isLoading}
        peergroups={peergroups}
      />
    </AppContentContainer>
  );
};

export default ViewUserPeerGroups;
