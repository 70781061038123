import { useMutation, useQueryClient } from "react-query";
import { resetPassword } from "api/UserApi";
import { userQueryKeys } from "queries/constants";
import { MutationOptions } from "queries/queries";
import { useToast } from "components/common/ui/toast/useToast";

export const useMutateReset = (options: MutationOptions = {}) => {
  const queryClient = useQueryClient();
  const { addErrorToast, addToast } = useToast();

  return useMutation(({ user }) => resetPassword(user), {
    onSuccess: (result, inputs) => {
      addToast(`Password Reset details sent to ${inputs.user.email}`, {
        theme: "success",
      });

      queryClient.invalidateQueries(userQueryKeys.detail(inputs.user.id));
      queryClient.invalidateQueries(userQueryKeys.list());
    },
    onError: (e, inputs) => {
      addErrorToast(e, `Error resetting password for ${inputs.user.email}`);
    },
    ...options,
  });
};
