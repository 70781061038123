import * as Yup from "yup";
import { DataAttribute } from "components/common/ui/form-elements/formElements";
import { keyBy } from "utils/common";
import { getDefaultValues, getValidations } from "utils/formUtils";

export interface PeerGroupAttribute extends DataAttribute {}

export const peerGroupAttributes: PeerGroupAttribute[] = [
  {
    name: "peergroup_name",
    type: "string",
    label: "Peer Group Name",
    required: true,
    validation: Yup.string().required("Please enter a name for the peer group"),
  },
  {
    name: "peergroup_summary",
    type: "richText",
    label: "Peergroup Summary",
    required: true,
    validation: Yup.string().required(
      "Please enter short summary for the peer group"
    ),
  },
];

export const defaultPeerGroupValues = getDefaultValues(peerGroupAttributes);
export const peergroupValidations = getValidations(peerGroupAttributes);

export const peerGroupAttributesMap = {
  ...keyBy(peerGroupAttributes, "name"),
} as const;

export const peerGroupAttributeKeys = Object.keys(peerGroupAttributesMap);
