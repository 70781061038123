import React, { useEffect } from "react";
import Meta from "components/common/Meta";
import { AppContentContainer } from "components/common/layouts/appLayout/AppContentContainer";
import { FACILITY_ROUTES } from "constants/routes/facility";
import Container from "components/common/layouts/Container";
import { coreService } from "services/CoreService";
import FacilityCreateForm from "components/facilities/create/FacilityCreateForm";
import { useHistory } from "react-router";
import { useMutateFacility } from "components/facilities/hooks/useMutateFacility";
import {
  createFacility,
  createFacility as blankFacility,
  Facility,
} from "models/facility/Facility";

const ViewAdminCreateFacility = () => {
  const history = useHistory();
  const { mutate, data, isSuccess } = useMutateFacility();

  useEffect(() => {
    if (isSuccess && data && data.facility_id) {
      history.push(
        coreService.getRoute(FACILITY_ROUTES.ADMIN_EDIT_FACILITY, {
          facilityId: data.facility_id,
        })
      );
    }
  }, [isSuccess, data, history]);

  const onSave = async (newFacility: Partial<Facility>) => {
    await mutate({
      newFacility,
      oldFacility: blankFacility(),
    });
  };

  // const onSave = () => {
  //   console.log("bla bla bla")
  // };

  const onCancel = () => {
    history.push(coreService.getRoute(FACILITY_ROUTES.ADMIN_FACILITIES));
  };

  return (
    <AppContentContainer
      spacing="none"
      backButton={{
        to: coreService.getRoute(FACILITY_ROUTES.ADMIN_FACILITIES),
        label: "Back to Facility",
      }}
    >
      <Meta title="Create Facility" />
      <br />
      <br />
      <br />
      <Container>
        {/*<button onClick={() => {console.log(data)}}> Click here </button>*/}

        <h1>Create New Facility</h1>
        <FacilityCreateForm
          facility={createFacility()}
          onSubmit={onSave}
          onCancel={onCancel}
          saveText={"Create Facility"}
        />
      </Container>
    </AppContentContainer>
  );
};

export default ViewAdminCreateFacility;
