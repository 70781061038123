import { DIMENSION_MATCH, IDimensions } from "models/Dimension";
import {
  exactArrayMatch,
  exactMatch,
  facetMatchArray,
  facetMatchString,
  fuzzyMatch,
  matchDateRange,
} from "utils/dimensions/matchUtils";
import { checkEmptyBuckets } from "utils/dimensions/bucketUtils";
import { deepObjectAccessor } from "utils/common";

type Data = TObjectAny[];

export const applyDimensionFilters = (data: Data, dimensions: IDimensions) => {
  const activeDimensions = Object.values(dimensions).filter(
    // if dimension is blank then ignore dimension
    (dim) => !checkEmptyBuckets(dim.buckets)
  );
  return data.filter((d) => {
    return activeDimensions.every(({ buckets, match, key }) => {
      // double check - if dimension is blank then ignore dimension
      if (checkEmptyBuckets(buckets)) {
        return true;
      }
      // deepObjectAccessor allows us to use dot notation as the dimension keys to access nested values in the data
      const value = deepObjectAccessor(d, key);

      switch (match) {
        case DIMENSION_MATCH.EXACT:
          return exactMatch(buckets, value);

        case DIMENSION_MATCH.EXACT_ARRAY:
          return exactArrayMatch(buckets, value);

        case DIMENSION_MATCH.FACET:
          return facetMatchString(buckets, value);

        case DIMENSION_MATCH.FACET_ARRAY:
          return facetMatchArray(buckets, value);

        case DIMENSION_MATCH.FUZZY:
          return fuzzyMatch(buckets, value);

        case DIMENSION_MATCH.DATE_RANGE:
          return matchDateRange(buckets, value);

        default:
          return buckets === value;
      }
    });
  });
};

export const paginateData = ({
  data,
  next: possibleNext,
  limit,
}: {
  data: any[];
  next: number;
  limit: number;
}) => {
  const total = data.length;
  if (total === 0) {
    return {
      data: data,
      current: 0,
    };
  }
  const next = Math.max(possibleNext, 0);
  const start = next * limit;
  if (start <= total) {
    const end = Math.min(start + limit, total);
    const paginatedData = data.slice(start, end);
    return {
      data: paginatedData,
      current: next,
    };
  }
  return false;
};

// export const flattenDimensionData = (data: any[], dimensions: IDimension[]) => {
//   return data.map((d) => {
//     return dimensions.reduce((obj, k) => {
//       return { ...obj, [k.bitIndex]: deepObjectAccessor(d, k.key) };
//     }, {} as Record<string, any>);
//   });
// };
