import { useQuery } from "react-query";
import { fetchOneById } from "api/UserApi";
import { userQueryKeys } from "queries/constants";
import { QueryOptions } from "queries/queries";
import { useToast } from "components/common/ui/toast/useToast";

export const useUserDetail = (userID: string, options?: QueryOptions) => {
  const { addErrorToast } = useToast();
  return useQuery(userQueryKeys.detail(userID), () => fetchOneById(userID), {
    onError: (e) =>
      addErrorToast(e, "Unable to load user details", {
        id: userQueryKeys.detail(userID).join("::"),
      }),
    staleTime: 60 * 1000,
    ...options,
  });
};
