import React from "react";
import cx from "classnames";
import { useFieldArray } from "react-hook-form";
import { DataAttribute } from "components/common/ui/form-elements/formElements";
import FormQuestion from "components/common/ui/form-elements/form-layout/FormQuestion";
import IconDelete from "components/common/icons/IconDelete";
import Button from "components/common/ui/button/Button";
import ConditionalLogicWrapper from "components/common/ui/form-elements/hook-form-elements/ConditionalLogicWrapperHookForm";
import { Control, UseFormRegister } from "react-hook-form/dist/types/form";
import ActionButton from "components/common/ui/button/ActionButton";
import { deepObjectConstructor } from "utils/common";
import styles from "./FieldArray.module.scss";

interface Props extends DefaultProps {
  question: DataAttribute;
  errors: Record<string, any>;
  register: UseFormRegister<any>;
  disabled?: boolean;
  control: Control;
  className?: string;
  defaultValue?: any;
}

const FieldArrayQuestion = ({
  question: { name, label, ...def },
  register,
  control,
  errors,
  className,
  ...props
}: Props) => {
  const { fields, append, remove } = useFieldArray({
    control: control, // control props comes from useForm (optional: if you are using FormContext)
    name: name, // unique name for your Field Array
  });

  const onAppend = () => {
    const blank =
      def.options?.reduce((b, r) => ({ ...b, [r.value]: "" }), {}) || {};
    append(blank);
  };

  return (
    <div className={cx(styles.root, className)} {...props}>
      <div className={styles.label}>{label}</div>
      {fields.map((f, i) => (
        <section className={styles.item} key={f.id}>
          {def.options &&
            def.options.map((o) => (
              <ConditionalLogicWrapper
                key={o.value}
                control={control}
                conditional={o.conditional}
                fieldPrefix={`${name}.${i}`}
                className={styles.questionWrap}
              >
                <FormQuestion
                  key={o.value}
                  question={{
                    ...o,
                    name: `${name}.${i}.${o.value}`,
                    // label: i > 0 ? " " : o.label,
                    label: o.label,
                    type: o.type || "string",
                  }}
                  errors={deepObjectConstructor(
                    {},
                    `${name}.${i}.${o.value}`,
                    errors?.[i]?.[o.value] || null
                  )}
                  register={register}
                  control={control}
                  className={cx(
                    styles.question,
                    o.type === "boolean" && styles.boolean
                  )}
                  //@ts-ignore we only want smaller textArea by default
                  rows={2}
                />
              </ConditionalLogicWrapper>
            ))}
          <ActionButton
            icon={<IconDelete />}
            onClick={() => remove(i)}
            aria-label="delete"
            theme="danger"
            rounded
            className={styles.delete}
          >
            DELETE
          </ActionButton>
        </section>
      ))}
      <Button onClick={onAppend} theme="primary" variant="lightFilled">
        Add new
      </Button>
    </div>
  );
};

export default FieldArrayQuestion;
