import {
  format as dateFnsFormat,
  formatDistance,
  formatDistanceStrict,
  formatDistanceToNow,
  differenceInDays,
  differenceInMinutes,
  fromUnixTime,
  getUnixTime,
  differenceInCalendarMonths,
  // formatISO,
} from "date-fns";

export const isValidDate = (d: any): d is Date => {
  let dateInst =
    typeof d === "string" || typeof d === "number" ? new Date(d) : d;
  // @ts-ignore TS doesn't like applying isNaN to Date/non-number type, but this is valid (I think)
  return dateInst instanceof Date && !isNaN(dateInst);
};

export const convertToDate = (d: any): Date | null => {
  return isValidDate(d) ? new Date(d) : null;
};

export const convertDateToIsoDate = (d: any): string | null => {
  const vd = convertToDate(d);
  // return vd ? formatISO(vd) : null;
  return vd ? vd.toISOString() : null;
};

export const convertUnixTimeToDate = (unixTimestamp: number) => {
  return fromUnixTime(unixTimestamp / 1000000000);
};
export const convertDateToUnixTime = (date: Date) => getUnixTime(date);

export const formatDate = (
  date: Date | string | null | undefined,
  format = "dd/MM/yyyy",
  invalidText = "Invalid Date"
) => {
  const d = convertToDate(date);
  if (d) {
    try {
      return dateFnsFormat(d, format);
    } catch (e) {
      return invalidText;
    }
  }
  return invalidText;
};

export const formatUnixTime = (
  unixTimestamp: number,
  format = "do MMM yyyy"
) => {
  return formatDate(convertUnixTimeToDate(unixTimestamp), format);
};

export const unixTimeDifference = (
  unixTimestamp: number,
  targetDate: number | Date
) => {
  const target =
    typeof targetDate === "number"
      ? convertUnixTimeToDate(targetDate)
      : targetDate;

  return formatDistance(convertUnixTimeToDate(unixTimestamp), target);
};

export const timeDifference = <T>(
  startDate: string | Date | null,
  targetDate: string | Date | null = new Date(),
  includeAgo: boolean = true,
  strict?: boolean,
  invalidReturn?: T
): string | T | undefined => {
  const start = convertToDate(startDate);
  const target = convertToDate(targetDate);
  const diff = daysDiff(target, start);
  const format = strict ? formatDistanceStrict : formatDistance;
  return start && target
    ? `${format(start, target)}${includeAgo && diff && diff < 0 ? " ago" : ""}`
    : invalidReturn;
};

export const unixTimeDiffToNow = (unixTimestamp: number) => {
  return formatDistanceToNow(convertUnixTimeToDate(unixTimestamp));
};

export const withinLastXDays = (unixTimestamp: number, numberOfDays = 2) => {
  const d = convertUnixTimeToDate(unixTimestamp);
  const diff = differenceInDays(new Date(), d);
  return diff < numberOfDays;
};

export const daysDiff = (
  startDate: Date | string | null,
  targetDate: Date | string | null = new Date()
) => {
  const target = convertToDate(targetDate);
  const start = convertToDate(startDate);
  if (isValidDate(target) && isValidDate(start)) {
    return differenceInDays(target, start);
  }
  return undefined;
};

export const minutesDiff = (
  unixTimestamp: number,
  targetDate: number | Date = new Date()
) => {
  const target =
    typeof targetDate === "number"
      ? convertUnixTimeToDate(targetDate)
      : targetDate;
  const d = convertUnixTimeToDate(unixTimestamp);
  return differenceInMinutes(target, d);
};

export const monthsDiff = (
  startDate: Date | string | null,
  targetDate: Date | string | null = new Date()
) => {
  const target = convertToDate(targetDate);
  const start = convertToDate(startDate);

  if (isValidDate(target) && isValidDate(start)) {
    return differenceInCalendarMonths(target, start);
  }
  return undefined;
};
