import React, { useState } from "react";
import { Goal } from "models/cpd/Goal";
import GoalEditForm from "components/cpd/goal/GoalEditForm";
import FormActions from "components/common/ui/form-elements/form-layout/FormActions";
import Button from "components/common/ui/button/Button";
import Chip from "components/common/ui/chip/Chip";
import {
  IconProgressComplete,
  IconProgressStar,
} from "components/common/icons/IconProgress";
import HintBox from "components/common/ui/hint-box/HintBox";
import { formatDate } from "utils/format/dateUtils";
import styles from "./GoalDetail.module.scss";
import GoalItemActionList from "components/cpd/goal/GoalItemActionList";

interface Props {
  goal?: Goal;
  editting?: boolean;
  userId: string;
  // onSubmit: (d: Partial<Goal>) => void;
  onCancel: () => void;
}

const GoalDetail = ({ goal, editting = false, ...rest }: Props) => {
  const [edit, setEdit] = useState(editting);
  return (
    <div>
      <h1>{edit ? "Development Goal" : <GoalHeader goal={goal} />}</h1>
      {edit ? (
        <GoalEditForm {...rest} goal={goal} />
      ) : (
        <div className={styles.root}>
          <h5>Development Goals</h5>
          <GoalItemActionList items={goal?.dev_goals} />

          <h5>Actions</h5>
          <GoalItemActionList items={goal?.actions} />

          <h5>Additional Notes</h5>
          {goal?.notes || " - "}
          <br />
          <br />
          <HintBox theme="info">
            <div>
              <div>
                <b>Created:</b>{" "}
                {goal?.created_at && formatDate(goal?.created_at)}
              </div>
              <div>
                <b>Last Updated:</b>{" "}
                {goal?.created_at && formatDate(goal?.created_at)}
              </div>
              {goal?.completed_at && (
                <div>
                  <b>Completed:</b> {formatDate(goal?.completed_at)}
                </div>
              )}
            </div>
          </HintBox>
          <br />
          <FormActions>
            <Button variant="empty" onClick={rest.onCancel}>
              Cancel
            </Button>
            <Button rounded theme="primary" onClick={() => setEdit(true)}>
              Edit Goal
            </Button>
          </FormActions>
        </div>
      )}
    </div>
  );
};

const GoalHeader = ({ goal }: { goal?: Goal }) => (
  <div className={styles.header}>
    {goal?.goal_name}
    {goal?.goal_status === "complete" ? (
      <Chip theme="success" icon={<IconProgressComplete />} condensed>
        Completed
      </Chip>
    ) : (
      <Chip theme="info" icon={<IconProgressStar />} condensed>
        In Progress
      </Chip>
    )}
  </div>
);

export default GoalDetail;
