import React from "react";
import foreground from "./logo-fg.png";
import background from "./logo-bg.png";
import styles from "./FullPageLoader.module.scss";

const FullPageLoader = () => (
  <div className={styles.root}>
    <img src={background} className={styles.bg} alt="loading" />
    <img src={foreground} className={styles.fg} alt="" />
  </div>
);

export default FullPageLoader;
