import React from "react";
import { useParams } from "react-router";
import { coreService } from "services/CoreService";
import Meta from "components/common/Meta";
import MiniLoader from "components/common/ui/loader/MiniLoader";
import { AppContentContainer } from "components/common/layouts/appLayout/AppContentContainer";
import { useSelector } from "react-redux";
import {
  currentUserSelector,
  isAppLoadedSelector,
} from "store/selectors/core.selectors";
import { ROUTE_PARAMS } from "constants/routes/common";
import { SUPERVISORS_ROUTES } from "constants/routes/supervisors";
import SuperviseeDetail from "components/supervisors/detail/SuperviseeDetail";
import { useSuperviseeDetails } from "components/supervisors/hooks/useSuperviseeDetails";
import { isDimensionsLoadingSelector } from "store/selectors/dimensions.selectors";

const ViewSuperviseeDetails = () => {
  const { supervisorId } = useParams<{ supervisorId: string }>();
  const { superviseeId } = useParams<{ superviseeId: string }>();
  const isAppLoaded = useSelector(isAppLoadedSelector);
  const isDimLoading = useSelector(isDimensionsLoadingSelector);
  const enabled = isAppLoaded && !isDimLoading;
  const currentUser = useSelector(currentUserSelector);

  const { isLoading: isLoadingDetail, data: superviseeDetail } =
    useSuperviseeDetails(supervisorId, superviseeId, {
      enabled,
    });

  return (
    <AppContentContainer
      spacing="none"
      backButton={{
        to: coreService.getRoute(SUPERVISORS_ROUTES.MY_SUPERVISEES, {
          [ROUTE_PARAMS.SUPERVISOR]: currentUser.id,
        }),
        label: "Back to My Supervisees",
      }}
    >
      <Meta
        title={`${
          superviseeDetail
            ? `${superviseeDetail.given_name} ${superviseeDetail.family_name}`
            : "Supervisee"
        } details`}
      />
      <br />
      <br />

      {isLoadingDetail || !superviseeDetail ? (
        <MiniLoader theme="secondary" />
      ) : (
        <SuperviseeDetail
          superviseeDetail={superviseeDetail}
          editLink={""}
          superviseeId={superviseeId}
        />
      )}
    </AppContentContainer>
  );
};

export default ViewSuperviseeDetails;
