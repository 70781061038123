import { core } from "models/user/attributes/core";
import { fields } from "models/user/attributes/fields";
import { office } from "models/user/attributes/office";
import {
  userFieldsKey,
  userOfficeOnlyKey,
} from "models/user/attributes/common";
import { keyBy } from "utils/common";

export const userAttributes = {
  ...keyBy(core, "name"),
  fields: keyBy(fields, "name"),
  admin: keyBy(office, "name"),
};

export const userAttributesMap = {
  ...keyBy(core, "name"),
  ...keyBy(
    fields,
    (q) => `${userFieldsKey}.${q.name}`,
    ({ name, ...rest }) => ({ ...rest, name: `${userFieldsKey}.${name}` })
  ),
  ...keyBy(
    office,
    (q) => `${userOfficeOnlyKey}.${q.name}`,
    ({ name, ...rest }) => ({ ...rest, name: `${userOfficeOnlyKey}.${name}` })
  ),
} as const;

export const attributes = Object.keys(userAttributesMap);
