import React from "react";
import Meta from "components/common/Meta";
import { AppContentContainer } from "components/common/layouts/appLayout/AppContentContainer";
import { usePlanList } from "components/cpd/hooks/usePlanList";
import { useSelector } from "react-redux";
import { isAppLoadedSelector } from "store/selectors/core.selectors";
import { CPD_ROUTES } from "constants/routes/cpd";
import { planListDimSelector } from "store/selectors/dimensions.selectors";
import MiniLoader from "components/common/ui/loader/MiniLoader";
import { coreService } from "services/CoreService";
import Button from "components/common/ui/button/Button";
import styles from "./UserPlanList.module.scss";
import Container from "components/common/layouts/Container";
import PlanList from "components/cpd/plan/PlanList";
import { cpdService } from "services/CpdService";
import { CreatePlanButton } from "components/cpd/plan/AdminPlanButtons";
import { TUser } from "models/user/User";
import { IPageBackButton } from "components/common/layouts/appLayout/PageBackButton";
import { useLocation } from "react-router";
import { checkTPS } from "components/cpd/utils/utils";

interface Props {
  user: TUser;
  userId: string;
  isLoading: boolean;
  isAdminList?: boolean;
  backButton?: IPageBackButton;
}

const UserPlanList = ({
  user,
  isLoading: isUserLoading,
  userId,
  isAdminList,
  backButton,
}: Props) => {
  const dimensions = useSelector(planListDimSelector);
  const isAppLoaded = useSelector(isAppLoadedSelector);
  const { isLoading: isPlanListLoading, data: planList } = usePlanList(
    dimensions,
    userId,
    { enabled: isAppLoaded }
  );
  const loc = useLocation();
  const { isTPS } = checkTPS(loc, undefined, user);

  const isLoading = isPlanListLoading || isUserLoading || !isAppLoaded;

  return (
    <AppContentContainer
      spacing="normal"
      maxWidth={true}
      backButton={backButton}
    >
      <Meta title={isTPS ? "Training Programme List" : "Plans"} />

      <Container spacing={"extra"} spacingTopOnly>
        <div className={styles.header}>
          <div>
            <h1>
              {isTPS
                ? "Training Programme Summary"
                : "Continuing Professional Development"}
            </h1>

            <h2>{user ? `${user.given_name} ${user.family_name}` : ""}</h2>
          </div>
          <div>
            <Button
              theme="secondary"
              variant="outline"
              to={
                isTPS
                  ? `${coreService.getRoute(CPD_ROUTES.MY_GOALS_TPS)}`
                  : `${coreService.getRoute(CPD_ROUTES.MY_GOALS)}`
              }
            >
              View goals
            </Button>
            <CreatePlanButton userId={userId} />
          </div>
        </div>
      </Container>
      {isLoading ? (
        <MiniLoader theme="secondary" />
      ) : (
        // TODO: setActive/ setManyActive comes from planService Dimensions?
        <PlanList
          plans={planList ?? []}
          dimensions={dimensions}
          setActive={cpdService.setDimensions}
          setManyActive={cpdService.setManyDimensions}
          isAppLoaded={isAppLoaded}
          isAdminList={!!isAdminList}
          userId={userId}
          isTPS={isTPS}
        />
      )}
    </AppContentContainer>
  );
};

export default UserPlanList;
