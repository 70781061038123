import React from "react";
import cx from "classnames";
import { RequirementType, TProgressItem } from "models/cpd/common/requirements";
import CategoryIcon from "components/cpd/common/CategoryIcon";
import { IconChip } from "components/common/ui/chip/Chip";
import { subCategoryOptions } from "models/cpd/common/common";
import { keyBy } from "utils/common";
import Tooltip from "components/common/ui/tooltip/Tooltip";
import styles from "components/cpd/common/RequirementsProgress.module.scss";

interface Props extends DefaultProps {
  progress: TProgressItem[];
  year: number;
  annual?: boolean;
}

const RequirementsProgress = ({ progress, year, annual }: Props) => {
  return (
    <div className={styles.req}>
      {progress.map((d, i) => (
        <Item key={i} item={d} year={year} annual={annual} />
      ))}
    </div>
  );
};

const Item = ({
  item,
  year,
  annual,
}: {
  item: TProgressItem;
  year?: number;
  annual?: boolean;
}) => {
  const progress =
    (year !== undefined && annual
      ? item?.year_progress?.[year]
      : item.progress) || 0;
  const percent = Math.min(100, (progress / item.credits) * 100);

  const waived_progress =
    (year !== undefined && annual
      ? item?.waived_year_progress?.[year]
      : item.waived_progress) || 0;
  const waivedPercent = Math.min(100, (waived_progress / item.credits) * 100);

  const plannedProgress =
    (year !== undefined && annual
      ? item?.planned_year_progress?.[year]
      : item.planned_progress) || 0;
  const plannedPercent = Math.min(100, (plannedProgress / item.credits) * 100);

  const sub = subCategoryOptions[item.category] || [];
  const optionsMap = keyBy(sub, "value", (d) => d.altLabel || d.label);
  let label = item.label;
  if (item.type === RequirementType.exact) {
    label = optionsMap[item.option] || item.option;
  }
  if (item.credits > 1) {
    return (
      <div
        className={cx(
          styles.row,
          // not 100 to account for rounding errors (e.g. 33.33% + 66.66%)
          percent + waivedPercent >= 99.9 && styles.complete
        )}
      >
        <Tooltip content={label}>
          <span className={styles.icon}>
            <CategoryIcon type={item.category} />
          </span>
        </Tooltip>
        <div className={styles.chartWrap}>
          <div
            className={styles.chartBar}
            style={{
              width: `${percent}%`,
            }}
          />
          {waivedPercent > 0 && (
            <div
              className={cx(styles.chartBar, styles.waived)}
              style={{
                left: `${percent}%`,
                width: `${waivedPercent}%`,
              }}
            />
          )}

          {plannedPercent > 0 && (
            <div
              className={cx(styles.chartBar, styles.planned)}
              style={{
                left: `${waivedPercent + percent}%`,
                width: `${plannedPercent}%`,
              }}
            />
          )}

          <div className={styles.chartLabel}>
            {progress + waived_progress}
            <span>/{item.credits}</span>
          </div>
        </div>
      </div>
    );
  }
  return (
    <Tooltip content={`${item.unitType === "hourly" ? "1hr of" : ""} ${label}`}>
      <span>
        <IconChip
          className={cx(styles.single, waivedPercent > 0 && styles.waived)}
          theme={
            percent + waivedPercent >= 99.9
              ? waivedPercent > 0
                ? "info"
                : "success"
              : plannedPercent >= 99.9
              ? "warning"
              : "grey"
          }
        >
          <CategoryIcon type={item.category} className={styles.icon} />
        </IconChip>
      </span>
    </Tooltip>
  );
};

export default RequirementsProgress;
