import React, { useEffect } from "react";
import Meta from "components/common/Meta";
import { useHistory, useParams } from "react-router";
import { AppContentContainer } from "components/common/layouts/appLayout/AppContentContainer";
import { ROUTE_PARAMS } from "constants/routes/common";
import { FACILITY_ROUTES } from "constants/routes/facility";
import Container from "components/common/layouts/Container";
import { coreService } from "services/CoreService";
import MiniLoader from "components/common/ui/loader/MiniLoader";
import { useFacilityDetail } from "components/facilities/hooks/useFacilityDetail";
import { useMutateFacility } from "components/facilities/hooks/useMutateFacility";
import { Facility } from "models/facility/Facility";
import FacilityEditForm from "components/facilities/edit/FacilityEditForm";

const ViewAdminEditFacility = () => {
  const { facilityId } = useParams<{ facilityId: string }>();
  const history = useHistory();
  const { isLoading, data: facility } = useFacilityDetail(facilityId);
  const { mutate, isSuccess, isLoading: isSubmitting } = useMutateFacility();

  useEffect(() => {
    if (isSuccess) {
      history.push(
        coreService.getRoute(FACILITY_ROUTES.ADMIN_FACILITY_DETAIL, {
          [ROUTE_PARAMS.FACILTIY]: facilityId,
        })
      );
    }
  }, [isSuccess, facilityId, history, facility]);

  const onSave = async (newFacility: Partial<Facility>) => {
    await mutate({
      newFacility,
      oldFacility: facility,
    });
  };

  const onCancel = () => {
    history.push(coreService.getRoute(FACILITY_ROUTES.ADMIN_FACILITIES));
  };
  return (
    <AppContentContainer
      spacing="none"
      backButton={{
        to: coreService.getRoute(FACILITY_ROUTES.ADMIN_FACILITY_DETAIL, {
          facilityId: facilityId,
        }),
        label: "Back to Facility Details",
      }}
    >
      <Meta title={`Edit - ${facility?.facility_name}`} />
      <br />
      <br />
      <br />
      <Container>
        <h1 style={{ marginBottom: 0 }}>Edit Facility</h1>
        {isLoading || !facility ? (
          <MiniLoader theme="secondary" />
        ) : (
          <FacilityEditForm
            isAdmin={true}
            onSubmit={onSave}
            facility={facility}
            onCancel={onCancel}
            isSubmitting={isSubmitting}
          />
        )}
      </Container>
    </AppContentContainer>
  );
};

export default ViewAdminEditFacility;
