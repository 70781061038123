import React from "react";
import cx from "classnames";
import {
  SkeletonText,
  SkeletonIcon,
} from "components/common/ui/skeleton/Skeleton";
import DefaultRow from "components/common/ui/table/DefaultRow";
import {
  defaultColPropGetter,
  getColumnClasses,
  IColumn,
} from "components/common/ui/table/utils";
import styles from "./ResponsiveTable.module.scss";
import SkeletonLoader from "components/common/ui/skeleton/SkeletonLoader";

export interface IPlaceholderProps {
  noDataLabel?: string;
  noDataHeight?: number;
  loadingRows?: number;
}

interface ITablePlaceholder extends IPlaceholderProps {
  loading: boolean;
  columns: IColumn[];
  columnStyle: TObjectAny;
  getColumnProps?: (col: IColumn) => TObjectAny;
}

const TablePlaceholder = ({
  loading,
  noDataLabel = "No Data",
  noDataHeight = 45,
  loadingRows = 1,
  columns,
  columnStyle,
  getColumnProps = defaultColPropGetter,
}: ITablePlaceholder) => {
  if (!loading) {
    return (
      <div
        key="table-no-data-placeholder"
        className={cx(styles.placeholder)}
        style={{ height: noDataHeight }}
      >
        <div className={styles.placeholderLabel}>{noDataLabel}</div>
      </div>
    );
  }
  const opacity = Array.from({ length: loadingRows }, (_, i) =>
    loadingRows > 3 ? (loadingRows - i) / loadingRows : 1
  );
  return (
    <>
      {opacity.map((d, i) => (
        <DefaultRow
          key={`skeleton-loading-row-${i}`}
          className={cx(styles.row, styles.loading)}
          style={columnStyle}
          wrapperStyle={{ opacity: d }}
        >
          {columns.map((c) => {
            const { className: colClass, ...colProps } = getColumnProps(c);
            return (
              <div
                key={c.id}
                className={cx(getColumnClasses(c), c.className, colClass)}
                role="cell"
                {...colProps}
              >
                {c.loadingCell ? (
                  c.loadingCell(c, i)
                ) : c.type === "icon" ? (
                  <SkeletonIcon
                    variant={i % 2 === 0 ? "dark" : "base"}
                    skeletonClassName={styles.skeleton}
                    style={{ fontSize: colProps?.style?.fontSize }}
                  />
                ) : (
                  <SkeletonText
                    variant={i % 2 === 0 ? "dark" : "base"}
                    randomWidth
                    skeletonClassName={styles.skeleton}
                  />
                )}
              </div>
            );
          })}
          <SkeletonLoader variant="inverse" key={"skeleton-loader-overlay"} />
        </DefaultRow>
      ))}
    </>
  );
};

// const TablePlaceholderOLD = ({
//   loading,
//   noDataPlaceholder = "No Data",
//   loadingPlaceholder = "Loading...",
//   placeholderHeight = 45,
//   loadingHeight = 45,
// }: {
//   loading: boolean;
//   noDataPlaceholder?: string;
//   loadingPlaceholder?: string;
//   placeholderHeight?: number;
//   loadingHeight?: number;
// }) => (
//   <div
//     className={cx(styles.placeholder, loading && styles.loading)}
//     style={{ height: loading ? loadingHeight : placeholderHeight }}
//   >
//     {loading && <SkeletonLoader variant="inverse" />}
//     <div className={styles.placeholderLabel}>
//       {loading ? loadingPlaceholder : noDataPlaceholder}
//     </div>
//   </div>
// );

export default TablePlaceholder;
