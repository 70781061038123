import React, { Ref } from "react";
import LinkWrapper, {
  TLinkWrapper,
} from "components/common/ui/link-wrapper/LinkWrapper";
import styles from "components/common/layouts/appLayout/appSettingBar/AppMenuButton.module.scss";

interface Props extends Partial<TLinkWrapper> {
  children: React.ReactNode;
  "aria-label"?: string;
}

const AppMenuButton = React.forwardRef(
  ({ children, onClick, "aria-label": ariaLabel, to }: Props, ref: Ref<any>) =>
    onClick ? (
      <button
        className={styles.button}
        onClick={onClick}
        ref={ref}
        aria-label={ariaLabel}
      >
        {children}
      </button>
    ) : (
      <div className={styles.button} ref={ref} aria-label={ariaLabel}>
        <LinkWrapper to={to}>{children}</LinkWrapper>
      </div>
    )
);

export default AppMenuButton;
