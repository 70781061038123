import React from "react";
import Meta from "components/common/Meta";
import AuthLayout from "components/common/layouts/AuthLayout";
import HeroButton from "components/common/ui/hero-button/HeroButton";
import { SUPPORT_EMAIL } from "constants/site";
import { HOME } from "constants/routes";
import { coreService } from "services/CoreService";

const ViewNotFound = () => (
  <AuthLayout variant={"bottle"}>
    <Meta title={"Not Found"} />

    <h1>Page Not Found</h1>
    <p>
      Sorry, it seems you were trying to access a page that doesn't exist.
      <br />
      <br />
      Please check the spelling of the URL you were trying to access and try
      again.
      <br />
      <br />
      If you keep having trouble, please contact{" "}
      <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>
    </p>
    <div style={{ paddingTop: 16 }}>
      <HeroButton
        to={coreService.getRoute(HOME)}
        theme={"secondary"}
        variant="outline"
      >
        Back to Home
      </HeroButton>
    </div>
  </AuthLayout>
);

export default ViewNotFound;
