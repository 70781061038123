import React, { useState } from "react";
import { Activity } from "models/cpd/Activity";
import { Goal } from "models/cpd/Goal";
import { PlanYear } from "models/cpd/Plan";
import styles from "./ActivityDetail.module.scss";
import FormActions from "components/common/ui/form-elements/form-layout/FormActions";
import Button from "components/common/ui/button/Button";
import ActivityEditForm from "components/cpd/activity/edit/ActivityEditForm";
import Chip, { IconChip } from "components/common/ui/chip/Chip";
import ActivityDetailFormatted from "components/cpd/activity/ActivityDetailFormatted";
import { useActivityDetail } from "components/cpd/hooks/useActivityDetail";
import { useSelector } from "react-redux";
import { permissionsSelector } from "store/selectors/auth.selectors";
import { canAdminEdit } from "utils/authUtils";
import IconLock from "components/common/icons/IconLock";
import IconPaperclip from "components/common/icons/IconPaperclip";
import IconAsterisk from "components/common/icons/IconAsterisk";
import { getOverdueStatus } from "components/cpd/utils/utils";

export interface ActivityEditState {
  activity: Partial<Activity>;
  editing?: boolean;
}

interface Props {
  activity?: Partial<Activity>;
  planId?: string;
  userId: string;
  goals: Goal[];
  year: PlanYear;
  onCancel: () => void;
  disabled?: boolean;
  editing?: boolean;
}

const ActivityDetail = ({
  onCancel,
  activity,
  userId,
  goals = [],
  year,
  disabled,
  editing: initialState,
  ...rest
}: Props) => {
  const permissions = useSelector(permissionsSelector);
  const [editing, setEditting] = useState<boolean>(!!initialState && !disabled);
  const [activityId, setActivityId] = useState<undefined | string>(
    activity?.id
  );
  const isAdmin = canAdminEdit(permissions);

  const { isLoading, data: activityDetail } = useActivityDetail(
    activityId || "",
    userId,
    { enabled: !!activityId, placeholderData: activity }
  );

  const locked =
    year.locked || activity?.locked || activity?.waive_req || disabled;
  const requiredStatus = activity && getOverdueStatus(activity).level;

  return (
    <div>
      <div className={styles.header}>
        <div className={styles.heading}>
          <h1>{editing ? (activityId ? "Edit" : "Add") : "View"} Activity </h1>
          <span style={{ display: "flex" }}>
            {activity?.waive_req && (
              <Chip
                condensed
                theme="info"
                variant="filled"
                className={styles.waivedChip}
              >
                Waived
              </Chip>
            )}
            {requiredStatus && (
              <IconChip theme={requiredStatus} className={styles.noShrink}>
                <IconAsterisk />
              </IconChip>
            )}
            {locked && (
              <IconChip theme="danger" className={styles.noShrink}>
                <IconLock />
              </IconChip>
            )}
            {activity?.hasAttachments && (
              <IconChip theme="secondary" className={styles.noShrink}>
                <IconPaperclip />
              </IconChip>
            )}
          </span>
        </div>

        <span className={styles.headerYear}>Year {year.year}</span>
      </div>

      {editing && (!locked || isAdmin) ? (
        <ActivityEditForm
          userId={userId}
          activity={activityDetail}
          onCancel={onCancel}
          goals={goals || []}
          year={year}
          disabled={disabled}
          setActivityId={setActivityId}
          locked={locked}
          isAdmin={isAdmin}
          {...rest}
        />
      ) : (
        <>
          {activityDetail && (
            <ActivityDetailFormatted
              activity={activityDetail}
              userId={userId}
              goals={goals}
              year={year}
              isLoading={isLoading}
              locked={locked}
              isAdmin={isAdmin}
            />
          )}

          <FormActions>
            <Button variant="empty" onClick={onCancel}>
              Close
            </Button>
            {(!locked || isAdmin) && (
              <Button rounded theme="primary" onClick={() => setEditting(true)}>
                Edit Activity
              </Button>
            )}
          </FormActions>
        </>
      )}
    </div>
  );
};

export default ActivityDetail;
