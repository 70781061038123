interface IConfig extends Omit<NumberFormatOptions, "style"> {
  decimalPlaces?: number;
  significantFigures?: number;
  type?: TFormatType;
}

export const customNumberFormat = (
  value: TNumberValue,
  { decimalPlaces = 0, significantFigures, type, ...rest }: IConfig
): string => {
  const v = Number(value) as number;
  if (v) {
    return Intl.NumberFormat(undefined, {
      ...rest,
      style: !type || type === "number" ? "decimal" : type,
      maximumFractionDigits: decimalPlaces,
      minimumSignificantDigits: significantFigures,
    }).format(v);
  }
  return `${value}`;
};

export const formatNumber = (
  value: TNumberValue,
  decimalPlaces: number = 0,
  config?: IConfig
) =>
  customNumberFormat(value, {
    ...config,
    decimalPlaces,
    type: "number",
  });

export const formatPercent = (
  value: TNumberValue,
  decimalPlaces: number = 0,
  config?: IConfig
) =>
  customNumberFormat(value, {
    decimalPlaces,
    ...config,
    type: "percent",
  });

export const numberToFixedLengthString = (
  value: number,
  length: number = 3,
  config?: IConfig
) =>
  customNumberFormat(value, {
    useGrouping: false,
    minimumIntegerDigits: length,
    maximumFractionDigits: 0,
    ...config,
  });

export const formatUnit = (
  value: TNumberValue,
  unit: TUnit,
  decimalPlaces: number = 0,
  config?: IConfig
) =>
  customNumberFormat(value, {
    decimalPlaces,
    ...config,
    type: "unit",
    unit,
  });

export const formatChangeInNumber = (
  value: TNumberValue,
  decimalPlaces: number = 0,
  config?: IConfig
) =>
  customNumberFormat(value, {
    decimalPlaces,
    // @ts-ignore `exceptZero` is an accepted type, but TS currently thinks it isn't
    signDisplay: "exceptZero",
    ...config,
  });

export const formatFileSize = (size?: number) => {
  if (!size || size === 0) {
    return "0 KB";
  }
  const i = Math.floor(Math.log(size) / Math.log(1024));
  // return `${Number(size / Math.pow(1024, i)).toFixed(2)} ${
  return `${formatNumber(size / Math.pow(1024, i))} ${
    ["B", "kB", "MB", "GB", "TB"][i]
  }`;
};

type distanceOptions = "kilometer" | "meter" | "centimeter" | "millimeter";
type timeOptions =
  | "year"
  | "month"
  | "week"
  | "day"
  | "hour"
  | "minute"
  | "second"
  | "millisecond"
  | "nanosecond";
type dataOptions = "gigabyte" | "megabyte" | "byte";
type volumeOptions = "liter" | "milliliter";
type weightOptions = "kilogram" | "gram";
type temperatureOptions = "celsius" | "degree";
type speedOptions = "kilometer-per-hour" | "meter-per-second";

type TUnit =
  | distanceOptions
  | timeOptions
  | dataOptions
  | volumeOptions
  | weightOptions
  | temperatureOptions
  | speedOptions;

type TFormatType = "number" | "percent" | "currency" | "unit";

type TNumberValue = number | string;

interface NumberFormatOptions {
  style?: TFormatType;
  unit?: TUnit;
  unitDisplay?: "short" | "long" | "narrow";
  useGrouping?: boolean;

  currency?: string;
  currencyDisplay?: "symbol" | "narrowSymbol" | "code" | "name";
  currencySign?: "standard" | "accounting";

  notation?: "standard" | "scientific" | "engineering" | "compact";
  compactDisplay?: "short" | "long";
  // signDisplay?: "auto" | "never" | "always" | "exceptZero";
  signDisplay?: "auto" | "never" | "always";

  minimumIntegerDigits?: number;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
  minimumSignificantDigits?: number;
  maximumSignificantDigits?: number;

  localeMatcher?: "best fit" | "lookup";
}
