import React, { useEffect } from "react";
import Meta from "components/common/Meta";
import { useHistory } from "react-router";
import { AppContentContainer } from "components/common/layouts/appLayout/AppContentContainer";
import { create, create as blankUser, TUser } from "models/user/User";
import { USER_ROUTES } from "constants/routes/user";
import Container from "components/common/layouts/Container";
import { coreService } from "services/CoreService";
import { useMutateUser } from "components/users/hooks/useMutateUserDetail";
import UserCreateForm from "components/users/admin/create/UserCreateForm";

const ViewAdminCreateUser = () => {
  const history = useHistory();
  const { mutate, data, isSuccess } = useMutateUser();

  useEffect(() => {
    if (isSuccess && data && data.id) {
      history.push(
        coreService.getRoute(USER_ROUTES.ADMIN_EDIT_USER, { id: data.id })
      );
    }
  }, [isSuccess, data, history]);

  const onSave = async (newUser: Partial<TUser>) => {
    await mutate({
      newUser,
      oldUser: blankUser(),
    });
  };

  const onCancel = () => {
    history.push(coreService.getRoute(USER_ROUTES.ADMIN_USERS));
  };
  return (
    <AppContentContainer
      spacing="none"
      backButton={{
        to: coreService.getRoute(USER_ROUTES.ADMIN_USERS),
        label: "Back to Users",
      }}
    >
      <Meta title="Create User" />
      <br />
      <br />
      <br />
      <Container>
        <h1>Create New User</h1>
        <UserCreateForm
          user={create()}
          onSubmit={onSave}
          onCancel={onCancel}
          saveText={"Invite New User"}
          title={"Create User"}
        />
      </Container>
    </AppContentContainer>
  );
};

export default ViewAdminCreateUser;
