import { useMutation } from "react-query";
import { resendInvite } from "api/UserApi";
import axios from "axios";
import { MutationOptions } from "queries/queries";
import { useToast } from "components/common/ui/toast/useToast";

export const useMutateResendInvitation = (options: MutationOptions = {}) => {
  // const queryClient = useQueryClient();
  const { addErrorToast, addToast } = useToast();

  return useMutation(({ user }) => resendInvite(user), {
    onSuccess: (result, inputs) => {
      addToast(`${inputs.user.email} was successfully resent an invite!`, {
        theme: "success",
      });

      // queryClient.invalidateQueries(userQueryKeys.detail(inputs.oldUser.id));
      // queryClient.invalidateQueries(userQueryKeys.list(inputs.orgId));
    },
    onError: (e) => {
      if (axios.isAxiosError(e)) {
        addErrorToast(e, "Error: Unable to send invite");
      } else {
      }
    },
    ...options,
  });
};
