import React from "react";
import cx from "classnames";
import styles from "./StepProgress.module.scss";

interface IStepper extends DefaultProps {
  stops: number;
  labels?: string[];
  current: number;
}

const StepProgress = ({
  stops,
  labels,
  current,
  className,
  ...rest
}: IStepper) => (
  <div
    className={cx(styles.root, labels && styles.labels, className)}
    {...rest}
  >
    <div className={styles.dotWrap}>
      {[...Array(stops)].map((e, i) => (
        <span
          className={cx(
            styles.dot,
            i < current && styles.prev,
            i === current && styles.active
          )}
          key={i}
        >
          <span>
            <label>{labels?.[i]}</label>
          </span>
        </span>
      ))}
      <div className={styles.line} />
      <div
        className={cx(styles.line, styles.active)}
        style={{
          width: `calc(${Math.min(current / (stops - 1), 1) * 100}% - 5px)`,
        }}
      />
    </div>
  </div>
);

export default StepProgress;
