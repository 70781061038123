import React from "react";
import cx from "classnames";
import Menu, { MenuItem } from "components/common/ui/menu/Menu";
import AppMenuButton from "components/common/layouts/appLayout/appSettingBar/AppMenuButton";
import LinkWrapper, {
  TLinkWrapper,
} from "components/common/ui/link-wrapper/LinkWrapper";
import styles from "components/common/layouts/appLayout/appSettingBar/AppSettingsMenu.module.scss";

interface IMenuOption extends Partial<TLinkWrapper> {
  key: string;
  children: React.ReactNode;
}

const AppSettingsMenu = ({
  header,
  options,
  children,
  "aria-label": aria,
}: {
  header?: React.ReactNode;
  options: IMenuOption[];
  children: React.ReactNode;
  "aria-label"?: string;
}) => (
  <Menu
    offset={-0}
    direction={"bottom-end"}
    content={
      <>
        {header && <div>{header}</div>}
        {options.map((o) => (
          <MenuItem className={styles.noSpacingMenuItem} key={o.key}>
            <LinkWrapper
              {...o}
              className={cx(o.className, styles.menuItemButton)}
            />
          </MenuItem>
        ))}
      </>
    }
  >
    <AppMenuButton aria-label={aria}>{children}</AppMenuButton>
  </Menu>
);

export default AppSettingsMenu;
