import React from "react";
import { motion } from "framer-motion";
import cx from "classnames";
import Footer from "components/common/layouts/appLayout/footer/Footer";
import styles from "./AppContentContainer.module.scss";
import { useSelector } from "react-redux";
import PageBackButton, {
  IPageBackButton,
} from "components/common/layouts/appLayout/PageBackButton";
import SkeletonLoader from "components/common/ui/skeleton/SkeletonLoader";
import {
  isDesktopSelector,
  isExpandedSelector,
  isMobileOpenedSelector,
} from "store/selectors/layout.selectors";

export const AppContentContainer = (props: {
  className?: string;
  spacing?: "none" | "small" | "normal" | "large" | "extra" | "fullscreen";
  maxWidth?: boolean;
  backButton?: IPageBackButton;
  children: any;
  style?: TObjectAny;
  backgroundImage?: string;
  backgroundColor?: "none" | "white" | "light-grey";
  noAnimation?: boolean;
  loadingSkeleton?: boolean;
}) => {
  const isDesktop = useSelector(isDesktopSelector);
  const isMobileOpened = useSelector(isMobileOpenedSelector);
  const isExpanded = useSelector(isExpandedSelector);

  const containerAnimation = props.noAnimation ? {} : anim;
  return (
    <>
      <motion.div className={styles.contentShadow} {...shadowAnimations} />
      <div
        className={cx(
          props.className,
          styles.contentContainer,
          isDesktop && styles.isDesktop,
          isMobileOpened && styles.mobileOpened,
          isExpanded && styles.isExpanded,
          styles[`background-${props.backgroundColor || "white"}`]
        )}
        style={props.style}
      >
        {props.backButton && (
          <PageBackButton {...props.backButton}>
            {props.backButton.label}
          </PageBackButton>
        )}
        <motion.main
          className={cx(
            styles.content,
            props.spacing && styles[`spacing-${props.spacing}`],
            props.maxWidth && styles.max,
            props.backgroundImage && styles.backgroundImageContent
          )}
          {...containerAnimation}
        >
          {props.loadingSkeleton ? (
            <SkeletonLoader className={styles.skeleton} angle="angled" />
          ) : (
            props.children
          )}
        </motion.main>
        {props.backgroundImage && (
          <img
            src={props.backgroundImage}
            className={styles.backgroundImage}
            alt={`decoration-background`}
          />
        )}
      </div>
      <Footer />
    </>
  );
};
const anim = {
  initial: { opacity: 0, y: -20 },
  animate: {
    x: 0,
    y: 0,
    opacity: 1,
    transition: { duration: 0.3, type: "easeOut", delay: 0.1 },
  },
  exit: {
    opacity: 0,
    y: 10,
    transition: { duration: 0.2, ease: "easeIn" },
  },
};
const shadowAnimations = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: { duration: 0.3, ease: "easeOut", delay: 0.8 },
  },
  exit: {
    opacity: 0,
  },
};

export default AppContentContainer;
