import React from "react";
import { Loading } from "aws-amplify-react";
import { IAuthPieceProps } from "aws-amplify-react/lib/Auth/AuthPiece";
import FullPageLoader from "components/common/ui/loader/FullPageLoader";

const exportDefault = {};
export default exportDefault;

export class CustomLoadingState extends Loading {
  constructor(props: IAuthPieceProps) {
    super(props);
    this._validAuthStates = ["loading"];
  }

  showComponent(theme: any) {
    return <FullPageLoader />;
  }
}
