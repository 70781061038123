export enum UserType {
  general = "general",
  education = "education",
  applicant = "applicant",

  fellow = "fellow",
  registrar = "registrar",
  snr_registrar = "snr_registrar",

  est_fellow = "esteemed_fellow",
  hon_fellow = "honorary_fellow",
}

const fellow = [UserType.fellow] as UserType[];
const registrar = [UserType.registrar, UserType.snr_registrar] as UserType[];
const applicant = [UserType.applicant] as UserType[];
const education = [UserType.education] as UserType[];

const clinicianMember = [...fellow, ...registrar];
const members = [...education, ...clinicianMember];
const clinicians = [...clinicianMember, ...applicant];

const nonGeneral = [...applicant, ...members];

const exceptFellow = [...registrar, ...applicant, ...education];
const exceptRegistrar = [...fellow, ...applicant, ...education];
const fellowEducation = [...fellow, ...education];
const fellowApplicant = [...fellow, ...applicant];

const potentialSupervisor = [...fellow, UserType.general];

export const CombinedUserTypes = {
  fellow,
  registrar,
  applicant,
  education,
  clinicians,
  members,
  clinicianMember,
  nonGeneral,
  exceptFellow,
  exceptRegistrar,
  fellowEducation,
  fellowApplicant,
  potentialSupervisor,
} as const;
