import React from "react";
import { useParams } from "react-router";
import { coreService } from "services/CoreService";
import { AppContentContainer } from "components/common/layouts/appLayout/AppContentContainer";
import { PEERGROUP_ROUTES } from "constants/routes/peergroup";
import Container from "components/common/layouts/Container";
import Button from "components/common/ui/button/Button";
import { canAdminEdit } from "utils/authUtils";
import { useSelector } from "react-redux";
import { permissionsSelector } from "store/selectors/auth.selectors";
import { usePeerGroupMeetings } from "components/peergroup/hooks/usePeerGroupMeetings";
import PeerGroupMeetingDetail from "components/peergroup/detail/PeerGroupMeetingDetail";
import { ROUTE_PARAMS } from "constants/routes/common";
import { usePeerGroupMembers } from "components/peergroup/hooks/usePeerGroupMembers";
import { currentUserSelector } from "store/selectors/core.selectors";
import Meta from "components/common/Meta";
import { isCurrentUserPeerGroupCoordinator } from "components/peergroup/utils/checkPeerGroupMembership";
import styles from "./PeerGroupMeetingsList.module.scss";

const ViewPeerGroupMeeting = () => {
  const { peergroupId, meetingId } = useParams<{
    [ROUTE_PARAMS.PEERGROUP]: string;
    [ROUTE_PARAMS.MEETING]: string;
  }>();
  const permissions = useSelector(permissionsSelector);
  const isAdmin = canAdminEdit(permissions);
  const { data } = usePeerGroupMeetings(peergroupId);
  const { data: members } = usePeerGroupMembers(peergroupId);
  const currentUser = useSelector(currentUserSelector);
  const canEditPeerGroupMeeting =
    isCurrentUserPeerGroupCoordinator(members?.accepted, currentUser.id) ||
    isAdmin;

  return (
    <AppContentContainer
      spacing="none"
      // backgroundColor="light-grey"
      backButton={{
        to: coreService.getRoute(PEERGROUP_ROUTES.PEERGROUP_DETAIL, {
          peergroupId: peergroupId,
        }),
        label: "Back to Peer Group Detail",
      }}
    >
      <Meta
        title={
          data?.meetingsById?.[meetingId].peergroup_meeting_title ??
          "Peer Group Meeting"
        }
      />
      <Container spacing={"extra"} spacingTopOnly>
        <div className={styles.header}>
          <h1>{data?.meetingsById?.[meetingId].peergroup_meeting_title}</h1>
          {canEditPeerGroupMeeting && (
            <Button
              rounded
              theme="primary"
              to={`${coreService.getRoute(
                PEERGROUP_ROUTES.EDIT_PEERGROUP_MEETING,
                {
                  [ROUTE_PARAMS.PEERGROUP]: peergroupId,
                  [ROUTE_PARAMS.MEETING]: meetingId,
                }
              )}`}
            >
              Edit Peer Group Meeting
            </Button>
          )}
        </div>
      </Container>{" "}
      <PeerGroupMeetingDetail meetingId={meetingId} peergroupId={peergroupId} />
    </AppContentContainer>
  );
};

export default ViewPeerGroupMeeting;
