export const kebabCase = (str: string) => cleanCase(str);

export const camelCase = (str: string) =>
  cleanCase(str).replace(/[-]+(\w|$)/g, (_, p1) => p1.toUpperCase());

export const pascalCase = (str: string) => {
  let temp = camelCase(str);
  return temp.charAt(0).toUpperCase() + temp.slice(1);
};

export const snakeCase = (str: string) => cleanCase(str).replace(/-/g, "_");

const cleanCase = (str: string) =>
  str
    //convert non-alphanumeric characters to dash (possibly too aggressive)
    .replace(/[^a-zA-Z0-9:]/g, "-") // alternative // str = str.replace(/[_\s.'-,]/g,'-')
    //add dash in front of capitals
    .replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, "$1-$2")
    //remove duplicate dashes
    .replace(/([-])\1+/g, "-")
    //remove dash from front and end of str
    .replace(/^[-]/, "")
    .replace(/[-]$/, "")
    //lowercase string
    .toLowerCase();

export const cleanOrderedLabel = (label: string | number): string => {
  const s = `${label}`;
  let parts = s.split("@");
  if (parts.length === 1) {
    return s;
  }
  parts.shift();
  return parts.join("@");
};
