import { TBucket } from "models/Dimension";
import { request } from "utils/http/Request";
import {
  PEERGROUPS,
  getApiUrl,
  PEERGROUP_MEMBERS,
  PEERGROUP_DETAIL,
  PEERGROUP_MEETINGS,
  PEERGROUP_MEETINGS_DETAIL,
  USER_PEERGROUP_MEETINGS,
  ADMIN_PEERGROUPS,
  PEERGROUP_MEETING_GET_DOCS,
  PEERGROUP_MEETING_DETAIL_DOCS,
} from "constants/paths";
import {
  fromApiToPeerGroup,
  IServerPeerGroup,
  PeerGroup,
  PeerGroupListShape,
  toServerObject,
} from "models/peergroup/PeerGroup";
import { keyBy, nest } from "utils/common";
import {
  PeerGroupMeeting,
  IServerPeerGroupMeeting,
  toServerMeetingObject,
  fromApiToPeerGroupMeeting,
} from "models/peergroup/meeting";
import {
  fromApiToPeerGroupMember,
  PeerGroupMember,
} from "models/peergroup/member";
import axios from "axios";
import fileDownload from "js-file-download";
import { fromS3SafeName, toS3SafeName } from "components/cpd/utils/fileUtils";

interface meetingApi {
  meetingKeys: string[];
  meetingsById: Record<string, PeerGroupMeeting>;
  meetings: PeerGroupMeeting[];
}

export const fetchMany = async (
  filter: Record<string, TBucket>
): Promise<PeerGroupListShape> => {
  const data = await request.get(getApiUrl(PEERGROUPS, {}), filter);
  const formatted = data.data.map(fromApiToPeerGroup) as PeerGroup[];

  return nest(formatted, [(d) => (d.peergroup_deleted ? "deleted" : "active")]);
};
export const fetchManyByAdmin = async (
  filter: Record<string, TBucket>
): Promise<PeerGroupListShape> => {
  const data = await request.get(getApiUrl(ADMIN_PEERGROUPS, {}), filter);
  const formatted = data.data.map(fromApiToPeerGroup) as PeerGroup[];

  return nest(formatted, [(d) => (d.peergroup_deleted ? "deleted" : "active")]);
};

export const fetchPeerGroupDetails = async (
  peergroupId: string
): Promise<{
  // peergroup_members: PeerGroupMember;
  // peergroup_meetings: meetingApi;
}> => {
  const data = await request.get(getApiUrl(PEERGROUP_DETAIL, { peergroupId }));
  // const peergroup_members = await fetchMembersById(peergroupId);
  // const peergroup_meetings = await fetchMeetingsByPeergroupId(peergroupId);
  // return { ...data, members, meetings };
  return {
    ...fromApiToPeerGroup(data.data[0] as IServerPeerGroup),
    // peergroup_members,
    // peergroup_meetings,
  };
  // return fromApiToPeerGroup(data.data[0] as IServerPeerGroup);
};

export const fetchMembersByPeergroupId = async (
  peergroupId: string
): Promise<Record<string, PeerGroupMember[]>> => {
  const data = await request.get(getApiUrl(PEERGROUP_MEMBERS, { peergroupId }));
  // return data.data;
  const formatted = data.data.map(fromApiToPeerGroupMember);
  return nest(formatted, [
    (d) => (!d.peergroup_member_deleted ? "accepted" : "deleted"),
  ]);
};

export const fetchMeetingsByPeergroupId = async (
  peergroupId: string
): Promise<meetingApi> => {
  const data = (await request.get(
    getApiUrl(PEERGROUP_MEETINGS, { peergroupId })
  )) as { data: PeerGroupMeeting[] };
  // const test = fromApiToPeerGroupMeeting(data as IServerPeerGroupMeeting);
  // return data.data;
  const formatted = data.data.map(fromApiToPeerGroupMeeting);

  // return {
  //   meetingKeys: data.data.map((m) => m.id),
  //   meetingsById: keyBy(data.data, "id"),
  // };
  return {
    meetingKeys: formatted.map((m) => m.id),
    meetingsById: keyBy(formatted, "id"),
    meetings: formatted,
  };
};

export const fetchMeetingById = async (
  meetingId: string,
  peergroupId: string
): Promise<PeerGroupMeeting> => {
  const data = (await request.get(
    getApiUrl(PEERGROUP_MEETINGS_DETAIL, { peergroupId, meetingId })
  )) as { data: PeerGroupMeeting[] };
  //
  // return data.data[0];
  return fromApiToPeerGroupMeeting(data.data[0]);
};

export const savePeerGroup = async (
  { id: peergroupId, ...peergroup }: Partial<PeerGroup>,
  oldPeerGroup: PeerGroup
): Promise<PeerGroup> => {
  if (peergroupId) {
    await request.put(
      getApiUrl(PEERGROUP_DETAIL, { peergroupId }),
      toServerObject(peergroup)
    );
    return { ...oldPeerGroup, ...peergroup, id: peergroupId };
  }
  const newPeerGroup = await request.post(
    getApiUrl(PEERGROUPS, {}),
    toServerObject(peergroup)
  );
  return fromApiToPeerGroup(newPeerGroup as IServerPeerGroup);
};

// export const savePeerGroupMeeting = async (
//   { ...peergroup_meeting }: Partial<PeerGroupMeeting>,
//   peergroupId: string
// ): Promise<PeerGroupMeeting> => {
//   await request.post(
//     getApiUrl(PEERGROUP_MEETINGS, { peergroupId }),
//     toServerMeetingObject(peergroup_meeting)
//   );
//   return { ...peergroup_meeting, id: peergroupId };
// };

export const savePeerGroupMeeting = async (
  { id: peergroupMeetingId, ...peergroup_meeting }: Partial<PeerGroupMeeting>,
  oldPeerGroup: PeerGroupMeeting,
  peergroupId: string,
  meetingId?: string
): Promise<PeerGroupMeeting> => {
  if (meetingId) {
    await request.put(
      getApiUrl(PEERGROUP_MEETINGS_DETAIL, { peergroupId, meetingId }),
      toServerMeetingObject(peergroup_meeting)
    );
    return { ...oldPeerGroup, ...peergroup_meeting, id: meetingId };
  }
  const newPeerGroupMeeting = await request.post(
    getApiUrl(PEERGROUP_MEETINGS, { peergroupId }),
    toServerMeetingObject(peergroup_meeting)
  );
  return fromApiToPeerGroupMeeting(
    newPeerGroupMeeting as IServerPeerGroupMeeting
  );
};

export const deletePeerGroup = async (
  peergroup: PeerGroup,
  accepted: boolean
): Promise<PeerGroup> => {
  await request.put(
    getApiUrl(PEERGROUP_DETAIL, { peergroupId: peergroup.id }),
    {
      peergroup_deleted: !accepted,
    }
  );
  return { ...peergroup, accepted };
};

export const updateMemberPeerGroup = async ({
  user_id,
  peergroup_role_id,
  peergroupId,
  current_peergroup_role_id,
  deleted = false,
}: {
  user_id: string;
  peergroup_role_id: string;
  peergroupId: string;
  current_peergroup_role_id?: string;
  deleted?: boolean;
}) => {
  if (current_peergroup_role_id !== undefined) {
    await request.put(getApiUrl(PEERGROUP_MEMBERS, { peergroupId }), {
      user_id,
      peergroup_role_id,
      current_peergroup_role_id,
      peergroup_member_deleted: deleted,
    });
  } else {
    await request.post(getApiUrl(PEERGROUP_MEMBERS, { peergroupId }), {
      peergroup_role_id,
      user_id,
    });
  }

  return { user_id, peergroup_role_id, peergroupId, current_peergroup_role_id };
};

export const fetchManyMeetings = async (
  //fixme: check type here
  filters: any,
  userId: string
): Promise<PeerGroupMeeting[]> => {
  const data = await request.get(
    getApiUrl(USER_PEERGROUP_MEETINGS, {
      userId,
      end: filters.end,
      start: filters.start,
    })
  );

  return data.data;
};

export const fetchMeetingUploadDoc = async (
  meetingId: string,
  peergroupId: string,
  filename: string
) => {
  const signedUrl = await request.get(
    getApiUrl(PEERGROUP_MEETING_GET_DOCS, {
      meetingId,
      peergroupId,
      filename: filename,
    })
  );
  console.log(signedUrl.url, "check meeting file");
  return await axios
    .get(signedUrl.url, {
      responseType: "blob",
    })
    .then((res) => {
      fileDownload(res.data, fromS3SafeName(filename));
    });
};

export const uploadMeetingDoc = async (
  file: File,
  meetingId: string,
  peergroupId: string
) => {
  try {
    const removedSpacedFile = new File([file], toS3SafeName(file.name));
    const signedUrl = await request.post(
      getApiUrl(PEERGROUP_MEETING_DETAIL_DOCS, { meetingId, peergroupId }),
      {
        filename: removedSpacedFile.name,
      }
    );

    return await axios.put(signedUrl.url, removedSpacedFile, {
      headers: {
        // contentType: file.type,
      },
    });
  } catch (e) {
    return e;
  }
};

export const deleteMeetingDoc = async (
  filename: string,
  meetingId: string,
  peergroupId: string
): Promise<any> => {
  if (filename) {
    return await request.delete(
      getApiUrl(PEERGROUP_MEETING_GET_DOCS, {
        peergroupId,
        meetingId,
        filename,
      })
    );
  } else {
    throw new Error("No meeting id provided");
  }
};
