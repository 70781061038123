import { queryKeyFactory } from "queries/utils";
import { TBucket } from "models/Dimension";

export enum queryKeys {
  users = "users",
  facility = "facilities",
  plans = "plans",
  activity = "activity",
  goals = "goals",
  peerGroup = "peerGroup",
  supervisors = "supervisors",
}

export const userQueryKeys = queryKeyFactory(queryKeys.users);

// TODO: find a better way of extending `queryKeyFactory`

export const facilityQueryKeys = {
  ...queryKeyFactory(queryKeys.facility),
  users: (id) => [...facilityQueryKeys.detail(id), "users"],
} as { users: (id: string) => string[] } & ReturnType<typeof queryKeyFactory>;

export const planQueryKeys = {
  ...queryKeyFactory(queryKeys.plans),
  activity: (id) => [...planQueryKeys.detail(id), "activity"],
} as { activity: (id: string) => string[] } & ReturnType<
  typeof queryKeyFactory
>;

export const activityQueryKeys = queryKeyFactory(queryKeys.activity);
export const goalQueryKeys = queryKeyFactory(queryKeys.goals);
// export const peerGroupQueryKeys = queryKeyFactory(queryKeys.peerGroup);

export const peerGroupQueryKeys = {
  ...queryKeyFactory(queryKeys.peerGroup),
  members: (id) => [...peerGroupQueryKeys.detail(id), "members"],
  meetings: (id) => [...peerGroupQueryKeys.detail(id), "meetings"],
  adminPeergroups: () => [...peerGroupQueryKeys.list(), "adminPeergroups"],
} as {
  meetings: (id: string) => string[];
  members: (id: string) => string[];
  //TODO: handle better queryKey here;
  adminPeergroups: (filters?: Record<string, TBucket>) => any;
} & ReturnType<typeof queryKeyFactory>;

export const superviseesQueryKeys = {
  ...queryKeyFactory(queryKeys.supervisors),
  supervisee: (id) => [...superviseesQueryKeys.detail(id), "supervisee"],
  goals: (id: string) => [...superviseesQueryKeys.detail(id), "goals"],
  plans: (id: string) => [...superviseesQueryKeys.detail(id), " plans"],
  activities: (id: string) => [
    ...superviseesQueryKeys.detail(id),
    "activities",
  ],
} as {
  supervisee: (id: string) => string[];
  goals: (id: string) => string[];
  plans: (id: string) => string[];
  activities: (id: string) => string[];
} & ReturnType<typeof queryKeyFactory>;
