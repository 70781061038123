import React from "react";
import { Controller } from "react-hook-form";
import { DatePicker } from "components/common/ui/form-elements/date-picker/DatePicker";
import {
  FormQuestionProps,
  TError,
} from "components/common/ui/form-elements/formElements";

export const DateHookForm = <T extends string>({
  control,
  errors,
  ...props
}: FormQuestionProps<T>) => {
  const datetimeProps =
    props.question.type === "datetime"
      ? {
          placeholder: "DD/MM/YYYY HH:mm",
          dateFormat: "MMMM d, yyyy h:mm aa",
          showTimeSelect: true,
        }
      : {};
  return (
    <Controller
      name={props.question.name}
      control={control}
      // defaultValue={new Date()}
      render={({ field: { onChange, ...rest } }) => {
        const onSelect = (v: Date) => {
          onChange(v);
        };

        return (
          <DatePicker
            label={props.question.label}
            onChange={onSelect}
            error={errors as TError}
            {...datetimeProps}
            {...props}
            {...rest}
          />
        );
      }}
    />
  );
};
