import React, { useState } from "react";
import { useLayer } from "react-laag";
import {
  useCombobox,
  UseComboboxState,
  UseComboboxStateChangeOptions,
  useMultipleSelection,
  UseMultipleSelectionStateChange,
} from "downshift";
import { InputWrapper } from "components/common/ui/form-elements/common/InputWrapper";
import IconSearch from "components/common/icons/IconSearch";
import ResizeObserver from "resize-observer-polyfill";
import { MenuContext } from "components/common/ui/form-elements/autocomplete/MenuContext";
import { IMenuItem } from "components/common/ui/form-elements/autocomplete/MenuItem";
import Menu from "components/common/ui/form-elements/autocomplete/Menu";
import {
  getPredictions,
  isSelected,
  SearchFunction,
} from "components/common/ui/form-elements/autocomplete/utils";
import { isObject } from "utils/common";
import ActiveOptionChip from "components/common/ui/select/ActiveOptionChip";
import ActionButton from "components/common/ui/button/ActionButton";
import styles from "./Autocomplete.module.scss";
import cx from "classnames";
import { TError } from "components/common/ui/form-elements/formElements";

interface Props<Item> extends DefaultProps {
  name: string;
  label?: string;
  description?: string;
  disabled?: boolean;
  items: Item[];
  onSearch?: SearchFunction<Item>;
  selected: Item[];
  onSelect: (changes: UseMultipleSelectionStateChange<Item>) => void;
  searchValueKey?: keyof Item;

  children?: (provided: { items: Item[] }) => React.ReactNode;
  // children?: React.ReactNode;
  className?: string;
  /** The direction of the tooltip relative to the element (will move if there isn't enough space) */
  direction?:
    | "top-center"
    | "top-start"
    | "top-end"
    | "left-start"
    | "left-center"
    | "left-end"
    | "right-start"
    | "right-center"
    | "right-end"
    | "bottom-start"
    | "bottom-center"
    | "bottom-end"
    | "center";

  /** Should the arrow show */
  arrow?: boolean;

  /** How much should the tooltip be offset from the element */
  offset?: number;

  /** Override hover trigger and force open */
  // open?: boolean;

  /** Framer motion animation object */
  animation?: {
    initial: object;
    animate: object;
    exit: object;
    transition: object;
  };
  error?: TError;
}

const MultiSelectCombobox = React.memo(
  ({
    name,
    children,
    className,
    onSearch,
    selected,
    onSelect,
    searchValueKey = "value",
    direction = "bottom-center",
    offset = 8,
    disabled,
    items,
    label,
    description,
    error,
  }: Props<IMenuItem>) => {
    const [filteredItems, setFilteredItems] = useState(items);
    const [inputValue, setInputValue] = useState<string | undefined>("");
    const options = onSearch ? items : filteredItems;
    const search = onSearch || getPredictions;

    const {
      getSelectedItemProps,
      getDropdownProps,
      addSelectedItem,
      removeSelectedItem,
      setSelectedItems,
      selectedItems,
    } = useMultipleSelection({
      initialSelectedItems: selected as IMenuItem[],
      selectedItems: selected as IMenuItem[],
      onSelectedItemsChange: onSelect,
      itemToString: (d: any) => (isObject(d) ? d?.value : d),
    });

    const update = (selectedItem: IMenuItem) => {
      const value = selectedItem.value;
      if (isSelected(value, selected)) {
        const newSelected = selected.filter(
          (s) => s.value !== selectedItem.value
        );
        setSelectedItems(newSelected);
      } else {
        addSelectedItem(selectedItem);
      }
    };

    const {
      isOpen,
      getMenuProps,
      getLabelProps,
      getInputProps,
      getComboboxProps,
      getItemProps,
      getToggleButtonProps,
      highlightedIndex,
      // inputValue,
    } = useCombobox({
      inputValue,
      items: options,
      defaultHighlightedIndex: 0, // after selection, highlight the first item.
      itemToString: (d: any) => (isObject(d) ? d?.value : d),
      onInputValueChange: ({ inputValue }) => {
        if (!onSearch) {
          setFilteredItems(search(inputValue, items, searchValueKey) || []);
        } else {
          search(inputValue);
        }
      },
      selectedItem: null,
      onStateChange: ({ inputValue, type, selectedItem }) => {
        switch (type) {
          case useCombobox.stateChangeTypes.InputChange:
            setInputValue(inputValue);
            break;
          case useCombobox.stateChangeTypes.InputKeyDownEnter:
          case useCombobox.stateChangeTypes.ItemClick:
          case useCombobox.stateChangeTypes.InputBlur:
            setInputValue("");
            if (selectedItem) {
              // TODO: fix hacky workaround for adding/removing items
              update(selectedItem);
            }
            break;
          default:
            break;
        }
      },
      stateReducer: comboboxStateReducer,
    });

    // the positioning stuff...
    const { renderLayer, triggerProps, layerProps, triggerBounds, layerSide } =
      useLayer({
        isOpen,
        overflowContainer: true, // we (don't?) want the menu to stay within its scroll-container
        auto: true, // auto find a placement when required
        snap: true, // snap to the possible placements (not in between)
        placement: direction, // we prefer placement on the bottom-side
        possiblePlacements: ["top-start", "bottom-start"], // stick with bottom and top
        triggerOffset: 0, // place the menu directly to the trigger
        containerOffset: offset, // make sure the menu gets a bit of space with respect to the containers edges
        ResizeObserver,
        // onOutsideClick: toggle,
        // onDisappear: toggle,
      });
    return (
      <>
        <InputWrapper
          label={label}
          description={description}
          name={name}
          labelProps={getLabelProps()}
          inputWrapperProps={getComboboxProps()}
          className={className}
          disabled={disabled}
          error={error}
        >
          <div
            className={cx(styles.wrap, disabled && styles.disabled)}
            {...triggerProps}
          >
            {selectedItems?.map((selectedItem, index) => (
              <span
                {...getSelectedItemProps({ selectedItem, index })}
                className={styles.selected}
              >
                <ActiveOptionChip
                  id={selectedItem.value}
                  key={`selected-item-${index}`}
                  label={selectedItem.label}
                  onClick={() => {
                    removeSelectedItem(selectedItem);
                  }}
                  theme={disabled ? "grey" : "primary"}
                  cancelIcon={!disabled}
                />
              </span>
            ))}
            <ActionButton
              icon={<IconSearch />}
              variant="empty"
              className={styles.searchIcon}
              {...getToggleButtonProps()}
            />

            <input
              type={"text"}
              {...getInputProps(getDropdownProps({ preventKeyAction: isOpen }))}
              disabled={disabled}
            />

            <div className={styles.bg} />
          </div>
        </InputWrapper>
        <MenuContext.Provider
          value={{
            name,
            isOpen,
            highlightedIndex,
            selectedItem: selectedItems,
            getItemProps,
            layerSide,
            menuProps: getMenuProps(layerProps),
            triggerBounds,
            offset,
            items: options,
            query: inputValue,
            isMultiselect: true,
          }}
        >
          {renderLayer(children ? children({ items: options }) : <Menu />)}
        </MenuContext.Provider>
      </>
    );
  }
);

export const comboboxStateReducer: (
  state: UseComboboxState<IMenuItem>,
  actionAndChanges: UseComboboxStateChangeOptions<IMenuItem>
) => Partial<UseComboboxState<IMenuItem>> = (state, actionAndChanges) => {
  const { changes, type } = actionAndChanges;
  switch (type) {
    case useCombobox.stateChangeTypes.InputKeyDownEnter:
    case useCombobox.stateChangeTypes.ItemClick:
      return {
        ...changes,
        isOpen: true, // keep the menu open after selection.
      };
  }
  return changes;
};

// const filterSelected = (items: IMenuItem[], selected: IMenuItem[]) =>
//   items.filter((item) => !selected.some((s) => s.value === item.value));

export default MultiSelectCombobox;
