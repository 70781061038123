import { shallowEqual, useSelector as reduxUseSelector } from "react-redux";
import { IState } from "store";
import { ROUTE_PARAMS } from "constants/routes/common";

export const useSelector: <TSelected = unknown>(
  selector: (state: IState) => TSelected
) => TSelected = (selector) => reduxUseSelector(selector, shallowEqual);

export const useRouteSelector = (
  url: string | undefined,
  match?: { id?: string }
) =>
  reduxUseSelector((s: IState) => {
    const user = s.app.core.user.id;
    return url ? url.replace(ROUTE_PARAMS.USER, match?.id || user) : "";
  }, shallowEqual);
