import React from "react";
import cx from "classnames";
import styles from "./BrandName.module.scss";
interface Props {
  className?: string;
}

const LighthouseLogo = React.memo(({ className }: Props) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 351 361"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={cx(styles.root, className)}
  >
    <path
      d="M155.705 238.28C157.019 228.389 165.454 221 175.432 221V221C185.468 221 193.934 228.473 195.179 238.432L208.297 343.38C208.671 346.364 206.344 349 203.336 349H146.708C143.685 349 141.354 346.338 141.751 343.342L155.705 238.28Z"
      fill="#A7A2B6"
    />
    <path
      d="M155.705 238.28C157.019 228.389 165.454 221 175.432 221V221C185.468 221 193.934 228.473 195.179 238.432L208.297 343.38C208.671 346.364 206.344 349 203.336 349H146.708C143.685 349 141.354 346.338 141.751 343.342L155.705 238.28Z"
      fill="#9081BE"
    />
    <circle cx="175" cy="180" r="24" fill="#EDFAFF" />
    <path
      d="M224.868 315.855C280.975 295.416 309.904 233.321 289.483 177.164C269.062 121.006 207.023 92.0513 150.916 112.491C150.829 112.523 150.741 112.555 150.654 112.587C188.002 99.1122 229.229 118.413 242.817 155.78C256.431 193.218 237.145 234.615 199.741 248.241C162.336 261.867 120.977 242.564 107.363 205.126C94.0069 168.397 112.315 127.859 148.332 113.469C93.9262 134.851 66.1965 195.894 86.3018 251.182C106.723 307.34 168.761 336.295 224.868 315.855Z"
      fill="url(#lighthouse_logo_143523_paint0_linear)"
    />
    <path
      d="M224.868 315.855C280.975 295.416 309.904 233.321 289.483 177.164C269.062 121.006 207.023 92.0513 150.916 112.491C150.829 112.523 150.741 112.555 150.654 112.587C188.002 99.1122 229.229 118.413 242.817 155.78C256.431 193.218 237.145 234.615 199.741 248.241C162.336 261.867 120.977 242.564 107.363 205.126C94.0069 168.397 112.315 127.859 148.332 113.469C93.9262 134.851 66.1965 195.894 86.3018 251.182C106.723 307.34 168.761 336.295 224.868 315.855Z"
      fill="url(#lighthouse_logo_143523_paint1_linear)"
    />
    <path
      d="M317.165 204.626C327.533 145.772 288.27 89.6492 229.47 79.2717C170.669 68.8942 114.597 108.192 104.228 167.046C104.212 167.137 104.196 167.229 104.18 167.321C111.195 128.21 148.509 102.125 187.634 109.03C226.835 115.948 253.01 153.364 246.098 192.599C239.186 231.835 201.804 258.034 162.604 251.115C124.146 244.328 98.2253 208.189 103.783 169.775C95.0799 227.625 134.032 282.183 191.923 292.4C250.724 302.777 306.796 263.479 317.165 204.626Z"
      fill="url(#lighthouse_logo_143523_paint2_linear)"
    />
    <path
      d="M317.165 204.626C327.533 145.772 288.27 89.6492 229.47 79.2717C170.669 68.8942 114.597 108.192 104.228 167.046C104.212 167.137 104.196 167.229 104.18 167.321C111.195 128.21 148.509 102.125 187.634 109.03C226.835 115.948 253.01 153.364 246.098 192.599C239.186 231.835 201.804 258.034 162.604 251.115C124.146 244.328 98.2253 208.189 103.783 169.775C95.0799 227.625 134.032 282.183 191.923 292.4C250.724 302.777 306.796 263.479 317.165 204.626Z"
      fill="url(#lighthouse_logo_143523_paint3_linear)"
    />
    <path
      d="M268.053 69.5547C222.314 31.1407 154.123 37.112 115.744 82.8921C77.3642 128.672 83.3301 196.925 129.069 235.339C129.14 235.399 129.212 235.459 129.283 235.518C98.9501 209.882 95.0368 164.496 120.574 134.034C146.16 103.514 191.621 99.5333 222.114 125.143C252.606 150.752 256.584 196.254 230.997 226.774C205.896 256.715 161.667 261.114 131.208 237.09C176.911 273.559 243.593 267.073 281.379 222.002C319.758 176.221 313.792 107.969 268.053 69.5547Z"
      fill="url(#lighthouse_logo_143523_paint4_linear)"
    />
    <path
      d="M268.053 69.5547C222.314 31.1407 154.123 37.112 115.744 82.8921C77.3642 128.672 83.3301 196.925 129.069 235.339C129.14 235.399 129.212 235.459 129.283 235.518C98.9501 209.882 95.0368 164.496 120.574 134.034C146.16 103.514 191.621 99.5333 222.114 125.143C252.606 150.752 256.584 196.254 230.997 226.774C205.896 256.715 161.667 261.114 131.208 237.09C176.911 273.559 243.593 267.073 281.379 222.002C319.758 176.221 313.792 107.969 268.053 69.5547Z"
      fill="url(#lighthouse_logo_143523_paint5_linear)"
    />
    <path
      d="M125.058 44.7224C68.9515 65.162 40.0225 127.256 60.4438 183.414C80.8651 239.571 142.903 268.526 199.01 248.087C199.098 248.055 199.185 248.023 199.273 247.991C161.925 261.466 120.698 242.165 107.11 204.798C93.4954 167.359 112.781 125.963 150.186 112.337C187.591 98.7103 228.95 118.014 242.564 155.452C255.92 192.181 237.611 232.718 201.595 247.108C256.001 225.727 283.73 164.684 263.625 109.395C243.204 53.2378 181.165 24.2827 125.058 44.7224Z"
      fill="url(#lighthouse_logo_143523_paint6_linear)"
    />
    <path
      d="M125.058 44.7224C68.9515 65.162 40.0225 127.256 60.4438 183.414C80.8651 239.571 142.903 268.526 199.01 248.087C199.098 248.055 199.185 248.023 199.273 247.991C161.925 261.466 120.698 242.165 107.11 204.798C93.4954 167.359 112.781 125.963 150.186 112.337C187.591 98.7103 228.95 118.014 242.564 155.452C255.92 192.181 237.611 232.718 201.595 247.108C256.001 225.727 283.73 164.684 263.625 109.395C243.204 53.2378 181.165 24.2827 125.058 44.7224Z"
      fill="url(#lighthouse_logo_143523_paint7_linear)"
    />
    <path
      d="M32.7447 155.693C22.3765 214.547 61.6389 270.67 120.44 281.047C179.24 291.425 235.313 252.127 245.681 193.274C245.697 193.182 245.713 193.09 245.729 192.998C238.714 232.109 201.4 258.194 162.275 251.289C123.074 244.371 96.8994 206.956 103.812 167.72C110.724 128.484 148.105 102.286 187.306 109.204C225.763 115.991 251.684 152.13 246.126 190.544C254.829 132.694 215.877 78.1364 157.986 67.9195C99.1852 57.542 43.1128 96.8397 32.7447 155.693Z"
      fill="url(#lighthouse_logo_143523_paint8_linear)"
    />
    <path
      d="M32.7447 155.693C22.3765 214.547 61.6389 270.67 120.44 281.047C179.24 291.425 235.313 252.127 245.681 193.274C245.697 193.182 245.713 193.09 245.729 192.998C238.714 232.109 201.4 258.194 162.275 251.289C123.074 244.371 96.8994 206.956 103.812 167.72C110.724 128.484 148.105 102.286 187.306 109.204C225.763 115.991 251.684 152.13 246.126 190.544C254.829 132.694 215.877 78.1364 157.986 67.9195C99.1852 57.542 43.1128 96.8397 32.7447 155.693Z"
      fill="url(#lighthouse_logo_143523_paint9_linear)"
    />
    <path
      d="M82.8175 291.286C128.556 329.7 196.748 323.729 235.127 277.949C273.506 232.169 267.54 163.916 221.802 125.502C221.73 125.442 221.659 125.382 221.587 125.322C251.92 150.958 255.834 196.345 230.296 226.806C204.71 257.326 159.249 261.307 128.757 235.698C98.2642 210.089 94.2869 164.587 119.873 134.067C144.974 104.125 189.204 99.7264 219.663 123.751C173.96 87.2821 107.278 93.7674 69.4921 138.839C31.1127 184.619 37.0787 252.872 82.8175 291.286Z"
      fill="url(#lighthouse_logo_143523_paint10_linear)"
    />
    <path
      d="M82.8175 291.286C128.556 329.7 196.748 323.729 235.127 277.949C273.506 232.169 267.54 163.916 221.802 125.502C221.73 125.442 221.659 125.382 221.587 125.322C251.92 150.958 255.834 196.345 230.296 226.806C204.71 257.326 159.249 261.307 128.757 235.698C98.2642 210.089 94.2869 164.587 119.873 134.067C144.974 104.125 189.204 99.7264 219.663 123.751C173.96 87.2821 107.278 93.7674 69.4921 138.839C31.1127 184.619 37.0787 252.872 82.8175 291.286Z"
      fill="url(#lighthouse_logo_143523_paint11_linear)"
    />
    <mask
      id="lighthouse_logo_143523_mask0"
      // maskType="alpha"
      maskUnits="userSpaceOnUse"
      x="79"
      y="107"
      width="173"
      height="217"
    >
      <path
        d="M218.772 293.767C229.415 292.977 242.04 289.588 244.652 288.585C251.182 290.329 254.266 309.846 248.57 312.031C245.087 315.875 198.507 322.926 192.848 322.926C185.502 325.977 161.07 319.352 153.021 316.602C123.126 306.385 97.9472 283.207 86.3018 251.182C66.497 196.72 93.1078 136.674 145.91 114.455C136.148 118.77 127.196 125.332 119.873 134.067C110.514 145.23 105.111 158.398 103.514 171.863C103.173 174.689 102.998 177.546 102.997 180.42C100.238 234.179 137.599 282.812 191.923 292.4C200.99 294 209.992 294.419 218.772 293.767Z"
        fill="#04B6CD"
      />
    </mask>
    <g mask="url(#lighthouse_logo_143523_mask0)">
      <path
        d="M224.868 315.855C280.975 295.416 309.904 233.321 289.483 177.164C269.062 121.006 207.023 92.0513 150.916 112.491C150.829 112.523 150.741 112.555 150.654 112.587C188.002 99.1122 229.229 118.413 242.817 155.78C256.431 193.218 237.145 234.615 199.741 248.241C162.336 261.867 120.977 242.564 107.363 205.126C94.0069 168.397 112.315 127.859 148.332 113.469C93.9262 134.851 66.1965 195.894 86.3018 251.182C106.723 307.34 168.761 336.295 224.868 315.855Z"
        fill="url(#lighthouse_logo_143523_paint12_linear)"
      />
      <path
        d="M224.868 315.855C280.975 295.416 309.904 233.321 289.483 177.164C269.062 121.006 207.023 92.0513 150.916 112.491C150.829 112.523 150.741 112.555 150.654 112.587C188.002 99.1122 229.229 118.413 242.817 155.78C256.431 193.218 237.145 234.615 199.741 248.241C162.336 261.867 120.977 242.564 107.363 205.126C94.0069 168.397 112.315 127.859 148.332 113.469C93.9262 134.851 66.1965 195.894 86.3018 251.182C106.723 307.34 168.761 336.295 224.868 315.855Z"
        fill="url(#lighthouse_logo_143523_paint13_linear)"
      />
    </g>
    <mask
      id="lighthouse_logo_143523_mask1"
      // maskType="alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="55"
      width="305"
      height="306"
    >
      <path
        d="M82.8175 291.286C128.556 329.7 196.748 323.729 235.127 277.949C273.506 232.169 267.54 163.916 221.802 125.502C221.73 125.442 221.659 125.382 221.587 125.322C251.92 150.958 255.834 196.345 230.296 226.806C204.71 257.326 159.249 261.307 128.757 235.698C98.2642 210.089 94.2869 164.587 119.873 134.067C144.974 104.125 189.204 99.7264 219.663 123.751C173.96 87.2821 107.278 93.7674 69.4921 138.839C31.1127 184.619 37.0787 252.872 82.8175 291.286Z"
        fill="url(#lighthouse_logo_143523_paint14_linear)"
      />
      <path
        d="M82.8175 291.286C128.556 329.7 196.748 323.729 235.127 277.949C273.506 232.169 267.54 163.916 221.802 125.502C221.73 125.442 221.659 125.382 221.587 125.322C251.92 150.958 255.834 196.345 230.296 226.806C204.71 257.326 159.249 261.307 128.757 235.698C98.2642 210.089 94.2869 164.587 119.873 134.067C144.974 104.125 189.204 99.7264 219.663 123.751C173.96 87.2821 107.278 93.7674 69.4921 138.839C31.1127 184.619 37.0787 252.872 82.8175 291.286Z"
        fill="url(#lighthouse_logo_143523_paint15_linear)"
      />
    </mask>
    <g mask="url(#lighthouse_logo_143523_mask1)">
      <path
        d="M224.868 315.855C280.975 295.416 309.904 233.322 289.483 177.164C269.062 121.006 207.023 92.0514 150.916 112.491C150.829 112.523 150.741 112.555 150.654 112.587C188.002 99.1122 229.229 118.413 242.817 155.78C256.431 193.218 237.145 234.615 199.741 248.241C162.336 261.868 120.977 242.564 107.363 205.126C94.0069 168.397 112.315 127.859 148.332 113.469C93.9262 134.851 66.1965 195.894 86.3018 251.183C106.723 307.34 168.761 336.295 224.868 315.855Z"
        fill="url(#lighthouse_logo_143523_paint16_linear)"
      />
      <path
        d="M224.868 315.855C280.975 295.416 309.904 233.322 289.483 177.164C269.062 121.006 207.023 92.0514 150.916 112.491C150.829 112.523 150.741 112.555 150.654 112.587C188.002 99.1122 229.229 118.413 242.817 155.78C256.431 193.218 237.145 234.615 199.741 248.241C162.336 261.868 120.977 242.564 107.363 205.126C94.0069 168.397 112.315 127.859 148.332 113.469C93.9262 134.851 66.1965 195.894 86.3018 251.183C106.723 307.34 168.761 336.295 224.868 315.855Z"
        fill="url(#lighthouse_logo_143523_paint17_linear)"
      />
    </g>
    <defs>
      <linearGradient
        id="lighthouse_logo_143523_paint0_linear"
        x1="92.7248"
        y1="268.846"
        x2="254.48"
        y2="168.451"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00791973" stopColor="#1428DD" />
        <stop offset="0.651851" stopColor="#4D004F" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="lighthouse_logo_143523_paint1_linear"
        x1="86.1282"
        y1="250.705"
        x2="289.352"
        y2="176.804"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.125" stopColor="#4CD5E7" />
        <stop offset="0.427083" stopColor="#84F8EA" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="lighthouse_logo_143523_paint2_linear"
        x1="210.418"
        y1="295.664"
        x2="204.265"
        y2="105.261"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00791973" stopColor="#1428DD" />
        <stop offset="0.651851" stopColor="#4D004F" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="lighthouse_logo_143523_paint3_linear"
        x1="191.423"
        y1="292.311"
        x2="229.035"
        y2="79.1951"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.125" stopColor="#4CD5E7" />
        <stop offset="0.427083" stopColor="#84F8EA" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="lighthouse_logo_143523_paint4_linear"
        x1="293.45"
        y1="207.602"
        x2="125.566"
        y2="117.851"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00791973" stopColor="#1428DD" />
        <stop offset="0.651851" stopColor="#4D004F" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="lighthouse_logo_143523_paint5_linear"
        x1="281.052"
        y1="222.391"
        x2="115.294"
        y2="83.4283"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.125" stopColor="#4CD5E7" />
        <stop offset="0.427083" stopColor="#84F8EA" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="lighthouse_logo_143523_paint6_linear"
        x1="257.202"
        y1="91.7322"
        x2="95.4467"
        y2="192.127"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00791973" stopColor="#1428DD" />
        <stop offset="0.651851" stopColor="#4D004F" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="lighthouse_logo_143523_paint7_linear"
        x1="263.799"
        y1="109.873"
        x2="60.5746"
        y2="183.774"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.125" stopColor="#4CD5E7" />
        <stop offset="0.427083" stopColor="#84F8EA" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="lighthouse_logo_143523_paint8_linear"
        x1="139.491"
        y1="64.6554"
        x2="145.645"
        y2="255.058"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00791973" stopColor="#1428DD" />
        <stop offset="0.651851" stopColor="#4D004F" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="lighthouse_logo_143523_paint9_linear"
        x1="158.486"
        y1="68.0077"
        x2="120.874"
        y2="281.124"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.125" stopColor="#4CD5E7" />
        <stop offset="0.427083" stopColor="#84F8EA" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="lighthouse_logo_143523_paint10_linear"
        x1="57.4207"
        y1="153.238"
        x2="225.304"
        y2="242.99"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00791973" stopColor="#1428DD" />
        <stop offset="0.651851" stopColor="#4D004F" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="lighthouse_logo_143523_paint11_linear"
        x1="69.8184"
        y1="138.45"
        x2="235.577"
        y2="277.412"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.125" stopColor="#4CD5E7" />
        <stop offset="0.427083" stopColor="#84F8EA" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="lighthouse_logo_143523_paint12_linear"
        x1="92.7248"
        y1="268.846"
        x2="254.48"
        y2="168.451"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00791973" stopColor="#1428DD" />
        <stop offset="0.651851" stopColor="#4D004F" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="lighthouse_logo_143523_paint13_linear"
        x1="86.1282"
        y1="250.705"
        x2="289.352"
        y2="176.804"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.125" stopColor="#4CD5E7" />
        <stop offset="0.427083" stopColor="#84F8EA" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="lighthouse_logo_143523_paint14_linear"
        x1="57.4207"
        y1="153.238"
        x2="225.304"
        y2="242.99"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00791973" stopColor="#1428DD" />
        <stop offset="0.651851" stopColor="#4D004F" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="lighthouse_logo_143523_paint15_linear"
        x1="69.8184"
        y1="138.45"
        x2="235.577"
        y2="277.412"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.125" stopColor="#4CD5E7" />
        <stop offset="0.427083" stopColor="#84F8EA" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="lighthouse_logo_143523_paint16_linear"
        x1="92.7248"
        y1="268.846"
        x2="254.48"
        y2="168.451"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00791973" stopColor="#1428DD" />
        <stop offset="0.651851" stopColor="#4D004F" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="lighthouse_logo_143523_paint17_linear"
        x1="86.1282"
        y1="250.705"
        x2="289.352"
        y2="176.804"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.125" stopColor="#4CD5E7" />
        <stop offset="0.427083" stopColor="#84F8EA" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
));

export default LighthouseLogo;
