import React from "react";
import cx from "classnames";
import Button from "components/common/ui/button/Button";
import IconClock from "components/common/icons/IconClock";
import CategoryIcon from "components/cpd/common/CategoryIcon";
import Chip from "components/common/ui/chip/Chip";
import {
  activityCategoryOptions,
  activityWaiverOptions,
} from "models/cpd/common/common";
import { IconProgressStar } from "components/common/icons/IconProgress";
import { formatDate } from "utils/format/dateUtils";
import { Activity } from "models/cpd/Activity";
import { Goal } from "models/cpd/Goal";
import IconPaperclip from "components/common/icons/IconPaperclip";
import IconLock from "components/common/icons/IconLock";
import { getOverdueStatus, getSubcategory } from "components/cpd/utils/utils";
import IconAsterisk from "components/common/icons/IconAsterisk";
import styles from "components/cpd/activity/ActivityItem.module.scss";

interface Props extends DefaultProps {
  activity: Activity;
  editActivity: () => void;
  goal?: Goal;
  locked?: boolean;
}

const ActivityItem = ({ activity, goal, editActivity, locked }: Props) => {
  const category = activityCategoryOptions.find(
    (c) => c.value === activity.category
  );

  const subcategory = getSubcategory(activity.category, activity.subcategory);
  const complete = !!activity.completed_at;

  const viewTemplate = (
    <div
      className={cx(
        styles.root,
        !complete && styles.planned,
        activity.waive_req && styles.waived
      )}
    >
      <div className={styles.column}>
        <div className={styles.row}>
          <Chip
            icon={
              activity.category && <CategoryIcon type={activity.category} />
            }
            condensed
            theme={complete ? "primary" : "grey"}
          >
            {subcategory
              ? subcategory.label
              : category?.label || activity.category}
          </Chip>
          {goal && (
            <Chip icon={<IconProgressStar />} condensed iconVariant="empty">
              {goal.goal_name}
            </Chip>
          )}
          {activity.waiver && (
            <Chip condensed theme="info" variant="filled">
              Waived :{" "}
              {
                activityWaiverOptions.find((a) => a.value === activity.waiver)
                  ?.label
              }
            </Chip>
          )}
          {activity.is_required && (
            <Chip
              className={cx(styles.row, styles.attachment)}
              theme={getOverdueStatus(activity).level}
            >
              <IconAsterisk />
            </Chip>
          )}
          {(activity.locked || locked) && (
            <Chip className={cx(styles.row, styles.attachment)} theme="danger">
              <IconLock />
            </Chip>
          )}
          {activity.hasAttachments && (
            <Chip
              className={cx(styles.row, styles.attachment)}
              theme="secondary"
            >
              <IconPaperclip />
            </Chip>
          )}
        </div>
        {activity.topic && <h4>{activity.topic}</h4>}
        <div className={styles.date}>
          {activity.completed_at ? (
            <>
              <b>Completed: </b>
              {formatDate(activity.completed_at, undefined, "")}
            </>
          ) : (
            <>
              <b>Planned: </b>
              {activity.planned_at
                ? formatDate(activity.planned_at, undefined, "")
                : "Unknown Date"}
            </>
          )}
        </div>
      </div>
      <div className={cx(styles.column, styles.endColumn)}>
        <div className={styles.row}>
          <Chip className={cx(styles.row, styles.credits)}>
            <b>{activity.credits}</b>
            <IconClock />
          </Chip>
          <Button
            variant="outline"
            className={styles.buttonColour}
            onClick={() => editActivity()}
            theme="primary"
            rounded
          >
            View
          </Button>
        </div>
      </div>
    </div>
  );

  return <li>{viewTemplate}</li>;
};

export default ActivityItem;
