import { useMutation, useQueryClient } from "react-query";
import { TUser } from "models/user/User";
import { save } from "api/UserApi";
import axios from "axios";
import { userQueryKeys } from "queries/constants";
import { MutationOptions } from "queries/queries";
import { useToast } from "components/common/ui/toast/useToast";

export const useMutateUser = (options: MutationOptions = {}) => {
  const queryClient = useQueryClient();
  const { addErrorToast, addToast } = useToast();

  return useMutation(
    ({ newUser, oldUser }: useMutateUserProps) => save(newUser, oldUser),
    {
      onSuccess: (result, inputs) => {
        addToast(
          inputs.oldUser.id
            ? "User was successfully updated!"
            : "User was successfully created!",
          {
            theme: "success",
          }
        );

        queryClient.invalidateQueries(userQueryKeys.detail(inputs.oldUser.id));
        queryClient.invalidateQueries(userQueryKeys.list());
      },
      onError: (e, inputs) => {
        if (axios.isAxiosError(e) && e.response?.status === 500) {
          addToast(`Email (${inputs.newUser.email}) is already taken`, {
            theme: "warning",
            delay: 6000,
          });
        } else {
          addErrorToast(e, "Error: Unable to save User Details");
        }
      },
      ...options,
    }
  );
};

interface useMutateUserProps {
  newUser: Partial<TUser>;
  oldUser: TUser;
}
