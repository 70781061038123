import cx from "classnames";
import styles from "./FormActions.module.scss";
import Button from "components/common/ui/button/Button";
import React from "react";

interface Props extends DefaultProps {
  isSubmitting?: boolean;
  submitText?: string;
  cancelText?: string;
  onCancel?: (d?: any) => void;
}

const FormActions = ({
  className,
  isSubmitting,
  submitText = "Save",
  cancelText = "Cancel",
  onCancel,
  children,
  ...props
}: Props) => (
  <div {...props} className={cx(styles.actions, className)}>
    {children || (
      <>
        <Button variant="empty" onClick={onCancel}>
          {cancelText}
        </Button>
        <Button theme="success" rounded type="submit" submitting={isSubmitting}>
          {submitText}
        </Button>
      </>
    )}
  </div>
);

export default FormActions;
