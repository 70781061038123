import React, { useEffect, useState } from "react";
import { SetFieldValue, useController } from "react-hook-form";
import { Control } from "react-hook-form/dist/types/form";
import cx from "classnames";
import styles from "components/cpd/common/peergroup-meeting-selection/PeerGroupMeetingCombobox.module.scss";
import Combobox from "components/common/ui/form-elements/autocomplete/Combobox";
import { TError } from "components/common/ui/form-elements/formElements";
import Alert from "components/common/ui/modal/Alert";
import { useActivityList } from "components/cpd/hooks/useActivityList";
import {
  IPeergroupMeetingItem,
  usePeerGroupActivityMeetings,
} from "components/peergroup/hooks/usePeerGroupActivityMeetings";
import { PlanYear } from "models/cpd/Plan";
import { SkeletonTextInput } from "components/common/ui/form-elements/text-input/TextField";
import IconSearch from "components/common/icons/IconSearch";
import IconExternalLink from "components/common/icons/IconExternalLink";
import { coreService } from "services/CoreService";
import { PEERGROUP_ROUTES } from "constants/routes/peergroup";
import IconButton from "components/common/ui/button/IconButton";
import { SearchFunction } from "components/common/ui/form-elements/autocomplete/utils";
import { fuzzyMatch } from "utils/dimensions/matchUtils";

interface Props extends DefaultProps {
  question: any;
  defaultValue?: any[];
  control: Control;
  error?: TError;
  disabled?: boolean;
  planId: string;
  userId: string;
  activityId?: string;
  year: PlanYear;
  setValue: SetFieldValue<any>;
}

const PeerGroupMeetingCombobox = ({
  question,
  defaultValue,
  control,
  className,
  children,
  planId,
  activityId,
  userId,
  year,
  setValue,
  ...props
}: Props) => {
  const { isLoading, data: activityMeetings } = usePeerGroupActivityMeetings(
    { start: year.start, end: year.end },
    userId
  );
  const { isLoading: isActivityLoading, data: activity } = useActivityList(
    planId,
    userId,
    undefined,
    { enabled: true }
  );
  const [menuOptions, setMenuOptions] = useState<IPeergroupMeetingItem[]>([]);
  const assignedMeetingKeys =
    activity?.keyByCategory?.peer?.map((k: string) =>
      k !== activityId ? activity?.map[k]?.peergroup_meeting_id : undefined
    ) ?? [];

  const [duplicateMeeting, displayDuplicateMeeting] = useState<
    IPeergroupMeetingItem | undefined
  >(undefined);

  const {
    field: { onChange, value },
  } = useController({
    name: "peergroup_meeting_id",
    control: control,
    rules: { required: true },
    defaultValue: defaultValue || "",
  });
  const selected = menuOptions.find((o) => o.value === value);

  useEffect(() => {
    if (activityMeetings && activityMeetings?.length > 0) {
      setMenuOptions(activityMeetings);
    }
  }, [activityMeetings]);

  const handleChange = (item?: IPeergroupMeetingItem) => {
    onChange(item?.value);
    if (item?.metadata?.peergroup_meeting_points) {
      setValue("credits", item?.metadata?.peergroup_meeting_points, {
        shouldValidate: true,
      });
    }
    displayDuplicateMeeting(undefined);
  };

  const onSelect = (item?: IPeergroupMeetingItem) => {
    if (item?.value && assignedMeetingKeys.includes(item.value)) {
      displayDuplicateMeeting(item);
    } else {
      handleChange(item);
    }
  };
  const onSearch: SearchFunction<IPeergroupMeetingItem> = (inputValue) => {
    setMenuOptions(
      inputValue
        ? activityMeetings?.filter((m: IPeergroupMeetingItem) =>
            fuzzyMatch(inputValue, m.label ?? "")
          )
        : activityMeetings
    );
  };

  return (
    <div className={cx(styles.root, className)}>
      {!isLoading && !isActivityLoading ? (
        <div>
          <Combobox
            items={menuOptions}
            selected={selected}
            onSelect={onSelect}
            onSearch={onSearch}
            name={"meeting"}
            label={question.label || "Search for a meeting"}
            description={question.description}
            {...props}
          />
          {selected && (
            <IconButton
              icon={<IconExternalLink />}
              iconVariant="empty"
              variant="outline"
              theme="primary"
              target="_blank"
              rounded={false}
              href={coreService.getRoute(
                PEERGROUP_ROUTES.PEERGROUP_MEETING_DETAIL,
                {
                  peergroupId: selected?.metadata?.peergroup_id || "",
                  meetingId: selected.value || "",
                }
              )}
            >
              Go to the meeting
            </IconButton>
          )}
        </div>
      ) : (
        <SkeletonTextInput icon={IconSearch} />
      )}

      <Alert
        open={!!duplicateMeeting}
        onClose={() => displayDuplicateMeeting(undefined)}
        heading={"This meeting is already associated with another activity "}
        onAgree={() => handleChange(duplicateMeeting)}
        agreeText="Add Meeting"
        cancelText={"Cancel"}
      >
        Are you aware of this meeting is associated with another activity?
      </Alert>
    </div>
  );
};

export default PeerGroupMeetingCombobox;
