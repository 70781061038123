import { TBucket } from "models/Dimension";
import { request } from "utils/http/Request";
import {
  FACILITIES,
  getApiUrl,
  FACILITY,
  FACILITY_USERS,
  FACILITY_USER,
} from "constants/paths";
import {
  Facility,
  formatFacility,
  toServerObject,
  IServerFacility,
} from "models/facility/Facility";

export const fetchMany = async (
  filter: Record<string, TBucket>
): Promise<Facility[]> => {
  const data = await request.get(getApiUrl(FACILITIES, {}), filter);
  return data.facilities.map(formatFacility);
};

// export const fetchFacilityUsers = async (id: string) => {
//   const data = await request.get(getApiUrl(FACILITY_USERS, { facilityId: id }));
//   // return data;
//   return map(data as any);
//
// };

export const fetchOneById = async (facilityId: string): Promise<Facility> => {
  const data = await request.get(getApiUrl(FACILITY, { facilityId }));
  return formatFacility(data as IServerFacility);
};

export const save = async (
  { facility_id, ...facility }: Partial<Facility>,
  oldFacility: Facility
): Promise<Facility> => {
  if (facility_id) {
    await request.put(
      getApiUrl(FACILITY, { facilityId: facility_id }),
      toServerObject(facility)
    );
    return { ...oldFacility, ...facility, facility_id };
  }
  const newFacility = await request.post(
    getApiUrl(FACILITIES, {}),
    toServerObject(facility)
  );
  return formatFacility(newFacility as IServerFacility);
};

export const addUserToFacility = async (userId: string, facilityId: string) => {
  return await request.post(getApiUrl(FACILITY_USERS, { facilityId }), {
    user_id: userId,
  });
};

export const removeUserFromFacility = async (
  userId: string,
  facilityId: string
) => {
  return await request.delete(getApiUrl(FACILITY_USER, { facilityId, userId }));
};

export const addUserToMultipleFacilities = async (
  userId: string,
  facilties: (Facility | undefined)[]
) => {
  const promises = facilties.map(async (f) => {
    return f && f?.facility_id && addUserToFacility(userId, f.facility_id);
  });
  return Promise.all(promises);
};

export const removeUserFromMultipleFacilities = async (
  userId: string,
  facilties: (Facility | undefined)[]
) => {
  const promises = facilties.map(async (f) => {
    return f?.facility_id && removeUserFromFacility(userId, f.facility_id);
  });
  return Promise.all(promises);
};
