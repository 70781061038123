import React from "react";

interface Props {
  className?: string;
}

const Squid = ({ className }: Props) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 87 87"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M0 71.1815C0 71.1815 1.97751 65.2497 7.90933 59.3179C13.8411 53.3861 29.6591 49.4318 35.5909 43.5L43.5002 51.4086C43.5002 51.4086 35.5909 63.2722 23.7273 63.2722C11.8636 63.2722 3.9543 67.2272 0 71.1815Z"
      fill="#D75A88"
    />
    <path
      d="M24.3922 63.2598C24.172 63.268 23.9504 63.2722 23.7275 63.2722C11.9704 63.2722 4.0969 67.1565 0.107708 71.075L0.0927734 70.9256C0.255715 70.4933 0.641805 69.5375 1.31406 68.2475C7.80036 60.351 17.0417 59.318 23.7278 59.318C31.2837 59.318 37.1692 53.0444 39.3487 50.3472L42.4232 52.8337C39.9485 55.8964 33.3178 62.9234 24.3922 63.2598Z"
      fill="#C74586"
    />
    <path
      d="M39.5452 31.6367L31.6366 39.5453C27.6816 43.5003 19.773 43.5003 19.773 43.5003C11.8636 43.5003 0 47.4546 0 55.364C0 55.364 3.9543 51.409 15.8179 51.409C27.6816 51.409 35.5909 51.409 39.5452 47.4546L47.4545 39.5453L39.5452 31.6367Z"
      fill="#D75A88"
    />
    <path
      d="M38.9822 47.9691C39.178 47.8054 39.3657 47.6339 39.5454 47.4543L46.0564 40.9432L43.2607 38.1475L36.7496 44.6586C33.9539 47.4543 26.3458 47.4543 15.8181 47.4543C11.634 47.4543 5.19078 48.5927 0.603304 52.3921C0.213863 53.3114 0 54.302 0 55.364C0 55.364 3.93814 51.4251 15.7452 51.409C15.7697 51.4087 15.7939 51.4086 15.8181 51.4086C18.5064 51.4086 20.9887 51.4086 23.2665 51.3631C30.6024 51.2155 35.8411 50.5933 38.9822 47.9691Z"
      fill="#C54385"
    />
    <path
      d="M55.364 47.4547L47.4546 55.3641C43.5003 59.3184 43.5003 67.2277 43.5003 67.2277C43.5003 75.1363 39.5453 86.9999 31.6367 86.9999C31.6367 86.9999 35.591 83.0456 35.591 71.182C35.591 59.3184 35.591 51.409 39.5453 47.4547L47.4546 39.5454L55.364 47.4547Z"
      fill="#D75A88"
    />
    <path
      d="M32.2862 86.149C38.6221 79.1462 39.5447 72.5276 39.5447 67.2273C39.5447 66.2498 39.6881 57.5375 44.6583 52.5672L51.1694 46.0562L53.9652 48.8526L47.4541 55.3637C47.2053 55.6125 46.9721 55.8774 46.7536 56.1552C43.8539 59.8345 43.5388 65.7514 43.5045 66.9941C43.5002 67.1383 43.4998 67.2196 43.4998 67.2273C43.4998 69.4374 43.0808 72.3659 42.3318 75.3531C40.6286 81.4141 37.0634 86.9999 31.6367 86.9999C31.6367 86.9999 31.898 86.7387 32.2862 86.149Z"
      fill="#BF3B84"
    />
    <path
      d="M39.5452 43.5C31.6366 43.5 23.7273 47.4543 23.7273 59.3179C23.7273 71.1815 15.8179 71.1815 15.8179 71.1815C7.90933 71.1815 0 75.1358 0 86.9995C0 86.9995 3.9543 79.0909 11.8636 79.0909C19.773 79.0909 35.5909 79.0909 35.5909 63.2722C35.5909 51.4086 47.4545 51.4086 47.4545 51.4086L39.5452 43.5Z"
      fill="#D75A88"
    />
    <path
      d="M38.2891 55.3229C40.0123 53.378 42.2198 52.4174 43.8455 51.9475L42.7485 48.1475C39.4082 49.1134 33 52.5 31.5 63.2724C30.5276 70.2558 23 74 11.8635 75.1361C8.81622 75.1361 3.06829 77.5814 0.200405 83.8801C0.0689478 84.8531 0 85.8918 0 86.9995C0 86.9995 3.9543 79.0909 11.8636 79.0909C12.1332 79.0909 12.412 79.0909 12.6991 79.0902C13.5654 79.088 14.4981 79.0799 15.4739 79.051C23.7859 78.7977 35.5884 76.9377 35.5909 63.2765C35.5908 63.2752 35.5908 63.2738 35.5908 63.2724C35.5908 59.5966 36.7456 57.0649 38.2891 55.3229Z"
      fill="#C34185"
    />
    <path
      d="M63.2727 0C63.2727 0 59.3184 0 59.3184 3.9543V23.7273L63.2727 27.6816H83.0456C86.9999 27.6816 86.9999 23.7273 86.9999 23.7273V0H63.2727Z"
      fill="#CB528C"
    />
    <path
      d="M83.0451 27.6816H73.5L74.5 23.7273H83.0451V1.97754H86.9994V23.7273C86.9379 26.1508 85.3854 27.6816 83.0451 27.6816Z"
      fill="#C34085"
    />
    <path
      d="M31.6367 35.5909C31.6367 46.511 40.4888 55.3638 51.4089 55.3638C83.0455 23.7273 86.9998 0 86.9998 0C86.9998 0 63.2726 3.9543 31.6367 35.5909Z"
      fill="#EF7A8D"
    />
    <path
      d="M31.6367 35.5909C31.6367 46.511 40.4888 55.3638 51.4089 55.3638C83.0455 23.7273 86.9998 0 86.9998 0C86.9998 0 63.2726 3.9543 31.6367 35.5909Z"
      fill="url(#squid_357201_paint0_linear)"
    />
    <path
      d="M53.1371 53.6156C52.5704 54.1956 51.9944 54.7783 51.4089 55.3638C42.3263 55.3638 34.6742 49.2396 32.3563 40.8953C32.2044 40.3282 32.0695 39.7405 31.9528 39.1316C31.7452 37.9828 31.6367 36.7995 31.6367 35.5909C32.4344 34.7931 33.2271 34.013 34.0145 33.2501C34.6143 42.6659 41.0776 51.9941 49.8394 51.3319C72.7577 28.0228 80.8296 8.12523 82.9837 1.05348C83.3022 0.953351 83.6029 0.861947 83.8855 0.778634L86.9998 0C86.9998 0 86.1518 5.08839 82.0993 13.6943C77.0239 24.1647 67.7918 38.5917 53.1371 53.6156Z"
      fill="#D75A88"
    />
    <path
      d="M47.4544 29.6589C47.4544 32.9347 44.7985 35.5907 41.5226 35.5907C38.2468 35.5907 35.5908 32.9347 35.5908 29.6589C35.5908 26.383 38.2468 23.7271 41.5226 23.7271C44.7985 23.7271 47.4544 26.383 47.4544 29.6589Z"
      fill="#2B1718"
    />
    <path
      d="M47.4543 29.6592C47.4543 30.7511 46.5687 31.6367 45.4768 31.6367C44.3848 31.6367 43.5 30.7511 43.5 29.6592C43.5 28.5672 44.3848 27.6816 45.4768 27.6816C46.5687 27.6816 47.4543 28.5672 47.4543 29.6592Z"
      fill="#766A6A"
    />
    <path
      d="M63.2728 45.4772C63.2728 48.7538 60.6175 51.409 57.341 51.409C54.0652 51.409 51.4092 48.7538 51.4092 45.4772C51.4092 42.2014 54.0652 39.5454 57.341 39.5454C60.6175 39.5454 63.2728 42.2014 63.2728 45.4772Z"
      fill="#2B1718"
    />
    <path
      d="M63.2727 45.4768C63.2727 46.5694 62.3878 47.4543 61.2959 47.4543C60.2039 47.4543 59.3184 46.5694 59.3184 45.4768C59.3184 44.3848 60.2039 43.5 61.2959 43.5C62.3878 43.5 63.2727 44.3848 63.2727 45.4768Z"
      fill="#766A6A"
    />
    <defs>
      <linearGradient
        id="squid_357201_paint0_linear"
        x1="59.3183"
        y1="0"
        x2="59.3183"
        y2="55.3638"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D75A88" />
        <stop offset="1" stopColor="#EF7B8C" />
      </linearGradient>
    </defs>
  </svg>
);

export default Squid;
