import React from "react";
import cx from "classnames";
import styles from "./Radio.module.scss";

interface IRadio {
  name: string;
  value?: string;
  label: string;
  className?: string;
  disabled?: boolean;
  required?: boolean;
  tabIndex?: number;
  defaultValue?: string;
  onChange?: (event: React.ChangeEvent<unknown>) => void;
}

const Radio = React.forwardRef(
  (
    {
      value,
      name,
      label,
      disabled,
      required,
      tabIndex,
      className,
      defaultValue,
      ...rest
    }: IRadio,
    ref: React.Ref<any>
  ) => (
    <label className={cx(className, styles.radio, disabled && styles.disabled)}>
      <input
        {...rest}
        type="radio"
        id={`radio-input-${name}-${value}`}
        name={name}
        value={value}
        disabled={disabled}
        className={cx(styles.input)}
        ref={ref}
        required={required}
        tabIndex={tabIndex}
        defaultChecked={defaultValue === value}
        //{...attr}
      />
      <span className={cx(styles.dot, disabled && styles.disabled)} />
      <label htmlFor={`radio-input-${name}-${value}`} className={styles.label}>
        {label}
      </label>
    </label>
  )
);

export default Radio;
