import React from "react";
import { AppContentContainer } from "components/common/layouts/appLayout/AppContentContainer";
import Meta from "components/common/Meta";
import { useGoalList } from "components/cpd/hooks/useGoalList";
import { useSelector } from "react-redux";
import {
  currentUserSelector,
  isAppLoadedSelector,
} from "store/selectors/core.selectors";
import { ROUTE_PARAMS } from "constants/routes/common";
import { useLocation, useParams } from "react-router";
import Goals from "components/cpd/goal/Goals";
import MiniLoader from "components/common/ui/loader/MiniLoader";
import { coreService } from "services/CoreService";
import { CPD_ROUTES } from "constants/routes/cpd";
import { checkTPS } from "components/cpd/utils/utils";

const ViewGoals = () => {
  const isAppLoaded = useSelector(isAppLoadedSelector);
  const currentUser = useSelector(currentUserSelector);
  const { [ROUTE_PARAMS.USER]: paramsUserId } =
    useParams<{ [ROUTE_PARAMS.USER]?: string }>();
  const { isLoading: isGoalsLoading, data: goals } = useGoalList(
    paramsUserId || currentUser.id,
    { enabled: isAppLoaded }
  );
  const loc = useLocation();
  const { isTPS } = checkTPS(loc);
  const isLoading = isGoalsLoading || !isAppLoaded;

  return (
    <AppContentContainer
      backButton={
        isTPS
          ? {
              to: coreService.getRoute(
                paramsUserId
                  ? CPD_ROUTES.ADMIN_USER_TPS
                  : CPD_ROUTES.MY_TPS_ALL,
                { [ROUTE_PARAMS.USER]: currentUser.id }
              ),
              label: "Back to TPS List",
            }
          : {
              to: coreService.getRoute(
                paramsUserId
                  ? CPD_ROUTES.ADMIN_USER_PLANS
                  : CPD_ROUTES.MY_PLANS_ALL,
                { [ROUTE_PARAMS.USER]: currentUser.id }
              ),
              label: "Back to Plans",
            }
      }
      spacing="extra"
      maxWidth={true}
    >
      <Meta title={"Goals"} />
      {isLoading ? (
        <MiniLoader theme="secondary" />
      ) : (
        <Goals goals={goals} userId={paramsUserId || currentUser.id} editable />
      )}
    </AppContentContainer>
  );
};

export default ViewGoals;
