import { useMutation, useQueryClient } from "react-query";
import { activityQueryKeys } from "queries/constants";
import { MutationOptions } from "queries/queries";
import { useToast } from "components/common/ui/toast/useToast";
import { saveActivity } from "api/CpdApi";
import { Activity, createActivity } from "models/cpd/Activity";

export const useMutateActivity = (options: MutationOptions = {}) => {
  const queryClient = useQueryClient();
  const { addErrorToast, addToast } = useToast();

  return useMutation(
    ({
      newActivity,
      oldActivity = createActivity(),
      userId,
    }: useMutateActivityProps) =>
      saveActivity(newActivity, oldActivity, userId),
    {
      onSuccess: (result, inputs) => {
        addToast(
          `Activity was successfully ${
            inputs.oldActivity?.id ? "updated" : "created"
          }!`,
          {
            theme: "success",
          }
        );
        if (inputs.oldActivity?.id) {
          queryClient.invalidateQueries(
            activityQueryKeys.detail(inputs.oldActivity.id)
          );
        }
        queryClient.invalidateQueries(activityQueryKeys.list());
      },
      onError: (e) => {
        addErrorToast(e, "Error: Unable to save Activity");
      },
      ...options,
    }
  );
};

interface useMutateActivityProps {
  newActivity: Partial<Activity>;
  oldActivity?: Activity;
  userId: string;
}
