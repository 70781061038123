import React from "react";
import cx from "classnames";
import styles from "./Chip.module.scss";
import { formatDate, timeDifference } from "utils/format/dateUtils";
import Chip, { IChip } from "components/common/ui/chip/Chip";
import { IconDanger, IconError } from "components/common/icons/twoToneIcons";

interface Props extends Omit<IChip, "children"> {
  startDate: Date | string | null;
  targetDate?: Date | string | null;
  showDiff?: boolean;
  format?: string;
  icon?: boolean;
}
const iconVariants = {
  default: "",
  primary: "",
  secondary: "",
  success: "",
  info: "",
  grey: "",
  danger: <IconDanger className={styles.icon} />,
  warning: <IconError className={styles.icon} />,
};

const DateChip = ({
  startDate,
  targetDate = new Date(),
  icon,
  theme,
  showDiff = true,
  format,
  className,
  ...rest
}: Props) => {
  return (
    <Chip {...rest} className={cx(className, styles.date)} theme={theme}>
      {icon && theme && iconVariants[theme]}
      <span>{formatDate(startDate, format)}</span>
      {showDiff && (
        <span className={styles.diff}>
          &nbsp;({timeDifference(startDate, targetDate)})
        </span>
      )}
    </Chip>
  );
};

export default DateChip;
