import React from "react";
import cx from "classnames";
import IconDelete from "components/common/icons/IconDelete";
import FileIcon from "./FileIcon";
import { formatFileSize } from "utils/format/numberUtils";
import { FileState } from "./File.d.ts";
import IconCheck from "components/common/icons/IconCheck";
import { motion } from "framer-motion";
import ActionButton from "components/common/ui/button/ActionButton";
import { Progress } from "components/common/ui/progress/Progress";
import MiniLoader from "components/common/ui/loader/MiniLoader";
import styles from "./FilePreview.module.scss";

interface Props extends DefaultProps, FileState {
  removeFile: (f: File) => void;
}

const FilePreview = ({ file, removeFile, progress, complete }: Props) => {
  const uploading = progress && progress < 100;
  const uploaded = (progress && progress >= 100) || complete;
  return (
    <motion.div
      layout
      initial={{ opacity: 0, y: 10, height: 0, marginBottom: 0 }}
      animate={{ opacity: 1, y: 0, height: "auto", marginBottom: 12 }}
      exit={{ opacity: 0, height: 0, marginBottom: 0 }}
      className={cx(
        styles.root,
        uploading && styles.loading,
        uploaded && styles.success
      )}
    >
      <div className={styles.avatar}>
        <div className={styles.icon}>
          {uploading && (
            <MiniLoader className={styles.loader} theme="primary" />
          )}
          {uploaded ? <IconCheck /> : <FileIcon filename={file.name} />}
        </div>
      </div>
      <div className={styles.content}>
        <p className={styles.path}>{file.name}</p>
        <p className={styles.size}>{formatFileSize(file.size)}</p>
      </div>
      {!uploaded && (
        <ActionButton
          icon={<IconDelete />}
          onClick={() => removeFile(file)}
          theme="danger"
          rounded
          aria-label={`remove ${file.name}`}
        />
      )}
      {progress && (
        <Progress
          theme="success"
          value={progress}
          className={styles.progress}
        />
      )}
    </motion.div>
  );
};

export default FilePreview;
