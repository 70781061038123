import React, { useEffect, useState } from "react";
import { useFieldArray } from "react-hook-form";
import { Control } from "react-hook-form/dist/types/form";
import { UserSearch } from "models/user/User";
import { useUsersSearch } from "components/users/hooks/useUsersList";
import useDebounce from "hooks/useDebounce";
import { SearchFunction } from "components/common/ui/form-elements/autocomplete/utils";
import { TError } from "components/common/ui/form-elements/formElements";
import { SkeletonTextInput } from "components/common/ui/form-elements/text-input/TextField";
import InputFeedback from "components/common/ui/form-elements/common/InputFeedback";
import MultiSelectCombobox from "components/common/ui/form-elements/autocomplete/MulitSelectCombobox";
import { UseMultipleSelectionStateChange } from "downshift";

interface Props extends DefaultProps {
  name: string;
  label?: string;
  description?: string;
  control: Control;
  error?: TError;
  disabled?: boolean;
  memberId?: string;
}

const UserSearchQuestion = ({
  name,
  label,
  control,
  children,
  memberId,
  ...props
}: Props) => {
  const [filter, setFilter] = useState<string>("");
  const debouncedFilter = useDebounce(filter, 200);
  const [users, setUsers] = useState<UserSearch[]>([]);
  const { isLoading, data, isSuccess } = useUsersSearch(
    debouncedFilter ? { search: debouncedFilter } : {}
  );
  // const {
  //   field: { onChange, value },
  // } = useController({
  //   name,
  //   control,
  //   rules: { required: true },
  // });

  const {
    fields,
    replace,
  }: // }: { fields: UserSearch["metadata"][]; replace: (f: (undefined | UserSearch["metadata"])[]) => void } =
  { fields: UserSearch[]; replace: (f: UserSearch[]) => void } = useFieldArray({
    control: control,
    name,
  });

  const onSelect = (changes: UseMultipleSelectionStateChange<UserSearch>) => {
    replace(changes.selectedItems?.map((f) => f) || []);
  };
  const onSearch: SearchFunction<UserSearch> = (inputValue = "") => {
    setFilter(inputValue);
  };

  useEffect(() => {
    if (!isLoading) {
      setUsers(data);
    }
  }, [data, isLoading]);

  return !isSuccess && users.length === 0 ? (
    <>
      <SkeletonTextInput label={label || "Search for person"} />
      <InputFeedback name={name} />
    </>
  ) : (
    <MultiSelectCombobox
      items={users || []}
      // selected={value ?? undefined}
      selected={fields}
      onSelect={onSelect}
      onSearch={onSearch}
      name={name}
      label={label || "Search for person"}
      {...props}
    />
  );
};

export default UserSearchQuestion;
