import * as React from "react";
import BaseLayout from "components/common/layouts/BaseLayout";
import { layoutService } from "services/LayoutService";
import { useLayoutEffect } from "react";
import { useWindowSize } from "hooks/useWindowSize";
import OnLoad from "components/app/AppOnLoad";

interface Props {
  children: any;
}

const AppLayout = (props: Props) => {
  const { width } = useWindowSize();
  const isDesktop = width > 992;
  useLayoutEffect(() => {
    layoutService.setDesktopLayout(isDesktop);
  }, [isDesktop]);

  return (
    <BaseLayout>
      <OnLoad />
      {props.children}
    </BaseLayout>
  );
};

export default React.memo(AppLayout);
