import React from "react";
import Container from "components/common/layouts/Container";
import { Facility } from "models/facility/Facility";
import FacilityHeader from "components/facilities/detail/FacilityHeader";
import { facilityDetailsQuestionIDs } from "models/facility/FacilityForm";
import { facilityAttributesMap } from "models/facility/attributes";
import FormattedQuestion from "components/common/ui/form-elements/display-elements/FormattedQuestion";
import UsersList from "components/users/admin/list/UserList";
import { TUser } from "models/user/User";

interface Props {
  facility: Facility;
  facilityUsers: TUser[];
  canChangeFacilityAttributes: boolean;
  onUpdateAttributes: (attributes: Partial<Facility>) => void;
  editLink: string;
}

const FacilityDetail = ({
  facility,
  facilityUsers,
  canChangeFacilityAttributes = false,
  editLink,
}: Props) => {
  // const remove = useMutateRemoveUserFromFacility();
  // const removeUser = (user: TUser) => {
  //   if (user) {
  //     remove.mutate({ user, facility });
  //   }
  // };
  return (
    <div>
      <FacilityHeader
        facility={facility}
        isAdmin={canChangeFacilityAttributes}
        editLink={editLink}
      />

      <Container>
        {facilityDetailsQuestionIDs.map((q) => (
          <FormattedQuestion
            def={facilityAttributesMap[q]}
            questions={facility}
          />
        ))}
        <h2>Facility users: </h2>
      </Container>
      <UsersList
        users={facilityUsers}
        dimensions={{}}
        isAppLoaded
        setActive={async () => ({})}
        setManyActive={async () => ({})}
        showFilters={false}
        // actionCell={(c, r) => (
        //   <ActionButton
        //     icon={<IconDelete />}
        //     rounded
        //     theme="danger"
        //     variant="outline"
        //     onClick={(e)=>{
        //       e.preventDefault()
        //       removeUser(r)
        //     }}
        //   />
        // )}
      />
    </div>
  );
};

export default FacilityDetail;
