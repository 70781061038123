import React, { useCallback, useEffect, useState } from "react";
import styles from "components/cpd/activity/edit/ActivityEditForm.module.scss";
import Spacer from "components/common/ui/spacer/Spacer";
import { ActivityFormActions } from "components/cpd/activity/edit/ActivityFormActions";
import { BaseActivityEditProps } from "components/cpd/activity/edit/activityEdit";
import FileUpload from "components/common/ui/form-elements/file-upload/FileUpload";
import { useMutateUploadActivityDoc } from "components/cpd/hooks/useMutateUploadActivityDoc";
import { FileState } from "components/common/ui/form-elements/file-upload/File.d.ts";
import Container from "components/common/layouts/Container";
import FileItem from "components/cpd/activity/edit/FileItem";
import { useMutateActivity } from "components/cpd/hooks/useMutateActivity";
import { createActivity } from "models/cpd/Activity";

const EditActivityDocs = ({
  onCancel,
  activity,
  userId,
  isAdmin,
}: BaseActivityEditProps) => {
  const [files, setFiles] = useState([] as FileState[]);
  const {
    mutateAsync: mutateUploadFileDoc,
    isLoading: isSubmitting,
    isSuccess,
  } = useMutateUploadActivityDoc();

  const { mutateAsync: mutateActivity, isLoading: isActivtyUpdating } =
    useMutateActivity();

  const handleSubmit = async () => {
    if (activity?.id && files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        const f = files[i];
        if (!f.complete && (!f.progress || f.progress < 100)) {
          await mutateUploadFileDoc({
            file: f.file,
            activityId: activity.id!,
            userId,
          });
          setFiles((s) => {
            const newFiles = [...s];
            newFiles[i] = {
              ...f,
              complete: true,
            };
            return newFiles;
          });
        }
      }
    } else {
      // if all the docs have been deleted
      if (activity?.supporting_docs?.length === 0 && activity.hasAttachments) {
        await mutateActivity({
          newActivity: { ...activity, hasAttachments: false },
          oldActivity: createActivity(activity),
          userId,
        });
      }
      onCancel();
    }
  };

  const closingTime = useCallback(async () => {
    if (!activity?.hasAttachments) {
      await mutateActivity({
        newActivity: { ...activity, hasAttachments: true },
        oldActivity: createActivity(activity),
        userId,
      });
    }
    onCancel();
  }, [activity, mutateActivity, onCancel, userId]);

  useEffect(() => {
    if (isSuccess) {
      if (files.every((f) => f.complete)) {
        closingTime();
      }
    }
  }, [isSuccess, files, closingTime]);

  return (
    <div className={styles.root}>
      {activity?.id && (
        <div>
          {" "}
          <Container>
            <FileUpload files={files} setState={setFiles} />

            <h4>Current documents on this activity</h4>
            <div className={styles.wrapRow}>
              {activity?.supporting_docs?.map((d) => (
                <FileItem
                  key={d}
                  file={d}
                  activityId={activity?.id || ""}
                  userId={userId}
                  removeFile={isAdmin}
                />
              ))}
            </div>
          </Container>
        </div>
      )}
      <Spacer />
      <ActivityFormActions
        isSubmitting={isSubmitting || isActivtyUpdating}
        onCancel={onCancel}
        onSubmit={handleSubmit}
        noBack
      />
    </div>
  );
};

export default EditActivityDocs;
