import React from "react";
import createIcon from "components/common/icons/common/createIcon";

const IconExpandArrow = createIcon(
  <React.Fragment>
    <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" />
    <path fill="none" d="M0 0h24v24H0V0z" />
  </React.Fragment>,
  "ExpandArrow"
);

export const IconExpandAllArrow = createIcon(
  <React.Fragment>
    <path
      d="M17.4114 3.47827L19.1739 5.26574L11.6739 12.872L4.17392 5.26574L5.93642 3.47827L11.6739 9.28439L17.4114 3.47827ZM17.4114 11.0845L19.1739 12.872L11.6739 20.4783L4.17392 12.872L5.93642 11.0845L11.6739 16.8907L17.4114 11.0845Z"
      fill="currentColor"
    />
  </React.Fragment>,
  "ExpandAllArrow"
);

export default IconExpandArrow;
