import React from "react";
import createIcon from "components/common/icons/common/createIcon";

const IconCaduceus = createIcon(
  <React.Fragment>
    <path d="M17.9999 2.98688C16.3412 2.98688 14.9972 4.33124 14.9972 5.99035H18.9972C20.6559 5.99035 21.9999 4.64599 21.9999 2.98688H17.9999Z" />
    <path d="M9.00267 5.99035C9.00267 4.33124 7.65867 2.98688 6 2.98688H2C2 4.64599 3.344 5.99035 5.00267 5.99035H9.00267Z" />
    <path d="M17.0026 7.99093H15.0026C14.448 7.99093 14 8.43905 14 8.99386C14 9.54868 14.448 9.9968 15.0026 9.9968H17.0026C17.552 9.9968 18.0053 10.4449 18.0053 10.9997V11.3305C18.0053 11.816 17.6586 12.2267 17.184 12.3121L13.0026 13.075V5.71299C13.5946 5.36623 14 4.73139 14 3.9952C14 2.8909 13.104 2 12.0053 2C10.9013 2 10.0106 2.89624 10.0106 3.9952C10.0106 4.73139 10.416 5.36623 11.008 5.71299V13.0696L6.83195 12.3121C6.35729 12.2267 6.01062 11.8106 6.01062 11.3305V10.9997C6.01062 10.4503 6.45862 9.9968 7.01329 9.9968H9.01329C9.56795 9.9968 10.016 9.54868 10.016 8.99386C10.016 8.43905 9.56795 7.99093 9.01329 7.99093H7.01329C5.35995 7.99093 4.01062 9.33529 4.01062 10.9944V11.3252C4.01062 12.7762 5.04529 14.0192 6.47462 14.2753L7.24795 14.414C6.49595 14.9635 6.01062 15.849 6.01062 16.8306V16.9907C6.01062 18.6444 7.35462 19.9941 9.01329 19.9941H11.0133V20.9971C11.0133 21.5519 11.4613 22 12.016 22C12.5706 22 13.0186 21.5519 13.0186 20.9971V19.9941H15.0186C16.672 19.9941 18.0213 18.6498 18.0213 16.9907V16.8306C18.0213 15.8437 17.5306 14.9581 16.8 14.4086L17.5573 14.2699C18.9866 14.0085 20.0213 12.7709 20.0213 11.3198V10.9891C20 9.33529 18.656 7.99093 17.0026 7.99093ZM10.9973 17.9936H8.99729C8.44795 17.9936 7.99462 17.5455 7.99462 16.9907V16.8306C7.99462 16.3131 8.39995 15.8757 9.00795 15.8223L10.9973 15.4596V17.9936ZM16 16.9907C16 17.5401 15.552 17.9936 14.9973 17.9936H12.9973V15.4596L15.0826 15.8384C15.5946 15.881 16 16.3185 16 16.836V16.9907Z" />
  </React.Fragment>,
  "Caduceus"
);

export default IconCaduceus;
