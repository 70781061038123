// Modified from https://github.com/jeetiss/tabs
import React, { useContext } from "react";
import cx from "classnames";
import styles from "./Tabs.module.scss";
import { TabElements, TabsState } from "components/common/ui/tabs/Tabs";

interface TabListProp {
  children: React.ReactNode;
  className?: string;
  contentClassName?: string;
  decorator?: "dot" | "line" | "none";
  tabBackground?: boolean;
}

export const TabList = React.memo(
  ({
    children,
    className,
    contentClassName,
    decorator = "dot",
    tabBackground,
  }: TabListProp) => {
    const [activeIndex] = useContext(TabsState);
    const { tabRefs } = useContext(TabElements);
    const activeTab = tabRefs[activeIndex] || {};
    const width = activeTab?.current?.offsetWidth;
    const left = activeTab?.current?.offsetLeft;

    return (
      <div
        className={cx(
          styles.tabListWrapper,
          tabBackground && styles.tabBackground,
          styles[`${decorator}Decorator`]
        )}
      >
        <div className={cx(styles.tabList, className)}>
          <div className={cx(styles.tabContent, contentClassName)}>
            {children}
            <div
              className={styles.decorator}
              style={{
                left,
                width,
              }}
            />
          </div>
          <div className={styles.tabShadow} />
        </div>
      </div>
    );
  }
);
