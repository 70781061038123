export const amplifyConfig = {
  Auth: {
    mandatorySignIn: true,
    region: process.env.REACT_APP_AWS_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
    identityPoolId: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_COGNITO_APP_CLIENTID,
  },
  Storage: {
    region: process.env.REACT_APP_AWS_COGNITO_REGION,
    bucket: process.env.REACT_APP_AWS_S3_BUCKET,
    identityPoolId: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
  },
};
