import { useMutation, useQueryClient } from "react-query";
import { save } from "api/FacilityApi";
import axios from "axios";
import { facilityQueryKeys } from "queries/constants";
import { MutationOptions } from "queries/queries";
import { useToast } from "components/common/ui/toast/useToast";
import { Facility } from "models/facility/Facility";

export const useMutateFacility = (options: MutationOptions = {}) => {
  const queryClient = useQueryClient();
  const { addErrorToast, addToast } = useToast();

  return useMutation(
    ({ newFacility, oldFacility }: useMutateFacilityProps) =>
      save(newFacility, oldFacility),
    {
      onSuccess: (result, inputs) => {
        addToast(
          inputs.oldFacility.facility_id
            ? "Facility was successfully updated!"
            : "Facility was successfully created!",
          {
            theme: "success",
          }
        );

        queryClient.invalidateQueries(
          facilityQueryKeys.detail(inputs.oldFacility.facility_id)
        );
        queryClient.invalidateQueries(facilityQueryKeys.list());
      },
      onError: (e, inputs) => {
        if (axios.isAxiosError(e) && e.response?.status === 500) {
          addToast(
            `Facility name (${inputs.newFacility.facility_name}) is already taken`,
            {
              theme: "warning",
              delay: 6000,
            }
          );
        } else {
          addErrorToast(e, "Error: Unable to save User Details");
        }
      },
      ...options,
    }
  );
};

interface useMutateFacilityProps {
  newFacility: Partial<Facility>;
  oldFacility: Facility;
}
