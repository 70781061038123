import React from "react";
import Button from "components/common/ui/button/Button";
import { logFeedback } from "utils/errorUtils";
import { SUPPORT_EMAIL } from "constants/site";
import AuthLayout from "components/common/layouts/AuthLayout";
import styles from "./ErrorPage.module.scss";
import { FallbackRender } from "@sentry/react/dist/errorboundary";

const ErrorPage: FallbackRender = ({ resetError, eventId }) => {
  const onClick = () => {
    resetError();
    window.location.href = "/";
  };

  return (
    <AuthLayout variant="kraken">
      <h1 className={styles.title}>
        It looks like you've encountered an error!
      </h1>
      <div className={styles.description}>
        We've automatically logged the issue
        {logFeedback ? (
          <>
            <span>, but please feel free to provide more detail:</span>
            <br />
            <br />
            <Button onClick={() => logFeedback(eventId)} variant="outline">
              Provide More Detail
            </Button>
            <br />
            <br />
            <span>I</span>
          </>
        ) : (
          <span>, but i</span>
        )}
        f you keep having trouble please contact: <br />
        <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>
        <br />
        <br />
        <br />
        Otherwise, the link below will take you back to the home page
      </div>
      <div>
        <Button onClick={onClick} theme="primary">
          Back to Home
        </Button>
      </div>
    </AuthLayout>
  );
};

export default ErrorPage;
