import React from "react";
import createIcon from "components/common/icons/common/createIcon";

const IconSchool = createIcon(
  <React.Fragment>
    <path
      fill="currentColor"
      d="M12,3L1,9L12,15L21,10.09V17H23V9M5,13.18V17.18L12,21L19,17.18V13.18L12,17L5,13.18Z"
    />
  </React.Fragment>,
  "School"
);

export const IconSchoolOutline = createIcon(
  <React.Fragment>
    <path d="M12 3L1 9L5 11.18V17.18L12 21L19 17.18V11.18L21 10.09V17H23V9L12 3M18.82 9L12 12.72L5.18 9L12 5.28L18.82 9M17 16L12 18.72L7 16V12.27L12 15L17 12.27V16Z" />
  </React.Fragment>,
  "SchoolOutline"
);

export default IconSchool;
