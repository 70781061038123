import React from "react";
import cx from "classnames";
import getMonth from "date-fns/getMonth";
import styles from "components/common/ui/timeline/Gantt.module.scss";
import Tooltip from "components/common/ui/tooltip/Tooltip";
import { AnimateSharedLayout, motion } from "framer-motion";

const monthsDef = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
] as const;

export type Month = typeof monthsDef[number];

const getMonthList = (month: Month) => {
  const index = monthsDef.indexOf(month);
  return monthsDef.reduce((list: Month[], m, i) => {
    const indexModifier = i - index;
    const newIndex = indexModifier < 0 ? 12 + indexModifier : indexModifier;
    list[newIndex] = m;
    return list;
  }, []);
};

// Arrange bars in optimal(ish) layout
// Layout should prevent overlap while still allowing multiple events on one line
const arrangeBars = (bars: GanttItem[] = []) => {
  const slots: { start: number; end: number; items: any[] }[] = [];
  bars
    .sort((a, b) => a.start - b.start)
    .forEach((b) => {
      let slotted = false;
      const start = Math.min(b.start, b.end || 24);
      const end = Math.max(b.start, b.end || 0) + 0.1;
      slots.forEach((s, i) => {
        if (!slotted) {
          if (start > s.end || end <= s.start) {
            slots[i] = {
              start: Math.min(start, s.start),
              end: Math.max(end, s.end),
              items: [...s.items, b],
            };
            slotted = true;
          }
        }
      });
      if (!slotted) {
        slots.push({ start: start, end: end, items: [b] });
      }
    });
  return slots.reduce((d, s) => {
    // return [...d, ...s.items.sort((a, b) => a.start - b.start)];
    return [...d, ...s.items];
  }, [] as any[]);
};

const defaultCurrentMonth = monthsDef[getMonth(new Date())] || undefined;

export type GanttItem = {
  id?: string;
  start: number;
  end?: number;
  label?: React.ReactNode;
  theme?: ThemeVariants;
  fillVariant?: FillVariants;
  outlineTheme?: ThemeVariants;
  stripeTheme?: ThemeVariants;
  icon?: React.ReactNode;
  striped?: boolean;
  badges?: IBadge[];
  onClick?: (B: GanttItem, e: any) => void;
};

export interface GanttProps extends DefaultProps {
  items?: GanttItem[];
  label?: string;
  startMonth?: Month;
  currentMonth?: Month;
  noLabelColumn?: boolean;
}

const Gantt = ({
  label,
  items,
  startMonth = "Jan",
  currentMonth = defaultCurrentMonth,
  noLabelColumn,
  children,
  className,
  ...rest
}: GanttProps) => {
  const months = getMonthList(startMonth);
  return (
    <div
      className={cx(
        styles.root,
        noLabelColumn && styles.noLabelColumn,
        className
      )}
      {...rest}
    >
      <div className={styles.header}>
        {!noLabelColumn && <div className={styles.rowLabel} />}
        {months.map((m) => (
          <span key={m}>{m}</span>
        ))}
      </div>
      <div className={styles.verticalLines}>
        {months.map((m, i) => (
          <span
            key={m}
            className={cx(
              i - (noLabelColumn ? 0 : 1) === months.indexOf(currentMonth) &&
                styles.marker
            )}
          />
        ))}
      </div>
      <AnimateSharedLayout type="crossfade">
        {children || (
          <GanttRow items={items} noLabelColumn={noLabelColumn} label={label} />
        )}
      </AnimateSharedLayout>
    </div>
  );
};

interface RowProps extends DefaultProps {
  label?: React.ReactNode;
  items?: GanttItem[];
  barComponent?: React.ComponentType<GanttItem>;
  noLabelColumn?: boolean;
}

export const GanttRow = ({
  items,
  label,
  children,
  barComponent: Component = GanttBar,
  noLabelColumn,
}: RowProps) => {
  const arrangedBars = arrangeBars(items);
  return (
    <motion.div className={styles.row} layout>
      {!noLabelColumn && <div className={styles.rowLabel}>{label}</div>}
      <motion.ul className={styles.bars}>
        {children ||
          arrangedBars.map((b, i) => <Component {...b} key={b.id || i} />)}
      </motion.ul>
    </motion.div>
  );
};

export const GanttBar = ({
  theme = "primary",
  fillVariant = "filled",
  stripeTheme,
  onClick,
  ...props
}: GanttItem) => {
  const clickProps = onClick
    ? {
        onClick: (e: any) => onClick(props, e),
      }
    : {};
  const rawStart = Math.min(props.start, props.end || 13) + 0.5;
  const start = Math.max(rawStart, 0.5) * 2;
  const end = Math.min(Math.max(props.start, props.end || 0, 0) + 1, 13) * 2;
  const content = (
    <motion.li
      // layout="position"
      layout
      layoutId={props.id}
      whileHover={{
        scale: 1.04,
        opacity: props.striped ? 0.8 : 1,
        transition: { duration: 0.2 },
      }}
      style={{
        gridColumn: `${start}/${end}`,
      }}
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: props.striped ? 0.6 : 1,
      }}
      className={cx(
        styles.bar,
        props.striped && styles.stripes,
        styles[theme],
        styles[`${fillVariant}-fill`],
        stripeTheme && styles[`stripe-${stripeTheme}`],
        props.outlineTheme && styles[`outline-${props.outlineTheme}`],
        !props.end && styles.event,
        rawStart <= 0 && styles.flatStart,
        end >= 26 && styles.flatEnd
      )}
      {...clickProps}
    >
      {props.icon && <span className={styles.icon}>{props.icon}</span>}
      <span className={styles.label}>{props.label}</span>
      {props.badges && props.badges.length > 0 && (
        <span className={styles.dotWrap}>
          {props.badges.map((b, i) => (
            <Dot {...b} index={i} key={i} />
          ))}
        </span>
      )}
    </motion.li>
  );

  return (end - start < 3 && props.label) ||
    (props.badges && props.badges.length > 0) ? (
    <Tooltip
      content={
        <div className={styles.tooltip}>
          {props.label}{" "}
          <span className={styles.badgeWrap}>
            {props.badges?.map((b, i) => (
              <Badge index={i} key={i} {...b} />
            ))}
          </span>
        </div>
      }
    >
      {content}
    </Tooltip>
  ) : (
    <>{content}</>
  );
};

interface IBadge {
  icon?: React.ReactNode;
  theme?: ThemeVariants;
  label?: React.ReactNode;
}

interface BadgeProps extends IBadge {
  index: number;
}

const Badge = ({ icon, theme = "default" }: BadgeProps) => (
  <span className={cx(styles.badge, styles[`badge-${theme}`])}>{icon}</span>
);

const Dot = ({ theme = "default", index }: BadgeProps) => (
  <span
    className={cx(styles.dot, styles[`badge-${theme}`])}
    style={{
      transform: calcAngle(index),
    }}
  />
);

const calcAngle = (index: number) => {
  const angle = ((30 * index - 145) * Math.PI) / 180;
  const x = 18 * Math.cos(angle);
  const y = -15 * Math.sin(angle);

  return `translate(${y}px, ${x}px)`;
};

export default Gantt;
