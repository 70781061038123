import React from "react";
import { Route, Switch } from "react-router-dom";
import { PRIVATE_ROUTES, PRIVATE_ROUTE_DETAILS } from "constants/routes";
import { USER_ROUTES } from "constants/routes/user";
import { CPD_ROUTES } from "constants/routes/cpd";
import { FACILITY_ROUTES } from "constants/routes/facility";
import { PEERGROUP_ROUTES } from "constants/routes/peergroup";
import Home from "components/home/viewHome";
import NotFound from "components/app/viewNotFound";
import ViewNotAllowed from "components/app/viewNotAllowed";
import { AnimatePresence } from "framer-motion";
import AppLayout from "components/common/layouts/AppLayout";
import RestrictedRoute from "components/common/auth/RestrictedRoute";
import AuthWrapper from "components/common/auth/AuthWrapper";
import ViewAdminUsers from "components/users/admin/viewAdminUsers";
import ViewMyUserDetail from "components/users/viewMyUserDetails";
import ViewUserDetail from "components/users/admin/viewUserDetails";
import ViewAdminCreateUser from "components/users/admin/viewAdminCreateUser";
import ViewTest from "components/app/viewTest";
import ViewAdminEditUser from "components/users/admin/viewAdminEditUser";
import ViewEditMyUser from "components/users/viewEditMyUser";
import ViewFacilities from "components/facilities/viewFacilities";
import ViewFacilityDetail from "components/facilities/viewFacilityDetails";
import ViewAdminCreateFacility from "components/facilities/viewAdminCreateFacility";
import ViewAdminEditFacility from "components/facilities/viewAdminEditFacility";
import ViewCreatePlan from "components/cpd/admin/ViewCreatePlan";
import ViewCPDPlanDetail from "components/cpd/viewCpdPlanDetail";
import ViewEditPlan from "components/cpd/admin/ViewEditPlan";
import ViewGoals from "components/cpd/viewGoals";
import ViewMyPlans from "components/cpd/viewMyPlans";
import ViewComingSoon from "components/app/viewComingSoon";
import ViewMyPlansList from "components/cpd/viewMyPlansList";
import ViewAdminPlansList from "components/cpd/admin/viewAdminUserPlansList";
import {
  ViewAdminCreatePeerGroup,
  ViewCreatePeerGroupMeeting,
  ViewAdminEditPeerGroup,
  ViewAdminEditPeerGroupMeeting,
  ViewAdminPeerGroups,
  ViewPeerGroupDetails,
  ViewPeerGroupMeeting,
  ViewUserPeerGroups,
} from "components/peergroup";
import { SUPERVISORS_ROUTES } from "constants/routes/supervisors";
import ViewMySupervisees from "components/supervisors/viewMySupervisees";
import ViewSuperviseeDetails from "components/supervisors/viewSuperviseeDetails";
import ViewSuperviseePlanDetail from "components/supervisors/viewSuperviseePlanDetail";
import ViewMyPotentialSupervisors from "components/supervisors/viewMyPotentialSupervisors";

const PrivateRoutes = (props: { location: any }) => (
  <AuthWrapper>
    <AppLayout>
      <AnimatePresence exitBeforeEnter>
        <Switch location={props.location} key={props.location.pathname}>
          <Route exact path={PRIVATE_ROUTES.HOME} component={Home} />
          {process.env.NODE_ENV === "development" && (
            <Route exact path={PRIVATE_ROUTES.TESTING} component={ViewTest} />
          )}
          <RestrictedRoute
            exact
            {...USER_ROUTES.MY_PROFILE}
            component={ViewMyUserDetail}
          />
          <RestrictedRoute
            exact
            {...USER_ROUTES.EDIT_MY_PROFILE}
            component={ViewEditMyUser}
          />
          <RestrictedRoute
            exact
            {...CPD_ROUTES.MY_PLANS}
            component={ViewMyPlans}
          />
          <RestrictedRoute
            exact
            {...CPD_ROUTES.MY_TPS}
            component={ViewMyPlans}
          />
          <RestrictedRoute
            exact
            {...CPD_ROUTES.MY_PLANS_ALL}
            component={ViewMyPlansList}
          />
          <RestrictedRoute
            exact
            {...CPD_ROUTES.MY_TPS_ALL}
            component={ViewMyPlansList}
          />
          <RestrictedRoute
            exact
            {...CPD_ROUTES.MY_PLAN_DETAIL}
            component={ViewCPDPlanDetail}
          />
          <RestrictedRoute
            exact
            {...CPD_ROUTES.MY_TPS_DETAIL}
            component={ViewCPDPlanDetail}
          />
          <RestrictedRoute
            exact
            {...CPD_ROUTES.MY_GOALS}
            component={ViewGoals}
          />
          <RestrictedRoute
            exact
            {...CPD_ROUTES.MY_GOALS_TPS}
            component={ViewGoals}
          />
          <RestrictedRoute
            exact
            {...CPD_ROUTES.ADMIN_PLANS_LIST}
            // component={ViewAdminPlans}
            component={ViewComingSoon}
          />
          <RestrictedRoute
            exact
            {...CPD_ROUTES.ADMIN_PLANS_LIST}
            // component={ViewAdminPlans}
            component={ViewComingSoon}
          />
          <RestrictedRoute
            exact
            {...CPD_ROUTES.ADMIN_USER_PLANS}
            component={ViewAdminPlansList}
          />
          <RestrictedRoute
            exact
            {...CPD_ROUTES.ADMIN_USER_TPS}
            component={ViewAdminPlansList}
          />
          <RestrictedRoute
            exact
            {...CPD_ROUTES.ADMIN_CREATE_PLAN}
            component={ViewCreatePlan}
          />
          <RestrictedRoute
            exact
            {...CPD_ROUTES.ADMIN_CREATE_PLAN_TPS}
            component={ViewCreatePlan}
          />
          <RestrictedRoute
            exact
            {...CPD_ROUTES.ADMIN_PLAN_DETAIL}
            component={ViewCPDPlanDetail}
          />
          <RestrictedRoute
            exact
            {...CPD_ROUTES.ADMIN_PLAN_DETAIL_TPS}
            component={ViewCPDPlanDetail}
          />
          <RestrictedRoute
            exact
            {...CPD_ROUTES.ADMIN_EDIT_PLAN}
            component={ViewEditPlan}
          />
          <RestrictedRoute
            exact
            {...CPD_ROUTES.ADMIN_EDIT_PLAN_TPS}
            component={ViewEditPlan}
          />
          <RestrictedRoute
            exact
            {...CPD_ROUTES.ADMIN_USER_GOALS}
            component={ViewGoals}
          />
          <RestrictedRoute
            exact
            {...PRIVATE_ROUTE_DETAILS.LEARNING_RESOURCES}
            component={ViewComingSoon}
          />
          <RestrictedRoute
            exact
            {...USER_ROUTES.ADMIN_USERS}
            component={ViewAdminUsers}
          />
          <RestrictedRoute
            exact
            {...USER_ROUTES.ADMIN_USER_DETAIL}
            component={ViewUserDetail}
          />
          <RestrictedRoute
            exact
            {...USER_ROUTES.ADMIN_CREATE_USER}
            component={ViewAdminCreateUser}
          />
          <RestrictedRoute
            exact
            {...USER_ROUTES.ADMIN_EDIT_USER}
            component={ViewAdminEditUser}
          />
          <RestrictedRoute
            exact
            {...FACILITY_ROUTES.ADMIN_FACILITIES}
            component={ViewFacilities}
          />
          <RestrictedRoute
            exact
            {...FACILITY_ROUTES.ADMIN_CREATE_FACILITY}
            component={ViewAdminCreateFacility}
          />
          <RestrictedRoute
            exact
            {...FACILITY_ROUTES.ADMIN_FACILITY_DETAIL}
            component={ViewFacilityDetail}
          />
          <RestrictedRoute
            exact
            {...FACILITY_ROUTES.ADMIN_EDIT_FACILITY}
            component={ViewAdminEditFacility}
          />
          <RestrictedRoute
            exact
            {...PRIVATE_ROUTE_DETAILS.ADMIN_REPORTS}
            component={ViewComingSoon}
          />
          <RestrictedRoute
            exact
            {...PEERGROUP_ROUTES.ADMIN_PEERGROUPS}
            component={ViewAdminPeerGroups}
          />
          <RestrictedRoute
            exact
            {...PEERGROUP_ROUTES.USER_PEERGROUPS}
            component={ViewUserPeerGroups}
          />
          <RestrictedRoute
            exact
            {...PEERGROUP_ROUTES.PEERGROUP_DETAIL}
            component={ViewPeerGroupDetails}
          />
          <RestrictedRoute
            exact
            {...PEERGROUP_ROUTES.ADMIN_CREATE_PEERGROUP}
            component={ViewAdminCreatePeerGroup}
          />
          <RestrictedRoute
            exact
            {...PEERGROUP_ROUTES.ADMIN_EDIT_PEERGROUP}
            component={ViewAdminEditPeerGroup}
          />
          <RestrictedRoute
            exact
            {...PEERGROUP_ROUTES.CREATE_PEERGROUP_MEETING}
            component={ViewCreatePeerGroupMeeting}
          />
          <RestrictedRoute
            exact
            {...PEERGROUP_ROUTES.PEERGROUP_MEETING_DETAIL}
            component={ViewPeerGroupMeeting}
          />
          <RestrictedRoute
            exact
            {...PEERGROUP_ROUTES.EDIT_PEERGROUP_MEETING}
            component={ViewAdminEditPeerGroupMeeting}
          />

          <RestrictedRoute
            exact
            {...SUPERVISORS_ROUTES.MY_POTENTIAL_SUPERVISORS}
            component={ViewMyPotentialSupervisors}
          />

          <RestrictedRoute
            exact
            {...SUPERVISORS_ROUTES.MY_SUPERVISEES}
            component={ViewMySupervisees}
          />
          <RestrictedRoute
            exact
            {...SUPERVISORS_ROUTES.SUPERVISEE_DETAIL}
            component={ViewSuperviseeDetails}
          />
          <RestrictedRoute
            exact
            {...SUPERVISORS_ROUTES.SUPERVISEE_PLAN_DETAIL}
            component={ViewSuperviseePlanDetail}
          />
        </Switch>
      </AnimatePresence>
    </AppLayout>
  </AuthWrapper>
);

const Routes = () => (
  <Switch>
    <Route
      exact
      path={Object.values(PRIVATE_ROUTES)}
      key={"app"}
      component={PrivateRoutes}
    />
    <Route
      exact
      path={"/forbidden"}
      component={ViewNotAllowed}
      key={"forbidden"}
    />
    <Route component={NotFound} key={"notfound"} />
  </Switch>
);

export default Routes;
