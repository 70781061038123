import React from "react";

// import PhoneInput, {PhoneInputProps} from "components/common/ui/form-elements/phone/PhoneInput";
import PhoneInput from "react-phone-number-input/react-hook-form-input";

import { Control } from "react-hook-form/dist/types/form";

import {
  InputWrapper,
  InputWrapperProps,
} from "components/common/ui/form-elements/common/InputWrapper";
import styles from "components/common/ui/form-elements/common/Input.module.scss";
import { IconPhone } from "components/common/icons/twoToneIcons";

interface Props extends InputWrapperProps {
  control: Control;
}

const HookFormPhoneInput = ({
  name,
  label,
  control,
  icon = IconPhone,
  ...rest
}: Props) => (
  <InputWrapper label={label} name={name} icon={icon} {...rest}>
    <PhoneInput
      className={styles.input}
      name={name}
      label={label}
      control={control}
      defaultCountry="NZ"
      onChange={() => {}}
    />
  </InputWrapper>
);
//@ts-ignore

export default HookFormPhoneInput;
