import React from "react";
import cx from "classnames";
import styles from "./WaveBackground.module.scss";

interface Props {
  className?: string;
  mobile?: boolean;
}
const AppWaveBackground = (props: Props) => (
  <>
    {props.mobile ? (
      <MobileAppWaveBg {...props} />
    ) : (
      <DesktopAppWaveBg {...props} />
    )}
  </>
);

const MobileAppWaveBg = ({ className }: Props) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 375 751"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="none"
    className={cx(styles.root, className)}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 592.107V500.542C44.3058 486.038 90.7827 476.175 145.274 482.327C222.004 490.99 277.376 527.461 332.594 563.83C346.623 573.071 360.643 582.305 375 591.074V750.714C356.986 736.547 339.418 721.392 321.791 706.185L320.955 705.463C256.197 649.597 190.924 593.286 101.265 583.164C65.3623 579.11 31.9475 583.186 0 592.107Z"
      fill="url(#app_wave_bg_840108_paint0_linear)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 741.747V660.294C14.9116 651.571 31.0133 643.402 48.7031 635.966C120.301 605.87 183.254 602.542 246.031 599.223H246.032C288.242 596.991 330.373 594.763 375 584.406V693.425C346.491 693.841 318.091 692.341 289.564 690.834L288.55 690.781C209.992 686.632 130.809 682.451 47.1469 717.618C30.1761 724.752 14.5529 732.861 0 741.747Z"
      fill="url(#app_wave_bg_840108_paint1_linear)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 552.105V468.383C42.3218 437.911 89.218 408.971 148.698 383.968C231.73 349.066 304.108 336.778 375 327.343V420.41C299.025 425.428 221.318 436.437 138.012 471.455C84.269 494.046 39.784 521.758 0 552.105Z"
      fill="url(#app_wave_bg_840108_paint2_linear)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M375 110.771C336.679 106.053 299.727 98.8247 261.334 91.3148C181.843 75.766 101.72 60.0935 2.29749 68.1531C1.53018 68.2153 0.764352 68.2787 0 68.3433L0 0H375V110.771Z"
      fill="url(#app_wave_bg_840108_paint3_linear)"
    />
    <defs>
      <linearGradient
        id="app_wave_bg_840108_paint0_linear"
        x1="81.2305"
        y1="534.608"
        x2="338.548"
        y2="531.907"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4B3F68" stopOpacity="0.31" />
        <stop offset="1" stopColor="#836AB9" stopOpacity="0.15" />
      </linearGradient>
      <linearGradient
        id="app_wave_bg_840108_paint1_linear"
        x1="81.2305"
        y1="615.929"
        x2="338.493"
        y2="611.291"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4B3F68" stopOpacity="0.31" />
        <stop offset="1" stopColor="#836AB9" stopOpacity="0.15" />
      </linearGradient>
      <linearGradient
        id="app_wave_bg_840108_paint2_linear"
        x1="81.2305"
        y1="372.374"
        x2="338.535"
        y2="369.127"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4B3F68" stopOpacity="0.31" />
        <stop offset="1" stopColor="#836AB9" stopOpacity="0.15" />
      </linearGradient>
      <linearGradient
        id="app_wave_bg_840108_paint3_linear"
        x1="-247.079"
        y1="80.5899"
        x2="542.902"
        y2="118.2"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4B3F68" stopOpacity="0.31" />
        <stop offset="1" stopColor="#836AB9" stopOpacity="0.15" />
      </linearGradient>
    </defs>
  </svg>
);

const DesktopAppWaveBg = ({ className }: Props) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 654 763"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="none"
    className={cx(styles.root, className)}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 592.107V500.542C44.3058 486.038 90.7827 476.175 145.274 482.327C222.004 490.99 277.376 527.461 332.594 563.83C387.506 599.998 442.265 636.065 517.724 644.585C567.453 650.199 611.742 642.22 654 629.562V763H391.114C367.372 745.508 344.631 725.889 321.791 706.185L320.955 705.463C256.197 649.597 190.924 593.286 101.265 583.164C65.3623 579.11 31.9475 583.186 0 592.107Z"
      fill="url(#app_wave_bg_840108_paint0_linear)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 741.747V660.294C14.9116 651.571 31.0133 643.402 48.7031 635.966C120.301 605.87 183.254 602.542 246.032 599.223C308.461 595.922 370.717 592.63 441.129 563.032C512.296 533.118 560.123 489.643 607.572 446.511C623.145 432.355 638.32 418.56 654 405.409V570.443C617.526 608.362 577.19 642.339 522.386 665.376C442.594 698.917 366.544 694.9 289.564 690.834L288.55 690.781C209.992 686.632 130.809 682.451 47.1469 717.618C30.1761 724.752 14.5529 732.861 0 741.747Z"
      fill="url(#app_wave_bg_840108_paint1_linear)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 552.105V468.383C42.3218 437.911 89.218 408.971 148.698 383.968C243.623 344.066 324.623 333.722 405.398 323.407L405.398 323.407C484.235 313.339 562.857 303.298 654 265.832V395.745C584.907 411.317 518.436 413.99 451.412 416.685L450.118 416.738C349.923 420.768 248.931 424.83 138.012 471.455C84.269 494.046 39.784 521.758 0 552.105Z"
      fill="url(#app_wave_bg_840108_paint2_linear)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M654 86.3141C610.887 99.4469 564.82 109.839 512.029 114.118C417.209 121.805 342.49 107.189 261.334 91.3148C181.843 75.766 101.72 60.0935 2.29749 68.1531C1.53018 68.2153 0.764352 68.2787 0 68.3433L0 0H391.395C466.647 21.8675 540.188 35.0072 631.064 27.6404C638.822 27.0115 646.465 26.2297 654 25.3055V86.3141Z"
      fill="url(#app_wave_bg_840108_paint3_linear)"
    />
    <defs>
      <linearGradient
        id="app_wave_bg_840108_paint0_linear"
        x1="81.2305"
        y1="534.608"
        x2="338.548"
        y2="531.907"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4B3F68" stopOpacity="0.31" />
        <stop offset="1" stopColor="#836AB9" stopOpacity="0.15" />
      </linearGradient>
      <linearGradient
        id="app_wave_bg_840108_paint1_linear"
        x1="81.2305"
        y1="615.929"
        x2="338.493"
        y2="611.291"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4B3F68" stopOpacity="0.31" />
        <stop offset="1" stopColor="#836AB9" stopOpacity="0.15" />
      </linearGradient>
      <linearGradient
        id="app_wave_bg_840108_paint2_linear"
        x1="81.2305"
        y1="372.374"
        x2="338.535"
        y2="369.127"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4B3F68" stopOpacity="0.31" />
        <stop offset="1" stopColor="#836AB9" stopOpacity="0.15" />
      </linearGradient>
      <linearGradient
        id="app_wave_bg_840108_paint3_linear"
        x1="-247.079"
        y1="80.5899"
        x2="542.902"
        y2="118.2"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4B3F68" stopOpacity="0.31" />
        <stop offset="1" stopColor="#836AB9" stopOpacity="0.15" />
      </linearGradient>
    </defs>
  </svg>
);

export default AppWaveBackground;
