import { IColumn } from "components/common/ui/table/utils";
import React from "react";
import Table from "components/common/ui/table/Table";
import { PotentialSupervisors } from "models/supervisors/Supervisor";

interface Props extends DefaultProps {
  supervisors: PotentialSupervisors[];
  isLoading?: boolean;
}

export const PotentialSupervisorsList = ({ supervisors, isLoading }: Props) => {
  const columns: IColumn<Record<string, PotentialSupervisors>>[] = [
    {
      id: "supervisor_name",
      label: "Name",
      type: "textEmphasis",
      cell: (c, row) =>
        `${row.metadata.user_given_name}  ${row.metadata.user_family_name}  `,
    },
    {
      id: "supervisor_email",
      label: "Email",
      type: "textEmphasis",
      cell: (c, row) => `${row.metadata.user_email} `,
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        data={supervisors || []}
        loading={isLoading}
        placeholder={{
          noDataLabel: "No Potential Supervisor Found",
          loadingRows: 5,
        }}
      />
    </>
  );
};
