import { useOnMount } from "hooks/useOnMount";
import { coreService } from "services/CoreService";

const loadBlockingResources = async () => {
  try {
    const userLoaded = await coreService.getCurrentUser();
    if (userLoaded) {
      coreService.setAppLoaded(true);
    } else {
      coreService.setAppError({
        name: "AppOnLoad",
        message: "App failed to Load",
      });
    }
  } catch (e) {
    coreService.setAppError(e);
  }
};

const OnLoad = () => {
  const mount = async () => {
    await loadBlockingResources();

    //load non-blocking resources

    // schemaService.loadSchema();
  };
  useOnMount(() => {
    mount();
  });
  return null;
};

export default OnLoad;
