import { TBucket } from "models/Dimension";
import { convertToDate } from "utils/format/dateUtils";

export const exactMatch = (match: TBucket, value: string) => {
  return typeof match === "string" ? value === match : false;
};

export const exactArrayMatch = (match: TBucket, values: string[]) => {
  return typeof match === "string" ? values.includes(match) : false;
};
export const facetMatchString = (matches: TBucket, value: string) => {
  return Array.isArray(matches) && matches.includes("" + value);
};
export const facetMatchArray = (matches: TBucket, values: string[]) => {
  return Array.isArray(matches) && matches.some((r) => values.includes(r));
};

export const fuzzyMatch = (match: TBucket, value: string) => {
  if (typeof match !== "string") {
    return false;
  }
  const v = ("" + value).toLowerCase();
  const queryParts = match
    .toLowerCase()
    .replace(/[()]/g, "")
    .split(" ")
    .map((q) => q.trim());

  return queryParts.reduce((test, q) => {
    return v.includes(q) && test;
  }, true);
};

export const fuzzyMatchArray = (match: string, values: string[]) => {
  const p = match.toLowerCase();
  return values.reduce((testAll, query) => {
    const queryParts = query
      .toLowerCase()
      .replace(/[()]/g, "")
      .split(" ")
      .map((q) => q.trim());

    return queryParts.reduce((test, q) => {
      return p.indexOf(q) > -1 && test;
    }, true);
  }, true);
};

export const matchDateRange = (range: TBucket, value: string) => {
  if (range && Array.isArray(range) && range.length === 2) {
    const d = convertToDate(value);
    const start = convertToDate(range[0]);
    const end = convertToDate(range[1]);
    if (!start && !end) {
      // if start & end are null then no filter applied
      return true;
    }
    return (!start || d! >= start) && (!end || d! < end);
  }
  // return true because range isn't in [start, end] format
  return true;
};
