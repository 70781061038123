import React from "react";
import axios from "axios";
import ToastErrorContent from "components/common/ui/error/ToastErrorContent";

export const verifyCaughtError = (e: unknown) => {
  return typeof e === "string" || e instanceof Error;
};

export const isAuthError = (e: unknown): e is AuthError =>
  e === null ||
  ((e as ValidAuthError).code !== undefined &&
    typeof (e as ValidAuthError).code === "string" &&
    (e as ValidAuthError).message !== undefined &&
    typeof (e as ValidAuthError).message === "string");

export const catchAuthError = (e: unknown): AuthError =>
  isAuthError(e) ? e : null;

const isRequestError = (e: unknown): e is RequestError =>
  axios.isAxiosError(e) ||
  ((e as RequestError).serverMessage !== undefined &&
    typeof (e as RequestError).serverMessage === "string");

export const catchRequestErrorMessage = (e: unknown, fallback: string) =>
  isRequestError(e)
    ? React.createElement(
        ToastErrorContent,
        {
          title: fallback,
          content:
            e.serverMessage ||
            e.response?.data?.message ||
            e.response?.data?.Message ||
            "",
        },
        null
      )
    : fallback;
