import React from "react";
import cx from "classnames";
import styles from "components/common/layouts/appLayout/mobileMenu/MobileMenu.module.scss";

interface Props {
  toggleMobileNav: () => void;
  isMobileOpened: boolean;
}

const MobileMenu = ({ toggleMobileNav, isMobileOpened }: Props) => (
  <button
    onClick={toggleMobileNav}
    className={cx(styles.root, styles.squeeze, isMobileOpened && styles.open)}
  >
    <div className={styles.box}>
      <div className={styles.inner} />
    </div>
  </button>
);

export default MobileMenu;
