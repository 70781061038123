import { keyBy, removeUndefinedFromObject } from "utils/common";
import { DataAttribute } from "components/common/ui/form-elements/formElements";

type MemberRoleTypes = "Coordinator" | "Member";
type MemberRoles = { key: MemberRoleTypes; value: string };
export interface PeerGroupMember {
  peergroup_id?: string;
  // peergroup_role_id?: string;
  // peergroup_role_name?: string;
  user_id: string;
  given_name?: string;
  family_name?: string;
  preferred_name?: string;
  email?: string;
  phone_number?: number;

  peergroup_member_roles?: MemberRoles[];
  peergroup_member_deleted?: string | number;
  // peergroup_member_date_added?: Date | string;
  // peergroup_member_date_updated?: Date | string;
  deleted?: boolean;
  accepted?: boolean;
}

export interface IServerPeerGroupMember
  extends Omit<PeerGroupMember, "user_id"> {
  // id: string;
  peergroup_id: string;
  peergroup_member_date_added: string;
  peergroup_member_date_updated: string;
  peergroup_member_email: string;
  peergroup_member_family_name: string;
  peergroup_member_first_name: string;
  peergroup_member_id: string;
  peergroup_member_phone_number: string;
  peergroup_member_preferred_name?: string;

  peergroup_members_deleted?: number | boolean;
}

const blankPeerGroupMember: PeerGroupMember = {
  user_id: "",
  given_name: "",
  family_name: "",
  deleted: false as boolean,
  peergroup_member_roles: [],
};

export const createPeerGroupMembers = (
  data: Partial<PeerGroupMember> = {}
): PeerGroupMember => {
  const cleanedData = removeUndefinedFromObject(data);
  return {
    ...blankPeerGroupMember,
    ...cleanedData,
  };
};
export const toServerPeerGroupMemberObject = ({
  peergroup_member_roles,
  ...rest
}: Partial<PeerGroupMember>): Partial<IServerPeerGroupMember> => {
  return {
    // peergroup_member_roles: peergroup_member_roles?.reduce(
    //   (map, d) => ({ ...map, [d.key]: d.value }),
    //   {} as ServerMemberRoles
    // ),
    ...rest,
  };
};

export const fromApiToPeerGroupMember = ({
  deleted,
  peergroup_member_deleted,
  peergroup_member_email,
  peergroup_member_family_name,
  peergroup_member_first_name,
  peergroup_member_id,
  ...peergroup_member
}: IServerPeerGroupMember): PeerGroupMember => {
  return createPeerGroupMembers({
    ...peergroup_member,
    user_id: peergroup_member_id,
    family_name: peergroup_member_family_name,
    given_name: peergroup_member_first_name,
    email: peergroup_member_email,
    deleted: peergroup_member_deleted === 1,
    peergroup_member_deleted,
  });
};

export type PeerGroupMemberAttribute = DataAttribute<keyof PeerGroupMember>;

export const peerGroupMemberAttributes: PeerGroupMemberAttribute[] = [
  {
    name: "peergroup_member_roles",
    label: "Peer Group Role",
    type: "enum",
    layout: {
      type: "select",
    },
  },
  {
    name: "user_id",
    label: "User",
    type: "enum",
    layout: {
      type: "select",
    },
  },
];

export const peerGroupMemberAttributesMap = {
  ...keyBy(peerGroupMemberAttributes, "name"),
} as const;
export const peerGroupMemberAttributeKeys = Object.keys(
  peerGroupMemberAttributesMap
);
// export const peerGroupMemberFormQuestionIDs = peerGroupMemberAttributeKeys;
