import React, { ChangeEvent } from "react";
import classNames from "classnames";
import {
  InputWrapper,
  InputWrapperProps,
} from "components/common/ui/form-elements/common/InputWrapper";
import styles from "components/common/ui/form-elements/common/Input.module.scss";

export interface ITextArea extends InputWrapperProps {
  defaultValue?: string;
  value?: string;
  setValue?: (
    name: string,
    value: any,
    config?: { shouldValidate?: boolean; shouldDirty?: boolean }
  ) => void;
  rows?: number;
}

const TextArea = React.forwardRef(
  (
    {
      name,
      label,
      description,
      disabled,
      required,
      className,
      error,
      placeholder,
      defaultValue,
      value,
      setValue,
      icon,
      config,
      hint,
      rows = 6,
      ...rest
    }: ITextArea,
    ref: React.Ref<any>
  ) => {
    const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
      setValue && setValue(name, e.target.value);
    };
    const props = defaultValue ? { defaultValue } : { value, onChange };
    return (
      <InputWrapper
        name={name}
        error={error}
        hint={hint}
        label={label}
        description={description}
        disabled={disabled}
        className={className}
        icon={icon}
        config={config}
      >
        <textarea
          id={`text-area-${name}`}
          name={name}
          disabled={disabled}
          className={classNames(styles.input, styles.area)}
          ref={ref}
          required={required}
          placeholder={placeholder}
          aria-labelledby={`text-area-label-${name}`}
          aria-describedby={error && `error-${name}-${error?.type}`}
          aria-invalid={!!error}
          rows={rows}
          tabIndex={disabled ? -1 : undefined}
          {...props}
          {...rest}
        />
      </InputWrapper>
    );
  }
);

export default TextArea;
