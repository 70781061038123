import React, { useContext } from "react";
import { generateTimestampID, generateUEID } from "utils/common";
import { IToastOptions, ToastContext } from "components/common/ui/toast/Toast";
import { catchRequestErrorMessage } from "utils/catchErrorUtils";

export const useToast = () => {
  const ctx = useContext(ToastContext);
  if (!ctx) {
    throw Error(
      "The `useToasts` hook must be called from a descendent of the `ToastProvider`."
    );
  }
  const addToast = (
    content: string | React.ReactNode,
    { id, ...options }: IToastOptions = {},
    forceAdd?: boolean
  ) => {
    const genId = id ? generateTimestampID(id) : generateUEID();
    // console.log(id);
    //exit if toast exists
    if (!forceAdd && ctx.toasts.filter((t) => t.id === id).length) {
      return;
    }
    ctx.add({
      content,
      options: { id: genId, timeStampId: false, ...options },
    });
  };

  //special category of preformatted toasts
  const addErrorToast = (
    e: RequestError | unknown,
    defaultMessage = "Error",
    options?: IToastOptions
  ) =>
    addToast(catchRequestErrorMessage(e, defaultMessage), {
      autoDismiss: false,
      theme: "danger",
      ...options,
    });

  return {
    addToast: addToast,
    addErrorToast: addErrorToast,
    removeToast: ctx.remove,
    toastStack: ctx.toasts,
  };
};
