import React from "react";
import { useForm } from "react-hook-form";
import styles from "components/cpd/activity/edit/ActivityEditForm.module.scss";
import FormQuestion from "components/common/ui/form-elements/form-layout/FormQuestion";
import ConditionalLogicWrapperHookForm from "components/common/ui/form-elements/hook-form-elements/ConditionalLogicWrapperHookForm";
import {
  Activity,
  createActivity,
  // peergroupCategoryMeeting,
} from "models/cpd/Activity";
import { Category } from "models/cpd/common/common";
import {
  activityAttributes,
  activitySchema,
  categoryConditional,
  adminOnlyCompleteConditional,
} from "components/cpd/utils/activityForm";
import { SubCategoryQuestion } from "components/cpd/activity/SubCategoryQuestion";
import { useMutateActivity } from "components/cpd/hooks/useMutateActivity";
import IconClock from "components/common/icons/IconClock";
import Spacer from "components/common/ui/spacer/Spacer";
import HintBox from "components/common/ui/hint-box/HintBox";
import { convertToDate, formatDate } from "utils/format/dateUtils";
import { Control, UseFormRegister } from "react-hook-form/dist/types/form";
import IconWarning from "components/common/icons/IconWarning";
import { ActivityFormActions } from "components/cpd/activity/edit/ActivityFormActions";
import { ActivityEditProps } from "components/cpd/activity/edit/activityEdit";
import { useUserContext } from "components/cpd/context/UserContext";
import DeliveryLocation from "components/cpd/activity/edit/DeliveryLocation";
import Chip from "components/common/ui/chip/Chip";
import PeerGroupMeetingCombobox from "components/cpd/common/peergroup-meeting-selection/PeerGroupMeetingCombobox";
import { getPathwayCategoryOptions } from "components/cpd/utils/utils";
import { usePlanDetail } from "components/cpd/hooks/usePlanDetails";
import RegistrarConcernsFlag from "components/cpd/activity/detail/RegistrarConcernsFlag";
import { checkConditionals } from "utils/formUtils";

const EditDetails = ({
  onCancel,
  activity,
  planId,
  userId,
  goals,
  year,
  setActivityId,
  disabled,
  isAdmin,
}: ActivityEditProps) => {
  const { user } = useUserContext();
  const {
    isLoading: isPlanLoading,
    data: { pathway, requirements },
  } = usePlanDetail(planId ?? "", userId, { enabled: !!planId });
  const {
    handleSubmit,
    formState: { isDirty, errors },
    register,
    control,
    setValue,
  } = useForm({
    mode: "onSubmit",
    defaultValues: createActivity(activity) as Record<string, any>,
    resolver: activitySchema,
  });

  const { mutateAsync: mutateActivity, isLoading: isSubmitting } =
    useMutateActivity();

  const includeDates = {
    minDate: convertToDate(year.start),
    maxDate: convertToDate(year.end),
  };

  const handleSave = async (newActivity: Partial<Activity>) => {
    if (
      !isAdmin &&
      checkConditionals(newActivity, adminOnlyCompleteConditional) &&
      !activity?.completed_at
    ) {
      newActivity.completed_at = null;
    }

    return await mutateActivity({
      newActivity: createActivity({ plan_id: planId, ...newActivity }),
      userId,
      oldActivity: activity ? createActivity(activity) : undefined,
    });
  };

  const onSubmit = async (newActivity: Partial<Activity>) => {
    // handleSave if form values have been changed
    if (isDirty) {
      try {
        await handleSave(newActivity);
        onCancel();
      } catch (e) {}
    } else {
      onCancel();
    }
  };

  const onNext = async () => {
    return new Promise((resolve) => {
      handleSubmit(
        async (v: any) => {
          // handleSave if form values have been changed
          if (isDirty) {
            const data = await handleSave(v);
            // set activity id if undefined to subsequent steps use new activity item
            if (!activity?.id) {
              setActivityId(data.id);
            }
          }
          resolve(true);
        },
        () => {
          resolve(false);
        }
      )();
    });
  };

  const sharedProps = {
    errors,
    register,
    control,
    disabled,
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate className={styles.root}>
      <FormQuestion question={activityAttributes.keyTopic} {...sharedProps} />
      <div className={styles.row}>
        <FormQuestion
          question={{
            ...activityAttributes.typeOfActivity,
            options: isPlanLoading
              ? []
              : getPathwayCategoryOptions(
                  pathway,
                  Object.keys(requirements) as Category[]
                ),
          }}
          {...sharedProps}
          disabled={!isAdmin && activity?.is_required}
        />

        <SubCategoryQuestion
          {...sharedProps}
          disabled={!isAdmin && activity?.lock_subcategory}
        />
        <Spacer />

        <FormQuestion
          question={{
            options: goals.map((g) => ({
              value: g.goal_id,
              label: g.goal_name,
              disabled: g.goal_status === "complete",
            })),
            ...activityAttributes.assignToGoal,
          }}
          {...sharedProps}
        />
      </div>

      <ConditionalLogicWrapperHookForm
        control={control}
        conditional={
          categoryConditional.peergroupCategoryMeetingConditional.conditional
        }
      >
        <PeerGroupMeetingCombobox
          // question={transformMeetingsToMenuItem(fakeMeetingsData)}
          question={[]}
          // defaultValue={[]}
          control={sharedProps.control}
          userId={userId}
          activityId={activity?.id}
          planId={planId ?? ""}
          year={year}
          setValue={setValue}
        />
      </ConditionalLogicWrapperHookForm>
      <ConditionalLogicWrapperHookForm
        control={control}
        conditional={activityAttributes.subCategoryOther.conditional}
      >
        <FormQuestion
          question={activityAttributes.subCategoryOther}
          {...sharedProps}
        />
      </ConditionalLogicWrapperHookForm>

      <ConditionalLogicWrapperHookForm
        control={control}
        conditional={activityAttributes.location.conditional}
      >
        <>
          <hr />
          <b>Workplace(s) where Clinical hours were delivered</b>
          <DeliveryLocation options={user?.facilities || []} {...sharedProps} />
          <FormQuestion
            question={activityAttributes.otherLocation}
            {...sharedProps}
          />
        </>
      </ConditionalLogicWrapperHookForm>

      <ConditionalLogicWrapperHookForm
        control={control}
        conditional={[
          {
            field: "category",
            values: [Category.tp_supervision],
          },
        ]}
      >
        <RegistrarConcernsFlag userId={userId} control={control} />
      </ConditionalLogicWrapperHookForm>
      <hr />

      <ConditionalLogicWrapperHookForm
        control={control}
        conditional={activityAttributes.grade_result.conditional}
      >
        <div className={styles.row}>
          <FormQuestion
            question={activityAttributes.letter_grade}
            {...sharedProps}
          />
          <FormQuestion
            question={activityAttributes.grade_result}
            {...sharedProps}
          />
        </div>
      </ConditionalLogicWrapperHookForm>
      <ConditionalLogicWrapperHookForm
        control={control}
        conditional={[
          {
            field: "completed_at",
            showIfEmpty: true,
          },
        ]}
      >
        <div className={styles.row}>
          <FormQuestion
            question={activityAttributes.dateStartedPlanned}
            errors={errors}
            register={register}
            control={control}
            disabled={disabled}
            {...includeDates}
          />
          <FormQuestion
            question={activityAttributes.datePlanned}
            errors={errors}
            register={register}
            control={control}
            {...includeDates}
            disabled={disabled}
          />
          {activity?.required_by && (
            <Chip theme="danger" className={styles.requiredChip}>
              Required By: {formatDate(activity?.required_by)}
            </Chip>
          )}
        </div>
      </ConditionalLogicWrapperHookForm>
      <ConditionalLogicWrapperHookForm
        control={control}
        conditional={adminOnlyCompleteConditional}
        showOnFalseConditional
      >
        <CompleteDateRow {...sharedProps} includeDates={includeDates} />
      </ConditionalLogicWrapperHookForm>
      {!isAdmin && (
        <ConditionalLogicWrapperHookForm
          control={control}
          conditional={adminOnlyCompleteConditional}
        >
          <>
            <HintBox theme="info" icon={<IconWarning />}>
              This activity can only be completed by RNZCUC Office staff
            </HintBox>
            <br />
          </>
        </ConditionalLogicWrapperHookForm>
      )}

      <FormQuestion
        question={activityAttributes.timeCredits}
        {...sharedProps}
        //@ts-ignore icon will be passed through to text input
        icon={IconClock}
      />
      <br />
      <hr />
      <br />
      <ConditionalLogicWrapperHookForm
        control={control}
        conditional={activityAttributes.learnings.conditional}
      >
        <FormQuestion
          question={activityAttributes.learnings}
          {...sharedProps}
        />
        <FormQuestion question={activityAttributes.benefits} {...sharedProps} />
      </ConditionalLogicWrapperHookForm>

      <Spacer />
      <ActivityFormActions
        isDirty={isDirty}
        isSubmitting={isSubmitting}
        onCancel={onCancel}
        onNext={onNext}
      />
    </form>
  );
};

interface RowProps {
  errors: Record<string, any>;
  register: UseFormRegister<any>;
  disabled?: boolean;
  control: Control;
  className?: string;
  defaultValue?: any;
  includeDates: { minDate: Date | null; maxDate: Date | null };
}

const CompleteDateRow = ({ includeDates, ...props }: RowProps) => (
  <div className={styles.row}>
    <FormQuestion
      question={activityAttributes.dateStarted}
      {...props}
      {...includeDates}
    />
    <FormQuestion
      question={activityAttributes.dateCompleted}
      {...props}
      {...includeDates}
    />
  </div>
);

export default EditDetails;
