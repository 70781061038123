import React, { memo, useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";

interface IConditional {
  value: string | string[];
  logic: (value: any) => boolean;
  children: React.ReactNode;
  className?: string;
  // TODO: add the ability to pass through children if conditional is false
  // onFalseChildren?:React.ReactNode
}

const ConditionalLogicWrapper = memo(
  ({ value, logic, children, className, ...rest }: IConditional) => {
    const [display, setDisplay] = useState(false);
    const v = Array.isArray(value) ? value : [value];

    useEffect(() => {
      setDisplay(logic(value));
      //the v spread makes useEffect watch of actual value change (rather than array ref changes)
    }, [logic, ...v]); // eslint-disable-line react-hooks/exhaustive-deps
    return (
      <AnimatePresence>
        {display && (
          <motion.div
            key="content"
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            layout
            transition={{
              height: { type: "tween", duration: 0.3 },
              opacity: { duration: 0.15 },
            }}
            style={{ overflowY: "hidden" }}
            aria-hidden={!display}
            className={className}
            {...rest}
          >
            <div style={!display ? { display: "none" } : {}}>{children}</div>
          </motion.div>
        )}
      </AnimatePresence>
    );
  }
);

export const ShowIf = ConditionalLogicWrapper;

export default ConditionalLogicWrapper;

// <AnimatePresence>
//   display?<motion.div
//   variants={{
//     hidden: { opacity: 0, height: 0 },
//     visible: { opacity: 1, height: "auto" },
//   }}
//   animate={display ? "visible" : "hidden"}
//   // initial={{ opacity: 0, height: 0 }}
//   // animate={{ opacity: 1, height: "auto" }}
//   // exit={{ opacity: 0, y: -20 }}
//   positionTransition={{ type: "tween" }}
//   // transition={{
//   //   height: { type: "tween", duration: 0.3 },
//   //   opacity: { duration: 0.15 },
//   // }}
//   style={{ overflowY: "hidden" }}
//   aria-hidden={!display}
// >
//   <div style={!display ? { display: "none" } : {}}>{children}</div>
// </motion.div>:<></>
// </AnimatePresence>
