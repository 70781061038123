import { useEffect, useRef, useState } from "react";
import throttle from "lodash.throttle";

// To support SSR
const defaultY = typeof window !== "undefined" ? window.pageYOffset : 0;
const defaultX = typeof window !== "undefined" ? window.pageXOffset : 0;

function useScroll() {
  const [scroll, setScroll] = useState({
    top: defaultY,
    left: defaultX,
  });
  const topRef = useRef(0);
  const leftRef = useRef(0);

  useEffect(() => {
    const onScroll = throttle(() => {
      if (
        window.pageYOffset !== topRef.current ||
        window.pageXOffset !== leftRef.current
      ) {
        topRef.current = window.pageYOffset;
        leftRef.current = window.pageXOffset;

        setScroll({
          top: window.pageYOffset,
          left: window.pageXOffset,
        });
      }
    }, 200);
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return scroll;
}

export { useScroll };
