import React from "react";
import cx from "classnames";
import {
  SkeletonBase,
  SkeletonText,
} from "components/common/ui/skeleton/Skeleton";
import styles from "components/common/ui/form-elements/common/Input.module.scss";
import {
  InputWrapper,
  InputWrapperProps,
} from "components/common/ui/form-elements/common/InputWrapper";

export interface ITextInput extends InputWrapperProps {
  type?: "email" | "text" | "password" | "search" | "tel" | "url" | "date";
  value?: string;
  defaultValue?: string;
  [key: string]: any;
}

const TextInput = React.forwardRef(
  (
    {
      name,
      label,
      description,
      disabled,
      required,
      type = "text",
      className,
      error,
      placeholder,
      hint,
      value,
      defaultValue,
      config,
      icon,
      labelProps,
      inputWrapperProps,
      ...rest
    }: ITextInput,
    ref: React.Ref<any>
  ) => {
    const defaultV: string | undefined =
      type === "date" && !!defaultValue
        ? new Date(defaultValue).toISOString().split("T")[0]
        : defaultValue;
    const props = defaultValue
      ? {
          defaultValue: defaultV,
        }
      : {};

    return (
      <InputWrapper
        name={name}
        error={error}
        hint={hint}
        label={label}
        description={description}
        disabled={disabled}
        className={className}
        icon={icon}
        config={config}
        inputWrapperProps={inputWrapperProps}
        labelProps={labelProps}
      >
        <input
          id={name}
          type={type}
          name={name}
          value={value}
          disabled={disabled}
          className={styles.input}
          ref={ref}
          required={required}
          placeholder={placeholder}
          aria-labelledby={`text-input-label-${name}`}
          aria-describedby={error && `error-${name}-${error?.type}`}
          aria-invalid={!!error}
          tabIndex={disabled ? -1 : undefined}
          {...props}
          {...rest}
        />
      </InputWrapper>
    );
  }
);

interface ISkeleton extends Omit<ITextInput, "name" | "label"> {
  name?: string;
  label?: string;
}

export const SkeletonTextInput = ({
  className,
  skeletonClassName,
  rounded,
  condensed,
  icon: Icon,
  label,
  ...rest
}: ISkeleton) => (
  <div
    className={cx(
      styles.skeleton,
      styles.root,
      rounded && styles.rounded,
      Icon && styles.iconInput,
      condensed && styles.condensed,
      className
    )}
  >
    <div className={styles.label}>{label ?? <SkeletonText />}</div>
    <SkeletonBase {...rest} className={cx(styles.input, skeletonClassName)} />
    {Icon && (
      <span className={styles.icon}>
        <Icon />
      </span>
    )}
  </div>
);

export default TextInput;
