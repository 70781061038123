import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { authService } from "services/AuthService";
import ViewNotFound from "components/app/viewNotFound";
import { setErrorUser } from "utils/errorUtils";
import FullPageLoader from "components/common/ui/loader/FullPageLoader";
import {
  errorSelector,
  isLoadingSelector,
  userIdSelector,
} from "store/selectors/auth.selectors";

interface Props {
  children: React.ReactNode;
}

const AuthUserWrapper = (props: Props) => {
  const id = useSelector(userIdSelector);
  const isLoading = useSelector(isLoadingSelector);
  const error = useSelector(errorSelector);

  useEffect(() => {
    setErrorUser({ id });
  }, [id]);

  useEffect(() => {
    const loadUser = async () => {
      if (!error) {
        await authService.setAuthUser();
      }
    };

    if (id === "" && !isLoading) {
      loadUser();
    }
  }, [id, isLoading, error]);

  return isLoading ? (
    <FullPageLoader />
  ) : (
    (id !== "" && <>{props.children}</>) || <ViewNotFound />
  );
};

export default AuthUserWrapper;
