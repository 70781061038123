import { IColumn } from "components/common/ui/table/utils";
import { PeerGroup } from "models/peergroup/PeerGroup";
import DisplayRichText from "components/common/ui/form-elements/rich-text-editor/DisplayRichText";
import { IconEye } from "components/common/icons/twoToneIcons";
import React from "react";
import { coreService } from "services/CoreService";
import { PEERGROUP_ROUTES } from "constants/routes/peergroup";
import Table from "components/common/ui/table/Table";
import Accordian from "components/common/ui/accordian/Accordian";
import styles from "components/peergroup/list/PeerGroupList.module.scss";

interface Props extends DefaultProps {
  peergroups?: {
    active?: PeerGroup[];
    deleted?: PeerGroup[];
  };
  isLoading?: boolean;
}

export const PeerGroupList = ({ peergroups, isLoading }: Props) => {
  const columns: IColumn<PeerGroup>[] = [
    {
      id: "peergroup_name",
      label: "Name",
      type: "textEmphasis",
      cell: (c, row) => `${row.peergroup_name} `,
    },
    // {
    //   id: "peergroup_status",
    //   label: "Status",
    //   cell: (c, row) => `${row.peergroup_status} `,
    // },
    {
      id: "peergroup_summary",
      label: "Summary",
      cell: (c, row) => <DisplayRichText text={row.peergroup_summary ?? ""} />,
    },
    {
      id: "link",
      label: " ",
      width: "50px",
      type: "icon",
      cell: () => <IconEye />,
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        data={peergroups?.active || []}
        getRowProps={(row) => ({
          to: coreService.getRoute(PEERGROUP_ROUTES.PEERGROUP_DETAIL, {
            peergroupId: row.id,
          }),
        })}
        loading={isLoading}
        // loading={true}
        placeholder={{ noDataLabel: "No Peer Groups Found", loadingRows: 5 }}
      />
      {(peergroups?.deleted || []).length > 0 && (
        <Accordian
          headerClassName={styles.deletedAccordian}
          header={<h4>Deleted Peer Groups</h4>}
        >
          <Table
            columns={columns}
            data={peergroups?.deleted || []}
            getRowProps={(row) => ({
              to: coreService.getRoute(PEERGROUP_ROUTES.PEERGROUP_DETAIL, {
                peergroupId: row.id,
              }),
            })}
            loading={isLoading}
          />
        </Accordian>
      )}
    </>
  );
};
