import React from "react";

interface Props {
  className?: string;
}

const PlanIllustration = ({ className }: Props) => (
  <svg
    width="auto"
    height="auto"
    viewBox="0 0 452 330"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <mask
      id="k901l0x31-data-mask0"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="4"
      y="2"
      width="444"
      height="323"
    >
      <rect x="4" y="2" width="444" height="323" fill="#9081BE" />
    </mask>
    <g mask="url(#k901l0x31-data-mask0)">
      <mask
        id="mask1"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="35"
        y="-8"
        width="441"
        height="340"
      >
        <path
          d="M429 2.5L362.329 113.277L278.399 177.584L127.095 224.168L77.8361 247.25L48.683 290.904L36.1382 331H474.638V-7L429 2.5Z"
          fill="#C4C4C4"
          stroke="black"
        />
      </mask>
      <g mask="url(#mask1)">
        <rect x="-71.8618" y="-2" width="544" height="328" fill="#DBE9EC" />
        <path
          d="M162.592 326.994C188.837 323.276 207.188 299.608 203.579 274.131C199.97 248.653 175.768 231.013 149.522 234.731C123.276 238.449 104.926 262.117 108.535 287.594C112.144 313.072 136.346 330.712 162.592 326.994Z"
          fill="#ADDEBB"
        />
        <path
          d="M166.143 184.739C165.961 185.018 165.836 185.292 165.653 185.57C158.418 198.403 155.315 212.692 156.044 226.879C156.022 226.969 156.032 227.085 156.041 227.202C157.167 247.424 166.228 267.185 182.903 281.38C190.391 287.755 198.77 292.446 207.569 295.432C236.515 305.477 269.872 297.463 290.951 272.702C318.405 240.454 314.491 192.081 282.273 164.655C278.466 161.414 274.401 158.604 270.225 156.243C270.225 156.243 198.407 144.864 174.195 173.306C171.114 176.924 168.433 180.774 166.143 184.739ZM249.023 191.155C251.406 192.371 253.697 193.887 255.837 195.708C270.908 208.538 272.727 231.195 259.898 246.266C250.309 257.53 235.232 261.408 221.917 257.29C217.435 255.861 213.147 253.568 209.34 250.327C201.349 243.524 197.063 233.961 196.753 224.255C196.739 224.08 196.751 223.874 196.737 223.699C196.621 217.143 198.261 210.562 201.809 204.738C202.139 204.096 202.531 203.507 202.924 202.919C203.628 201.836 204.422 200.776 205.279 199.769C216.287 186.838 234.526 183.643 249.023 191.155Z"
          fill="#676F9A"
        />
        <path
          d="M156.041 227.202L196.737 223.699C196.886 233.594 201.191 243.39 209.339 250.328C213.146 253.568 217.434 255.862 221.916 257.29L207.569 295.433C198.77 292.446 190.391 287.755 182.903 281.381C166.229 267.186 157.167 247.425 156.041 227.202Z"
          fill="#668FDD"
        />
        <path
          d="M164.443 186.526L188.978 196.978L201.315 205.956C198.294 211.483 196.618 217.61 196.737 223.699C196.751 223.874 196.738 224.08 196.752 224.254C186.523 224.988 167.619 226.477 156.041 227.202C155.289 212.724 156.872 199.562 164.443 186.526Z"
          fill="#99B3E7"
        />
        <path
          d="M173.253 173.906C198.191 144.614 239.002 138.512 270.967 156.504L249.695 191.756C234.909 184.144 216.233 187.525 204.895 200.843C204.012 201.88 203.194 202.972 202.466 204.086C202.062 204.692 201.657 205.296 201.316 205.956L164.443 186.526C164.632 186.24 164.761 185.958 164.95 185.671C167.315 181.594 170.08 177.633 173.253 173.906Z"
          fill="#ADDBDE"
        />
        <path
          d="M157.983 294.88H167.513V285.35H157.983V294.88Z"
          fill="#401A49"
        />
        <path
          d="M199.032 290.655H162.015C161.717 290.655 161.476 290.414 161.476 290.116C161.476 289.818 161.717 289.578 162.015 289.578H198.494V261.527C198.494 261.229 198.735 260.988 199.032 260.988C199.329 260.988 199.57 261.23 199.57 261.527V290.116C199.571 290.414 199.329 290.655 199.032 290.655Z"
          fill="#401A49"
        />
        <path
          d="M188.114 262.564H135.053C132.815 262.564 131 264.692 131 267.317V274.866C131 277.491 132.815 279.618 135.053 279.618H179.556L185.556 286.493L183.809 279.618H188.114C190.353 279.618 192.167 277.49 192.167 274.866V267.317C192.167 264.692 190.353 262.564 188.114 262.564Z"
          fill="#FFE5D2"
        />
        <path
          d="M182.854 267.716H140.428C140.226 267.716 140.063 267.553 140.063 267.351C140.063 267.148 140.226 266.985 140.428 266.985H182.854C183.056 266.985 183.219 267.148 183.219 267.351C183.22 267.553 183.056 267.716 182.854 267.716Z"
          fill="#401A49"
        />
        <path
          d="M182.854 270.278H140.428C140.226 270.278 140.063 270.115 140.063 269.913C140.063 269.711 140.226 269.548 140.428 269.548H182.854C183.056 269.548 183.219 269.711 183.219 269.913C183.22 270.115 183.056 270.278 182.854 270.278Z"
          fill="#401A49"
        />
        <path
          d="M182.854 270.278H140.428C140.226 270.278 140.063 270.115 140.063 269.913C140.063 269.711 140.226 269.548 140.428 269.548H182.854C183.056 269.548 183.219 269.711 183.219 269.913C183.22 270.115 183.056 270.278 182.854 270.278Z"
          fill="#401A49"
        />
        <path
          d="M182.854 272.838H140.428C140.226 272.838 140.063 272.675 140.063 272.473C140.063 272.27 140.226 272.107 140.428 272.107H182.854C183.056 272.107 183.219 272.27 183.219 272.473C183.219 272.675 183.056 272.838 182.854 272.838Z"
          fill="#401A49"
        />
        <path
          d="M182.854 272.838H140.428C140.226 272.838 140.063 272.675 140.063 272.473C140.063 272.27 140.226 272.107 140.428 272.107H182.854C183.056 272.107 183.219 272.27 183.219 272.473C183.219 272.675 183.056 272.838 182.854 272.838Z"
          fill="#401A49"
        />
        <path
          d="M182.854 275.4H140.428C140.226 275.4 140.063 275.237 140.063 275.035C140.063 274.833 140.226 274.67 140.428 274.67H182.854C183.056 274.67 183.219 274.833 183.219 275.035C183.219 275.237 183.056 275.4 182.854 275.4Z"
          fill="#401A49"
        />
        <path
          d="M259.528 125.115H249.999V134.645H259.528V125.115Z"
          fill="#401A49"
        />
        <path
          d="M218.479 159.007C218.181 159.007 217.941 158.766 217.941 158.468V129.879C217.941 129.581 218.181 129.34 218.479 129.34H255.497C255.794 129.34 256.035 129.581 256.035 129.879C256.035 130.177 255.794 130.417 255.497 130.417H219.018V158.468C219.018 158.766 218.776 159.007 218.479 159.007Z"
          fill="#401A49"
        />
        <path
          d="M267.71 122H320.77C323.008 122 324.823 124.127 324.823 126.752V134.302C324.823 136.927 323.008 139.054 320.77 139.054H276.267L270.267 145.93L272.014 139.054H267.709C265.471 139.054 263.657 136.926 263.657 134.302V126.752C263.657 124.128 265.471 122 267.71 122Z"
          fill="#FFE5D2"
        />
        <path
          d="M315.395 127.151H272.97C272.768 127.151 272.605 126.988 272.605 126.786C272.605 126.583 272.768 126.42 272.97 126.42H315.395C315.598 126.42 315.761 126.583 315.761 126.786C315.761 126.988 315.598 127.151 315.395 127.151Z"
          fill="#401A49"
        />
        <path
          d="M315.395 129.713H272.97C272.768 129.713 272.605 129.55 272.605 129.348C272.605 129.146 272.768 128.983 272.97 128.983H315.395C315.598 128.983 315.761 129.146 315.761 129.348C315.761 129.55 315.598 129.713 315.395 129.713Z"
          fill="#401A49"
        />
        <path
          d="M315.395 129.713H272.97C272.768 129.713 272.605 129.55 272.605 129.348C272.605 129.146 272.768 128.983 272.97 128.983H315.395C315.598 128.983 315.761 129.146 315.761 129.348C315.761 129.55 315.598 129.713 315.395 129.713Z"
          fill="#401A49"
        />
        <path
          d="M315.395 132.275H272.97C272.768 132.275 272.605 132.112 272.605 131.91C272.605 131.708 272.768 131.545 272.97 131.545H315.395C315.598 131.545 315.761 131.708 315.761 131.91C315.761 132.112 315.598 132.275 315.395 132.275Z"
          fill="#401A49"
        />
        <path
          d="M315.395 132.275H272.97C272.768 132.275 272.605 132.112 272.605 131.91C272.605 131.708 272.768 131.545 272.97 131.545H315.395C315.598 131.545 315.761 131.708 315.761 131.91C315.761 132.112 315.598 132.275 315.395 132.275Z"
          fill="#401A49"
        />
        <path
          d="M315.395 134.836H272.97C272.768 134.836 272.605 134.673 272.605 134.471C272.605 134.269 272.768 134.106 272.97 134.106H315.395C315.598 134.106 315.761 134.269 315.761 134.471C315.761 134.673 315.598 134.836 315.395 134.836Z"
          fill="#401A49"
        />
        <path
          d="M438.631 105.01C438.664 105.129 438.686 105.268 438.704 105.392C438.875 106.244 439.031 107.101 439.168 107.947C440.392 115.705 440.657 123.601 440.001 131.412C439.989 131.544 439.977 131.675 439.969 131.822C439.293 139.447 437.728 146.995 435.314 154.263C435.262 154.422 435.22 154.562 435.153 154.725C433.389 159.983 431.168 165.093 428.513 169.97C432.654 165.471 440.21 168.286 444.512 172.643C448.814 176.998 449.787 183.847 448.375 189.795C448.266 190.275 448.139 190.744 447.992 191.202C447.954 191.357 447.912 191.496 447.871 191.637C446.227 196.861 443.112 201.527 439.864 205.942C421.596 230.691 397.524 250.47 373.689 269.942C371.147 272.025 368.22 274.23 364.958 273.876C364.612 273.843 364.268 273.761 363.92 273.665C363.78 273.623 363.64 273.582 363.515 273.536C359.291 272.082 354.708 266.535 352.983 262.785C345.382 246.23 343.568 227.887 343.148 209.529C343.148 209.529 343.133 209.533 343.144 209.514C343.133 209.244 343.127 208.988 343.117 208.718C343.004 203.705 343.004 198.693 343 193.73C342.994 186.599 343.123 179.029 346.715 172.946C346.786 172.798 346.877 172.66 346.957 172.542C347.043 172.39 347.134 172.252 347.225 172.115C351.339 165.888 360.987 162.777 366.375 167.926C367.298 155.896 369.836 143.951 374.008 132.632C374.049 132.492 374.106 132.348 374.163 132.204C374.159 132.189 374.159 132.189 374.159 132.189C377.459 123.359 381.718 114.923 387.009 107.135C387.019 107.116 387.019 107.116 387.031 107.097C387.114 106.993 387.179 106.879 387.263 106.776C392.672 98.8907 399.646 91.6181 408.635 88.4485C409.515 88.1418 410.422 87.8758 411.33 87.6736C411.494 87.6283 411.658 87.5831 411.83 87.5678C420.331 85.7698 429.922 88.217 434.821 95.2674C436.835 98.1477 437.917 101.544 438.631 105.01Z"
          fill="#68BBA8"
        />
        <path
          d="M411.828 87.5659C407.546 95.221 403.895 102.895 400.692 109.979C399.615 112.364 398.553 114.746 397.535 117.114C397.448 117.267 397.392 117.411 397.335 117.555C393.319 126.759 389.672 135.846 386.388 144.848C386.331 144.992 386.27 145.121 386.243 145.257C383.088 153.854 380.291 162.352 377.803 170.75C377.761 170.89 377.705 171.034 377.667 171.189C374.465 182.064 371.801 192.791 369.696 203.331C369.669 203.467 369.638 203.588 369.611 203.724C367.492 214.445 365.931 224.98 364.944 235.325C364.911 235.494 364.892 235.661 364.888 235.822C363.663 248.738 363.337 261.359 363.919 273.664C363.978 275.335 364.082 276.993 364.185 278.651L363.806 278.675C363.69 276.972 363.599 275.246 363.513 273.535C362.948 261.177 363.284 248.473 364.523 235.489C364.526 235.327 364.545 235.162 364.563 234.996C365.54 224.735 367.077 214.287 369.144 203.724C369.186 203.584 369.213 203.448 369.229 203.331C369.956 199.677 370.743 196.006 371.608 192.33C375.156 177.118 379.887 161.42 385.767 145.341C385.809 145.201 385.869 145.071 385.926 144.928C389.211 135.925 392.864 126.805 396.88 117.601C396.926 117.476 396.972 117.351 397.048 117.217C398.104 114.757 399.21 112.299 400.326 109.823C403.505 102.825 407.112 95.2283 411.326 87.6723C411.492 87.6264 411.656 87.5812 411.828 87.5659Z"
          fill="#FFD2A7"
        />
        <path
          d="M438.631 105.01C438.664 105.129 438.686 105.268 438.704 105.392C429.063 108.645 419.229 111.662 409.256 114.412C405.3 115.503 401.331 116.55 397.335 117.555L396.932 117.666L396.882 117.6L396.858 117.574L387.029 107.096C387.113 106.992 387.177 106.878 387.262 106.775L397.049 117.216L397.053 117.231C397.218 117.186 397.367 117.145 397.535 117.114C411.502 113.584 425.245 109.536 438.631 105.01Z"
          fill="#FFD2A7"
        />
        <path
          d="M440.001 131.412C439.989 131.544 439.977 131.676 439.97 131.823C434.047 133.762 428.112 135.542 422.185 137.177C410.137 140.499 398.112 143.205 386.245 145.257C386.091 145.283 385.923 145.313 385.77 145.339C385.71 145.356 385.646 145.357 385.586 145.374L385.482 145.402L385.412 145.325C381.304 141.382 377.482 137.135 374.007 132.631C374.049 132.49 374.106 132.347 374.162 132.202C377.645 136.736 381.475 141.013 385.606 144.982C385.715 144.968 385.819 144.939 385.928 144.926C386.082 144.899 386.235 144.873 386.388 144.847C404.062 141.772 422.048 137.262 440.001 131.412Z"
          fill="#FFD2A7"
        />
        <path
          d="M435.314 154.263C435.262 154.422 435.22 154.562 435.153 154.725C423.88 158.588 412.465 162.106 400.984 165.272C393.236 167.409 385.458 169.378 377.667 171.189C377.574 171.199 377.499 171.219 377.409 171.244L377.322 170.867C377.486 170.821 377.639 170.795 377.804 170.75C397.157 166.247 416.455 160.732 435.314 154.263Z"
          fill="#FFD2A7"
        />
        <path
          d="M447.991 191.201C447.953 191.356 447.912 191.496 447.87 191.636C444.623 192.676 441.359 193.657 438.06 194.566C416.114 200.618 392.835 203.761 369.612 203.723C369.454 203.735 369.308 203.727 369.146 203.723C368.989 203.735 368.827 203.731 368.666 203.727L368.587 203.733L368.537 203.666C359.381 194.994 351.914 184.459 346.715 172.945C346.787 172.796 346.877 172.659 346.957 172.541C352.131 184.078 359.588 194.633 368.751 203.335C368.912 203.338 369.074 203.342 369.232 203.331C369.393 203.334 369.54 203.342 369.698 203.331C396.407 203.371 423.211 199.208 447.991 191.201Z"
          fill="#FFD2A7"
        />
        <path
          d="M365.433 235.752L365.18 236.063C365.077 235.979 364.993 235.906 364.889 235.822L364.885 235.807C364.759 235.697 364.636 235.602 364.524 235.489C356.044 228.156 348.744 219.278 343.147 209.528C343.147 209.528 343.132 209.532 343.143 209.513C343.133 209.243 343.127 208.987 343.117 208.717C348.697 218.584 356.03 227.582 364.565 234.996C364.691 235.106 364.818 235.215 364.944 235.325C365.099 235.475 365.264 235.606 365.433 235.752Z"
          fill="#FFD2A7"
        />
        <path
          d="M462.745 264.367C460.98 265.619 459.155 266.777 457.27 267.839C457.154 267.924 457.038 267.986 456.911 268.059C451.402 271.172 445.485 273.554 439.398 275.518C439.27 275.57 439.13 275.611 439 275.642C431.989 277.906 424.756 279.603 417.597 281.262C420.496 282.001 422.511 283.322 424.967 285.032C426.102 285.806 427.192 286.724 427.818 287.957C429.188 290.713 427.622 294.246 425.066 295.976C422.509 297.707 419.27 298.032 416.192 298.106C415.457 298.119 414.712 298.123 413.976 298.114C413.8 298.103 413.613 298.104 413.437 298.094L413.425 298.083C405.889 297.945 398.517 296.567 391.313 294.389C391.079 294.327 390.854 294.253 390.62 294.191L390.608 294.181C378.31 290.382 366.472 284.327 355.05 278.245C352.154 276.705 349.13 275.031 347.428 272.231C346.571 270.815 346.119 269.22 345.983 267.593C345.975 267.462 345.977 267.319 345.969 267.188C345.886 265.526 346.119 263.832 346.623 262.253C347.622 258.964 350.708 254.15 353.562 252.246C360.797 247.455 368.469 243.317 376.473 239.926L376.483 239.914C376.674 239.814 376.867 239.736 377.06 239.658L377.07 239.647C386.24 235.776 395.789 232.879 405.558 231.022C406.805 230.779 408.086 230.567 409.384 230.452C409.548 230.43 409.722 230.419 409.887 230.42C412.158 230.254 414.409 230.441 416.433 231.4C419.78 232.966 421.983 237.446 419.893 240.497C423.102 239.526 426.324 238.774 429.562 238.262C429.747 238.24 429.92 238.207 430.105 238.184C437.007 237.145 443.942 237.146 450.892 238.09C451.047 238.102 451.19 238.125 451.357 238.148C453.815 238.497 456.291 238.966 458.765 239.556C463.855 240.76 469.456 243.095 471.172 248.033C471.372 248.602 471.507 249.175 471.578 249.774C471.608 249.904 471.617 250.035 471.625 250.167C471.861 252.686 471.082 255.303 469.739 257.483C468.017 260.281 465.433 262.463 462.745 264.367Z"
          fill="#ADDEBB"
        />
        <path
          d="M471.624 250.167C461.099 250.305 450.573 250.773 440.102 251.577C439.915 251.578 439.75 251.577 439.599 251.609C432.883 252.109 426.186 252.752 419.51 253.536C419.356 253.545 419.204 253.555 419.052 253.587C411.275 254.495 403.531 255.576 395.798 256.833C395.788 256.845 395.766 256.846 395.744 256.848C392.42 257.386 389.112 257.98 385.792 258.584C385.673 258.603 385.543 258.633 385.401 258.653C379.48 259.751 373.565 260.959 367.68 262.274C367.538 262.294 367.398 262.336 367.268 262.366C360.137 263.947 353.049 265.689 345.983 267.594C345.974 267.463 345.976 267.321 345.968 267.189C352.96 265.322 359.994 263.593 367.049 262.028C367.168 261.988 367.319 261.957 367.461 261.937C373.476 260.592 379.532 259.364 385.605 258.235C388.903 257.631 392.202 257.05 395.514 256.501C395.677 256.48 395.829 256.448 395.97 256.428C403.593 255.179 411.24 254.114 418.919 253.223C419.059 253.181 419.234 253.171 419.409 253.159C426.139 252.361 432.912 251.702 439.717 251.218C439.869 251.187 440.032 251.166 440.23 251.175C450.636 250.375 461.097 249.912 471.578 249.777C471.607 249.904 471.616 250.036 471.624 250.167Z"
          fill="#99B3E7"
        />
        <path
          d="M457.269 267.84C457.154 267.924 457.037 267.986 456.91 268.06C455.405 266.817 453.933 265.56 452.492 264.29C447.809 260.164 443.493 255.927 439.599 251.608L439.482 251.484L439.718 251.216C443.333 246.786 447.07 242.38 450.892 238.09C451.047 238.102 451.19 238.126 451.357 238.148C447.555 242.415 443.837 246.775 440.231 251.172C440.149 251.265 440.079 251.369 439.997 251.462C440.032 251.492 440.068 251.545 440.103 251.576C445.172 257.16 450.915 262.612 457.269 267.84Z"
          fill="#99B3E7"
        />
        <path
          d="M439.397 275.519C439.269 275.571 439.129 275.612 438.999 275.643C432.155 269.075 425.477 261.684 419.051 253.586C419.015 253.534 418.969 253.493 418.933 253.44L418.838 253.336L418.919 253.222C422.228 248.108 425.805 243.087 429.561 238.264C429.746 238.241 429.919 238.208 430.105 238.186C426.339 243.021 422.761 248.042 419.409 253.158C419.379 253.215 419.349 253.25 419.319 253.306C419.379 253.38 419.45 253.463 419.51 253.536C425.934 261.611 432.586 268.959 439.397 275.519Z"
          fill="#99B3E7"
        />
        <path
          d="M395.789 256.865L395.744 256.846L395.428 256.712C395.457 256.634 395.486 256.577 395.514 256.498C399.273 247.406 403.933 238.652 409.384 230.452C409.547 230.431 409.722 230.419 409.886 230.42C404.4 238.59 399.752 247.332 395.97 256.425C395.913 256.561 395.855 256.696 395.798 256.831C395.799 256.854 395.799 256.854 395.789 256.865Z"
          fill="#99B3E7"
        />
        <path
          d="M413.975 298.114C413.799 298.103 413.612 298.104 413.436 298.093C413.436 298.093 413.426 298.105 413.424 298.083C402.386 286.272 392.994 273.062 385.401 258.652C385.352 258.567 385.314 258.492 385.265 258.408L385.605 258.233C385.667 258.35 385.729 258.466 385.792 258.583C393.409 273.036 402.858 286.275 413.975 298.114Z"
          fill="#99B3E7"
        />
        <path
          d="M391.312 294.389C391.078 294.327 390.854 294.254 390.619 294.192L390.607 294.181C381.233 284.042 373.716 272.382 367.268 262.365C367.195 262.26 367.134 262.165 367.074 262.07L367.05 262.028L367.036 261.996L367.063 261.895C369.616 254.361 372.782 246.996 376.472 239.927L376.482 239.915C376.674 239.815 376.866 239.737 377.059 239.659C373.275 246.81 370.06 254.277 367.46 261.936C367.533 262.041 367.594 262.158 367.679 262.273C374.201 272.417 381.814 284.203 391.312 294.389Z"
          fill="#99B3E7"
        />
        <path
          d="M285.891 247.223L272.766 251.822L277.366 264.947L290.491 260.348L285.891 247.223Z"
          fill="#FF899A"
        />
        <path
          d="M295.626 275.004L282.501 279.604L287.1 292.728L300.225 288.128L295.626 275.004Z"
          fill="#FF899A"
        />
        <path
          d="M306.203 305.191L293.078 309.79L297.678 322.915L310.802 318.316L306.203 305.191Z"
          fill="#FF899A"
        />
        <path
          d="M341.136 232.046L295.85 247.915C295.664 247.98 295.458 247.881 295.392 247.695L294.94 246.404C294.875 246.218 294.974 246.012 295.16 245.947L340.446 230.078C340.632 230.012 340.838 230.112 340.904 230.298L341.356 231.589C341.421 231.775 341.322 231.981 341.136 232.046Z"
          fill="#64B8BD"
        />
        <path
          d="M342.898 237.078L297.613 252.946C297.427 253.012 297.221 252.913 297.156 252.726L296.703 251.435C296.638 251.249 296.737 251.043 296.924 250.977L342.209 235.108C342.396 235.043 342.602 235.142 342.667 235.328L343.119 236.619C343.184 236.806 343.085 237.012 342.898 237.078Z"
          fill="#64B8BD"
        />
        <path
          d="M381.262 249.177L306.231 275.47C305.69 275.66 305.092 275.373 304.902 274.831C304.712 274.29 305 273.692 305.541 273.502L380.572 247.21C381.114 247.02 381.712 247.308 381.901 247.849C382.091 248.39 381.804 248.988 381.262 249.177Z"
          fill="#64B8BD"
        />
        <path
          d="M383.025 254.209L307.994 280.501C307.452 280.691 306.854 280.404 306.665 279.862C306.475 279.321 306.763 278.723 307.304 278.532L382.335 252.24C382.877 252.05 383.475 252.338 383.665 252.879C383.854 253.421 383.566 254.019 383.025 254.209Z"
          fill="#64B8BD"
        />
        <path
          d="M392.07 280.022L317.039 306.314C316.498 306.504 315.9 306.216 315.709 305.675C315.52 305.133 315.808 304.535 316.349 304.345L391.38 278.053C391.921 277.863 392.519 278.15 392.709 278.692C392.9 279.234 392.612 279.832 392.07 280.022Z"
          fill="#64B8BD"
        />
        <path
          d="M393.834 285.053L318.802 311.345C318.261 311.535 317.663 311.248 317.473 310.706C317.284 310.165 317.571 309.567 318.113 309.377L393.144 283.085C393.685 282.895 394.283 283.183 394.473 283.724C394.662 284.265 394.375 284.863 393.834 285.053Z"
          fill="#64B8BD"
        />
        <path
          d="M351.802 221.707L356.651 219.766L360.201 229.225L355.338 231.049L351.988 222.122"
          fill="#FF899A"
        />
        <path
          d="M358.688 214.208L363.519 212.218L368.702 226.034L363.841 227.858L358.947 214.819"
          fill="#FF899A"
        />
        <path
          d="M351.984 234.296C351.959 234.296 351.933 234.292 351.909 234.281C351.868 234.262 351.837 234.229 351.822 234.188L343.527 212.082C343.494 211.996 343.538 211.9 343.625 211.867C343.712 211.837 343.808 211.879 343.84 211.965L352.076 233.915L385.503 221.372C385.59 221.342 385.686 221.383 385.718 221.469C385.75 221.556 385.707 221.652 385.62 221.684L352.037 234.286C352.019 234.292 352.001 234.295 351.984 234.296Z"
          fill="#FF899A"
        />
        <path
          d="M355.345 231.216C355.319 231.217 355.293 231.212 355.269 231.201C355.229 231.183 355.198 231.149 355.182 231.108L349.949 217.159C349.916 217.073 349.96 216.977 350.046 216.944L354.988 215.09C355.03 215.074 355.075 215.076 355.116 215.094C355.156 215.113 355.188 215.146 355.203 215.187L360.437 229.136C360.47 229.223 360.426 229.319 360.339 229.351L355.398 231.206C355.38 231.212 355.362 231.215 355.345 231.216ZM350.318 217.198L355.436 230.835L360.065 229.098L354.948 215.461L350.318 217.198Z"
          fill="#FF899A"
        />
        <path
          d="M363.874 228.036C363.845 228.038 363.817 228.032 363.789 228.02C363.744 227.999 363.71 227.961 363.692 227.915L357.082 210.3C357.046 210.203 357.094 210.095 357.191 210.059L362.132 208.204C362.18 208.186 362.231 208.189 362.276 208.209C362.321 208.23 362.357 208.268 362.374 208.314L368.984 225.929C369.02 226.026 368.971 226.135 368.875 226.171L363.933 228.025C363.915 228.032 363.894 228.036 363.874 228.036ZM357.499 210.343L363.977 227.607L368.568 225.885L362.09 208.621L357.499 210.343Z"
          fill="#FF899A"
        />
        <path
          d="M372.084 224.969C372.054 224.97 372.023 224.964 371.995 224.951C371.945 224.93 371.908 224.889 371.889 224.839L364.292 204.593C364.253 204.489 364.306 204.373 364.41 204.334L369.352 202.48C369.402 202.462 369.458 202.464 369.505 202.484C369.554 202.506 369.592 202.547 369.611 202.597L377.208 222.842C377.247 222.946 377.194 223.062 377.09 223.101L372.148 224.956C372.127 224.964 372.106 224.968 372.084 224.969ZM364.739 204.64L372.195 224.509L376.761 222.796L369.305 202.927L364.739 204.64Z"
          fill="#FF899A"
        />
        <path
          d="M366.069 209.161L370.891 207.148L376.833 222.983L371.971 224.808L366.362 209.862"
          fill="#FF899A"
        />
        <path
          d="M312.527 379.784L295.943 334.057L296.324 333.92L408.181 293.352L424.764 339.077L424.382 339.215L312.527 379.784ZM296.983 334.546L313.013 378.742L423.723 338.589L407.694 294.394L296.983 334.546Z"
          fill="#668FDD"
        />
        <path
          d="M321.674 371.08L320.933 370.745L330.284 350.082L345.598 352.928L355.842 327.051L356.24 327.254C362.56 330.472 372.754 335.535 374.399 336.03C375.665 333.841 382.886 323.574 395.913 305.445L396.574 305.918C388.221 317.545 375.698 335.127 375.013 336.51L375.017 336.665L374.81 336.81C374.564 336.971 374.095 337.25 356.269 328.18L346.107 353.851L330.763 350.998L321.674 371.08Z"
          fill="#668FDD"
        />
        <path
          d="M397.737 305.456C398.09 306.43 397.587 307.507 396.612 307.861C395.637 308.214 394.561 307.711 394.207 306.736C393.853 305.761 394.356 304.685 395.332 304.331C396.307 303.977 397.384 304.481 397.737 305.456Z"
          fill="#668FDD"
        />
        <path
          d="M412.474 320.61L407.875 322.277L409.543 326.876L414.141 325.208L412.474 320.61Z"
          fill="#64B8BD"
        />
        <path
          d="M281.656 342.796C281.656 353.9 273.541 363.114 262.918 364.833C261.958 364.988 260.982 365.081 259.991 365.112C259.774 365.127 259.542 365.127 259.325 365.127C256.29 365.127 253.378 364.508 250.73 363.393C249.801 363.021 248.903 362.572 248.051 362.061C241.438 358.205 236.994 351.019 236.994 342.796C236.994 330.748 246.503 320.945 258.442 320.495C258.736 320.464 259.031 320.464 259.325 320.464C259.542 320.464 259.774 320.464 259.991 320.479C263.057 320.556 265.984 321.269 268.617 322.492C269.515 322.91 270.397 323.39 271.234 323.917C277.505 327.851 281.656 334.836 281.656 342.796Z"
          fill="#676F9A"
        />
        <path
          d="M282.973 342.796C282.973 354.349 274.192 363.857 262.948 365.019C262.189 365.081 261.415 365.127 260.641 365.127C260.424 365.127 260.207 365.127 259.99 365.112C256.753 365.019 253.718 364.26 250.977 362.944C250.079 362.51 249.196 362.015 248.359 361.457C242.304 357.462 238.309 350.586 238.309 342.796C238.309 331.196 247.136 321.673 258.457 320.589C258.952 320.527 259.479 320.495 259.99 320.48C260.207 320.465 260.424 320.465 260.641 320.465C263.537 320.465 266.309 321.022 268.849 322.029C269.778 322.401 270.692 322.834 271.559 323.314C278.373 327.139 282.973 334.418 282.973 342.796Z"
          fill="#668FDD"
        />
        <path
          d="M270.8 324.755C269.948 324.259 269.035 323.826 268.105 323.47C265.798 322.572 263.273 322.076 260.641 322.076C259.96 322.076 259.278 322.107 258.612 322.185C248.128 323.191 239.921 332.049 239.921 342.797C239.921 349.952 243.575 356.286 249.119 360.002C249.956 360.56 250.823 361.055 251.721 361.489C254.431 362.774 257.451 363.502 260.641 363.502C261.354 363.502 262.066 363.471 262.779 363.394C273.186 362.326 281.362 353.498 281.362 342.797C281.362 335.069 277.103 328.316 270.8 324.755ZM262.747 363.053C262.066 363.13 261.354 363.161 260.641 363.161C257.513 363.161 254.539 362.449 251.891 361.179C250.993 360.745 250.111 360.234 249.29 359.677C243.854 356.022 240.277 349.827 240.277 342.797C240.277 332.251 248.345 323.532 258.644 322.525C259.294 322.448 259.96 322.417 260.641 322.417C263.212 322.417 265.674 322.897 267.935 323.78C268.864 324.136 269.778 324.569 270.645 325.065C276.824 328.549 281.006 335.208 281.006 342.797C281.006 353.312 272.984 362 262.747 363.053Z"
          fill="#FFF5EE"
        />
        <path
          opacity="0.15"
          d="M282.973 342.796C282.973 354.349 274.192 363.858 262.948 365.019L262.918 364.833L262.778 363.393L262.747 363.052L261.555 351.252L261.307 348.743L261.152 347.257L261.059 346.374L260.671 342.595L260.486 340.752L260.439 340.335L260.269 338.491L260.114 336.958L259.912 334.976L259.881 334.712L259.587 331.878L258.643 322.524L258.611 322.183L258.456 320.588L258.441 320.495C258.735 320.464 259.03 320.464 259.324 320.464C259.541 320.464 259.773 320.464 259.99 320.479C260.206 320.464 260.423 320.464 260.64 320.464C263.536 320.464 266.308 321.021 268.848 322.028C269.777 322.4 270.691 322.833 271.558 323.313C278.373 327.14 282.973 334.418 282.973 342.796Z"
          fill="#FFE5D2"
        />
        <path
          d="M384.483 141.446C385.283 141.84 385.915 142.583 386.152 143.536C386.576 145.244 385.578 146.98 383.921 147.416C382.265 147.851 380.578 146.821 380.153 145.113C379.729 143.406 380.728 141.67 382.384 141.234C383.115 141.043 383.852 141.135 384.483 141.446Z"
          fill="#401A49"
        />
        <path
          d="M382.184 153.804C382.173 153.179 382.33 152.562 382.645 152.012C383.096 151.227 383.817 150.671 384.675 150.446C385.425 150.249 386.223 150.33 386.921 150.674L386.922 150.673C387.811 151.112 388.46 151.927 388.707 152.908C389.159 154.738 388.087 156.594 386.319 157.061C384.55 157.524 382.741 156.42 382.288 154.597C382.224 154.334 382.189 154.07 382.184 153.804ZM386.736 151.075C386.128 150.777 385.434 150.707 384.782 150.879C384.037 151.074 383.41 151.558 383.018 152.24C382.627 152.921 382.512 153.718 382.709 154.488C383.102 156.07 384.675 157.031 386.213 156.627C386.957 156.432 387.584 155.948 387.976 155.267C388.367 154.586 388.477 153.79 388.287 153.019C388.074 152.166 387.509 151.458 386.736 151.075Z"
          fill="#401A49"
        />
        <path
          d="M384.53 163.233C384.519 162.608 384.676 161.991 384.991 161.44C385.442 160.656 386.163 160.1 387.021 159.875C387.769 159.678 388.568 159.758 389.267 160.102L389.268 160.102C390.158 160.542 390.808 161.356 391.053 162.338C391.271 163.218 391.144 164.138 390.694 164.924C390.243 165.708 389.522 166.264 388.664 166.489C386.895 166.952 385.086 165.848 384.633 164.025C384.569 163.763 384.534 163.498 384.53 163.233ZM389.082 160.504C388.474 160.206 387.779 160.136 387.127 160.308C386.383 160.503 385.756 160.987 385.364 161.668C384.973 162.35 384.858 163.146 385.054 163.916C385.448 165.499 387.02 166.46 388.558 166.056C389.302 165.861 389.929 165.377 390.322 164.696C390.714 164.014 390.823 163.217 390.633 162.447C390.421 161.594 389.856 160.887 389.082 160.504Z"
          fill="#401A49"
        />
        <path
          d="M126.139 234C120.556 234 116 238.556 116 244.139C116 249.722 120.556 254.278 126.139 254.278C131.722 254.278 136.278 249.722 136.278 244.139C136.278 238.556 131.722 234 126.139 234ZM126.139 253.392C121.041 253.392 116.886 249.236 116.886 244.139C116.886 239.041 121.041 234.886 126.139 234.886C131.237 234.886 135.392 239.041 135.392 244.139C135.392 249.236 131.236 253.392 126.139 253.392Z"
          fill="#412424"
        />
      </g>
      <path
        d="M10.5 329L40.6381 329C40.6381 329 60.6381 282.999 92.1381 258.499C123.638 233.999 177.793 216.427 248.323 193.076C302.44 175.158 349.522 148.612 383.5 107C401.305 85.1945 450 -0.499962 450 -0.499962L426.5 -0.500222C398.5 51.4998 372 93.4998 343.771 117.822C315.542 142.144 283.067 157.499 241.339 173.789C184.428 196.006 123.049 204.051 75.3717 242.295C33.5 275.882 10.5 329 10.5 329Z"
        fill="#F05F26"
      />
      <path
        d="M10.5 329L40.6381 329C40.6381 329 60.6381 282.999 92.1381 258.499C123.638 233.999 177.793 216.427 248.323 193.076C302.44 175.158 349.522 148.612 383.5 107C401.305 85.1945 450 -0.499962 450 -0.499962L426.5 -0.500222C398.5 51.4998 372 93.4998 343.771 117.822C315.542 142.144 283.067 157.499 241.339 173.789C184.428 196.006 123.049 204.051 75.3717 242.295C33.5 275.882 10.5 329 10.5 329Z"
        fill="url(#k901l0x31-data-paint0_linear)"
      />
      <path
        d="M198.527 159.345L251.899 365.138L425.323 324.8L367.594 114.391L198.527 159.345Z"
        fill="#401A49"
      />
      <path
        d="M209.945 161.907L257.074 343.629L410.213 308.01L359.236 122.211L209.945 161.907Z"
        fill="#FFE5D2"
      />
      <path
        d="M261.331 334.72L261.283 334.537L218.003 167.656L218.189 167.606L355.32 131.145L355.371 131.329L402.185 301.957L401.989 302.003L261.331 334.72ZM218.475 167.932L261.611 334.257L401.706 301.671L355.049 131.617L218.475 167.932Z"
        fill="#401A49"
      />
      <path
        d="M244.485 145.255L249.154 162.137L319.868 143.341L314.839 126.031L293.196 130.679C293.196 130.679 288.769 111.04 272.263 115.941C258.459 120.04 264.974 137.925 264.974 137.925L244.485 145.255Z"
        fill="#64B8BD"
      />
      <path
        d="M280.801 125.201C282.45 127.167 282.193 130.098 280.226 131.747C278.26 133.395 275.33 133.138 273.681 131.172C272.032 129.205 272.29 126.275 274.256 124.626C276.223 122.978 279.153 123.235 280.801 125.201Z"
        fill="#401A49"
      />
      <path
        d="M248.772 159.717C248.694 159.71 248.624 159.656 248.602 159.576C248.574 159.473 248.634 159.367 248.738 159.338L319.11 139.918C319.21 139.886 319.318 139.95 319.347 140.053C319.376 140.157 319.316 140.262 319.212 140.291L248.84 159.712C248.817 159.718 248.794 159.719 248.772 159.717Z"
        fill="#401A49"
      />
      <path
        d="M259.469 190.651L245.992 194.084L249.425 207.561L262.902 204.128L259.469 190.651Z"
        fill="#FF899A"
      />
      <path
        d="M266.736 219.177L253.259 222.61L256.692 236.087L270.169 232.653L266.736 219.177Z"
        fill="#FF899A"
      />
      <path
        d="M274.631 250.174L261.155 253.607L264.588 267.084L278.064 263.651L274.631 250.174Z"
        fill="#FF899A"
      />
      <path
        d="M315.829 180.365L269.329 192.211C269.138 192.26 268.941 192.143 268.892 191.952L268.555 190.627C268.506 190.436 268.623 190.239 268.814 190.19L315.314 178.344C315.506 178.296 315.702 178.412 315.751 178.604L316.089 179.929C316.137 180.12 316.021 180.317 315.829 180.365Z"
        fill="#64B8BD"
      />
      <path
        d="M317.145 185.532L270.645 197.378C270.454 197.427 270.257 197.31 270.209 197.119L269.871 195.793C269.822 195.602 269.939 195.405 270.13 195.356L316.631 183.51C316.822 183.461 317.019 183.578 317.067 183.769L317.405 185.095C317.453 185.287 317.336 185.484 317.145 185.532Z"
        fill="#64B8BD"
      />
      <path
        d="M354.303 200.942L277.259 220.57C276.703 220.711 276.133 220.373 275.991 219.817C275.85 219.261 276.188 218.69 276.744 218.549L353.788 198.921C354.344 198.78 354.915 199.118 355.056 199.674C355.198 200.23 354.859 200.8 354.303 200.942Z"
        fill="#64B8BD"
      />
      <path
        d="M355.619 206.108L278.575 225.735C278.019 225.877 277.449 225.538 277.307 224.982C277.166 224.426 277.504 223.856 278.06 223.714L355.104 204.086C355.66 203.945 356.23 204.284 356.373 204.84C356.514 205.396 356.175 205.967 355.619 206.108Z"
        fill="#64B8BD"
      />
      <path
        d="M362.371 232.613L285.328 252.24C284.772 252.381 284.201 252.043 284.059 251.487C283.917 250.931 284.257 250.36 284.812 250.218L361.856 230.591C362.412 230.449 362.982 230.788 363.124 231.344C363.266 231.901 362.927 232.471 362.371 232.613Z"
        fill="#64B8BD"
      />
      <path
        d="M363.688 237.779L286.644 257.406C286.088 257.548 285.517 257.209 285.376 256.653C285.235 256.097 285.573 255.527 286.129 255.385L363.173 235.758C363.729 235.616 364.299 235.955 364.441 236.511C364.582 237.067 364.244 237.637 363.688 237.779Z"
        fill="#64B8BD"
      />
      <path
        d="M277.261 323.185L266.543 279.459L266.908 279.37L373.873 253.152L384.59 296.877L384.225 296.966L277.261 323.185ZM267.452 280.011L277.812 322.275L383.68 296.325L373.321 254.063L267.452 280.011Z"
        fill="#FF899A"
      />
      <path
        d="M286.544 316.085L285.895 315.703L296.554 297.617L310.363 301.758L322.361 278.963L322.707 279.189C328.203 282.779 337.081 288.45 338.546 289.07C339.928 287.181 347.593 278.451 361.382 263.062L361.943 263.563C353.101 273.432 339.831 288.368 339.062 289.573L339.051 289.715L338.847 289.828C338.604 289.952 338.144 290.162 322.642 280.045L310.74 302.658L296.903 298.508L286.544 316.085Z"
        fill="#64B8BD"
      />
      <path
        d="M287.84 315.591C288.068 316.523 287.498 317.464 286.566 317.692C285.634 317.921 284.693 317.35 284.464 316.418C284.236 315.486 284.807 314.545 285.738 314.317C286.671 314.089 287.612 314.659 287.84 315.591Z"
        fill="#676F9A"
      />
      <path
        d="M324.122 279.2C324.35 280.133 323.779 281.073 322.847 281.302C321.915 281.53 320.975 280.96 320.746 280.028C320.518 279.095 321.088 278.155 322.02 277.926C322.952 277.698 323.893 278.268 324.122 279.2Z"
        fill="#99B3E7"
      />
      <path
        d="M363.059 263.252C363.288 264.184 362.717 265.125 361.785 265.354C360.853 265.582 359.912 265.012 359.684 264.08C359.455 263.147 360.025 262.207 360.958 261.978C361.89 261.749 362.831 262.32 363.059 263.252Z"
        fill="#64B8BD"
      />
      <path
        d="M376.572 284.509L372.175 285.587L373.253 289.984L377.65 288.906L376.572 284.509Z"
        fill="#99B3E7"
      />
      <path
        d="M375.115 278.663L370.718 279.74L371.795 284.137L376.192 283.06L375.115 278.663Z"
        fill="#676F9A"
      />
      <path
        d="M377.922 290.028L373.525 291.106L374.603 295.504L379 294.426L377.922 290.028Z"
        fill="#64B8BD"
      />
      <path
        d="M327.36 171L332.36 169.49L335.068 179.223L330.064 180.616L327.508 171.429"
        fill="#FF899A"
      />
      <path
        d="M334.875 164.132L339.861 162.573L343.816 176.789L338.814 178.181L335.08 164.763"
        fill="#FF899A"
      />
      <path
        d="M326.439 183.556C326.414 183.554 326.389 183.548 326.366 183.535C326.327 183.513 326.299 183.477 326.287 183.434L319.958 160.688C319.933 160.599 319.985 160.507 320.074 160.482C320.164 160.46 320.255 160.51 320.28 160.599L326.564 183.185L360.961 173.614C361.05 173.592 361.142 173.641 361.166 173.73C361.19 173.819 361.139 173.911 361.05 173.936L326.493 183.551C326.475 183.555 326.457 183.557 326.439 183.556Z"
        fill="#FF899A"
      />
      <path
        d="M330.056 180.782C330.031 180.78 330.006 180.773 329.983 180.761C329.944 180.739 329.916 180.703 329.904 180.66L325.911 166.307C325.886 166.218 325.938 166.126 326.027 166.101L331.112 164.686C331.155 164.675 331.2 164.68 331.239 164.702C331.278 164.724 331.306 164.76 331.318 164.802L335.312 179.156C335.336 179.245 335.284 179.337 335.195 179.361L330.11 180.777C330.092 180.781 330.074 180.783 330.056 180.782ZM326.276 166.378L330.181 180.411L334.945 179.085L331.04 165.053L326.276 166.378Z"
        fill="#FF899A"
      />
      <path
        d="M338.831 178.361C338.803 178.36 338.775 178.351 338.748 178.337C338.705 178.312 338.674 178.272 338.66 178.224L333.617 160.099C333.589 159.999 333.647 159.895 333.746 159.868L338.831 158.452C338.881 158.439 338.931 158.446 338.974 158.47C339.017 158.494 339.049 158.535 339.062 158.582L344.106 176.709C344.133 176.809 344.075 176.912 343.976 176.94L338.891 178.355C338.872 178.36 338.851 178.362 338.831 178.361ZM334.028 160.177L338.971 177.942L343.696 176.629L338.753 158.864L334.028 160.177Z"
        fill="#FF899A"
      />
      <path
        d="M347.279 176.024C347.248 176.022 347.217 176.014 347.191 175.998C347.144 175.972 347.11 175.928 347.096 175.877L341.299 155.044C341.269 154.937 341.332 154.826 341.439 154.796L346.524 153.381C346.576 153.368 346.631 153.375 346.677 153.399C346.723 153.425 346.757 153.469 346.772 153.521L352.568 174.353C352.598 174.46 352.535 174.571 352.428 174.601L347.343 176.016C347.321 176.023 347.3 176.025 347.279 176.024ZM341.74 155.13L347.429 175.575L352.127 174.268L346.438 153.822L341.74 155.13Z"
        fill="#FF899A"
      />
      <path
        d="M342.669 159.75L347.649 158.167L352.182 174.461L347.179 175.854L342.9 160.475"
        fill="#FF899A"
      />
    </g>
    <defs>
      <linearGradient
        id="k901l0x31-data-paint0_linear"
        x1="35.7629"
        y1="229.5"
        x2="412.058"
        y2="19.7771"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5BC9AF" />
        <stop offset="0.286458" stopColor="#2F9D99" />
        <stop offset="0.459848" stopColor="#2E8495" />
        <stop offset="0.630208" stopColor="#2B9997" />
        <stop offset="1" stopColor="#5BC9AF" />
      </linearGradient>
    </defs>
  </svg>
);

export default PlanIllustration;
