import { useQuery } from "react-query";
import { superviseesQueryKeys } from "queries/constants";
import { extractFiltersFromDimensions } from "components/users/utils/userUtils";
import { QueryOptions } from "queries/queries";
import { useToast } from "components/common/ui/toast/useToast";
import { fetchManySupervisees } from "api/SupervisorsApi";
import { ISuperviseesDimensions } from "models/supervisors/Supervisee";

export const useSuperviseesList = (
  supervisorId: string,
  dimensions: ISuperviseesDimensions,
  options?: QueryOptions
) => {
  const { addErrorToast } = useToast();
  const dim = extractFiltersFromDimensions(dimensions);
  return useQuery(
    superviseesQueryKeys.list(Object.keys(dim).length > 0 ? dim : undefined),

    () => fetchManySupervisees(dim, supervisorId),
    {
      onError: (e) =>
        addErrorToast(e, "Unable to load supervisees", {
          id: superviseesQueryKeys.list().join("::"),
        }),
      // staleTime: 60 * 1000,
      ...options,
    }
  );
};
