import React, { useState } from "react";
import { useForm } from "react-hook-form";
import FormActions from "components/common/ui/form-elements/form-layout/FormActions";
import {
  createPeerGroupMeeting,
  defaultPeerGroupMeetingValues,
  PeerGroupMeeting,
  peerGroupMeetingAttributeKeys,
} from "models/peergroup/meeting";
import { peerGroupMeetingFormSchema } from "models/peergroup/PeerGroupForm";
import { peerGroupMeetingAttributesMap } from "models/peergroup/meeting";
import PeerGroupMeetingAttendeesBox from "components/peergroup/common/PeerGroupMeetingAttendeesBox";
import FormSubSection from "components/common/ui/form-elements/form-layout/FormSubSection";
import { FileState } from "components/common/ui/form-elements/file-upload/File.d.ts";
import { useMutateUploadMeetingDoc } from "components/peergroup/hooks/useMutateUploadMeetingDoc";
import FileUpload from "components/common/ui/form-elements/file-upload/FileUpload";
import FileItemPeerGroupMeeting from "components/peergroup/edit/FileItemPeerGroupMeeting";
import styles from "components/cpd/activity/edit/ActivityEditForm.module.scss";

interface Props {
  peergroup_meeting?: PeerGroupMeeting;
  peergroupId: string;
  onSubmit: (attributes: Partial<PeerGroupMeeting>) => void;
  onCancel: (attributes: Partial<PeerGroupMeeting>) => void;
  saveText?: string;
  isSubmitting?: boolean;
  children?: React.ReactNode;
}

const PeerGroupMeetingEditForm = ({
  peergroup_meeting = createPeerGroupMeeting(),
  peergroupId,
  onSubmit,
  onCancel,
  saveText = "Update Peer Group Meeting",
  isSubmitting,
}: Props) => {
  const [files, setFiles] = useState([] as FileState[]);
  const { mutateAsync: mutateUploadFileDoc, isLoading: isSubmmittingFile } =
    useMutateUploadMeetingDoc();

  const {
    handleSubmit,
    formState: { errors },
    register,
    control,
  } = useForm({
    mode: "onSubmit",
    defaultValues: !!peergroup_meeting?.id
      ? (peergroup_meeting as Record<string, any>)
      : defaultPeerGroupMeetingValues,
    resolver: peerGroupMeetingFormSchema,
  });
  const handleSave = async (attributes: Partial<PeerGroupMeeting>) => {
    await onSubmit(
      peergroup_meeting?.id
        ? { ...attributes, id: peergroup_meeting?.id }
        : attributes
    );

    if (peergroup_meeting.id && files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        const f = files[i];
        if (!f.complete && (!f.progress || f.progress < 100)) {
          await mutateUploadFileDoc({
            file: f.file,
            meetingId: peergroup_meeting?.id,
            peergroupId: peergroupId,
          });
          setFiles((s) => {
            const newFiles = [...s];
            newFiles[i] = {
              ...f,
              complete: true,
            };
            return newFiles;
          });
        }
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(handleSave)} noValidate>
      <FormSubSection
        key={"meeting-form"}
        hookForm={{
          register,
          control,
          errors,
        }}
        questions={peerGroupMeetingAttributeKeys}
        questionMap={peerGroupMeetingAttributesMap}
      />
      <br />

      <PeerGroupMeetingAttendeesBox
        peergroupId={peergroupId}
        control={control}
        errors={errors}
      />
      <br />

      <h5>Add attachments to meeting</h5>
      <div className={styles.wrapRow}>
        {peergroup_meeting?.peergroup_meeting_docs?.map((d: any) => (
          <FileItemPeerGroupMeeting
            key={d.filename}
            file={d.filename}
            meetingId={peergroup_meeting?.id || ""}
            peergroupId={peergroupId}
            removeFile={true}
          />
        ))}
      </div>

      <FileUpload files={files} setState={setFiles} />

      <FormActions
        onCancel={onCancel}
        isSubmitting={isSubmmittingFile || isSubmitting}
        submitText={saveText}
      />
    </form>
  );
};

export default PeerGroupMeetingEditForm;
