import React from "react";
import { useController } from "react-hook-form";
import {
  UseMultipleSelectionStateChange,
  UseSelectStateChange,
} from "downshift";
import {
  FormQuestionProps,
  TError,
} from "components/common/ui/form-elements/formElements";
import Select from "components/common/ui/form-elements/select/Select";
import MultiSelect from "components/common/ui/form-elements/select/MultiSelect";
import { IMenuItem } from "components/common/ui/form-elements/autocomplete/MenuItem";

interface ISelectWrapper extends Omit<FormQuestionProps, "errors"> {
  error?: TError;
  multiSelect?: boolean;
}

export const SelectHookForm = ({
  multiSelect,
  control,
  register,
  defaultValue,
  question,
  ...props
}: ISelectWrapper) => {
  const {
    field: { onChange, value, ...rest },
    // fieldState: { invalid, isTouched, isDirty },
    // formState: { touchedFields, dirtyFields },
  } = useController({
    name: question.name,
    control: control,
    rules: { required: true },
    defaultValue: defaultValue || "",
  });

  const options =
    question.options?.map((d) => ({
      ...d,
      label: d.altLabel || d.label,
      key: d.value,
    })) || [];

  if (multiSelect) {
    const onMultiSelect = (
      changes: UseMultipleSelectionStateChange<IMenuItem>
    ) => {
      onChange(changes.selectedItems?.map((d) => d.value));
    };

    const multiSelected = options.filter((d) => value.includes(d.value));
    return (
      <MultiSelect
        selected={multiSelected}
        onSelect={onMultiSelect}
        label={question.label || value}
        items={options}
        {...props}
        {...rest}
      />
    );
  } else {
    const onSingleSelect = (changes: UseSelectStateChange<IMenuItem>) => {
      onChange(changes.selectedItem?.value);
    };

    const singleSelected = options.find((d) => `${value}` === `${d.value}`);
    return (
      <Select
        selected={singleSelected}
        onSelect={onSingleSelect}
        label={question.label || value}
        items={options}
        {...props}
        {...rest}
      />
    );
  }
};
