import React from "react";
import { useSelector } from "react-redux";
import { permissionsSelector } from "store/selectors/auth.selectors";
import { canAdminEdit } from "utils/authUtils";
import Button from "components/common/ui/button/Button";
import { Plan } from "models/cpd/Plan";
import { useMutatePlan } from "components/cpd/hooks/useMutatePlan";
import IconButton from "components/common/ui/button/IconButton";
import IconLock from "components/common/icons/IconLock";
import { coreService } from "services/CoreService";
import { CPD_ROUTES } from "constants/routes/cpd";
import { ROUTE_PARAMS } from "constants/routes/common";
import { useLocation } from "react-router";
import { checkTPS } from "components/cpd/utils/utils";

interface Props {
  plan: Plan;
  yearIndex: number;
}

export const LockPlanYear = ({ plan, yearIndex }: Props) => {
  const permissions = useSelector(permissionsSelector);
  const isAdmin = canAdminEdit(permissions);
  const currentYear = (plan.progress?.years || [])[yearIndex];
  const locked = !!currentYear?.locked;

  const { mutate, isLoading: isSubmitting } = useMutatePlan();

  const toggleLock = () => {
    const years = plan.progress?.years || [];
    years[yearIndex] = {
      ...currentYear,
      locked: !locked,
    };
    mutate({
      newPlan: {
        ...plan,
        progress: {
          ...plan.progress,
          years,
        },
      },
      oldPlan: plan,
      userId: plan.user_id,
    });
  };

  return isAdmin ? (
    <IconButton
      onClick={() => toggleLock()}
      submitting={isSubmitting}
      theme={locked ? "info" : "danger"}
      icon={<IconLock />}
      iconVariant="empty"
    >
      {locked ? "Unlock" : "Lock"} Year
    </IconButton>
  ) : null;
};

export const EditPlanButton = ({ plan }: { plan: Plan }) => {
  const permissions = useSelector(permissionsSelector);
  const isAdmin = canAdminEdit(permissions);
  const loc = useLocation();
  const { isTPSLocation } = checkTPS(loc);

  return isAdmin && plan ? (
    <Button
      theme="danger"
      // variant="outline"
      to={
        isTPSLocation
          ? `${coreService.getRoute(CPD_ROUTES.ADMIN_EDIT_PLAN_TPS, {
              [ROUTE_PARAMS.PLAN]: plan?.plan_id,
              [ROUTE_PARAMS.USER]: plan?.user_id,
            })}`
          : `${coreService.getRoute(CPD_ROUTES.ADMIN_EDIT_PLAN, {
              [ROUTE_PARAMS.PLAN]: plan?.plan_id,
              [ROUTE_PARAMS.USER]: plan?.user_id,
            })}`
      }
    >
      Edit Plan
    </Button>
  ) : null;
};

export const CreatePlanButton = ({ userId }: { userId: string }) => {
  const permissions = useSelector(permissionsSelector);
  const isAdmin = canAdminEdit(permissions);
  const loc = useLocation();
  const { isTPSLocation } = checkTPS(loc);

  return isAdmin && userId ? (
    <Button
      rounded
      theme="primary"
      // variant="outline"
      to={
        isTPSLocation
          ? `${coreService.getRoute(CPD_ROUTES.ADMIN_CREATE_PLAN_TPS, {
              [ROUTE_PARAMS.USER]: userId,
            })}`
          : `${coreService.getRoute(CPD_ROUTES.ADMIN_CREATE_PLAN, {
              [ROUTE_PARAMS.USER]: userId,
            })}`
      }
    >
      Create Plan
    </Button>
  ) : null;
};
