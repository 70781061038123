import styles from "./UserHeader.module.scss";
import Chip from "components/common/ui/chip/Chip";
import { StatusAction } from "components/users/common/detail/StatusActions";
import Button from "components/common/ui/button/Button";
import Container from "components/common/layouts/Container";
import React, { useState } from "react";
import { TUser } from "models/user/User";
import HintBox from "components/common/ui/hint-box/HintBox";
import Modal from "components/common/ui/modal/Modal";
import { useMutateEnable } from "components/users/hooks/useMutateEnableUser";
import {
  userFieldsKey,
  userOfficeOnlyKey,
  userTypeKey,
  userTypeOptions,
} from "models/user/attributes/common";
import { pascalCase } from "utils/format/stringUtils";
import UserIcon, { UserIconBase } from "components/users/common/UserIcon";
import { userAttributesMap } from "models/user/attributes";
import { FieldOption } from "components/common/ui/form-elements/formElements";
import Tooltip from "components/common/ui/tooltip/Tooltip";
import ActionButton from "components/common/ui/button/ActionButton";
import { IconRestore } from "components/common/icons/IconDelete";
import { IconSuspendUser } from "components/common/icons/IconUser";
import { MEMBERSHIP_STATUS_OPTIONS } from "models/user/attributes/office";
import {
  IconDanger,
  IconError,
  IconWarning,
} from "components/common/icons/twoToneIcons";
import { convertToDate, formatDate } from "utils/format/dateUtils";
import { OversightLevelChip } from "components/users/common/detail/OversightLevelChip";

interface Props extends DefaultProps {
  user: TUser;
  isLoading?: boolean;
  isAdmin?: boolean;
  editLink: string;
}
const UserHeader = ({ user, isAdmin = false, editLink }: Props) => {
  const [openSuspendModal, setSuspendModalOpen] = useState(false);
  const enableUserMutation = useMutateEnable({
    onSettled: () => setSuspendModalOpen(false),
  });
  const suspendUser = () => {
    enableUserMutation.mutate({
      user,
      active: !user.active,
    });
  };
  return (
    <>
      <Container className={styles.pageHeader}>
        <UserIcon className={styles.userIcon} user={user} />
        <div className={styles.userText}>
          <h1>{getFullName(user)}</h1>
          <div className={styles.item}>
            {user.mcnz && (
              <div className={styles.text}>
                MCNZ/ID : <span>{user.mcnz}</span>
              </div>
            )}
            <UserTypeChip user={user} />
            <MembershipStatusChip user={user} />
            <OversightLevelChip
              oversight={user[userOfficeOnlyKey]?.additional_oversight_severity}
            />
          </div>
          {/*<div className={styles.item}>*/}
          {/*  <IconEmail className={styles.icon} />*/}
          {/*  <div className={styles.text}>*/}
          {/*    {user.email ? (*/}
          {/*      <a href={`mailto:${user.email}`}>{user.email}</a>*/}
          {/*    ) : (*/}
          {/*      <i className={styles.noValue}>No email</i>*/}
          {/*    )}*/}
          {/*  </div>*/}
          {/*</div>*/}
          {/*<div className={styles.item}>*/}
          {/*  <IconPhone className={styles.icon} />*/}
          {/*  <div className={styles.text}>*/}
          {/*    {user.phone ? (*/}
          {/*      <a href={`tel:${user.phone}`}>*/}
          {/*        {formatPhoneNumber(user.phone)}*/}
          {/*      </a>*/}
          {/*    ) : (*/}
          {/*      <i className={styles.noValue}>No phone</i>*/}
          {/*    )}*/}
          {/*  </div>*/}

          {/*  <Chip*/}
          {/*    condensed*/}
          {/*    className={styles.mfa}*/}
          {/*    theme={user.mfa_enabled ? "success" : "danger"}*/}
          {/*    icon={user.mfa_enabled ? <IconMfa /> : <IconMfaDisabled />}*/}
          {/*  >*/}
          {/*    Two-Step Verification :{" "}*/}
          {/*    {user.mfa_enabled ? "ENABLED" : "DISABLED"}*/}
          {/*  </Chip>*/}
          {/*</div>*/}
        </div>
        <div className={styles.actionContainer}>
          <div className={styles.actions}>
            <StatusAction user={user} canEdit={isAdmin} actionOnly={!isAdmin} />
          </div>
          <div className={styles.actions}>
            <Button to={editLink} variant="filled" rounded>
              Edit Details
            </Button>
            {isAdmin && (
              <Tooltip
                content={
                  user.active ? "Suspend User" : "Restore Suspended User"
                }
                offset={8}
              >
                <ActionButton
                  icon={user.active ? <IconSuspendUser /> : <IconRestore />}
                  theme={user.active ? "danger" : "info"}
                  variant="lightFilled"
                  rounded
                  onClick={() => setSuspendModalOpen(true)}
                />
              </Tooltip>
            )}
          </div>
        </div>
      </Container>
      <Modal open={openSuspendModal} onClose={() => setSuspendModalOpen(false)}>
        <div className={styles.modalContent}>
          <h1>{user.active ? "Supsend" : "Restore"} User</h1>
          {user.active ? (
            <HintBox theme="danger">
              A suspended user can't log into UCCIS - their account is
              effectively disabled.
              <br />
              This process is reversible, and a suspended user can be restored
              in the future.
            </HintBox>
          ) : (
            <HintBox theme="success">
              A restored user will be able to log into the UCCIS platform again,
              and will retain all of their old permissions
            </HintBox>
          )}
          <br />
          <br />
          Are you sure you want to {user.active ? "supsend" : "restore"}{" "}
          <b>
            {user.given_name} {user.family_name}
          </b>{" "}
          ({user.email})?
        </div>
        <div className={styles.modalActions}>
          <Button onClick={() => setSuspendModalOpen(false)} variant="empty">
            Cancel
          </Button>
          <Button
            onClick={suspendUser}
            theme={user.active ? "danger" : "success"}
            rounded
            submitting={enableUserMutation.isLoading}
          >
            {user.active ? "Suspend" : "Restore"} User
          </Button>
        </div>
      </Modal>
    </>
  );
};

const getFullName = (user: TUser) => {
  const title = userAttributesMap[`${userFieldsKey}.title`].options.find(
    (f: FieldOption<keyof TUser>) => f.value === user[userFieldsKey].title
  )?.label;
  return `${title ? `${title} ` : ""}${user.given_name}${
    user.nickname ? ` (${user.nickname})` : ""
  } ${user.family_name}`;
};

const defaultChipProps = {
  variant: "lightFilled",
  theme: "primary",
  iconVariant: "empty",
  condensed: true,
} as {
  variant: FillVariants;
  theme: ThemeVariants;
};

const UserTypeChip = ({ user }: { user: TUser }) => {
  const userType = userTypeOptions.find((t) => t.value === user[userTypeKey]);
  const label = userType?.label || pascalCase(user[userTypeKey]) || "Unknown";
  return (
    <Chip {...defaultChipProps} icon={<UserIconBase user={user} />}>
      {label}
    </Chip>
  );
};

const MembershipStatusChip = ({ user }: { user: TUser }) => {
  const status = MEMBERSHIP_STATUS_OPTIONS.find(
    (t) => t.value === user[userOfficeOnlyKey]?.membership_status
  );
  if (status) {
    let icon = <></>;
    let theme = undefined;
    let label = status.label;
    switch (status.value) {
      case "pending":
        icon = <IconWarning className={styles.yellow} />;
        theme = "info";
        break;
      case "paused":
        icon = <IconError className={styles.warning} />;
        theme = "warning";
        const date = convertToDate(user.current_pause_return);
        label = `${status.label} ${
          user.current_pause_return !== "" && date
            ? `(until ${formatDate(date)})`
            : "(no planned return date)"
        }`;
        break;
      case "cancelled":
        icon = <IconDanger className={styles.danger} />;
        theme = "danger";
        break;
      case "active":
        return null;
      // icon = <IconSuccess className={styles.success} />;
      // theme = "success";
    }
    return (
      <Chip {...defaultChipProps} icon={icon} theme={theme as ThemeVariants}>
        <i>Membership Status: </i>&nbsp;&nbsp;{label}
      </Chip>
    );
  }
  return null;
};

export default UserHeader;
