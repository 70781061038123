const ROOT_API = process.env.REACT_APP_API_URL;

enum API_PARAMS {
  USER = ":user_id",
  FACILITY = ":facility_id",
  PLAN = ":plan_id",
  ACTIVITY = ":activity_id",
  GOAL = ":goal_id",
  PEERGROUP = ":peergroup_id",
  MEETING = ":meetingId",
  FILENAME = ":filename",
  END = ":end",
  START = ":start",
  SUPERVISOR = ":supervisorId",
  SUPERVISEE = ":superviseeId",
}

export const CURRENT_USER = `${ROOT_API}/me`;

export const USERS = `${ROOT_API}/users`;
export const USERS_SEARCH = `${ROOT_API}/peergroups/users`;
export const USER = `${USERS}/${API_PARAMS.USER}`;
export const USER_PERMISSONS = `${USER}/permissions`;

export const FACILITIES = `${ROOT_API}/facilities`;
export const FACILITY = `${FACILITIES}/${API_PARAMS.FACILITY}`;
export const FACILITY_USERS = `${FACILITY}/users`;
// export const FACILITY_USERS = `${FACILITY}/facilities`;
export const FACILITY_USER = `${FACILITY_USERS}/${API_PARAMS.USER}`;

export const USER_PLANS = `${USER}/plans`;
export const USER_PLAN_DETAIL = `${USER_PLANS}/${API_PARAMS.PLAN}`;

export const PLANS = `${ROOT_API}/plans`;
export const PLAN_DETAIL = `${PLANS}/${API_PARAMS.PLAN}`;

export const ACTIVITY = `${USER}/activities`;
export const ACTIVITY_DETAIL = `${ACTIVITY}/${API_PARAMS.ACTIVITY}`;
export const ACTIVITY_DETAIL_DOCS = `${ACTIVITY_DETAIL}/docs`;
export const ACTIVITY_DETAIL_GET_DOCS = `${ACTIVITY_DETAIL}/docs/${API_PARAMS.FILENAME}`;

export const GOALS = `${USER}/goals`;
export const GOAL_DETAIL = `${GOALS}/${API_PARAMS.GOAL}`;

export const USER_PEERGROUPS = `${USER}/peergroups`;
export const ADMIN_PEERGROUPS = `${ROOT_API}/admin/peergroups`;
export const PEERGROUPS = `${ROOT_API}/peergroups`;
export const PEERGROUP_DETAIL = `${PEERGROUPS}/${API_PARAMS.PEERGROUP}`;
export const PEERGROUP_MEMBERS = `${PEERGROUP_DETAIL}/members`;
export const PEERGROUP_MEETINGS = `${PEERGROUP_DETAIL}/meetings`;
export const PEERGROUP_MEETINGS_DETAIL = `${PEERGROUP_MEETINGS}/${API_PARAMS.MEETING}`;
export const USER_PEERGROUP_MEETINGS = `${USER_PEERGROUPS}/meetings?end=${API_PARAMS.END}&start=${API_PARAMS.START}`;
export const PEERGROUP_MEETING_DETAIL_DOCS = `${PEERGROUP_MEETINGS_DETAIL}/docs`;
export const PEERGROUP_MEETING_GET_DOCS = `${PEERGROUP_MEETINGS_DETAIL}/docs/${API_PARAMS.FILENAME}`;

export const SUPERVISOR = `${ROOT_API}/supervisors`;
export const MY_SUPERVISEES = `${ROOT_API}/supervisors/${API_PARAMS.SUPERVISOR}/supervisees`;
export const SUPERVISEE_DETAILS = `${MY_SUPERVISEES}/${API_PARAMS.SUPERVISEE}`;
export const SUPERVISEE_PLAN_DETAIL = `${SUPERVISEE_DETAILS}?plan_id=${API_PARAMS.PLAN}`;

export const getApiUrl = (
  url: string,
  match: {
    userId?: string;
    facilityId?: string;
    planId?: string;
    activityId?: string;
    goalId?: string;
    filename?: string;
    peergroupId?: string;
    meetingId?: string;
    end?: string;
    start?: string;
    // peerGroupId?:string;
    supervisorId?: string;
    superviseeId?: string;
  } = {}
) => {
  return url
    .replace(API_PARAMS.USER, match?.userId || "")
    .replace(API_PARAMS.FACILITY, match?.facilityId || "")
    .replace(API_PARAMS.PLAN, match?.planId || "")
    .replace(API_PARAMS.ACTIVITY, match?.activityId || "")
    .replace(API_PARAMS.GOAL, match?.goalId || "")
    .replace(API_PARAMS.PEERGROUP, match?.peergroupId || "")
    .replace(API_PARAMS.FILENAME, match?.filename || "")
    .replace(API_PARAMS.MEETING, match?.meetingId || "")
    .replace(API_PARAMS.END, match?.end || "")
    .replace(API_PARAMS.START, match?.start || "")
    .replace(API_PARAMS.SUPERVISOR, match?.supervisorId || "")
    .replace(API_PARAMS.SUPERVISEE, match?.superviseeId || "");
};
