import React from "react";
import cx from "classnames";
import styles from "./Clouds.module.scss";
interface Props {
  className?: string;
}

const Clouds = ({ className }: Props) => (
  <div className={cx(className, styles.root)}>
    <Cloud1 className={styles.right} />
    <Cloud1 className={styles.bottomLeft} />
    <Cloud2 className={styles.topLeft} />
  </div>
);

const Cloud1 = ({ className }: Props) => (
  <svg
    width="auto"
    height="auto"
    viewBox="0 0 390 144"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g filter="url(#cloud_1_filter_124132423q)">
      <path
        d="M288.176 36.281C284.499 36.2757 280.824 36.5005 277.174 36.9542C267.223 38.3522 257.118 35.9332 248.816 30.166C236.037 20.989 221.578 14.5291 206.3 11.1711C191.022 7.81306 175.237 7.62567 159.886 10.6201C144.536 13.6145 129.933 19.7294 116.948 28.6005C103.964 37.4716 92.863 48.9173 84.3073 62.2559C82.5401 64.8611 80.1628 66.9749 77.3912 68.4053C74.6197 69.8356 71.5417 70.5373 68.4367 70.4466C53.6428 70.4466 39.4549 76.4401 28.994 87.1084C18.5331 97.7767 12.6562 112.246 12.6562 127.333H377.43C377.434 115.377 375.128 103.538 370.644 92.4912C366.16 81.4444 359.587 71.4067 351.298 62.9513C343.01 54.496 333.169 47.7887 322.339 43.2125C311.508 38.6364 299.899 36.281 288.176 36.281V36.281Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="cloud_1_filter_124132423q"
        x="0.65625"
        y="0.505371"
        width="388.774"
        height="142.828"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="6" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.729412 0 0 0 0 0.780392 0 0 0 0 0.835294 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

const Cloud2 = ({ className }: Props) => (
  <svg
    width="auto"
    height="auto"
    viewBox="0 0 259 95"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g opacity="0.6" filter="url(#cloud_2_filter_12413297863q)">
      <path
        d="M158.496 24.3697C156.559 24.3736 154.625 24.5102 152.703 24.7787C147.41 25.6035 142.035 24.1782 137.619 20.7792C130.822 15.3665 123.129 11.5558 115 9.57419C106.871 7.59256 98.4726 7.48067 90.3046 9.24505C82.1366 11.0094 74.3669 14.614 67.4582 19.8439C60.5495 25.0738 54.6432 31.822 50.092 39.6861C49.1495 41.2194 47.8838 42.4629 46.4089 43.3041C44.9339 44.1453 43.2965 44.5576 41.645 44.5038C33.7827 44.5038 26.2421 48.0328 20.6826 54.3146C15.1231 60.5964 12 69.1163 12 78H206C206 70.9533 204.771 63.9758 202.383 57.4661C199.995 50.9564 196.496 45.0422 192.084 40.0616C187.672 35.081 182.435 31.1315 176.672 28.439C170.908 25.7465 164.732 24.3637 158.496 24.3697Z"
        fill="white"
      />
      <path
        d="M100.367 33.571C102.298 33.5742 104.227 33.6874 106.143 33.9101C111.421 34.5938 116.781 33.4122 121.184 30.5944C127.962 26.1071 135.632 22.9479 143.738 21.3051C151.843 19.6622 160.217 19.5695 168.362 21.0322C176.506 22.4949 184.254 25.4832 191.143 29.819C198.031 34.1548 203.921 39.7492 208.459 46.2688C209.398 47.54 210.66 48.5709 212.131 49.2683C213.602 49.9656 215.235 50.3075 216.881 50.2628C224.721 50.2628 232.24 53.1885 237.783 58.3963C243.327 63.6041 246.441 70.6673 246.441 78.0323H53C53 72.1904 54.2254 66.4057 56.6064 61.009C58.9873 55.6122 62.4767 50.7092 66.8757 46.5801C71.2747 42.451 76.4971 39.1767 82.2438 36.9446C87.9904 34.7124 94.1488 33.5661 100.367 33.571Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="cloud_2_filter_12413297863q"
        x="0"
        y="0"
        width="258.441"
        height="94.0323"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="6" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.729412 0 0 0 0 0.780392 0 0 0 0 0.835294 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export default Clouds;
