import Chip from "components/common/ui/chip/Chip";
import { keyBy } from "utils/common";
import {
  PLAN_STATUS,
  planPathwayOptions,
  planStatusOptions,
} from "models/cpd/Plan";
import { PATHWAY } from "models/cpd/common/requirements";

interface PathwayProps extends DefaultProps {
  pathway?: PATHWAY;
}
interface StatusProps extends PathwayProps {
  status?: PLAN_STATUS;
}

const statusLabelMap = keyBy(
  planStatusOptions,
  "key",
  (t) => t.altLabel || t.label
);
const pathwayLabelMap = keyBy(
  planPathwayOptions,
  "key",
  (t) => t.altLabel || t.label
);

export const PlanAttributeChip = ({
  status,
  pathway,
  ...props
}: StatusProps) => {
  const s = status ? statusLabelMap[status] : undefined;
  const p = pathway ? pathwayLabelMap[pathway] : undefined;

  return s || p ? (
    <Chip variant="lightFilled" theme="primary" {...props}>
      {s || p}
    </Chip>
  ) : (
    <></>
  );
};
