import React, { useState } from "react";
import Container from "components/common/layouts/Container";
import Button from "components/common/ui/button/Button";
import UsersMemberList from "components/peergroup/members/UserMembersList";
import Modal from "components/common/ui/modal/Modal";
import { usePeerGroupMembers } from "components/peergroup/hooks/usePeerGroupMembers";
import { useParams } from "react-router";
import { usePeerGroupDetail } from "components/peergroup/hooks/usePeerGroupDetails";
import FormActions from "components/common/ui/form-elements/form-layout/FormActions";
import Spacer from "components/common/ui/spacer/Spacer";
import AddManyMembersToPeerGroup from "components/peergroup/create/add-member/AddManyMembersToPeerGroup";

interface Props {
  canEditPeerGroup?: boolean;
}

const MemberTab = ({ canEditPeerGroup }: Props) => {
  const { peergroupId } = useParams<{ peergroupId: string; orgId: string }>();
  const { data: peergroupDetail } = usePeerGroupDetail(peergroupId);
  const { data } = usePeerGroupMembers(peergroupId);
  const [open, setOpen] = useState(false);
  return (
    <Container>
      <Spacer height={10} />
      {canEditPeerGroup && (
        <FormActions>
          <Button theme="primary" onClick={() => setOpen(true)} rounded>
            Add New Member
          </Button>
        </FormActions>
      )}

      <UsersMemberList
        users={data || []}
        canEditPeerGroup={canEditPeerGroup}
        peergroupDetail={peergroupDetail}
      />

      <Modal open={open} onClose={() => setOpen(false)}>
        <h2>Add new member to Peer Group</h2>
        <AddManyMembersToPeerGroup
          peergroup={peergroupDetail}
          onCancel={() => setOpen(false)}
          members={[...(data?.accepted ?? []), ...(data?.deleted ?? [])]}
        />
      </Modal>
    </Container>
  );
};

export default MemberTab;
