import React from "react";
import cx from "classnames";
import { useLayer } from "react-laag";
import {
  useSelect,
  UseSelectState,
  useMultipleSelection,
  UseMultipleSelectionStateChange,
  UseSelectStateChangeOptions,
} from "downshift";
import { InputWrapper } from "components/common/ui/form-elements/common/InputWrapper";
import { MenuContext } from "components/common/ui/form-elements/autocomplete/MenuContext";
import { IMenuItem } from "components/common/ui/form-elements/autocomplete/MenuItem";
import Menu from "components/common/ui/form-elements/autocomplete/Menu";
import { isSelected } from "components/common/ui/form-elements/autocomplete/utils";
import ActiveOptionChip from "components/common/ui/select/ActiveOptionChip";
import ActionButton from "components/common/ui/button/ActionButton";
import IconExpandArrow from "components/common/icons/IconExpandArrow";
import {
  defaultLayerProps,
  itemToString,
  SelectProps,
} from "components/common/ui/form-elements/select/common";
import styles from "./Select.module.scss";

interface Props<Item extends IMenuItem>
  extends Omit<SelectProps<Item>, "selected" | "onSelect"> {
  selected: Item[];
  onSelect: (changes: UseMultipleSelectionStateChange<Item>) => void;
}

const MultiSelect = React.memo(
  React.forwardRef(
    <T extends IMenuItem = IMenuItem>({
      name,
      children,
      className,
      selected,
      onSelect,
      direction = "bottom-center",
      offset = 8,
      disabled,
      items,
      label,
      description,
      error,
      placeholder = "Select an option",
    }: Props<T>) =>
      // ref: React.Ref<any>
      {
        const {
          getSelectedItemProps,
          getDropdownProps,
          addSelectedItem,
          removeSelectedItem,
          setSelectedItems,
          selectedItems,
        } = useMultipleSelection({
          initialSelectedItems: selected,
          selectedItems: selected,
          onSelectedItemsChange: onSelect,
          itemToString,
        });

        const update = (selectedItem: T) => {
          const value = selectedItem.value;
          if (isSelected(value, selected)) {
            const newSelected = selected.filter(
              (s) => s.value !== selectedItem.value
            );
            setSelectedItems(newSelected);
          } else {
            addSelectedItem(selectedItem);
          }
        };

        const {
          isOpen,
          getMenuProps,
          // selectedItem,
          getLabelProps,
          getItemProps,
          getToggleButtonProps,
          highlightedIndex,
          // inputValue,
        } = useSelect<T>({
          items,
          defaultHighlightedIndex: 0, // after selection, highlight the first item.
          itemToString,
          selectedItem: null,
          onStateChange: ({ type, selectedItem }) => {
            switch (type) {
              case useSelect.stateChangeTypes.MenuKeyDownEnter:
              case useSelect.stateChangeTypes.MenuKeyDownSpaceButton:
              case useSelect.stateChangeTypes.ItemClick:
                if (selectedItem) {
                  update(selectedItem);
                }
                break;
              default:
                break;
            }
          },
          stateReducer: selectStateReducer,
        });

        // the positioning stuff...
        const {
          renderLayer,
          triggerProps,
          layerProps,
          triggerBounds,
          layerSide,
        } = useLayer({
          ...defaultLayerProps,
          isOpen,
          placement: direction,
          containerOffset: offset,
        });
        return (
          <>
            <InputWrapper
              label={label}
              description={description}
              name={name}
              labelProps={getLabelProps()}
              inputWrapperProps={getDropdownProps()}
              className={className}
              disabled={disabled}
              error={error}
            >
              <div
                className={cx(styles.multiwrap, disabled && styles.disabled)}
                {...triggerProps}
              >
                {selectedItems?.map((selectedItem, index) => (
                  <span
                    key={index}
                    {...getSelectedItemProps({ selectedItem, index })}
                    className={styles.selected}
                  >
                    <ActiveOptionChip
                      id={selectedItem.value}
                      key={`selected-item-${index}`}
                      label={selectedItem.label}
                      onClick={() => {
                        removeSelectedItem(selectedItem);
                      }}
                      theme={disabled ? "grey" : selectedItem.theme}
                      color={selectedItem.color}
                      cancelIcon={!disabled}
                    />
                  </span>
                ))}
                {selectedItems.length === 0 && (
                  <span className={styles.placeholder}>{placeholder}</span>
                )}
                <ActionButton
                  icon={<IconExpandArrow />}
                  variant="empty"
                  className={styles.expandIcon}
                  disabled
                />

                <button
                  type="button"
                  className={styles.bg}
                  {...getToggleButtonProps()}
                />
              </div>
            </InputWrapper>
            <MenuContext.Provider
              value={{
                name,
                isOpen,
                highlightedIndex,
                selectedItem: selectedItems,
                getItemProps,
                layerSide,
                menuProps: getMenuProps(layerProps),
                triggerBounds,
                offset,
                items,
                // query: inputValue,
                isMultiselect: true,
              }}
            >
              {renderLayer(children ? children({ items }) : <Menu />)}
            </MenuContext.Provider>
          </>
        );
      }
  )
);

export const selectStateReducer: <T extends IMenuItem = IMenuItem>(
  state: UseSelectState<T>,
  actionAndChanges: UseSelectStateChangeOptions<T>
) => Partial<UseSelectState<T>> = (state, actionAndChanges) => {
  const { changes, type } = actionAndChanges;
  switch (type) {
    case useSelect.stateChangeTypes.MenuKeyDownEnter:
    case useSelect.stateChangeTypes.MenuKeyDownSpaceButton:
    case useSelect.stateChangeTypes.ItemClick:
      return {
        ...changes,
        isOpen: true, // keep the menu open after selection.
      };
  }
  return changes;
};

// const filterSelected = (items: IMenuItem[], selected: IMenuItem[]) =>
//   items.filter((item) => !selected.some((s) => s.value === item.value));

export default MultiSelect;
