import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { DropzoneOptions, useDropzone } from "react-dropzone";
import FilePreview from "./FilePreview";
import styles from "./FileUpload.module.scss";
import IconUpload from "components/common/icons/IconUpload";
import { FileState } from "./File.d.ts";
import { AnimateSharedLayout, AnimatePresence } from "framer-motion";

interface Props extends Omit<DropzoneOptions, "onDrop"> {
  multiple?: boolean;
  mimeType?: string;
  setState?: Dispatch<SetStateAction<FileState[]>>;
  files?: FileState[];
  onChange?: (files: FileState[]) => void;
  noIcon?: boolean;
}

const FileUpload = ({
  onChange,
  multiple = true,
  mimeType,
  noIcon,
  files: outerState,
  setState: outerSetState,
  ...props
}: Props) => {
  const innerState = useState<FileState[]>([]);
  const files = outerState || innerState[0];
  const setFiles = outerSetState || innerState[1];

  const onDrop = useCallback(
    (acceptedFiles: File[]) =>
      setFiles((s) => {
        const f: FileState[] = acceptedFiles.map((f) => ({
          file: f,
        }));
        return multiple ? removeDuplicates(f, s) : f;
      }),
    [multiple, setFiles]
  );

  const removeFile = (index: number) => {
    setFiles((s) => {
      const newFiles = [...s];
      newFiles.splice(index, 1);
      return newFiles;
    });
  };
  const dropProps = mimeType ? { accept: mimeType } : {};

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    ...dropProps,
    ...props,
  });

  useEffect(() => {
    if (onChange) {
      onChange(files);
    }
  }, [files, onChange]);

  return (
    <>
      <div
        {...getRootProps({ className: styles.root })}
        aria-label="file upload"
      >
        <input {...getInputProps()} className={styles.input} />
        {!noIcon && (
          <div className={styles.uploadIcon}>
            <IconUpload />
          </div>
        )}
        <label>
          {isDragActive
            ? "Drop the files here ..."
            : "Drag files here, or click to select files from your computer"}
        </label>
      </div>
      <div className={styles.fileList}>
        <AnimateSharedLayout>
          <AnimatePresence>
            {files.map((f: FileState, i) => (
              <FilePreview
                {...f}
                removeFile={() => removeFile(i)}
                key={f.file.name}
              />
            ))}
          </AnimatePresence>
        </AnimateSharedLayout>
      </div>
    </>
  );
};

const removeDuplicates = (newState: FileState[], oldState: FileState[]) => {
  const ids = new Set(newState.map((d) => d.file.name));
  return [...oldState.filter((d) => !ids.has(d.file.name)), ...newState];
};

export default FileUpload;
