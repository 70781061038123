import React from "react";
import createIcon from "components/common/icons/common/createIcon";

const IconList = createIcon(
  <React.Fragment>
    <path d="M9,5V9H21V5M9,19H21V15H9M9,14H21V10H9M4,9H8V5H4M4,19H8V15H4M4,14H8V10H4V14Z" />
  </React.Fragment>,
  "List"
);

export default IconList;
