import React from "react";
import styles from "./LighthouseAndBirds.module.scss";

interface Props {
  className?: string;
}

const LighthouseAndBirds = ({ className }: Props) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 911 851"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M697.914 346.44H739.95L769.622 812.873H697.914V346.44Z"
      fill="#6A628F"
    />
    <path
      d="M581.202 346.44H625.711V812.873H554.002L581.202 346.44Z"
      fill="#F5F9FB"
    />
    <path d="M625.71 346.44H697.913V812.873H625.71V346.44Z" fill="#E1DFED" />
    <path
      d="M597.112 304.936H723.63L697.914 346.44H625.71L597.112 304.936Z"
      fill="#DAD7EB"
    />
    <path
      d="M597.112 304.936H723.63L697.914 346.44L651.754 327.409L597.112 304.936Z"
      fill="#61578E"
      fillOpacity="0.1"
    />
    <path
      d="M723.63 304.936H767.644L739.95 346.44H697.914L723.63 304.936Z"
      fill="#61578E"
    />
    <path
      d="M597.409 304.936H553.816L581.249 346.44H625.902L597.409 304.936Z"
      fill="#F5F9FB"
    />
    <rect
      x="546.583"
      y="286.16"
      width="177.046"
      height="18.7759"
      fill="#F5F9FB"
    />
    <rect
      x="723.629"
      y="286.16"
      width="55.3888"
      height="18.7759"
      fill="#A198C7"
    />
    <path
      d="M592.647 169.353L638.652 170.807V286.282H592.647V169.353Z"
      fill="url(#lighthouse_birds_paint0_linear)"
    />
    <path
      d="M638.652 170.805H690.657V286.28H638.652V170.805Z"
      fill="url(#lighthouse_birds_paint1_linear)"
    />
    <path
      d="M690.657 170.805L736.662 169.353V286.28H690.657V170.805Z"
      fill="url(#lighthouse_birds_paint2_linear)"
    />
    <rect
      x="690.545"
      y="170.245"
      width="46.117"
      height="116.037"
      fill="url(#lighthouse_birds_paint3_linear)"
    />
    <rect
      opacity="0.8"
      x="638.764"
      y="170.245"
      width="51.7805"
      height="116.037"
      fill="url(#lighthouse_birds_paint4_linear)"
    />
    <path
      d="M592.648 168.46L638.765 170.399V286.282H592.648V168.46Z"
      fill="url(#lighthouse_birds_paint5_linear)"
    />
    <path
      d="M662.246 117.109H671.529L679.453 152.109H655.453L662.246 117.109Z"
      fill="#D75A88"
    />
    <path
      d="M670.453 117.109H671.529L679.453 152.109H673.453L670.453 117.109Z"
      fill="#C54385"
    />
    <path
      d="M639.522 150.472L659.084 141.344H671.147L690.292 151.546L693.12 187.266H635.389L639.522 150.472Z"
      fill="url(#lighthouse_birds_paint6_radial)"
    />
    <path
      d="M631.13 146.12C642.598 143.371 649.454 142.265 659.084 141.344L643.878 159.918L635.389 187.266H579.812C579.823 181.825 581.655 177.37 588.636 170.002C604.466 158.584 613.628 152.959 631.13 146.12Z"
      fill="url(#lighthouse_birds_paint7_radial)"
    />
    <path
      d="M696.464 146.12C686.842 143.675 682.919 142.485 671.147 141.344L683.716 159.918L693.12 187.266H748.696C747.478 181.05 748.21 178.176 738.958 170.002C724.086 159.239 715.283 153.592 696.464 146.12Z"
      fill="url(#lighthouse_birds_paint8_radial)"
    />
    <mask
      id="lighthouse_birds_mask0"
      maskUnits="userSpaceOnUse"
      x="635"
      y="141"
      width="59"
      height="47"
    >
      <path
        d="M646.574 152.001C651.121 146.776 654.65 144.281 659.499 141.209H671.562C678.233 144.572 680.178 147.435 684.487 152.001C692.241 171.094 690.856 169.442 693.965 187.697H635.373C638.193 174.828 638.847 168.764 646.574 152.001Z"
        fill="#D34A7C"
      />
    </mask>
    <g mask="url(#lighthouse_birds_mask0)">
      <path
        d="M639.522 150.472L658.22 141.344H669.374L690.292 151.546L693.103 187.266H636.665L639.522 150.472Z"
        fill="#D75A88"
      />
      <path
        d="M631.13 146.12C642.598 143.371 648.59 142.265 658.22 141.344L643.878 159.918L636.665 187.266H579.812C579.823 181.825 581.655 177.37 588.636 170.002C604.466 158.584 613.628 152.959 631.13 146.12Z"
        fill="#D75A88"
      />
      <path
        d="M696.464 146.12C686.842 143.675 681.146 142.485 669.374 141.344L683.716 159.918L693.103 187.266H748.696C747.478 181.05 748.21 178.176 738.958 170.002C724.086 159.239 715.283 153.592 696.464 146.12Z"
        fill="#D75A88"
      />
    </g>
    <mask
      id="lighthouse_birds_mask1"
      maskUnits="userSpaceOnUse"
      x="647"
      y="710"
      width="36"
      height="70"
    >
      <path
        d="M647.641 721.298L660.567 710.145H670.555L682.893 721.298V780H647.641V721.298Z"
        fill="#3E1A83"
      />
    </mask>
    <g mask="url(#lighthouse_birds_mask1)">
      <rect
        x="642.477"
        y="697.128"
        width="51.7379"
        height="95.5174"
        fill="#34187A"
      />
    </g>
    <mask
      id="lighthouse_birds_mask2"
      maskUnits="userSpaceOnUse"
      x="642"
      y="191"
      width="45"
      height="60"
    >
      <rect
        x="642.386"
        y="191.704"
        width="44.1224"
        height="58.5101"
        fill="#5FDBED"
      />
    </mask>
    <g mask="url(#lighthouse_birds_mask2)">
      <rect
        x="641.553"
        y="191.704"
        width="45.7875"
        height="58.5101"
        fill="#5FDBED"
      />
      <rect
        opacity="0.2"
        width="78.1483"
        height="53.4164"
        transform="matrix(0.857489 -0.514503 -0.711106 -0.703085 624.885 227.789)"
        fill="white"
      />
      <rect
        opacity="0.2"
        width="81.3252"
        height="22.636"
        transform="matrix(0.857489 -0.514502 -0.711107 -0.703084 629.694 237.166)"
        fill="white"
      />
    </g>
    <mask
      id="lighthouse_birds_mask3"
      maskUnits="userSpaceOnUse"
      x="693"
      y="191"
      width="41"
      height="60"
    >
      <rect
        x="693.729"
        y="191.704"
        width="39.309"
        height="58.5101"
        fill="#5FDBED"
      />
    </mask>
    <g mask="url(#lighthouse_birds_mask3)">
      <rect
        x="693.729"
        y="191.704"
        width="39.309"
        height="58.5101"
        fill="#5FDBED"
      />
      <rect
        opacity="0.2"
        width="70.1881"
        height="49.7384"
        transform="matrix(0.819657 -0.572854 -0.655639 -0.755075 679.418 227.789)"
        fill="white"
      />
      <rect
        opacity="0.2"
        width="73.0413"
        height="21.0774"
        transform="matrix(0.819658 -0.572854 -0.655639 -0.755075 683.546 237.166)"
        fill="white"
      />
    </g>
    <mask
      id="lighthouse_birds_mask4"
      maskUnits="userSpaceOnUse"
      x="595"
      y="191"
      width="41"
      height="60"
    >
      <rect
        x="595.857"
        y="191.704"
        width="39.309"
        height="58.5101"
        fill="#5FDBED"
      />
    </mask>
    <g mask="url(#lighthouse_birds_mask4)">
      <rect
        x="595.857"
        y="191.704"
        width="39.309"
        height="58.5101"
        fill="#5FDBED"
      />
      <rect
        opacity="0.2"
        width="70.1882"
        height="49.7385"
        transform="matrix(0.819658 -0.572853 -0.655639 -0.755074 581.546 227.789)"
        fill="white"
      />
      <rect
        opacity="0.2"
        width="73.0413"
        height="21.0774"
        transform="matrix(0.819658 -0.572854 -0.655639 -0.755075 585.676 237.166)"
        fill="white"
      />
    </g>
    <path
      d="M459.953 664.109L399.453 850.109H706.123L664.453 781.109L634.453 721.609L547.453 704.109L492.453 672.609L459.953 664.109Z"
      fill="#DADEE0"
    />
    <path
      d="M459.953 664.109L399.453 850.109H432.953L452.953 737.109L492.453 672.609L459.953 664.109Z"
      fill="#F2F2F0"
    />
    <path
      d="M634.407 720.921L547.453 704.109L568.953 753.109L590.453 781.109L595.705 850.109H706.453L668.453 789.581L634.407 720.921Z"
      fill="#C0C2C3"
    />
    <path
      d="M838.953 704.609L685.953 736.609L678.453 756.109L655.453 773.109L603.453 850.109H910.123L886.296 781.704L838.953 704.609Z"
      fill="#DADEE0"
    />
    <path
      d="M703.953 732.85L685.953 736.609L678.453 756.109L655.453 773.109L603.453 850.109H644.953L679.953 810.609L703.953 732.85Z"
      fill="#F2F2F0"
    />
    <path
      d="M838.453 704.109L794.453 713.609L809.953 744.609L806.953 792.609L812.953 850.109H910.123L886.296 781.704L838.453 704.109Z"
      fill="#C0C2C3"
    />
    <path
      d="M326.453 756.109L302.953 850.609H502.453L486.953 806.109L434.453 729.609L326.453 756.109Z"
      fill="#DADEE0"
    />
    <path
      d="M326.453 756.109L302.953 850.609H336.953V795.109L342.953 751.109L326.453 756.109Z"
      fill="#F2F2F0"
    />
    <path
      d="M434.453 729.609L412.453 735.109V775.109L399.953 850.609H502.453L486.953 806.109L434.453 729.609Z"
      fill="#C0C2C3"
    />
    <path
      d="M306.596 797.109L278.953 798.609L263.953 810.609L248.453 850.609H336.662L329.809 830.934L306.596 797.109Z"
      fill="#DADEE0"
    />
    <path
      d="M280.953 798.609H278.953L263.953 810.609L248.453 850.609H262.453L268.453 818.109L280.953 798.609Z"
      fill="#F2F2F0"
    />
    <path
      d="M306.596 797.109L297.453 797.609L296.869 817.227L291.342 850.609H336.662L329.809 830.934L306.596 797.109Z"
      fill="#C0C2C3"
    />
    <g filter="url(#lighthouse_birds_filter0_d)" className={styles.bird1}>
      <path
        d="M429.451 137.737C424.005 138.952 418.967 137.686 414.128 135.186C413.982 135.112 413.788 135.091 413.684 134.981C410.52 131.593 406.318 132.439 402.391 132.136C399.408 131.905 396.412 131.731 393.456 131.291C387.932 130.474 388.714 130.898 389.002 135.182C389.111 136.823 389.256 138.465 389.394 140.211C385.39 141.428 381.533 141.718 378.088 139.088C376.53 137.899 375.288 136.262 373.995 134.746C373.314 133.949 373.452 133.255 374.498 132.643C376.338 131.565 378.09 130.33 379.843 129.112C381.578 127.903 381.493 127.161 379.704 125.966C375.342 123.053 371.004 120.101 366.671 117.145C366.087 116.744 365.564 116.248 365.04 115.766C362.83 113.734 360.429 112.497 357.273 112.149C352.027 111.574 347.883 108.626 344.453 104.514C344.702 104.328 344.82 104.164 344.938 104.164C350.631 104.16 356.327 104.015 362.012 104.214C368.106 104.43 373.448 106.652 377.997 110.887C379.951 112.706 382.24 114.181 384.473 115.66C387.565 117.712 389.213 117.597 392.247 115.412C395.414 113.127 397.378 112.785 401.334 113.853C399.968 115.3 398.56 116.668 397.301 118.16C396.185 119.482 396.412 120.007 398.087 120.216C401.664 120.659 405.262 120.954 408.824 121.492C411.415 121.883 413.639 123.184 415.601 124.949C419.766 128.691 423.951 132.412 428.137 136.13C428.539 136.49 429.013 136.758 429.453 137.071C429.451 137.295 429.451 137.514 429.451 137.737Z"
        fill="#FCFCF8"
      />
    </g>
    <g filter="url(#lighthouse_birds_filter1_d)" className={styles.bird2}>
      <path
        d="M154.697 2.10938C158.205 4.08677 161.713 6.06108 165.22 8.04002C168.275 9.7644 171.384 11.4556 173.474 14.3956C174.48 15.8121 175.327 17.3646 176.077 18.9371C177.439 21.7991 178.673 24.7218 179.963 27.6166C180.096 27.9144 180.247 28.2007 180.391 28.4938C181.748 31.2894 182.968 32.1477 185.993 31.6374C188.501 31.2152 190.628 31.6633 192.662 33.0756C193.385 33.5758 194.194 33.9528 195.112 34.4705C193.161 35.0456 191.383 35.5211 189.644 36.111C189.116 36.2902 188.699 36.7874 188.229 37.1385C188.598 37.5866 188.891 38.1375 189.345 38.4623C191.304 39.8541 193.317 41.1667 195.296 42.5284C197.743 44.2122 199.605 46.3773 200.583 49.215C202.443 54.6179 204.275 60.031 205.914 65.7302C204.723 65.1941 203.481 64.7475 202.351 64.1028C198.467 61.8913 195.592 58.7995 194.161 54.4735C194.027 54.0687 193.592 53.7075 193.213 53.4502C188.603 50.3109 183.977 47.1959 179.357 44.0739C179.12 43.9148 178.896 43.7386 178.668 43.5667C177.188 42.4496 176.801 42.4712 175.851 44.1203C174.781 45.9791 173.853 47.9186 172.841 49.867C168.849 49.0531 165.846 46.927 164.546 43.1271C164.046 41.6643 163.156 40.0542 163.731 38.2518C163.958 37.5422 164.12 37.2212 164.891 37.227C166.759 37.2413 168.628 37.1849 170.497 37.1416C170.737 37.1374 170.978 37.0678 171.211 36.9941C172.643 36.5433 172.714 36.2814 172.104 34.9475C169.303 28.816 166.545 22.666 163.729 16.5418C163.52 16.0879 162.998 15.7584 162.582 15.4188C161.746 14.7394 160.869 14.1109 160.042 13.4214C159.734 13.1656 159.568 12.7349 159.255 12.492C156.5 10.3628 155.498 7.2856 154.654 4.11149C154.625 4.00604 154.523 3.91913 154.453 3.82255C154.534 3.25124 154.615 2.68031 154.697 2.10938Z"
        fill="#FCFCF8"
      />
    </g>
    <g filter="url(#lighthouse_birds_filter2_d)" className={styles.bird3}>
      <path
        d="M63.8046 172.549C61.8589 170.655 59.7687 168.877 58.02 166.815C57.0226 165.64 56.583 163.992 55.8763 162.524C59.7337 159.832 65.0291 159.544 67.5239 155.038C61.2034 149.967 55.1107 145.178 49.1399 140.246C46.2587 137.865 43.5699 135.251 40.8702 132.662C40.0243 131.852 39.2493 131.431 38.0169 131.457C36.6975 131.488 35.3108 131.229 34.0575 130.797C31.923 130.061 29.8761 129.078 27.7762 128.232C24.4682 126.9 18.2294 120.796 16.9967 117.523C19.876 117.757 22.6714 117.977 25.4612 118.208C30.2864 118.607 35.1089 119.064 39.9395 119.393C48.0174 119.945 54.8323 123.233 60.8224 128.569C64.8221 132.131 68.9689 135.531 73.0915 138.952C76.546 141.822 78.7051 142.234 82.4048 139.773C85.9706 137.4 89.5847 136.572 93.712 137.362C94.9699 137.603 96.2798 137.586 98.0168 137.724C96.238 139.256 94.6975 140.547 93.1996 141.884C92.3839 142.611 91.5687 143.361 90.8769 144.199C89.5638 145.793 89.8643 146.759 91.8685 147.159C94.8441 147.754 97.8291 148.375 100.846 148.678C108.447 149.448 114.479 152.955 119.613 158.505C124.608 163.901 129.894 169.027 135.047 174.278C135.39 174.629 135.697 175.013 136.489 175.917C133.651 175.883 131.346 176.047 129.089 175.794C122.19 175.029 116.175 172.292 111.213 167.345C110.76 166.896 110.038 166.593 109.391 166.481C99.9206 164.826 90.4362 163.227 80.9582 161.59C78.6448 161.191 77.9666 161.526 77.8583 163.874C77.719 166.837 77.7361 169.821 77.9244 172.781C78.0283 174.411 77.416 175.086 75.9561 175.082C73.397 175.073 70.8393 174.958 68.281 174.89C66.7921 174.112 65.2981 173.33 63.8046 172.549Z"
        fill="#FCFCF8"
      />
    </g>
    <defs>
      <filter
        id="lighthouse_birds_filter0_d"
        x="340.453"
        y="102.109"
        width="93"
        height="45"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0901961 0 0 0 0 0.105882 0 0 0 0 0.117647 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
      <filter
        id="lighthouse_birds_filter1_d"
        x="150.453"
        y="0.109375"
        width="59.4606"
        height="71.6209"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0901961 0 0 0 0 0.105882 0 0 0 0 0.117647 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
      <filter
        id="lighthouse_birds_filter2_d"
        x="0.453125"
        y="98.1094"
        width="147.457"
        height="111.015"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0901961 0 0 0 0 0.105882 0 0 0 0 0.117647 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
      <linearGradient
        id="lighthouse_birds_paint0_linear"
        x1="592.642"
        y1="203.758"
        x2="737.394"
        y2="215.492"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F5828E" />
        <stop offset="1" stopColor="#BC3784" />
      </linearGradient>
      <linearGradient
        id="lighthouse_birds_paint1_linear"
        x1="592.642"
        y1="203.758"
        x2="737.394"
        y2="215.492"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F5828E" />
        <stop offset="1" stopColor="#BC3784" />
      </linearGradient>
      <linearGradient
        id="lighthouse_birds_paint2_linear"
        x1="592.642"
        y1="203.758"
        x2="737.394"
        y2="215.492"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F5828E" />
        <stop offset="1" stopColor="#BC3784" />
      </linearGradient>
      <linearGradient
        id="lighthouse_birds_paint3_linear"
        x1="690.68"
        y1="196.155"
        x2="737.03"
        y2="195.811"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CD4E87" />
        <stop offset="1" stopColor="#BC3784" />
      </linearGradient>
      <linearGradient
        id="lighthouse_birds_paint4_linear"
        x1="640.2"
        y1="196.155"
        x2="686.237"
        y2="196.155"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D85C89" />
        <stop offset="1" stopColor="#D75A88" />
      </linearGradient>
      <linearGradient
        id="lighthouse_birds_paint5_linear"
        x1="592.642"
        y1="223.128"
        x2="638.995"
        y2="222.438"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FB8A8F" />
        <stop offset="0.973401" stopColor="#FB8A8F" stopOpacity="0" />
      </linearGradient>
      <radialGradient
        id="lighthouse_birds_paint6_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(598.768 161.44) rotate(5.17183) scale(124.152 439.657)"
      >
        <stop stopColor="#FB8A8F" />
        <stop offset="1" stopColor="#BC3784" />
      </radialGradient>
      <radialGradient
        id="lighthouse_birds_paint7_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(598.768 161.44) rotate(5.17183) scale(124.152 439.657)"
      >
        <stop stopColor="#FB8A8F" />
        <stop offset="1" stopColor="#BC3784" />
      </radialGradient>
      <radialGradient
        id="lighthouse_birds_paint8_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(598.768 161.44) rotate(5.17183) scale(124.152 439.657)"
      >
        <stop stopColor="#FB8A8F" />
        <stop offset="1" stopColor="#BC3784" />
      </radialGradient>
    </defs>
  </svg>
);

export default LighthouseAndBirds;
