import React from "react";

interface Props {
  className?: string;
}

const LetterIllus = ({ className }: Props) => (
  <svg
    width="1202"
    height="1351"
    viewBox="0 0 1202 1351"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <mask
      id="letter-illustration-mask"
      // maskType="alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="1202"
      height="1356"
    >
      <rect width="1202" height="1356" fill="#C4C4C4" />
      <rect width="1202" height="1356" fill="url(#paint0_linear)" />
    </mask>
    <g mask="url(#letter-illustration-mask)">
      <path
        opacity="0.1"
        d="M665 781L1011.66 971.902L1011.83 1608.88L665.173 1418.15L665 781Z"
        fill="url(#paint1_linear)"
      />
      <path
        opacity="0.1"
        d="M708 744L1054.66 934.902L1054.83 1571.88L708.173 1381.15L708 744Z"
        fill="url(#paint2_linear)"
      />
      <path
        opacity="0.2"
        d="M709.909 1153.3L971.243 1297.21L971.934 1525.61L710.6 1381.69L709.909 1153.3Z"
        fill="url(#paint3_linear)"
      />
      <path
        opacity="0.15"
        d="M714.573 1001.27L835.481 1067.79V1091.28L714.573 1024.77V1001.27Z"
        fill="white"
      />
      <path
        opacity="0.15"
        d="M715.091 1047.92L835.999 1114.43V1137.93L715.091 1071.41V1047.92Z"
        fill="white"
      />
      <path
        opacity="0.15"
        d="M714.917 1092.84L944.298 1219.13V1242.62L714.917 1116.33V1092.84Z"
        fill="white"
      />
      <path
        opacity="0.2"
        d="M665 781L1011.66 971.902L1012.01 1081.95L665.173 891.222L665 781Z"
        fill="url(#paint4_linear)"
      />
      <path
        opacity="0.2"
        d="M751.709 884.484C751.709 901.069 741.863 909.189 729.773 902.451C717.682 895.714 707.664 876.883 707.664 860.125C707.664 843.539 717.509 835.42 729.6 842.157C741.863 848.895 751.709 867.899 751.709 884.484Z"
        fill="white"
      />
      <path
        d="M870.889 1151.23L870.544 1056.56L1105.62 1185.95L1105.97 1280.63L870.889 1151.23Z"
        fill="#7FCCBA"
        fillOpacity="0.56"
      />
      <path
        opacity="0.2"
        d="M589 196L1135.72 512.079L1136 999.287L589.275 683.208L589 196Z"
        fill="url(#paint5_linear)"
      />
      <path
        opacity="0.15"
        d="M589 196L862.638 646.096L1136 511.673L589 196Z"
        fill="url(#paint6_linear)"
      />
      <path
        d="M910.315 656.318C910.315 690.675 889.661 706.341 863.775 691.499C838.165 676.657 817.235 636.804 817.235 602.722C817.235 568.365 837.889 552.699 863.775 567.541C889.661 582.383 910.315 622.236 910.315 656.318Z"
        fill="#326984"
        fillOpacity="0.5"
      />
      <path
        opacity="0.1"
        d="M534.901 370.453C493.824 378.447 401.451 388.769 361.183 366C361.487 527.105 400.945 755.707 529.943 944.236C660.965 902.847 703.965 720.997 707 561.815C666.732 538.945 575.472 424.694 534.901 370.453Z"
        fill="url(#paint7_linear)"
      />
      <path
        opacity="0.3"
        d="M484.718 403.848C443.641 411.842 351.268 422.164 311 399.395C311.304 560.5 350.762 789.102 479.76 977.631C610.782 936.242 653.782 754.291 656.817 595.21C616.549 572.34 525.289 458.089 484.718 403.848Z"
        fill="url(#paint8_linear)"
      />
      <path
        opacity="0.4"
        d="M656.918 595.109C616.55 572.239 525.391 458.089 484.819 403.848L479.862 977.53C610.783 936.242 653.782 754.291 656.918 595.109Z"
        fill="url(#paint9_linear)"
      />
      <path
        opacity="0.3"
        d="M590.2 993.6L292.7 822L292.8 973.3L564.1 1129.8L590.4 1168.1L590.2 993.6Z"
        fill="url(#paint10_linear)"
      />
      <path d="M261 880.6V839.5L373.3 904.3V945.4L261 880.6Z" fill="#497695" />
      <path
        opacity="0.15"
        d="M513.7 986.3L448.5 948.7V962L513.7 999.6V986.3Z"
        fill="white"
      />
      <path
        opacity="0.15"
        d="M513.801 1012.9L388.101 940.4V953.7L513.801 1026.2V1012.9Z"
        fill="white"
      />
      <path
        opacity="0.15"
        d="M513.8 1039.5L361.5 951.7V965L513.8 1052.8V1039.5Z"
        fill="white"
      />
      <path
        d="M529.8 1009.4C529.8 1021.9 537.4 1036.3 546.7 1041.7C556 1047.1 563.6 1041.3 563.6 1028.9C563.6 1016.4 556 1002 546.7 996.6C537.3 991.2 529.8 997 529.8 1009.4Z"
        fill="#71A6CB"
        fillOpacity="0.6"
      />
      <path
        opacity="0.2"
        d="M628.78 1251.6L256.727 1041L255 1233.98L627.225 1444.57L628.78 1251.6Z"
        fill="url(#paint11_linear)"
      />
      <path
        opacity="0.2"
        d="M696.78 1245.6L324.727 1035L323 1227.98L695.225 1438.57L696.78 1245.6Z"
        fill="url(#paint12_linear)"
      />
      <path
        opacity="0.15"
        d="M351.554 1141.38L463.48 1204.78L463.308 1227.93L351.381 1164.53L351.554 1141.38Z"
        fill="white"
      />
      <path
        opacity="0.15"
        d="M351.207 1187.85L589.57 1322.78L589.397 1346.27L351.035 1211.34L351.207 1187.85Z"
        fill="white"
      />
      <path
        d="M331.69 1148.46C331.517 1164.35 322.017 1171.95 310.272 1165.22C298.526 1158.65 289.199 1140.34 289.372 1124.44C289.545 1108.55 299.045 1100.95 310.79 1107.69C322.535 1114.25 331.863 1132.56 331.69 1148.46Z"
        fill="#5AC8AE"
      />
      <path
        opacity="0.2"
        d="M1434.95 1068.6L1062.73 858L1061 1050.98L1433.23 1261.57L1434.95 1068.6Z"
        fill="url(#paint13_linear)"
      />
      <path
        opacity="0.15"
        d="M1157.55 958.374L1269.48 1021.78L1269.31 1044.93L1157.38 981.524L1157.55 958.374Z"
        fill="white"
      />
      <path
        opacity="0.15"
        d="M1157.21 1004.85L1395.57 1139.78L1395.4 1163.27L1157.04 1028.34L1157.21 1004.85Z"
        fill="white"
      />
      <path
        d="M1137.69 965.458C1137.52 981.352 1128.02 988.953 1116.27 982.216C1104.53 975.651 1095.2 957.338 1095.37 941.444C1095.55 925.55 1105.05 917.948 1116.79 924.686C1128.54 931.251 1137.86 949.564 1137.69 965.458Z"
        fill="#10517D"
        fillOpacity="0.7"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="52.5"
        y1="1004"
        x2="1143"
        y2="1369"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="688.686"
        y1="959.144"
        x2="758.735"
        y2="1194.63"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1="731.686"
        y1="922.144"
        x2="801.735"
        y2="1157.63"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint3_linear"
        x1="759"
        y1="1228"
        x2="746.02"
        y2="1351"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.109447" stopColor="white" stopOpacity="0.59" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint4_linear"
        x1="782.593"
        y1="780.683"
        x2="891.099"
        y2="1073.7"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint5_linear"
        x1="904.631"
        y1="253.052"
        x2="845.7"
        y2="734.462"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint6_linear"
        x1="856.254"
        y1="217.825"
        x2="879.706"
        y2="734.456"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint7_linear"
        x1="270.08"
        y1="422.486"
        x2="551.967"
        y2="674.721"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1E7A7B" />
        <stop offset="0.453125" stopColor="white" stopOpacity="0.51" />
      </linearGradient>
      <linearGradient
        id="paint8_linear"
        x1="219.906"
        y1="455.854"
        x2="501.793"
        y2="708.089"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#359D9E" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint9_linear"
        x1="671.534"
        y1="654.47"
        x2="445.444"
        y2="697.732"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint10_linear"
        x1="362.5"
        y1="894.5"
        x2="519"
        y2="1083"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#215273" />
        <stop offset="1" stopColor="white" stopOpacity="0.26" />
      </linearGradient>
      <linearGradient
        id="paint11_linear"
        x1="369.5"
        y1="1131.5"
        x2="516.961"
        y2="1348.03"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5AC8AE" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint12_linear"
        x1="437.5"
        y1="1125.5"
        x2="584.961"
        y2="1342.03"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#45AB93" stopOpacity="0.6" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint13_linear"
        x1="1289.5"
        y1="986.302"
        x2="1206.38"
        y2="1133.17"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

export default LetterIllus;
