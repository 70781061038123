import {
  FacilityAttribute,
  facilityValidations,
  facilityAttributeKeys,
} from "models/facility/attributes";
import { getSchema } from "utils/formUtils";
import { LayoutProps, Page, Section } from "models/common/Form";

export interface IPage extends Page {
  sections: ISection[];
  layout?: React.ComponentType<layoutProps>;
}
interface ISection extends Section {
  questions: typeof facilityAttributeKeys[number][];
}

export interface layoutProps extends LayoutProps {
  questions: FacilityAttribute[];
}

export const facilityPages: IPage[] = [
  {
    id: `details`,
    title: `Facility Details`,
    sections: [
      {
        questions: facilityAttributeKeys,
      },
      {
        title: `Facility Users`,
        questions: [],
      },
    ],
  },
];

export const facilityFormQuestionIDs = facilityAttributeKeys;
export const facilityDetailsQuestionIDs = facilityFormQuestionIDs.filter(
  (q) => !["facility_name", "facility_type"].includes(q)
);

export const facilityFormSchema = getSchema({
  ...facilityValidations,
});
