import React from "react";
import cx from "classnames";
import InputFeedback, {
  TError,
} from "components/common/ui/form-elements/common/InputFeedback";
import styles from "./ConsentCheckbox.module.scss";

interface ICheckbox {
  name: string;
  label: string;

  className?: string;
  error?: TError;
  disabled?: boolean;
  required?: boolean;
}

const ConsentCheckbox = React.forwardRef(
  (
    { name, label, disabled, required, className, error, ...rest }: ICheckbox,
    ref: React.Ref<any>
  ) => (
    <div className={className}>
      <label
        className={cx(
          styles.checkbox,
          disabled && styles.disabled,
          !!error && styles.error
        )}
      >
        <input
          id={`checkbox-input-${name}`}
          ref={ref}
          type="checkbox"
          name={name}
          disabled={disabled}
          required={required}
          className={cx(styles.input)}
          aria-describedby={error && `error-${name}-${error?.type}`}
          aria-invalid={!!error}
          tabIndex={disabled ? -1 : undefined}
          {...rest}
        />
        <span className={styles.label}>{label}</span>
        <span className={cx(styles.box, disabled && styles.disabled)} />
      </label>
      <InputFeedback error={error} visible={!!error} name={name} />
    </div>
  )
);

export default ConsentCheckbox;
