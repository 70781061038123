import React from "react";
import { useForm } from "react-hook-form";
import styles from "components/cpd/activity/edit/ActivityEditForm.module.scss";
import FormQuestion from "components/common/ui/form-elements/form-layout/FormQuestion";
import ConditionalLogicWrapperHookForm from "components/common/ui/form-elements/hook-form-elements/ConditionalLogicWrapperHookForm";
import { Activity, createActivity } from "models/cpd/Activity";
import {
  activityAttributes,
  activitySchema,
  adminOnlyCompleteConditional,
} from "components/cpd/utils/activityForm";
import { useMutateActivity } from "components/cpd/hooks/useMutateActivity";
import Spacer from "components/common/ui/spacer/Spacer";
import HintBox from "components/common/ui/hint-box/HintBox";
import { convertToDate } from "utils/format/dateUtils";
import { Control, UseFormRegister } from "react-hook-form/dist/types/form";
import IconWarning from "components/common/icons/IconWarning";
import { ActivityFormActions } from "components/cpd/activity/edit/ActivityFormActions";
import FormattedQuestion from "components/common/ui/form-elements/display-elements/FormattedQuestion";
import { ActivityEditAdminProps } from "components/cpd/activity/edit/activityEdit";

const EditAdminDetails = ({
  onCancel,
  activity,
  planId,
  userId,
  year,
  disabled,
}: ActivityEditAdminProps) => {
  const {
    handleSubmit,
    formState: { errors, isDirty },
    register,
    control,
  } = useForm({
    mode: "onSubmit",
    defaultValues: createActivity(activity) as Record<string, any>,
    resolver: activitySchema,
  });

  const { mutateAsync: mutateActivity, isLoading: isSubmitting } =
    useMutateActivity();

  const includeDates = {
    minDate: convertToDate(year.start),
    maxDate: convertToDate(year.end),
  };

  const handleSave = async (newActivity: Partial<Activity>) => {
    return mutateActivity({
      newActivity: createActivity({ plan_id: planId, ...newActivity }),
      userId,
      oldActivity: activity ? createActivity(activity) : undefined,
    });
  };

  const onSubmit = async (newActivity: Partial<Activity>) => {
    // handleSave if form values have been changed
    if (isDirty) {
      try {
        await handleSave(newActivity);
        onCancel();
      } catch (e) {}
    } else {
      onCancel();
    }
  };
  const onSave = async () => {
    return new Promise((resolve) => {
      handleSubmit(
        async (v: any) => {
          // handleSave if form values have been changed
          if (isDirty) {
            await handleSave(v);
          }
          resolve(true);
        },
        () => {
          resolve(false);
        }
      )();
    });
  };

  const sharedProps = {
    errors,
    register,
    control,
    disabled,
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate className={styles.root}>
      <HintBox theme={disabled ? "grey" : "danger"}>
        <b>Admin only</b>
      </HintBox>
      <ConditionalLogicWrapperHookForm
        control={control}
        conditional={adminOnlyCompleteConditional}
      >
        <>
          <h5>Results</h5>

          <ConditionalLogicWrapperHookForm
            control={control}
            conditional={activityAttributes.auditGrade.conditional}
          >
            <FormQuestion
              question={activityAttributes.auditGrade}
              {...sharedProps}
            />
          </ConditionalLogicWrapperHookForm>

          <ConditionalLogicWrapperHookForm
            control={control}
            conditional={activityAttributes.admin_grade_score.conditional}
          >
            <div className={styles.row}>
              <FormQuestion
                question={activityAttributes.admin_grade_score}
                {...sharedProps}
              />
              <FormQuestion
                question={activityAttributes.admin_grade_result}
                {...sharedProps}
              />
            </div>
          </ConditionalLogicWrapperHookForm>

          <ConditionalLogicWrapperHookForm
            control={control}
            conditional={activityAttributes.adminGradeScoreOutOf10.conditional}
          >
            <div className={styles.row}>
              <FormQuestion
                question={activityAttributes.adminGradeScoreOutOf10}
                {...sharedProps}
              />
              <FormQuestion
                question={activityAttributes.admin_grade}
                {...sharedProps}
              />
            </div>
          </ConditionalLogicWrapperHookForm>

          <ConditionalLogicWrapperHookForm
            control={control}
            conditional={activityAttributes.admin_letter_grade.conditional}
          >
            <div className={styles.row}>
              <FormQuestion
                question={activityAttributes.admin_letter_grade}
                {...sharedProps}
              />
              <FormQuestion
                question={activityAttributes.admin_grade}
                {...sharedProps}
              />
            </div>
          </ConditionalLogicWrapperHookForm>

          <ConditionalLogicWrapperHookForm
            control={control}
            conditional={activityAttributes.admin_grade.conditional}
          >
            <div className={styles.row}>
              <FormQuestion
                question={activityAttributes.admin_grade}
                {...sharedProps}
              />
            </div>
          </ConditionalLogicWrapperHookForm>

          <CompleteDateRow {...sharedProps} includeDates={includeDates} />
          <FormQuestion
            question={activityAttributes.adminComments}
            {...sharedProps}
          />
          <hr />
        </>
      </ConditionalLogicWrapperHookForm>

      <h6>
        <i>
          Locking activity prevents non-admin users from editing the activity &
          uploading documents
        </i>
      </h6>
      <FormQuestion
        question={activityAttributes.lockActivity}
        {...sharedProps}
      />

      <hr />

      <h6>
        <i>
          Required activity a special override that identifies activity 'in
          addition' to regular requirements, e.g. 'overdue' activity from a
          previous plan.
        </i>
      </h6>
      <FormQuestion question={activityAttributes.isRequired} {...sharedProps} />
      <ConditionalLogicWrapperHookForm
        control={control}
        conditional={activityAttributes.requiredBy.conditional}
      >
        <FormQuestion
          question={activityAttributes.requiredReason}
          {...sharedProps}
        />
        <FormQuestion
          question={activityAttributes.requiredBy}
          {...sharedProps}
        />
        <FormQuestion
          question={activityAttributes.requiredCredits}
          {...sharedProps}
        />
      </ConditionalLogicWrapperHookForm>
      <FormQuestion
        question={activityAttributes.lock_subcategory}
        {...sharedProps}
      />
      <hr />

      <h6>
        <i>
          By default all activity is included in the Annual & Programme period
          progress counts. The two options below allows Admins to exclude this
          activity from those counts. This is commonly for 'overdue' activity
          pulled from previous plans, which shouldn't towards this year's
          progress.
        </i>
      </h6>
      <FormQuestion
        question={activityAttributes.excludeTriennium}
        {...sharedProps}
      />
      <FormQuestion
        question={activityAttributes.excludeAnnual}
        {...sharedProps}
      />

      <hr />
      <h6>
        <i>
          Waived activity counts towards CPD progress, and is specially flagged
          to identify activity that wasn't actually completed by the user.
        </i>
      </h6>
      <FormQuestion question={activityAttributes.waiveReq} {...sharedProps} />
      <ConditionalLogicWrapperHookForm
        control={control}
        conditional={[
          {
            field: "waive_req",
            values: ["true"],
          },
        ]}
      >
        <FormQuestion question={activityAttributes.waiver} {...sharedProps} />
        <FormQuestion
          question={activityAttributes.waiverReason}
          {...sharedProps}
        />
      </ConditionalLogicWrapperHookForm>

      {disabled && activity?.waive_req && (
        <HintBox theme="info" icon={<IconWarning />} className={styles.waived}>
          <b>Requirements Waived</b>
          <FormattedQuestion
            className={styles.waiveInfo}
            def={{
              ...activityAttributes.waiver,
              label: activityAttributes.waiver.label || "",
            }}
            questions={activity}
            spacing="none"
          />
          <FormattedQuestion
            def={{
              ...activityAttributes.waiverReason,
              label: activityAttributes.waiverReason.label || "",
            }}
            questions={activity}
          />
        </HintBox>
      )}
      <Spacer />
      <ActivityFormActions
        isSubmitting={isSubmitting}
        isDirty={isDirty}
        onCancel={onCancel}
        onNext={onSave}
        onBack={onSave}
      />
    </form>
  );
};

interface RowProps {
  errors: Record<string, any>;
  register: UseFormRegister<any>;
  disabled?: boolean;
  control: Control;
  className?: string;
  defaultValue?: any;
  includeDates: { minDate: Date | null; maxDate: Date | null };
}

const CompleteDateRow = ({ includeDates, ...props }: RowProps) => (
  <div className={styles.row}>
    <FormQuestion
      question={activityAttributes.dateStarted}
      {...props}
      {...includeDates}
    />
    <FormQuestion
      question={activityAttributes.dateCompleted}
      {...props}
      {...includeDates}
    />
  </div>
);

export default EditAdminDetails;
