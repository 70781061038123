import React from "react";
import cx from "classnames";
import styles from "./FormattedQuestion.module.scss";
import FormattedAddress from "components/common/ui/form-elements/display-elements/FormattedAdress";
import { parseISO } from "date-fns";
import { formatDate } from "utils/format/dateUtils";
import Chip from "components/common/ui/chip/Chip";
import IconCheck from "components/common/icons/IconCheck";
import IconCancel from "components/common/icons/IconCancel";
import { checkConditionals } from "utils/formUtils";
import { DataAttribute } from "components/common/ui/form-elements/formElements";
import { formatPhoneNumber } from "components/common/ui/form-elements/display-elements/FormattedPhoneNumber";
import { deepObjectAccessor } from "utils/common";
import Table from "components/common/ui/table/Table";
import { SkeletonText } from "components/common/ui/skeleton/Skeleton";
import DisplayRichText from "components/common/ui/form-elements/rich-text-editor/DisplayRichText";

export interface IQuestion<F extends string = string> extends DataAttribute<F> {
  label: string;
}

interface Props<T extends string> {
  def: IQuestion<T>;
  questions: TObjectAny;
  className?: string;
  spacing?: "normal" | "none";
  loading?: boolean;
}

const FormattedQuestion = <T extends string>({
  def,
  questions,
  className,
  spacing = "normal",
  loading,
}: Props<T>) => {
  const question = deepObjectAccessor(questions, def.name) || "";
  const label = def.altLabel || def.label;
  const qType = getType(def);
  return checkConditionals(questions, def.conditional) ? (
    <div
      className={cx(styles.row, className, styles[`spacing-${spacing}`])}
      key={def.name}
    >
      <div className={styles.label}>{label}:</div>
      <div
        className={cx(
          styles.value,
          [
            "address",
            "textArea",
            "richText",
            "fieldArray",
            "object[]",
            "fieldArrayRows",
          ].includes(qType) && styles.fullWidth
        )}
      >
        {loading ? (
          <SkeletonText />
        ) : (
          <FormattedValue question={question} def={def} />
        )}
      </div>
    </div>
  ) : (
    <></>
  );
};

interface IValueProps {
  def: IQuestion;
  question: any;
}
const FormattedValue = ({ def, question }: IValueProps) => {
  const qType = getType(def);
  if (qType === "consentCheckbox") {
    return !!question ? (
      <IconCheck className={styles.success} />
    ) : (
      <IconCancel className={styles.danger} />
    );
  }
  if (question) {
    const optionsMap: Record<string, string> = def.options
      ? def.options.reduce((a, b) => {
          return { ...a, [b.value]: b.label };
        }, {})
      : {};
    switch (qType) {
      case "address":
        return (
          <FormattedAddress address={question} className={styles.address} />
        );
      case "email":
        return <a href={`mailto:${question}`}>{question}</a>;

      case "phone":
      case "tel":
        return <a href={`tel:${question}`}>{formatPhoneNumber(question)}</a>;

      case "enum":
      case "radio":
        const q = def.options && def.options.find((d) => d.value === question);
        const value = q?.label;
        return (
          <Chip theme={q?.theme}>
            {q?.color && (
              <span
                style={{
                  background: q.color,
                  width: 10,
                  height: 10,
                  borderRadius: 16,
                  marginRight: 8,
                }}
              />
            )}
            {value || question}
          </Chip>
        );

      case "enum[]":
      case "checkboxGroup":
        const checkQ =
          typeof question === "string" ? question.split(",") : question;
        return (
          <span className={styles.chipContainer}>
            {checkQ.map(
              (d: string) =>
                optionsMap[d] && (
                  <Chip key={d} className={styles.chip}>
                    {optionsMap[d] || d}
                  </Chip>
                )
            )}
          </span>
        );
      case "date":
      case "splitDate":
        try {
          return formatDate(parseISO(question));
        } catch (e) {
          return <NoData />;
        }
      case "datetime":
        try {
          return formatDate(question, "dd/MM/yyyy p");
        } catch (e) {
          return <NoData />;
        }
      case "richText":
        return <DisplayRichText text={question} />;
      // return (
      //   <RichTextEditor
      //     question={question}
      //     isDisable={true}
      //     label={def.label ?? ""}
      //     name={def.name ?? ""}
      //   />
      // );
      case "object[]":
      case "fieldArray":
        return (
          Array.isArray(question) && (
            <Table
              className={styles.fieldArrayTable}
              data={question}
              columns={
                def.options?.map(({ value, label, ...o }) => ({
                  id: value,
                  label: label,
                  // crazy recursive table...
                  cell: (c) => (
                    <FormattedValue
                      def={{
                        ...o,
                        name: value,
                        label: label || "",
                        type: o.type || "string",
                      }}
                      question={c}
                    />
                  ),
                })) || []
                // def.options?.map((o) => ({ id: o.value, label: o.label })) || []
              }
              rowKeyAccessor={(row, index) => `${index}`}
              noContainerPadding
            />
          )
        );
      case "fieldArrayRows":
        return (
          Array.isArray(question) && (
            <div className={styles.fieldRow}>
              {question.map((q, i) => (
                <div key={i}>
                  {def.options?.map(({ value, label, ...o }) => (
                    <FormattedValue
                      key={`${i}::${value}`}
                      def={{
                        ...o,
                        name: value,
                        label: label || "",
                        type: o.type || "string",
                      }}
                      question={q[value]}
                    />
                  ))}
                </div>
              ))}
            </div>
          )
        );
      case "heading":
        return <h5 className={styles.heading}>{question}</h5>;
      default:
        return question;
    }
  }

  return <NoData />;
};

const NoData = () => <i className={styles.noValue}>&nbsp;&nbsp;- </i>;

const getType = (def: IQuestion) =>
  def.layout?.display || def.layout?.type || def.type;

export default FormattedQuestion;
