import React from "react";
import { useForm } from "react-hook-form";
import TextInput from "components/common/ui/form-elements/text-input/TextField";
import HeroButton from "components/common/ui/hero-button/HeroButton";
import { getSchema } from "utils/formUtils";
import {
  verificationCode,
  contact,
} from "components/common/auth/forms/questionSchema";
import styles from "./Forms.module.scss";
import RadioGroup from "components/common/ui/form-elements/radio/RadioGroup";

const cleanError = (e: string) => {
  switch (e) {
    case "Incorrect username or password.":
      return "Incorrect username or password.";

    case "User does not exist.":
      return "User does not exist.";

    default:
      return e;
  }
};

const submitSchema = getSchema({ code: verificationCode });

interface Props {
  inputs: any;
  onSubmit: () => void;
  errorMessage: string | null;
  changeState: (state: string, authData?: any) => void;
  authData: any;
}

export const VerifySubmitForm = ({
  authData,
  inputs,
  onSubmit,
  errorMessage,
  changeState,
}: Props) => {
  let defaultValues = {
    code: inputs.code || "",
  };
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm({
    mode: "onBlur",
    resolver: submitSchema,
    defaultValues: defaultValues,
  });
  const submit = (data: any) => {
    inputs.code = data.code.trim();
    onSubmit();
  };
  return (
    <form
      onSubmit={handleSubmit(submit)}
      aria-label="verify-contact"
      noValidate
    >
      {errorMessage && (
        <div className={styles.error}>Error: {cleanError(errorMessage)}</div>
      )}
      <TextInput
        {...register("code")}
        label="Verification Code"
        error={errors["code"]}
      />
      <div className={styles.formActions}>
        <HeroButton type="submit">Submit Verification Code</HeroButton>
        <button
          onClick={() => changeState("signedIn", authData)}
          className="link"
        >
          Skip verification for now
        </button>
      </div>
    </form>
  );
};

const verifySchema = getSchema({ contact });

export const VerifyViewForm = ({
  authData,
  inputs,
  onSubmit,
  errorMessage,
  changeState,
}: Props) => {
  const { email, phone_number } = authData.unverified;
  const selectionRequired = email && phone_number;
  const unverifiedAttribute = !selectionRequired
    ? email
      ? "email"
      : "phone_number"
    : undefined;

  let defaultValues = {
    contact: inputs.contact || "",
  };
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm({
    mode: "onBlur",
    resolver: selectionRequired ? verifySchema : undefined,
    defaultValues: defaultValues,
  });
  const submit = (data: any) => {
    inputs.contact = selectionRequired ? data.contact : unverifiedAttribute;
    onSubmit();
  };
  return (
    <form
      onSubmit={handleSubmit(submit)}
      aria-label="verify-contact"
      noValidate
    >
      {errorMessage && (
        <div className={styles.error}>Error: {cleanError(errorMessage)}</div>
      )}
      {selectionRequired ? (
        <RadioGroup
          name="contact"
          label="Select verification options"
          options={[
            { value: "email", label: "Email" },
            { value: "phone_number", label: "Phone" },
          ]}
          register={register}
          error={errors["contact"]}
        />
      ) : (
        <div className={styles.verifyContainer}>
          Your {unverifiedAttribute === "email" ? "email" : "phone number"} -{" "}
          <b>{email || phone_number}</b> - requires validation.
          <br />
          Please click on the button below to be sent a verification code.
        </div>
      )}

      <div className={styles.formActions}>
        <HeroButton type="submit">Send Verification Code</HeroButton>
        <button
          onClick={() => changeState("signedIn", authData)}
          className="link"
        >
          Skip verification for now
        </button>
      </div>
    </form>
  );
};
