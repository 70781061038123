import React from "react";
import createIcon from "components/common/icons/common/createIcon";

const IconChartGantt = createIcon(
  <React.Fragment>
    <path d="M2,5H10V2H12V22H10V18H6V15H10V13H4V10H10V8H2V5M14,5H17V8H14V5M14,10H19V13H14V10M14,15H22V18H14V15Z" />
  </React.Fragment>,
  "ChartGantt"
);

export default IconChartGantt;
