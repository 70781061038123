import { Plan } from "models/cpd/Plan";
import { Goal } from "models/cpd/Goal";
import { Category, PATHWAY } from "models/cpd/common/common";
import { getPathwayCategoryOptions } from "components/cpd/utils/utils";

export const getInitialPlanYearTabState = (plan?: Plan) => {
  if (!plan) {
    return 0;
  }
  const unlockedYear = plan.progress?.years.findIndex((y) => !y.locked);
  if (unlockedYear !== undefined && unlockedYear >= 0) {
    return unlockedYear;
  }
  const lastYear = (plan.progress?.years.length || 1) - 1;
  return lastYear >= 0 ? lastYear : 0;
};

interface LayoutState {
  display: "list" | "gantt";
  group: "goal_id" | "category";
}
export const groupOptions = [
  {
    key: "goal_id",
    label: "Goal",
  },
  {
    key: "category",
    label: "Activity Type",
  },
];
export const layoutReducer = (
  state: LayoutState,
  action:
    | { type: "display"; value: "list" | "gantt" }
    | { type: "group"; value: "goal_id" | "category" }
) => {
  switch (action.type) {
    case "display":
      return { ...state, display: action.value };
    case "group":
      return { ...state, group: action.value };
  }
};

interface GroupBy {
  id: string | Category;
  label?: string;
  keys: string[];
  goal?: Goal;
}

export const mergeGoalsKeys = (
  activityGoals: Record<string, string[]>,
  goals: Goal[]
) => {
  const merge = goals
    .map((g) => {
      const id = g.goal_id ?? g.id;
      const aKeys = activityGoals[id];
      if (aKeys || g.goal_status !== "complete") {
        return {
          id,
          label: g.goal_name,
          keys: aKeys || [],
          goal: g,
        };
      }
      return undefined;
    })
    .filter((g) => !!g) as GroupBy[];
  if (activityGoals["__undefined__"]) {
    merge.push({
      id: "__undefined__",
      // label: "Unassigned",
      keys: activityGoals["__undefined__"] || [],
    });
  }
  return merge;
};

export const mergeCategory = (
  activityCategories: Record<string, string[]>,
  pathway: PATHWAY,
  requiredCategories?: Category[]
) => {
  const categories = getPathwayCategoryOptions(pathway, requiredCategories).map(
    ({ value, label }) => ({
      id: value,
      label,
      keys: activityCategories[value] || [],
    })
  );
  if (activityCategories["__undefined__"]) {
    categories.push({
      //@ts-ignore
      id: "__undefined__",
      keys: activityCategories["__undefined__"] || [],
    });
  }
  return categories as GroupBy[];
};
