import { request } from "utils/http/Request";
import {
  CURRENT_USER,
  getApiUrl,
  USER,
  USERS,
  USERS_SEARCH,
} from "constants/paths";
import { map, toServerObject, TUser, UserSearch } from "models/user/User";
import { IDimensions } from "models/Dimension";
import { applyDimensionFilters } from "utils/dimensions/filterUtils";
import { diffFacilities } from "components/users/utils/userUtils";
import { Facility } from "models/facility/Facility";
import {
  addUserToMultipleFacilities,
  removeUserFromMultipleFacilities,
} from "api/FacilityApi";
import { fuzzyMatch } from "utils/dimensions/matchUtils";
import { QueryFilters } from "queries/queries";
import { userTypeKey } from "models/user/attributes/common";

export const fetchCurrentUser = async (): Promise<TUser> => {
  const data = await request.get(getApiUrl(CURRENT_USER, {}));
  data.Permissions = data.permissions;
  return map(data as any);
};

export const updateCurrentUser = async (
  { id = "", facilities, ...user }: Partial<TUser>,
  oldUser: TUser
): Promise<TUser> => {
  await request.put(getApiUrl(CURRENT_USER, {}), toServerObject(user));
  await updateFacilityAssociations(id, facilities, oldUser);
  return { ...oldUser, ...user, id };
};

export const fetchMany = async ({ user_status, ...filter }: IDimensions) => {
  if (user_status) {
    filter["cognito:user_status"] = user_status;
  }
  // currently applying filters on client-side rather than API
  // TODO: change to api-side filtering if API gets updated with filtering
  const data = await request.get(getApiUrl(USERS, {}), {});
  const rawUsers = (data.Users as any[]) ? data.Users.map(map) : [];
  const items = applyDimensionFilters(rawUsers, filter);
  const total = data.total || items.length;
  return {
    items: items.sort((a, b) => a.given_name.localeCompare(b.given_name)),
    total,
  };
};

export const fetchOneById = async (userId: string): Promise<TUser> => {
  const data = await request.get(getApiUrl(USER, { userId }));
  return map(data as any);
};

export const save = async (
  { id, facilities, ...user }: Partial<TUser>,
  oldUser: TUser
): Promise<TUser> => {
  if (id) {
    await request.put(getApiUrl(USER, { userId: id }), toServerObject(user));
    await updateFacilityAssociations(id, facilities, oldUser);
    //TODO: add update supervisors here
    return { ...oldUser, ...user, id };
  }
  const newUser = await request.post(
    getApiUrl(USERS, {}),
    toServerObject(user)
  );
  await updateFacilityAssociations(newUser.id, facilities, oldUser);
  return { ...map(newUser as any), ...user };
};

const updateFacilityAssociations = async (
  userId: string,
  facilities: Facility[] = [],
  oldUser: TUser
) => {
  // const newFacilities = facilities?.map((f) =>
  //   f.facility_name ? f : f.metadata
  // );
  const diff = diffFacilities(facilities, oldUser?.facilities);
  if (diff.toAdd.length > 0) {
    await addUserToMultipleFacilities(userId, diff.toAdd);
  }
  if (diff.toRemove.length > 0) {
    await removeUserFromMultipleFacilities(userId, diff.toRemove);
  }
  return diff;
};

export const resendInvite = async (user: Partial<TUser>): Promise<boolean> => {
  await request.post(getApiUrl(USERS, {}), {
    email: user.email,
    resend: true,
  });
  return true;
};
export const resetPassword = async (user: TUser): Promise<TUser> => {
  await request.put(getApiUrl(USER, { userId: user.id }), {
    // username: user.email,
    reset_password: true,
  });
  return { ...user, status: "RESET_REQUIRED" };
};

export const enableUser = async (
  user: TUser,
  active: boolean
): Promise<TUser> => {
  await request.put(getApiUrl(USER, { userId: user.id }), {
    // username: user.email,
    enabled: active,
  });
  return { ...user, active };
};

export const fetchUserSearch = async (
  filter: QueryFilters,
  isAdmin?: boolean
) => {
  // currently applying filters on client-side rather than API
  // TODO: change to api-side filtering if API gets updated with filtering
  let data, rawUsers: UserSearch[];
  if (isAdmin) {
    const raw = await request.get(getApiUrl(USERS, {}), {});
    data = (raw.Users as any[]) ? raw.Users.map(map) : [];
    rawUsers = data.map(
      (u: TUser) =>
        ({
          value: u.id,
          label: `${u.given_name ?? ""} ${u.family_name ?? ""} (${
            u.email ?? " "
          })`,
          searchValue: [u.given_name, u.family_name, u.email]
            .join("::")
            .toLowerCase(),
          metadata: {
            type: u[userTypeKey],
            approvedToSupervise: u.approved_to_supervise === "yes",
          },
        } as UserSearch)
    );
  } else {
    data = await request.get(getApiUrl(USERS_SEARCH, {}), {});
    rawUsers = (data.Users as any[])
      ? data.Users.map(
          (u: {
            user_id: string;
            user_given_name?: string;
            user_family_name?: string;
            user_email?: string;
          }) =>
            ({
              value: u.user_id,
              label: `${u.user_given_name ?? ""} ${u.user_family_name ?? ""} (${
                u.user_email ?? " "
              })`,
              searchValue: [u.user_given_name, u.user_family_name, u.user_email]
                .join("::")
                .toLowerCase(),
              metadata: u,
            } as UserSearch)
        )
      : [];
  }

  console.log(filter);

  return rawUsers
    .filter(
      (u) =>
        (filter?.search
          ? fuzzyMatch(filter?.search, u.searchValue || u.value)
          : true) &&
        (filter?.type ? filter?.type.includes(u.metadata.type) : true)
    )
    .sort((a, b) =>
      (a.searchValue || a.value).localeCompare(b.searchValue || b.value)
    ) as UserSearch[];
};
