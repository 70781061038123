import { ADMIN_ROOT, ROOT, ROUTE_PARAMS } from "constants/routes/common";
import { PermissionGroups } from "models/user/Permissions";

const MY_PROFILE = { path: `${ROOT}/my-profile` };
const EDIT_MY_PROFILE = { path: `${MY_PROFILE.path}/edit` };

const ADMIN_USERS = {
  path: `${ADMIN_ROOT}/users`,
  requiredRole: PermissionGroups.adminRead,
};
const ADMIN_CREATE_USER = {
  path: `${ADMIN_USERS.path}/create`,
  requiredRole: PermissionGroups.adminEdit,
};
const ADMIN_USER_DETAIL = {
  path: `${ADMIN_USERS.path}/detail/:${ROUTE_PARAMS.USER}`,
  requiredRole: ADMIN_USERS.requiredRole,
};
const ADMIN_EDIT_USER = {
  path: `${ADMIN_USER_DETAIL.path}/edit`,
  requiredRole: ADMIN_CREATE_USER.requiredRole,
};

export const USER_ROUTES = {
  MY_PROFILE,
  EDIT_MY_PROFILE,

  ADMIN_USERS,
  ADMIN_CREATE_USER,
  ADMIN_USER_DETAIL,
  ADMIN_EDIT_USER,
};
