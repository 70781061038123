import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import { peerGroupQueryKeys } from "queries/constants";
import { MutationOptions } from "queries/queries";
import { useToast } from "components/common/ui/toast/useToast";
import { updateMemberPeerGroup } from "api/PeerGroupApi";
import { PeerGroupMember } from "models/peergroup/member";

export const useMutateUpdateMemberInPeerGroup = (
  options: MutationOptions = {}
) => {
  const queryClient = useQueryClient();
  const { addErrorToast, addToast } = useToast();

  return useMutation(
    async ({
      currentMember,
      peergroup_role_id,
      ...inputs
    }: useMutateAddMemberToPeerGroupProps) => {
      const peergroupRoleId =
        peergroup_role_id ?? currentMember?.peergroup_member_roles?.[0]?.value;
      // peergroup_role_id ?? "";

      const current_peergroup_role_id =
        currentMember?.peergroup_member_roles?.find(
          (r) => r.value !== peergroupRoleId
        )?.value || currentMember?.peergroup_member_roles?.[0]?.value;

      return await updateMemberPeerGroup({
        ...inputs,
        peergroup_role_id: peergroupRoleId ?? "",
        current_peergroup_role_id,
      });
    },

    {
      onSuccess: (result, inputs) => {
        addToast(`Peer group member successfully updated`, {
          theme: "success",
        });

        queryClient.invalidateQueries(
          peerGroupQueryKeys.members(inputs.peergroupId)
        );
      },
      onError: (e) => {
        if (axios.isAxiosError(e)) {
          addErrorToast(e, `Error to update peer group member`);
        } else {
        }
      },
      ...options,
    }
  );
};

interface useMutateAddMemberToPeerGroupProps {
  user_id: string;
  peergroupId: string;
  peergroup_role_id?: string;
  currentMember?: PeerGroupMember;
  deleted?: boolean;
}
