import React from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { CPD_ROUTES } from "constants/routes/cpd";
import { ROUTE_PARAMS } from "constants/routes/common";
import Meta from "components/common/Meta";
import EditPlan from "components/cpd/plan/EditPlan";
import AppContentContainer from "components/common/layouts/appLayout/AppContentContainer";
import { coreService } from "services/CoreService";
import { useSelector } from "react-redux";
import {
  currentUserSelector,
  isAppLoadedSelector,
} from "store/selectors/core.selectors";
import { useUserDetail } from "components/users/hooks/useUserDetail";
import MiniLoader from "components/common/ui/loader/MiniLoader";
import { checkTPS } from "components/cpd/utils/utils";

const ViewCreatePlan = () => {
  const loc = useLocation();
  const { [ROUTE_PARAMS.USER]: paramsUserId } =
    useParams<{ [ROUTE_PARAMS.USER]?: string }>();
  const history = useHistory();
  const isAppLoaded = useSelector(isAppLoadedSelector);
  const currentUser = useSelector(currentUserSelector);
  const { isLoading: isUserLoading, data: user } = useUserDetail(
    paramsUserId || currentUser.id,
    { enabled: isAppLoaded }
  );
  const { isTPSLocation } = checkTPS(loc);

  const onSuccess = async (planId: string, userId: string) => {
    history.push(
      isTPSLocation
        ? coreService.getRoute(CPD_ROUTES.ADMIN_PLAN_DETAIL_TPS, {
            [ROUTE_PARAMS.PLAN]: planId,
            [ROUTE_PARAMS.USER]: userId,
          })
        : coreService.getRoute(CPD_ROUTES.ADMIN_PLAN_DETAIL, {
            [ROUTE_PARAMS.PLAN]: planId,
            [ROUTE_PARAMS.USER]: userId,
          })
    );
  };

  return (
    <AppContentContainer
      backButton={
        isTPSLocation
          ? {
              to: coreService.getRoute(
                paramsUserId
                  ? CPD_ROUTES.ADMIN_USER_TPS
                  : CPD_ROUTES.MY_TPS_ALL,
                { [ROUTE_PARAMS.USER]: currentUser.id }
              ),
              label: "Back to TPS List",
            }
          : {
              to: coreService.getRoute(
                paramsUserId
                  ? CPD_ROUTES.ADMIN_USER_PLANS
                  : CPD_ROUTES.MY_PLANS_ALL,
                { [ROUTE_PARAMS.USER]: currentUser.id }
              ),
              label: "Back to Plans",
            }
      }
      spacing="extra"
      maxWidth={true}
    >
      <Meta title={`Create new CPD Plan`} />
      {isUserLoading || !user ? (
        <MiniLoader theme="secondary" />
      ) : (
        <EditPlan user={user} onSuccess={onSuccess} />
      )}
    </AppContentContainer>
  );
};

export default ViewCreatePlan;
