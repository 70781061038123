import React from "react";
import { useFacilityList } from "components/facilities/hooks/useFacilityList";
import { useFieldArray } from "react-hook-form";
import { Control } from "react-hook-form/dist/types/form";
import cx from "classnames";
import styles from "components/common/ui/form-elements/field-array/FieldArray.module.scss";
import { Facility } from "models/facility/Facility";
import { IMenuItem } from "components/common/ui/form-elements/autocomplete/MenuItem";
import { UseMultipleSelectionStateChange } from "downshift";
import MultiSelectCombobox from "components/common/ui/form-elements/autocomplete/MulitSelectCombobox";

interface Props extends DefaultProps {
  question: any;
  defaultValue: any[];
  control: Control;
  errors: Record<string, any>;
  disabled?: boolean;
}
interface TField extends Omit<Facility, "users"> {
  id: string;
}
interface FacilityMenuItem extends IMenuItem {
  metadata?: TField;
}

const FacilityQuestion = ({
  question,
  defaultValue,
  control,
  className,
  errors,
  children,
  ...props
}: Props) => {
  const {
    fields,
    replace,
  }: { fields: TField[]; replace: (f: (undefined | TField)[]) => void } =
    useFieldArray({
      control: control,
      name: question.name, // unique name for your Field Array
    });
  const { data: facilities, isLoading } = useFacilityList({});

  const onSelect = (
    changes: UseMultipleSelectionStateChange<FacilityMenuItem>
  ) => {
    replace(changes.selectedItems?.map((f) => f.metadata) || []);
  };

  return (
    <div className={cx(styles.root, className)}>
      {!isLoading && (
        <MultiSelectCombobox
          items={formatFacilityToMenuOption(facilities)}
          selected={formatFacilityToMenuOption(fields)}
          onSelect={onSelect}
          name={"facility"}
          label={question.label || "Search for a facility"}
          description={question.description}
          {...props}
        />
      )}
    </div>
  );
};

const formatFacilityToMenuOption = (facilities: Facility[] = []) =>
  facilities?.map((f) => ({
    value: f.facility_name || "",
    label: f.facility_name || "",
    metadata: f,
  }));

export default FacilityQuestion;
