import React from "react";
import cx from "classnames";
import styles from "./Button.module.scss";

import Button, { TButton } from "components/common/ui/button/Button";

export interface IIconButton extends TButton {
  icon: React.ReactNode;
  iconClass?: string;
  iconVariant?: FillVariants;
}

const IconButton = ({
  icon,
  iconClass,
  iconVariant = "filled",
  className,
  children,
  ...rest
}: IIconButton) => {
  const variant = `icon-${iconVariant}`;
  return (
    <Button rounded {...rest} className={cx(styles.iconButton, className)}>
      <div className={styles.content}>
        <div className={cx(styles.icon, styles[variant], iconClass)}>
          {icon}
        </div>

        {children}
      </div>
    </Button>
  );
};

export default IconButton;
