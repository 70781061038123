/* tslint:disable:no-console */
import { captureError } from "utils/errorUtils";

/**
 * Wrapper for console.log
 * Can be extended with special logging libs or services like Sentry
 * Prefer to use window.logger.error() instead of console.error()
 */

const errorLogger = (error: Error, errorInfo: any) => {
  captureError(error);
  console.error(...timeStamp(), error, errorInfo);
};

const timeStamp = () => ["%c" + new Date().toISOString(), "color:#949494"];

// only log info during development
const infoLogger =
  process.env.NODE_ENV === "development"
    ? // ? (...args: any[]) => console.log("%c"+new Date().toISOString(),"color:#949494", ...args)
      (...args: any[]) => console.log(...timeStamp(), ...args)
    : () => {};

const groupLogger =
  process.env.NODE_ENV === "development"
    ? (...args: any[]) => console.group(...args)
    : () => {};

const groupEnd =
  process.env.NODE_ENV === "development" ? () => console.groupEnd() : () => {};

const logger = {
  debug: (...args: any[]) => console.debug(...timeStamp(), ...args),
  error: errorLogger,
  info: infoLogger,
  warn: (...args: any[]) => console.warn(...timeStamp(), ...args),
  group: groupLogger,
  groupEnd: groupEnd,
};

window.logger = logger;
export { logger };
