import React from "react";
import cx from "classnames";
import styles from "./RadioGroup.module.scss";
import InputFeedback, {
  TError,
} from "components/common/ui/form-elements/common/InputFeedback";
import Radio from "components/common/ui/form-elements/radio/Radio";
import { UseFormRegister } from "react-hook-form/dist/types/form";

interface IRadioGroupProps {
  name: string;
  label: string | React.ReactNode;
  error?: TError;
  className?: string;
  options: { label?: string; value: string }[];
  register: UseFormRegister<any>;
  required?: boolean;
  disabled?: boolean;
  defaultValue?: string;
}

const RadioGroup = ({
  name,
  label,
  error,
  className,
  options,
  register,
  required,
  disabled,
  defaultValue,
}: IRadioGroupProps) => (
  <fieldset
    className={cx(
      styles.group,
      {
        [styles.error]: error,
        [styles.disabled]: disabled,
      },
      className
    )}
    aria-labelledby={`radio-group-legend-${name}`}
    aria-describedby={error && `error-${name}-${error?.type}`}
    aria-invalid={!!error}
    disabled={required}
  >
    <legend className={styles.legend} id={`radio-group-legend-${name}`}>
      {label}
    </legend>
    {options.map((r) => (
      <Radio
        key={r.value}
        label={r.label || r.value}
        disabled={disabled}
        defaultValue={defaultValue}
        {...register(name)}
        value={r.value}
      />
    ))}
    <InputFeedback error={error} visible={!!error} name={name} />
  </fieldset>
);

export default RadioGroup;
