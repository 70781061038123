import React from "react";
import ErrorPage from "components/common/ui/error/ErrorPage";
import { ErrorBoundary as SentryErrorBoundary } from "@sentry/react";
import { FallbackRender } from "@sentry/react/dist/errorboundary";

type TFallback = React.ReactElement | FallbackRender;

export interface Props {
  fallback?: TFallback;
  children: React.ReactNode;
}

const ErrorBoundary = ({ fallback = ErrorPage, children }: Props) => {
  return (
    <SentryErrorBoundary fallback={fallback}>{children}</SentryErrorBoundary>
  );
};

export const withComponentErrorBoundary =
  (
    ComponentThatMayError: React.ComponentType<any>,
    FallbackComponent: TFallback = ErrorPage
  ) =>
  (props: any) =>
    (
      <ErrorBoundary fallback={FallbackComponent}>
        <ComponentThatMayError {...props} />
      </ErrorBoundary>
    );

export const withErrorBoundary =
  (
    ComponentThatMayError: React.ComponentType<any>,
    FallbackComponent: TFallback
  ) =>
  (props: any) =>
    (
      <ErrorBoundary fallback={FallbackComponent}>
        <ComponentThatMayError {...props} />
      </ErrorBoundary>
    );

export { ErrorBoundary };
