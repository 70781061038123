import React from "react";
import Accordian from "components/common/ui/accordian/Accordian";
import Container from "components/common/layouts/Container";
import Tooltip from "components/common/ui/tooltip/Tooltip";
import ActionButton from "components/common/ui/button/ActionButton";
import { IconProgressStar } from "components/common/icons/IconProgress";
import IconAdd from "components/common/icons/IconAdd";
import ActivityItem from "components/cpd/activity/ActivityItem";
// import Chip from "components/common/ui/chip/Chip";
import { Activity } from "models/cpd/Activity";
import { Category } from "models/cpd/common/common";
import { Goal } from "models/cpd/Goal";
import styles from "components/cpd/plan/PlanDetail.module.scss";
import { ActivityEditState } from "components/cpd/activity/ActivityDetail";

interface Props {
  group: Group[];
  groupKey: keyof Activity;
  activity: Record<string, Activity>;
  setActivityEdit: (S: ActivityEditState | undefined) => void;
  setGoalDetail: (G: Goal | undefined) => void;
  defaultActivityFields?: Partial<Activity>;
  locked: boolean;
}

interface Group {
  id: string | Category;
  label?: string;
  keys: string[];
  goal?: Goal;
}

const ActivityList = ({
  group,
  groupKey,
  activity,
  setGoalDetail,
  setActivityEdit,
  defaultActivityFields = {},
  locked,
}: Props) => (
  <div>
    {group.map(({ id, label, keys, goal }) => {
      const items = keys.map((k) => activity[k]);
      // const completed = items.filter((i) => i.completed);
      return (
        <Accordian
          key={id}
          isOpen={false}
          bottomBorder
          // headerClassName={styles.groupHeader}
          header={
            <Container className={styles.groupHeader}>
              <h3>{label || <i>Unassigned</i>}</h3>
              <div className={styles.row}>
                {goal && (
                  <Tooltip content={"View Goal"}>
                    <ActionButton
                      icon={<IconProgressStar />}
                      rounded
                      theme={"secondary"}
                      variant="outline"
                      onClick={(e) => {
                        e.stopPropagation();
                        setGoalDetail(goal);
                      }}
                    />
                  </Tooltip>
                )}

                {!locked && (
                  <Tooltip content={"Add Activity"}>
                    <ActionButton
                      icon={<IconAdd />}
                      rounded
                      theme={"primary"}
                      disabled={locked}
                      onClick={(e) => {
                        e.stopPropagation();

                        setActivityEdit({
                          activity: {
                            ...defaultActivityFields,
                            [groupKey]: id,
                          },
                          editing: true,
                        });
                      }}
                    />
                  </Tooltip>
                )}

                {/*<CompletedCounter*/}
                {/*  completed={completed.length}*/}
                {/*  total={items.length}*/}
                {/*/>*/}
              </div>
            </Container>
          }
        >
          <Container>
            {items.length > 0 ? (
              <ul className={styles.activityList}>
                {items.map((a: Activity) => (
                  <ActivityItem
                    key={a.id}
                    activity={a}
                    editActivity={() =>
                      setActivityEdit({
                        activity: a,
                        editing: false,
                      })
                    }
                    locked={locked}
                    // deleteActivity={() => setEditting(a)}
                    goal={goal}
                  />
                ))}
              </ul>
            ) : (
              <i>
                This {groupKey === "goal_id" ? "goal" : "activity type"} is
                empty
                {!locked && (
                  <button
                    className="link"
                    onClick={() =>
                      setActivityEdit({
                        activity: {
                          ...defaultActivityFields,
                          [groupKey]: id,
                        },
                        editing: true,
                      })
                    }
                  >
                    click here to add activity
                  </button>
                )}
              </i>
            )}
          </Container>
        </Accordian>
      );
    })}
  </div>
);

// const CompletedCounter = ({
//   completed,
//   total,
// }: {
//   completed: number;
//   total: number;
// }) => (
//   <Chip theme="primary" className={styles.counter}>
//     <div className={styles.label}>Completed:</div>
//     <div className={styles.count}>
//       {completed}/{total}
//     </div>
//   </Chip>
// );

export default ActivityList;
