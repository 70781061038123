import Chip from "components/common/ui/chip/Chip";
import { facilityTypeOptions } from "models/facility/attributes";
import { keyBy } from "utils/common";
import { TFacilityType } from "models/facility/Facility";

const typeMap = keyBy(facilityTypeOptions, "value", (t) => t.label);

interface Props extends DefaultProps {
  facilityType: TFacilityType;
}

export const FacilityType = ({ facilityType }: Props) => {
  const t = facilityType ? typeMap[facilityType] : undefined;

  return t ? (
    <Chip variant="lightFilled" theme="primary">
      {t}
    </Chip>
  ) : (
    <></>
  );
};
