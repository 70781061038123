import { TBucket } from "models/Dimension";

export const queryKeyFactory = (key: string) => {
  const queryKeys = {
    all: [key] as const,
    list: (filters?: Record<string, TBucket>) =>
      filters
        ? ([...queryKeys.all, filters] as const)
        : ([...queryKeys.all] as const),
    allDetails: () => [...queryKeys.all, "detail"] as const,
    detail: (id: string) => [...queryKeys.allDetails(), id] as const,
  };

  return queryKeys;
};
