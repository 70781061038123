import {
  ConditionalRestriction,
  DataAttribute,
} from "components/common/ui/form-elements/formElements";
import { CombinedUserTypes, UserType } from "models/user/attributes/userType";

//FIXME: should use something like <keyof User> for proper typing
export interface UserAttribute extends DataAttribute {
  warning?: any; // UI display warning if not completed
  tracked?: boolean; //must track changes to this field (as a history)
}

//FIXME: should use something like <keyof User> for proper typing
// export type UserConditional = Conditional<keyof TUser>;
// type UserArrayConditional = ConditionalRestriction<keyof TUser>[]
type UserArrayConditional = ConditionalRestriction<string>[];

export const userTypeOptions: { value: UserType; label: string }[] = [
  {
    value: UserType.applicant,
    label: "Applicant",
  },
  {
    value: UserType.registrar,
    label: "Registrar",
  },
  {
    value: UserType.snr_registrar,
    label: "Senior Registrar",
  },
  {
    value: UserType.fellow,
    label: "Fellow",
  },
  {
    value: UserType.est_fellow,
    label: "Esteemed Fellow",
  },
  {
    value: UserType.hon_fellow,
    label: "Honorary Fellow",
  },
  {
    value: UserType.education,
    label: "Educational",
  },
  {
    value: UserType.general,
    label: "General",
  },
];

export const userFieldsKey = "fields_others";
export const userOfficeOnlyKey = "fields_office_only";
export const userTypeKey = "user_type";

export const generalUsersConditional: UserArrayConditional = [
  {
    field: userTypeKey,
    // values: ["education", "applicant", "fellow", "registrar", "snr_registrar"],
    values: [UserType.general],
  },
];

export const nonGeneralUsersConditional: UserArrayConditional = [
  {
    field: userTypeKey,
    // values: ["education", "applicant", "fellow", "registrar", "snr_registrar"],
    values: CombinedUserTypes.nonGeneral,
  },
];

export const clinicalUsersConditional: UserArrayConditional = [
  {
    field: userTypeKey,
    // values: ["applicant", "fellow", "registrar", "snr_registrar"],
    values: CombinedUserTypes.clinicians,
  },
];

export const memberUsersConditional: UserArrayConditional = [
  {
    field: userTypeKey,
    // values: ["education", "fellow", "registrar", "snr_registrar"],
    values: CombinedUserTypes.members,
  },
];

export const confirmedClinicalConditional: UserArrayConditional = [
  {
    field: userTypeKey,
    // values: ["fellow", "registrar", "snr_registrar"],
    values: CombinedUserTypes.clinicianMember,
  },
];

export const registrarConditional: UserArrayConditional = [
  {
    field: userTypeKey,
    values: CombinedUserTypes.registrar,
  },
];

export const fellowConditional: UserArrayConditional = [
  {
    field: userTypeKey,
    values: CombinedUserTypes.fellow,
  },
];

export const educationConditional: UserArrayConditional = [
  {
    field: userTypeKey,
    values: CombinedUserTypes.education,
  },
];

export const exceptFellowUsersConditional: UserArrayConditional = [
  {
    field: userTypeKey,
    // values: ["applicant", "fellow", "registrar", "snr_registrar"],
    values: CombinedUserTypes.exceptFellow,
  },
];
export const exceptRegistrarUsersConditional: UserArrayConditional = [
  {
    field: userTypeKey,
    // values: ["applicant", "fellow", "registrar", "snr_registrar"],
    values: CombinedUserTypes.exceptRegistrar,
  },
];

export const fellowEducationUsersConditional: UserArrayConditional = [
  {
    field: userTypeKey,
    values: CombinedUserTypes.fellowEducation,
  },
];

export const fellowApplicantUsersConditional: UserArrayConditional = [
  {
    field: userTypeKey,
    values: CombinedUserTypes.fellowApplicant,
  },
];

export const potentialSupervisorConditional: UserArrayConditional = [
  {
    field: userTypeKey,
    values: CombinedUserTypes.potentialSupervisor,
  },
];
