import React from "react";
import createIcon from "components/common/icons/common/createIcon";

const IconMedBag = createIcon(
  <React.Fragment>
    <path d="M10.0138 3L8.01384 5V7H5.01384C3.86384 7 3.13384 8 3.01384 9L2.01384 19C1.89384 20 2.55384 21 4.01384 21H20.0138C21.4738 21 22.1338 20 22.0138 19L21.0138 9C20.8938 8 20.0738 7 19.0138 7H16.0138V5L14.0138 3H10.0138ZM10.0138 5H14.0138V7H10.0138V5ZM11.0138 10H13.0138V13H16.0138V15H13.0138V18H11.0138V15H8.01384V13H11.0138V10Z" />{" "}
  </React.Fragment>,
  "MedBag"
);

export default IconMedBag;
