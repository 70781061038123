import { IconWarning } from "components/common/icons/twoToneIcons";
import FormattedQuestion from "components/common/ui/form-elements/display-elements/FormattedQuestion";
import { Activity } from "models/cpd/Activity";
import { activityAttributes } from "components/cpd/utils/activityForm";
import HintBox from "components/common/ui/hint-box/HintBox";
import React from "react";
import styles from "components/cpd/activity/ActivityDetail.module.scss";

interface Props {
  activity: Activity;
}

const WaiverResults = ({ activity }: Props) =>
  activity.waive_req ? (
    <HintBox theme="info" icon={<IconWarning />} className={styles.waived}>
      <b>Requirements Waived</b>
      <FormattedQuestion
        className={styles.waiveInfo}
        def={{
          ...activityAttributes.waiver,
          label: activityAttributes.waiver.label || "",
        }}
        questions={activity}
        spacing="none"
      />
      <FormattedQuestion
        def={{
          ...activityAttributes.waiverReason,
          label: activityAttributes.waiverReason.label || "",
        }}
        questions={activity}
      />
    </HintBox>
  ) : (
    <></>
  );

export default WaiverResults;
