// Modified from https://github.com/jeetiss/tabs
import React from "react";
import { usePanelState } from "components/common/ui/tabs/Tabs";

interface PanelProps {
  active?: boolean;
  className?: string;
  children: React.ReactNode;
}

export const Panel = ({ active, children }: PanelProps): React.ReactElement => {
  const isActive = usePanelState();

  return isActive || active ? <>{children}</> : <></>;
};
