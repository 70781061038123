import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router";
import { AppContentContainer } from "components/common/layouts/appLayout/AppContentContainer";
import { coreService } from "services/CoreService";
import {
  createPeerGroupMeeting as blankPeerGroupMeeting,
  PeerGroupMeeting,
} from "models/peergroup/meeting";
import { PEERGROUP_ROUTES } from "constants/routes/peergroup";
import { useMutatePeerGroupMeeting } from "components/peergroup/hooks/useMutatePeerGroupMeeting";
import PeerGroupMeetingEditForm from "components/peergroup/edit/PeerGroupMeetingEditForm";
import { usePeerGroupMeetings } from "components/peergroup/hooks/usePeerGroupMeetings";
import MiniLoader from "components/common/ui/loader/MiniLoader";
import Container from "components/common/layouts/Container";
import { ROUTE_PARAMS } from "constants/routes/common";
import Meta from "components/common/Meta";

const ViewAdminEditPeerGroupMeeting = () => {
  const { peergroupId, meetingId } =
    useParams<{ peergroupId: string; meetingId: string }>();
  const history = useHistory();
  const { isLoading: loadingDetails, data: peergroup_meeting } =
    usePeerGroupMeetings(peergroupId);

  const {
    mutate,
    isLoading: isSubmitting,
    isSuccess,
  } = useMutatePeerGroupMeeting();

  useEffect(() => {
    if (isSuccess) {
      history.push(
        coreService.getRoute(PEERGROUP_ROUTES.PEERGROUP_MEETING_DETAIL, {
          [ROUTE_PARAMS.PEERGROUP]: peergroupId,
          [ROUTE_PARAMS.MEETING]: meetingId,
        })
      );
    }
  }, [isSuccess, history, peergroupId, meetingId]);

  const onSave = async (newPeerGroupMeeting: Partial<PeerGroupMeeting>) => {
    await mutate({
      newPeerGroupMeeting,
      oldPeerGroupMeeting: blankPeerGroupMeeting(),
      peergroupId,
      meetingId,
    });
  };

  const onCancel = () => {
    history.push(
      coreService.getRoute(PEERGROUP_ROUTES.PEERGROUP_MEETING_DETAIL, {
        peergroupId: peergroupId,
        meetingId: meetingId,
      })
    );
  };

  return (
    <AppContentContainer
      spacing="none"
      backButton={{
        to: coreService.getRoute(PEERGROUP_ROUTES.PEERGROUP_MEETING_DETAIL, {
          peergroupId: peergroupId,
          meetingId: meetingId,
        }),
        label: "Back to meeting detail",
      }}
    >
      <Meta title={`Edit Peer Group`} />
      <br />
      <br />
      <br />
      <Container>
        <h1 style={{ marginBottom: 0 }}>Edit Peer Group Meeting</h1>
        {loadingDetails || !peergroup_meeting ? (
          <MiniLoader theme="secondary" />
        ) : (
          <PeerGroupMeetingEditForm
            onSubmit={onSave}
            peergroup_meeting={
              peergroup_meeting.meetingsById?.[meetingId] || []
            }
            peergroupId={peergroupId}
            onCancel={onCancel}
            isSubmitting={isSubmitting}
          />
        )}
      </Container>
    </AppContentContainer>
  );
};

export default ViewAdminEditPeerGroupMeeting;
