import { useMutation, useQueryClient } from "react-query";
import { MutationOptions } from "queries/queries";
import { useToast } from "components/common/ui/toast/useToast";
import { peerGroupQueryKeys } from "queries/constants";
import { deleteMeetingDoc, uploadMeetingDoc } from "api/PeerGroupApi";

export const useMutateUploadMeetingDoc = (options: MutationOptions = {}) => {
  const queryClient = useQueryClient();
  const { addErrorToast, addToast } = useToast();

  return useMutation(
    ({ file, meetingId, peergroupId }: useMutateUploadMeetingProps) =>
      uploadMeetingDoc(file, meetingId, peergroupId),
    {
      onSuccess: (result, inputs) => {
        addToast(`File was successfully uploaded`, {
          theme: "success",
        });

        queryClient.invalidateQueries(
          peerGroupQueryKeys.meetings(inputs.peergroupId)
        );
      },
      onError: (e) => {
        addErrorToast(e, "Error: Unable to upload file");
      },
      ...options,
    }
  );
};

export const useMutateDeleteMeetingDoc = (options: MutationOptions = {}) => {
  const queryClient = useQueryClient();
  const { addErrorToast, addToast } = useToast();

  return useMutation(
    ({ file, meetingId, peergroupId }: useMutateDeleteDocProps) =>
      deleteMeetingDoc(file, meetingId, peergroupId),
    {
      onSuccess: (result, inputs) => {
        addToast(`File was successfully deleted`, {
          theme: "success",
        });

        queryClient.invalidateQueries(
          peerGroupQueryKeys.meetings(inputs.peergroupId)
        );
      },
      onError: (e) => {
        addErrorToast(e, "Error: Unable to delete file");
      },
      ...options,
    }
  );
};

interface useMutateDeleteDocProps {
  file: string;
  meetingId: string;
  peergroupId: string;
}
interface useMutateUploadMeetingProps {
  file: File;
  meetingId: string;
  peergroupId: string;
}
