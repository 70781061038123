import { IDimensions, TBucket } from "models/Dimension";

export const dimensionsToParamsFormat = (dimensions: IDimensions) => {
  return Object.keys(dimensions)
    .filter((d) => {
      const b = dimensions[d].buckets || [];
      return b !== null && b.length > 0;
    })
    .reduce((r, d) => ({ ...r, [d]: dimensions[d].buckets }), {});
};

// export const dimensionsToParamsFormat = (dimensions: IDimensions) => {
//   return Object.keys(dimensions)
//     .filter((d) => {
//       const b = dimensions[d].buckets || [];
//       return b !== null && b.length > 0;
//     })
//     .reduce(
//       (r, d) =>
//         dimensions[d].buckets !== undefined
//           ? { ...r, [d]: dimensions[d].buckets }
//           : r,
//       {}
//     );
// };

export const diffParamsAndDimensions = (
  dimensions: Record<string, TBucket>,
  params: Record<string, any>
) => {
  const dimKeys = Object.keys(dimensions).sort();
  const paramKeys = Object.keys(params).sort();
  const dimToRemove = dimKeys
    .filter((x) => !paramKeys.includes(x))
    .map((d) => ({ key: d, bucket: null }));

  const dimToAdd = paramKeys
    .filter((x) => !dimKeys.includes(x))
    .map((d) => ({ key: d, bucket: params[d] }));

  const diffIntersection = dimKeys
    .filter((a) => paramKeys.includes(a))
    .map((x) => {
      const d = dimensions[x] as string | string[];
      let p = params[x];
      if (typeof d === "string") {
        if (d !== p) {
          return { key: x, bucket: p };
        }
      } else {
        p = typeof p === "string" ? [p] : p;
        if (
          p.length !== d.length ||
          !d.every((val, index) => val === p[index])
        ) {
          return { key: x, bucket: p };
        }
      }
      return undefined;
    })
    .filter((x) => !!x);
  return [...dimToRemove, ...dimToAdd, ...diffIntersection];
};
