import React from "react";
import cx from "classnames";
import styles from "./WaveBackground.module.scss";

interface Props {
  variant?: "contrast" | "dark" | "light";
  className?: string;
}

const WaveBackground = ({ className }: Props) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 2560 1440"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="none"
    className={cx(styles.root, className)}
  >
    <path
      d="M0 1133.49V902.624C141.24 910.964 249.851 879.428 360.832 847.203C370.016 844.536 379.215 841.865 388.451 839.212L390.11 838.735C519.276 801.62 647.698 764.719 809.483 778.644C971.962 792.629 1089.22 851.506 1206.14 910.219C1322.42 968.607 1438.38 1026.83 1598.16 1040.59C1759.66 1054.49 1894.07 1016.77 2027.41 979.352C2163.55 941.15 2288.89 905.979 2442.91 919.236C2484.56 922.82 2523.33 929.648 2560 938.85V1102.63C2402.35 1102.02 2270.09 1152.47 2122.92 1218.87C2120.22 1219.97 2117.51 1221.08 2114.81 1222.18C1960.06 1285.44 1810.21 1346.69 1631.46 1331.3C1450.39 1315.72 1317.64 1228.41 1183.27 1140.03L1181.5 1138.86C1044.37 1048.68 906.148 957.772 716.292 941.43C528.448 925.262 372.765 989.205 216.649 1053.33C145.549 1082.53 74.359 1111.77 0 1133.49Z"
      fill="url(#wave_bg_54354_paint0_linear)"
    />
    <path
      d="M167.671 1440H0V1365.65C98.9282 1324.74 168.245 1275.85 238.842 1226.06C245.717 1221.21 252.605 1216.35 259.534 1211.49L260.779 1210.61C357.681 1142.64 454.025 1075.05 604.99 1026.67C756.601 978.086 889.907 972.713 1022.84 967.355C1155.04 962.026 1286.87 956.712 1435.97 908.931C1586.67 860.638 1687.94 790.454 1788.42 720.825C1891 649.736 1985.45 584.286 2129.17 538.228C2273.53 491.967 2404.43 486.898 2535.78 481.811L2536.29 481.792L2536.3 481.792C2544.2 481.486 2552.1 481.18 2560 480.865V550.472C2500.41 557.402 2440.31 569.277 2379.49 588.767C2206.18 644.307 2101.98 729.169 1990.83 833.024C1988.97 834.646 1987.1 836.269 1985.23 837.89C1878.33 930.769 1774.83 1020.7 1608.03 1074.15C1439.07 1128.3 1278.03 1121.81 1115.02 1115.25L1112.88 1115.16C946.526 1108.46 778.852 1101.71 601.694 1158.49C426.414 1214.66 319.027 1308.28 211.342 1402.16C196.846 1414.8 182.344 1427.44 167.671 1440Z"
      fill="url(#wave_bg_54354_paint1_linear)"
    />
    <path
      d="M0 1029.86V1226.75C95.6027 1172.66 177.87 1113.16 260.013 1053.76C411.075 944.51 561.717 835.565 794.105 761.094C1028.98 685.824 1242.84 679.266 1455.01 672.76L1457.74 672.676C1665.65 666.302 1871.04 660.005 2095.05 588.217C2290.95 525.438 2427.23 436.126 2560 341.399V89.6768C2496.66 123.139 2437.14 158.705 2375.48 195.546C2237.04 278.264 2097.5 361.641 1897.71 425.668C1700.03 489.017 1530.4 505.531 1360.31 522.091C1189.26 538.744 1017.74 555.443 816.734 619.859C616.585 683.999 483.725 764.192 350.096 844.849L348.379 845.886C338.824 851.653 329.321 857.414 319.836 863.166C223.828 921.378 129.537 978.549 0 1029.86Z"
      fill="url(#wave_bg_54354_paint2_linear)"
    />
    <path
      d="M733.802 0H116.865C319.232 24.764 483.218 84.6558 646.078 144.136L648.501 145.021C832.316 212.156 1013.91 278.48 1249.96 293.068C1482.98 307.469 1669.97 265.187 1863.09 221.518C1866.47 220.756 1869.84 219.993 1873.21 219.231C2079.45 167.252 2263.47 129.805 2505.59 144.769C2524.03 145.908 2542.16 147.358 2560 149.095V51.4131C2467.03 29.5691 2367.71 12.2195 2253.53 5.16343C2052.75 -7.24544 1894.52 16.349 1722.67 41.9763C1554.35 67.0774 1384.68 92.3784 1174.15 79.3673C1001.82 68.7168 864.877 36.2749 733.802 0Z"
      fill="url(#wave_bg_54354_paint3_linear)"
    />
    <defs>
      <linearGradient
        id="wave_bg_54354_paint0_linear"
        x1="554.534"
        y1="887.444"
        x2="2309.23"
        y2="826.556"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2F82FF" stopOpacity="0.15" />
        <stop offset="1" stopColor="#2F82FF" stopOpacity="0.05" />
      </linearGradient>
      <linearGradient
        id="wave_bg_54354_paint1_linear"
        x1="554.534"
        y1="673.026"
        x2="2310.63"
        y2="637.57"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2F82FF" stopOpacity="0.15" />
        <stop offset="1" stopColor="#2F82FF" stopOpacity="0.05" />
      </linearGradient>
      <linearGradient
        id="wave_bg_54354_paint2_linear"
        x1="554.534"
        y1="317.487"
        x2="2310.84"
        y2="287.576"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2F82FF" stopOpacity="0.15" />
        <stop offset="1" stopColor="#2F82FF" stopOpacity="0.05" />
      </linearGradient>
      <linearGradient
        id="wave_bg_54354_paint3_linear"
        x1="646.084"
        y1="59.2904"
        x2="2316.18"
        y2="-45.0041"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2F82FF" stopOpacity="0.15" />
        <stop offset="1" stopColor="#2F82FF" stopOpacity="0.05" />
      </linearGradient>
    </defs>
  </svg>
);

export default WaveBackground;
