import React from "react";
import cx from "classnames";
import { IAddress } from "models/user/Contact";
import styles from "./FormattedAddress.module.scss";

interface Props {
  className?: string;
  address?: IAddress;
  bg?: boolean;
}

const FormattedAddress = ({ address, bg = true, className }: Props) =>
  address && address?.street_address ? (
    <address className={cx(styles.address, bg && styles.bg, className)}>
      <div>{address.street_address}</div>
      <div>{address.locality}</div>
      <div>
        {address.region} {address.postcode}
      </div>
      <div>{address.country}</div>
    </address>
  ) : (
    <i className={styles.noValue}>No address</i>
  );

export default FormattedAddress;
