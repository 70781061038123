import React from "react";
import { useSelector } from "react-redux";
import { TUser, USER_LIST_FILTER_KEYS, USER_STATUS } from "models/user/User";
import Table from "components/common/ui/table/Table";
import { IColumn } from "components/common/ui/table/utils";
import { USER_ROUTES } from "constants/routes/user";
import { coreService } from "services/CoreService";
import Chip from "components/common/ui/chip/Chip";
import {
  IDimensions,
  TSetActiveBucket,
  TSetManyBucketsActive,
} from "models/Dimension";
import ListFilters from "components/common/ui/filter/ListFilters";
import Container from "components/common/layouts/Container";
import { isDimensionsLoadingSelector } from "store/selectors/dimensions.selectors";
import { IconSuspendUser } from "components/common/icons/IconUser";
import {
  IconWarning,
  IconDanger,
  IconError,
  IconSuccess,
} from "components/common/icons/twoToneIcons";
import Accordian from "components/common/ui/accordian/Accordian";
import { UserIconWithToolTip } from "components/users/common/UserIcon";
import styles from "components/users/admin/list/UsersList.module.scss";
import { getTabs } from "components/users/utils/userUtils";
import ActionButton from "components/common/ui/button/ActionButton";
import Tooltip from "components/common/ui/tooltip/Tooltip";
import { StatusAction } from "components/users/common/detail/StatusActions";
import { checkConditionals } from "utils/formUtils";
import { CPD_ROUTES } from "constants/routes/cpd";
import {
  exceptRegistrarUsersConditional,
  registrarConditional,
} from "models/user/attributes/common";
import IconSchool from "components/common/icons/IconSchool";

interface Props {
  users: TUser[];
  dimensions: IDimensions;
  setActive: TSetActiveBucket;
  setManyActive: TSetManyBucketsActive;
  isAppLoaded: boolean;
  loading?: boolean;
  showFilters?: boolean;
  actionCell?: (
    cell: string,
    row: TUser,
    column: IColumn<TUser>
  ) => React.ReactNode;
}

interface IData {
  active: TUser[];
  archived: TUser[];
}

const UsersList = ({
  users,
  loading,
  dimensions,
  setActive,
  setManyActive,
  isAppLoaded,
  showFilters = true,
  actionCell = (c, row) => (
    <>
      {getTabs(row as TUser).map((t) =>
        t.icon ? (
          <Tooltip content={t.title} key={t.id}>
            <ActionButton
              icon={t.icon}
              to={`${coreService.getRoute(USER_ROUTES.ADMIN_USER_DETAIL, {
                id: row.id,
              })}#${t.id}`}
              variant="empty"
              theme={"primary"}
            />
          </Tooltip>
        ) : null
      )}
      {checkConditionals(row, exceptRegistrarUsersConditional) && (
        <Tooltip content={"Plan"} key="cpd plan">
          <ActionButton
            icon={<IconSchool />}
            to={coreService.getRoute(CPD_ROUTES.ADMIN_USER_PLANS, {
              id: row.id,
            })}
            variant="empty"
            theme={"info"}
          />
        </Tooltip>
      )}
      {checkConditionals(row, registrarConditional) && (
        <Tooltip content={"TPS"} key="tps">
          <ActionButton
            icon={<IconSchool />}
            to={coreService.getRoute(CPD_ROUTES.ADMIN_USER_TPS, {
              id: row.id,
            })}
            variant="empty"
            theme={"info"}
          />
        </Tooltip>
      )}
    </>
  ),
}: Props) => {
  const isDimLoading = useSelector(isDimensionsLoadingSelector);
  const data = React.useMemo(
    () =>
      users.reduce(
        (d, u) => {
          if (u.active) {
            d.active.push(u);
          } else {
            d.archived.push(u);
          }
          return d;
        },
        { active: [], archived: [] } as IData
      ),
    [users]
  );
  const columns = [
    {
      id: "oversight",
      label: " ",
      width: "50px",
      type: "icon",
      className: styles.iconCol,
      condition: !users,
      cell: (cm, row: TUser) => {
        switch (row.fields_office_only?.additional_oversight_severity) {
          case "yellow":
            return <IconWarning className={styles.yellow} />;
          case "orange":
            return <IconError className={styles.warning} />;
          case "red":
            return <IconDanger className={styles.danger} />;
          case "purple":
            return <IconWarning className={styles.primary} />;
          case "blue":
            return <IconWarning className={styles.info} />;

          case "green":
            return <IconSuccess className={styles.success} />;
          default:
            return null;
        }
      },
    },
    {
      id: "icon",
      label: " ",
      width: "50px",
      type: "icon",
      className: styles.iconCol,
      cell: (cm, row: TUser) =>
        row.active ? (
          <UserIconWithToolTip user={row} />
        ) : (
          <IconSuspendUser className={styles.danger} />
        ),
    },
    {
      id: "name",
      label: "Name",
      cell: (c, row: TUser) => `${row.given_name} ${row.family_name}`,
      className: styles.clientColumn,
    },
    {
      label: "MCNZ/ID",
      id: "mcnz",
    },
    {
      label: "Email",
      id: "email",
    },

    {
      id: "status",
      label: "Status",
      className: styles.statusContainer,
      cell: (c, row: TUser) => getStatus(row),
    },
    {
      id: "actions",
      label: " ",
      // type: "icon",
      className: styles.actionCol,
      cell: actionCell,
    },
  ] as IColumn[];

  return (
    <div className={styles.root}>
      {showFilters && (
        <Container spacing={"none"}>
          <ListFilters
            filters={USER_LIST_FILTER_KEYS}
            dimensions={dimensions}
            setActive={setActive}
            setManyActive={setManyActive}
            isLoading={!isAppLoaded}
            isUpdating={isDimLoading}
          />
        </Container>
      )}

      <Table
        className={styles.table}
        columns={columns}
        data={data.active}
        getRowProps={(row) => ({
          to: coreService.getRoute(USER_ROUTES.ADMIN_USER_DETAIL, {
            id: row.id,
          }),
          wrapperClassName:
            styles[
              `oversight-${row.fields_office_only?.additional_oversight_severity}`
            ],
        })}
        loading={loading}
        // loading={true}
        placeholder={{ noDataLabel: "No Users Found", loadingRows: 5 }}
      />

      {data.archived.length > 0 && (
        <Accordian
          headerClassName={styles.deletedAccordian}
          header={<h4>Suspended Users</h4>}
        >
          <Table
            className={styles.table}
            columns={columns}
            data={data.archived}
            getRowProps={(row) => ({
              to: coreService.getRoute(USER_ROUTES.ADMIN_USER_DETAIL, {
                id: row.id,
              }),
            })}
            loading={loading}
            placeholder={{
              noDataLabel: "No Suspended Users Found",
              loadingRows: 2,
            }}
          />
        </Accordian>
      )}
    </div>
  );
};

const getStatus = (user: TUser) => {
  if (!user.active) {
    return (
      <Chip condensed theme="danger" className={styles.status}>
        <b>SUSPENDED</b>
      </Chip>
    );
  }
  if (!user.status) {
    return "";
  }
  const text = USER_STATUS[user.status];
  switch (user.status) {
    case "FORCE_CHANGE_PASSWORD":
      return (
        <>
          <Chip condensed theme="warning" className={styles.status}>
            {text}
          </Chip>
          <StatusAction user={user} canEdit={true} actionOnly />
          {/*<Tooltip content={"Resend Invite Email"} offset={6}>*/}
          {/*  <button*/}
          {/*    onClick={(e) => {*/}
          {/*      e.preventDefault();*/}
          {/*      userService.resendInvitation(user);*/}
          {/*    }}*/}
          {/*    className={cx(styles.resend, styles.icon)}*/}
          {/*  >*/}
          {/*    <IconResendEmail />*/}
          {/*  </button>*/}
          {/*</Tooltip>*/}
        </>
      );
    case "RESET_REQUIRED":
      return (
        <Chip condensed theme="danger" className={styles.status}>
          {text}
        </Chip>
      );
    case "CONFIRMED":
      return (
        <>
          <Chip condensed theme="info" className={styles.status}>
            {text}
          </Chip>
          {/*<Tooltip content={"Send Password Reset"} offset={6}>*/}
          {/*  <button*/}
          {/*    onClick={(e) => {*/}
          {/*      e.preventDefault();*/}
          {/*      userService.resetUserPassword(user);*/}
          {/*    }}*/}
          {/*    className={cx(styles.reset, styles.icon)}*/}
          {/*  >*/}
          {/*    <IconResetLock />*/}
          {/*  </button>*/}
          {/*</Tooltip>*/}
        </>
      );
    default:
      return (
        <Chip condensed theme="grey" className={styles.status}>
          {text}
        </Chip>
      );
    // return text;
  }
};

export default UsersList;
