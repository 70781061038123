import React from "react";
import Table from "components/common/ui/table/Table";
import { IconEye } from "components/common/icons/twoToneIcons";
import { IColumn } from "components/common/ui/table/utils";
import {
  PeerGroupMeeting,
  PeergroupMeetingStatus,
} from "models/peergroup/meeting";
import { coreService } from "services/CoreService";
import { PEERGROUP_ROUTES } from "constants/routes/peergroup";
import { useParams } from "react-router";
import { formatDate } from "utils/format/dateUtils";
import {
  IconProgressCancel,
  IconProgressQuestion,
  IconProgressStar,
} from "components/common/icons/IconProgress";
import { AvatarGroup } from "components/common/ui/avatar/Avatar";
import Chip from "components/common/ui/chip/Chip";
import styles from "./PeerGroupMeetingsList.module.scss";

interface Props {
  meetings: PeerGroupMeeting[];
}
const PeerGroupMeetingsList = ({ meetings }: Props) => {
  const { peergroupId } = useParams<{ peergroupId: string; orgId: string }>();
  const columns: IColumn<PeerGroupMeeting>[] = [
    {
      label: " ",
      id: "icon",
      width: "50px",
      type: "icon",
      className: styles.iconCol,
      cell: (c, row) => (
        <MeetingStatus
          status={row.peergroup_meeting_status as PeergroupMeetingStatus}
        />
      ),
    },
    {
      id: "peergroup_meeting_title",
      label: "Meeting",
      type: "textEmphasis",
      cell: (c, row) => `${row.peergroup_meeting_title} `,
    },

    {
      id: "peergroup_meeting_schedule",
      label: "Scheduled Date",
      cell: (c, row) =>
        formatDate(row.peergroup_meeting_schedule, "dd/MM/yyyy, p"),
    },
    {
      id: "peergroup_meeting_points",
      label: "Points (1/hr)",
      cell: (c, row) => `${row.peergroup_meeting_points} `,
    },
    {
      id: "peergroup_meeting_status",
      label: "Status",
      cell: (c, row) => (
        <Chip
          condensed
          theme={getStatus(
            row.peergroup_meeting_status as PeergroupMeetingStatus
          )}
        >
          {row.peergroup_meeting_status}
        </Chip>
      ),
    },
    {
      id: "attendees",
      label: "Attendees",
      cell: (c, row) => (
        <AvatarGroup
          users={row.peergroup_meeting_attendees}
          size={"md"}
          tooltip
          max={4}
        />
      ),
    },

    {
      id: "link",
      label: " ",
      width: "50px",
      type: "icon",
      className: styles.iconCol,
      cell: () => <IconEye />,
    },
  ];
  return (
    <Table
      columns={columns}
      data={meetings}
      getRowProps={(row) => ({
        to: coreService.getRoute(PEERGROUP_ROUTES.PEERGROUP_MEETING_DETAIL, {
          peergroupId: peergroupId,
          meetingId: row.id,
        }),
      })}
      placeholder={{ noDataLabel: "No Peer Meeting Found", loadingRows: 5 }}
      className={styles.table}
    />
  );
};

const getStatus = (status?: PeergroupMeetingStatus): ThemeVariants =>
  status
    ? ({
        [PeergroupMeetingStatus.COMPLETE]: "success",
        [PeergroupMeetingStatus.DRAFT]: "warning",
        [PeergroupMeetingStatus.CANCELLED]: "danger",
      }[status] as ThemeVariants)
    : "default";
const MeetingStatus = ({ status }: { status?: PeergroupMeetingStatus }) => {
  switch (status) {
    case PeergroupMeetingStatus.COMPLETE:
      return <IconProgressStar className={styles[getStatus(status)]} />;
    case PeergroupMeetingStatus.DRAFT:
      return (
        // <span className={styles.success}>
        <IconProgressQuestion className={styles[getStatus(status)]} />
        // </span>
      );
    case PeergroupMeetingStatus.CANCELLED:
      return <IconProgressCancel className={styles[getStatus(status)]} />;
  }
  return <></>;
};

export default PeerGroupMeetingsList;
