import { TBucket } from "models/Dimension";
import { request } from "utils/http/Request";
import {
  getApiUrl,
  USER_PLANS,
  PLANS,
  USER_PLAN_DETAIL,
  PLAN_DETAIL,
  ACTIVITY_DETAIL,
  ACTIVITY,
  GOAL_DETAIL,
  GOALS,
  ACTIVITY_DETAIL_DOCS,
  ACTIVITY_DETAIL_GET_DOCS,
} from "constants/paths";
import {
  formatPlan,
  IServerPlan,
  Plan,
  PlanListShape,
  PlanYear,
  toServerObject as toServerPlan,
} from "models/cpd/Plan";
import {
  Activity,
  ActivityListShape,
  formatActivity,
  IServerActivity,
  toServerObject as toServerActivity,
} from "models/cpd/Activity";
import { formatGoal, Goal, IServerGoal, toServerGoal } from "models/cpd/Goal";
import { nest } from "utils/common";
import axios from "axios";
import fileDownload from "js-file-download";
import { fromS3SafeName, toS3SafeName } from "components/cpd/utils/fileUtils";
import { formattedActivities } from "components/cpd/utils/utils";

//---------------------------------------------
//                 Plans
//---------------------------------------------

export const fetchManyPlans = async (
  filter: Record<string, TBucket>,
  userId?: string
): Promise<PlanListShape> => {
  const data = await request.get(
    getApiUrl(userId ? USER_PLANS : PLANS, { userId }),
    filter
  );
  const formatted = data.plans.map(formatPlan) as Plan[];
  return nest(formatted, [(d) => (d.complete ? "completed" : "active")]);
};

export const fetchOnePlanById = async (
  planId: string,
  userId?: string
): Promise<Plan> => {
  const data = await request.get(
    getApiUrl(userId ? USER_PLAN_DETAIL : PLAN_DETAIL, { userId, planId })
  );
  return formatPlan(data as IServerPlan);
};

export const savePlan = async (
  { plan_id, ...plan }: Partial<Plan>,
  oldPlan: Plan,
  userId: string
): Promise<Plan> => {
  if (plan_id) {
    await request.put(
      getApiUrl(USER_PLAN_DETAIL, { userId, planId: plan_id }),
      toServerPlan(plan)
    );
    return { ...oldPlan, ...plan, plan_id };
  }
  const newPlan = await request.post(
    getApiUrl(USER_PLANS, { userId }),
    toServerPlan(plan)
  );
  return formatPlan(newPlan as IServerPlan);
};

//---------------------------------------------
//                 Goals
//---------------------------------------------

export const fetchManyGoals = async (userId: string): Promise<Goal[]> => {
  const data = await request.get(getApiUrl(GOALS, { userId }));
  return (
    data.goals
      .map(formatGoal)
      //sort goals by completed state, then by goal_name
      .sort((a: Goal, b: Goal) => {
        if (!!a.completed_at === !!b.completed_at) {
          return a.goal_name.localeCompare(b.goal_name);
        }
        return !!a.completed_at ? 1 : -1;
      })
  );
};

export const saveGoal = async (
  { goal_id, ...goal }: Partial<Goal>,
  oldGoal: Goal,
  userId: string
): Promise<Goal> => {
  if (goal_id) {
    await request.put(
      getApiUrl(GOAL_DETAIL, { userId, goalId: goal_id }),
      toServerGoal(goal)
    );
    return { ...oldGoal, ...goal, goal_id };
  }
  const newGoal = await request.post(
    getApiUrl(GOALS, { userId }),
    toServerGoal(goal)
  );
  return formatGoal(newGoal as IServerGoal);
};

//---------------------------------------------
//                 Activity
//---------------------------------------------

export const fetchManyActivity = async (
  // filter: Record<string, TBucket>,
  userId: string,
  planId?: string | null,
  planYears?: PlanYear[]
): Promise<ActivityListShape> => {
  const params = {} as TObjectAny;
  if (planId) {
    params.plan_id = planId;
  }
  const data = await request.get(getApiUrl(ACTIVITY, { userId }), params);
  return formattedActivities(data.activities, planYears);
};

export const fetchOneActivityById = async (
  activityId: string,
  userId?: string
): Promise<Activity> => {
  const data = await request.get(
    getApiUrl(userId ? ACTIVITY_DETAIL : ACTIVITY, {
      userId,
      activityId,
    })
  );
  return formatActivity(data as IServerActivity);
};

export const saveActivity = async (
  { id, ...activity }: Partial<Activity>,
  oldActivity: Activity,
  userId: string
): Promise<Activity> => {
  if (id) {
    await request.put(
      getApiUrl(ACTIVITY_DETAIL, { userId, activityId: id }),
      toServerActivity(activity)
      // toServerActivity(activity, useraccount_id: userId)
    );
    return { ...oldActivity, ...activity, id };
  }
  const newActivity = await request.post(
    getApiUrl(ACTIVITY, { userId }),
    toServerActivity(activity)
  );
  return formatActivity(newActivity as IServerActivity);
};

export const deleteActivity = async (activityId: string, userId: string) => {
  if (activityId) {
    return await request.delete(
      getApiUrl(ACTIVITY_DETAIL, { userId, activityId })
    );
  } else {
    throw new Error("No activity id provided");
  }
};

export interface IFile extends File {
  path: string;
}

export const uploadActivityDoc = async (
  file: File,
  activityId: string,
  userId: string
) => {
  try {
    // const signedUrl = await request.post(`${ACTIVITY}/${id}/docs/${file.path}`);
    const removedSpacedFile = new File([file], toS3SafeName(file.name));
    const signedUrl = await request.post(
      getApiUrl(ACTIVITY_DETAIL_DOCS, { activityId, userId }),
      {
        filename: removedSpacedFile.name,
      }
    );

    return await axios.put(signedUrl.url, removedSpacedFile, {
      headers: {
        // contentType: file.type,
      },
    });
  } catch (e) {
    console.log(e);
    return e;
  }
};

export const deleteActivityDoc = async (
  filename: string,
  activityId: string,
  userId: string
): Promise<any> => {
  if (filename) {
    return await request.delete(
      getApiUrl(ACTIVITY_DETAIL_GET_DOCS, { userId, activityId, filename })
    );
  } else {
    throw new Error("No activity id provided");
  }
};

export const fetchActivityUploadDoc = async (
  activityId: string,
  userId: string,
  filename: string
) => {
  const signedUrl = await request.get(
    getApiUrl(ACTIVITY_DETAIL_GET_DOCS, {
      activityId,
      userId,
      filename: filename,
    })
  );
  return await axios
    .get(signedUrl.url, {
      responseType: "blob",
    })
    .then((res) => {
      fileDownload(res.data, fromS3SafeName(filename));
    });
};
