import React from "react";
import { useHistory } from "react-router";
import { authService } from "services/AuthService";
import { HOME } from "constants/routes";

interface Props {
  children?: React.ReactNode;
  className?: string;
}
const SignOut = ({ className, children }: Props) => {
  const history = useHistory();
  const onClick = async () => {
    await authService.logout();
    history.push(HOME.path);
  };

  return (
    <button onClick={onClick} className={className}>
      {children ? children : "Sign Out"}
    </button>
  );
};

export default SignOut;
