import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import HeroButton from "components/common/ui/hero-button/HeroButton";
import { getSchema as buildSchema } from "utils/formUtils";
import PasswordTextInput from "components/common/ui/form-elements/text-input/PasswordTextField";
import {
  password,
  passwordMatch,
  resetCode,
  username,
} from "components/common/auth/forms/questionSchema";
import styles from "./Forms.module.scss";
import IconCheck from "components/common/icons/IconCheck";
import IconCancel from "components/common/icons/IconCancel";
import TextInput from "components/common/ui/form-elements/text-input/TextField";
import PasswordStrength from "components/common/auth/forms/PasswordStrength/PasswordStrength";

const passRequirements = [
  {
    key: "Meet at least the 'Strong' criteria",
    type: "strength",
    check: /[a-z]+/,
    req: 3,
    status: false,
  },
  {
    key: "Minimum of 8 characters long",
    type: "regex",
    check: /^.{10,}$/,
    req: 8,
    status: false,
  },
];

const checkValidationRules = (password: string, strength: number) => {
  return passRequirements.map((r) => {
    if (r.type === "strength") {
      return { key: r.key, status: strength >= r.req };
    }
    return { key: r.key, status: r.check.test(password) };
  });
};

const getDefaultValues = (values: any, resetCode: boolean, email: boolean) => {
  let defaults = { password: values.password || "" } as Record<string, string>;
  if (resetCode) {
    defaults["code"] = values.code || "";
  }
  if (email) {
    defaults["username"] = values.username || "";
  }
  return defaults;
};

const getSchema = (isResetCode: boolean, email: boolean) => {
  const schema = { password, "password-match": passwordMatch } as Record<
    string,
    any
  >;
  if (isResetCode) {
    schema["code"] = resetCode;
  }
  if (email) {
    schema["username"] = username;
  }
  return buildSchema(schema);
};

interface Props {
  onSubmit: () => void;
  values: any;
  errorMessage: string | null;
  changeState: (state: string) => void;
  isResetCode?: boolean;
  isFromEmailLink?: boolean;
}

const RequireNewPasswordForm = ({
  onSubmit,
  values,
  errorMessage,
  changeState,
  isResetCode = false,
  isFromEmailLink = false,
}: Props) => {
  const [passwordReq, setPasswordReq] = useState(checkValidationRules("", -1));
  const includeEmail = isFromEmailLink; //!values.username;
  let defaultValues = getDefaultValues(values, isResetCode, includeEmail);
  const {
    handleSubmit,
    formState: { errors },
    register,
    watch,
    trigger,
    setValue,
  } = useForm({
    mode: "onBlur",
    resolver: getSchema(isResetCode, includeEmail),
    defaultValues: defaultValues,
  });
  const submit = (data: any) => {
    values.password = data.password;
    if (isResetCode) {
      values.code = data.code.trim();
    }
    if (includeEmail) {
      values.username = data.username;
    }
    onSubmit();
  };
  const [passVal, strengthVal] = watch(["password", "password-strength"]);

  useEffect(() => {
    setPasswordReq(checkValidationRules(passVal, +strengthVal));
  }, [passVal, strengthVal]);

  return (
    <form onSubmit={handleSubmit(submit)} aria-label="new password" noValidate>
      {errorMessage && (
        <div className={styles.error}>Error: {errorMessage}</div>
      )}
      {includeEmail && (
        <TextInput
          {...register("username")}
          label="Email"
          error={errors["username"]}
        />
      )}
      {isResetCode && (
        <TextInput {...register("code")} label="Code" error={errors["code"]} />
      )}
      <b>Your new password must meet the following criteria:</b>
      <ul className={styles.passwordRules}>
        {passwordReq.map((r) => (
          <li key={r.key} className={r.status ? styles.success : ""}>
            {r.status ? <IconCheck /> : <IconCancel />}
            {r.key}
          </li>
        ))}
      </ul>

      <PasswordStrength
        name="password"
        label="New Password"
        register={register}
        error={errors["password"]}
        password={passVal}
        trigger={trigger}
        // noInputError
        setValue={setValue}
        autoComplete="new-password"
      />

      <PasswordTextInput
        {...register("password-match")}
        label="Confirm Password"
        error={errors["password-match"]}
        autoComplete="password-confirm"
      />

      <div className={styles.formActions}>
        <HeroButton type="submit">Reset password</HeroButton>
        <button onClick={() => changeState("signIn")} className="link">
          Back to Sign in
        </button>
      </div>
    </form>
  );
};

export default RequireNewPasswordForm;
