import {
  getLastItem,
  parseAttribute,
  removeUndefinedFromObject,
} from "utils/common";
import { convertToDate, formatDate } from "utils/format/dateUtils";
import {
  DIMENSION_MATCH,
  IDimension,
  IDimensions,
  IFacetOption,
} from "models/Dimension";
import {
  PATHWAY,
  pathwayDefaultRequirements,
  PathwayRequirements,
  TProgressItem,
} from "models/cpd/common/requirements";
import { addMonths, addYears, min, subDays } from "date-fns";

interface BasePlan {
  pathway: PATHWAY;
  plan_type: PLAN_TYPE;
  plan_status: PLAN_STATUS;
  duration: number;
  requirements: PathwayRequirements;
  progress?: PlanProgress;
}

export interface Plan extends BasePlan {
  plan_id: string;
  user_id: string;
  started_at: Date;
  completed_at?: Date | null;

  // calculated fields
  complete: boolean;
  final_date: Date | null;
  next_review_date: Date | null;
}

export type PlanListShape = {
  active?: Plan[];
  completed?: Plan[];
};

export interface IServerPlan extends BasePlan {
  id: string;
  useraccount_id: string;

  started_at: string | Date | null;
  completed_at: string | Date | null;

  // API created attributes
  created_at: string;
  updated_at: string;
}

export interface PlanYear {
  year: number;
  label: string;
  start: Date | string;
  end: Date | string;
  locked: boolean;
}

interface PlanProgress {
  years: PlanYear[];
  progress?: { annual: TProgressItem[]; triennial: TProgressItem[] };
}

export enum PLAN_TYPE {
  fellow = "fellow",
  registrar = "registrar",
}

interface IPathwayOption extends IFacetOption {
  url?: string;
}

export const planPathwayOptions: IPathwayOption[] = [
  {
    key: PATHWAY.urgent_care,
    label: "Urgent Care",
    url: "https://rnzcuc.org.nz/recertification-2021/overview/",
  },
  {
    key: PATHWAY.alternative_clinical,
    label: "Alternative Clinical",
    url: "https://rnzcuc.org.nz/recertification-2021/other-recertification-pathways/alternative-clinical-recertification-pathway/",
  },
  {
    key: PATHWAY.non_clinical,
    label: "Non Clinical",
    url: "https://rnzcuc.org.nz/recertification-2021/other-recertification-pathways/",
  },
  // {
  //   key: PATHWAY.special_circumstance,
  //   label: "Regular Practice Review (RPR)",
  //   url: "https://rnzcuc.org.nz/recertification-2021/regular-practice-review-option/",
  // },
  {
    key: PATHWAY.special_circumstance,
    label: "Special Circumstance",
    url: "https://rnzcuc.org.nz/recertification-2021/other-recertification-pathways/special-circumstance-recertification-pathway/",
  },
  {
    key: PATHWAY.training,
    label: "Training",
    url: "https://rnzcuc.org.nz/completing-the-training-programme/overview/",
  },
  {
    key: PATHWAY.itap,
    label: "Individualised Training And Assessment Programme",
  },
  // { value: Category.additional, label: "Additional CPD" },
];

export enum PLAN_STATUS {
  pending = "pending",
  active = "active",
  pause = "paused",
  finished = "finished",
  unfinished = "unfinished",
}

export const planStatusOptions: IFacetOption[] = [
  { key: PLAN_STATUS.pending, label: "Pending" },
  { key: PLAN_STATUS.active, label: "Active" },
  { key: PLAN_STATUS.pause, label: "Paused" },
  {
    key: PLAN_STATUS.finished,
    label: "Closed (Requirements met)",
    altLabel: "Requirements met",
  },
  {
    key: PLAN_STATUS.unfinished,
    label: "Closed (Unfinished requirements)",
    altLabel: "Unfinished requirements",
  },
  // { value: Category.additional, label: "Additional CPD" },
];

export const formatPlan = ({
  id,
  useraccount_id,
  started_at,
  completed_at,
  progress,
  requirements,
  ...plan
}: IServerPlan) => {
  const started = convertToDate(started_at) || undefined;
  const parsedProgress = parseAttribute<PlanProgress>(progress ?? "{}");
  const years =
    parsedProgress?.years ||
    (started ? getPlanYears(started, plan.duration) : []);

  const lastYear = getLastItem(years);
  const final_date = lastYear
    ? convertToDate(lastYear.end)
    : started && getFinalDate(started, plan.duration);
  const next_review_date =
    lastYear &&
    convertToDate(years.find((y) => !y.locked)?.end || lastYear.end);

  return createPlan({
    plan_id: id,
    user_id: useraccount_id,
    started_at: started,
    completed_at: convertToDate(completed_at),
    complete: !!completed_at,
    progress: { ...parsedProgress, years },
    requirements: parseAttribute(requirements),
    final_date: final_date || null,
    next_review_date: next_review_date || null,
    ...plan,
  });
};

export const createPlan = (data: Partial<Plan> = {}): Plan => {
  const cleanedData = removeUndefinedFromObject(data);
  return {
    ...blankPlan,
    ...cleanedData,
  };
};

export const toServerObject = ({
  plan_id,
  user_id,
  complete,
  completed_at,
  final_date,
  next_review_date,
  ...plan
}: Partial<Plan>): Partial<IServerPlan> => {
  return {
    // id: plan_id,
    // useraccount_id: user_id || userId,
    completed_at:
      plan.plan_status &&
      [PLAN_STATUS.finished, PLAN_STATUS.unfinished].includes(plan.plan_status)
        ? completed_at || new Date()
        : null,

    ...plan,
  };
};

export const PLAN_LIST_FILTER_KEYS = [
  "plan_status",
  "pathway",
  "completed_at",
  "next_review_date",
];

const PlanListDimArray = [
  {
    key: "completed_at",
    label: "Completed Date",
    match: DIMENSION_MATCH.DATE_RANGE,
    buckets: null,
  },
  {
    key: "plan_status",
    label: "Plan Status",
    match: DIMENSION_MATCH.FACET,
    buckets: null,
    options: planStatusOptions.map(({ key, ...d }) => ({
      key: key,
      ...d,
    })),
  },
  {
    key: "pathway",
    label: "Plan Pathway",
    match: DIMENSION_MATCH.FACET,
    buckets: null,
    options: planPathwayOptions.map(({ key, ...d }) => ({
      key: key,
      ...d,
    })),
  },
  {
    key: "next_review_date",
    label: "Next Review Date",
    match: DIMENSION_MATCH.DATE_RANGE,
    buckets: null,
  },
] as IDimension[];

export interface IPlanListDimensions extends IDimensions {}

export const planListDimensions = PlanListDimArray.reduce(
  (s, d) => ({ ...s, [d.key]: d }),
  {}
) as IPlanListDimensions;
const getFinalDate = (start: Date, duration: number) =>
  subDays(addMonths(start, duration), 1);

const blankPlan: Plan = {
  plan_id: "",
  user_id: "",
  duration: 36,
  pathway: PATHWAY.urgent_care,
  requirements: pathwayDefaultRequirements[PATHWAY.urgent_care],
  plan_type: PLAN_TYPE.fellow,
  plan_status: PLAN_STATUS.active,
  started_at: new Date(),
  complete: false,
  final_date: getFinalDate(new Date(), 36),
  next_review_date: addMonths(new Date(), 12),
  // requirements:{}
};

export const getPlanYears = (start: Date, duration: number): PlanYear[] => {
  const finalDate = getFinalDate(start, duration);
  return Array.from(Array(Math.ceil(duration / 12)).keys()).map((d, i) => {
    const startDate = addYears(start, i);
    const endDate = min([subDays(addYears(start, i + 1), 1), finalDate]);
    return {
      year: i + 1,
      label: `Year ${i + 1} (${formatDate(startDate)} to ${formatDate(
        endDate
      )})`,
      start: startDate,
      end: endDate,
      locked: false,
    };
  });
};
