import {
  PermissionGroups,
  PERMISSIONS,
  TRequiredPermission,
} from "models/user/Permissions";
import { IAppState } from "store/state";

export const verifyPermisisons = (
  permissions: PERMISSIONS[] = [],
  requiredRole?: TRequiredPermission
) =>
  !requiredRole ||
  (typeof requiredRole === "string" && permissions.includes(requiredRole)) ||
  (Array.isArray(requiredRole) &&
    (requiredRole.length === 0 ||
      requiredRole.some((r) => permissions.includes(r))));

export const canAdminEdit = (permissions: PERMISSIONS[]) =>
  verifyPermisisons(permissions, PermissionGroups.adminEdit);

export const extractCurrentPermissions = (appState: IAppState) =>
  appState.core.user.roles && appState.core.user.roles?.length > 0
    ? appState.core.user.roles
    : appState.auth.permissions;
