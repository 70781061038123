import React, { useEffect, useState } from "react";
import Meta from "components/common/Meta";
import AppContentContainer from "components/common/layouts/appLayout/AppContentContainer";
import PageHeader from "components/common/layouts/appLayout/PageHeader";
import { useUsersSearch } from "components/users/hooks/useUsersList";
import useDebounce from "hooks/useDebounce";
import { CombinedUserTypes } from "models/user/attributes/userType";
import { PotentialSupervisorsList } from "components/supervisors/list/PotentialSupervisorsList";
import MiniLoader from "components/common/ui/loader/MiniLoader";
import HintBox from "components/common/ui/hint-box/HintBox";
import { IconWarning } from "components/common/icons/twoToneIcons";
import Container from "components/common/layouts/Container";
import Spacer from "components/common/ui/spacer/Spacer";
import { PotentialSupervisors } from "models/supervisors/Supervisor";
import { useSelector } from "react-redux";
import { currentUserSelector } from "store/selectors/core.selectors";
import { Facility } from "models/facility/Facility";

const defaultFilter = { type: CombinedUserTypes.fellow };

const ViewMyPotentialSupervisors = () => {
  const [filter] = useState<string>("");
  const currentUser = useSelector(currentUserSelector);
  const debouncedFilter = useDebounce(filter, 200);
  const { isLoading, data: supervisors } = useUsersSearch(
    debouncedFilter
      ? { ...defaultFilter, search: debouncedFilter }
      : defaultFilter
  );
  const [supervisorsList, setSupervisorsList] = useState<
    PotentialSupervisors[]
  >([]);

  useEffect(() => {
    supervisors?.forEach((s: PotentialSupervisors) => {
      s.metadata.facilities.length > 0 &&
        s.metadata.facilities.forEach((f: Facility) => {
          currentUser.facilities.forEach((facility) => {
            if (f.facility_id === facility.facility_id) {
              setSupervisorsList((oldArray) => [...oldArray, s]);
            }
          });
        });
    });
  }, [isLoading, currentUser.facilities, supervisors]);

  return (
    <AppContentContainer spacing="none">
      <Meta title={`Potential Supervisors`} />
      <PageHeader
        spacing="extra"
        spacingTopOnly
        header={"Potential Supervisors"}
      />
      <Container>
        <HintBox theme="success" icon={<IconWarning />}>
          Potential supervisors within your workplace !
        </HintBox>
        <Spacer height={10} />
        {isLoading ? (
          <MiniLoader theme="secondary" />
        ) : (
          <PotentialSupervisorsList
            supervisors={[...new Set(supervisorsList)]}
          />
        )}
      </Container>
    </AppContentContainer>
  );
};

export default ViewMyPotentialSupervisors;
