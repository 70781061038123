import React from "react";
import Container from "components/common/layouts/Container";
import FormattedQuestion from "components/common/ui/form-elements/display-elements/FormattedQuestion";
import {
  PeerGroupMeetingAttendees,
  peerGroupMeetingAttributesMap,
} from "models/peergroup/meeting";
import { usePeerGroupMeetingDetail } from "components/peergroup/hooks/usePeerGroupMeetingDetail";
import { usePeerGroupMembers } from "components/peergroup/hooks/usePeerGroupMembers";
import { useSelector } from "react-redux";
import { currentUserSelector } from "store/selectors/core.selectors";
import { permissionsSelector } from "store/selectors/auth.selectors";
import { canAdminEdit } from "utils/authUtils";
import FileItemPeerGroupMeeting from "components/peergroup/edit/FileItemPeerGroupMeeting";
import { isUserCurrentMemberOrMeetingAttendee } from "components/peergroup/utils/checkPeerGroupMembership";
import MiniLoader from "components/common/ui/loader/MiniLoader";
import { SUPPORT_EMAIL } from "constants/site";
import styles from "./PeerGroupMeetingDetail.module.scss";
import Spacer from "components/common/ui/spacer/Spacer";

interface Props {
  meetingId: string;
  peergroupId: string;
  showHeading?: boolean;
}
const PeerGroupMeetingDetail = ({
  meetingId,
  peergroupId,
  showHeading,
}: Props) => {
  const { data: meeting, isLoading } = usePeerGroupMeetingDetail(
    meetingId,
    peergroupId,
    {
      // intialData: initialMeeting,
      // placeholderData: initialMeeting,
    }
  );

  const { data: members, isLoading: memberLoading } =
    usePeerGroupMembers(peergroupId);
  const currentUser = useSelector(currentUserSelector);
  const permissions = useSelector(permissionsSelector);
  const isAdmin = canAdminEdit(permissions);
  const canAccessPeerGroupMeeting =
    isUserCurrentMemberOrMeetingAttendee(
      members?.accepted,
      meeting?.peergroup_meeting_attendees,
      currentUser.id
    ) || isAdmin;

  if (memberLoading) {
    return <MiniLoader theme={"secondary"} />;
  }

  return (
    <Container spacing={"small"} spacingTopOnly>
      {canAccessPeerGroupMeeting && showHeading && (
        <h1>{meeting?.peergroup_meeting_title}</h1>
      )}
      {canAccessPeerGroupMeeting ? (
        <div>
          <div className={styles.wrapRow}>
            <FormattedQuestion
              def={{ name: "peergroup", label: "Peer Group", type: "enum" }}
              questions={{ peergroup: meeting?.peergroup_name }}
              loading={isLoading}
            />
            {/***Note***:MeetingStatus field's size bigger than peergroup_meeting_venue. Put margin here so they can be at the same line*/}

            <FormattedQuestion
              def={peerGroupMeetingAttributesMap["peergroup_meeting_status"]}
              questions={meeting}
              loading={isLoading}
            />

            {meeting?.peergroup_meeting_venue && (
              <div style={{ marginTop: 8 }}>
                <FormattedQuestion
                  def={peerGroupMeetingAttributesMap["peergroup_meeting_venue"]}
                  questions={meeting}
                  loading={isLoading}
                />
              </div>
            )}
          </div>
          <div className={styles.wrapRow}>
            <FormattedQuestion
              def={peerGroupMeetingAttributesMap["peergroup_meeting_schedule"]}
              questions={meeting}
              loading={isLoading}
            />
            <FormattedQuestion
              def={peerGroupMeetingAttributesMap["peergroup_meeting_points"]}
              questions={meeting}
              loading={isLoading}
            />
          </div>
          <br />
          {meeting?.peergroup_meeting_topics?.length > 0 && (
            <FormattedQuestion
              def={peerGroupMeetingAttributesMap["peergroup_meeting_topics"]}
              questions={meeting}
              loading={isLoading}
            />
          )}
          {meeting?.peergroup_meeting_notes && (
            <FormattedQuestion
              def={peerGroupMeetingAttributesMap["peergroup_meeting_notes"]}
              questions={meeting}
              loading={isLoading}
            />
          )}
          <hr />
          <FormattedQuestion
            def={{
              name: "attendees",
              label: "Meeting Attendees",
              type: "enum[]",
              options: meeting?.peergroup_meeting_attendees?.map(
                (u: PeerGroupMeetingAttendees) => ({
                  value: u.user_id,
                  label: `${u.given_name} ${u.family_name}`,
                })
              ),
            }}
            questions={{
              attendees: meeting?.peergroup_meeting_attendees?.map(
                (u: PeerGroupMeetingAttendees) => u.user_id
              ),
            }}
          />
          <hr />
          <h5>Supporting Documents</h5>
          <br />
          <div className={styles.wrapRow}>
            {!!meeting?.peergroup_meeting_docs &&
            meeting?.peergroup_meeting_docs.length > 0 ? (
              meeting?.peergroup_meeting_docs.map((d: any) => (
                <FileItemPeerGroupMeeting
                  key={d.filename}
                  file={d.filename}
                  meetingId={meeting.id || ""}
                  peergroupId={peergroupId}
                />
              ))
            ) : (
              <i>No documents have been uploaded</i>
            )}
          </div>
        </div>
      ) : (
        <Container>
          <h1>Unable to load meeting details</h1>
          <p>
            Sorry, it seems you're no longer part of the Peer Group that this
            Meeting is in.
            <br />
            <br />
            If you believe you should still be part of this Peer Group, please
            contact <a href={`mailto:${SUPPORT_EMAIL}`}>Support</a>
          </p>
        </Container>
      )}
      <Spacer height={60} />
    </Container>
  );
};

export default PeerGroupMeetingDetail;
