import { PermissionGroups } from "models/user/Permissions";
import { ROOT, ROUTE_PARAMS } from "constants/routes/common";

const MY_SUPERVISEES = {
  path: `${ROOT}/supervisees`,
  requiredRole: PermissionGroups.fellow,
};

const MY_POTENTIAL_SUPERVISORS = {
  path: `${ROOT}/potential_supervisors`,
  requiredRole: PermissionGroups.registrar,
};

// const ADD_SUPERVISEE = {
//   path: `${MY_SUPERVISEES.path}/create`,
//   requiredRole: MY_SUPERVISEES.requiredRole,
// };

const SUPERVISEE_DETAIL = {
  path: `${MY_SUPERVISEES.path}/:${ROUTE_PARAMS.SUPERVISOR}/detail/:${ROUTE_PARAMS.SUPERVISEE}`,
  requiredRole: MY_SUPERVISEES.requiredRole,
};

const SUPERVISEE_PLAN_DETAIL = {
  path: `${SUPERVISEE_DETAIL.path}/plan/:${ROUTE_PARAMS.PLAN}`,
  requiredRole: MY_SUPERVISEES.requiredRole,
};

export const SUPERVISORS_ROUTES = {
  MY_SUPERVISEES,
  MY_POTENTIAL_SUPERVISORS,
  SUPERVISEE_DETAIL,
  SUPERVISEE_PLAN_DETAIL,
};
