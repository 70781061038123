import { DataAttribute } from "components/common/ui/form-elements/formElements";
import { UseFormRegister } from "react-hook-form/dist/types/form";
import { deepObjectAccessor } from "utils/common";
import TextInput from "components/common/ui/form-elements/text-input/TextField";
import styles from "./Address.module.scss";

interface Props {
  question: DataAttribute;
  register: UseFormRegister<any>;
  errors: Record<string, any>;
  defaultValue?: Record<string, any>;
}

export const Address = ({
  question,
  register,
  errors,
  defaultValue = {},
}: Props) => (
  <>
    <div className={styles.label}>{question.label}</div>
    <div className={styles.items}>
      {question.options?.map((d) => {
        const name = `${question.name}.${d.value}`;
        const error = deepObjectAccessor(errors, d.value);
        const dValue = deepObjectAccessor(defaultValue, d.value);
        return (
          <TextInput
            key={d.value}
            {...register(name, {
              // required: d.required && `${d.label} is required`,
            })}
            label={d.label}
            error={error}
            defaultValue={dValue || d.defaultValue}
            className={styles.input}
          />
        );
      })}
    </div>
  </>
);
