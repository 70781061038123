import UserPlanList from "components/cpd/common/UserPlanList";
import { ROUTE_PARAMS } from "constants/routes/common";
import { useParams } from "react-router";
import { useUserDetail } from "components/users/hooks/useUserDetail";
import { USER_ROUTES } from "constants/routes/user";
import { coreService } from "services/CoreService";

const ViewAdminPlansList = () => {
  const { [ROUTE_PARAMS.USER]: userId } =
    useParams<{ [ROUTE_PARAMS.USER]: string }>();

  const { isLoading, data: user } = useUserDetail(userId);

  return (
    <UserPlanList
      user={user}
      isLoading={isLoading}
      userId={userId}
      isAdminList
      backButton={{
        to: `${coreService.getRoute(USER_ROUTES.ADMIN_USER_DETAIL, {
          id: userId,
        })}#cpd`,
        label: `Back to ${
          user ? `${user.given_name} ${user.family_name}` : "Detail"
        }`,
      }}
    />
  );
};

export default ViewAdminPlansList;
