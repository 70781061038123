import React, { useLayoutEffect } from "react";
import cx from "classnames";
import { motion, useAnimation } from "framer-motion";
import MobileMenu from "components/common/layouts/appLayout/mobileMenu/MobileMenu";
import { useScroll } from "hooks/useScroll";
import { useHasChanged } from "hooks/useHasChanged";
import AppSettingBar from "components/common/layouts/appLayout/appSettingBar/AppSettingBar";
import BrandName from "components/common/ui/logo/BrandName";
import styles from "./MobileAppBar.module.scss";

interface Props {
  toggleMobileNav: () => void;
  isMobileOpened: boolean;
}

const MobileAppBar = ({ toggleMobileNav, isMobileOpened }: Props) => {
  const { top } = useScroll();
  const anim = useAnimation();
  const update = useHasChanged(isMobileOpened);

  useLayoutEffect(() => {
    if (update) {
      if (isMobileOpened) {
        sequence(top);
      } else {
        sequence(0);
      }
    }
    async function sequence(top: number) {
      await anim.start({
        top: top < 1 ? -52 : top,
        transition: { duration: 0 },
      });
      await anim.start({
        top: top < 1 ? 0 : top - 52,
        transition: {
          duration: 0.4,
          delay: top === 0 ? 0.2 : 0,
          ease: "easeInOut",
        },
      });
    }
  }, [isMobileOpened, anim, top, update]);

  return (
    <motion.header
      className={cx(
        styles.root,
        isMobileOpened && styles.open,
        top < 52 && styles.isTop,
        top > 0 && styles.isScrolled
      )}
      animate={anim}
      initial={{ top: 0 }}
    >
      <MobileMenu
        toggleMobileNav={toggleMobileNav}
        isMobileOpened={isMobileOpened}
      />
      {/*<DataCommonsLogo className={styles.logo} variant="dark" />*/}
      <BrandName
        className={cx(styles.brand, isMobileOpened && styles.fade)}
        variant="dark"
      />
      <AppSettingBar />
    </motion.header>
  );
};

export default MobileAppBar;
