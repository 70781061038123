import React, { useCallback, useMemo, useReducer, useState } from "react";
import { ActivityListShape } from "models/cpd/Activity";
import Modal from "components/common/ui/modal/Modal";
import { Plan, PLAN_STATUS, PlanYear } from "models/cpd/Plan";
import RequirementsProgress from "components/cpd/common/RequirementsProgress";
import { Goal } from "models/cpd/Goal";
import styles from "./PlanSuperviseeDetail.module.scss";
import { Tab, TabList, Tabs } from "components/common/ui/tabs/Tabs";
import Container from "components/common/layouts/Container";
import GoalDetail from "components/cpd/goal/GoalDetail";
import {
  calcPlanProgress,
  getPathwayCategoryOptions,
} from "components/cpd/utils/utils";
import { convertToDate, formatDate } from "utils/format/dateUtils";
import ActivityGantt from "components/cpd/plan/ActivityGantt";
import ToggleIconButton from "components/common/ui/button/toggle-icon-button/ToggleIconButton";
import IconChartGantt from "components/common/icons/IconChartGantt";
import IconList from "components/common/icons/IconList";
import { Category, PATHWAY } from "models/cpd/common/common";
import Select from "components/common/ui/select/Select";
import ActivityList from "components/cpd/plan/ActivityList";
import IconLock from "components/common/icons/IconLock";
import HintBox from "components/common/ui/hint-box/HintBox";
import ActivityDetail, {
  ActivityEditState,
} from "components/cpd/activity/ActivityDetail";
import OverdueActivity from "components/cpd/plan/OverdueActivity";

interface Props extends DefaultProps {
  activity: ActivityListShape;
  userId: string;
  plan: Plan;
  goals: Goal[];
}
interface GroupBy {
  id: string | Category;
  label?: string;
  keys: string[];
  goal?: Goal;
}

const PlanSuperviseeDetail = ({ plan, goals, activity, userId }: Props) => {
  const [tabState, setTabState] = useState(getInitialTabState(plan));

  const [activeActivity, setActiveActivity] = useState<
    ActivityEditState | undefined
  >(undefined);
  const [goalDetail, setGoalDetail] = useState<Goal | undefined>(undefined);
  const [layout, setLayout] = useReducer(layoutReducer, {
    group: "category",
    display: "gantt",
  });
  const resetActivty = useCallback(() => setActiveActivity(undefined), []);

  const currentYear =
    plan.progress?.years[tabState] || ({ year: tabState + 1 } as PlanYear);

  const planLocked = ![PLAN_STATUS.active, PLAN_STATUS.pending].includes(
    plan.plan_status
  );

  const req = useMemo(
    () =>
      calcPlanProgress(
        plan.requirements,
        activity,
        Math.ceil(plan.duration / 12)
      ),
    [plan, activity]
  );

  const goalGroup = mergeGoalsKeys(
    activity.keyByYearByGoal[currentYear.year] || [],
    goals
  );
  const categoryGroup = mergeCategory(
    activity.keyByYearByCategory[currentYear.year] || [],
    plan.pathway,
    Object.keys(plan.requirements) as Category[]
  );

  return (
    <div className={styles.root}>
      {/*<PlanStatus status={plan.plan_status}  />*/}
      <div className={styles.bgGrey}>
        <Container>
          <div className={styles.reqLabel}>
            Programme period Progress{" "}
            <span>
              {formatDate(plan.started_at)} -{" "}
              {formatDate(
                plan.progress?.years?.[Math.ceil(plan.duration / 12) - 1].end
              )}
            </span>
          </div>
          <RequirementsProgress
            progress={req.triennial}
            year={Math.ceil(plan.duration / 12)}
          />
          {req.overdue.length > 0 && (
            <>
              <div className={styles.reqLabel}>Overdue/Required Activity</div>
              <OverdueActivity
                activity={req.overdue}
                setActiveActivity={setActiveActivity}
              />
            </>
          )}
          <br />
        </Container>

        <Tabs state={[tabState, setTabState]}>
          <TabList decorator="none" tabBackground>
            {plan.progress?.years.map((y) => (
              <Tab key={y.year}>
                {y.locked && <IconLock className={styles.tabLock} />}Year{" "}
                {y.year}
              </Tab>
            ))}
            <div className={styles.spacer} />
            {/*<Tab>Summary</Tab>*/}
          </TabList>
        </Tabs>
      </div>
      <Container>
        {currentYear.locked && (
          <>
            <br />
            <HintBox icon={<IconLock />} theme="danger">
              <b>Year {currentYear.year} Locked</b>
              <br />
              No more activity can be added against this year
            </HintBox>
          </>
        )}
        <div className={styles.row}>
          <div className={styles.reqLabel}>
            Annual Progress <span>{currentYear?.label}</span>
          </div>
          <span className={styles.spacer} />
        </div>

        <RequirementsProgress progress={req.annual} year={tabState} annual />
      </Container>
      <hr className={styles.hr} />
      <Container>
        <div className={styles.row}>
          <h2>Activity</h2>
          <span className={styles.spacer} />
          <ToggleIconButton
            onIcon={<IconList />}
            offIcon={<IconChartGantt />}
            toggled={layout.display === "list"}
            setToggle={(t) => {
              setLayout({ type: "display", value: t ? "list" : "gantt" });
            }}
            tooltipContent={`Switch to ${
              layout.display === "gantt" ? "List" : "Timeline"
            } layout`}
            theme="primary"
          />
          <Select
            name={"pathway"}
            value={layout.group}
            onSelect={(d, v) => setLayout({ type: "group", value: v })}
            label={"Group By"}
            options={groupOptions}
            config={{
              noInputError: true,
              inputChip: true,
              selectedOptionFormat: "chip",
            }}
          />
        </div>
      </Container>
      {layout.display === "gantt" ? (
        <Container className={styles.gantt}>
          <ActivityGantt
            activities={activity.map}
            groups={layout.group === "goal_id" ? goalGroup : categoryGroup}
            startDate={convertToDate(currentYear.start) || plan.started_at}
            setEditting={setActiveActivity}
            locked={currentYear.locked || planLocked}
          />
        </Container>
      ) : (
        <ActivityList
          activity={activity.map}
          group={layout.group === "goal_id" ? goalGroup : categoryGroup}
          groupKey={layout.group}
          setActivityEdit={setActiveActivity}
          setGoalDetail={setGoalDetail}
          defaultActivityFields={{
            year: currentYear.year,
          }}
          locked={false}
        />
      )}

      <Modal open={!!activeActivity} onClose={resetActivty}>
        <ActivityDetail
          onCancel={resetActivty}
          goals={goals || []}
          userId={userId}
          planId={plan.plan_id}
          year={currentYear}
          editing={false}
          {...activeActivity}
          disabled={planLocked}
        />
      </Modal>
      <Modal open={!!goalDetail} onClose={() => setGoalDetail(undefined)}>
        <GoalDetail
          goal={goalDetail}
          userId={userId}
          editting={false}
          onCancel={() => setGoalDetail(undefined)}
        />
      </Modal>
      <br />
    </div>
  );
};

interface LayoutState {
  display: "list" | "gantt";
  group: "goal_id" | "category";
}
const groupOptions = [
  {
    key: "goal_id",
    label: "Goal",
  },
  {
    key: "category",
    label: "Activity Type",
  },
];

const layoutReducer = (
  state: LayoutState,
  action:
    | { type: "display"; value: "list" | "gantt" }
    | { type: "group"; value: "goal_id" | "category" }
) => {
  switch (action.type) {
    case "display":
      return { ...state, display: action.value };
    case "group":
      return { ...state, group: action.value };
  }
};

const mergeGoalsKeys = (
  activityGoals: Record<string, string[]>,
  goals: Goal[]
) => {
  const merge = goals
    .map((g) => {
      const aKeys = activityGoals[g.goal_id];
      if (aKeys || g.goal_status !== "complete") {
        return {
          id: g.goal_id,
          label: g.goal_name,
          keys: aKeys || [],
          goal: g,
        };
      }
      return undefined;
    })
    .filter((g) => !!g) as GroupBy[];
  if (activityGoals["__undefined__"]) {
    merge.push({
      id: "__undefined__",
      // label: "Unassigned",
      keys: activityGoals["__undefined__"] || [],
    });
  }
  return merge;
};

const mergeCategory = (
  activityCategories: Record<string, string[]>,
  pathway: PATHWAY,
  requiredCategories?: Category[]
) => {
  const categories = getPathwayCategoryOptions(pathway, requiredCategories).map(
    ({ value, label }) => ({
      id: value,
      label,
      keys: activityCategories[value] || [],
    })
  );
  if (activityCategories["__undefined__"]) {
    categories.push({
      //@ts-ignore
      id: "__undefined__",
      keys: activityCategories["__undefined__"] || [],
    });
  }
  return categories as GroupBy[];
};

const getInitialTabState = (plan: Plan) => {
  const unlockedYear =
    plan.progress && plan.progress?.years.findIndex((y) => !y.locked);
  if (unlockedYear !== undefined && unlockedYear >= 0) {
    return unlockedYear;
  }
  const lastYear = (plan.progress?.years.length || 1) - 1;
  return lastYear >= 0 ? lastYear : 0;
};

export default PlanSuperviseeDetail;
