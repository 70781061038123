import { createContext, useContext } from "react";
import { TUser } from "models/user/User";

interface IUserContext {
  user?: TUser;
}

export const UserContext = createContext<IUserContext>({
  user: undefined,
});

export const useUserContext = () => {
  return useContext(UserContext);
};
