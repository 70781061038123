import { PeerGroupMember } from "models/peergroup/member";
import { PeerGroupMeetingAttendees } from "models/peergroup/meeting";

export const checkPeerGroupMembership = (p?: PeerGroupMember) =>
  !!p?.peergroup_member_roles?.find((r) => r.key === "Coordinator");

export const isCurrentUserPeerGroupCoordinator = (
  members?: PeerGroupMember[],
  currentUserId?: string
) =>
  checkPeerGroupMembership(members?.find((m) => m.user_id === currentUserId));

export const isCurrentUserMemberOfPeerGroup = (
  members?: PeerGroupMember[],
  currentUserId?: string
) => !!members?.find((m) => m.user_id === currentUserId);

export const isUserCurrentMemberOrMeetingAttendee = (
  members?: PeerGroupMember[],
  attendees?: PeerGroupMeetingAttendees[],
  currentUserId?: string
) =>
  isCurrentUserMemberOfPeerGroup(members, currentUserId) ||
  isCurrentUserMemberOfPeerGroup(attendees, currentUserId);
