import { ADMIN_ROOT, ROOT } from "constants/routes/common";
import { PermissionGroups, TRequiredPermission } from "models/user/Permissions";
import { USER_ROUTES } from "constants/routes/user";
import { FACILITY_ROUTES } from "constants/routes/facility";
import { CPD_ROUTES } from "constants/routes/cpd";
import { PEERGROUP_ROUTES } from "constants/routes/peergroup";
import { SUPERVISORS_ROUTES } from "constants/routes/supervisors";

//------------------------------------------------------------------------------

export const HOME = { path: `${ROOT}/` };

export const LEARNING_RESOURCES = {
  path: `${ROOT}/learning-resources`,
  requiredRole: PermissionGroups.disabled,
};

export const ADMIN_REPORTS = {
  path: `${ADMIN_ROOT}/reports`,
  requiredRole: PermissionGroups.disabled,
};

export const TESTING = { path: `${ROOT}/testing` };

export const SCRIPTS = { path: `${ROOT}/scripts` };

//------------------------------------------------------------------------------
export type TRoute = {
  path: string;
  href?: string;
  requiredRole?: TRequiredPermission;
};
export const PRIVATE_ROUTE_DETAILS = {
  HOME,
  ADMIN_REPORTS,
  LEARNING_RESOURCES,

  ...USER_ROUTES,
  ...FACILITY_ROUTES,
  ...CPD_ROUTES,
  ...PEERGROUP_ROUTES,
  ...SUPERVISORS_ROUTES,

  TESTING,
  SCRIPTS,
} as Record<string, TRoute>;

export const PRIVATE_ROUTES = Object.keys(PRIVATE_ROUTE_DETAILS).reduce(
  (o, k) => {
    return { ...o, [k]: PRIVATE_ROUTE_DETAILS[k].path };
  },
  {}
) as Record<string, string>;

export default { ...PRIVATE_ROUTES } as Record<string, string>;
