import React from "react";
import ReactDatePicker, { ReactDatePickerProps } from "react-datepicker";
import cx from "classnames";
import "react-datepicker/dist/react-datepicker.css";
import IconExpandArrow from "components/common/icons/IconExpandArrow";
import {
  InputWrapper,
  InputWrapperProps,
} from "components/common/ui/form-elements/common/InputWrapper";
import IconCalendar from "components/common/icons/IconCalendar";
import { isValidDate } from "utils/format/dateUtils";
import Chip from "components/common/ui/chip/Chip";
import ActiveOptionChip from "components/common/ui/select/ActiveOptionChip";
import styles from "./DatePicker.module.scss";

export interface DatePickerProps
  extends Omit<
      ReactDatePickerProps,
      "value" | "selected" | "placeholderText" | "name" | "selectsRange"
    >,
    Omit<InputWrapperProps, "onChange" | "onBlur" | "config"> {
  name: string;
  dateFormat?: string;
  value: Date | string | null;
  selectsRange?: boolean;
  config?: IPickerConfig;
}

export interface IPickerConfig {
  chip?: boolean;
  menuOffset?: number;
  // menuWidth?: "fit-content" | "parent" | number;
  // selectedOptionFormat?: "chip" | "text";
  noInputError?: boolean;
  theme?: ThemeVariants;
  chipVariant?: FillVariants;
  // rounded?: boolean;
  // condensed?: boolean;
}
const defaultConfig = {};

export const DatePicker = React.forwardRef(
  (
    {
      value,
      placeholder = "DD/MM/YYYY",
      dateFormat = "dd/MM/yyyy",
      config: { chip, ...config } = defaultConfig,
      icon = IconCalendar,
      showPopperArrow = false,
      ...rest
    }: DatePickerProps,
    ref: React.Ref<any>
  ) => {
    const v = isValidDate(value) ? new Date(value) : null;
    return chip ? (
      <ChipDatePicker
        ref={ref}
        {...rest}
        value={v}
        placeholder={placeholder}
        dateFormat={dateFormat}
        icon={icon}
        showPopperArrow={showPopperArrow}
        config={config}
      />
    ) : (
      <InputDatePicker
        ref={ref}
        {...rest}
        value={v}
        placeholder={placeholder}
        dateFormat={dateFormat}
        icon={icon}
        showPopperArrow={showPopperArrow}
        config={config}
      />
    );
  }
);

interface InternalProps extends Omit<DatePickerProps, "value"> {
  value: Date | null;
}

const InputDatePicker = React.forwardRef(
  (
    {
      name,
      label,
      description,
      hint,
      placeholder = "DD/MM/YYYY",
      dateFormat = "dd/MM/yyyy",
      onChange,
      value,
      error,
      config: { chip, ...config } = defaultConfig,
      className,
      disabled,
      icon = IconCalendar,
      showPopperArrow = false,
      ...rest
    }: InternalProps,
    ref: React.Ref<any>
  ) => {
    return (
      <InputWrapper
        name={name}
        error={error}
        hint={hint}
        label={label}
        description={description}
        className={className}
        disabled={disabled}
        config={config}
        icon={icon}
      >
        <div className={styles.inputWrapper}>
          <ReactDatePicker
            ref={ref}
            className={styles.input}
            name={name}
            placeholderText={!disabled ? placeholder : ""}
            onChange={onChange}
            selected={value}
            dateFormat={dateFormat}
            popperClassName={styles.pickerPopper}
            portalId="layers"
            showPopperArrow={showPopperArrow}
            disabled={disabled}
            popperModifiers={[
              {
                name: "offset",
                options: {
                  offset: [0, -4],
                },
              },
            ]}
            {...rest}
          />
          {placeholder !== "DD/MM/YYYY - DD/MM/YYYY" && !disabled && (
            <IconExpandArrow className={styles.expand} />
          )}
        </div>
      </InputWrapper>
    );
  }
);

const ChipDatePicker = React.forwardRef(
  (
    {
      name,
      label,
      description,
      hint,
      placeholder,
      dateFormat,
      onChange,
      value,
      error,
      config: { chip, ...config } = defaultConfig,
      className,
      disabled,
      showPopperArrow = false,
      icon: Icon = IconCalendar,
      isClearable,
      ...rest
    }: InternalProps,
    ref: React.Ref<any>
  ) => {
    const ChipButton = React.forwardRef(
      (
        {
          value,
          className,
          ...chipProps
        }: { value?: string | null; className?: string },
        ref: React.Ref<any>
      ) => (
        <Chip
          ref={ref}
          icon={<Icon />}
          iconVariant="empty"
          variant={config.chipVariant}
          condensed
          {...chipProps}
          className={cx(styles.chip, className)}
        >
          <label className={styles.label} htmlFor={name}>
            {label}
          </label>
          {value ? (
            <ActiveOptionChip
              id="date"
              key="date-option"
              cancelIcon={isClearable}
              onClick={
                isClearable
                  ? () => {
                      onChange(null, undefined);
                    }
                  : undefined
              }
            >
              {value}
            </ActiveOptionChip>
          ) : (
            <span className={styles.placeholder}>
              {!disabled && placeholder}
            </span>
          )}
          {placeholder !== "DD/MM/YYYY - DD/MM/YYYY" && (
            <IconExpandArrow className={styles.expand} />
          )}
        </Chip>
      )
    );
    return (
      <ReactDatePicker
        ref={ref}
        customInput={<ChipButton />}
        className={styles.chipInput}
        name={name}
        placeholderText={!disabled ? placeholder : ""}
        onChange={onChange}
        selected={value}
        dateFormat={dateFormat}
        popperClassName={styles.pickerPopper}
        portalId="layers"
        showPopperArrow={showPopperArrow}
        disabled={disabled}
        popperModifiers={[
          {
            name: "offset",
            options: {
              offset: [0, -4],
            },
          },
        ]}
        {...rest}
      />
    );
  }
);
