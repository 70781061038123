import React, { useState } from "react";
import PlanSuperviseeDetail from "components/supervisors/detail/PlanSuperviseeDetail";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import { isAppLoadedSelector } from "store/selectors/core.selectors";
import { isDimensionsLoadingSelector } from "store/selectors/dimensions.selectors";
import { coreService } from "services/CoreService";
import Meta from "components/common/Meta";
import PageHeader from "components/common/layouts/appLayout/PageHeader";
import Button from "components/common/ui/button/Button";
import MiniLoader from "components/common/ui/loader/MiniLoader";
import Modal from "components/common/ui/modal/Modal";
import { formatDate } from "utils/format/dateUtils";
import Requirements from "components/cpd/common/Requirements";
import Goals from "components/cpd/goal/Goals";
import { AppContentContainer } from "components/common/layouts/appLayout/AppContentContainer";
import { SUPERVISORS_ROUTES } from "constants/routes/supervisors";
import { useSuperviseePlanDetail } from "./hooks/useSuperviseePlanDetail";

const ViewSuperviseePlanDetail = () => {
  const { supervisorId } = useParams<{ supervisorId: string }>();
  const { superviseeId } = useParams<{ superviseeId: string }>();
  const { planId } = useParams<{ planId: string }>();
  const isAppLoaded = useSelector(isAppLoadedSelector);
  const isDimLoading = useSelector(isDimensionsLoadingSelector);
  const enabled = isAppLoaded || !isDimLoading;

  const [showReq, setShowReq] = useState(false);
  const [showGoals, setShowGoals] = useState(false);

  const { isLoading: planDetailLoading, data: superviseePlanDetail } =
    useSuperviseePlanDetail(supervisorId, superviseeId, planId, {
      enabled,
    });

  return (
    <AppContentContainer
      backButton={{
        to: coreService.getRoute(SUPERVISORS_ROUTES.SUPERVISEE_DETAIL, {
          supervisorId: supervisorId,
          superviseeId: superviseeId,
        }),
        label: "Back to Supervisee detail",
      }}
      spacing="none"
    >
      <Meta title={"Plan Detail"} />
      <PageHeader
        spacing="extra"
        spacingTopOnly
        header={`Plan Detail`}
        children={""}
        actions={
          planDetailLoading
            ? []
            : [
                <>
                  <Button
                    theme="primary"
                    variant="outline"
                    onClick={() => setShowReq(true)}
                  >
                    View Requirements
                  </Button>
                  <Button
                    theme="secondary"
                    variant="outline"
                    onClick={() => setShowGoals(true)}
                  >
                    View Goals
                  </Button>
                </>,
              ]
        }
      />

      {planDetailLoading ? (
        <MiniLoader theme="secondary" />
      ) : (
        <>
          <PlanSuperviseeDetail
            activity={superviseePlanDetail?.activities || blankActivities}
            userId={superviseeId}
            plan={superviseePlanDetail?.plan}
            goals={superviseePlanDetail?.goals}
          />

          <Modal open={showReq} onClose={() => setShowReq(false)}>
            <h1 style={{ marginBottom: 0 }}>Requirements</h1>
            <i>
              The table below describes your requirements between{" "}
              {formatDate(superviseePlanDetail?.plan?.started_at)} and{" "}
              {formatDate(
                superviseePlanDetail?.plan?.progress?.years[
                  superviseePlanDetail?.plan?.progress?.years?.length - 1
                ]?.end
              )}
              .
              <br />
              Annual requirements must be completed each year, while Programme
              requirements are tracked over the course of the entire CPD cycle
            </i>
            <br />
            <Requirements
              setRequirements={() => undefined}
              requirements={superviseePlanDetail?.plan?.requirements}
            />
          </Modal>
          <Modal open={showGoals} onClose={() => setShowGoals(false)}>
            <Goals goals={superviseePlanDetail?.goals} userId={superviseeId} />
          </Modal>
        </>
      )}
    </AppContentContainer>
  );
};
export default ViewSuperviseePlanDetail;

const blankActivities = {
  map: {},
  keys: [],
  keyByCategory: {},
  keyByYear: {},
  keyByYearByGoal: {},
  keyByYearByCategory: {},
};
