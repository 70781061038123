import { useMutation, useQueryClient } from "react-query";
import { goalQueryKeys } from "queries/constants";
import { MutationOptions } from "queries/queries";
import { useToast } from "components/common/ui/toast/useToast";
import { saveGoal } from "api/CpdApi";
import { createGoal, Goal } from "models/cpd/Goal";

export const useMutateGoal = (options: MutationOptions = {}) => {
  const queryClient = useQueryClient();
  const { addErrorToast, addToast } = useToast();

  return useMutation(
    ({ newGoal, oldGoal = createGoal(), userId }: useMutateGoalProps) =>
      saveGoal(newGoal, oldGoal, userId),
    {
      onSuccess: (result, inputs) => {
        addToast(
          `Goal was successfully ${
            inputs.oldGoal?.goal_id ? "updated" : "created"
          }!`,
          {
            theme: "success",
          }
        );

        if (inputs.oldGoal?.goal_id) {
          queryClient.invalidateQueries(
            goalQueryKeys.detail(inputs.oldGoal.goal_id)
          );
        }

        queryClient.invalidateQueries(
          goalQueryKeys.list({ userId: inputs.userId })
        );
      },
      onError: (e) => {
        addErrorToast(e, "Error: Unable to save Goal");
      },
      ...options,
    }
  );
};

interface useMutateGoalProps {
  newGoal: Partial<Goal>;
  oldGoal?: Goal;
  userId: string;
}
