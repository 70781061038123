import React from "react";
import cx from "classnames";
import {
  DatePicker,
  DatePickerProps,
} from "components/common/ui/form-elements/date-picker/DatePicker";
import { isValidDate } from "utils/format/dateUtils";
import styles from "./DatePicker.module.scss";

interface Props extends Omit<DatePickerProps, "value" | "onChange"> {
  value:
    | [Date | string | null, Date | string | null]
    | /* for selectsRange */ null;
  onChange: (
    r: [Date | null, Date | null] | /* for selectsRange */ null
  ) => void;
}

export const DateRangePicker = React.forwardRef(
  (
    {
      value = [null, null],
      placeholder = "DD/MM/YYYY - DD/MM/YYYY",
      className,
      onChange,
      ...rest
    }: Props,
    ref: React.Ref<any>
  ) => {
    const startDate =
      value && isValidDate(value[0]) ? new Date(value[0]) : null;
    const endDate = value && isValidDate(value[1]) ? new Date(value[1]) : null;
    return (
      <DatePicker
        ref={ref}
        {...rest}
        className={cx(className, styles.rangeWrapper)}
        value={null}
        selectsRange
        //@ts-ignore because `selectsRange===true` onChange will always return a [Date, Date], never a single Date
        onChange={onChange}
        startDate={startDate}
        endDate={endDate}
        placeholder={placeholder}
        isClearable={true}
      />
    );
  }
);

// export const DateRangePicker = React.forwardRef(({
//                                     placeholder = "DD/MM/YYYY - DD/MM/YYYY",
//                                     dateFormat = "dd/MM/yyyy",
//                                     icon = IconCalendar,
//                                     hint, name,
//                                     label, description,
//                                     className,
//     showPopperArrow = false,
//     error, disabled, config,
//     value, onChange, ...rest
//                                 }: Props,
//     ref: React.Ref<any>) => {
//     const [startDate, endDate] = value ||[null, null];
//     return (
//         <InputWrapper
//             name={name}
//             error={error}
//             hint={hint}
//             label={label}
//             description={description}
//             className={className}
//             disabled={disabled}
//             config={config}
//             icon={icon}
//         >
//             <div className={cx(styles.inputWrapper, styles.rangeWrapper)}>
//                 <ReactDatePicker
//                     ref={ref}
//                     className={styles.input}
//                     name={name}
//                     placeholderText={placeholder}
//                     onChange={onChange}
//                     selectsRange={true}
//                     startDate={startDate}
//                     endDate={endDate}
//                     dateFormat={dateFormat}
//                     popperClassName={styles.pickerPopper}
//                     portalId="layers"
//                     isClearable={true}
//                    showPopperArrow={showPopperArrow}
//             disabled={disabled}
//             popperModifiers={[
//               {
//                 name: "offset",
//                 options: {
//                   offset: [0, -4],
//                 },
//               },
//             ]}
//             {...rest}
//                 />
//                 {/*<IconExpandArrow className={styles.expand} />*/}
//             </div>
//         </InputWrapper>
//     );
// });
