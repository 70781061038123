import React, { useState } from "react";
import { TUser } from "models/user/User";
import Table from "components/common/ui/table/Table";
import { IColumn } from "components/common/ui/table/utils";
import { PeerGroup } from "models/peergroup/PeerGroup";
import { PeerGroupMember } from "models/peergroup/member";
import IconDelete, { IconRestore } from "components/common/icons/IconDelete";
import Modal from "components/common/ui/modal/Modal";
import Accordian from "components/common/ui/accordian/Accordian";
import { IconWarning } from "components/common/icons/twoToneIcons";
import HintBox from "components/common/ui/hint-box/HintBox";
import { Avatar } from "components/common/ui/avatar/Avatar";
import Tooltip from "components/common/ui/tooltip/Tooltip";
import ActionButton from "components/common/ui/button/ActionButton";
import IconEditUser from "components/common/icons/IconEditUser";
import AddMemberToPeerGroup from "../create/add-member/AddMemberToPeerGroup";
import Chip from "components/common/ui/chip/Chip";
import { checkPeerGroupMembership } from "components/peergroup/utils/checkPeerGroupMembership";
import { DeleteMemberModal } from "components/peergroup/members/DeleteMemberModal";
import styles from "components/users/admin/list/UsersList.module.scss";

interface Props {
  // users: PeerGroupMember[];
  users: Record<Partial<"accepted" | "deleted">, PeerGroupMember[]>;
  loading?: boolean;
  actionCell?: (
    cell: string,
    row: TUser,
    column: IColumn<TUser>
  ) => React.ReactNode;
  canEditPeerGroup?: boolean;
  peergroupDetail: PeerGroup;
}

const UsersMemberList = ({
  users,
  loading,
  canEditPeerGroup,
  peergroupDetail,
}: Props) => {
  const [memberToDelete, setMemberToDelete] = useState<
    PeerGroupMember | undefined
  >();
  const [selectedEditItem, setSelectedEditItem] =
    useState<PeerGroupMember | null>();
  const [open, setOpen] = useState(false);

  const columns = getColumns(
    setMemberToDelete,
    setSelectedEditItem,
    setOpen,
    canEditPeerGroup
  );

  const coordinators = users.accepted?.find((u: PeerGroupMember) =>
    checkPeerGroupMembership(u)
  );

  return (
    <div className={styles.root}>
      {canEditPeerGroup && !coordinators && (
        <>
          <HintBox theme="danger" icon={<IconWarning />}>
            <b>Peer Group doesn't have a Coordinator!</b>
            <br />
            If a Peer Group doesn't have at least one Coordinator, then no one
            will be able to manage the Peer Group members. Click on the button
            in the top right to add a new member as a Coordinator.
          </HintBox>
          <br />
        </>
      )}
      <Table
        className={styles.table}
        columns={columns}
        data={users.accepted ?? []}
        loading={loading}
        // loading={true}
        placeholder={{ noDataLabel: "No Users Found", loadingRows: 5 }}
      />

      {(users.deleted || []).length > 0 && canEditPeerGroup && (
        <Accordian
          headerClassName={styles.deletedAccordian}
          header={<h4>Deleted Members</h4>}
        >
          <Table
            className={styles.table}
            columns={columns}
            data={users.deleted}
            loading={loading}
            // loading={true}
            placeholder={{ noDataLabel: "No Users Found", loadingRows: 5 }}
          />
        </Accordian>
      )}

      <DeleteMemberModal
        member={memberToDelete}
        peergroupId={peergroupDetail.id}
        onClose={() => setMemberToDelete(undefined)}
      />

      <Modal open={open} onClose={() => setOpen(false)}>
        <h2>
          Update Peer Group role for {selectedEditItem?.given_name}{" "}
          {selectedEditItem?.family_name}{" "}
        </h2>
        <br />
        <AddMemberToPeerGroup
          peergroup={peergroupDetail}
          onCancel={() => setOpen(false)}
          currentMember={selectedEditItem ?? undefined}
        />
      </Modal>
    </div>
  );
};

const getColumns = (
  setSelectedItem: (row: PeerGroupMember) => void,
  setSelectedEditItem: (row: PeerGroupMember) => void,
  setOpen: (d: boolean) => void,
  canEditPeerGroup?: boolean
) => {
  const columns = [
    {
      id: "avatar",
      label: " ",
      type: "icon",
      width: "56px",
      cell: (c, row: PeerGroupMember) => (
        <Avatar
          user={{
            id: row.user_id,
            family_name: row.family_name,
            given_name: row.given_name,
          }}
        />
      ),
    },
    {
      id: "name",
      label: "Name",
      type: "textEmphasis",
      cell: (c, row: PeerGroupMember) => `${row.given_name} ${row.family_name}`,
    },
    {
      label: "Email",
      id: "email",
      cell: (c, row: PeerGroupMember) => (
        <a href={`mailto:${row.email}`}>{row.email}</a>
      ),
    },
    {
      label: "Roles",
      id: "roles",
      cell: (c, row: PeerGroupMember) => (
        <span style={{ display: "flex" }}>
          {row.peergroup_member_roles?.map((d) => (
            <Chip
              key={d.value}
              theme={d.key === "Coordinator" ? "primary" : "secondary"}
            >
              {d.key}
            </Chip>
          ))}
        </span>
      ),
    },
  ] as IColumn<PeerGroupMember>[];

  if (canEditPeerGroup) {
    columns.push(
      {
        id: "editRole",
        label: " ",
        width: "70px",
        type: "icon",
        className: styles.iconCol,
        cell: (c, row: PeerGroupMember) => (
          <Tooltip content={"Edit role"} key="edit">
            <ActionButton
              icon={<IconEditUser />}
              onClick={() => {
                setOpen(true);
                setSelectedEditItem(row);
              }}
              variant="empty"
              theme={"info"}
            />
          </Tooltip>
        ),
      },
      {
        id: "link",
        label: " ",
        width: "70px",
        type: "icon",
        className: styles.iconCol,
        cell: (c, row: PeerGroupMember) =>
          row.peergroup_member_deleted === 1 ? (
            <Tooltip content={"Restore"} key="restore">
              <ActionButton
                icon={<IconRestore />}
                onClick={() => setSelectedItem(row)}
                variant="empty"
                theme={"info"}
              />
            </Tooltip>
          ) : (
            <Tooltip content={"Delete"} key="delete">
              <ActionButton
                icon={<IconDelete />}
                onClick={() => setSelectedItem(row)}
                variant="empty"
                theme={"danger"}
              />
            </Tooltip>
          ),
      }
    );
  }

  return columns;
};

export default UsersMemberList;
