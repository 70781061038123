import { Activity } from "models/cpd/Activity";
import React, { useReducer, useState } from "react";
import Container from "components/common/layouts/Container";
import ToggleIconButton from "components/common/ui/button/toggle-icon-button/ToggleIconButton";
import IconList from "components/common/icons/IconList";
import IconChartGantt from "components/common/icons/IconChartGantt";
import Select from "components/common/ui/select/Select";
import ActivityGantt from "components/cpd/plan/ActivityGantt";
import { convertToDate } from "utils/format/dateUtils";
import ActivityList from "components/cpd/plan/ActivityList";
import {
  groupOptions,
  layoutReducer,
  mergeCategory,
  mergeGoalsKeys,
} from "components/cpd/utils/planUtils";
import { Category } from "models/cpd/common/common";
import { Supervisee } from "models/supervisors/Supervisee";
import { Plan, PlanYear } from "models/cpd/Plan";
import { ReadOnlyActivityDetail } from "components/supervisors/detail/ReadOnlyActivityDetail";
import styles from "components/cpd/plan/PlanDetail.module.scss";

export const ReadOnlyPlan = ({
  user,
  plan,
  currentYear,
}: {
  user: Supervisee;
  plan: Plan;
  currentYear: PlanYear;
}) => {
  const [layout, setLayout] = useReducer(layoutReducer, {
    group: "category",
    display: "gantt",
  });
  const [activeActivity, setActiveActivity] = useState<Activity | undefined>(
    undefined
  );

  const goalGroup = mergeGoalsKeys(
    user.activities.keyByYearByGoal[currentYear.year] || [],
    user.goals || []
  );
  const categoryGroup = mergeCategory(
    user.activities.keyByYearByCategory[currentYear.year] || [],
    plan.pathway,
    Object.keys(plan.requirements) as Category[]
  );

  return (
    <div>
      <hr className={styles.hr} />
      <Container>
        <div className={styles.row}>
          <span className={styles.spacer} />
          <ToggleIconButton
            onIcon={<IconList />}
            offIcon={<IconChartGantt />}
            toggled={layout.display === "list"}
            setToggle={(t) => {
              setLayout({ type: "display", value: t ? "list" : "gantt" });
            }}
            tooltipContent={`Switch to ${
              layout.display === "gantt" ? "List" : "Timeline"
            } layout`}
            theme="primary"
          />
          <Select
            name={"pathway"}
            value={layout.group}
            onSelect={(d, v) => setLayout({ type: "group", value: v })}
            label={"Group By"}
            options={groupOptions}
            config={{
              noInputError: true,
              inputChip: true,
              selectedOptionFormat: "chip",
            }}
          />
        </div>
      </Container>
      {layout.display === "gantt" ? (
        <Container className={styles.gantt}>
          <ActivityGantt
            activities={user.activities.map}
            groups={layout.group === "goal_id" ? goalGroup : categoryGroup}
            startDate={convertToDate(currentYear.start) || plan.started_at}
            setEditting={(s) => setActiveActivity(s?.activity as Activity)} //we can coerce the Partial to full Activity, as will always be a full Activity object
            locked={currentYear.locked}
          />
        </Container>
      ) : (
        <ActivityList
          activity={user.activities.map}
          group={layout.group === "goal_id" ? goalGroup : categoryGroup}
          groupKey={layout.group}
          setActivityEdit={(s) => setActiveActivity(s?.activity as Activity)}
          setGoalDetail={() => {}}
          locked={currentYear.locked}
        />
      )}
      <ReadOnlyActivityDetail
        userId={user.id}
        resetActivty={() => setActiveActivity(undefined)}
        activity={activeActivity}
        goals={user.goals}
      />
    </div>
  );
};
