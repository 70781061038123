import { keyBy } from "utils/common";
import { DataAttribute } from "components/common/ui/form-elements/formElements";
import * as Yup from "yup";
import { getDefaultValues, getValidations } from "utils/formUtils";

export interface FacilityAttribute extends DataAttribute {
  warning?: any; // UI display warning if not completed
  tracked?: boolean; //must track changes to this field (as a history)
}

export const facilityTypeOptions = [
  {
    value: "ucc",
    label: "UCC",
  },
  {
    value: "ucc_gp",
    label: "UCC+GP",
  },
  {
    value: "gp",
    label: "GP",
  },
  {
    value: "ed_large",
    label: "ED (large)",
  },
  {
    value: "ed_rural",
    label: "ED (rural)",
  },
];

export const facilityAttributes: FacilityAttribute[] = [
  {
    name: "facility_name",
    type: "string",
    label: "Facility Name",
    required: true,
    validation: Yup.string().required("Please enter facility name"),
  },
  {
    name: "facility_type",
    type: "enum",
    label: "Facility Type",
    defaultValue: "ucc",
    options: facilityTypeOptions,
    validation: Yup.string()
      .required("Please select a facility type")
      .nullable(),
  },
  {
    name: "facility_status",
    type: "enum",
    label: "Facility Status",
    required: true,
    defaultValue: "active",
    options: [
      {
        value: "active",
        label: "Active",
      },
      {
        value: "inactive",
        label: "Inactive",
      },
    ],
    validation: Yup.string()
      .required("Please select a facility status")
      .nullable(),
  },
  {
    name: "hpi_facility_id",
    type: "string",
    label: "Hpi Facility Id",
    // required: true,
    // validation: Yup.string().required("Please enter Hpi Facility Id"),
  },
  {
    name: "hpi_organisation_id",
    type: "string",
    label: "Hpi Organisation Id",
    // required: true,
    // validation: Yup.string().required("Please enter Hpi Organisation Id"),
  },
  {
    name: "approved_training",
    type: "enum",
    label: "Training Approved",
    required: true,
    defaultValue: "false",
    options: [
      { value: "true", label: "Yes" },
      { value: "false", label: "No" },
    ],
    // validation: Yup.string().required("Please choose approved training option"),
  },
  {
    name: "review_date",
    label: "Training Approved Review Date",
    type: "date",
    conditional: [{ field: "approved_training", values: ["true"] }],
    // required: true,
    // validation: Yup.string().required(
    //   "Please enter training approved review date"
    // ),
  },
  {
    name: "facility_alias",
    type: "string",
    label: "Facility Alias",
    description:
      "Alternative name/spelling for the facility - used when searching by facility name",
  },
  {
    name: "services_provided",
    type: "string",
    label: "Services Provided",
    layout: {
      type: "textArea",
    },
    // required: true,
    // validation: Yup.string().required("Please enter HServices Provided"),
  },
  {
    name: "ucc_workplace",
    type: "enum",
    label: "UCC workplace",
    required: true,
    defaultValue: "true",
    options: [
      { value: "true", label: "Yes" },
      { value: "false", label: "No" },
    ],
    validation: Yup.string().required("Please choose Ucc workplace option"),
  },
];

export const defaultFacilityValues = getDefaultValues(facilityAttributes);
export const facilityValidations = getValidations(facilityAttributes);

export const facilityAttributesMap = {
  ...keyBy(facilityAttributes, "name"),
} as const;

export const facilityAttributeKeys = Object.keys(facilityAttributesMap);
