import React from "react";
import cx from "classnames";
import type { Editor } from "@tiptap/react/src/Editor";
import styles from "components/common/ui/form-elements/rich-text-editor/MenuBar.module.scss";
import ActionButton from "components/common/ui/button/ActionButton";
import Tooltip from "components/common/ui/tooltip/Tooltip";
import {
  IconBold,
  IconBulletList,
  IconClear,
  IconHeading1,
  IconHeading2,
  IconHeading3,
  IconHeading4,
  IconHeading5,
  IconHeading6,
  IconHorizontalRule,
  IconItalic,
  IconOrderList,
  IconParagraph,
  IconQuote,
  IconRedo,
  IconStrike,
  IconUndo,
} from "components/common/ui/form-elements/rich-text-editor/editor-icons/EditorIcons";

const commandKey = navigator.platform.indexOf("Win") > -1 ? "Ctrl" : "Cmd";

interface MenuBarIconType {
  label: string;
  icon: React.ReactNode;
  isActive: boolean;
  onClick: () => void;
}

const MenuBarIcon = ({ label, icon, isActive, onClick }: MenuBarIconType) => (
  <Tooltip content={label}>
    <ActionButton
      icon={icon}
      onClick={onClick}
      theme={isActive ? "primary" : "default"}
      aria-label={label}
      variant={isActive ? "lightFilled" : "empty"}
      className={cx(styles.actionButton, !isActive && styles.notActive)}
      tabIndex={-1}
    />
  </Tooltip>
);

interface MenuBarProps extends DefaultProps {
  editor: Editor | null;
}
const MenuBar = ({ editor, className, ...rest }: MenuBarProps) => {
  if (!editor) {
    return null;
  }

  return (
    <div
      className={cx(
        styles.menu,
        editor?.isFocused && styles.focused,
        className
      )}
      {...rest}
    >
      <div className={styles.actions}>
        <MenuBarIcon
          label={`Bold (${commandKey}+B)`}
          icon={<IconBold />}
          isActive={editor.isActive("bold")}
          onClick={() => editor.chain().focus().toggleBold().run()}
        />
        <MenuBarIcon
          label={`Italic (${commandKey}+I)`}
          icon={<IconItalic />}
          isActive={editor.isActive("italic")}
          onClick={() => editor.chain().focus().toggleItalic().run()}
        />
        <MenuBarIcon
          label={`Strikethrough (${commandKey}+Shift+X)`}
          icon={<IconStrike />}
          isActive={editor.isActive("strike")}
          onClick={() => editor.chain().focus().toggleStrike().run()}
        />
        {/*<MenuBarIcon*/}
        {/*  label={"Code"}*/}
        {/*  icon={<IconCode />}*/}
        {/*  isActive={editor.isActive("code")}*/}
        {/*  onClick={() => editor.chain().focus().toggleCode().run()}*/}
        {/*/>*/}
        <div className={styles.spacer} />
        <MenuBarIcon
          label={`Blockquote (${commandKey}+Shift+B)`}
          icon={<IconQuote />}
          isActive={editor.isActive("blockquote")}
          onClick={() => editor.chain().focus().toggleBlockquote().run()}
        />

        <MenuBarIcon
          label={`Numbered list (${commandKey}+Shift+7)`}
          icon={<IconOrderList />}
          isActive={editor.isActive("orderedList")}
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
        />
        <MenuBarIcon
          label={`Bullet list (${commandKey}+Shift+8)`}
          icon={<IconBulletList />}
          isActive={editor.isActive("bulletList")}
          onClick={() => editor.chain().focus().toggleBulletList().run()}
        />
        <MenuBarIcon
          label={`Horizontal rule`}
          icon={<IconHorizontalRule />}
          isActive={editor.isActive("horizontalRule")}
          onClick={() => editor.chain().focus().setHorizontalRule().run()}
        />
        {/*<MenuBarIcon*/}
        {/*  label={"Highlight"}*/}
        {/*  icon={<IconHorizontalRule />}*/}
        {/*  isActive={editor.isActive("horizontalRule")}*/}
        {/*  onClick={() => editor.chain().focus().toggleHighlight().run()}*/}
        {/*/>*/}

        <div className={styles.spacer} />
        <MenuBarIcon
          label={`Paragraph`}
          icon={<IconParagraph />}
          isActive={editor.isActive("paragraph")}
          onClick={() => editor.chain().focus().setParagraph().run()}
        />
        <MenuBarIcon
          label={`Heading 1`}
          icon={<IconHeading1 />}
          isActive={editor.isActive("heading", { level: 1 })}
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 1 }).run()
          }
        />
        <MenuBarIcon
          label={`Heading 2`}
          icon={<IconHeading2 />}
          isActive={editor.isActive("heading", { level: 2 })}
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 2 }).run()
          }
        />
        <MenuBarIcon
          label={"Heading 3"}
          icon={<IconHeading3 />}
          isActive={editor.isActive("heading", { level: 3 })}
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 3 }).run()
          }
        />
        <MenuBarIcon
          label={"Heading 4"}
          icon={<IconHeading4 />}
          isActive={editor.isActive("heading", { level: 4 })}
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 4 }).run()
          }
        />
        <MenuBarIcon
          label={"Heading 5"}
          icon={<IconHeading5 />}
          isActive={editor.isActive("heading", { level: 5 })}
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 5 }).run()
          }
        />
        <MenuBarIcon
          label={"Heading 6"}
          icon={<IconHeading6 />}
          isActive={editor.isActive("heading", { level: 6 })}
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 6 }).run()
          }
        />
        <div className={styles.spacer} />
        <MenuBarIcon
          label={`Clear formatting`}
          icon={<IconClear />}
          isActive={false}
          onClick={() => {
            editor.chain().focus().clearNodes().unsetAllMarks().run();
          }}
        />
        {/*<MenuBarIcon*/}
        {/*  label={"Hard break"}*/}
        {/*  icon={<IconHardBreak />}*/}
        {/*  isActive={editor.isActive("hardBreak")}*/}
        {/*  onClick={() => editor.chain().focus().setHardBreak().run()}*/}
        {/*/>*/}
      </div>
      <div>
        <MenuBarIcon
          label={"Undo"}
          icon={<IconUndo />}
          isActive={editor.isActive("undo")}
          onClick={() => editor.chain().focus().undo().run()}
        />
        <MenuBarIcon
          label={"Redo"}
          icon={<IconRedo />}
          isActive={editor.isActive("redo")}
          onClick={() => editor.chain().focus().redo().run()}
        />
      </div>
    </div>
  );
};

export default MenuBar;
