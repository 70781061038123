import React, { useRef, Ref } from "react";
import { useHover, useLayer, Arrow, mergeRefs } from "react-laag";
import { motion, AnimatePresence } from "framer-motion";
import ResizeObserver from "resize-observer-polyfill";
import cx from "classnames";
import styles from "./Tooltip.module.scss";

interface Props {
  /** The content to be displayed within the tooltip */
  content: React.ReactNode;
  /** The direction of the tooltip relative to the element (will move if there isn't enough space) */
  direction?:
    | "top-center"
    | "top-start"
    | "top-end"
    | "left-start"
    | "left-center"
    | "left-end"
    | "right-start"
    | "right-center"
    | "right-end"
    | "bottom-start"
    | "bottom-center"
    | "bottom-end"
    | "center";

  /** Should the arrow show */
  arrow?: boolean;

  /** How much should the tooltip be offset from the element */
  offset?: number;

  /** Override hover trigger and force open */
  // open?: boolean;

  /** Framer motion animation object */
  animation?: {
    initial: object;
    animate: object;
    exit: object;
    transition: object;
  };

  /** Override default delays on hover enter and leave */
  delay?: {
    enter?: number;
    leave?: number;
  };

  className?: string;
  children: React.ReactElement;
}

export const NavTooltip = React.memo(
  ({
    children,
    content,
    className,
    direction = "right-start",
    arrow = false,
    offset = 0,
    animation,
    delay,
  }: Props) => {
    const ref = useRef<HTMLDivElement>(null);
    const [isOpen, hoverProps] = useHover({
      delayEnter: delay?.enter || 100,
      delayLeave: delay?.leave || 200,
    });
    const { layerProps, triggerProps, arrowProps, renderLayer, layerSide } =
      useLayer({
        isOpen,
        triggerOffset: offset,
        containerOffset: offset,
        placement: direction,
        ResizeObserver,
      });

    const mouseLeaveHandler = (
      event: React.MouseEvent<HTMLSpanElement, MouseEvent>
    ) => {
      const { relatedTarget } = event;
      if (
        !(
          relatedTarget instanceof Element &&
          ref.current?.contains(relatedTarget)
        )
      ) {
        hoverProps.onMouseLeave(event);
      }
    };

    const trigger = isReactText(children) ? (
      <span {...triggerProps} {...hoverProps} onMouseLeave={mouseLeaveHandler}>
        {children}
      </span>
    ) : (
      React.cloneElement(children, {
        ...triggerProps,
        ...hoverProps,
        onMouseLeave: mouseLeaveHandler,
      })
    );

    return (
      <>
        {trigger}
        {renderLayer(
          <TooltipWrapper
            isOpen={isOpen}
            className={className}
            animation={animation}
            layerSide={layerSide}
            layerProps={layerProps}
            arrowProps={arrowProps}
            hoverProps={hoverProps}
            arrow={arrow}
            ref={ref}
          >
            {content}
          </TooltipWrapper>
        )}
      </>
    );
  }
);

interface WrapperProps {
  isOpen: boolean;
  layerProps: any;
  layerSide: "top" | "left" | "right" | "bottom" | "center" | undefined;
  arrowProps: any;
  hoverProps: any;
  children: React.ReactNode;
  className?: string;
  arrow?: boolean;
  animation?: {
    initial: object;
    animate: object;
    exit: object;
    transition: object;
  };
}

const TooltipWrapper = React.forwardRef(
  (
    {
      isOpen,
      layerProps,
      layerSide,
      arrowProps,
      hoverProps,
      children,
      className,
      animation = tooltipAnimations,
      arrow,
    }: WrapperProps,
    ref: Ref<HTMLDivElement>
  ) => {
    return (
      <AnimatePresence>
        {isOpen && (
          <motion.div
            {...animation}
            {...layerProps}
            className={cx(className, styles.tooltip)}
            ref={mergeRefs(layerProps.ref, ref)}
            onMouseLeave={hoverProps.onMouseLeave}
            onClick={hoverProps.onMouseLeave}
          >
            {children}

            {arrow && <Arrow layerSide={layerSide} {...arrowProps} />}
          </motion.div>
        )}
      </AnimatePresence>
    );
  }
);

const tooltipAnimations = {
  initial: {
    opacity: 0,
    // scale: 0.8,
    x: -12,
  },
  animate: { opacity: 1, scale: 1, x: 0 },
  exit: {
    opacity: 0,
    // scale: 0.8,
    x: -12,
  },
  transition: {
    type: "tween",
    damping: 20,
    stiffness: 100,
  },
};
function isReactText(children: any) {
  return ["string", "number"].includes(typeof children);
}
export default NavTooltip;
