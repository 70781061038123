import React from "react";
import ReactDOM from "react-dom";
import App from "components/App";
import * as serviceWorker from "./serviceWorker";
import { Amplify } from "aws-amplify";
import { amplifyConfig } from "components/common/auth/config/amplify-config";
import "utils/Logger";
import "styles/base.scss";
import { errorInit } from "utils/errorUtils";

errorInit();
Amplify.configure(amplifyConfig);

//Accessibility testing in development - check the console!
if (process.env.NODE_ENV === "development") {
  const axe = require("@axe-core/react");
  axe(React, ReactDOM, 2000);
}

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
