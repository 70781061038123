import React from "react";
import { Auth, Hub } from "aws-amplify";
import { VerifyContact } from "aws-amplify-react";
import { IAuthPieceProps } from "aws-amplify-react/lib/Auth/AuthPiece";
import Meta from "components/common/Meta";
import AuthLayout from "components/common/layouts/AuthLayout";
import {
  VerifySubmitForm,
  VerifyViewForm,
} from "components/common/auth/forms/VerifyContactForm";
import styles from "./forms/Forms.module.scss";

const exportDefault = {};
export default exportDefault;

export class CustomVerifyContact extends VerifyContact {
  verifyContactError: null | string = null;
  boundHubAuthListener: (result: any) => void = () => {};

  constructor(props: IAuthPieceProps) {
    super(props);
    this._validAuthStates = ["verifyContact"];
  }

  hubAuthListener(result: any) {
    if (!result || !result.payload) {
      return;
    }
    switch (result.payload.event) {
      case "verifyContact_failure":
        this.verifyContactError =
          result.payload.data && result.payload.data.message;
        this.setState({ ...this.state });
        return;
    }
  }

  componentDidMount() {
    super.componentDidMount();
    this.verifyContactError = null;
    this.boundHubAuthListener = this.hubAuthListener.bind(this);
    Hub.listen("auth", this.boundHubAuthListener);
  }

  componentWillUnmount() {
    Hub.remove("auth", this.boundHubAuthListener);
  }

  async handleFormSend() {
    const attr = this.state.verifyAttr;
    const { code } = this.inputs;
    try {
      await Auth.verifyCurrentUserAttributeSubmit(attr, code);
      this.changeState("signedIn", this.props.authData);
      this.setState({ verifyAttr: null });
    } catch (e) {
      if (e instanceof Error) {
        this.verifyContactError = e.message;
      }
      this.setState({ ...this.state });
    }
  }

  async handleVerify() {
    try {
      const { contact } = this.inputs;
      if (!contact) {
        this.error("Neither Email nor Phone Number selected");
        return;
      }
      await Auth.verifyCurrentUserAttribute(contact);
      this.setState({ verifyAttr: contact });
    } catch (e) {
      if (e instanceof Error) {
        this.verifyContactError = e.message;
      }
      this.setState({ ...this.state });
    }
  }

  showComponent(theme: any) {
    const { authData } = this.props;
    const submitView = this.state.verifyAttr;
    return (
      <AuthLayout variant={submitView ? "letter" : "data"}>
        <Meta title="Verify Contact Information" />
        <h1>
          {submitView
            ? "Submit Verification Code"
            : "Verify Contact Information"}
        </h1>
        <p>
          {submitView ? (
            <>
              You should have recieved a verification code{" "}
              {submitView === "email" ? (
                <>
                  in your email (
                  <span className={styles.contact}>
                    {authData.unverified.email}
                  </span>
                  )
                </>
              ) : (
                <>
                  on your phone (
                  <span className={styles.contact}>
                    {authData.unverified.phone_number}
                  </span>
                  )
                </>
              )}
              <br />
              <br />
              Please enter the code in the box below:
            </>
          ) : (
            "To complete account setup, we need to verify your some of your contact information."
          )}
        </p>
        {submitView ? (
          <VerifySubmitForm
            authData={authData}
            inputs={this.inputs}
            onSubmit={this.handleFormSend.bind(this)}
            errorMessage={this.verifyContactError}
            changeState={this.changeState}
          />
        ) : (
          <VerifyViewForm
            authData={authData}
            inputs={this.inputs}
            onSubmit={this.handleVerify.bind(this)}
            errorMessage={this.verifyContactError}
            changeState={this.changeState}
          />
        )}
      </AuthLayout>
    );
  }
}
