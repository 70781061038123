import React from "react";

interface Props {
  className?: string;
}

const DataPortal = ({ className }: Props) => (
  <svg
    width="1202"
    height="1356"
    viewBox="0 0 1202 1356"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <mask
      id="data-illustration-mask"
      // maskType="alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="1202"
      height="1356"
    >
      <rect width="1202" height="1356" fill="#C4C4C4" />
      <rect width="1202" height="1356" fill="url(#paint0_linear)" />
    </mask>
    <g mask="url(#data-illustration-mask)">
      <path
        opacity="0.2"
        d="M1367.78 829.597L995.727 619L994 811.975L1366.22 1022.57L1367.78 829.597Z"
        fill="url(#paint1_linear)"
      />
      <path
        opacity="0.15"
        d="M1090.55 719.375L1202.48 782.779L1202.31 805.929L1090.38 742.525L1090.55 719.375Z"
        fill="white"
      />
      <path
        opacity="0.15"
        d="M1090.21 765.848L1328.57 900.775L1328.4 924.271L1090.03 789.344L1090.21 765.848Z"
        fill="white"
      />
      <path
        d="M1070.69 726.458C1070.52 742.352 1061.02 749.953 1049.27 743.216C1037.53 736.651 1028.2 718.338 1028.37 702.444C1028.54 686.55 1038.04 678.948 1049.79 685.686C1061.54 692.251 1070.86 710.564 1070.69 726.458Z"
        fill="#5AC8AE"
      />
      <path
        opacity="0.2"
        d="M992.952 1198.6L620.727 988L619 1180.98L991.225 1391.57L992.952 1198.6Z"
        fill="url(#paint2_linear)"
      />
      <path
        opacity="0.15"
        d="M715.555 1088.37L827.481 1151.78L827.308 1174.93L715.382 1111.52L715.555 1088.37Z"
        fill="white"
      />
      <path
        opacity="0.15"
        d="M715.209 1134.85L953.571 1269.78L953.398 1293.27L715.036 1158.34L715.209 1134.85Z"
        fill="white"
      />
      <path
        d="M695.691 1095.46C695.518 1111.35 686.018 1118.95 674.273 1112.22C662.527 1105.65 653.2 1087.34 653.373 1071.44C653.546 1055.55 663.046 1047.95 674.791 1054.69C686.536 1061.25 695.864 1079.56 695.691 1095.46Z"
        fill="#5AC8AE"
      />
      <path
        d="M695.691 1095.46C695.518 1111.35 686.018 1118.95 674.273 1112.22C662.527 1105.65 653.2 1087.34 653.373 1071.44C653.546 1055.55 663.046 1047.95 674.791 1054.69C686.536 1061.25 695.864 1079.56 695.691 1095.46Z"
        fill="#179898"
      />
      <path
        opacity="0.1"
        d="M1108.94 72.6015C1038.81 86.2498 881.113 103.872 812.368 65C812.886 340.038 880.249 730.308 1100.48 1052.16C1324.16 981.504 1397.56 671.05 1402.75 399.295C1334 360.251 1178.2 165.202 1108.94 72.6015Z"
        fill="url(#paint3_linear)"
      />
      <path
        opacity="0.3"
        d="M1023.27 129.613C953.139 143.261 795.44 160.883 726.695 122.012C727.213 397.049 794.577 787.319 1014.8 1109.18C1238.48 1038.52 1311.89 727.889 1317.07 456.307C1248.33 417.262 1092.53 222.214 1023.27 129.613Z"
        fill="url(#paint4_linear)"
      />
      <path
        opacity="0.4"
        d="M1317.25 456.135C1248.33 417.091 1092.7 222.215 1023.44 129.614L1014.98 1109C1238.48 1038.52 1311.89 727.89 1317.25 456.135Z"
        fill="url(#paint5_linear)"
      />
      <path
        opacity="0.1"
        d="M907.314 846.356L1253.98 1037.26L1254.15 1674.23L907.487 1483.5L907.314 846.356Z"
        fill="url(#paint6_linear)"
      />
      <path
        opacity="0.2"
        d="M952.223 1218.66L1213.56 1362.57L1214.25 1590.96L952.914 1447.05L952.223 1218.66Z"
        fill="url(#paint7_linear)"
      />
      <path
        opacity="0.15"
        d="M956.887 1066.63L1077.8 1133.14V1156.64L956.887 1090.12V1066.63Z"
        fill="white"
      />
      <path
        opacity="0.15"
        d="M957.405 1113.27L1078.31 1179.79V1203.28L957.405 1136.77V1113.27Z"
        fill="white"
      />
      <path
        opacity="0.15"
        d="M957.232 1158.19L1186.61 1284.48V1307.98L957.232 1181.69V1158.19Z"
        fill="white"
      />
      <path
        opacity="0.2"
        d="M907.314 846.356L1253.98 1037.26L1254.32 1147.31L907.487 956.579L907.314 846.356Z"
        fill="url(#paint8_linear)"
      />
      <path
        opacity="0.2"
        d="M994.023 949.841C994.023 966.426 984.178 974.546 972.087 967.808C959.996 961.07 949.978 942.239 949.978 925.481C949.978 908.896 959.823 900.776 971.914 907.514C984.178 914.252 994.023 933.255 994.023 949.841Z"
        fill="white"
      />
      <path
        d="M1113.2 1216.59L1112.86 1121.91L1347.94 1251.31L1348.28 1345.98L1113.2 1216.59Z"
        fill="#CFF5D3"
        fillOpacity="0.36"
      />
      <path
        opacity="0.3"
        d="M628.844 527.181L281.951 327L282.068 507.828L598.305 690.404L628.961 735.757L628.844 527.181Z"
        fill="url(#paint9_linear)"
      />
      <path
        d="M245 397.419V348.335L375.901 423.884V472.968L245 397.419Z"
        fill="#75BEC2"
      />
      <path
        opacity="0.15"
        d="M539.673 519.719L463.673 475.882V491.738L539.673 535.575V519.719Z"
        fill="white"
      />
      <path
        opacity="0.15"
        d="M539.673 551.548L393.152 467.022V482.878L539.673 567.404V551.548Z"
        fill="white"
      />
      <path
        opacity="0.15"
        d="M539.673 583.26L362.146 480.779V496.635L539.673 599.116V583.26Z"
        fill="white"
      />
      <path
        opacity="0.6"
        d="M558.323 547.117C558.323 562.04 567.182 579.179 578.022 585.474C588.862 591.77 597.721 584.775 597.721 569.852C597.721 554.928 588.862 537.79 578.022 531.494C567.182 525.198 558.323 532.194 558.323 547.117Z"
        fill="#215273"
      />
      <path
        opacity="0.3"
        d="M138.5 1103.5C84.8757 1082.23 108 1101 47.6926 1081.9C47.6926 1081.9 16.2926 1024 19.1926 1095.1L901.693 1601.7L919.882 1348.4C811.682 1154.7 740.082 1019.4 669.682 1081.9C547.682 1190 488.156 1263.09 332.5 1128.5C254.318 1060.9 230.5 1140 138.5 1103.5Z"
        fill="url(#paint10_linear)"
      />
      <path
        opacity="0.3"
        d="M226.789 1152.9C129.189 1068.1 33.5001 1142 11.4998 1142C-10.5005 1142 38.9999 1169 66 1184.5C93.0001 1200 1060.29 1789.8 1060.29 1789.8V1403.4C906.287 1179.8 804.789 1221.4 716.789 1253.7C630.589 1285.3 598.989 1219.3 489.889 1125.2C400.189 1047.8 343.989 1254.7 226.789 1152.9Z"
        fill="url(#paint11_linear)"
      />
      <path
        d="M748.488 1063.7C748.488 1055.7 743.789 1046.6 738.089 1043.3C732.289 1040 727.688 1043.7 727.688 1051.7C727.688 1059.7 732.389 1068.8 738.089 1072.1C743.789 1075.5 748.488 1071.7 748.488 1063.7Z"
        fill="#215273"
      />
      <path
        d="M472.588 1117.5C472.588 1109.8 467.788 1100.8 461.788 1097.4C455.788 1094 450.988 1097.4 450.988 1105.1C450.988 1112.8 455.788 1121.8 461.788 1125.2C467.688 1128.6 472.588 1125.2 472.588 1117.5Z"
        fill="#5AC8AE"
      />
      <path
        d="M472.588 1117.5C472.588 1109.8 467.788 1100.8 461.788 1097.4C455.788 1094 450.988 1097.4 450.988 1105.1C450.988 1112.8 455.788 1121.8 461.788 1125.2C467.688 1128.6 472.588 1125.2 472.588 1117.5Z"
        fill="#179898"
      />
      <path
        opacity="0.07"
        d="M681.889 552L866.489 658.5V1021.3L681.889 914.8V552Z"
        fill="white"
      />
      <path
        opacity="0.3"
        d="M830.488 689.7L645.888 583.2L645.788 946L725.588 992L738.188 1016.8L750.688 1006.6L830.488 1052.5V689.7Z"
        fill="url(#paint12_linear)"
      />
      <path
        opacity="0.1"
        d="M806.288 802.8C806.288 814.4 804.588 825 801.388 833.9C798.188 842.8 793.488 849.8 787.588 854.6C781.688 859.4 774.588 861.8 766.888 861.8C759.188 861.8 750.788 859.3 742.488 854.4V765.9L806.288 802.8Z"
        fill="white"
      />
      <path
        opacity="0.3"
        d="M742.489 854.5C731.289 848 720.289 837.6 710.589 824.2C700.889 810.8 692.789 795 687.189 778.3C681.589 761.6 678.589 744.6 678.589 729.1C678.589 713.6 681.589 700 687.189 689.8L742.489 766V854.5Z"
        fill="white"
      />
      <path
        d="M806.289 802.8C806.289 783.3 801.689 761.7 793.089 741.3C784.489 720.9 772.489 702.9 758.989 690C745.389 677.1 730.989 670.1 717.989 670.1C704.989 670.1 694.189 677 687.089 689.8L742.389 766L806.289 802.8Z"
        fill="#2F6F83"
      />
      <path
        opacity="0.15"
        d="M669.988 858.3L782.488 923.2V936.8L669.988 871.9V858.3Z"
        fill="white"
      />
      <path
        opacity="0.15"
        d="M669.988 884.9L806.388 963.6V977.2L669.988 898.5V884.9Z"
        fill="white"
      />
      <path
        opacity="0.15"
        d="M669.988 911.5L758.388 962.5V976.1L669.988 925.1V911.5Z"
        fill="white"
      />
      <g opacity="0.07">
        <path
          d="M404.988 606.2L589.588 712.7V1075.5L404.988 969V606.2Z"
          fill="#6EAEAE"
        />
        <path
          d="M404.988 606.2L589.588 712.7V1075.5L404.988 969V606.2Z"
          fill="url(#paint13_linear)"
        />
      </g>
      <path
        opacity="0.3"
        d="M554.089 744.1L369.489 637.6L369.389 1000.5L449.289 1046.5L461.789 1071.2L474.289 1061L554.089 1106.9V744.1Z"
        fill="url(#paint14_linear)"
      />
      <path
        opacity="0.1"
        d="M529.888 857.3C529.888 872.8 526.888 886.4 521.288 896.6C515.688 906.8 507.588 913.3 497.888 915.5L465.988 820.4L529.888 857.3Z"
        fill="white"
      />
      <path
        opacity="0.3"
        d="M497.989 915.5C485.789 918.2 471.689 913.9 457.689 903.4C443.789 892.8 430.789 876.5 420.889 857L466.089 820.5L497.989 915.5Z"
        fill="white"
      />
      <path
        d="M529.888 857.3C529.888 842.7 527.288 826.9 522.388 811.2C517.388 795.5 510.288 780.4 501.588 767.3C492.888 754.2 482.788 743.4 472.388 735.9C461.888 728.4 451.388 724.5 441.688 724.5C431.988 724.5 423.388 728.3 416.788 735.7C410.088 743.1 405.588 753.8 403.488 766.9C401.488 780 401.988 795 404.988 810.7C407.988 826.4 413.488 842.2 420.988 856.8L466.188 820.3L529.888 857.3Z"
        fill="#87DAC6"
        fillOpacity="0.69"
      />
      <path
        opacity="0.15"
        d="M393.589 912.8L521.289 986.5V1000.1L393.589 926.4V912.8Z"
        fill="white"
      />
      <path
        opacity="0.15"
        d="M393.589 939.3L526.189 1015.9V1029.5L393.589 952.9V939.3Z"
        fill="white"
      />
      <path
        opacity="0.15"
        d="M393.589 965.9L481.989 1016.9V1030.5L393.589 979.5V965.9Z"
        fill="white"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="52.5"
        y1="1004"
        x2="1143"
        y2="1369"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="1222.47"
        y1="747.316"
        x2="1139.34"
        y2="894.183"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1="847.503"
        y1="1116.3"
        x2="764.38"
        y2="1263.17"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#215273" />
        <stop offset="1" stopColor="#D6E9F7" />
      </linearGradient>
      <linearGradient
        id="paint3_linear"
        x1="656.836"
        y1="161.433"
        x2="1138.07"
        y2="592.049"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint4_linear"
        x1="571.179"
        y1="218.399"
        x2="1052.42"
        y2="649.014"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint5_linear"
        x1="1342.2"
        y1="557.475"
        x2="956.218"
        y2="631.333"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint6_linear"
        x1="931"
        y1="1024.5"
        x2="1001.05"
        y2="1259.99"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint7_linear"
        x1="1192"
        y1="1359.5"
        x2="978.002"
        y2="1373.53"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.109447" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint8_linear"
        x1="1024.91"
        y1="846.039"
        x2="1133.41"
        y2="1139.06"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint9_linear"
        x1="438.681"
        y1="461.897"
        x2="663.358"
        y2="693.752"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.21875" stopColor="#5AC8AE" />
        <stop offset="0.40625" stopColor="white" stopOpacity="0.45" />
      </linearGradient>
      <linearGradient
        id="paint10_linear"
        x1="793"
        y1="1195.5"
        x2="534.5"
        y2="1402.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0380039" stopColor="#215273" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint11_linear"
        x1="641.5"
        y1="1256.5"
        x2="349.5"
        y2="1362"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5AC8AE" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint12_linear"
        x1="738.138"
        y1="643.903"
        x2="738.138"
        y2="1092.26"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint13_linear"
        x1="405"
        y1="629"
        x2="590"
        y2="1062.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#28C6C6" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint14_linear"
        x1="369"
        y1="701"
        x2="538"
        y2="1107"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#359D9E" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
    </defs>
  </svg>
);

export default DataPortal;
