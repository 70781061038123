import axios, { AxiosInstance, Method } from "axios";
import { RequestCancel } from "./RequestCancel";
import qs from "query-string";
import { Auth } from "aws-amplify";

class Request {
  private readonly client: AxiosInstance;
  private headers: TObjectAny = {};

  public constructor() {
    this.client = axios.create({
      paramsSerializer: (params) => qs.stringify(params),
    });

    // Add Cognito id token to all requests
    // Amplify will handle the refresh if needed
    this.client.interceptors.request.use((config) => {
      return Auth.currentSession()
        .then((session) => {
          const idToken = session.getIdToken();
          return Promise.resolve({
            ...config,
            headers: {
              ...config?.headers,
              Authorization: `Bearer ${idToken.getJwtToken()}`,
            },
          });
        })
        .catch(() => {
          return Promise.resolve(config);
        });
    });

    this.setHeaders({
      "Content-Type": "application/json; charset=UTF-8",
    });
  }

  public async get(
    url: string,
    payload: Object = {},
    cancelObject?: RequestCancel
  ) {
    return this.do("GET", url, payload, cancelObject);
  }

  public async post(
    url: string,
    payload: Object = {},
    cancelObject?: RequestCancel
  ) {
    return this.do("POST", url, payload, cancelObject);
  }

  public async put(
    url: string,
    payload: Object = {},
    cancelObject?: RequestCancel
  ) {
    return this.do("PUT", url, payload, cancelObject);
  }

  public async delete(
    url: string,
    payload: Object = {},
    cancelObject?: RequestCancel
  ) {
    return this.do("DELETE", url, payload, cancelObject);
  }

  public setHeaders(headers: TObjectAny) {
    this.headers = headers;
  }

  protected async do(
    method: Method,
    url: string,
    payload: Object,
    cancelObject?: RequestCancel
  ): Promise<TObjectAny> {
    const options = {
      method,
      url,
      headers: this.headers,
      params: {},
      data: {},
      // withCredentials: true,
      cancelToken: new axios.CancelToken((c) => {
        if (cancelObject) {
          cancelObject.setCancelFunction(c);
        }
      }),
    };

    method === "GET" ? (options.params = payload) : (options.data = payload);

    try {
      const { data } = await this.client(options);
      return data || {};
    } catch (error) {
      let e = error as RequestError;
      if (axios.isAxiosError(error)) {
        e = { ...error };
        const message =
          error.response && error.response.data && error.response.data.messages
            ? error.response.data.messages.join(", ")
            : null;
        window.logger.error(message || error);
        e.serverMessage = message;
      }
      throw e;
    }
  }
}

const request = new Request();

export { request };
