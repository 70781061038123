import { useQuery } from "react-query";
import { fetchManyByAdmin } from "api/PeerGroupApi";
import { peerGroupQueryKeys } from "queries/constants";
import { extractFiltersFromDimensions } from "components/users/utils/userUtils";
import { QueryOptions } from "queries/queries";
import { useToast } from "components/common/ui/toast/useToast";
import { IPeerGroupDimensions } from "models/peergroup/PeerGroup";

export const usePeerGroupListByAdmin = (
  dimensions: IPeerGroupDimensions,
  options?: QueryOptions
) => {
  const { addErrorToast } = useToast();
  const dim = extractFiltersFromDimensions(dimensions);
  return useQuery(
    peerGroupQueryKeys.adminPeergroups(
      Object.keys(dim).length > 0 ? dim : undefined
    ),

    () => fetchManyByAdmin(dim),
    {
      onError: (e) =>
        addErrorToast(e, "Unable to load peer groups", {
          id: peerGroupQueryKeys.list().join("::"),
        }),
      // staleTime: 60 * 1000,
      ...options,
    }
  );
};
