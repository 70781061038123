import { useEffect, useRef } from "react";

//Checks a value to see if has has changed between renders. Useful to put inside a
// useEffect hook, to only rerender when 1 dependency changes.
export const useHasChanged = (val: any) => {
  const prevVal = usePrevious(val);
  return prevVal !== val;
};

export const usePrevious = <T extends unknown>(value: T): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};
