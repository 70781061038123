export type SearchValueKey<I extends string | number | symbol = string> =
  | I
  | ((d: any, index: number) => I);

export type SearchFunction<Item, Return = void | Item[]> = (
  inputValue: string | undefined,
  items?: Item[],
  valueKey?: SearchValueKey<keyof Item>
) => Return;

type IsSelected<Item> = (
  value: string,
  selectedItem: Item | null | Item[]
) => boolean;

export const isSelected: IsSelected<any> = (value, selectedItem) => {
  if (Array.isArray(selectedItem)) {
    return selectedItem.some((d) => d.value === value);
  }
  return selectedItem?.value === value;
};

export const getPredictions: SearchFunction<any> = (
  query = "",
  items = [],
  valueKey
) => {
  if (query === "") return items;
  const queryChunks = query ? splitQueryChunks(query) : [];
  return items.filter((u, i) => {
    const k = valueKey
      ? typeof valueKey === "function"
        ? valueKey(u, i)
        : u[valueKey]
      : u;
    return queryChunks.reduce((test, q) => {
      return typeof k === "string" && k.toLowerCase().indexOf(q) > -1 && test;
    }, true as boolean);
  });
};

type Chunk = { highlight: boolean; start: number; end: number };

const splitQueryChunks = (query: string = ""): string[] =>
  query
    .toLowerCase()
    .replace(/[()]/g, "")
    .split(" ")
    .map((q) => q.trim());

// const escapeRegExpFn = (s: string): string => {
//   return s.replace(/[-[\]/{}()*+?.\\^$|]/g, "\\$&");
// };

// modified from https://github.com/reach/reach-ui/blob/develop/packages/combobox/src/utils.ts

export const findChunks = (
  query?: string,
  textToHighlight?: string | null,
  caseSensitive?: boolean
): Chunk[] => {
  const allChunks: Chunk[] = [];
  const totalLength = textToHighlight ? textToHighlight.length : 0;
  const searchWords = query ? splitQueryChunks(query) : [];
  const highlights = findHighlightedChunks(
    searchWords,
    textToHighlight,
    caseSensitive
  );

  const appendChunk = (start: number, end: number, highlight: boolean) => {
    if (end - start > 0) {
      allChunks.push({
        start,
        end,
        highlight,
      });
    }
  };

  if (highlights.length === 0) {
    appendChunk(0, totalLength, false);
  } else {
    let lastIndex = 0;
    highlights.forEach((chunk) => {
      appendChunk(lastIndex, chunk.start, false);
      appendChunk(chunk.start, chunk.end, true);
      lastIndex = chunk.end;
    });
    appendChunk(lastIndex, totalLength, false);
  }
  return allChunks;
};

const findHighlightedChunks = (
  searchWords: string[],
  textToHighlight?: string | null,
  caseSensitive?: boolean
) => {
  const chunks = searchWords
    .filter((searchWord) => searchWord) // Remove empty words
    .reduce<Chunk[]>((chunks, word) => {
      const regex = new RegExp(word, caseSensitive ? "g" : "gi");
      let match;
      while ((match = regex.exec(textToHighlight || ""))) {
        let start = match.index;
        let end = regex.lastIndex;
        // We do not return zero-length matches
        if (end > start) {
          chunks.push({ highlight: true, start, end });
        }

        // Prevent browsers like Firefox from getting stuck in an infinite loop
        // See http://www.regexguru.com/2008/04/watch-out-for-zero-length-matches/
        if (match.index === regex.lastIndex) {
          regex.lastIndex++;
        }
      }

      return chunks;
    }, []);

  return chunks
    .sort((first, second) => first.start - second.start)
    .reduce<Chunk[]>((processedChunks, nextChunk) => {
      // First chunk just goes straight in the array...
      if (processedChunks.length === 0) {
        return [nextChunk];
      } else {
        // ... subsequent chunks get checked to see if they overlap...
        const prevChunk = processedChunks.pop()!;
        if (nextChunk.start <= prevChunk.end) {
          // It may be the case that prevChunk completely surrounds nextChunk, so take the
          // largest of the end indeces.
          const endIndex = Math.max(prevChunk.end, nextChunk.end);
          processedChunks.push({
            highlight: false,
            start: prevChunk.start,
            end: endIndex,
          });
        } else {
          processedChunks.push(prevChunk, nextChunk);
        }
        return processedChunks;
      }
    }, []);
};
