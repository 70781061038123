import React from "react";
import cx from "classnames";
import {
  defaultColPropGetter,
  defaultPropGetter,
  getColumnClasses,
  IColumn,
  IRow,
} from "components/common/ui/table/utils";
import TablePlaceholder, {
  IPlaceholderProps,
} from "components/common/ui/table/TablePlaceholder";
import DefaultRow from "components/common/ui/table/DefaultRow";
import styles from "./ResponsiveTable.module.scss";

export interface ITableProps<Datum = IRow> {
  data: Datum[];
  columns: IColumn<any>[];
  rowKeyAccessor?: (row: Datum, index: number) => string;
  getHeaderProps?: (col: IColumn<Datum>) => TObjectAny;
  getColumnProps?: (col: IColumn<Datum>) => TObjectAny;
  getRowProps?: (row: Datum) => TObjectAny;
  getCellProps?: (
    cell: string,
    row: Datum,
    column: IColumn<Datum>
  ) => TObjectAny;
  rowElement?: React.ComponentType<any>;
  className?: string;
  loading?: boolean;
  placeholder?: IPlaceholderProps;
  noContainerPadding?: boolean;
}
const getGrid = (columns: IColumn[]) =>
  columns.reduce(
    (g, c) => {
      const min =
        typeof c.width === "string"
          ? c.width
          : c.type === "icon"
          ? `${(c.width || 0.21) * 150}px`
          : `${(c.width || 1) * 150}px`;
      const max =
        typeof c.width === "string"
          ? c.width
          : c.type === "icon"
          ? `${32}px`
          : `${c.width || 1}fr`;
      return `${g} minmax(${min}, ${max})`;
    },
    // (g, c) => `${g} ${c.width || 1}fr`,
    ""
  );

const Table = ({
  data,
  columns,
  rowKeyAccessor = (r: IRow) => r.id,
  getHeaderProps = defaultPropGetter,
  getColumnProps = defaultColPropGetter,
  getRowProps = defaultPropGetter,
  getCellProps = defaultPropGetter,
  rowElement: Row = DefaultRow,
  className,
  loading,
  placeholder,
  noContainerPadding,
}: ITableProps) => {
  const columnStyle = {
    gridTemplateColumns: getGrid(columns),
  };
  return (
    <div
      className={cx(
        styles.table,
        className,
        noContainerPadding && styles.noContainerPadding
      )}
      role="table"
    >
      <div role="rowgroup" className={styles.headerWrapper}>
        <div role="row" className={styles.header} style={columnStyle}>
          {columns.map((c) => {
            const { className: colClass, ...colProps } = getColumnProps(c);
            const { className: headerClass, ...headerProps } =
              getHeaderProps(c);
            return (
              <div
                key={c.id}
                {...colProps}
                {...headerProps}
                className={cx(
                  getColumnClasses(c),
                  c.className,
                  c.headerClassname,
                  colClass,
                  headerClass
                )}
                role="columnheader"
              >
                {c.label || c.id}
              </div>
            );
          })}
        </div>
      </div>
      <div role="rowgroup" className={styles.body}>
        {!loading &&
          data?.map((row, index) => {
            const { className: rowClass, ...rowProps } = getRowProps(row);
            return (
              <Row
                {...rowProps}
                className={cx(styles.row, rowClass)}
                key={rowKeyAccessor(row, index)}
                style={columnStyle}
              >
                {columns.map((c) => {
                  const cell = c.accessor ? c.accessor(row, c) : row[c.id];
                  const { className: colClass, ...colProps } =
                    getColumnProps(c);
                  const { className: cellClass, ...cellProps } = getCellProps(
                    cell,
                    row,
                    c
                  );
                  return (
                    <div
                      key={c.id}
                      {...colProps}
                      {...cellProps}
                      className={cx(
                        getColumnClasses(c),
                        c.className,
                        colClass,
                        cellClass
                      )}
                      role="cell"
                    >
                      {c.cell ? c.cell(cell, row, c) : cell}
                    </div>
                  );
                })}
              </Row>
            );
          })}
        {(loading || data?.length === 0) && (
          <TablePlaceholder
            columnStyle={columnStyle}
            columns={columns}
            getColumnProps={getColumnProps}
            loading={!!loading}
            noDataLabel={placeholder?.noDataLabel}
            loadingRows={placeholder?.loadingRows}
            noDataHeight={placeholder?.noDataHeight}
          />
        )}
      </div>
    </div>
  );
};

export default Table;
