import type { SupervisorStatus } from "models/supervisors/Supervisor";
import { IMenuItem } from "components/common/ui/form-elements/autocomplete/MenuItem";
import { IQuestion } from "components/common/ui/form-elements/display-elements/FormattedQuestion";

export const statusOptions: IMenuItem<SupervisorStatus>[] = [
  { value: "Active", label: "Active", theme: "success" },
  { value: "Pending", label: "Pending", theme: "warning" },
  { value: "Inactive", label: "Inactive", theme: "danger" },
];

export const supervisorStatusQuestion: IQuestion = {
  name: "supervisor_status",
  label: "Supervisor Relationship Status",
  type: "enum",
  options: statusOptions,
};
