import React, { useMemo, useState } from "react";
import PageHeader from "components/common/layouts/appLayout/PageHeader";
import { Tab, TabList, Tabs } from "components/common/ui/tabs/Tabs";
import Spacer from "components/common/ui/spacer/Spacer";
import Container from "components/common/layouts/Container";
import { Supervisee } from "models/supervisors/Supervisee";
import { PlanYear } from "models/cpd/Plan";
import SupervisionMeetings from "components/supervisors/detail/SupervisionMeetings";
import { OversightLevelChip } from "components/users/common/detail/OversightLevelChip";
import FormattedQuestion from "components/common/ui/form-elements/display-elements/FormattedQuestion";
import RequirementsProgress from "components/cpd/common/RequirementsProgress";
import { calcPlanProgress } from "components/cpd/utils/utils";
import IconLock from "components/common/icons/IconLock";
import { formatDate } from "utils/format/dateUtils";
import { getInitialPlanYearTabState } from "components/cpd/utils/planUtils";
import Button from "components/common/ui/button/Button";
import { ReadOnlyPlan } from "components/supervisors/detail/ReadOnlyPlan";
import styles from "./SuperviseeDetail.module.scss";
import Modal from "components/common/ui/modal/Modal";
import Goals from "components/cpd/goal/Goals";

interface Props {
  superviseeId: string;
  superviseeDetail: Supervisee;
  editLink: string;
}

const SuperviseeDetail = ({ superviseeDetail, superviseeId }: Props) => {
  const [tabState, setTabState] = useState<number>(
    getInitialPlanYearTabState(superviseeDetail.plan)
  );
  const [showFullPlan, setShowFullPlan] = useState<boolean>(false);
  const [showGoals, setShowGoals] = useState(false);

  const activePlan = superviseeDetail.plan;

  const currentYear =
    activePlan?.progress?.years[tabState] ||
    ({ year: tabState + 1 } as PlanYear);

  const req = useMemo(() => {
    if (activePlan && superviseeDetail.activities) {
      return activePlan && superviseeDetail.activities
        ? calcPlanProgress(
            activePlan.requirements,
            superviseeDetail.activities,
            Math.ceil(activePlan.duration / 12)
          )
        : undefined;
    }

    return;
  }, [activePlan, superviseeDetail.activities]);

  return (
    <>
      <PageHeader
        spacing="extra"
        spacingTopOnly
        header={
          superviseeDetail.family_name
            ? ` ${superviseeDetail.given_name} ${superviseeDetail.family_name}`
            : "Supervisee Details"
        }
        actions={[
          <>
            <Button
              theme="primary"
              variant="outline"
              onClick={() => setShowFullPlan(!showFullPlan)}
            >
              View {showFullPlan ? "Meetings" : "Full Progress"}
            </Button>
            ,
            <Button
              theme="secondary"
              variant="outline"
              onClick={() => setShowGoals(true)}
            >
              View Goals
            </Button>
          </>,
        ]}
      >
        <div>
          <OversightLevelChip
            oversight={superviseeDetail.additional_oversight_severity}
            condensed={false}
          />

          {!!superviseeDetail.additional_oversight_severity &&
            superviseeDetail.additional_oversight_severity !== "green" && (
              <div>
                <FormattedQuestion
                  def={{
                    name: "monitor_check_date",
                    label: "Monitor Progress date",
                    type: "date",
                  }}
                  questions={superviseeDetail}
                />
                <FormattedQuestion
                  def={{
                    name: "oversight_notes",
                    label: "Monitor notes",
                    type: "string",
                    layout: {
                      type: "textArea",
                    },
                  }}
                  questions={superviseeDetail}
                />
              </div>
            )}
        </div>
      </PageHeader>

      {req && (
        <>
          <hr />
          <Spacer height={16} />
          <Container>
            <strong>Programme period Progress</strong>{" "}
            <span>
              {formatDate(activePlan?.started_at)} -{" "}
              {formatDate(
                activePlan?.progress?.years?.[
                  Math.ceil((activePlan.duration ?? 0) / 12) - 1
                ].end
              )}
            </span>
            <Spacer height={12} />
            <RequirementsProgress
              progress={req.triennial}
              year={Math.ceil((activePlan?.duration ?? 0) / 12)}
            />
          </Container>
          <Spacer height={24} />
        </>
      )}

      <Tabs state={[tabState, setTabState]}>
        <TabList decorator="none" tabBackground>
          {activePlan?.progress?.years.map((y) => (
            <Tab key={y.year}>
              {y.locked && <IconLock className={styles.tabLock} />}Year {y.year}
            </Tab>
          ))}
        </TabList>
      </Tabs>
      <Container>
        {req && (
          <>
            <Spacer height={16} />
            <div>
              <strong>Annual Progress</strong> <span>{currentYear?.label}</span>
            </div>
            <Spacer height={8} />
            <RequirementsProgress
              progress={req.annual}
              year={tabState}
              annual
            />
          </>
        )}
        {showFullPlan && activePlan ? (
          <ReadOnlyPlan
            user={superviseeDetail}
            plan={activePlan}
            currentYear={currentYear}
          />
        ) : (
          <div>
            {" "}
            <h4>Meetings</h4>
            {superviseeDetail.activities &&
            superviseeDetail.activities.keys.length > 0 ? (
              <SupervisionMeetings
                activities={superviseeDetail.activities}
                userId={superviseeId}
                plan={activePlan}
                year={tabState + 1}
                goals={superviseeDetail.goals}
              />
            ) : (
              <h4>No meetings with this supervisee</h4>
            )}
          </div>
        )}
        <Modal open={showGoals} onClose={() => setShowGoals(false)}>
          <Goals
            goals={superviseeDetail?.goals ?? []}
            userId={superviseeId}
            editable={false}
            lockCreateGoal={true}
          />
        </Modal>
      </Container>
    </>
  );
};

export default SuperviseeDetail;
