import { useQuery, useQueryClient } from "react-query";
import { fetchMany, fetchUserSearch } from "api/UserApi";
import { userQueryKeys } from "queries/constants";
import { extractFiltersFromDimensions } from "utils/dimensions/bucketUtils";
import { IUserListDimensions } from "models/user/User";
import { QueryOptions } from "queries/queries";
import { useToast } from "components/common/ui/toast/useToast";

export const useUsersList = (
  dimensions: IUserListDimensions,
  options?: QueryOptions
) => {
  const { addErrorToast } = useToast();
  const dim = extractFiltersFromDimensions(dimensions);
  return useQuery(
    userQueryKeys.list(Object.keys(dim).length > 0 ? dim : undefined),
    () => fetchMany(dimensions),
    {
      onError: (e) =>
        addErrorToast(e, "Unable to load users", {
          id: userQueryKeys.list().join("::"),
        }),
      staleTime: 60 * 1000,
      ...options,
    }
  );
};

export const useUsersSearch = (
  dimensions: IUserListDimensions,
  isAdmin?: boolean,
  options?: QueryOptions
) => {
  const { addErrorToast } = useToast();
  const qk = [
    isAdmin ? "admin-search" : "search",
    ...userQueryKeys.list(
      Object.keys(dimensions).length > 0 ? dimensions : undefined
    ),
  ];
  return useQuery(qk, () => fetchUserSearch(dimensions, isAdmin), {
    onError: (e) =>
      addErrorToast(e, "Unable to load user list", {
        id: JSON.stringify(qk),
      }),
    // staleTime: 60 * 1000 * 1000,
    staleTime: 60,
    ...options,
  });
};

export const usePrefetchUsersList = (dim: TObjectAny) => {
  const queryClient = useQueryClient();
  queryClient.prefetchQuery(userQueryKeys.list(dim), () => fetchMany(dim));
};
