import React from "react";
import cx from "classnames";
import styles from "./Checkbox.module.scss";

interface ICheckbox {
  name: string;
  label: string;
  value: string;
  className?: string;
  disabled?: boolean;
  required?: boolean;
  tabIndex?: number;
  defaultValue?: string;
}

const Checkbox = React.forwardRef(
  (
    {
      value,
      name,
      label,
      disabled,
      required,
      tabIndex,
      className,
      defaultValue,
      ...rest
    }: ICheckbox,
    ref: React.Ref<any>
  ) => {
    const dv = defaultValue || "";
    const checked = dv.includes(value);
    return (
      <>
        <label
          className={cx(
            className,
            styles.checkbox,
            disabled && styles.disabled
          )}
        >
          <input
            type="checkbox"
            id={`checkbox-input-${name}-${value}`}
            ref={ref}
            name={name}
            disabled={disabled}
            required={required}
            className={cx(styles.input)}
            tabIndex={tabIndex}
            defaultValue={value}
            defaultChecked={checked}
            {...rest}
          />
          <label
            className={styles.label}
            htmlFor={`checkbox-input-${name}-${value}`}
          >
            {label}
          </label>
          <span className={cx(styles.box, disabled && styles.disabled)} />
        </label>
      </>
    );
  }
);

export default Checkbox;
