import React from "react";
import Chip from "components/common/ui/chip/Chip";
import { Activity } from "models/cpd/Activity";
import { activityAttributes } from "components/cpd/utils/activityForm";
import { gradeOptions } from "models/cpd/common/common";
import FormattedQuestion from "components/common/ui/form-elements/display-elements/FormattedQuestion";
import HintBox from "components/common/ui/hint-box/HintBox";
import {
  IconDanger,
  IconError,
  IconSuccess,
} from "components/common/icons/twoToneIcons";
import styles from "./AuditResults.module.scss";

interface Props {
  activity: Activity;
}

const AuditResults = ({ activity }: Props) => {
  const grade = gradeOptions.find((g) => g.value === activity.audit_grade);
  return grade ? (
    <HintBox
      theme={grade.theme}
      className={styles.root}
      icon={getIcon(grade.theme)}
    >
      <h4>Audit Results</h4>
      <Chip theme={grade.theme} variant="filled">
        {grade.label}
      </Chip>
      <FormattedQuestion
        def={{
          ...activityAttributes.adminComments,
          label: activityAttributes.adminComments.label || "",
        }}
        questions={activity}
      />
    </HintBox>
  ) : (
    <></>
  );
};

const getIcon = (theme?: ThemeVariants) => {
  switch (theme) {
    case "success":
      return <IconSuccess />;
    case "warning":
      return <IconError />;
    case "danger":
      return <IconDanger />;
    default:
      return undefined;
  }
};

export default AuditResults;
