import React from "react";
import cx from "classnames";
import { dimensionsToParamsFormat } from "utils/dimensions/paramsUtils";
import Select, {
  ISelecInputConfig,
  SkeletonSelect,
} from "components/common/ui/select/Select";
import {
  DIMENSION_MATCH,
  IDimension,
  IDimensions,
  TBucket,
  TSetActiveBucket,
  TSetManyBucketsActive,
} from "models/Dimension";
import SyncUrlQueryState from "components/common/ui/sync-url-state/SyncUrlQueryState";
import DebouncedTextInput from "components/common/ui/form-elements/text-input/DebouncedTextInput";
import { DateRangePicker } from "components/common/ui/form-elements/date-picker/DateRangePicker";
import { isValidDate } from "utils/format/dateUtils";
import { SkeletonTextInput } from "components/common/ui/form-elements/text-input/TextField";
import IconSearch from "components/common/icons/IconSearch";
import Button from "components/common/ui/button/Button";
import styles from "./ListFilter.module.scss";

const defaultConfig = {
  inputChip: true,
  selectedOptionFormat: "chip",
  expandArrow: true,
  noInputError: true,
} as ISelecInputConfig;

interface IFilterBase {
  filters: string[];
  dimensions: IDimensions;
  setActive: TSetActiveBucket;
  config?: ISelecInputConfig;
  className?: string;
  multiSelect?: boolean;
  clearFilter?: () => void;
}

interface Props extends IFilterBase {
  setManyActive: TSetManyBucketsActive;
  isUpdating?: boolean;
  isLoading?: boolean;
}

const ListFilters = ({
  isLoading,
  setManyActive,
  isUpdating,
  ...rest
}: Props) => {
  const updateFromQueryURL = (dim: Record<string, TBucket>) => {
    setManyActive(
      Object.keys(dim).map((k) => ({ key: k, bucket: dim[k] })),
      !isLoading
    );
  };
  const clearFilter = () => {
    // filters.map((f) => dimensions[f].buckets === null || []);
    setManyActive(
      Object.keys(rest.dimensions).map((k) => ({ key: k, bucket: null })),
      !isLoading
    );
  };
  return (
    <>
      <SyncUrlQueryState
        queryKeys={rest.filters}
        state={dimensionsToParamsFormat(rest.dimensions)}
        setState={updateFromQueryURL}
        pauseSync={isUpdating}
      />
      {!isLoading ? (
        <ListFiltersBase {...rest} clearFilter={clearFilter} />
      ) : (
        <div className={cx(styles.root, rest.className)}>
          {rest.filters.map((f) => {
            const dim = rest.dimensions[f];
            return dim ? (
              <div
                key={f}
                className={cx(
                  styles.filter,
                  dim.layout && styles[`layout-${dim.layout}`]
                )}
              >
                {dim.match === DIMENSION_MATCH.FUZZY ? (
                  <SkeletonTextInput
                    className={styles.wideFilter}
                    icon={IconSearch}
                    rounded
                    condensed
                  />
                ) : (
                  <SkeletonSelect />
                )}
              </div>
            ) : null;
          })}
        </div>
      )}
    </>
  );
};

const ListFiltersBase = ({
  filters,
  dimensions,
  setActive,
  config,
  className,
  multiSelect,
  clearFilter,
}: IFilterBase) => {
  const onFilter = async (key: string, value: TBucket) => {
    const v = value ? value : null;
    await setActive({
      key: key,
      bucket: v,
    });
  };
  const onDateFilter = async (
    key: string,
    value: [Date | null, Date | null] | null
  ) => {
    const startDate =
      value && isValidDate(value[0]) ? value[0].toISOString() : null;
    const endDate =
      value && isValidDate(value[1]) ? value[1].toISOString() : null;
    // @ts-ignore DateRangePicker can handle string | null, while other facets can't at this stage
    return onFilter(key, [startDate, endDate]);
  };

  // const test= () =>  {     filters.map((f) => console.log(dimensions[f].buckets))}

  return (
    <div className={cx(styles.root, className)}>
      {filters.map((f) => {
        const dim = dimensions[f];
        return dim ? (
          <div
            key={f}
            className={cx(
              styles.filter,
              dim.layout && styles[`layout-${dim.layout}`]
            )}
          >
            {dim.clearButton ? (
              <Button
                className={styles.buttonClear}
                onClick={() => (clearFilter ? clearFilter() : undefined)}
              >
                {" "}
                Clear all filters
              </Button>
            ) : dim.match === DIMENSION_MATCH.FUZZY ? (
              <DebouncedTextInput
                name={dim.key}
                label={dim.label || dim.key}
                value={getStringValue(dim)}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  onFilter(dim.key, e.target.value)
                }
                className={styles.wideFilter}
                debounceTimeout={200}
                config={{
                  rounded: true,
                  noInputError: true,
                  condensed: true,
                }}
                icon={IconSearch}
              />
            ) : dim.match === DIMENSION_MATCH.DATE_RANGE ? (
              <DateRangePicker
                name={dim.key}
                label={dim.label || dim.key}
                //@ts-ignore - TBucket is an array of any length, but DateRangePicker is expecting an array of 2 items. This is easily handled by internal logic already in DateRangePicker component
                value={typeof dim.buckets === "string" ? null : dim.buckets}
                onChange={(range) => onDateFilter(dim.key, range)}
                config={{ chip: true }}
                placeholder="Add Filter"
              />
            ) : (
              <Select
                name={dim.key}
                value={removeNullValues(dim.buckets)}
                onSelect={onFilter}
                label={dim.label || dim.key}
                options={dim.options}
                multiSelect={isMultiSelect(dim.match, multiSelect)}
                placeholder="Add filter"
                config={{
                  ...defaultConfig,
                  ...config,
                }}
              />
            )}
          </div>
        ) : null;
      })}
      {/*<button onClick={() => {console.log(dimensions)}}> Click here </button>*/}
      {/*<button onClick={() => test()}> Click 2</button>*/}
      {/*<Button className={styles.buttonClear} onClick={() => clearFilter()}> Clear bucket</Button>*/}

      {/*:  testHere === true ? ( <Button className={styles.buttonClear} onClick={() => clearFilter()}> Clear bucket</Button>)*/}
    </div>
  );
};

const getStringValue = (d: IDimension): string =>
  Array.isArray(d.buckets)
    ? removeNullValues(d.buckets)[0] || ""
    : d.buckets || "";

const removeNullValues = (bucket: TBucket): string | string[] =>
  Array.isArray(bucket)
    ? (bucket.filter((b) => !!b) as string[])
    : bucket || "";

const isMultiSelect = (match?: DIMENSION_MATCH, override?: boolean) =>
  override !== undefined
    ? override
    : match &&
      [DIMENSION_MATCH.FACET, DIMENSION_MATCH.FACET_ARRAY].includes(match);

export default ListFilters;
