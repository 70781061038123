import React from "react";
import Button from "components/common/ui/button/Button";
import { Facility } from "models/facility/Facility";
import { FacilityType } from "components/facilities/common/FacilityType";
import PageHeader from "components/common/layouts/appLayout/PageHeader";
import styles from "components/facilities/detail/FacilityHeader.module.scss";

interface Props extends DefaultProps {
  facility: Facility;
  isAdmin?: boolean;
  editLink: string;
}
const FacilityHeader = ({ facility, isAdmin = false, editLink }: Props) => {
  return (
    <PageHeader
      header={facility.facility_name}
      actions={
        isAdmin
          ? [
              <Button to={editLink} variant="filled" rounded theme="primary">
                Edit Facility Details
              </Button>,
            ]
          : []
      }
      children={
        facility.facility_type && (
          <div className={styles.item}>
            Type : &nbsp;
            <FacilityType facilityType={facility.facility_type} />
          </div>
        )
      }
    />
  );
};

export default FacilityHeader;
