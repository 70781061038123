import { useQuery } from "react-query";
import { fetchManyActivity } from "api/CpdApi";
import { activityQueryKeys } from "queries/constants";
import { QueryOptions } from "queries/queries";
import { useToast } from "components/common/ui/toast/useToast";
import { PlanYear } from "models/cpd/Plan";

export const useActivityList = (
  planId: string | null = null,
  userId: string,
  planYears?: PlanYear[],
  options?: QueryOptions
) => {
  const { addErrorToast } = useToast();
  const qk = activityQueryKeys.list({ planId, userId });
  return useQuery(qk, () => fetchManyActivity(userId, planId, planYears), {
    onError: (e) =>
      addErrorToast(
        e,
        `Unable to load ${planId ? "plan" : "unassigned"} activities`,
        {
          id: qk.join("::"),
        }
      ),
    ...options,
  });
};

export const useUnassignedActivityList = (
  userId: string,
  options?: QueryOptions
) => {
  const { addErrorToast } = useToast();
  const qk = activityQueryKeys.list({ plan_id: null });
  return useQuery(qk, () => fetchManyActivity(userId), {
    onError: (e) =>
      addErrorToast(e, "Unable to load unassigned activities", {
        id: qk.join("::"),
      }),
    ...options,
  });
};
