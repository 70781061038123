import { ADMIN_ROOT, ROOT, ROUTE_PARAMS } from "constants/routes/common";
import { PermissionGroups } from "models/user/Permissions";

const ADMIN_PEERGROUPS = {
  path: `${ADMIN_ROOT}/peergroups`,
  requiredRole: PermissionGroups.adminRead,
};

const USER_PEERGROUPS = {
  path: `${ROOT}/peergroups`,
  requiredRole: PermissionGroups.clinicianMember,
};

const peerGroupPermissions = [
  ...PermissionGroups.clinicianMember,
  ...PermissionGroups.adminRead,
];

const PEERGROUP_DETAIL = {
  path: `${USER_PEERGROUPS.path}/detail/:${ROUTE_PARAMS.PEERGROUP}`,
  requiredRole: peerGroupPermissions,
};

const ADMIN_CREATE_PEERGROUP = {
  path: `${ADMIN_PEERGROUPS.path}/create`,
  requiredRole: PermissionGroups.adminEdit,
};

const ADMIN_EDIT_PEERGROUP = {
  path: `${PEERGROUP_DETAIL.path}/edit`,
  requiredRole: peerGroupPermissions,
};

const PEERGROUP_MEETINGS = {
  path: `${PEERGROUP_DETAIL.path}/meetings`,
  requiredRole: peerGroupPermissions,
};

const PEERGROUP_MEETING_DETAIL = {
  path: `${PEERGROUP_MEETINGS.path}/:${ROUTE_PARAMS.MEETING}`,
  requiredRole: peerGroupPermissions,
};

const CREATE_PEERGROUP_MEETING = {
  path: `${PEERGROUP_DETAIL.path}/create-meeting`,
  requiredRole: peerGroupPermissions,
};

const EDIT_PEERGROUP_MEETING = {
  path: `${PEERGROUP_DETAIL.path}/edit/meeting/:${ROUTE_PARAMS.MEETING}`,
  requiredRole: peerGroupPermissions,
};

export const PEERGROUP_ROUTES = {
  ADMIN_PEERGROUPS,
  USER_PEERGROUPS,
  PEERGROUP_DETAIL,
  ADMIN_CREATE_PEERGROUP,
  ADMIN_EDIT_PEERGROUP,
  PEERGROUP_MEETING_DETAIL,
  CREATE_PEERGROUP_MEETING,
  EDIT_PEERGROUP_MEETING,
};
