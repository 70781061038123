import React from "react";
import cx from "classnames";
import { useMenuContext } from "components/common/ui/form-elements/autocomplete/MenuContext";
import { motion } from "framer-motion";
import { HighlightChunks } from "components/common/ui/form-elements/autocomplete/HighlightChunks";
import { isSelected } from "components/common/ui/form-elements/autocomplete/utils";
import ActiveOptionChip from "components/common/ui/select/ActiveOptionChip";
import { FieldOption } from "components/common/ui/form-elements/formElements";
import styles from "./MenuItem.module.scss";

export interface IMenuItem<F extends string = string, Meta = any>
  extends FieldOption<F> {
  searchValue?: string;
  metadata?: Meta;
}

export interface IOption<F extends string = string>
  extends DefaultProps,
    IMenuItem<F> {
  index: number;
  animation?: TObjectAny;
}
export const MenuItem = ({
  value,
  label,
  color,
  theme,
  className,
  children,
  style,
  index,
  animation,
}: IOption) => {
  const { getItemProps, highlightedIndex, selectedItem, query, isMultiselect } =
    useMenuContext();
  const animateOptions = animation || {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0, height: 0, paddingTop: 0, paddingBottom: 0 },
    transition: {
      type: "spring",
      stiffness: 800,
      damping: 35,
    },
  };
  const active = isSelected(value, selectedItem);
  const content = children || (
    <HighlightChunks text={label || value} query={query} />
  );
  return (
    <motion.li
      className={cx(
        className,
        styles.option,
        active && !isMultiselect && styles.active,
        highlightedIndex === index && styles.highlighted
      )}
      {...getItemProps({ index, item: value })}
      style={style}
      {...animateOptions}
    >
      {!isMultiselect && (color || theme) && (
        <span
          className={cx(styles.dot, theme && styles[theme])}
          style={{ background: color }}
        />
      )}
      {isMultiselect && active ? (
        <ActiveOptionChip
          id={selectedItem.value}
          key={`selected-item-${index}`}
          cancelIcon
          theme={theme}
          color={color}
        >
          {content}
        </ActiveOptionChip>
      ) : (
        content
      )}
    </motion.li>
  );
};
