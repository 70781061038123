import React, { useState } from "react";
import {
  PathwayCategoryRequirements,
  PathwayRequirements,
  RequirementType,
  TRequirement,
} from "models/cpd/common/requirements";
import {
  activityCategoryOptions,
  Category,
  subCategoryOptions,
} from "models/cpd/common/common";
import CategoryIcon from "components/cpd/common/CategoryIcon";
import Chip from "components/common/ui/chip/Chip";
import { IColumn } from "components/common/ui/table/utils";
import Table from "components/common/ui/table/Table";
import { keyBy } from "utils/common";
import Button from "components/common/ui/button/Button";
import Modal from "components/common/ui/modal/Modal";
import AddRequirement from "components/cpd/common/AddRequirement";
import { getPathwayLinks } from "components/cpd/utils/pathwayLinks";
import IconExternalLink from "components/common/icons/IconExternalLink";
import styles from "./Requirements.module.scss";

interface Props extends DefaultProps {
  requirements: PathwayRequirements;
  setRequirements: (requirements: PathwayRequirements) => void;
  editable?: boolean;
}

interface ReqRow {
  id: Category;
}

const Requirements = ({
  requirements,
  setRequirements,
  editable = false,
}: Props) => {
  const [open, setOpen] = useState<Category | undefined>(undefined);

  const updateRequirements = (
    category: Category,
    categoryRequirement: PathwayCategoryRequirements
  ) => {
    setRequirements({ ...requirements, [category]: categoryRequirement });
    setOpen(undefined);
  };

  if (!requirements) {
    return <></>;
  }
  const columns: IColumn<ReqRow>[] = [
    // { id: "type", label: "Type" },
    { id: "annual", label: "Annual Requirements", className: styles.cell },
    {
      id: "triennial",
      label: "Programme period Requirements",
      className: styles.cell,
    },
  ];
  if (editable) {
    columns.push({
      id: "edit",
      label: " ",
      width: "75px",
      type: "icon",
      cell: (cell, row) => (
        <Button onClick={() => setOpen(row.id)}>Edit</Button>
      ),
    });
  }

  const data = activityCategoryOptions
    .map(({ value, label, unitType }) => {
      const annual = requirements[value]?.annual;
      const triennial = requirements[value]?.triennial;
      //Remove row if no requirements
      if (!annual && !triennial) {
        return undefined;
      }
      return {
        id: value,
        type: (
          <>
            <CategoryIcon type={value} />
            &nbsp;<b>{label}</b>{" "}
          </>
        ),
        annual: annual ? (
          <>
            {annual.map((d, i) => (
              <CompactRequirement
                key={i}
                req={d}
                type={value}
                label={label || value}
                unitType={unitType}
              />
            ))}
          </>
        ) : (
          ""
        ),
        triennial: triennial ? (
          <>
            {triennial.map((e, i) => (
              <CompactRequirement
                key={i}
                req={e}
                type={value}
                label={label || value}
                unitType={unitType}
              />
            ))}
          </>
        ) : (
          ""
        ),
      };
    })
    .filter((d) => d !== undefined) as ReqRow[]; //filter out empty rows

  return (
    <div>
      {" "}
      <Table
        data={data}
        columns={columns}
        placeholder={{
          noDataLabel: "No Default Pathway Requirements",
        }}
      />{" "}
      <br />
      <Modal open={!!open} onClose={() => setOpen(undefined)}>
        <h2>
          Requirements -{" "}
          {activityCategoryOptions.find((c) => c.value === open)?.label || ""}
        </h2>
        {open && (
          <AddRequirement
            requirements={requirements[open]}
            category={open}
            setRequirements={updateRequirements}
            onCancel={() => setOpen(undefined)}
          />
        )}
      </Modal>
    </div>
  );
};

const CompactRequirement = ({
  req,
  label,
  type,
  unitType,
}: {
  req: TRequirement;
  type: Category;
  label: string;
  unitType?: "hourly" | "event";
}) => {
  const reqLabel = req.label || label;
  const sub = subCategoryOptions[type] || [];
  const optionsMap = keyBy(sub, "value", (d) => d.altLabel || d.label);
  let children = (<></>) as React.ReactNode;

  switch (req.type) {
    case RequirementType.any:
      children =
        req.credits === 1
          ? `${unitType === "hourly" ? "1hr of " : ""}${reqLabel}`
          : `${req.credits}${
              unitType === "hourly" ? "hrs of" : ""
            } ${reqLabel}`;

      break;
    case RequirementType.exact:
      const optionLabel = optionsMap[req.option] || req.option;

      children =
        req.credits === 1 ? (
          <>
            {unitType === "hourly" ? "1hr of " : ""}
            <i>{optionLabel}</i>
          </>
        ) : (
          <>
            <i>
              {optionLabel} - {req.credits}
            </i>
            &nbsp;credits/hours
          </>
        );

      break;
    case RequirementType.multi:
      const optionLabels = req.options.map((o) => optionsMap[o] || o);
      const label =
        req.credits === 1
          ? `1${unitType === "hourly" ? "hr of" : ""} ${reqLabel}`
          : `${req.credits}${
              unitType === "hourly" ? "hrs of" : ""
            } ${reqLabel} from`;
      children = (
        <>
          <div>
            <div>{label}:</div>
            <div>
              {optionLabels?.map((o, i) => (
                <i key={i}>
                  {i + 1 === req.options?.length ? `or ${o}` : `${o}, `}&nbsp;
                </i>
              ))}
            </div>
          </div>

          <br />
        </>
      );
      break;
    case RequirementType.distinct:
      const optionLabelDistinct = req.options.map((o) => optionsMap[o] || o);
      const labelDistinct =
        req.credits === 1
          ? `1${unitType === "hourly" ? "hr of" : ""} ${reqLabel}`
          : `${req.credits}${
              unitType === "hourly" ? "hrs of" : ""
            } ${reqLabel} from`;
      children = (
        <>
          <div>
            <div>{labelDistinct}:</div>
            <div>
              {optionLabelDistinct?.map((o, i) => (
                <i key={i}>
                  {i + 1 === req.options?.length ? `or ${o}` : `${o}, `}&nbsp;
                </i>
              ))}
            </div>
          </div>

          <br />
        </>
      );
      break;
  }

  const url = getPathwayLinks(
    req.type,
    type,
    req.type === RequirementType.exact ? req.option : undefined
  );

  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      <Chip icon={<CategoryIcon type={type} />} condensed theme="primary">
        {children}
        <IconExternalLink className={styles.outLinkIcon} />
      </Chip>
    </a>
  );
};

export default Requirements;
