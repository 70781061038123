import React from "react";
import Meta from "components/common/Meta";
import { AppContentContainer } from "components/common/layouts/appLayout/AppContentContainer";
import { PRIVATE_ROUTE_DETAILS } from "constants/routes";
import HomeCard, {
  HomeCardContainer,
} from "components/common/ui/card/HomeCard";
import PlanIllustration from "components/common/images/PlanIllustration";
import DoctorIllustration from "components/common/images/DoctorIllustration";
import AdminIllustration from "components/common/images/AdminIllustration";
import clientLogo from "assets/images/rnzcuc-logo.png";
import { SITE_LONG_NAME } from "constants/site";
import Accordian from "components/common/ui/accordian/Accordian";
import styles from "components/home/Home.module.scss";

const ViewHome = () => {
  return (
    <AppContentContainer
      className={styles.root}
      maxWidth={true}
      spacing="extra"
    >
      <Meta title={"Home"} />

      <img src={clientLogo} alt="Logo" className={styles.clientLogo} />
      <h1>Welcome to {SITE_LONG_NAME}</h1>
      <p className={styles.pageBlurb}>
        UCCIS will eventually be the central place for all College information
        and resources. It aims to streamline information flow across the College
        and make it easier for College members to access what they need.
      </p>
      <Accordian header={"Read more"} headerClassName={styles.readMore}>
        <br />
        <b>It currently includes:</b>
        <ul>
          <li>
            Your membership profile. If you notice information that’s not
            correct, you can edit most fields yourself.
          </li>{" "}
          <li>
            Continued Professional Development module covering recertification
            for Fellows and annual requirements for registrars. This was
            previously ePortfolio.
          </li>
        </ul>
        <br />
        <b>Coming Soon:</b>
        <ul>
          <li>
            Training programme support module for registrars and supervisors.
          </li>
          <li>Module to support the application process.</li>
          <li>
            Resources such as UCC, quizzes, other educational material which
            will link to CPD and training programme modules.
          </li>
          <li>
            Additional functions to optimise the support our office team
            provides to members.
          </li>
        </ul>
      </Accordian>
      <br />
      <HomeCardContainer>
        <HomeCard
          title={"Admin"}
          description={"Create and manage users"}
          route={PRIVATE_ROUTE_DETAILS.ADMIN_USERS}
          svg={AdminIllustration}
        />
        <HomeCard
          title={"My Profile"}
          description={"View your membership details & update your profile"}
          route={PRIVATE_ROUTE_DETAILS.MY_PROFILE}
          svg={DoctorIllustration}
        />
        {/*<HomeCard*/}
        {/*  title={"PDP"}*/}
        {/*  description={"[Add text]"}*/}
        {/*  route={PRIVATE_ROUTE_DETAILS.MY_PROFILE}*/}
        {/*  svg={ReportsIllustration}*/}
        {/*/>*/}
        <HomeCard
          title={"CPD"}
          description={
            "View & update your Continuing Professional Development Plan"
          }
          route={PRIVATE_ROUTE_DETAILS.MY_PLANS}
          svg={PlanIllustration}
        />
        <HomeCard
          title={"Training Programme"}
          description={"View & update your Training Programme Summary"}
          route={PRIVATE_ROUTE_DETAILS.MY_TPS}
          svg={PlanIllustration}
        />
        {/*<HomeCard*/}
        {/*  title={"Peer Groups"}*/}
        {/*  description={*/}
        {/*    "[Add text]"*/}
        {/*  }*/}
        {/*  route={PRIVATE_ROUTE_DETAILS.MY_PLANS}*/}
        {/*  svg={PlanIllustration}*/}
        {/*  // col={12}*/}
        {/*/>*/}

        {/*<HomeCard*/}
        {/*  title={"Learning Resources"}*/}
        {/*  description={*/}
        {/*    "Access learning resources and track your \n completion progress"*/}
        {/*  }*/}
        {/*  route={PRIVATE_ROUTE_DETAILS.LEARNING_RESOURCES}*/}
        {/*  svg={LearningIllustration}*/}
        {/*/>*/}
        {/*<HomeCard*/}
        {/*  title={"Reports"}*/}
        {/*  description={"Office reports, data extracts and summaries"}*/}
        {/*  route={PRIVATE_ROUTE_DETAILS.ADMIN_REPORTS}*/}
        {/*  svg={ReportsIllustration}*/}
        {/*/>*/}
      </HomeCardContainer>
    </AppContentContainer>
  );
};

export default ViewHome;
