import * as React from "react";

export function useClickOutside(
  ref: React.RefObject<any>,
  onClickOutside: () => void
) {
  const handleClick = (event: any) => {
    if (ref && ref.current) {
      if (event.target !== ref && !ref.current.contains(event.target)) {
        onClickOutside();
      }
    }
  };
  React.useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    // document.addEventListener("click", handleClick);
    document.addEventListener("touchstart", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
      // document.addEventListener("click", handleClick);
      document.addEventListener("touchstart", handleClick);
    };
  });
}
