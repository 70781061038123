import React from "react";
import { Supervisor } from "models/supervisors/Supervisor";
import FormattedQuestion from "components/common/ui/form-elements/display-elements/FormattedQuestion";
import { supervisorStatusQuestion } from "components/supervisors/utils/supervisorForm";
import styles from "./FormattedSupervisor.module.scss";

interface Props {
  className?: string;
  supervisor?: Supervisor;
}

const FormattedSupervisor = ({ supervisor }: Props) => (
  <>
    <h5>Supervisor</h5>
    {supervisor && supervisor?.email ? (
      <div className={styles.wrapRow}>
        <FormattedQuestion
          def={{
            name: "supervisor_name",
            type: "string",
            label: "Name",
          }}
          questions={{
            supervisor_name: `${supervisor.given_name} ${supervisor.family_name}`,
          }}
          loading={false}
        />

        <FormattedQuestion
          def={{
            name: "supervisor_email",
            type: "email",
            label: "Supervisor email",
          }}
          questions={{
            supervisor_email: supervisor.email,
          }}
          loading={false}
        />
        {supervisor.status && (
          <FormattedQuestion
            def={supervisorStatusQuestion}
            questions={{
              supervisor_status: supervisor.status,
            }}
            loading={false}
          />
        )}
      </div>
    ) : (
      <div>
        {" "}
        <i>There's no supervisor for this user</i>
      </div>
    )}
  </>
);

export default FormattedSupervisor;
