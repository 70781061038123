export const SITE_NAME = "UCCIS";
export const SITE_LONG_NAME = "Urgent Care College Information System (UCCIS)";
export const CLIENT_NAME = "RNZCUC";

export const SUPPORT_EMAIL = "support@synergia.app";
export const CONTACT_EMAIL = SUPPORT_EMAIL;

export const SENTRY_DSN =
  "https://92f24892a8fa47cd84eac9b7eb89e59c@o143981.ingest.sentry.io/6264569";
export const SENTRY_RELSEASE = `${process.env.REACT_APP_PROJECT_NAME}@${process.env.REACT_APP_VERSION}`;
