import React, { useState } from "react";
import { useLayer } from "react-laag";
import { useCombobox } from "downshift";
import { InputWrapper } from "components/common/ui/form-elements/common/InputWrapper";
import IconSearch from "components/common/icons/IconSearch";
import ResizeObserver from "resize-observer-polyfill";
import { MenuContext } from "components/common/ui/form-elements/autocomplete/MenuContext";
import { IMenuItem } from "components/common/ui/form-elements/autocomplete/MenuItem";
import Menu from "components/common/ui/form-elements/autocomplete/Menu";
import {
  getPredictions,
  SearchFunction,
  SearchValueKey,
} from "components/common/ui/form-elements/autocomplete/utils";
import { isObject } from "utils/common";
import ActiveOptionChip from "components/common/ui/select/ActiveOptionChip";
import ActionButton from "components/common/ui/button/ActionButton";
import { TError } from "components/common/ui/form-elements/formElements";
import styles from "./Autocomplete.module.scss";

interface Props<Item extends IMenuItem = IMenuItem> extends DefaultProps {
  name: string;
  label?: string;
  description?: string;
  disabled?: boolean;
  items: Item[];
  onSearch?: SearchFunction<Item>;
  selected: Item | undefined;
  onSelect: (item?: Item) => void;
  searchValueKey?: SearchValueKey<keyof Item>;

  children?: (provided: { items: Item[] }) => React.ReactNode;
  // children?: React.ReactNode;
  className?: string;
  /** The direction of the tooltip relative to the element (will move if there isn't enough space) */
  direction?:
    | "top-center"
    | "top-start"
    | "top-end"
    | "left-start"
    | "left-center"
    | "left-end"
    | "right-start"
    | "right-center"
    | "right-end"
    | "bottom-start"
    | "bottom-center"
    | "bottom-end"
    | "center";

  /** Should the arrow show */
  arrow?: boolean;

  /** How much should the tooltip be offset from the element */
  offset?: number;

  /** Override hover trigger and force open */
  // open?: boolean;

  /** Framer motion animation object */
  animation?: {
    initial: object;
    animate: object;
    exit: object;
    transition: object;
  };
  error?: TError;
}

const Combobox = <T extends IMenuItem = IMenuItem>({
  // ({
  name,
  children,
  className,
  onSearch,
  selected,
  onSelect,
  searchValueKey = "value",
  direction = "bottom-center",
  offset = 8,
  disabled,
  items,
  label,
  description,
  error,
}: Props<T>) => {
  // }: Props) => {
  const [filteredItems, setFilteredItems] = useState(items);
  const [inputValue, setInputValue] = useState<string | undefined>("");
  const options = onSearch ? items : filteredItems;
  const search = onSearch || getPredictions;

  const {
    isOpen,
    getMenuProps,
    getLabelProps,
    getInputProps,
    getComboboxProps,
    getItemProps,
    getToggleButtonProps,
    highlightedIndex,
    // inputValue,
  } = useCombobox({
    inputValue,
    items: options,
    defaultHighlightedIndex: 0, // after selection, highlight the first item.
    itemToString: (d: any) => (isObject(d) ? d?.value : d),
    onInputValueChange: ({ inputValue }) => {
      if (!onSearch) {
        setFilteredItems(search(inputValue, items, searchValueKey) || []);
      } else {
        search(inputValue);
      }
    },
    // onSelectedItemChange: onSelect,
    // onSelectedItemChange: (),
    selectedItem: null,
    onStateChange: ({ inputValue, type, selectedItem }) => {
      switch (type) {
        case useCombobox.stateChangeTypes.InputChange:
          setInputValue(inputValue);
          break;
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputBlur:
          setInputValue("");
          if (selectedItem) {
            onSelect(selectedItem);
          }
          break;
        default:
          break;
      }
    },
  });

  // the positioning stuff...
  const { renderLayer, triggerProps, layerProps, triggerBounds, layerSide } =
    useLayer({
      isOpen,
      overflowContainer: true, // we want the menu to stay within its scroll-container
      auto: true, // auto find a placement when required
      snap: true, // snap to the possible placements (not in between)
      placement: direction, // we prefer placement on the bottom-side
      possiblePlacements: ["top-start", "bottom-start"], // stick with bottom and top
      triggerOffset: 0, // place the menu directly to the trigger
      containerOffset: offset, // make sure the menu gets a bit of space with respect to the containers edges
      ResizeObserver,
      // onOutsideClick: toggle,
      // onDisappear: toggle,
    });
  return (
    <>
      <InputWrapper
        label={label}
        description={description}
        name={name}
        labelProps={getLabelProps()}
        inputWrapperProps={getComboboxProps()}
        className={className}
        disabled={disabled}
        error={error}
      >
        <div className={styles.wrap} {...triggerProps}>
          {selected && (
            <ActiveOptionChip
              key={selected.value}
              id={selected.value}
              label={selected.label}
              theme={disabled ? "grey" : "primary"}
              className={styles.active}
              onClick={() => onSelect()}
              cancelIcon={!disabled}
            />
          )}
          <ActionButton
            icon={<IconSearch />}
            variant="empty"
            className={styles.searchIcon}
            {...getToggleButtonProps()}
          />

          <input type={"text"} {...getInputProps()} />

          <div className={styles.bg} />
        </div>
      </InputWrapper>

      <MenuContext.Provider
        value={{
          name,
          isOpen,
          highlightedIndex,
          selectedItem: selected,
          getItemProps,
          layerSide,
          menuProps: getMenuProps(layerProps),
          triggerBounds,
          offset,
          items: options,
          query: inputValue,
          isMultiselect: false,
        }}
      >
        {renderLayer(children ? children({ items: options }) : <Menu />)}
      </MenuContext.Provider>
    </>
  );
};

const MemoCombobox = React.memo(Combobox) as typeof Combobox;

export default MemoCombobox;
