import React from "react";
import cx from "classnames";
import { useLayer } from "react-laag";
import { useSelect } from "downshift";
import { InputWrapper } from "components/common/ui/form-elements/common/InputWrapper";
import { MenuContext } from "components/common/ui/form-elements/autocomplete/MenuContext";
import { IMenuItem } from "components/common/ui/form-elements/autocomplete/MenuItem";
import {
  defaultLayerProps,
  itemToString,
  SelectProps,
} from "components/common/ui/form-elements/select/common";
import Menu from "components/common/ui/form-elements/autocomplete/Menu";
import ActiveOptionChip from "components/common/ui/select/ActiveOptionChip";
import ActionButton from "components/common/ui/button/ActionButton";
import IconExpandArrow from "components/common/icons/IconExpandArrow";
import styles from "./Select.module.scss";

const Select = React.memo(
  React.forwardRef(
    <T extends IMenuItem = IMenuItem>({
      name,
      children,
      className,
      selected,
      onSelect,
      direction = "bottom-center",
      offset = 8,
      disabled,
      items,
      label,
      error,
      description,
      placeholder = "Select an option",
    }: SelectProps<T>) =>
      // ref: React.Ref<any> //TODO: figure out where ref should be passed to
      {
        const {
          isOpen,
          getMenuProps,
          // selectedItem,
          getLabelProps,
          getItemProps,
          getToggleButtonProps,
          highlightedIndex,
          // inputValue,
        } = useSelect({
          items,
          selectedItem: selected || null,
          onSelectedItemChange: onSelect,
          itemToString,
        });

        // the positioning stuff...
        const {
          renderLayer,
          triggerProps,
          layerProps,
          triggerBounds,
          layerSide,
        } = useLayer({
          ...defaultLayerProps,
          isOpen,
          placement: direction,
          containerOffset: offset,
        });
        return (
          <>
            <InputWrapper
              label={label}
              description={description}
              name={name}
              labelProps={getLabelProps()}
              // inputWrapperProps={getDropdownProps()}
              className={className}
              disabled={disabled}
              error={error}
            >
              <button
                type="button"
                className={cx(styles.wrap, styles.trigger)}
                {...getToggleButtonProps({ ...triggerProps, disabled })}
              >
                {selected ? (
                  <ActiveOptionChip
                    key="selected"
                    id={selected.value}
                    label={selected.label}
                    theme={disabled ? "grey" : selected.theme}
                    color={selected.color}
                    className={styles.selected}
                    disabled={disabled}
                  />
                ) : (
                  <span className={styles.placeholder}>{placeholder}</span>
                )}
                <ActionButton
                  icon={<IconExpandArrow />}
                  variant="empty"
                  className={styles.expandIcon}
                  disabled
                />
              </button>
            </InputWrapper>
            <MenuContext.Provider
              value={{
                name,
                isOpen,
                highlightedIndex,
                selectedItem: selected,
                getItemProps,
                layerSide,
                menuProps: getMenuProps(layerProps),
                triggerBounds,
                offset,
                items,
                // query: inputValue,
                isMultiselect: false,
              }}
            >
              {renderLayer(children ? children({ items }) : <Menu />)}
            </MenuContext.Provider>
          </>
        );
      }
  )
);

export default Select;
