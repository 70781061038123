import React from "react";
import cx from "classnames";
import Container, { ContainerProps } from "components/common/layouts/Container";
import styles from "./PageHeader.module.scss";

interface Props extends Omit<ContainerProps, "children"> {
  children?: any;
  header: React.ReactNode;
  actions?: React.ReactNode[];
}
const PageHeader = ({
  header,
  actions,
  children,
  className,
  ...rest
}: Props) => (
  <Container className={cx(styles.pageHeader, className)} {...rest}>
    <div className={styles.content}>
      <h1>{header}</h1>
      {children && <div className={styles.item}>{children}</div>}
    </div>

    <div className={styles.actionContainer}>
      {actions &&
        actions.map((a, i) => (
          <div key={i} className={styles.actions}>
            {a}
          </div>
        ))}
    </div>
  </Container>
);

export default PageHeader;
