import React from "react";
import cx from "classnames";
import { motion } from "framer-motion";
import styles from "./Progress.module.scss";

interface Props extends DefaultProps {
  value: number;
  theme: ThemeVariants;
}

export const Progress = ({ value, theme }: Props) => (
  <div className={cx(styles.progressWrapper, styles[theme])}>
    <motion.div
      className={styles.progress}
      initial={{ width: 0 }}
      animate={{
        width: `${Math.min(Math.max(value, 0), 100)}%`,
      }}
      transition={{ ease: "linear", duration: 0.2 }}
    />
  </div>
);
