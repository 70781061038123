import React from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import FormActions from "components/common/ui/form-elements/form-layout/FormActions";
import { PeerGroup } from "models/peergroup/PeerGroup";
import { PeerGroupMember } from "models/peergroup/member";
import UserSearchQuestion from "components/users/common/UserSearchQuestion";
import { SelectHookForm } from "components/common/ui/form-elements/hook-form-elements/SelectHookForm";
import { UserSearch } from "models/user/User";
import { getSchema } from "utils/formUtils";
import { useMutateUpdateMemberInPeerGroup } from "components/peergroup/hooks/useMutateUpdateMemberInPeerGroup";

// const resolver = getSchema({
//   user_search: Yup.object({
//     value: Yup.string().required("Please select a Member"),
//   }).nullable(),
//   peergroup_role_id: Yup.string().required("Please select a Role"),
// });
const resolver = getSchema({
  peergroup_role_id: Yup.string().required("Please select a Role"),
});

interface Props {
  saveText?: string;
  peergroup: PeerGroup;
  onCancel: () => void;
  children?: React.ReactNode;
  currentMember?: PeerGroupMember;
}

const AddMemberToPeerGroup = ({
  onCancel,
  saveText,
  peergroup,
  currentMember,
}: Props) => {
  const { mutateAsync: Update, isLoading: updateLoading } =
    useMutateUpdateMemberInPeerGroup();
  const {
    handleSubmit,
    formState: { errors },
    register,
    control,
  } = useForm({
    mode: "onSubmit",
    resolver,
  });
  const handleSave = async ({
    peergroup_role_id,
    user_search,
  }: {
    user_search: UserSearch;
    peergroup_role_id: string;
  }) => {
    await Update({
      currentMember,
      peergroup_role_id,
      user_id: currentMember?.user_id ?? user_search.value,
      peergroupId: peergroup.id,
      deleted: false,
    });

    onCancel();
  };

  return (
    <>
      <form onSubmit={handleSubmit(handleSave)} noValidate>
        {!currentMember && (
          <UserSearchQuestion name="user_search" control={control} />
        )}

        <SelectHookForm
          question={{
            name: "peergroup_role_id",
            label: "Peer group Role",
            type: "enum",
            options:
              peergroup.peergroup_roles?.map((r) => ({
                value: r.peergroup_role_id || "",
                label: r.peergroup_role_name,
              })) ?? [],
          }}
          error={errors?.peergroup_role_id}
          control={control}
          register={register}
          defaultValue={currentMember?.peergroup_member_roles?.[0].value}
        />
        <FormActions
          onCancel={onCancel}
          isSubmitting={updateLoading}
          submitText={
            saveText ||
            `${currentMember ? "Update" : "Add"} Member${
              currentMember ? "" : "(s)"
            }`
          }
        />
      </form>
    </>
  );
};

export default AddMemberToPeerGroup;
