import React, { useReducer } from "react";
import {
  PathwayCategoryRequirements,
  PathwayRequirements,
} from "models/cpd/common/requirements";
import { activityCategoryOptions, Category } from "models/cpd/common/common";
import Button from "components/common/ui/button/Button";
import Modal from "components/common/ui/modal/Modal";
import AddRequirement from "components/cpd/common/AddRequirement";
import Select from "components/common/ui/select/Select";

interface Props extends DefaultProps {
  requirements: PathwayRequirements;
  setRequirements: (requirements: PathwayRequirements) => void;
}

const categoryOptions = activityCategoryOptions.map((c) => ({
  ...c,
  key: c.value,
}));

interface State {
  open: boolean;
  category: Category | undefined;
}

const reducer = (
  state: State,
  action: { type: "open" | "close" | "setCategory"; value?: Category }
) => {
  switch (action.type) {
    case "open":
      return { open: true, category: undefined };
    case "close":
      return { open: false, category: undefined };
    case "setCategory":
      return { ...state, category: action.value };
  }
};

const EditCustomRequirements = ({
  requirements,
  // category,
  setRequirements,
}: Props) => {
  // const [open, setOpen] = useState<boolean>(false);
  // const [cat, setCat] = useState<Category | undefined>(undefined);
  const [state, dispatch] = useReducer(reducer, {
    open: false,
    category: undefined,
  });

  const updateRequirements = (
    category: Category,
    categoryRequirement: PathwayCategoryRequirements
  ) => {
    setRequirements({ ...requirements, [category]: categoryRequirement });
    dispatch({ type: "close" });
  };

  return (
    <div style={{ textAlign: "right" }}>
      {" "}
      <Button theme={"primary"} onClick={() => dispatch({ type: "open" })}>
        Add new requirement
      </Button>
      <Modal open={state.open} onClose={() => dispatch({ type: "close" })}>
        <h2>Add New Requirement</h2>
        {/*<AddRequirement*/}
        {/*  requirements={requirements[category]}*/}
        {/*  category={category}*/}
        {/*  setRequirements={updateRequirements}*/}
        {/*  onCancel={() => setOpen(false)}*/}
        {/*/>*/}
        <Select
          name={"category"}
          value={state.category || ""}
          onSelect={(name, value) => dispatch({ type: "setCategory", value })}
          label={"Category"}
          options={categoryOptions}
          disabled={!!state.category}
        />
        {state.category && (
          <AddRequirement
            key={state.category}
            requirements={requirements[state.category]}
            category={state.category}
            setRequirements={updateRequirements}
            onCancel={() => dispatch({ type: "close" })}
          />
        )}

        {/*<Button*/}
        {/*  onClick={() => setRequirements((s) => ({ ...s, triennial: [] }))}*/}
        {/*>*/}
        {/*<Button*/}
        {/*  onClick={() => setRequirements(s=>({...s,[category]:newReq}))}*/}
        {/*>*/}
        {/*  Add New*/}
        {/*</Button>*/}
      </Modal>
    </div>
  );
};

// const Requirement = ({
//   req,
//   onChange,
// }: {
//   req: TRequirement;
//   onChange: (newReq: TRequirement) => void;
// }) => {
//   console.log("REQ", req);
//   return (
//     <div>
//       <Select
//         name={"type"}
//         value={req.type}
//         onSelect={(name, value, config) => {
//           onChange({ ...req, type: value });
//         }}
//         label={"Type"}
//         options={requirementTypeOptions.map((o) => ({ ...o, key: o.value }))}
//       />
//     </div>
//   );
// };

export default EditCustomRequirements;
