import React, { Ref } from "react";
import cx from "classnames";
import styles from "./ActionButton.module.scss";

import Button, { TButton } from "./Button";

export interface IIconButton extends TButton {
  icon: React.ReactNode;
  iconClass?: string;
  iconVariant?: FillVariants;
}

const ActionButton = React.forwardRef(
  (
    {
      icon,
      iconClass,
      iconVariant = "filled",
      className,
      children,
      ...rest
    }: IIconButton,
    ref: Ref<HTMLButtonElement>
  ) => {
    const variant = `icon-${iconVariant}`;
    return (
      <Button {...rest} className={cx(styles.root, className)} ref={ref}>
        <div className={cx(styles.icon, styles[variant], iconClass)}>
          {icon}
        </div>
      </Button>
    );
  }
);

export default ActionButton;
