import React from "react";
import cx from "classnames";
import { motion, AnimatePresence } from "framer-motion";
import styles from "./Menu.module.scss";
import { useMenuContext } from "components/common/ui/form-elements/autocomplete/MenuContext";
import { MenuItem } from "components/common/ui/form-elements/autocomplete/MenuItem";
import { LayerSide } from "react-laag/dist/types";

interface Props extends DefaultProps {
  width?: "parent" | "fit-content" | number;

  /** Text/element to display if no items are found */
  noMatch?: React.ReactNode;

  /** Should the arrow show */
  arrow?: boolean;

  /** Override hover trigger and force open */
  // open?: boolean;
}

const Menu = React.memo(
  ({
    width = "parent",
    children,
    className,
    noMatch = "No results found",
  }: Props) => {
    const { isOpen, layerSide, triggerBounds, menuProps, offset, items } =
      useMenuContext();

    const styleWidth =
      width === "parent"
        ? triggerBounds?.width
        : typeof width === "number"
        ? width
        : "fit-content";

    let style = {
      ...menuProps.style,
      opacity: isOpen ? 1 : 0,
      // width: styleWidth,
      height: "fit-content",
    } as Record<string, any>;

    const variants = {
      initial: { opacity: 0, ...inTransform[layerSide] },
      visible: {
        opacity: 1,
        ...visibleTransform(layerSide, offset),
      },
      hidden: {
        opacity: 0,
        ...outTransform[layerSide],
        transition: {
          when: "afterChildren",
        },
      },
    };
    return (
      <motion.ul
        className={cx(className, styles.menu, isOpen && styles.open)}
        //{...getMenuProps()}
        {...menuProps}
        style={style}
        variants={variants}
        animate={isOpen ? "visible" : "hidden"}
        transition={{
          type: "tween",
          duration: 0.1,
        }}
      >
        <AnimatePresence>
          {isOpen &&
            (children || items.length > 0 ? (
              items.map((m, i) => (
                <MenuItem
                  key={m.value}
                  {...m}
                  index={i}
                  style={{ width: styleWidth }}
                />
              ))
            ) : (
              <motion.div
                style={{ width: styleWidth }}
                className={styles.noMatch}
              >
                {noMatch}
              </motion.div>
            ))}
        </AnimatePresence>
      </motion.ul>
    );
  }
);

const inTransform = {
  top: { x: 0, y: -20 },
  left: { x: -20, y: 0 },
  bottom: { x: 0, y: 20 },
  right: { x: 20, y: 0 },
  center: { x: 0, y: 20 },
};
const outTransform = {
  top: { x: 0, y: -20, height: 0 },
  left: { x: -20, y: 0, width: 0 },
  bottom: { x: 0, y: 0, height: 0 },
  right: { x: 20, y: 0, width: 0 },
  center: { x: 0, y: 20, height: 0 },
};
const visibleTransform = (layerSide: LayerSide, offset: number) =>
  ({
    top: { x: 0, y: -offset },
    left: { x: -offset, y: 0 },
    bottom: { x: 0, y: offset },
    right: { x: offset, y: 0 },
    center: { x: 0, y: offset },
  }[layerSide]);

export default Menu;
