import { useQuery } from "react-query";
import { QueryOptions } from "queries/queries";
import { useToast } from "components/common/ui/toast/useToast";
import { fetchManyMeetings } from "api/PeerGroupApi";
import {
  PeerGroupMeeting,
  PeergroupMeetingStatus,
} from "models/peergroup/meeting";
import { formatDate } from "utils/format/dateUtils";
import { IMenuItem } from "components/common/ui/form-elements/autocomplete/MenuItem";

interface Filters {
  start?: Date | string;
  end?: Date | string;
}

export const usePeerGroupActivityMeetings = (
  filters: Filters,
  userId: string,
  options?: QueryOptions
) => {
  const { addErrorToast } = useToast();
  return useQuery(
    //TODO: should probably be a queryKeyFactory
    ["activity", "peer_group_meetings", filters],
    async () =>
      transformMeetingsToMenuItem(await fetchManyMeetings(filters, userId)),
    {
      onError: (e) =>
        addErrorToast(e, "Unable to load peer group meetings", {
          id: ["activity", "peer_group_meetings"].join("::"),
        }),
      staleTime: 60 * 1000,
      ...options,
    }
  );
};

export type IPeergroupMeetingItem = IMenuItem<string, PeerGroupMeeting>;

const transformMeetingsToMenuItem = (
  meetings: PeerGroupMeeting[] = []
): IPeergroupMeetingItem[] =>
  meetings?.map((m) => ({
    value: m.id || "",
    label: `${
      m.peergroup_meeting_status !== PeergroupMeetingStatus.COMPLETE
        ? `[${m.peergroup_meeting_status}] `
        : ""
    }${m.peergroup_meeting_title} (${
      formatDate(m.peergroup_meeting_schedule) || ""
    })`,

    date: formatDate(m.peergroup_meeting_schedule) || "",
    metadata: m,
  }));
