import { differenceInCalendarMonths, getDaysInMonth } from "date-fns";
import { Location } from "history";
import {
  PATHWAY,
  PathwayRequirements,
  RequirementType,
  TProgressItem,
  TRequirement,
} from "models/cpd/common/requirements";
import {
  activityCategoryOptions,
  Category,
  subCategoryOptions,
} from "models/cpd/common/common";
import {
  Activity,
  ActivityListShape,
  formatActivity,
  IServerActivity,
} from "models/cpd/Activity";
import { Plan } from "models/cpd/Plan";
import { CPD_ROUTES } from "constants/routes/cpd";
import { TUser } from "models/user/User";
import { isRegistrar } from "components/users/utils/userTypeUtils";
import { convertToDate, daysDiff, monthsDiff } from "utils/format/dateUtils";
import { PlanYear } from "models/cpd/Plan";
import { keyBy, nest } from "utils/common";

export const formatPlanProgress = (requirements: PathwayRequirements) => {
  const annual: TProgressItem[] = [];
  const triennial: TProgressItem[] = [];
  activityCategoryOptions.forEach(({ value, label, unitType }) => {
    const req = requirements[value];
    if (req?.annual) {
      req?.annual?.forEach((d) => {
        annual.push({ category: value, label, unitType, ...d });
      });
    }
    if (req?.triennial) {
      req?.triennial?.forEach((d) => {
        triennial.push({ category: value, label, unitType, ...d });
      });
    }
  });
  annual.sort((a, b) => b.credits - a.credits);
  triennial.sort((a, b) => b.credits - a.credits);

  return { annual, triennial };
};

export const calcPlanProgress = (
  req: PathwayRequirements,
  activity: ActivityListShape,
  duration: number
) => {
  const activityByCategory = activity.keyByCategory;
  const keys = Object.keys(req) as Category[];
  const progress: PathwayRequirements = { ...req };

  keys.forEach((k) => {
    const requirement = { ...req[k] };
    const p = activityByCategory[k]?.map((l) => activity.map[l]);

    if (requirement && requirement.triennial) {
      requirement.triennial = calcReqProgress(requirement.triennial, p);
    }
    if (requirement && requirement.annual) {
      for (let y = 1; y <= duration; y++) {
        requirement.annual = calcReqProgress(requirement.annual, p, y);
      }
    }
    progress[k] = requirement;
  });

  //TODO: `calcAdditionalCPD` currently edits in place rather than creating new copies
  //Calc overflow CPD from `edu` activity
  calcAdditionalCPD(
    progress[Category.additional]?.triennial?.[0],
    progress[Category.edu]?.annual?.[0]
  );
  //Calc overflow CPD from `peer` activity
  calcAdditionalCPD(
    progress[Category.additional]?.triennial?.[0],
    progress[Category.peer]?.annual?.[0]
  );

  const overdue = activity.keys
    .filter((k) => {
      const level = getOverdueStatus(activity.map[k]).level;
      return !!level && level !== "success";
    })
    .map((k) => activity.map[k]);

  return { ...formatPlanProgress(progress), overdue };
};

const calcReqProgress = (
  req: TRequirement[],
  activity: Activity[] = [],
  year?: number
) => {
  const a = year
    ? activity.filter((a) => a.year === year && !a.exclude_annual)
    : activity.filter((a) => !a.exclude_triennium);
  const planned = a.filter((d) => !d.completed_at && isFutureActivity(d));
  const completed = a.filter((d) => !!d.completed_at && !d.waive_req);
  const waived = a.filter((d) => !!d.completed_at && d.waive_req);

  return req.map((t) => {
    let completeSum = calcProgressSum(completed, t);
    let plannedSum = calcProgressSum(planned, t);
    let waivedSum = calcProgressSum(waived, t);

    if (year) {
      const year_progress = t.year_progress || [];
      year_progress[year - 1] = completeSum;

      const waived_year_progress = t.waived_year_progress || [];
      waived_year_progress[year - 1] = waivedSum;

      const planned_year_progress = t.planned_year_progress || [];
      planned_year_progress[year - 1] = plannedSum;

      return {
        ...t,
        year_progress,
        planned_year_progress,
        waived_year_progress,
      };
    }

    return {
      ...t,
      progress: completeSum,
      planned_progress: plannedSum,
      waived_progress: waivedSum,
    };
  });
};

const calcProgressSum = (activity: Activity[], req: TRequirement) => {
  switch (req.type) {
    case RequirementType.any:
      return activity.reduce((s, d) => s + Number(d.credits), 0);

    case RequirementType.exact:
      return activity
        .filter((d) => d.subcategory === req.option)
        .reduce((s, d) => s + Number(d.credits), 0);

    case RequirementType.multi:
      return activity
        .filter((d) => d.subcategory && req.options.includes(d.subcategory))
        .reduce((s, d) => s + Number(d.credits), 0);

    case RequirementType.distinct:
      const s = new Set(
        activity
          .filter((d) => d.subcategory && req.options.includes(d.subcategory))
          .map((d) => d.subcategory)
      );
      return s.size;
  }
  return 0;
};

const calcAdditionalCPD = (cpd?: TRequirement, annualReq?: TRequirement) => {
  if (annualReq?.year_progress && cpd) {
    annualReq.year_progress = annualReq?.year_progress.map((n) => {
      if (n > annualReq.credits) {
        cpd.progress = (cpd?.progress || 0) + (n - annualReq.credits);
        return annualReq.credits;
      }
      return n;
    });
  }
  if (annualReq?.planned_year_progress && cpd) {
    annualReq.planned_year_progress = annualReq?.planned_year_progress.map(
      (n, i) => {
        const actualProgress = annualReq.year_progress?.[i] || 0;
        const estimatedProgress = actualProgress + n;
        if (estimatedProgress > annualReq.credits) {
          cpd.planned_progress =
            (cpd?.planned_progress || 0) +
            (estimatedProgress - annualReq.credits);
          return annualReq.credits - actualProgress;
        }
        return n;
      }
    );
  }
};

// get the difference in full months between two dates
// then if the `date` is more than half way through its month, add 0.5
export const calcMonthsSinceStart = (date?: Date | null, start?: Date | null) =>
  date && start
    ? differenceInCalendarMonths(date, start) +
      // (date.getDate() > getDaysInMonth(date) / 2 ? 1.5 :1)
      (date.getDate() > getDaysInMonth(date) / 2 ? 0.5 : 0)
    : -1;

interface SubmittedVariantProps {
  start: Date | string | null;
  target?: Date | string | null;

  fallback?: ThemeVariants;
}

export const getReviewWarningVariant = ({
  start,
  target = new Date(),
  fallback,
}: SubmittedVariantProps): ThemeVariants | undefined => {
  const mDiff = monthsDiff(target, start);
  if (!mDiff || mDiff > 4) {
    return fallback;
  }

  return mDiff < 0 ? "danger" : mDiff < 3 ? "warning" : "info";
};

export const getCategoryLabel = (a: Activity) => {
  if (a.topic) {
    return a.topic;
  }
  if (a.category) {
    if (a.subcategory) {
      return (
        subCategoryOptions[a.category]?.find((s) => s.value === a.subcategory)
          ?.label || ""
      );
    }
    return (
      activityCategoryOptions.find((c) => c.value === a.category)?.label || ""
    );
  }
  return "";
};

export interface OverdueStatus {
  level: ThemeVariants | undefined;
  fill?: ThemeVariants | undefined;
  completedRequiredCredits?: boolean;
  daysLate?: number;
}

export const getOverdueStatus = (
  activity: Partial<Activity>
): OverdueStatus => {
  if (!activity.is_required) {
    return { level: undefined };
  }
  if (activity.completed) {
    const completedRequiredCredits =
      !activity.required_credits ||
      (activity?.credits || 0) >= activity.required_credits;

    const daysLate = daysDiff(
      activity.required_by || null,
      activity.completed_at
    );
    const completedOnTime = daysLate && daysLate < 1;

    return {
      completedRequiredCredits,
      daysLate,
      fill: "success",
      // completedOnTime && completedRequiredCredits ? "success" : "warning",
      level:
        completedOnTime && completedRequiredCredits ? "success" : "warning",
    };
  }
  return { level: "danger", fill: "danger" };
};

export const getPathwayCategoryOptions = (
  pathway: PATHWAY,
  overrideCategories: Category[] = []
) =>
  activityCategoryOptions.filter((c) =>
    c.restrictedPathway
      ? c.restrictedPathway.includes(pathway) ||
        overrideCategories.includes(c.value)
      : true
  );

export const getSubcategory = (category?: Category, subcategory?: string) =>
  category &&
  subCategoryOptions[category]?.find((sc) => sc.value === subcategory);

const _TODAY = new Date(Date.now() - 86400000); //yesterday: : 24 * 60 * 60 * 1000
export const isFutureActivity = (a: Activity) =>
  a.planned_at && a.planned_at >= _TODAY;

export const checkTPS = (loc: Location<unknown>, plan?: Plan, user?: TUser) => {
  const isTPSLocation = loc.pathname.includes(CPD_ROUTES.MY_TPS.path);
  const isPathwayTPS = plan ? plan.pathway === PATHWAY.training : isTPSLocation;
  const isUserRegistrar = user ? isRegistrar(user) : isTPSLocation;

  return {
    isTPSLocation,
    isPathwayTPS,
    isTPS: isPathwayTPS || isUserRegistrar,
  };
};

export const calculateActivityYear = (
  a: IServerActivity,
  planYears?: PlanYear[]
) => {
  const formatted = formatActivity(a);
  if (!planYears) {
    return formatted;
  }
  const activityDate = formatted.completed_at ?? (formatted.planned_at as Date); // will always have either `completed_at` or `planned_at`
  const planStart = convertToDate(planYears[0].start);
  const planEnd = convertToDate(planYears[planYears.length - 1].end);

  let year = formatted.year || 1;

  if (planStart && activityDate <= planStart) {
    year = planYears[0].year;
  } else if (planEnd && activityDate >= planEnd) {
    year = planYears[planYears.length - 1].year;
  } else {
    const yearMatch = planYears.find((y) => {
      const start = convertToDate(y.start);
      const end = convertToDate(y.end);
      return start && activityDate >= start && end && activityDate <= end;
    });
    year = yearMatch?.year || year;
  }
  return { ...formatted, year };
};

export const formattedActivities = (
  activities: IServerActivity[],
  planYears?: PlanYear[]
) => {
  const formattedData = activities
    .map((a) => calculateActivityYear(a, planYears))
    .sort((a: Activity, b: Activity) => {
      if (a.completed === b.completed) {
        return (
          new Date(b.completed_at || b.planned_at || 0).getTime() -
          new Date(a.completed_at || a.planned_at || 0).getTime()
        );
      }
      return a.completed ? -1 : 1;
    }) as Activity[];

  return {
    map: keyBy(formattedData, "id"),
    keys: formattedData.map((d: Activity) => d.id),
    keyByCategory: nest(formattedData, ["category"], (d) => d.id) as Record<
      Category,
      string[]
    >,
    keyByYear: nest(formattedData, ["year"], (d) => d.id),
    keyByYearByGoal: nest(formattedData, ["year", "goal_id"], (d) => d.id),
    keyByYearByCategory: nest(formattedData, ["year", "category"], (d) => d.id),
  };
};
