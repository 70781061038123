import { CombinedUserTypes, UserType } from "models/user/attributes/userType";
import { TUser } from "models/user/User";
import { userTypeKey } from "models/user/attributes/common";

export const verifyRole = (
  user?: TUser,
  requiredType?: UserType | UserType[]
) =>
  user &&
  (user[userTypeKey] as UserType) &&
  (!requiredType ||
    (typeof requiredType === "string" && requiredType === user[userTypeKey]) ||
    (Array.isArray(requiredType) &&
      (requiredType.length === 0 ||
        requiredType.some((r) => r === user[userTypeKey]))));

export const isRegistrar = (user?: TUser) =>
  verifyRole(user, CombinedUserTypes.registrar);

export const isFellow = (user?: TUser) =>
  verifyRole(user, CombinedUserTypes.fellow);
