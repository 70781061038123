import React from "react";
import { useForm } from "react-hook-form";
import {
  facilityFormSchema,
  facilityFormQuestionIDs,
} from "models/facility/FacilityForm";
import { Facility } from "models/facility/Facility";
import FormSubSection from "components/common/ui/form-elements/form-layout/FormSubSection";
import {
  facilityAttributesMap,
  defaultFacilityValues,
} from "models/facility/attributes";
import FormActions from "components/common/ui/form-elements/form-layout/FormActions";

interface Props {
  saveText?: string;
  facility?: Facility;
  onSubmit: (attributes: Partial<Facility>) => void;
  onCancel: (attributes: Partial<Facility>) => void;
  isSubmitting?: boolean;
  children?: React.ReactNode;
}

const FacilityCreateForm = ({
  onSubmit,
  onCancel,
  saveText = "Update Facility",
  isSubmitting,
}: Props) => {
  const { handleSubmit, formState, register, control } = useForm({
    mode: "onSubmit",
    defaultValues: defaultFacilityValues,
    resolver: facilityFormSchema,
    // ...defaultSettings,
  });
  const { errors } = formState;
  const handleSave = async (attributes: Partial<Facility>) => {
    await onSubmit(attributes);
  };

  return (
    <>
      <form onSubmit={handleSubmit(handleSave)} noValidate>
        <FormSubSection
          questionMap={facilityAttributesMap}
          hookForm={{
            register,
            control,
            errors,
          }}
          key="createFacility"
          questions={facilityFormQuestionIDs}
          isAdmin
        />
        <FormActions
          onCancel={onCancel}
          isSubmitting={isSubmitting}
          submitText={saveText}
        />
      </form>
    </>
  );
};

export default FacilityCreateForm;
