import React from "react";
import { Activity } from "models/cpd/Activity";
import HintBox from "components/common/ui/hint-box/HintBox";
import IconAsterisk from "components/common/icons/IconAsterisk";
import { formatDate } from "utils/format/dateUtils";
import { getOverdueStatus } from "components/cpd/utils/utils";

interface Props {
  activity: Activity;
}

interface Status {
  level: ThemeVariants;
  heading: string;
  message: React.ReactNode;
}

const RequiredBox = ({ activity }: Props) => {
  if (!activity.is_required) {
    return null;
  }
  const status = getStatus(activity);

  return (
    <HintBox theme={status.level} icon={<IconAsterisk />}>
      <div>
        <b>{status.heading}</b>
        <br />
        {status.message}
      </div>
    </HintBox>
  );
};

const getStatus = (activity: Activity): Status => {
  const status = getOverdueStatus(activity);
  if (status.level === "danger") {
    return {
      level: status.level,
      heading: "Activity is Required",
      message: (
        <>
          This activity must be completed by{" "}
          <b>{formatDate(activity.required_by)}</b>
          {activity.required_credits &&
            ` with a minimum of ${activity.required_credits} credit${
              activity.required_credits > 1 ? "s" : ""
            }`}
        </>
      ),
    };
  }

  return {
    level: status.level || "warning",
    heading:
      status.level === "success" ? "Requirements met" : "Requirements not met",
    message:
      status.level === "success" ? null : (
        <>
          {!status.completedRequiredCredits && (
            <>
              Required a minimum of {activity.required_credits} credit(s), but
              only{" "}
              {activity.credits === 1
                ? `${activity.credits} credit was `
                : `${activity.credits} credits were `}
              completed.
              <br />
            </>
          )}
          {status.daysLate && status.daysLate > 0 && (
            <>
              Activity was not completed by the due date -{" "}
              {formatDate(activity.required_by)} ({status.daysLate} day
              {status.daysLate > 1 && "s"} late).
            </>
          )}
        </>
      ),
  };
};

export default RequiredBox;
