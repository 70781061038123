import React from "react";
import { useForm } from "react-hook-form";
import PasswordTextInput from "components/common/ui/form-elements/text-input/PasswordTextField";
import TextInput from "components/common/ui/form-elements/text-input/TextField";
import HeroButton from "components/common/ui/hero-button/HeroButton";
import { getSchema } from "utils/formUtils";
import {
  username,
  password,
} from "components/common/auth/forms/questionSchema";
import styles from "./Forms.module.scss";

const cleanError = (e: string) => {
  switch (e) {
    case "Incorrect username or password.":
      return "Incorrect username or password.";

    case "User does not exist.":
      return "User does not exist.";

    default:
      return e;
  }
};

const schema = getSchema({ username, password });

interface Props {
  onSubmit: () => void;
  onInputChange: (evt: any) => void;
  values: any;
  errorMessage: string | null;
  changeState: (state: string) => void;
}

const SignInForm = ({ onSubmit, values, errorMessage, changeState }: Props) => {
  let defaultValues = {
    username: values.username || "",
    password: values.password || "",
  };
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm({
    mode: "onBlur",
    resolver: schema,
    defaultValues: defaultValues,
  });
  const submit = (data: any) => {
    values.username = data.username;
    values.password = data.password;
    onSubmit();
  };

  return (
    <form onSubmit={handleSubmit(submit)} aria-label="signin" noValidate>
      {errorMessage && (
        <div className={styles.error}>Error: {cleanError(errorMessage)}</div>
      )}
      <TextInput
        {...register("username")}
        label="Email"
        error={errors["username"]}
        autoComplete="username"
      />

      <PasswordTextInput
        {...register("password")}
        label="Password"
        error={errors["password"]}
        autoComplete="current-password"
      />

      <div className={styles.formActions}>
        <HeroButton type="submit">Sign in</HeroButton>
        <button onClick={() => changeState("forgotPassword")} className="link">
          Forgot Password?
        </button>
      </div>
    </form>
  );
};

export default SignInForm;
