import React from "react";
import Meta from "components/common/Meta";
import AuthLayout from "components/common/layouts/AuthLayout";
import Container from "components/common/layouts/Container";
import { coreService } from "services/CoreService";
import { HOME } from "constants/routes";
import HeroButton from "components/common/ui/hero-button/HeroButton";

const ViewNotAllowed = () => (
  <AuthLayout variant={"kraken"}>
    <Meta title={"Not Allowed"} />
    <Container>
      <h1>Not Allowed</h1>
      <p>
        Sorry, it seems you're trying to access a page that you don't have
        permission to view.
      </p>
      <HeroButton to={coreService.getRoute(HOME)} variant="outline">
        Back to Home
      </HeroButton>
    </Container>
  </AuthLayout>
);

export default ViewNotAllowed;
