import React from "react";
import { Category } from "models/cpd/common/common";
import IconDoctor from "components/common/icons/IconDoctor";
import IconMedReport from "components/common/icons/IconMedReport";
import IconSchool from "components/common/icons/IconSchool";
import IconNotebook from "components/common/icons/IconNotebook";
import IconCaduceus from "components/common/icons/medical/IconCaduceus";
import IconPulse from "components/common/icons/medical/IconPulse";
import IconClock from "components/common/icons/IconClock";
import IconGroup from "components/common/icons/IconGroup";
import {
  IconProgressComplete,
  IconProgressStar,
} from "components/common/icons/IconProgress";
import IconScroll from "components/common/icons/IconScroll";
import IconMedBook from "components/common/icons/medical/IconMedicalBook";
import IconMedicalBag from "components/common/icons/medical/IconMedicalBag";
import { IconSupervisorCircle } from "components/common/icons/IconSupervisor";

interface Props extends DefaultProps {
  type: Category;
}

const CategoryIcon = ({ type, className }: Props) => {
  switch (type) {
    case Category.hours:
      return <IconClock className={className} />;
    case Category.audit:
      return <IconMedReport className={className} />;
    case Category.edu:
    case Category.tp_uni_papers:
      return <IconSchool className={className} />;
    case Category.resus:
      return <IconPulse className={className} />;
    case Category.peer:
      return <IconGroup className={className} />;
    case Category.pdp:
    case Category.tp_case_studies:
      return <IconNotebook className={className} />;
    case Category.quiz:
      return <IconProgressComplete className={className} />;
    case Category.sac:
      return <IconCaduceus className={className} />;
    case Category.tp_supervision:
      return <IconSupervisorCircle className={className} />;
    case Category.tp_ucpex:
      return <IconScroll className={className} />;
    case Category.tp_uc_course:
      return <IconProgressStar className={className} />;
    case Category.tp_med_lit:
      return <IconMedBook className={className} />;
    case Category.tp_core_skills:
      return <IconMedicalBag className={className} />;

    case Category.tp_practical:
    default:
      return <IconDoctor className={className} />;
  }
};

export default CategoryIcon;
