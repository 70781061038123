import {
  Conditional,
  DataAttribute,
} from "components/common/ui/form-elements/formElements";
import * as Yup from "yup";
import {
  activityCategoryOptions,
  ActivityReqReason,
  activityRequiredReasonOptions,
  activityWaiverOptions,
  Category,
  gradeLetterScaleOptions,
  gradeOptions,
  gradeResultOptions,
  subTypeConditionalFields,
} from "models/cpd/common/common";
import {
  PeerGroupMeeting,
  PeergroupMeetingStatus,
} from "models/peergroup/meeting";
import { Activity } from "models/cpd/Activity";
import { getSchema, getValidations } from "utils/formUtils";

interface ActivityAttribute extends DataAttribute<keyof Activity> {
  // name: keyof Activity;
}

const typeOfActivity: ActivityAttribute = {
  name: "category",
  type: "enum",
  label: "Activity Type",
  required: true,
  validation: Yup.string().required("Please select an activity type"),
  options: activityCategoryOptions,
  // disabled: true,
};

export const subCategoryQuestion: ActivityAttribute = {
  name: "subcategory",
  type: "enum",
  label: "Sub-Type",
  required: true,
  validation: Yup.string()
    .when(["category", "completed_at"], {
      is: (category: Category, completed_at: any) =>
        subTypeConditionalFields.includes(category) && !!completed_at,
      then: Yup.string()
        .required("Please select an activity sub type")
        .nullable(),
    })
    .nullable(),
  conditional: [{ field: "category", values: subTypeConditionalFields }],
};

const subCategoryOther: ActivityAttribute = {
  name: "subcategory_other",
  type: "string",
  label: "Other Activity",
  // disabled: true,
  conditional: [
    {
      field: "subcategory",
      values: ["other"],
    },
  ],
};

const peergroupCategoryMeetingConditional: ActivityAttribute = {
  name: "topic",
  type: "string",
  label: "Add peergroup meeting",
  conditional: [
    {
      field: "category",
      values: ["peer"],
    },
  ],
};

const datePlanned: ActivityAttribute = {
  name: "planned_at",
  type: "date",
  label: "Planned Completion Date",
  validation: Yup.date()
    .when("completed_at", {
      is: null,
      then: Yup.date()
        .required("Please select a Planned Date or Date Completed")
        .nullable(),
    })
    .nullable(),
};
const dateStartedPlanned: ActivityAttribute = {
  name: "planned_started_at",
  type: "date",
  label: "Planned Start Date",
};

const dateCompleted: ActivityAttribute = {
  name: "completed_at",
  type: "date",
  label: "Actual Completion Date",
  validation: Yup.date()
    .when(["category", "peergroup_meeting_details"], {
      is: (category?: string, meeting?: PeerGroupMeeting) =>
        category === "peer" &&
        meeting?.peergroup_meeting_status === PeergroupMeetingStatus.DRAFT,
      then: Yup.date()
        .max(
          new Date("1970-01-01"),
          "Cannot complete activity while Meeting is in Draft"
        )
        .nullable(),
      otherwise: Yup.date().nullable(),
    })
    .nullable(),
};

const dateStarted: ActivityAttribute = {
  name: "started_at",
  type: "date",
  label: "Actual Start Date",
};

const assignToGoal: ActivityAttribute = {
  name: "goal_id",
  type: "enum",
  label: "Link to Goal",
};

const timeCredits: ActivityAttribute = {
  name: "credits",
  type: "number",
  label: "Time Credits",
  defaultValue: "1",
  required: true,
  validation: Yup.string().required("Please enter time credits"),
};

const keyTopic: ActivityAttribute = {
  name: "topic",
  type: "string",
  label: "Key topic(s) or name of activity",
  conditional: [
    {
      field: "category",
      values: ["edu"],
    },
  ],
};

const learnings: ActivityAttribute = {
  name: "learnings",
  type: "string",
  label: "What did I learn",
  conditional: [
    {
      field: "category",
      values: ["edu", "additional", "peer"],
    },
  ],
  layout: {
    type: "textArea",
  },
};

const benefits: ActivityAttribute = {
  name: "benefits",
  type: "string",
  label: "How will did this benefit care for my patients",
  conditional: [
    {
      field: "category",
      values: ["edu", "additional", "peer"],
    },
  ],
  layout: {
    type: "textArea",
  },
};

const location: ActivityAttribute = {
  name: "locations",
  type: "facility",
  label: "Workplace",
  conditional: [
    {
      field: "category",
      values: ["hours"],
    },
  ],
};

const otherLocation: ActivityAttribute = {
  name: "other_locations",
  type: "facility",
  label: "Other Locations (e.g locum facilities)",
  conditional: [
    {
      field: "category",
      values: ["hours"],
    },
  ],
};

const lockActivity: ActivityAttribute = {
  name: "locked",
  type: "boolean",
  label: "Lock Activity",
};
const waiveReq: ActivityAttribute = {
  name: "waive_req",
  type: "boolean",
  label: "Waive Requirements",
};
const waiver: ActivityAttribute = {
  name: "waiver",
  type: "enum",
  label: "Waiver Type",
  options: activityWaiverOptions,
  validation: Yup.string()
    .when("waive_req", {
      is: true,
      then: Yup.string().required("Please select the type of waiver"),
    })
    .nullable(),
};
const waiverReason: ActivityAttribute = {
  name: "waiver_reason",
  type: "boolean",
  label: "Waiver Reason",
  layout: {
    type: "textArea",
  },
};

const isRequired: ActivityAttribute = {
  name: "is_required",
  type: "boolean",
  label: "Is this activity Required?",
  description:
    "Required activity is a special override that is commonly used for 'overdue' activity from a previous plan",
};

const requiredReason: ActivityAttribute = {
  name: "required_reason",
  label: "Reason",
  type: "enum",
  conditional: [
    {
      field: "is_required",
      values: ["true"],
    },
  ],
  defaultValue: ActivityReqReason.overdue,
  options: activityRequiredReasonOptions,
};

const requiredCredits: ActivityAttribute = {
  name: "required_credits",
  type: "number",
  label: "Required Points",
  conditional: [
    {
      field: "is_required",
      values: ["true"],
    },
  ],
};

const requiredBy: ActivityAttribute = {
  name: "required_by",
  type: "date",
  label: "Required By",
  conditional: [
    {
      field: "is_required",
      values: ["true"],
    },
  ],
};

const lock_subcategory: ActivityAttribute = {
  name: "lock_subcategory",
  type: "boolean",
  label: "Lock activity sub-type? (if the user shouldn't be able to change it)",
};

const excludeTriennium: ActivityAttribute = {
  name: "exclude_triennium",
  type: "boolean",
  label: "Exclude this Activity from Programme period progress",
};

const excludeAnnual: ActivityAttribute = {
  name: "exclude_annual",
  type: "boolean",
  label: "Exclude this Activity from Annual progress",
};

const auditGrade: ActivityAttribute = {
  name: "audit_grade",
  type: "enum",
  label: "Grade",
  options: gradeOptions,
  conditional: [
    {
      field: "subcategory",
      values: ["patient", "clinical"],
    },
  ],
};

const admin_grade_result: ActivityAttribute = {
  name: "admin_grade_result",
  type: "enum",
  label: "Grade",
  options: gradeResultOptions,
  conditional: [
    {
      field: "category",
      values: [Category.tp_ucpex],
    },
  ],
  //
  // conditional: {
  //   OR: [
  //     {
  //       category: {
  //         values: [Category.tp_med_lit],
  //       },
  //       subcategory: {
  //         values: ["mlp_med_lit", "other"],
  //       },
  //     },
  //     {
  //       category: {
  //         values: [Category.tp_uni_papers],
  //       },
  //     },
  //   ],
  // },
};

const admin_grade: ActivityAttribute = {
  name: "admin_grade",
  type: "enum",
  label: "Outcome",
  options: gradeOptions,
  // conditional: {
  //   OR: [
  //     {
  //       field: "category",
  //       values: [Category.tp_case_studies],
  //     },
  //     {
  //       field: "category",
  //       values: [Category.tp_med_lit],
  //     },
  //   ],
  // },

  conditional: {
    OR: [
      {
        category: {
          values: [Category.tp_med_lit],
        },
        subcategory: {
          values: ["mlp_med_lit", "other"],
        },
      },
      {
        category: {
          values: [Category.tp_uni_papers],
        },
      },
    ],
  },
};

// Note: rename current 'grade_score' and 'grade_result' to 'admin_{...}'
// Option below can only be completed by ADMIN

// admin_grade_score
// Category.tp_ucpex: score out of 100 (%)
// Category.tp_case_studies: score out of 10

// admin_letter_grade:
// Category.tp_med_lit && subcategory = "PoplHlth701": grade from A,B,C,D,F (including e.g. A+, A, A-)

// admin_grade_result
// Category.tp_ucpex: gradeResultOptions
// Category.tp_case_studies || Category.tp_med_lit: gradeOptions

// Note: create new 'letter_grade' and 'grade_result' to be stored in 'fields_others'
// options below can be completed by any (non-Admin)

// letter_grade
// Category.tp_uni_papers: grade from A,B,C,D,F (including e.g. A+, A, A-)

// grade_result
// Category.tp_uni_papers: gradeOptions

const admin_grade_score: ActivityAttribute = {
  name: "admin_grade_score",
  type: "number",
  label: "Grade score (in %)",
  defaultValue: "50",
  validation: Yup.number()
    // .integer("Decimal values are not allowed")
    // .required("Please add the number of attendees")
    .typeError("Marks must be a number")
    .min(0, "Score must be at least 0%")
    .max(100, "Score cannot be higher than 100%")
    .nullable(),
  conditional: [
    {
      field: "category",
      values: [Category.tp_ucpex],
    },
  ],
};
const adminGradeScoreOutOf10: ActivityAttribute = {
  name: "admin_grade_score",
  type: "enum",
  label: "Grade score",
  defaultValue: "50",
  options: [
    { value: "0", label: "0" },
    { value: "10", label: "1" },
    { value: "20", label: "2" },
    { value: "30", label: "3" },
    { value: "40", label: "4" },
    { value: "50", label: "5" },
    { value: "60", label: "6" },
    { value: "70", label: "7" },
    { value: "80", label: "8" },
    { value: "90", label: "9" },
    { value: "100", label: "10" },
  ],
  // validation: Yup.number()
  //   // .integer("Decimal values are not allowed")
  //   // .required("Please add the number of attendees")
  //   .typeError("Marks must be a number")
  //   .min(0, "Score must be at least 0%")
  //   .max(100, "Score cannot be higher than 100%")
  //   .nullable(),
  conditional: [
    {
      field: "category",
      values: [Category.tp_case_studies],
    },
  ],
};

const letter_grade: ActivityAttribute = {
  name: "letter_grade",
  type: "enum",
  label: "Grade",
  options: gradeLetterScaleOptions,
  // validation: Yup.number()
  //   // .integer("Decimal values are not allowed")
  //   // .required("Please add the number of attendees")
  //   .typeError("Marks must be a number")
  //   .min(0, "Score must be at least 0%")
  //   .max(100, "Score cannot be higher than 100%")
  //   .nullable(),
  conditional: [
    {
      field: "category",
      values: [Category.tp_uni_papers],
    },
  ],
};
const admin_letter_grade: ActivityAttribute = {
  name: "admin_letter_grade",
  type: "enum",
  label: "Grade",
  options: gradeLetterScaleOptions,
  conditional: {
    AND: {
      category: {
        values: [Category.tp_med_lit],
      },
      subcategory: {
        values: ["pop_hlth_701_med_lit"],
      },
    },
  },
};

const grade_result: ActivityAttribute = {
  name: "grade_result",
  type: "enum",
  label: "Outcome",
  options: gradeOptions,
  // conditional: {
  //   OR: [
  //     {
  //       field: "category",
  //       values: [Category.tp_uni_papers],
  //     },
  //     {
  //       field: "category",
  //       values: [Category.tp_med_lit],
  //     },
  //   ],
  // },
  conditional: [
    {
      field: "category",
      values: [Category.tp_uni_papers],
    },
  ],
};

export const adminOnlyCompleteConditional: Conditional<keyof Activity> = {
  OR: [
    {
      category: {
        values: [Category.audit],
      },
      subcategory: {
        values: ["patient", "clinical"],
      },
    },
    {
      category: {
        values: [
          Category.tp_practical,
          Category.tp_ucpex,
          Category.tp_case_studies,
          Category.tp_med_lit,
        ],
      },
    },
  ],
};

const adminComments: ActivityAttribute = {
  name: "admin_comments",
  type: "string",
  label: "Comments",
  layout: {
    type: "textArea",
  },
};

export const activityAttributes = {
  keyTopic,
  typeOfActivity,
  subCategoryQuestion,
  subCategoryOther,
  datePlanned,
  dateStartedPlanned,
  dateCompleted,
  dateStarted,
  assignToGoal,
  timeCredits,

  learnings,
  benefits,
  location,
  otherLocation,

  lockActivity,
  waiveReq,
  waiver,
  waiverReason,
  isRequired,
  lock_subcategory,
  requiredReason,
  requiredCredits,
  requiredBy,
  excludeTriennium,
  excludeAnnual,
  auditGrade,
  admin_grade,
  admin_grade_result,
  admin_grade_score,
  adminGradeScoreOutOf10,
  admin_letter_grade,
  letter_grade,
  grade_result,
  adminComments,
} as Record<string, ActivityAttribute>;

export const categoryConditional = {
  peergroupCategoryMeetingConditional,
} as Record<string, ActivityAttribute>;

export const activitySchema = getSchema(
  getValidations(Object.values(activityAttributes))
);
