import React from "react";
import Meta from "components/common/Meta";
import { AppContentContainer } from "components/common/layouts/appLayout/AppContentContainer";
import { userService } from "services/UserService";
import { useSelector } from "react-redux";
import UsersList from "components/users/admin/list/UserList";
import Button from "components/common/ui/button/Button";
import { USER_ROUTES } from "constants/routes/user";
import { coreService } from "services/CoreService";
import {
  userListDimSelector,
  isDimensionsLoadingSelector,
} from "store/selectors/dimensions.selectors";
import { permissionsSelector } from "store/selectors/auth.selectors";
import { verifyPermisisons } from "utils/authUtils";
import { useUsersList } from "components/users/hooks/useUsersList";
import { isAppLoadedSelector } from "store/selectors/core.selectors";
import PageHeader from "components/common/layouts/appLayout/PageHeader";
import ExportUsers from "components/users/admin/ExportUsers";

const ViewAdminUsers = () => {
  const isDimLoading = useSelector(isDimensionsLoadingSelector);
  const dimensions = useSelector(userListDimSelector);
  const isAppLoaded = useSelector(isAppLoadedSelector);
  const permissions = useSelector(permissionsSelector);
  const canEdit = verifyPermisisons(
    permissions,
    USER_ROUTES.ADMIN_CREATE_USER.requiredRole
  );
  const enabled = isAppLoaded && !isDimLoading;
  const { isLoading, data: users } = useUsersList(dimensions, {
    enabled,
  });

  return (
    <AppContentContainer spacing="none">
      <Meta title={"Admin Users"} />
      <PageHeader
        header={"Manage Users"}
        actions={[
          <>
            {canEdit && (
              <Button
                rounded
                theme="primary"
                to={coreService.getRoute(USER_ROUTES.ADMIN_CREATE_USER)}
              >
                Create New User
              </Button>
            )}

            <ExportUsers users={users?.items || []} />
          </>,
        ]}
        spacing="extra"
        spacingTopOnly
      />

      <UsersList
        users={users?.items || []}
        dimensions={dimensions}
        setActive={userService.setDimensions}
        setManyActive={userService.setManyDimensions}
        isAppLoaded={isAppLoaded}
        loading={!enabled || isLoading}
      />
    </AppContentContainer>
  );
};

export default ViewAdminUsers;
