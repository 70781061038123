import React from "react";
import { useFieldArray } from "react-hook-form";
import { Control } from "react-hook-form/dist/types/form";
import Select from "components/common/ui/select/Select";
import { activityAttributes } from "components/cpd/utils/activityForm";
import { Facility } from "models/facility/Facility";
import { TError } from "components/common/ui/form-elements/formElements";

interface TField extends Omit<Facility, "users"> {
  // id: string;
}

interface Props extends DefaultProps {
  options: Facility[];
  control: Control;
  errors: Record<string, TError>;
  disabled?: boolean;
}

const DeliveryLocation = ({
  options,
  control,
  className,
  errors,
  children,
  ...props
}: Props) => {
  const q = activityAttributes.location;
  const formattedOptions =
    options?.map((d) => ({
      key: d.facility_id,
      label: d.facility_name,
    })) || [];
  const {
    fields,
    append,
    remove,
  }: {
    fields: TField[];
    remove: (index: number) => void;
    append: (f: TField) => void;
  } = useFieldArray({
    control: control,
    name: q.name, // unique name for your Field Array
  });
  const onSelect = (name: string, value: string) => {
    const facility = options.find((f) => f.facility_id === value);
    const index = fields.findIndex((f) => f.facility_id === value);

    if (facility) {
      if (index > -1) {
        remove(index);
      } else {
        append(facility);
      }
    }
  };
  return (
    <Select
      name={q.name}
      multiSelect={true}
      onSelect={onSelect}
      label={q.label || q.name}
      options={formattedOptions}
      error={errors[q.name]}
      config={{
        inputChip: true,
        selectedOptionFormat: "chip",
        expandArrow: true,
        // chipVariant:"empty"
      }}
      value={fields.map((f) => f.facility_id)}
      disabled={props.disabled}
    />
  );
};

export default DeliveryLocation;
