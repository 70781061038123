import { Activity } from "models/cpd/Activity";
import { Goal } from "models/cpd/Goal";
import Modal from "components/common/ui/modal/Modal";
import ActivityDetailFormatted from "components/cpd/activity/ActivityDetailFormatted";
import FormActions from "components/common/ui/form-elements/form-layout/FormActions";
import Button from "components/common/ui/button/Button";
import React from "react";
import { activityCategoryOptions } from "models/cpd/common/common";
import { getSubcategory } from "components/cpd/utils/utils";
import { useActivityDetail } from "components/cpd/hooks/useActivityDetail";

export const ReadOnlyActivityDetail = ({
  activity,
  userId,
  resetActivty,
  goals = [],
}: {
  activity?: Activity;
  userId: string;
  goals?: Goal[];
  heading?: string;
  resetActivty: () => void;
}) => {
  const { data } = useActivityDetail(activity?.id || "", userId, {
    enabled: !!activity,
    placeholderData: activity,
  });
  const category = activityCategoryOptions.find(
    (c) => c.value === data?.category
  );
  const subcategory = getSubcategory(data?.category, data?.subcategory);

  return (
    <Modal open={!!activity} onClose={resetActivty}>
      <h2 style={{ marginTop: 8 }}>
        {data?.topic ||
          (subcategory?.label ?? category?.altLabel ?? category?.label)}
      </h2>

      <ActivityDetailFormatted
        goals={goals}
        userId={userId}
        activity={data ?? {}}
        year={{
          year: data?.year ?? 0,
          start: "",
          end: "",
          label: "",
          locked: true,
        }}
        isLoading={false}
        locked={true}
      />

      <FormActions>
        <Button variant="empty" onClick={resetActivty}>
          Close
        </Button>
      </FormActions>
    </Modal>
  );
};
