import { IState } from "store/index";

export const isDimensionsLoadingSelector = (state: IState) =>
  state.app.dimensions.isLoading;
export const userListDimSelector = (state: IState) =>
  state.app.dimensions.users;
export const facilityListDimSelector = (state: IState) =>
  state.app.dimensions.facilities;
export const planListDimSelector = (state: IState) =>
  state.app.dimensions.plans;
export const peergroupDimSelector = (state: IState) =>
  state.app.dimensions.peergroups;
