import React, { Ref } from "react";
import cx from "classnames";
import styles from "./Button.module.scss";
import LinkWrapper, {
  TLinkWrapper,
} from "components/common/ui/link-wrapper/LinkWrapper";
import MiniLoader from "components/common/ui/loader/MiniLoader";
import {
  ISkeleton,
  SkeletonBase,
} from "components/common/ui/skeleton/Skeleton";

export interface TButton extends TLinkWrapper {
  /** Alternative color variations */
  theme?: ThemeVariants;

  /** CSS class to be applied to the button */
  className?: string;

  /** Should the button be disabled */
  disabled?: boolean;

  /** Button type */
  type?: "button" | "submit" | "reset";

  /** Should the corners of the button should be rounded */
  rounded?: boolean;

  /** Should the button be filled in (default) or outline (ghost) or empty or lightFilled */
  variant?: FillVariants;

  /** Alternative HEX color value for background of the ghost (non-filled) button  */
  altBg?: string;

  /** Prevent hover scale animation  */
  noHoverAnimation?: boolean;

  /** Disable button and show submitting loader  */
  submitting?: boolean;

  isLoaded?: boolean;

  tabIndex?: number;

  "aria-label"?: string;
  children?: React.ReactNode;
}

const Button = React.forwardRef(
  (props: TButton, ref: Ref<HTMLButtonElement>) => {
    return (
      <LinkWrapper
        to={props.to}
        href={props.href}
        target={props.target}
        download={props.download}
      >
        <ButtonBase {...props} ref={ref} />
      </LinkWrapper>
    );
  }
);

const ButtonBase = React.forwardRef(
  (
    {
      className,
      style,
      onClick,
      disabled = false,
      type = "button",
      theme = "default",
      variant = "filled",
      rounded,
      altBg,
      noHoverAnimation = false,
      submitting = false,
      isLoaded = true,
      children,
      ...rest
    }: TButton,
    ref: Ref<HTMLButtonElement>
  ) => (
    <button
      className={cx(
        styles.button,
        theme && styles[theme],
        variant && styles[variant],
        rounded && styles.rounded,
        noHoverAnimation && styles.noHover,
        submitting && styles.submitting,
        !isLoaded && styles.loading,
        className
      )}
      disabled={disabled || submitting || !isLoaded}
      onClick={onClick ? (e) => onClick(e) : () => {}}
      type={type}
      style={{ background: altBg, ...style }}
      {...rest}
      ref={ref}
    >
      {children}
      {submitting && (
        <div className={styles.submittingLoader}>
          <MiniLoader variant="alt" />
        </div>
      )}
      {!isLoaded && <SkeletonBase className={styles.skeleton} />}
    </button>
  )
);

interface ISkeletonButton extends Omit<TButton, "variant">, ISkeleton {
  buttonVariant?: FillVariants;
}

export const SkeletonButton = ({
  buttonVariant,
  className,
  children,
  rounded,
  noHoverAnimation = true,
  style,
  ...rest
}: ISkeletonButton) => (
  <Button
    className={cx(styles.loading, className)}
    disabled
    rounded={rounded}
    noHoverAnimation={noHoverAnimation}
    variant={buttonVariant}
  >
    {children}
    <SkeletonBase className={styles.skeleton} {...rest} />
  </Button>
);

export default Button;
