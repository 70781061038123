import React from "react";
import { useLayer, Arrow } from "react-laag";
import { motion, AnimatePresence } from "framer-motion";
import cx from "classnames";
import styles from "components/common/ui/menu/Menu.module.scss";
import ResizeObserver from "resize-observer-polyfill";

interface Props {
  children: React.ReactElement;
  /** The content to be displayed within the tooltip */
  content: React.ReactNode;
  className?: string;
  /** The direction of the tooltip relative to the element (will move if there isn't enough space) */
  direction?:
    | "top-center"
    | "top-start"
    | "top-end"
    | "left-start"
    | "left-center"
    | "left-end"
    | "right-start"
    | "right-center"
    | "right-end"
    | "bottom-start"
    | "bottom-center"
    | "bottom-end"
    | "center";

  /** Should the arrow show */
  arrow?: boolean;

  /** How much should the tooltip be offset from the element */
  offset?: number;

  /** Override hover trigger and force open */
  // open?: boolean;

  /** Framer motion animation object */
  animation?: {
    initial: object;
    animate: object;
    exit: object;
    transition: object;
  };
}

export const Menu = React.memo(
  ({
    children,
    content,
    className,
    direction = "bottom-center",
    arrow = false,
    offset = 2,
    animation,
  }: Props) => {
    const [isOpen, setOpen] = React.useState(false);
    function close() {
      setOpen(false);
    }
    const { layerProps, triggerProps, arrowProps, renderLayer, layerSide } =
      useLayer({
        isOpen,
        containerOffset: offset,
        placement: direction,
        ResizeObserver,
        onOutsideClick: close,
        onDisappear: close,
      });
    return (
      <>
        <button
          onClick={() => setOpen((s) => !s)}
          className={styles.menuToggle}
          {...triggerProps}
        >
          {children}
        </button>
        {renderLayer(
          <MenuWrapper
            isOpen={isOpen}
            close={close}
            className={className}
            animation={animation}
            arrow={arrow}
            layerSide={layerSide}
            layerProps={layerProps}
            arrowProps={arrowProps}
          >
            {content}
          </MenuWrapper>
        )}
      </>
    );
  }
);
interface IMenuItemProps {
  onClick?: () => void;
  className?: string;
  children: React.ReactNode;
}
export const MenuItem = ({ onClick, className, children }: IMenuItemProps) =>
  onClick ? (
    <button className={cx(styles.item, className)} onClick={onClick}>
      {children}
    </button>
  ) : (
    <li className={cx(styles.item, className)}>{children}</li>
  );

interface WrapperProps {
  isOpen: boolean;
  close: () => void;
  layerProps: object;
  layerSide: "top" | "left" | "right" | "bottom" | "center" | undefined;
  arrowProps: object;
  children: React.ReactNode;
  className?: string;
  arrow?: boolean;
  animation?: {
    initial: object;
    animate: object;
    exit: object;
    transition: object;
  };
}
const outTransform = {
  top: { x: 0, y: -20 },
  left: { x: -20, y: 0 },
  bottom: { x: 0, y: 20 },
  right: { x: 20, y: 0 },
  center: { x: 0, y: 20 },
};
const MenuWrapper = ({
  isOpen,
  close,
  layerProps,
  layerSide = "bottom",
  arrowProps,
  children,
  className,
  animation,
  arrow,
}: WrapperProps) => {
  const animateOptions = animation || {
    initial: { opacity: 0, scale: 0.85, ...outTransform[layerSide] },
    animate: { opacity: 1, scale: 1, x: 0, y: 0 },
    exit: { opacity: 0, scale: 0.85, ...outTransform[layerSide] },
    transition: {
      type: "spring",
      stiffness: 800,
      damping: 35,
    },
  };
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.ul
          className={cx(className, styles.menu)}
          {...animateOptions}
          {...layerProps}
          onClick={() => close()}
        >
          {children}
          {arrow && <Arrow layerSide={layerSide} {...arrowProps} />}
        </motion.ul>
      )}
    </AnimatePresence>
  );
};

export default Menu;
