import React, { Ref } from "react";
import { TUser } from "models/user/User";
import { UserType } from "models/user/attributes/userType";
import { userTypeKey, userTypeOptions } from "models/user/attributes/common";
import IconDoctor from "components/common/icons/IconDoctor";
import IconSchool, {
  IconSchoolOutline,
} from "components/common/icons/IconSchool";
import { pascalCase } from "utils/format/stringUtils";
import Tooltip from "components/common/ui/tooltip/Tooltip";
import IconUser from "components/common/icons/IconUser";

interface BaseProps {
  user: TUser;
}
interface Props extends DefaultProps, BaseProps {}

const UserIcon = React.forwardRef(
  ({ user, ...props }: Props, ref: Ref<HTMLDivElement>) => (
    <div {...props} ref={ref}>
      <UserIconBase user={user} />
    </div>
  )
);

export const UserIconBase = ({ user }: BaseProps) => {
  switch (user[userTypeKey] as UserType) {
    case "fellow":
      return <IconDoctor />;
    case "education":
      return <IconSchoolOutline />;

    case "registrar":
    case "snr_registrar":
      return <IconSchool />;

    case "applicant":
    case "general":
    case "honorary_fellow":
    case "esteemed_fellow":
    default:
      return <IconUser />;
  }
};

export const UserIconWithToolTip = ({ user, ...props }: Props) => {
  const userType = userTypeOptions.find((t) => t.value === user[userTypeKey]);
  const label = userType?.label || pascalCase(user[userTypeKey]) || "Unknown";
  return (
    <Tooltip content={label}>
      <UserIcon user={user} {...props} />
    </Tooltip>
  );
};

export default UserIcon;
