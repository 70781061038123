import { useMutation, useQueryClient } from "react-query";
import { planQueryKeys } from "queries/constants";
import { MutationOptions } from "queries/queries";
import { useToast } from "components/common/ui/toast/useToast";
import { savePlan } from "api/CpdApi";
import { createPlan, Plan } from "models/cpd/Plan";

export const useMutatePlan = (options: MutationOptions = {}) => {
  const queryClient = useQueryClient();
  const { addErrorToast, addToast } = useToast();

  return useMutation(
    ({ newPlan, oldPlan = createPlan(), userId }: useMutatePlanProps) =>
      savePlan(newPlan, oldPlan, userId),
    {
      onSuccess: (result, inputs) => {
        addToast(
          `Plan was successfully ${
            inputs.oldPlan?.plan_id ? "updated" : "created"
          }!`,
          {
            theme: "success",
          }
        );
        if (inputs.oldPlan?.plan_id) {
          queryClient.invalidateQueries(
            planQueryKeys.detail(inputs.oldPlan.plan_id)
          );
        }
        queryClient.invalidateQueries(planQueryKeys.list());
      },
      onError: (e) => {
        addErrorToast(e, "Error: Unable to save Plan Details");
      },
      ...options,
    }
  );
};

interface useMutatePlanProps {
  newPlan: Partial<Plan>;
  oldPlan?: Plan;
  userId: string;
}
