import React from "react";
import cx from "classnames";
import styles from "./Highlight.module.scss";
import { findChunks } from "components/common/ui/form-elements/autocomplete/utils";

interface Props {
  text: string;
  query?: string;
}

export const HighlightChunks = ({ text, query }: Props) => {
  const chunks = findChunks(query, text);
  return (
    <span>
      {chunks.map((chunk) => (
        <span
          className={cx(chunk.highlight && styles.highlight)}
          key={`chunk-${chunk.start}-${chunk.end}`}
        >
          {text.slice(chunk.start, chunk.end)}
        </span>
      ))}
    </span>
  );
};
