import React from "react";
import Meta from "components/common/Meta";
import AuthLayout from "components/common/layouts/AuthLayout";
import HeroButton from "components/common/ui/hero-button/HeroButton";
import { HOME } from "constants/routes";
import { coreService } from "services/CoreService";

const ViewComingSoon = () => (
  <AuthLayout variant={"moon"}>
    <Meta title={"Not Found"} />

    <h1>Coming Soon</h1>
    <p>
      Watch this space!
      <br />
      <br />
      <br />
    </p>
    <div style={{ paddingTop: 16 }}>
      <HeroButton
        to={coreService.getRoute(HOME)}
        theme={"secondary"}
        variant="outline"
      >
        Back to Home
      </HeroButton>
    </div>
  </AuthLayout>
);

export default ViewComingSoon;
