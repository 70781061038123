import React from "react";

interface Props {
  className?: string;
}

const AdminIllustration = ({ className }: Props) => (
  <svg
    width="auto"
    height="auto"
    viewBox="0 0 452 330"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <mask
      id="x91kt650uq-admin-mask0"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="4"
      y="2"
      width="444"
      height="323"
    >
      <rect x="4" y="2" width="444" height="323" fill="#9081BE" />
    </mask>
    <g mask="url(#x91kt650uq-admin-mask0)">
      <mask
        id="x91kt650uq-admin-x91kt650uq-admin-mask1"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="35"
        y="-8"
        width="441"
        height="340"
      >
        <path
          d="M429 2.5L362.329 113.277L278.399 177.584L127.095 224.168L77.8361 247.25L48.683 290.904L36.1382 331H474.638V-7L429 2.5Z"
          fill="#C4C4C4"
          stroke="black"
        />
      </mask>
      <g mask="url(#x91kt650uq-admin-x91kt650uq-admin-mask1)">
        <rect x="-71.8618" y="-2" width="544" height="328" fill="#FBCAD9" />
        <path
          d="M408.831 69.1778C426.839 78.6885 436.974 99.4047 432.238 120.206C426.582 145.047 401.87 160.593 377.029 154.938C363.987 151.968 353.497 143.728 347.298 132.98C347.284 132.977 347.284 132.977 347.301 132.966C347.9 131.632 348.519 130.332 349.166 129.038C349.249 128.866 349.326 128.722 349.409 128.55C350.428 126.532 351.491 124.51 352.625 122.562C352.705 122.404 352.792 122.218 352.899 122.066C357.149 114.638 362.077 107.717 367.587 101.341C367.729 101.167 367.886 100.997 368.031 100.81C368.565 100.211 369.102 99.5978 369.662 99.0049C369.75 98.8777 369.863 98.7712 369.978 98.6505C375.311 92.7922 381.16 87.4482 387.409 82.6663C387.769 82.3806 388.128 82.0948 388.498 81.826L388.501 81.8123C388.596 81.7162 388.72 81.6267 388.836 81.565C395.061 76.895 401.697 72.8034 408.63 69.2798C408.697 69.25 408.763 69.2205 408.831 69.1778Z"
          fill="#8F8FEF"
        />
        <path
          d="M144.411 270.02C160.433 280.562 144.411 325.032 144.411 325.032C137.679 326.328 131.063 324.494 124.229 325.032C137 333.5 129.192 336.504 129 336.5C118.761 337.319 103.064 325.9 92.7414 325.032C92.5719 324.993 92.3795 324.989 92.1709 324.973C81.8881 324.081 88.1245 329.115 78 326.5C77.785 326.456 67.5 327 55.0002 327.5C42.5003 328.001 41.0468 327.298 40.0001 327C35.2939 325.707 48.4033 315.893 43.7458 314.193C45.1886 305.37 48.4561 296.699 53.6935 288.738C73.5783 258.513 114.186 250.135 144.411 270.02Z"
          fill="#F98293"
        />
        <path
          d="M153.885 329.766C143.734 330.041 135.282 322.036 135.007 311.885C134.731 301.734 142.737 293.282 152.887 293.007C163.038 292.731 171.49 300.737 171.766 310.888C172.041 321.038 164.036 329.491 153.885 329.766Z"
          fill="#FFA766"
        />
        <path
          d="M155.885 328.766C145.734 329.042 137.282 321.037 137.007 310.885C136.731 300.734 144.737 292.282 154.887 292.007C165.038 291.731 173.49 299.737 173.766 309.887C174.042 320.039 166.036 328.491 155.885 328.766Z"
          fill="#F5E5E7"
        />
        <path
          d="M384.381 313.381C387.318 310.445 387.222 305.587 384.167 302.532C381.112 299.477 376.254 299.381 373.317 302.317C370.38 305.254 370.477 310.112 373.532 313.167C376.587 316.222 381.445 316.318 384.381 313.381Z"
          fill="#FFEEDC"
        />
        <path
          d="M367.877 247.622C386.167 268.113 384.358 299.59 363.889 317.86C343.375 336.17 311.919 334.384 293.629 313.892C281.538 300.345 278.193 281.978 283.305 265.797L304 242L325.053 231.357C340.566 229.536 356.679 235.076 367.877 247.622Z"
          fill="#F05D6A"
        />
        <path
          d="M437.848 295.739C437.854 295.852 437.825 295.984 437.74 296.077C421.907 315.629 393.764 319.996 373.675 306.007C363.894 299.195 357.514 289.076 355.078 277.963C355.359 277.833 355.63 277.716 355.886 277.59C358.27 288.537 364.533 298.552 374.185 305.275C393.776 318.918 421.164 314.785 436.766 295.899L378.498 255.455C378.928 255.384 378.52 254.633 378.949 254.562L437.66 295.434C437.759 295.503 437.828 295.616 437.848 295.739Z"
          fill="#401A49"
        />
        <path
          d="M167.369 251.169C168.812 245.974 165.769 240.592 160.574 239.149C155.378 237.706 149.996 240.749 148.554 245.944C147.111 251.14 150.153 256.521 155.348 257.964C160.544 259.408 165.926 256.366 167.369 251.169Z"
          fill="#8F8FEF"
        />
        <path
          d="M211.477 283.686C212.92 278.491 209.877 273.109 204.682 271.666C199.486 270.223 194.105 273.266 192.662 278.461C191.219 283.657 194.261 289.038 199.456 290.481C204.652 291.924 210.034 288.882 211.477 283.686Z"
          fill="#FFA766"
        />
        <path
          d="M136.455 220.16C136.379 220.177 136.306 220.214 136.245 220.27C136.053 220.441 136.037 220.736 136.211 220.927L159.234 246.599C159.291 246.665 159.369 246.713 159.456 246.737L188.165 254.71L201.894 283.075C202.004 283.307 202.285 283.411 202.516 283.29C202.749 283.18 202.845 282.9 202.732 282.668L188.912 254.114C188.855 253.994 188.747 253.904 188.617 253.868L159.836 245.874L136.903 220.305C136.785 220.174 136.614 220.125 136.455 220.16Z"
          fill="#401A49"
        />
        <path
          d="M204.631 275.18C204.468 275.216 204.33 275.338 204.283 275.512L202.259 282.796L195.482 277.562C195.28 277.402 194.985 277.44 194.829 277.646C194.671 277.851 194.709 278.141 194.913 278.299L202.238 283.957C202.363 284.051 202.524 284.072 202.67 284.03C202.818 283.981 202.93 283.862 202.972 283.712L205.182 275.759C205.252 275.511 205.107 275.255 204.857 275.186C204.78 275.165 204.703 275.164 204.631 275.18Z"
          fill="#401A49"
        />
        <path
          d="M353.196 163.393C354.409 163.393 355.392 162.409 355.392 161.196C355.392 159.984 354.409 159 353.196 159C351.983 159 351 159.984 351 161.196C351 162.409 351.983 163.393 353.196 163.393Z"
          fill="#401A49"
        />
        <path
          d="M353.196 172.544C354.409 172.544 355.392 171.561 355.392 170.348C355.392 169.135 354.409 168.152 353.196 168.152C351.983 168.152 351 169.135 351 170.348C351 171.561 351.983 172.544 353.196 172.544Z"
          fill="#401A49"
        />
        <path
          d="M353.196 181.695C354.409 181.695 355.392 180.712 355.392 179.499C355.392 178.286 354.409 177.303 353.196 177.303C351.983 177.303 351 178.286 351 179.499C351 180.711 351.983 181.695 353.196 181.695Z"
          fill="#401A49"
        />
        <path
          d="M338.973 65.509C338.973 66.7217 339.956 67.705 341.17 67.705C342.382 67.705 343.366 66.7217 343.366 65.509C343.366 64.2962 342.382 63.313 341.17 63.313C339.956 63.3124 338.973 64.2957 338.973 65.509Z"
          fill="#401A49"
        />
        <path
          d="M329.822 65.509C329.822 66.7217 330.805 67.705 332.018 67.705C333.231 67.705 334.214 66.7217 334.214 65.509C334.214 64.2962 333.231 63.313 332.018 63.313C330.805 63.3124 329.822 64.2957 329.822 65.509Z"
          fill="#401A49"
        />
        <path
          d="M320.671 65.509C320.671 66.7217 321.654 67.705 322.867 67.705C324.08 67.705 325.063 66.7217 325.063 65.509C325.063 64.2962 324.08 63.313 322.867 63.313C321.654 63.3124 320.671 64.2957 320.671 65.509Z"
          fill="#401A49"
        />
        <path
          d="M417.139 141C411.556 141 407 145.556 407 151.139C407 156.722 411.556 161.278 417.139 161.278C422.722 161.278 427.278 156.722 427.278 151.139C427.278 145.556 422.722 141 417.139 141ZM417.139 160.392C412.041 160.392 407.886 156.236 407.886 151.139C407.886 146.041 412.041 141.886 417.139 141.886C422.237 141.886 426.392 146.041 426.392 151.139C426.392 156.236 422.236 160.392 417.139 160.392Z"
          fill="#412424"
        />
      </g>
      <path
        d="M14.1381 329L40.6382 329C40.6382 329 60.6382 283 92.1382 258.5C123.638 234 177.793 216.427 248.323 193.076C302.44 175.159 349.522 148.613 383.5 107C401.305 85.1947 450 -0.499793 450 -0.499793L426.5 -0.500053C398.5 51.5 372 93.5 343.771 117.822C315.542 142.144 283.067 157.499 241.339 173.789C184.428 196.006 123.049 204.051 75.3717 242.295C41.6097 269.377 14.1381 329 14.1381 329Z"
        fill="#F05F26"
      />
      <path
        d="M14.1381 329L40.6382 329C40.6382 329 60.6382 283 92.1382 258.5C123.638 234 177.793 216.427 248.323 193.076C302.44 175.159 349.522 148.613 383.5 107C401.305 85.1947 450 -0.499793 450 -0.499793L426.5 -0.500053C398.5 51.5 372 93.5 343.771 117.822C315.542 142.144 283.067 157.499 241.339 173.789C184.428 196.006 123.049 204.051 75.3717 242.295C41.6097 269.377 14.1381 329 14.1381 329Z"
        fill="url(#x91kt650uq-admin-paint0_linear)"
      />
      <path
        d="M350.372 118.938L364.152 220.068L152.734 248.875L138.954 147.746C138.356 143.36 140.415 132.899 144.645 132.323L340.53 103.899C344.761 103.322 349.775 114.552 350.372 118.938Z"
        fill="#F5E5E7"
      />
      <path
        d="M348.496 103.924L350.443 118.216L139.097 147.013L137.15 132.722C136.574 128.492 139.525 124.593 143.754 124.016L339.804 97.303C344.032 96.7268 347.92 99.6952 348.496 103.924Z"
        fill="#FFD2A7"
      />
      <path
        d="M298.581 120.607C301.647 120.189 303.794 117.365 303.376 114.299C302.958 111.232 300.134 109.085 297.068 109.503C294.002 109.921 291.855 112.745 292.272 115.812C292.69 118.878 295.515 121.025 298.581 120.607Z"
        fill="#925F93"
      />
      <path
        d="M316.194 118.208C319.26 117.79 321.407 114.965 320.989 111.899C320.571 108.833 317.747 106.686 314.681 107.104C311.615 107.522 309.468 110.346 309.885 113.412C310.303 116.478 313.128 118.625 316.194 118.208Z"
        fill="#FF81B0"
      />
      <path
        d="M335.082 115.634C338.149 115.216 340.296 112.392 339.878 109.326C339.46 106.259 336.636 104.112 333.569 104.53C330.503 104.948 328.356 107.772 328.774 110.838C329.192 113.905 332.016 116.052 335.082 115.634Z"
        fill="#FF81B0"
      />
      <path
        d="M235.663 186.372C235.59 195.092 232.488 203.09 227.379 209.363C220.543 217.757 180.822 218.334 180.822 218.334C169.391 211.99 161.705 199.743 161.823 185.753C161.993 165.369 178.662 148.976 199.057 149.147C219.442 149.319 235.834 165.986 235.663 186.372Z"
        fill="#925F93"
      />
      <path
        d="M211.955 172.973C211.896 180.051 206.11 185.74 199.032 185.682C191.955 185.623 186.264 179.836 186.324 172.759C186.383 165.682 192.168 159.992 199.247 160.051C206.325 160.11 212.014 165.896 211.955 172.973Z"
        fill="#F5E5E7"
      />
      <path
        d="M227.379 209.363C220.544 217.757 210.104 223.075 198.439 222.977C192.045 222.924 186.047 221.243 180.823 218.334C181.447 212.316 184.64 193.282 199.855 190.757C213.393 188.512 223.258 202.282 227.379 209.363Z"
        fill="#F5E5E7"
      />
      <path
        d="M335.741 144.793L256.483 155.593C254.799 155.822 253.232 154.632 253.002 152.947C252.773 151.263 253.963 149.696 255.648 149.466L334.907 138.667C336.591 138.437 338.158 139.628 338.387 141.313C338.616 142.998 337.426 144.564 335.741 144.793Z"
        fill="#FF81B0"
      />
      <path
        d="M337.828 160.108L258.57 170.908C256.885 171.137 255.319 169.947 255.089 168.262C254.86 166.578 256.05 165.011 257.735 164.781L336.993 153.982C338.678 153.752 340.244 154.942 340.474 156.628C340.703 158.313 339.512 159.879 337.828 160.108Z"
        fill="#FF81B0"
      />
      <path
        d="M339.915 175.425L260.657 186.224C258.972 186.454 257.405 185.263 257.176 183.578C256.946 181.893 258.137 180.327 259.822 180.098L339.08 169.298C340.765 169.069 342.331 170.26 342.561 171.944C342.79 173.628 341.599 175.195 339.915 175.425Z"
        fill="#FF81B0"
      />
      <path
        d="M342.002 190.74L262.743 201.54C261.059 201.77 259.492 200.579 259.263 198.894C259.033 197.209 260.224 195.643 261.909 195.414L341.167 184.614C342.851 184.385 344.418 185.575 344.648 187.26C344.876 188.944 343.686 190.511 342.002 190.74Z"
        fill="#FF81B0"
      />
      <g opacity="0.34">
        <path
          opacity="0.34"
          d="M357.194 167.743L364.32 220.044L217.804 240.009L212.308 199.675C212.032 197.648 212.236 195.672 212.819 193.894C214.146 189.849 217.456 186.784 221.655 186.212L357.194 167.743Z"
          fill="#00143A"
        />
      </g>
      <path
        d="M430.782 184.989L444.261 283.909L232.842 312.717L219.364 213.796C218.779 209.507 221.038 200.516 225.269 199.94L421.385 173.217C425.615 172.641 430.198 180.699 430.782 184.989Z"
        fill="#FFF1E6"
      />
      <path
        d="M428.872 170.972L430.819 185.264L219.473 214.062L217.526 199.77C216.95 195.541 219.901 191.641 224.13 191.065L420.18 164.351C424.408 163.775 428.296 166.744 428.872 170.972Z"
        fill="#FFD2A7"
      />
      <path
        d="M378.957 187.655C382.024 187.238 384.171 184.413 383.753 181.347C383.335 178.281 380.511 176.134 377.444 176.552C374.378 176.969 372.231 179.794 372.649 182.86C373.067 185.926 375.891 188.073 378.957 187.655Z"
        fill="#925F93"
      />
      <path
        d="M396.57 185.255C399.636 184.837 401.783 182.013 401.365 178.947C400.948 175.88 398.123 173.733 395.057 174.151C391.991 174.569 389.844 177.393 390.262 180.46C390.679 183.526 393.504 185.673 396.57 185.255Z"
        fill="#FF81B0"
      />
      <path
        d="M420.254 176.373C420.672 179.439 418.525 182.263 415.459 182.681C412.393 183.099 409.569 180.952 409.151 177.886C408.733 174.82 410.88 171.995 413.946 171.577C417.013 171.16 419.837 173.307 420.254 176.373Z"
        fill="#FF81B0"
      />
      <path
        d="M316.039 253.42C315.967 262.14 312.865 270.138 307.755 276.411C300.92 284.805 290.48 290.123 278.815 290.025C272.421 289.972 266.423 288.292 261.199 285.383C249.767 279.038 242.081 266.792 242.198 252.801C242.369 232.417 259.037 216.025 279.434 216.196C299.818 216.366 316.21 233.035 316.039 253.42Z"
        fill="#925F93"
      />
      <path
        d="M292.331 240.021C292.272 247.099 286.487 252.789 279.409 252.73C272.331 252.671 266.642 246.885 266.7 239.807C266.76 232.73 272.545 227.039 279.623 227.099C286.701 227.158 292.391 232.944 292.331 240.021Z"
        fill="#FFF1E6"
      />
      <path
        d="M307.756 276.409C300.92 284.803 290.48 290.121 278.815 290.024C272.422 289.97 266.423 288.29 261.199 285.381C261.823 279.363 265.017 260.33 280.232 257.805C293.769 255.558 303.634 269.329 307.756 276.409Z"
        fill="#FFF1E6"
      />
      <path
        d="M416.118 211.842L336.859 222.642C335.175 222.871 333.608 221.681 333.379 219.996C333.149 218.311 334.34 216.745 336.025 216.515L415.283 205.716C416.967 205.486 418.534 206.676 418.764 208.361C418.992 210.046 417.802 211.613 416.118 211.842Z"
        fill="#FF81B0"
      />
      <path
        d="M418.204 227.158L338.946 237.958C337.261 238.188 335.695 236.997 335.465 235.312C335.236 233.628 336.426 232.061 338.111 231.832L417.369 221.032C419.054 220.802 420.62 221.993 420.85 223.678C421.079 225.363 419.889 226.929 418.204 227.158Z"
        fill="#FF81B0"
      />
      <path
        d="M420.291 242.473L341.033 253.273C339.348 253.502 337.782 252.312 337.552 250.627C337.323 248.942 338.513 247.376 340.198 247.146L419.456 236.346C421.141 236.117 422.707 237.307 422.937 238.992C423.166 240.678 421.976 242.243 420.291 242.473Z"
        fill="#FF81B0"
      />
      <path
        d="M422.378 257.79L343.12 268.59C341.435 268.819 339.869 267.629 339.639 265.944C339.41 264.26 340.6 262.693 342.285 262.463L421.543 251.664C423.228 251.434 424.794 252.625 425.024 254.31C425.253 255.995 424.062 257.561 422.378 257.79Z"
        fill="#FF81B0"
      />
      <rect
        x="219.408"
        y="214.127"
        width="213.372"
        height="0.442222"
        transform="rotate(-7.75926 219.408 214.127)"
        fill="#412424"
      />
      <rect
        x="138.853"
        y="147"
        width="213.372"
        height="0.442222"
        transform="rotate(-7.75926 138.853 147)"
        fill="#412424"
      />
    </g>
    <defs>
      <linearGradient
        id="x91kt650uq-admin-paint0_linear"
        x1="35.7629"
        y1="229.5"
        x2="412.058"
        y2="19.7771"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EB6897" />
        <stop offset="0.145833" stopColor="#E64A7B" />
        <stop offset="0.459848" stopColor="#BE2F61" />
        <stop offset="0.84375" stopColor="#DA446F" />
        <stop offset="1" stopColor="#EB6897" />
      </linearGradient>
    </defs>
  </svg>
);

export default AdminIllustration;
