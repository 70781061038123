import React, { useState } from "react";
import { Activity } from "models/cpd/Activity";
import { Goal } from "models/cpd/Goal";
import { PlanYear } from "models/cpd/Plan";
import { Panel, PanelList, Tabs } from "components/common/ui/tabs/Tabs";
import EditDetails from "components/cpd/activity/edit/EditDetails";
import EditAdminDetails from "components/cpd/activity/edit/EditAdminDetails";
import { ActivityEditProgressStepper } from "components/cpd/activity/edit/ActivityEditProgressStepper";
import EditActivityDocs from "components/cpd/activity/edit/EditActivityDocs";
import MiniLoader from "components/common/ui/loader/MiniLoader";
import HintBox from "components/common/ui/hint-box/HintBox";
import IconLock from "components/common/icons/IconLock";

interface Props {
  activity?: Partial<Activity>;
  planId?: string;
  userId: string;
  goals: Goal[];
  year: PlanYear;
  onCancel: () => void;
  setActivityId: (id: string) => void;
  disabled?: boolean;
  isAdmin: boolean;
  locked?: boolean;
}

// const tab = ["Details", "Documents", "Waiver"];

const ActivityEdit = ({
  activity,
  planId,
  setActivityId,
  goals,
  year,
  disabled: disableOverride,
  locked,
  isAdmin,
  ...rest
}: Props) => {
  const [tabState, setTabState] = useState<number>(0);
  const disabled = disableOverride || (locked && !isAdmin);

  const editDetailsPanel = (
    <Panel key="detail">
      <EditDetails
        activity={activity}
        planId={planId}
        goals={goals}
        year={year}
        // disabled={disabled}
        setActivityId={setActivityId}
        isAdmin={isAdmin}
        {...rest}
      />
    </Panel>
  );
  const uploadFilesPanel = (
    <Panel key="upload">
      {activity?.id ? (
        <EditActivityDocs activity={activity} isAdmin={isAdmin} {...rest} />
      ) : (
        <MiniLoader theme="secondary" />
      )}
    </Panel>
  );

  const content = isAdmin
    ? [
        editDetailsPanel,
        <Panel key="admin">
          {activity?.id ? (
            <EditAdminDetails
              activity={activity}
              planId={planId}
              year={year}
              {...rest}
              disabled={disabled}
            />
          ) : (
            <MiniLoader theme="secondary" />
          )}
        </Panel>,
        uploadFilesPanel,
      ]
    : [editDetailsPanel, uploadFilesPanel];

  const tabs = isAdmin
    ? ["Details", "Admin", "Documents"]
    : ["Details", "Documents"];

  return (
    <div>
      <Tabs state={[tabState, setTabState]}>
        <ActivityEditProgressStepper stops={tabs} />
        <br />
        {locked && (
          <>
            <HintBox theme="warning" icon={<IconLock />}>
              {`This Activity has been locked${
                isAdmin
                  ? `. Futher edits are not recommended`
                  : ` from further edits.`
              }`}
            </HintBox>
            <br />
          </>
        )}
        <PanelList children={content} />
      </Tabs>
    </div>
  );
};

export default ActivityEdit;
