import React from "react";
import { Plan, PLAN_LIST_FILTER_KEYS, PlanListShape } from "models/cpd/Plan";
import { IColumn } from "components/common/ui/table/utils";
import styles from "./PlanList.module.scss";
import { IconEye } from "components/common/icons/twoToneIcons";
import Table from "components/common/ui/table/Table";
import { coreService } from "services/CoreService";
import { CPD_ROUTES } from "constants/routes/cpd";
import Accordian from "components/common/ui/accordian/Accordian";
import Container from "components/common/layouts/Container";
import ListFilters from "components/common/ui/filter/ListFilters";
import DateChip from "components/common/ui/chip/DateChip";
import {
  IDimensions,
  TSetActiveBucket,
  TSetManyBucketsActive,
} from "models/Dimension";
import { useSelector } from "react-redux";
import { isDimensionsLoadingSelector } from "store/selectors/dimensions.selectors";
import { PlanAttributeChip } from "components/cpd/common/PlanAttributeChip";
import { formatDate, timeDifference } from "utils/format/dateUtils";
import { getReviewWarningVariant } from "components/cpd/utils/utils";

interface Props {
  plans: PlanListShape;
  showFilters?: boolean;
  dimensions: IDimensions;
  setActive: TSetActiveBucket;
  setManyActive: TSetManyBucketsActive;
  isAppLoaded: boolean;
  isAdminList: boolean;
  userId: string;
  isTPS?: boolean;
}

const PlanList = ({
  plans,
  showFilters = true,
  dimensions,
  setActive,
  setManyActive,
  isAppLoaded,
  isAdminList,
  userId,
  isTPS,
}: Props) => {
  const isDimLoading = useSelector(isDimensionsLoadingSelector);
  // const data = React.useMemo(
  //   () =>
  //     plans.reduce(
  //       (d, u) => {
  //         if (u.complete) {
  //           d.completed.push(u);
  //         } else {
  //           d.uncompleted.push(u);
  //         }
  //         return d;
  //       },
  //       { completed: [], uncompleted: [] } as IData
  //     ),
  //   [plans]
  // );

  const columns: IColumn<Plan>[] = [
    {
      id: "plan_status",
      label: "Status",
      cell: (c, row) => <PlanAttributeChip status={row.plan_status} />,
      className: styles.clientColumn,
    },
    {
      id: "next_review_date",
      label: "Next Review Date",
      // cell: (c, row) => `${row.next_review_date?.toISOString().split("T")[0]} `,
      width: 1.3,
      cell: (c, row) => (
        <DateChip
          startDate={row.next_review_date}
          theme={getReviewWarningVariant({ start: row.next_review_date })}
        />
      ),

      className: styles.statusContainer,
    },
    {
      label: "Start At",
      id: "started_at",
      cell: (c, row) => formatDate(row.started_at),
    },
    {
      id: "final_date",
      label: "Final Date",
      cell: (c, row) => formatDate(row.final_date),
      className: styles.statusContainer,
    },
    {
      id: "duration",
      label: "Duration",
      cell: (c, row) =>
        timeDifference(row.started_at, row.final_date, false, true),
    },

    {
      id: "pathway",
      label: "Pathway",
      cell: (c, row) => <PlanAttributeChip pathway={row.pathway} />,

      className: styles.statusContainer,
    },
    {
      id: "link",
      label: " ",
      width: "50px",
      type: "icon",
      className: styles.iconCol,
      cell: () => <IconEye />,
    },
  ];
  return (
    <div className={styles.root}>
      {showFilters && (
        <Container spacing={"none"}>
          <ListFilters
            filters={PLAN_LIST_FILTER_KEYS}
            dimensions={dimensions}
            setActive={setActive}
            setManyActive={setManyActive}
            isLoading={!isAppLoaded}
            isUpdating={isDimLoading}
          />
        </Container>
      )}

      <Table
        columns={columns}
        data={plans.active || []}
        getRowProps={(row) => ({
          to: coreService.getRoute(
            isAdminList
              ? isTPS
                ? CPD_ROUTES.ADMIN_PLAN_DETAIL_TPS
                : CPD_ROUTES.ADMIN_PLAN_DETAIL
              : isTPS
              ? CPD_ROUTES.MY_TPS_DETAIL
              : CPD_ROUTES.MY_PLAN_DETAIL,
            {
              planId: row.plan_id,
              id: userId,
            }
          ),
        })}
        placeholder={{ noDataLabel: "No Active Plans", loadingRows: 2 }}
      />

      {(plans.completed || []).length > 0 && (
        <Accordian
          headerClassName={styles.deletedAccordian}
          header={<h4>Completed Plans</h4>}
        >
          <Table
            className={styles.table}
            columns={columns}
            data={plans.completed || []}
            getRowProps={(row) => ({
              to: coreService.getRoute(
                isAdminList
                  ? isTPS
                    ? CPD_ROUTES.ADMIN_PLAN_DETAIL_TPS
                    : CPD_ROUTES.ADMIN_PLAN_DETAIL
                  : isTPS
                  ? CPD_ROUTES.MY_TPS_DETAIL
                  : CPD_ROUTES.MY_PLAN_DETAIL,
                {
                  planId: row.plan_id,
                  id: userId,
                }
              ),
            })}
            placeholder={{
              noDataLabel: "No Completed Plans Found",
              loadingRows: 5,
            }}
          />
        </Accordian>
      )}
    </div>
  );
};

export default PlanList;
