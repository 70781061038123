import React from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import FormActions from "components/common/ui/form-elements/form-layout/FormActions";
import { PeerGroup } from "models/peergroup/PeerGroup";
import { PeerGroupMember } from "models/peergroup/member";
import UserSearchQuestion from "components/users/common/UserSearchQuestion";
import { SelectHookForm } from "components/common/ui/form-elements/hook-form-elements/SelectHookForm";
import { UserSearch } from "models/user/User";
import { getSchema } from "utils/formUtils";
import { useMutateUpdateMemberInPeerGroup } from "components/peergroup/hooks/useMutateUpdateMemberInPeerGroup";

// const resolver = getSchema({
//   user_search: Yup.object({
//     value: Yup.string().required("Please select a Member"),
//   }).nullable(),
//   peergroup_role_id: Yup.string().required("Please select a Role"),
// });
const resolver = getSchema({
  peergroup_role_id: Yup.string().required("Please select a Role"),
});

interface Props {
  saveText?: string;
  peergroup: PeerGroup;
  members: PeerGroupMember[];
  onCancel: () => void;
  children?: React.ReactNode;
}

const AddManyMembersToPeerGroup = ({
  onCancel,
  saveText = "Add Member(s)",
  peergroup,
  members,
}: Props) => {
  const { mutateAsync: Update, isLoading: updateLoading } =
    useMutateUpdateMemberInPeerGroup();
  const {
    handleSubmit,
    formState: { errors },
    register,
    control,
  } = useForm({
    mode: "onSubmit",
    resolver,
  });
  const handleSave = async ({
    peergroup_role_id,
    user_search,
  }: {
    user_search: UserSearch[];
    peergroup_role_id: string;
  }) => {
    await Promise.allSettled(
      user_search.map(async (user) => {
        const currentMember = members?.find(
          (member) => member.user_id === user.value
        );
        return Update({
          currentMember,
          peergroup_role_id,
          user_id: user.value,
          peergroupId: peergroup.id,
          deleted: false,
        });
      })
    );
    onCancel();
  };

  return (
    <>
      <form onSubmit={handleSubmit(handleSave)} noValidate>
        <UserSearchQuestion
          name="user_search"
          control={control}
          error={errors?.user_search?.value}
        />

        <SelectHookForm
          question={{
            name: "peergroup_role_id",
            label: "Peer group Role",
            type: "enum",
            options:
              peergroup.peergroup_roles?.map((r) => ({
                value: r.peergroup_role_id || "",
                label: r.peergroup_role_name,
              })) ?? [],
          }}
          error={errors?.peergroup_role_id}
          control={control}
          register={register}
          defaultValue={peergroup.peergroup_roles?.find(
            (r) => r.peergroup_role_claim === "MEMBER"
          )}
        />
        <FormActions
          onCancel={onCancel}
          isSubmitting={updateLoading}
          submitText={saveText}
        />
      </form>
    </>
  );
};

export default AddManyMembersToPeerGroup;
