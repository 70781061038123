import HintBox from "components/common/ui/hint-box/HintBox";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { currentUserSelector } from "store/selectors/core.selectors";
import { useUserDetail } from "components/users/hooks/useUserDetail";
import { useController } from "react-hook-form";
import { Control } from "react-hook-form/dist/types/form";
import { Supervisor } from "models/supervisors/Supervisor";
import { TUser } from "models/user/User";

const RegistrarConcernsFlag = ({
  userId,
  control,
  supervisor,
}: {
  userId: string;
  control?: Control;
  supervisor?: Supervisor;
}) => {
  const currentUser = useSelector(currentUserSelector);
  const { data } = useUserDetail(userId || "", {
    enabled: !!currentUser.id && userId !== currentUser.id,
  });
  const user =
    !!currentUser.id && userId !== currentUser.id ? data : currentUser;

  return control ? (
    <ControlSupervisor control={control} user={user} />
  ) : (
    <RegistrarConcerns supervisor={supervisor} user={user} />
  );
};

const ControlSupervisor = ({
  control,
  user,
}: {
  control: Control;
  user?: TUser;
}) => {
  const {
    field: { onChange, value: supervisor },
  } = useController({
    name: "supervisor",
    control: control,
  });

  // if the supervisor field isn't on the activity,
  // then we insert the user's current supervisor into the activity form state
  useEffect(() => {
    if (!supervisor?.id && user?.supervisor?.id) {
      console.log("SET SUPERVISOR");
      onChange(user?.supervisor);
    }
  }, [user?.supervisor, supervisor, onChange]);

  return <RegistrarConcerns supervisor={supervisor} user={user} />;
};

const RegistrarConcerns = ({
  supervisor,
  user,
}: {
  supervisor?: Supervisor;
  user?: TUser;
}) => {
  return (
    <>
      <br />
      <HintBox theme="warning">
        {supervisor && (
          <>
            <span>
              Supervisor:{" "}
              <strong>
                {supervisor?.given_name}&nbsp;
                {supervisor?.family_name}
              </strong>
            </span>
            <br />
            <br />
          </>
        )}
        Do you have concerns about your supervision, training programme
        progress, or clinic that you'd like to discuss with the DCT?
        <br />
        Please send an email to&nbsp;
        <a
          href={`mailto:tpc@rnzcuc.org.nz?subject=Registrar concerns -${user?.given_name} ${user?.family_name}&body=I have concerns about my supervision, training programme progress, or clinic`}
        >
          <b>tpc@rnzcuc.org.nz</b>
        </a>
      </HintBox>
    </>
  );
};

export default RegistrarConcernsFlag;
