import { Category } from "models/cpd/common/common";
import { RequirementType } from "models/cpd/common/requirements";

export const getPathwayLinks = (
  type: RequirementType,
  category: Category,
  sub?: string
) => {
  const url = categoryLinks[category];
  if (type === RequirementType.any) {
    return url;
  } else if (type === RequirementType.multi) {
    return subcategoryLinks[`${category}.${RequirementType.multi}`] || url;
  } else {
    return (
      subcategoryLinks[`${category}.${RequirementType.exact}.${sub}`] || url
    );
  }
};

export const categoryLinks = {
  [Category.resus]: "https://rnzcuc.org.nz/recertification-2021/resuscitation/",
  [Category.hours]:
    "https://rnzcuc.org.nz/recertification-2021/clinical-hours/",
  [Category.audit]:
    "https://rnzcuc.org.nz/recertification-2021/measuring-and-improving-outcomes/",
  [Category.quiz]:
    "https://rnzcuc.org.nz/recertification-2021/essential-knowledge-quiz/",
  [Category.sac]:
    "https://rnzcuc.org.nz/recertification-2021/structured-annual-conversation-21/",
  [Category.pdp]:
    "https://rnzcuc.org.nz/recertification-2021/professional-development-plan-21/",
  [Category.peer]:
    "https://rnzcuc.org.nz/recertification-2021/peer-group-activities/",
  [Category.edu]:
    "https://rnzcuc.org.nz/recertification-2021/educational-activities/",
  [Category.additional]:
    "https://rnzcuc.org.nz/recertification-2021/triennial-cpd/",
  [Category.tp_supervision]:
    "https://rnzcuc.org.nz/completing-the-training-programme/supervisor/",
  [Category.tp_ucpex]:
    "https://rnzcuc.org.nz/completing-the-training-programme/training-programme-components/ucpex/",
  [Category.tp_practical]: "",
  [Category.tp_uc_course]:
    "https://rnzcuc.org.nz/completing-the-training-programme/training-programme-components/online-urgent-care-course/",
  [Category.tp_core_skills]:
    "https://rnzcuc.org.nz/completing-the-training-programme/training-programme-components/core-skills-sign-off/",
  [Category.tp_case_studies]:
    "https://rnzcuc.org.nz/completing-the-training-programme/training-programme-components/case-studies/",
  [Category.tp_uni_papers]:
    "https://rnzcuc.org.nz/completing-the-training-programme/training-programme-components/university-papers/",
  [Category.tp_med_lit]:
    "https://rnzcuc.org.nz/completing-the-training-programme/training-programme-components/medical-literature-project/",
};

export const subcategoryLinks = {
  [`${Category.audit}.${RequirementType.exact}.clinical`]:
    "https://rnzcuc.org.nz/recertification-2021/measuring-and-improving-outcomes/clinical-notes-audit-21/",
  [`${Category.audit}.${RequirementType.exact}.patient`]:
    "https://rnzcuc.org.nz/recertification-2021/measuring-and-improving-outcomes/multi-source-feedback-survey/",
  [`${Category.audit}.${RequirementType.multi}`]:
    "https://rnzcuc.org.nz/recertification-2021/measuring-and-improving-outcomes/audit-of-a-doctors-choosing/",

  [`${Category.tp_practical}.${RequirementType.exact}.pals_practical`]:
    "https://rnzcuc.org.nz/completing-the-training-programme/training-programme-components/palsapls/",
  [`${Category.tp_practical}.${RequirementType.exact}.trauma_practical`]:
    "https://rnzcuc.org.nz/completing-the-training-programme/training-programme-components/trauma/",
  [`${Category.tp_practical}.${RequirementType.exact}.comms_practical`]:
    "https://rnzcuc.org.nz/completing-the-training-programme/training-programme-components/communications/",
  [`${Category.tp_practical}.${RequirementType.exact}.skills_demo`]:
    "https://rnzcuc.org.nz/completing-the-training-programme/training-programme-components/skills-demonstration-and-assessment-weekend/",
};
