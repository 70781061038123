import { useMutation, useQueryClient } from "react-query";
import { enableUser } from "api/UserApi";
import axios from "axios";
import { userQueryKeys } from "queries/constants";
import { MutationOptions } from "queries/queries";
import { useToast } from "components/common/ui/toast/useToast";

export const useMutateEnable = (options: MutationOptions = {}) => {
  const queryClient = useQueryClient();
  const { addErrorToast, addToast } = useToast();

  return useMutation(({ user, active }) => enableUser(user, active), {
    onSuccess: (result, inputs) => {
      addToast(
        `User successfully ${inputs.active ? "restored" : "suspended"}`,
        {
          theme: "success",
        }
      );

      queryClient.invalidateQueries(userQueryKeys.detail(inputs.user.id));
      queryClient.invalidateQueries(userQueryKeys.list());
    },
    onError: (e, inputs) => {
      if (axios.isAxiosError(e)) {
        addErrorToast(
          e,
          `Error ${inputs.active ? "suspending" : "restoring"} user`
        );
      } else {
      }
    },
    ...options,
  });
};
