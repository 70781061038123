import FormActions from "components/common/ui/form-elements/form-layout/FormActions";
import Button from "components/common/ui/button/Button";
import React, { useContext } from "react";
import { TabElements, TabsState } from "components/common/ui/tabs/Tabs";

interface Props {
  isSubmitting: boolean;
  isDirty?: boolean;
  onNext?: () => Promise<any>;
  onSubmit?: () => Promise<any>;
  onBack?: () => Promise<any>;
  onCancel: () => void;
  noBack?: boolean;
}

export const ActivityFormActions = ({
  isSubmitting,
  onNext,
  onBack,
  isDirty,
  onSubmit,
  noBack,
}: Props) => {
  const [activeIndex, setActive] = useContext(TabsState);
  const elements = useContext(TabElements);
  const stops = elements.tabs;

  const handleNext = async () => {
    let goNext = true;
    if (onNext) {
      goNext = await onNext();
    }
    if (goNext) {
      setActive((s: number) => s + 1);
    }
  };

  const handleBack = async () => {
    let goBack = true;
    if (onBack) {
      goBack = await onBack();
    }
    if (goBack) {
      setActive((s: number) => s - 1);
    }
  };

  return (
    <FormActions>
      {activeIndex !== 0 && !noBack && (
        <Button
          onClick={handleBack}
          disabled={activeIndex === 0}
          variant="empty"
          submitting={isSubmitting}
        >
          {isDirty ? "Save & " : ""}Back
        </Button>
      )}

      {activeIndex !== stops - 1 && (
        <Button
          key="next"
          onClick={handleNext}
          theme={"primary"}
          submitting={isSubmitting}
        >
          {isDirty ? "Save & " : ""}Next
        </Button>
      )}

      <Button
        theme="success"
        type={onSubmit ? "button" : "submit"}
        submitting={isSubmitting}
        onClick={onSubmit}
      >
        Save & Exit
      </Button>
    </FormActions>
  );
};
