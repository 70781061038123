import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import FormQuestion from "components/common/ui/form-elements/form-layout/FormQuestion";
import { Goal, goalAttributes, goalFormSchema } from "models/cpd/Goal";
import FormActions from "components/common/ui/form-elements/form-layout/FormActions";
import { useMutateGoal } from "components/cpd/hooks/useMutateGoal";

interface Props {
  goal?: Goal;
  userId: string;
  // onSubmit: (d: Partial<Goal>) => void;
  onCancel: () => void;
}

const GoalEditForm = ({ goal, userId, onCancel }: Props) => {
  const {
    handleSubmit,
    formState: { errors },
    register,
    control,
  } = useForm({
    mode: "onSubmit",
    defaultValues: { ...goal } as Record<string, Goal>,
    resolver: goalFormSchema,
  });
  const {
    mutate: mutateGoal,
    isLoading: isSubmitting,
    isSuccess,
  } = useMutateGoal();

  const handleSave = async (newGoal: Partial<Goal>) => {
    mutateGoal({
      newGoal: newGoal,
      oldGoal: goal,
      userId,
    });
  };
  useEffect(() => {
    if (isSuccess) {
      onCancel();
    }
  }, [isSuccess, onCancel]);

  return (
    <form onSubmit={handleSubmit(handleSave)} noValidate>
      {goalAttributes.map((g) => (
        <FormQuestion
          key={g.name}
          question={g}
          errors={errors}
          register={register}
          control={control}
        />
      ))}
      <FormActions
        onCancel={onCancel}
        isSubmitting={isSubmitting}
        submitText={"Save Goal"}
      />
    </form>
  );
};

export default GoalEditForm;
