import max from "date-fns/max";
import { PERMISSIONS } from "models/user/Permissions";
import { DIMENSION_MATCH, IDimension } from "models/Dimension";
import {
  userFieldsKey,
  userOfficeOnlyKey,
  userTypeKey,
  userTypeOptions,
} from "models/user/attributes/common";
import { isPlainObject, removeUndefinedFromObject } from "utils/common";
import {
  MEMBERSHIP_STATUS_OPTIONS,
  OVERSIGHT_SEVERITY_OPTIONS,
} from "models/user/attributes/office";
import {
  Facility,
  formatFacility,
  IServerFacility,
} from "models/facility/Facility";
import { convertToDate, isValidDate } from "utils/format/dateUtils";
import { QueryFilters } from "queries/queries";
import { IMenuItem } from "components/common/ui/form-elements/autocomplete/MenuItem";
import { UserType } from "models/user/attributes/userType";
import { Supervisor } from "models/supervisors/Supervisor";
import { PeerGroup } from "models/peergroup/PeerGroup";
import { APPROVED_TO_SUPERVISE } from "models/user/attributes/core";

interface User {
  id: string;
  email: string;
  given_name: string;
  family_name: string;
  [userTypeKey]: UserType;
  mcnz?: string;
  phone?: string;
  preferred_username?: string;
  nickname?: string;
  title?: string;
  [userFieldsKey]: TObjectAny;
  [userOfficeOnlyKey]: TObjectAny;
  facilities: Facility[];

  roles: PERMISSIONS[];

  mfa_enabled: boolean;
  active: boolean;
  status?: TUserStatus;
  search_field: string; //combined `email::given_name::family_name::mcnz` used for filtering user list
  current_pause_status: "active" | "paused"; //calculated from fields_office_only.pause_periods
  current_pause_start: Date | string; //calculated from fields_office_only.pause_periods
  current_pause_return: Date | string; //calculated from fields_office_only.pause_periods
  last_modified: string;
  approved_to_supervise: "yes" | "no";
  supervisor_approval_date: Date | string | null;
  supervisor?: Supervisor;
  peergroups_coordinating?: PeerGroup[];
}

const blankUser: TUser = {
  id: "",
  email: "",
  given_name: "",
  family_name: "",
  [userTypeKey]: UserType.general,
  mcnz: undefined as string | undefined,

  [userFieldsKey]: {} as TObjectAny,
  [userOfficeOnlyKey]: {} as TObjectAny,
  facilities: [] as Facility[],

  roles: [] as PERMISSIONS[],

  mfa_enabled: false as boolean,
  active: false as boolean,
  status: "" as TUserStatus | undefined,
  search_field: "", //combined `email::given_name::family_name::mcnz` used for filtering user list
  current_pause_status: "active" as "active" | "paused", //calculated from fields_office_only.pause_periods
  current_pause_start: "" as Date | string, //calculated from fields_office_only.pause_periods
  current_pause_return: "" as Date | string, //calculated from fields_office_only.pause_periods
  last_modified: "" as string,
  approved_to_supervise: "no",
  supervisor_approval_date: null,
};

// export type TUser = typeof blankUser;
export interface TUser extends User {
  [key: string]: any;
}
export interface UserSearch
  extends Pick<IMenuItem, "value" | "searchValue" | "label" | "metadata"> {
  // metadata: {
  //   id: string;
  //   email?: string;
  //   given_name?: string;
  //   family_name?: string;
  //   search_field: string;
  // };
}

export enum USER_STATUS {
  CONFIRMED = "Enabled",
  FORCE_CHANGE_PASSWORD = "Activation required",
  UNCONFIRMED = "Awaiting confirmation",
  RESET_REQUIRED = "Password Reset required",
  ARCHIVED = "Archived",
  COMPROMISED = "Compromised",
  UNKNOWN = "Unknown",
}

export enum COGNITO_MFA_STATUS {
  MFA = "SMS",
  NONE = "NOMFA",
}

export const getCognitoMfaStatus = (mfa: boolean) => {
  return mfa ? COGNITO_MFA_STATUS.MFA : COGNITO_MFA_STATUS.NONE;
};

export type TUserStatus = keyof typeof USER_STATUS;

export interface ICognitoUser {
  Attributes?: { Name: string; Value: string }[];
  UserAttributes?: { Name: string; Value: string }[];
  UserLastModifiedDate?: string;
  Enabled: boolean;
  UserStatus: TUserStatus;
  Username: string;
  PreferredMfaSetting: string | null;
  permissions?: PERMISSIONS[];
  fields_office_only: TObjectAny;
  fields_others: TObjectAny;
  facilities?: IServerFacility[];
  supervisor?: Supervisor;
  approved_to_supervise: number;
  supervisor_approval_date: string | null;
  peergroups_coordinating?: PeerGroup[];
}

interface IServerAttribute {
  Name: string;
  Value: string;
}

export interface ICognitoUserAttributes {
  sub?: string;
  "custom:useraccount_type"?: string;
  "custom:mcnz"?: string;
  given_name?: string;
  family_name?: string;
  email?: string;
  phone_number?: string;
  email_verified?: string;
  phone_number_verified?: string;
  birthdate?: string;
  nickname?: string;
  address?: string;
  UserLastModifiedDate?: string;
}

export const create = (data: Partial<TUser> = {}): TUser => {
  //strip out undefined attributes to they can be replaced with defaults from initialData
  const cleanedData = removeUndefinedFromObject(data);
  const search_field = [
    data.email,
    data.family_name,
    data.given_name,
    data.mcnz,
    data.phone,
  ].join("::");

  const activePauses = (data.fields_office_only?.pause_periods || []).filter(
    (p: { actual_return_date?: string }) =>
      p.actual_return_date === "" && !isValidDate(p.actual_return_date)
  );
  const latest_pause_return =
    activePauses.length > 0
      ? max(
          activePauses.map((p: { planned_return_date?: string }) =>
            convertToDate(p.planned_return_date)
          )
        )
      : "";

  const latest_pause_start =
    activePauses.length > 0
      ? max(
          activePauses.map((p: { started_at?: string }) =>
            convertToDate(p.started_at)
          )
        )
      : "";

  return {
    ...blankUser,
    ...cleanedData,
    search_field,
    current_pause_status: activePauses.length > 0 ? "paused" : "active",
    current_pause_start: latest_pause_start,
    current_pause_return: latest_pause_return,
    // supervisor: supervisor,
  };
};

export const map = (serverUser: ICognitoUser) => {
  const {
    UserStatus,
    UserAttributes,
    Attributes,
    PreferredMfaSetting,
    UserLastModifiedDate,
    Enabled,
    permissions,
    fields_office_only,
    fields_others,
    facilities,
    approved_to_supervise,
    supervisor_approval_date,
    supervisor,
    peergroups_coordinating,
  } = serverUser;
  const attArray = UserAttributes || Attributes || [];
  const attributes = attArray.reduce(
    (obj: Record<string, any>, a: IServerAttribute) => ({
      ...obj,
      [a.Name]: a.Value,
    }),
    {}
  );
  // extract the `object[]` types to make sure they are stored as arrays
  const { other_workplaces, home_phone, work_phone, ...others } =
    fields_others || {};
  const { medical_qualification, other_qualifications, mcnz, ...office } =
    fields_office_only || {};

  return create({
    id: attributes.sub,
    email: attributes.email,
    phone: attributes.phone_number ? `${attributes.phone_number}` : "",
    given_name: attributes.given_name,
    family_name: attributes.family_name,
    nickname: attributes.nickname,
    [userTypeKey]: attributes["custom:useraccount_type"],
    mcnz: attributes["custom:mcnz"] || mcnz,
    fields_office_only: {
      medical_qualification: Array.isArray(medical_qualification)
        ? medical_qualification
        : [],
      other_qualifications: Array.isArray(other_qualifications)
        ? other_qualifications
        : [],
      ...office,
    },
    fields_others: {
      home_phone: home_phone ? `${home_phone}` : "", //fixes for incorrect phone number crashing PhoneInput
      work_phone: work_phone ? `${work_phone}` : "", //fixes for incorrect phone number crashing PhoneInput
      other_workplaces: Array.isArray(other_workplaces) ? other_workplaces : [],
      ...others,
    },
    last_modified: UserLastModifiedDate,
    facilities: facilities?.map(formatFacility),

    status: UserStatus,
    active: Enabled,
    roles: permissions,
    mfa_enabled: !!PreferredMfaSetting,
    approved_to_supervise: approved_to_supervise === 1 ? "yes" : "no",
    supervisor_approval_date:
      approved_to_supervise === 1 ? supervisor_approval_date : null,
    supervisor:
      isPlainObject(supervisor) && Object.keys(supervisor).length > 0 // checks that the supervisor isn't an empty object
        ? supervisor
        : undefined,
    peergroups_coordinating: peergroups_coordinating ?? [],
  });
};

export const toServerObject = ({
  // given_name,
  // family_name,
  phone,
  roles,
  mfa_enabled,
  // status,
  active,
  mcnz,
  [userTypeKey]: userType,
  search_field,
  current_pause_status,
  current_pause_start,
  current_pause_return,
  facilities,
  last_modified,
  supervisor,
  approved_to_supervise,
  supervisor_approval_date,
  peergroups_coordinating,
  status: user_status,
  ...rest
}: Partial<TUser>) => {
  //TODO: remove this when phone_number is allowed to be nullable in the API
  const attr = phone
    ? {
        phone_number: phone,
      }
    : {};
  return {
    ...attr,
    // permissions: stripReadOnlyPermissions(roles),
    // enable_mfa: !!mfa_enabled,
    "custom:useraccount_type": userType,
    "custom:mcnz": mcnz,
    approved_to_supervise: approved_to_supervise === "yes" ? "1" : "0",
    supervisor_approval_date:
      approved_to_supervise === "yes" ? supervisor_approval_date : null,
    // address: `${rest[userFieldsKey].address.street_address} \n ${rest[userFieldsKey].address.locality} \n ${rest[userFieldsKey].address.region} ${rest[userFieldsKey].address.postcode} \n  ${rest[userFieldsKey].address.country}`,
    ...rest,
  };
};

export const toCognitoAttributes = ({
  email,
  given_name,
  family_name,
  phone,
  nickname,
}: Partial<TUser>) => ({
  email,
  given_name: given_name,
  family_name: family_name,
  phone_number: phone,
  nickname,
});

export interface IUserListDimensions extends QueryFilters {}

export const USER_LIST_FILTER_KEYS = [
  "search_field",
  userTypeKey,
  "fields_office_only.membership_status",
  "fields_office_only.additional_oversight_severity",
  "approved_to_supervise",
  "fields_office_only.fellowship_date",
  "fields_office_only.training_programme_start_date",
  "fields_office_only.rollover_date",
  // "current_pause_status",
  "current_pause_start",
  "current_pause_return",
  "last_modified",
  "status",
  "clear",
];

const STATUS_OPTIONS = [
  { key: null, label: "Select All" },
  ...Object.keys(USER_STATUS).map((k) => ({
    key: k,
    // @ts-ignore No clue why TS thinks the keys of USER_STATUS can't be used as the index for USER_STATUS...
    label: USER_STATUS[k],
  })),
];

const USER_TYPE_OPTIONS = [
  // { key: null, label: "Select All" },
  ...userTypeOptions.map((u) => ({ key: u.value, label: u.label })),
];

const UserListDimArray = [
  {
    key: "search_field",
    label: "Search by Email, Name, Phone or MCNZ",
    match: DIMENSION_MATCH.FUZZY,
    buckets: null,
    options: [],
    layout: "fullWidth",
  },
  {
    key: userTypeKey,
    label: "User Type",
    match: DIMENSION_MATCH.FACET,
    buckets: null,
    options: USER_TYPE_OPTIONS,
  },

  {
    key: "fields_office_only.membership_status",
    label: "Membership Status",
    match: DIMENSION_MATCH.FACET,
    buckets: null,
    options: MEMBERSHIP_STATUS_OPTIONS.map(({ value, ...d }) => ({
      key: value,
      ...d,
    })),
  },
  {
    key: "fields_office_only.additional_oversight_severity",
    label: "Monitor status",
    match: DIMENSION_MATCH.FACET,
    buckets: null,
    options: OVERSIGHT_SEVERITY_OPTIONS.map(({ value, ...d }) => ({
      key: value,
      ...d,
    })),
  },
  {
    key: "approved_to_supervise",
    label: "Approved to supervise",
    match: DIMENSION_MATCH.EXACT,
    buckets: null,
    options: [
      { key: null, label: "Select All" },
      ...APPROVED_TO_SUPERVISE.map(({ value, ...d }) => ({ key: value, ...d })),
    ],
  },
  {
    key: "fields_office_only.fellowship_date",
    label: "Fellowship Date",
    match: DIMENSION_MATCH.DATE_RANGE,
    buckets: null,
  },
  {
    key: "fields_office_only.training_programme_start_date",
    label: "Training Programme Start Date",
    match: DIMENSION_MATCH.DATE_RANGE,
    buckets: null,
  },
  {
    key: "fields_office_only.rollover_date",
    label: "Rollover Date",
    match: DIMENSION_MATCH.DATE_RANGE,
    buckets: null,
  },
  {
    key: "last_modified",
    label: "User Last Modified",
    match: DIMENSION_MATCH.DATE_RANGE,
    buckets: null,
  },
  {
    key: "clear",
    clearButton: true,
  },
  // {
  //   key: "current_pause_status",
  //   label: "Pause status",
  //   match: DIMENSION_MATCH.EXACT,
  //   buckets: null,
  //   options: [
  //     { key: null, label: "Select All" },
  //     { key: "active", label: "Active" },
  //     { key: "paused", label: "Paused" },
  //
  //   ],
  // },
  {
    key: "current_pause_start",
    label: "Pause Start Date",
    match: DIMENSION_MATCH.DATE_RANGE,
    buckets: null,
  },
  {
    key: "current_pause_return",
    label: "Pause Return Date",
    match: DIMENSION_MATCH.DATE_RANGE,
    buckets: null,
  },
  {
    key: "status",
    label: "Account Status",
    match: DIMENSION_MATCH.EXACT,
    buckets: null,
    options: STATUS_OPTIONS,
  },
] as IDimension[];

export const userListDimensions = UserListDimArray.reduce(
  (s, d) => ({ ...s, [d.key]: d }),
  {}
) as IUserListDimensions;
