import React, { useCallback, useMemo, useReducer, useState } from "react";
import { ActivityListShape } from "models/cpd/Activity";
import Modal from "components/common/ui/modal/Modal";
import { Plan, PLAN_STATUS, PlanYear } from "models/cpd/Plan";
import RequirementsProgress from "components/cpd/common/RequirementsProgress";
import { Goal } from "models/cpd/Goal";
import { Tab, TabList, Tabs } from "components/common/ui/tabs/Tabs";
import Container from "components/common/layouts/Container";
import IconButton from "components/common/ui/button/IconButton";
import IconAdd from "components/common/icons/IconAdd";
import GoalDetail from "components/cpd/goal/GoalDetail";
import { calcPlanProgress } from "components/cpd/utils/utils";
import { convertToDate, formatDate } from "utils/format/dateUtils";
import ActivityGantt from "components/cpd/plan/ActivityGantt";
import ToggleIconButton from "components/common/ui/button/toggle-icon-button/ToggleIconButton";
import IconChartGantt from "components/common/icons/IconChartGantt";
import IconList from "components/common/icons/IconList";
import { Category } from "models/cpd/common/common";
import Select from "components/common/ui/select/Select";
import ActivityList from "components/cpd/plan/ActivityList";
import IconLock from "components/common/icons/IconLock";
import HintBox from "components/common/ui/hint-box/HintBox";
import { LockPlanYear } from "components/cpd/plan/AdminPlanButtons";
import ActivityDetail, {
  ActivityEditState,
} from "components/cpd/activity/ActivityDetail";
import OverdueActivity from "components/cpd/plan/OverdueActivity";
import PlanStatus from "components/cpd/plan/PlanStatus";
import {
  getInitialPlanYearTabState,
  groupOptions,
  layoutReducer,
  mergeCategory,
  mergeGoalsKeys,
} from "components/cpd/utils/planUtils";
import styles from "./PlanDetail.module.scss";

interface Props extends DefaultProps {
  activity: ActivityListShape;
  userId: string;
  plan: Plan;
  goals: Goal[];
  isPathwayTPS: boolean;
}

const PlanDetail = ({ plan, goals, activity, userId, isPathwayTPS }: Props) => {
  const [tabState, setTabState] = useState(getInitialPlanYearTabState(plan));

  const [activeActivity, setActiveActivity] = useState<
    ActivityEditState | undefined
  >(undefined);
  const [goalDetail, setGoalDetail] = useState<Goal | undefined>(undefined);
  const [layout, setLayout] = useReducer(layoutReducer, {
    group: "category",
    display: "gantt",
  });
  const resetActivty = useCallback(() => setActiveActivity(undefined), []);

  const currentYear =
    plan.progress?.years[tabState] || ({ year: tabState + 1 } as PlanYear);

  const planLocked = ![PLAN_STATUS.active, PLAN_STATUS.pending].includes(
    plan.plan_status
  );

  const req = useMemo(
    () =>
      calcPlanProgress(
        plan.requirements,
        activity,
        Math.ceil(plan.duration / 12)
      ),
    [plan, activity]
  );

  const goalGroup = mergeGoalsKeys(
    activity.keyByYearByGoal[currentYear.year] || [],
    goals
  );
  const categoryGroup = mergeCategory(
    activity.keyByYearByCategory[currentYear.year] || [],
    plan.pathway,
    Object.keys(plan.requirements) as Category[]
  );
  return (
    <div className={styles.root}>
      <PlanStatus status={plan.plan_status} isTPS={isPathwayTPS} />
      <div className={styles.bgGrey}>
        <Container>
          <div className={styles.reqLabel}>
            Programme period Progress{" "}
            <span>
              {formatDate(plan.started_at)} -{" "}
              {formatDate(
                plan.progress?.years?.[Math.ceil(plan.duration / 12) - 1].end
              )}
            </span>
          </div>
          <RequirementsProgress
            progress={req.triennial}
            year={Math.ceil(plan.duration / 12)}
          />
          {req.overdue.length > 0 && (
            <>
              <div className={styles.reqLabel}>Overdue/Required Activity</div>
              <OverdueActivity
                activity={req.overdue}
                setActiveActivity={setActiveActivity}
              />
            </>
          )}
          <br />
        </Container>

        <Tabs state={[tabState, setTabState]}>
          <TabList decorator="none" tabBackground>
            {plan.progress?.years.map((y) => (
              <Tab key={y.year}>
                {y.locked && <IconLock className={styles.tabLock} />}Year{" "}
                {y.year}
              </Tab>
            ))}
            <div className={styles.spacer} />
            {/*<Tab>Summary</Tab>*/}
          </TabList>
        </Tabs>
      </div>
      <Container>
        {currentYear.locked && (
          <>
            <br />
            <HintBox icon={<IconLock />} theme="danger">
              <b>Year {currentYear.year} Locked</b>
              <br />
              No more activity can be added against this year
            </HintBox>
          </>
        )}
        <div className={styles.row}>
          <div className={styles.reqLabel}>
            Annual Progress <span>{currentYear?.label}</span>
          </div>
          <span className={styles.spacer} />
          <LockPlanYear plan={plan} yearIndex={tabState} />
          {!currentYear.locked && (
            <IconButton
              icon={<IconAdd />}
              theme="primary"
              iconVariant="empty"
              onClick={() =>
                setActiveActivity({
                  activity: { year: currentYear.year },
                  editing: true,
                })
              }
              disabled={currentYear.locked || planLocked}
            >
              Add Activity
            </IconButton>
          )}
        </div>

        <RequirementsProgress progress={req.annual} year={tabState} annual />
      </Container>
      <hr className={styles.hr} />
      <Container>
        <div className={styles.row}>
          <h2>Activity</h2>
          <span className={styles.spacer} />
          <ToggleIconButton
            onIcon={<IconList />}
            offIcon={<IconChartGantt />}
            toggled={layout.display === "list"}
            setToggle={(t) => {
              setLayout({ type: "display", value: t ? "list" : "gantt" });
            }}
            tooltipContent={`Switch to ${
              layout.display === "gantt" ? "List" : "Timeline"
            } layout`}
            theme="primary"
          />
          <Select
            name={"pathway"}
            value={layout.group}
            onSelect={(d, v) => setLayout({ type: "group", value: v })}
            label={"Group By"}
            options={groupOptions}
            config={{
              noInputError: true,
              inputChip: true,
              selectedOptionFormat: "chip",
            }}
          />
        </div>
      </Container>
      {layout.display === "gantt" ? (
        <Container className={styles.gantt}>
          <ActivityGantt
            activities={activity.map}
            groups={layout.group === "goal_id" ? goalGroup : categoryGroup}
            startDate={convertToDate(currentYear.start) || plan.started_at}
            setEditting={setActiveActivity}
            locked={currentYear.locked || planLocked}
          />
        </Container>
      ) : (
        <ActivityList
          activity={activity.map}
          group={layout.group === "goal_id" ? goalGroup : categoryGroup}
          groupKey={layout.group}
          setActivityEdit={setActiveActivity}
          setGoalDetail={setGoalDetail}
          defaultActivityFields={{
            year: currentYear.year,
          }}
          locked={currentYear.locked || planLocked}
        />
      )}

      <Modal open={!!activeActivity} onClose={resetActivty}>
        <ActivityDetail
          onCancel={resetActivty}
          goals={goals || []}
          userId={userId}
          planId={plan.plan_id}
          year={currentYear}
          {...activeActivity}
          disabled={planLocked}
        />
      </Modal>
      <Modal open={!!goalDetail} onClose={() => setGoalDetail(undefined)}>
        <GoalDetail
          goal={goalDetail}
          userId={userId}
          onCancel={() => setGoalDetail(undefined)}
        />
      </Modal>
      <br />
    </div>
  );
};

export default PlanDetail;
