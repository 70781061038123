import React from "react";
import cx from "classnames";
import LinkWrapper, {
  TLinkWrapper,
} from "components/common/ui/link-wrapper/LinkWrapper";
import styles from "./PageBackButton.module.scss";

export interface IPageBackButton extends TLinkWrapper {
  /** Alternative color variations */
  theme?: ThemeVariants;
  label?: string;

  /** CSS class to be applied to the button */
  className?: string;

  /** CSS override styles to be applied to the button */
  style?: TObjectAny;
  children?: React.ReactNode;
}

const PageBackButton = ({
  className,
  theme = "secondary",
  children,
  to,
  href,
  target,
  ...rest
}: IPageBackButton) => {
  return (
    <LinkWrapper to={to} href={href} target={target}>
      <button
        className={cx(
          styles.root,
          // buttonStyles.button,
          // buttonStyles[theme],
          className
        )}
        {...rest}
      >
        {children}
      </button>
    </LinkWrapper>
  );
};

export default PageBackButton;
