import React, { useMemo } from "react";
import { IconExcelFile } from "components/common/icons/IconFile";
import IconButton from "components/common/ui/button/IconButton";
import { TUser } from "models/user/User";
import {
  attributes as userAttributes,
  userAttributesMap,
} from "models/user/attributes";
import { deepObjectAccessor, keyBy } from "utils/common";
import { UserAttribute } from "models/user/attributes/common";
import { convertToDate, formatDate } from "utils/format/dateUtils";
import { DataAttribute } from "components/common/ui/form-elements/formElements";

const additionalAttributes: DataAttribute[] = [
  {
    name: "supervisor.id",
    label: "Supervisor Id",
    type: "string",
  },
  {
    name: "supervisor.given_name",
    label: "Supervisor Name",
    type: "string",
  },
  {
    name: "supervisor.family_name",
    label: "Supervisor Name",
    type: "string",
  },
  {
    name: "supervisor.mcnz",
    label: "Supervisor Mcnz",
    type: "string",
  },
  {
    name: "supervisor.status",
    label: "Supervisor Status",
    type: "string",
  },
  {
    name: "supervisor.email",
    label: "Supervisor Email",
    type: "string",
  },
];

const attributes = [
  ...userAttributes.filter((a) => a !== "facilities"),
  ...additionalAttributes.map((d) => d.name),
];
const attributeMap = {
  ...userAttributesMap,
  ...keyBy(additionalAttributes, "name"),
};

const headers = [
  "id",
  ...attributes.map((d) => {
    //TODO: label for header here places wrong column for supervisors detail
    // const label = attributeMap[d].altLabel ?? attributeMap[d].label;
    // if (label) {
    //   return label;
    // }
    const h = d.split(".");
    if (h[0] === "supervisor") {
      return d;
    }
    return h ? (h.length > 1 ? h[1] : h[0]) : d;
  }),
];

const formatUserAttribute = (user: TUser, key: string) => {
  const d = deepObjectAccessor(user, key);
  const attr = attributeMap[key] as UserAttribute | undefined;

  if (attr) {
    switch (attr.type) {
      case "enum":
        return attr.options?.find((o) => o.value === d)?.label || "";
      case "address":
        return attr.options?.reduce(
          (s, o) => `${s}${d?.[o.value] ? `${d[o.value]}\n` : ""}`,
          ""
        );
      case "enum[]":
        return d
          ? d
              .map(
                (e: string) =>
                  attr.options?.find((o) => o.value === e)?.label || e
              )
              .join("|")
          : "";
      case "object[]":
        return d
          ? d
              .map((e: TObjectAny) =>
                attr.options?.reduce(
                  (s, o, i) =>
                    `${s}${
                      e[o.value] ? `${i > 0 ? `, ` : ""}${e[o.value]}` : ""
                    }`,
                  ""
                )
              )
              .join("|")
          : "";
      case "date":
        let date = convertToDate(d);
        return date ? formatDate(date, "dd/MM/yyyy") : "";

      default:
        return d || "";
    }
  }
};

const prepData = (users: TUser[]) => {
  const data = users.map((user) => [
    user.id,
    ...attributes.reduce(
      (u, k) => [...u, `"${formatUserAttribute(user, k)}"`],
      [] as string[]
    ),
  ]);
  return [headers, ...data];
};

const toCSV = (rows: (string | number | boolean)[][]) => {
  let csvContent =
    "data:text/csv;charset=utf-8," + rows.map((e) => e.join(",")).join("\n");

  return encodeURI(csvContent);
};

interface Props extends DefaultProps {
  users: TUser[];
}
const ExportUsers = ({ users = [] }: Props) => {
  const data = useMemo(() => toCSV(prepData(users)), [users]);

  return (
    <IconButton
      download={`Users_Export - ${formatDate(new Date(), "yyyy-MM-dd")}.csv`}
      theme="secondary"
      icon={<IconExcelFile />}
      iconVariant="empty"
      variant="outline"
      href={data}
      target="_self"
    >
      Export Users
    </IconButton>
  );
};

export default ExportUsers;
