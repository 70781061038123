import { forwardRef, Ref } from "react";
import cx from "classnames";
import Tooltip from "components/common/ui/tooltip/Tooltip";
import {
  AvatarUser,
  getInitials,
  stringToColour,
} from "components/common/ui/avatar/avatarUtils";
import styles from "./Avatar.module.scss";

interface Props extends DefaultProps {
  user?: AvatarUser;
  size?: SizeOption;
  tooltip?: boolean;
  theme?: ThemeVariants;
  variant?: FillVariants;
}

const AvatarBase = forwardRef(
  (
    {
      user,
      size = "md",
      className,
      theme = "primary",
      variant = "filled",
      children,
      style,
      ...props
    }: Props,
    ref: Ref<HTMLDivElement>
  ) => (
    <span
      className={cx(
        styles.avatar,
        styles[`size-${size}`],
        styles[theme],
        styles[variant],
        className
      )}
      ref={ref}
      style={
        user?.id || user?.user_id
          ? {
              background: stringToColour(user?.id ?? user?.user_id ?? ""),
              ...style,
            }
          : style
      }
      {...props}
    >
      {children ?? getInitials(user)}
    </span>
  )
);

export const Avatar = ({ tooltip, ...props }: Props) =>
  tooltip ? (
    <Tooltip
      content={`${props.user?.given_name ?? ""} ${
        props.user?.family_name ?? ""
      }`}
      direction={"bottom-center"}
      arrow={false}
      offset={0}
    >
      <AvatarBase {...props} />
    </Tooltip>
  ) : (
    <AvatarBase {...props} />
  );

interface GroupProps extends DefaultProps {
  users?: AvatarUser[];
  size?: SizeOption;
  tooltip?: boolean;
  max?: number;
}

export const AvatarGroup = ({ users = [], size, max, tooltip }: GroupProps) => {
  console.log(users);
  const overflow = max && users?.length > max;
  const trimmedUsers = overflow ? users?.slice(0, max) : users;

  return (
    <div className={cx(styles.group)}>
      {trimmedUsers.map((u, i) => (
        <Avatar
          user={u}
          size={size}
          tooltip={tooltip}
          key={u.id ?? u.user_id ?? i}
        />
      ))}
      {overflow && (
        <Avatar className={styles.overflow} size={size}>
          +{users?.length - max}
        </Avatar>
      )}
    </div>
  );
};
