import { useQuery } from "react-query";
import { superviseesQueryKeys } from "queries/constants";
import { QueryOptions } from "queries/queries";
import { useToast } from "components/common/ui/toast/useToast";
import { fetchSuperviseeDetails } from "api/SupervisorsApi";

export const useSuperviseeDetails = (
  supervisorId: string,
  superviseeId: string,
  options?: QueryOptions
) => {
  const { addErrorToast } = useToast();
  return useQuery(
    superviseesQueryKeys.detail(superviseeId),
    () => fetchSuperviseeDetails(supervisorId, superviseeId),
    {
      onError: (e) =>
        addErrorToast(e, "Unable to load supervisee details", {
          id: superviseesQueryKeys.detail(superviseeId).join("::"),
        }),
      staleTime: 60 * 1000,
      ...options,
    }
  );
};
