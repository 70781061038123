import React from "react";

interface Props {
  className?: string;
}

const MessageBottle = ({ className }: Props) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 855 855"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M637.365 439.211L644.472 318.403C645.942 293.398 656.539 269.8 674.253 252.087L755.24 171.099C761.897 177.756 772.692 177.756 779.349 171.099L803.458 146.99C810.114 140.333 810.114 129.539 803.458 122.882L731.134 50.5581C724.477 43.9012 713.682 43.9012 707.025 50.5581L682.917 74.6666C676.26 81.3235 676.26 92.1163 682.917 98.7752L601.927 179.763C584.215 197.475 560.618 208.072 535.61 209.544L414.803 216.65C385.627 218.366 358.096 230.729 337.431 251.396L19.9987 568.833C-6.62643 595.458 -6.68396 638.57 19.9327 665.199C69.5093 714.799 139.184 784.494 188.772 834.094C215.399 860.729 258.525 860.678 285.157 834.047L602.624 516.58C623.285 495.917 635.648 468.386 637.365 439.211Z"
      fill="#E4EAF8"
      opacity="0.8"
    />
    <path
      d="M731.133 146.992L721.271 137.131C716.127 131.987 717.354 122.419 724.011 115.762L748.12 91.6539C754.777 84.997 764.344 83.7697 769.488 88.9136L731.135 50.5602C724.478 43.9033 713.683 43.9033 707.026 50.5602L682.918 74.6687C676.261 81.3256 676.261 92.1184 682.918 98.7773L601.928 179.765C584.216 197.477 560.619 208.074 535.613 209.544L414.808 216.65C385.632 218.366 358.099 230.729 337.434 251.396L19.9994 568.833C-6.63001 595.462 -6.63001 638.636 19.9994 665.265L22.6395 667.905C34.9027 680.169 54.8838 674.735 61.0121 658.51C64.9287 648.142 71.3958 637.98 80.2709 629.105L397.71 311.666C418.375 291 444.182 276.913 470.238 272.076L578.126 252.05C600.457 247.906 622.578 235.83 640.29 218.119L697.171 161.237L707.032 171.099C713.689 177.756 724.484 177.756 731.141 171.099C737.789 164.442 737.789 153.649 731.133 146.992Z"
      fill="#D5DCED"
    />
    <path
      d="M519.884 514.33C513.22 507.667 513.227 496.878 519.884 490.221L547.57 462.535C553.479 456.626 556.999 448.774 557.493 440.417L558.93 416.05C559.477 406.651 567.547 399.476 576.946 400.035C586.328 400.565 593.514 408.633 592.962 418.038L591.532 442.424C590.55 459.134 583.492 474.83 571.679 486.643L543.992 514.33C537.333 520.987 526.545 520.993 519.884 514.33ZM567.905 360.908C564.585 357.588 562.644 352.915 562.944 347.858L564.598 319.624C565.145 310.225 573.215 303.05 582.614 303.609C592.013 304.144 599.177 312.213 598.63 321.612L596.976 349.846C596.429 359.246 588.359 366.42 578.96 365.862C574.619 365.61 570.754 363.757 567.905 360.908Z"
      fill="#F4F7FC"
    />
    <path
      d="M849.022 47.9116L806.106 4.99386C798.499 -2.61332 785.836 -1.35825 779.869 7.59138L743.188 62.6145L791.403 110.83L846.427 74.1489C855.374 68.1825 856.629 55.5166 849.022 47.9116Z"
      fill="#FAEBC8"
    />
    <path
      d="M796.509 48.342L817.686 16.5751L806.105 4.99386C798.497 -2.61332 785.834 -1.35825 779.867 7.59138L743.187 62.6145L791.402 110.83L820.332 91.5431L798.64 69.8509C792.893 64.1061 792.002 55.1032 796.509 48.342Z"
      fill="#F5DCB4"
    />
    <path
      d="M417.731 436.285C397.759 416.312 392.364 389.329 405.677 376.016L140.491 641.201C127.178 654.515 132.573 681.5 152.545 701.471C172.518 721.441 199.501 726.839 212.815 713.525L453.779 472.561C459.673 466.667 457.091 456.37 448.97 454.495C438.463 452.068 427.293 445.848 417.731 436.285Z"
      fill="#EBC9A0"
    />
    <path
      d="M405.676 376.016C392.362 389.329 397.757 416.314 417.73 436.285L452.018 401.997C460.009 394.006 458.321 380.22 448.306 374.984C432.167 366.544 415.329 366.363 405.676 376.016Z"
      fill="#D7B48C"
    />
    <path
      d="M417.731 484.502C411.074 477.845 400.282 477.845 393.623 484.502L166.076 712.048C183.411 722.741 202.335 724.007 212.817 713.525L417.733 508.608C424.388 501.952 424.388 491.159 417.731 484.502Z"
      fill="#F5DCB4"
    />
  </svg>
);

export default MessageBottle;
