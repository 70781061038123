import { IDimension, IDimensions } from "models/Dimension";
import { parseAttribute, removeUndefinedFromObject } from "utils/common";
import { PeerGroupMeeting } from "./meeting";
import { PeerGroupMember } from "./member";

export enum PeerGroupStatus {
  inactive = "inactive",
  active = "active",
  // inactive,
  // active,
}

export interface PeerGroup {
  id: string;
  peergroup_name?: string;
  peergroup_summary?: string;
  peergroup_status?: PeerGroupStatus;
  peergroup_deleted?: boolean;
  peergroup_archived?: boolean;
  peergroup_updated_by?: string;
  peergroup_created_by?: string;
  peergroup_members?: PeerGroupMember[];
  peergroup_meetings?: PeerGroupMeeting[];
  peergroup_roles?: PeerGroupRole[];
  deleted?: boolean;
  accepted?: boolean;
}

export interface PeerGroupRole {
  id: string;
  peergroup_id?: string;
  peergroup_role_id?: string;
  peergroup_role_name?: string;
  peergroup_role_claim?: string;
  peergroup_role_date_created?: Date | string;
  peergroup_role_date_updated?: Date | string;
}

const blankPeerGroup: PeerGroup = {
  id: "",
  peergroup_name: "",
  peergroup_summary: "",
  peergroup_members: [],
  peergroup_roles: [],
  peergroup_meetings: [],
  peergroup_deleted: false,
  peergroup_archived: false,
  peergroup_status: PeerGroupStatus.inactive,
  // peergroup_updated_by: "",
  // peergroup_created_by: "",
  deleted: false as boolean,
};

export type PeerGroupListShape = {
  accepted?: PeerGroup[];
  deleted?: PeerGroup[];
};

export interface IServerPeerGroup
  extends Omit<
    PeerGroup,
    "peergroup_status" | "peergroup_archived" | "peergroup_deleted"
  > {
  peergroup_status?: number | boolean;
  peergroup_archived?: number | boolean;
  peergroup_deleted?: number | boolean;
}

export const createPeerGroup = (data: Partial<PeerGroup> = {}): PeerGroup => {
  const cleanedData = removeUndefinedFromObject(data);
  return {
    ...blankPeerGroup,
    ...cleanedData,
  };
};

export const fromApiToPeerGroup = ({
  id,
  deleted,
  peergroup_deleted,
  peergroup_archived,
  peergroup_status,
  peergroup_meetings,
  peergroup_members,
  peergroup_roles,
  ...peergroup
}: IServerPeerGroup): PeerGroup => {
  return createPeerGroup({
    id,
    ...peergroup,
    deleted: peergroup_deleted !== 1,
    // peergroup_status:
    //   peergroup_status === 1
    //     ? PeerGroupStatus.active
    //     : PeerGroupStatus.inactive,
    peergroup_archived: peergroup_archived === 1,
    peergroup_deleted: peergroup_deleted === 1,
    peergroup_meetings: parseAttribute(peergroup_meetings),
    peergroup_roles: parseAttribute(peergroup_roles),
    peergroup_members: parseAttribute(peergroup_members),
  });
};

export const toServerObject = ({
  id,
  peergroup_deleted,
  peergroup_archived,
  peergroup_status,
  peergroup_members,
  peergroup_meetings,
  peergroup_roles,
  deleted,
  ...rest
}: Partial<PeerGroup>): Partial<IServerPeerGroup> => {
  return {
    //fields that sending when create peergroup here
    id,
    peergroup_archived: peergroup_archived,
    peergroup_deleted: peergroup_deleted === true,
    peergroup_status: peergroup_status === PeerGroupStatus.active,
    ...rest,
  };
};

const PeerGroupDimArray = [] as IDimension[];

export interface IPeerGroupDimensions extends IDimensions {}

export const peergroupDimensions = PeerGroupDimArray.reduce(
  (s, d) => ({ ...s, [d.key]: d }),
  {}
) as IPeerGroupDimensions;
