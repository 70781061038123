import React from "react";
import Meta from "components/common/Meta";
import { AppContentContainer } from "components/common/layouts/appLayout/AppContentContainer";
import Container from "components/common/layouts/Container";
import { coreService } from "services/CoreService";
import { useHistory } from "react-router";
import { PEERGROUP_ROUTES } from "constants/routes/peergroup";
import {
  createPeerGroup as blankPeerGroup,
  PeerGroup,
} from "models/peergroup/PeerGroup";
import { useMutatePeerGroup } from "components/peergroup/hooks/useMutatePeerGroup";
import PageHeader from "components/common/layouts/appLayout/PageHeader";
import PeerGroupEditForm from "components/peergroup/edit/PeerGroupEditForm";

const ViewAdminCreatePeerGroup = () => {
  const history = useHistory();
  const { mutateAsync, isLoading: isSubmitting } = useMutatePeerGroup();
  const onSave = async (newPeerGroup: Partial<PeerGroup>) => {
    await mutateAsync({
      newPeerGroup,
      oldPeerGroup: blankPeerGroup(),
    });
    // history.push(
    //   coreService.getRoute(PEERGROUP_ROUTES.PEERGROUP_DETAIL, {
    //     peergroupId: newPG.id,
    //   })
    // );
    onCancel();
  };

  const onCancel = () => {
    history.push(coreService.getRoute(PEERGROUP_ROUTES.ADMIN_PEERGROUPS));
  };

  return (
    <AppContentContainer
      spacing="none"
      backButton={{
        to: coreService.getRoute(PEERGROUP_ROUTES.ADMIN_PEERGROUPS),
        label: "Back to Admin Peer Groups",
      }}
    >
      <Meta title="Create Peer Group" />
      <PageHeader
        spacing="extra"
        spacingTopOnly
        header={"Create New Peer Group"}
      />
      <Container>
        <PeerGroupEditForm
          onSubmit={onSave}
          onCancel={onCancel}
          saveText={"Create Peer Group"}
          isSubmitting={isSubmitting}
        />
      </Container>
    </AppContentContainer>
  );
};

export default ViewAdminCreatePeerGroup;
