import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import { userQueryKeys } from "queries/constants";
import { MutationOptions } from "queries/queries";
import { useToast } from "components/common/ui/toast/useToast";
import { updateSupervisor } from "api/SupervisorsApi";

export const useUpdateSupervisor = (options: MutationOptions = {}) => {
  const queryClient = useQueryClient();
  const { addErrorToast, addToast } = useToast();

  return useMutation(
    ({ id, supervisor_id, status }: useMutateUpdateSupervisorProps) => {
      return updateSupervisor(id, supervisor_id, status);
    },
    {
      onSuccess: (result, inputs) => {
        addToast(`Update supervisor successfully `, {
          theme: "success",
        });

        queryClient.invalidateQueries(userQueryKeys.detail(inputs.id));
        queryClient.invalidateQueries(userQueryKeys.list());
      },
      onError: (e) => {
        if (axios.isAxiosError(e)) {
          addErrorToast(e, `Unable to update supervisor`);
        } else {
        }
      },
      ...options,
    }
  );
};

interface useMutateUpdateSupervisorProps {
  id: string;
  supervisor_id: string;
  status: string;
}
