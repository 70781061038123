import React from "react";
import cx from "classnames";
import { useLayer } from "react-laag";
import ResizeObserver from "resize-observer-polyfill";
import { motion, AnimatePresence } from "framer-motion";
import styles from "components/common/ui/select/SelectMenu.module.scss";

interface Props {
  name: string;
  isOpened: boolean;
  disabled?: boolean;
  toggle: () => void;
  width?: "parent" | "fit-content" | number;

  children: React.ReactNode;
  className?: string;
  /** The direction of the tooltip relative to the element (will move if there isn't enough space) */
  direction?:
    | "top-center"
    | "top-start"
    | "top-end"
    | "left-start"
    | "left-center"
    | "left-end"
    | "right-start"
    | "right-center"
    | "right-end"
    | "bottom-start"
    | "bottom-center"
    | "bottom-end"
    | "center";

  /** Should the arrow show */
  arrow?: boolean;

  /** How much should the tooltip be offset from the element */
  offset?: number;

  /** Override hover trigger and force open */
  // open?: boolean;

  /** Framer motion animation object */
  animation?: {
    initial: object;
    animate: object;
    exit: object;
    transition: object;
  };
}

const SelectMenu = React.memo(
  ({
    name,
    isOpened,
    toggle,
    width = "parent",
    children,
    className,
    direction = "bottom-center",
    offset = 8,
    disabled,
  }: Props) => {
    const {
      layerProps,
      triggerProps,
      triggerBounds,
      arrowProps,
      renderLayer,
      layerSide,
    } = useLayer({
      isOpen: isOpened,
      containerOffset: offset,
      placement: direction,
      ResizeObserver,
      onOutsideClick: toggle,
      onDisappear: toggle,
    });

    const styleWidth =
      width === "parent"
        ? triggerBounds?.width
        : typeof width === "number"
        ? width
        : "fit-content";
    let style = {
      ...layerProps.style,
      opacity: isOpened ? 1 : 0,
      width: styleWidth,
    } as Record<string, any>;

    return (
      <>
        <button
          onClick={() => toggle()}
          className={styles.menuTrigger}
          aria-hidden="true"
          tabIndex={-1}
          type="button"
          disabled={disabled}
          {...triggerProps}
        />
        {renderLayer(
          <SelectMenuWrapper
            name={name}
            isOpen={isOpened}
            close={toggle}
            className={className}
            layerSide={layerSide}
            layerProps={layerProps}
            arrowProps={arrowProps}
            style={style}
            offset={offset}
          >
            {children}
          </SelectMenuWrapper>
        )}
      </>
    );
  }
);

interface WrapperProps {
  name: string;
  isOpen: boolean;
  close: () => void;
  layerProps: object;
  layerSide: "top" | "left" | "right" | "bottom" | "center" | undefined;
  arrowProps: object;
  children: React.ReactNode;
  className?: string;
  arrow?: boolean;
  offset?: number;
  animation?: {
    initial: object;
    animate: object;
    exit: object;
    transition: object;
  };
  style: object;
}
const inTransform = {
  top: { x: 0, y: -20 },
  left: { x: -20, y: 0 },
  bottom: { x: 0, y: 20 },
  right: { x: 20, y: 0 },
  center: { x: 0, y: 20 },
};
const outTransform = {
  top: { x: 0, y: -20, height: 0 },
  left: { x: -20, y: 0, width: 0 },
  bottom: { x: 0, y: 0, height: 0 },
  right: { x: 20, y: 0, width: 0 },
  center: { x: 0, y: 20, height: 0 },
};

const SelectMenuWrapper = ({
  name,
  isOpen,
  layerProps,
  layerSide = "bottom",
  children,
  className,
  animation,
  offset,
  style,
}: WrapperProps) => {
  const animateOptions = animation || {
    initial: { opacity: 0, scale: 0.85, ...inTransform[layerSide] },
    animate: { opacity: 1, scale: 1, x: 0, y: offset },
    exit: { opacity: 0, scale: 0.82, ...outTransform[layerSide] },
    transition: {
      type: "spring",
      stiffness: 800,
      damping: 35,
    },
  };
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className={cx(className, styles.menu, isOpen && styles.open)}
          {...layerProps}
          style={style}
          {...animateOptions}
        >
          <ul
            id={`select-${name}-results`}
            role="listbox"
            // className={cx(className, styles.menu)}
            // onClick={() => close()}
            aria-hidden={!isOpen}
            aria-expanded={isOpen}
          >
            {children}
          </ul>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default SelectMenu;
