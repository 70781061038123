import React from "react";
import { useWatch } from "react-hook-form";
import { Control, UseFormRegister } from "react-hook-form/dist/types/form";
import { FieldErrors } from "react-hook-form/dist/types/errors";
import {
  subTypeConditionalFields,
  subCategoryOptions,
} from "models/cpd/common/common";
import { subCategoryQuestion } from "components/cpd/utils/activityForm";
import ConditionalLogicWrapperHookForm from "components/common/ui/form-elements/hook-form-elements/ConditionalLogicWrapperHookForm";
import FormQuestion from "components/common/ui/form-elements/form-layout/FormQuestion";
import { Activity } from "models/cpd/Activity";

export const SubCategoryQuestion = ({
  control,
  register,
  errors,
  disabled,
}: {
  // question: DataAttribute;
  errors: Record<string, FieldErrors>;
  register: UseFormRegister<Record<string, Activity>>;
  disabled?: boolean;
  control: Control;
}) => {
  const watch = useWatch({
    name: "category",
    control,
  });
  let options = [];
  if (subTypeConditionalFields.includes(watch)) {
    //TODO: do better TS definitions
    // @ts-ignore
    options = subCategoryOptions[watch];
  }

  return (
    <ConditionalLogicWrapperHookForm
      control={control}
      conditional={subCategoryQuestion.conditional}
    >
      <FormQuestion
        question={{
          ...subCategoryQuestion,
          options,
        }}
        errors={errors}
        register={register}
        control={control}
        disabled={disabled}
      />
    </ConditionalLogicWrapperHookForm>
  );
};
