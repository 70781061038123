import { TUser } from "models/user/User";

export interface AvatarUser
  extends Partial<Pick<TUser, "family_name" | "given_name" | "id">> {
  user_id?: string;
}

export const getInitials = (user: AvatarUser = {}) =>
  `${user.given_name ? user.given_name.charAt(0).toUpperCase() : ""}${
    user.family_name ? user.family_name.charAt(0).toUpperCase() : ""
  }`;

export const stringToColour = (str: string) =>
  colors[Math.abs(hashCode(str)) % colors.length];

function hashCode(str: string) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
}

const colors = [
  "#31217d",
  "#3f2b88",
  "#4b3793",
  "#57429d",
  "#624fa6",
  "#6b5dad",
  "#726cb2",
  "#4a7abd",
  "#456aae",
  "#3c5ca0",
  "#314e92",
  "#254184",
  "#163476",
  "#012869",
  "#1c5372",
  "#196178",
  "#156f80",
  "#0f7d89",
  "#088b93",
  "#02999f",
  "#9d4f90",
  "#904889",
  "#873e7f",
  "#813274",
  "#7b2569",
  "#77135d",
];
