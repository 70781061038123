import * as Yup from "yup";
import { DataAttribute } from "components/common/ui/form-elements/formElements";
import { keyBy, parseAttribute, removeUndefinedFromObject } from "utils/common";
import { getDefaultValues, getValidations } from "utils/formUtils";
import { convertDateToIsoDate } from "utils/format/dateUtils";
import { PeerGroupMember } from "models/peergroup/member";

export enum PeergroupMeetingStatus {
  DRAFT = "DRAFT",
  COMPLETE = "COMPLETE",
  CANCELLED = "CANCELLED",
}

export interface PeerGroupMeeting {
  id: string;
  peergroup_id?: string;
  peergroup_meeting_title?: string;
  peergroup_meeting_status?: PeergroupMeetingStatus | string;
  peergroup_meeting_archived?: string;
  peergroup_meeting_venue?: string;
  peergroup_meeting_points?: string | number;
  peergroup_meeting_schedule?: Date | string;
  peergroup_meeting_attendees?: PeerGroupMeetingAttendees[];
  peergroup_meeting_deleted?: string | number;
  peergroup_meeting_updated_by?: string;
  peergroup_name?: string;
  peergroup_meeting_created_by?: string;
  peergroup_meeting_activity?: string;
  peergroup_meeting_notes?: string;
  peergroup_meeting_topics?: [] | string;
  peergroup_meeting_docs?: string[];
}

const blankPeerGroupMeeting: PeerGroupMeeting = {
  id: "",
  peergroup_id: "",
  peergroup_name: "",
  peergroup_meeting_title: "",
  peergroup_meeting_status: PeergroupMeetingStatus.DRAFT,
  peergroup_meeting_points: 1,
  peergroup_meeting_schedule: new Date(),
  peergroup_meeting_attendees: [],
  peergroup_meeting_deleted: 0,
  peergroup_meeting_notes: "",
  peergroup_meeting_topics: [],
};

export interface PeerGroupMeetingAttendees
  extends Partial<
    Pick<PeerGroupMember, "user_id" | "given_name" | "family_name" | "email">
  > {
  user_id: string;
}

export const createPeerGroupMeeting = (
  data: Partial<PeerGroupMeeting> = {}
): PeerGroupMeeting => {
  const cleanedData = removeUndefinedFromObject(data);
  return {
    ...blankPeerGroupMeeting,
    ...cleanedData,
  };
};

export interface IServerPeerGroupMeeting
  extends Omit<
    PeerGroupMeeting,
    | "id"
    | "peergroup_meeting_title"
    | "peergroup_meeting_subject"
    | "peergroup_meeting_status"
    | "peergroup_meeting_points"
    | "peergroup_meeting_schedule"
    | "peergroup_meeting_deleted"
    | "peergroup_meeting_attendees"
  > {
  id?: string;
  peergroup_meeting_title?: string;
  peergroup_meeting_subject?: string;
  peergroup_meeting_status?: string | PeergroupMeetingStatus;
  peergroup_meeting_attendees?: string | PeerGroupMeetingAttendees[];
  peergroup_meeting_points?: string | number;
  peergroup_meeting_deleted?: number | string;
  peergroup_meeting_schedule?: Date | string;
  peergroup_meeting_docs?: string[];
}

export const toServerMeetingObject = ({
  id,
  peergroup_meeting_deleted,
  peergroup_meeting_schedule,
  peergroup_meeting_topics,
  peergroup_meeting_attendees,
  peergroup_meeting_docs,
  ...rest
}: Partial<PeerGroupMeeting>): Partial<IServerPeerGroupMeeting> => {
  return {
    id,
    peergroup_meeting_deleted: peergroup_meeting_deleted === "true" ? 1 : 0,
    peergroup_meeting_schedule:
      convertDateToIsoDate(peergroup_meeting_schedule) ?? undefined,
    peergroup_meeting_topics:
      peergroup_meeting_topics && JSON.stringify(peergroup_meeting_topics),
    peergroup_meeting_attendees:
      peergroup_meeting_attendees &&
      JSON.stringify(peergroup_meeting_attendees),
    ...rest,
  };
};

export const fromApiToPeerGroupMeeting = ({
  id,
  peergroup_meeting_topics,
  // peergroup_meeting_notes_json,
  peergroup_meeting_attendees,
  peergroup_meeting_docs,
  peergroup_meeting_schedule,
  ...peergroupmeeting
}: IServerPeerGroupMeeting): PeerGroupMeeting => {
  return createPeerGroupMeeting({
    id,
    ...peergroupmeeting,
    peergroup_meeting_attendees: parseAttribute(peergroup_meeting_attendees),
    peergroup_meeting_topics: parseAttribute(peergroup_meeting_topics),
    peergroup_meeting_docs: parseAttribute(peergroup_meeting_docs),
    peergroup_meeting_schedule:
      peergroup_meeting_schedule &&
      new Date(peergroup_meeting_schedule).toString().substring(0, 25),
  });
};

export interface PeerGroupMeetingAttribute
  extends DataAttribute<keyof PeerGroupMeeting> {
  warning?: any; // UI display warning if not completed
  tracked?: boolean; //must track changes to this field (as a history)
}

export const peerGroupMeetingAttributes: PeerGroupMeetingAttribute[] = [
  {
    name: "peergroup_meeting_title",
    type: "string",
    label: "Short description (e.g key topics to cover or month/period)",
    altLabel: "Meeting description",
    required: true,
    validation: Yup.string().required("Please enter a description"),
    layout: {
      width: "half",
    },
  },
  {
    name: "peergroup_meeting_status",
    type: "enum",
    label: "Meeting Status",
    required: true,
    defaultValue: PeergroupMeetingStatus.DRAFT,
    options: [
      { value: PeergroupMeetingStatus.DRAFT, label: "DRAFT", theme: "warning" },
      {
        value: PeergroupMeetingStatus.COMPLETE,
        label: "COMPLETE",
        theme: "success",
      },
      {
        value: PeergroupMeetingStatus.CANCELLED,
        label: "CANCELLED",
        theme: "danger",
      },
    ],
    validation: Yup.string()
      .required("Please select a meeting status")
      .nullable(),
    layout: {
      width: "half",
    },
  },
  {
    name: "peergroup_meeting_venue",
    type: "string",
    label: "Venue",
  },
  {
    name: "peergroup_meeting_schedule",
    type: "datetime",
    // defaultValue: "2025-12-01",
    label: "Start date/time",
    required: true,
    validation: Yup.date().required("Please select a date/time"),
    layout: {
      width: "half",
    },
  },

  {
    name: "peergroup_meeting_points",
    type: "number",
    label: "Points (1/hr)",
    required: true,
    validation: Yup.number()
      .required("Please enter the points/duration")
      .typeError("Points/duration must be a number")
      .min(0, "Cannot be negative"),
    layout: {
      width: "half",
    },
  },

  {
    name: "peergroup_meeting_topics",
    label: "Topics Covered",
    type: "object[]",
    options: [
      {
        value: "subject",
        type: "string",
        label: "Topic",
        layout: {
          display: "heading",
        },
      },
      {
        value: "content",
        label: "Topic notes",
        type: "richText",
        defaultValue: "",
      },
    ],
    defaultValue: [
      {
        subject: "",
        content: "",
      },
    ],
    layout: {
      display: "fieldArrayRows",
    },
  },
  {
    name: "peergroup_meeting_notes",
    label: "Other Notes",
    type: "richText",
    altLabel: "Notes",
    tracked: true,
  },
];

export const defaultPeerGroupMeetingValues = getDefaultValues(
  peerGroupMeetingAttributes
);
export const peergroupMeetingValidations = getValidations(
  peerGroupMeetingAttributes
);

export const peerGroupMeetingAttributesMap = {
  ...keyBy(peerGroupMeetingAttributes, "name"),
} as const;

export const peerGroupMeetingAttributeKeys = Object.keys(
  peerGroupMeetingAttributesMap
);

export const peerGroupMeetingFormQuestionIDs = peerGroupMeetingAttributeKeys;
