import React from "react";
import { useForm } from "react-hook-form";
import FormActions from "components/common/ui/form-elements/form-layout/FormActions";
import { createPeerGroup, PeerGroup } from "models/peergroup/PeerGroup";
import { peerGroupFormSchema } from "models/peergroup/PeerGroupForm";
import { peerGroupAttributes } from "models/peergroup/attributes";
import FormQuestion from "components/common/ui/form-elements/form-layout/FormQuestion";
import Spacer from "components/common/ui/spacer/Spacer";

interface Props {
  peergroup?: PeerGroup;
  onSubmit: (attributes: Partial<PeerGroup>) => void;
  onCancel: (attributes: Partial<PeerGroup>) => void;
  saveText?: string;
  isSubmitting?: boolean;
  children?: React.ReactNode;
}

const PeerGroupEditForm = ({
  peergroup = createPeerGroup(),
  onSubmit,
  onCancel,

  saveText = "Update Peer Group",
  isSubmitting,
}: Props) => {
  const {
    handleSubmit,
    formState: { errors },
    register,
    control,
  } = useForm({
    mode: "onSubmit",
    defaultValues: peergroup as Record<string, any>,
    resolver: peerGroupFormSchema,
  });
  const handleSave = async (attributes: Partial<PeerGroup>) => {
    await onSubmit({ ...attributes, id: peergroup?.id });
  };

  return (
    <form onSubmit={handleSubmit(handleSave)} noValidate>
      <Spacer height={20} />
      {peerGroupAttributes.map((q) => (
        <FormQuestion
          question={q}
          errors={errors}
          register={register}
          control={control}
        />
      ))}

      <FormActions
        onCancel={onCancel}
        isSubmitting={isSubmitting}
        submitText={saveText}
      />
    </form>
  );
};

export default PeerGroupEditForm;
