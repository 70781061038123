import { QueryClient } from "react-query";
import { layoutService } from "services/LayoutService";

export function queryErrorHandler(error: unknown): void {
  layoutService.error(error, "Error");
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 60 * 1000,
      onError: queryErrorHandler,
      // refetchInterval: 1000,
    },
  },
});

export default queryClient;
