import React, { useEffect } from "react";
import Meta from "components/common/Meta";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { AppContentContainer } from "components/common/layouts/appLayout/AppContentContainer";
import UserDetail from "components/users/common/detail/UserDetail";
import { canAdminEdit } from "utils/authUtils";
import { PERMISSIONS } from "models/user/Permissions";
import { USER_ROUTES } from "constants/routes/user";
import { coreService } from "services/CoreService";
import { permissionsSelector } from "store/selectors/auth.selectors";
import { TUser } from "models/user/User";
import { useMutateUser } from "components/users/hooks/useMutateUserDetail";
import { useUserDetail } from "components/users/hooks/useUserDetail";
import MiniLoader from "components/common/ui/loader/MiniLoader";
import { useToast } from "components/common/ui/toast/useToast";

const ViewUserDetail = () => {
  const { id } = useParams<{ id: string; orgId: string }>();
  const permissions = useSelector(permissionsSelector);
  const updateUser = useMutateUser();
  const { addToast } = useToast();

  const { isLoading, data: user } = useUserDetail(id);

  const user_last_modified: any = new Date(user?.last_modified!);
  const today: any = new Date();
  const oneDay = 24 * 60 * 60 * 1000;
  const daysLastModified = Math.round(
    Math.abs((today - user_last_modified) / oneDay)
  );

  const checkIfUser6MonthsOutOfDate = () => {
    if (
      daysLastModified >= 180 &&
      (user?.user_type! === "fellow" || user?.user_type! === "registrar")
    ) {
      addToast(
        `User detail is currently 6+ months out of date. Need to be updated !`,
        {
          theme: "warning",
        }
      );
    }
  };

  useEffect(() => {
    checkIfUser6MonthsOutOfDate();
  }, [daysLastModified]); // eslint-disable-line react-hooks/exhaustive-deps

  const onUpdateAttributes = (attributes: Partial<TUser>) => {
    if (user) {
      updateUser.mutate({
        newUser: { id: user.id, ...attributes },
        oldUser: user,
      });
    }
  };
  const onUpdatePermission = (permissions: PERMISSIONS[]) => {
    if (user) {
      updateUser.mutate({
        newUser: { id: user.id, roles: permissions },
        oldUser: user,
      });
    }
  };

  return (
    <AppContentContainer
      spacing="none"
      backgroundColor="light-grey"
      backButton={{
        to: coreService.getRoute(USER_ROUTES.ADMIN_USERS),
        label: "Back to Users",
      }}
    >
      <Meta
        title={`${
          user ? `${user.given_name} ${user.family_name}` : "User"
        } details`}
      />
      <br />
      <br />

      {isLoading || !user ? (
        <MiniLoader theme="secondary" />
      ) : (
        <UserDetail
          user={user}
          canChangeUserAttributes={canAdminEdit(permissions)}
          canChangePermissions={canAdminEdit(permissions)}
          onUpdateAttributes={onUpdateAttributes}
          onUpdatePermission={onUpdatePermission}
          editLink={coreService.getRoute(USER_ROUTES.ADMIN_EDIT_USER, { id })}
          isSubmitting={updateUser.isLoading}
          isAdmin
        />
      )}
    </AppContentContainer>
  );
};

export default ViewUserDetail;
