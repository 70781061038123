import React from "react";
import cx from "classnames";
import styles from "./MiniLoader.module.scss";

interface Props {
  className?: string;
  variant?: "default" | "alt";
  theme?: ThemeVariants | "none";
}
const MiniLoader = ({
  className,
  variant = "default",
  theme = "default",
}: Props) => (
  <div className={cx(styles.dot, styles[variant], styles[theme], className)}>
    <div />
    <div />
    <div />
    <div />
  </div>
);

export default MiniLoader;
