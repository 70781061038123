import {
  clinicalUsersConditional,
  confirmedClinicalConditional,
  fellowConditional,
  memberUsersConditional,
  UserAttribute,
  userFieldsKey,
} from "models/user/attributes/common";
import { getDefaultValues, getValidations } from "utils/formUtils";
// import * as Yup from "yup";

export const fields: UserAttribute[] = [
  // Contact details
  {
    name: "title",
    type: "enum",
    label: "Prefix",
    options: [
      {
        value: "Dr",
      },
      {
        value: "Mr",
      },
      {
        value: "Ms",
      },
      {
        value: "Mrs",
      },
      {
        value: "Miss",
      },
      {
        value: "Mx",
      },
      {
        value: "prof",
        label: "Prof",
      },
      {
        value: "a_prof",
        label: "Associate Professor",
      },
      {
        value: "Sir",
      },
      {
        value: "Lady",
      },
    ],
  },
  {
    name: "home_phone",
    label: "Home Number",
    type: "phone",
  },
  {
    name: "work_phone",
    label: "Work Number",
    type: "phone",
  },
  {
    name: "address",
    label: "Address",
    type: "address",
    options: [
      {
        value: "street_address",
        label: "Street Number and Name",
        required: true,
      },
      { value: "locality", label: "Suburb (optional)" },
      { value: "region", label: "Town/City", required: true },
      { value: "postcode", label: "Postcode", required: true },
      {
        value: "country",
        label: "Country",
        required: true,
        defaultValue: "New Zealand",
      },
    ],
  },

  // Demographics
  {
    name: "birthdate",
    label: "Date of Birth",
    type: "date",
    required: true,
    // warning: { field: userTypeKey, values: "all" },
    conditional: clinicalUsersConditional,
  },
  {
    name: "gender",
    label: "Gender you identify with",
    type: "enum",
    options: [
      { value: "F", label: "Female" },
      { value: "M", label: "Male" },
      { value: "D", label: "Gender diverse" },
    ],
    conditional: clinicalUsersConditional,
  },
  {
    name: "ethnicity",
    label: "Ethnicity",
    type: "enum[]",
    options: [
      { value: "maori", label: "Māori" },
      { value: "nz_euro", label: "NZ European" },
      { value: "cook_islands_maori", label: "Cook Islands Māori" },
      { value: "samoan", label: "Samoan" },
      { value: "niuean", label: "Niuean" },
      { value: "tongan", label: "Tongan" },
      { value: "indian", label: "Indian" },
      { value: "chinese", label: "Chinese" },
      { value: "other", label: "Other (please specify)" },
    ],
    conditional: clinicalUsersConditional,
  },
  {
    name: "ethnicity_other",
    label: "Please specify",
    type: "string",
    conditional: [
      ...clinicalUsersConditional,
      { field: "fields_others.ethnicity", values: ["other"] },
    ],
  },
  {
    name: "maori_whakapapa",
    label: "Māori whakapapa?",
    type: "enum",
    options: [
      { value: "yes", label: "Yes" },
      { value: "no", label: "No" },
      { value: "dont_know", label: "Don't know" },
    ],
    conditional: clinicalUsersConditional,
  },

  // Workplace
  {
    name: "other_workplaces",
    label: "Non-Urgent Care Workplace(s)",
    type: "object[]",
    tracked: true,
    conditional: clinicalUsersConditional,
    options: [
      { value: "workplace", label: "Workplace Name", type: "string" },
      // { value: "fte", label: "Approximate FTE", type: "string" },
      // { value: "location", label: "Location", type: "string" },
    ],
  },

  {
    name: "interests",
    label: "Interests",
    type: "string",
    conditional: confirmedClinicalConditional,
    layout: {
      type: "textArea",
    },
  },
  {
    name: "specialisations",
    label: "Specialisations",
    type: "string",
    conditional: confirmedClinicalConditional,
    layout: {
      type: "textArea",
    },
  },

  // CPD details
  // {
  //   name: "current_supervisor",
  //   label: "Who is your current supervisor?",
  //   type: "string",
  //   conditional: exceptFellowUsersConditional,
  //   tracked: true,
  // },
  // {
  //   name: "peer_group",
  //   label: "Name/ID of your peer group",
  //   type: "string",
  //   conditional: clinicalUsersConditional,
  //   tracked: true,
  // },
  {
    name: "supervisor_status",
    label: "Are you a supervisor of any registrars?",
    type: "enum",
    options: [
      { value: "supervisor", label: "Yes" },
      { value: "not_supervisor", label: "No" },
    ],
    defaultValue: "not_supervisor",
    conditional: [
      ...fellowConditional,
      { field: "approved_to_supervise", values: ["yes"] },
    ],
    tracked: true,
  },
  {
    name: "website_login_email",
    label:
      "If yes, please provide the email used to login to the RNZCUC website:",
    type: "email",
    conditional: [
      ...memberUsersConditional,
      { field: "fields_office_only.website_access", values: ["yes"] },
    ],
  },
  {
    name: "ecg_weekly_email",
    label: "If no, please provide your email to be subscribed:",
    type: "email",
    conditional: [
      ...memberUsersConditional,
      { field: "fields_office_only.ecg_weekly_subscription", values: ["yes"] },
    ],
  },
  {
    name: "uc_course_email",
    label: "If yes, please provide the email you use to access UC Course:",
    type: "email",
    conditional: [
      ...memberUsersConditional,
      { field: "fields_office_only.uc_course_access", values: ["yes"] },
    ],
  },
];

export const defaultValuesFields = getDefaultValues(fields);
export const userFieldsValidations = getValidations(fields, userFieldsKey);
