import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import { permissionsSelector } from "store/selectors/auth.selectors";
import { coreService } from "services/CoreService";
import { FACILITY_ROUTES } from "constants/routes/facility";
import Meta from "components/common/Meta";
import MiniLoader from "components/common/ui/loader/MiniLoader";
import { canAdminEdit } from "utils/authUtils";
import { AppContentContainer } from "components/common/layouts/appLayout/AppContentContainer";
import FacilityDetail from "components/facilities/detail/FacilityDetail";
import { userListDimSelector } from "store/selectors/dimensions.selectors";
import { useUsersList } from "components/users/hooks/useUsersList";
import { TUser } from "models/user/User";
import { FacilityUser, Facility } from "models/facility/Facility";
import { useMutateFacility } from "components/facilities/hooks/useMutateFacility";
import { useFacilityDetail } from "components/facilities/hooks/useFacilityDetail";

const filterFacilityUsers = (
  users: TUser[] = [],
  facilityUsers: FacilityUser[] = []
) =>
  users.filter((user) =>
    facilityUsers.some((f) => f.useraccount_id === user.id)
  );

const ViewFacilityDetails = () => {
  const [facilityUsers, setUsers] = useState<TUser[]>([]);
  const { facilityId } = useParams<{ facilityId: string; orgId: string }>();
  const permissions = useSelector(permissionsSelector);
  const updateFacility = useMutateFacility();

  const { isLoading, data: facility } = useFacilityDetail(facilityId);
  const dimensions = useSelector(userListDimSelector);
  //get users list to compare facility users list
  const { data: users } = useUsersList(dimensions);

  useEffect(() => {
    setUsers(filterFacilityUsers(users?.items, facility?.users));
  }, [users, facility]);

  const onUpdateAttributes = (attributes: Partial<Facility>) => {
    if (facility) {
      updateFacility.mutate({
        newFacility: { facility_id: facilityId, ...attributes },
        oldFacility: facility,
      });
    }
  };
  return (
    <AppContentContainer
      spacing="none"
      // backgroundColor="light-grey"
      backButton={{
        to: coreService.getRoute(FACILITY_ROUTES.ADMIN_FACILITIES),
        label: "Back to Facilites",
      }}
    >
      <Meta
        title={`${
          facility ? `${facility.facility_name} ` : "Facility"
        } details`}
      />
      <br />
      <br />
      {isLoading || !facility ? (
        <MiniLoader theme="secondary" />
      ) : (
        <FacilityDetail
          facility={facility}
          facilityUsers={facilityUsers}
          canChangeFacilityAttributes={canAdminEdit(permissions)}
          onUpdateAttributes={onUpdateAttributes}
          editLink={coreService.getRoute(FACILITY_ROUTES.ADMIN_EDIT_FACILITY, {
            facilityId,
          })}
        />
      )}
    </AppContentContainer>
  );
};

export default ViewFacilityDetails;
