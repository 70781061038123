import React, { useState } from "react";
import { createPortal } from "react-dom";
import { useOnMount } from "hooks/useOnMount";
import styles from "./Toast.module.scss";

const toastClassName = styles.portal;

const ToastPortal = ({ children }: { children: React.ReactNode }) => {
  const [container] = useState(() => {
    const el = document.createElement("div");
    el.classList.add(toastClassName);
    return el;
  });
  useOnMount(() => {
    document.body.appendChild(container);
    return () => {
      document.body.removeChild(container);
    };
  });

  return createPortal(children, container);
};

export default ToastPortal;
