import React from "react";
import createIcon from "components/common/icons/common/createIcon";

const IconExternalLink = createIcon(
  <React.Fragment>
    <path d="M13.2 3.59998C12.5372 3.59998 12 4.13723 12 4.79998C12 5.46272 12.5372 5.99998 13.2 5.99998H16.3029L8.75145 13.5514C8.28282 14.0201 8.28282 14.7799 8.75145 15.2485C9.22008 15.7171 9.97987 15.7171 10.4485 15.2485L18 7.69703V10.8C18 11.4627 18.5372 12 19.2 12C19.8627 12 20.4 11.4627 20.4 10.8V4.79998C20.4 4.13723 19.8627 3.59998 19.2 3.59998H13.2Z" />
    <path d="M5.99998 5.99998C4.67449 5.99998 3.59998 7.07449 3.59998 8.39998V18C3.59998 19.3255 4.67449 20.4 5.99998 20.4H15.6C16.9255 20.4 18 19.3255 18 18V14.4C18 13.7372 17.4627 13.2 16.8 13.2C16.1372 13.2 15.6 13.7372 15.6 14.4V18H5.99998V8.39998L9.59998 8.39998C10.2627 8.39998 10.8 7.86272 10.8 7.19998C10.8 6.53723 10.2627 5.99998 9.59998 5.99998H5.99998Z" />
  </React.Fragment>,
  "Scroll"
);

export default IconExternalLink;
