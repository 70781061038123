import { DevelopmentAction } from "models/cpd/Goal";
import cx from "classnames";
import {
  IconProgressComplete,
  IconProgressStar,
} from "components/common/icons/IconProgress";
import React from "react";
import styles from "./GoalActionList.module.scss";

const GoalItemActionList = ({ items }: { items?: DevelopmentAction[] }) => (
  <ul>
    {items?.map((g, i) => (
      <li className={cx(styles.item, g.complete && styles.complete)} key={i}>
        {g.complete ? <IconProgressComplete /> : <IconProgressStar />}
        <div className={styles.text}>{g.text}</div>
      </li>
    ))}
  </ul>
);

export default GoalItemActionList;
