import React from "react";
import { Auth, Hub } from "aws-amplify";
import { ForgotPassword } from "aws-amplify-react";
import { IAuthPieceProps } from "aws-amplify-react/lib/Auth/AuthPiece";
import Meta from "components/common/Meta";
import AuthLayout from "components/common/layouts/AuthLayout";
import ForgotPasswordForm from "components/common/auth/forms/ForgotPasswordForm";
import RequireNewPasswordForm from "components/common/auth/forms/RequireNewPasswordForm";
import { layoutService } from "services/LayoutService";

const exportDefault = {};
export default exportDefault;

export class CustomForgotPassword extends ForgotPassword {
  forgotPasswordError = null;
  resetPasswordError = null;
  boundHubAuthListener: (result: any) => void = () => {};

  constructor(props: IAuthPieceProps) {
    super(props);
    this._validAuthStates = ["forgotPassword"];
  }

  hubAuthListener(result: any) {
    if (!result || !result.payload) {
      return;
    }
    switch (result.payload.event) {
      case "forgotPassword":
        this.forgotPasswordError = null;
        return;
      case "forgotPassword_failure":
        this.forgotPasswordError =
          result.payload.data && result.payload.data.message;
        this.setState({ ...this.state });
        return;
      case "forgotPasswordSubmit_failure":
        this.resetPasswordError =
          result.payload.data && result.payload.data.message;
        this.setState({ ...this.state });
    }
  }

  componentDidMount() {
    super.componentDidMount();
    this.boundHubAuthListener = this.hubAuthListener.bind(this);
    Hub.listen("auth", this.boundHubAuthListener);
  }

  componentWillUnmount() {
    Hub.remove("auth", this.boundHubAuthListener);
  }
  async handleFormSend() {
    try {
      const data = await Auth.forgotPassword(this.getUsernameFromInput());
      this.setState({ delivery: data.CodeDeliveryDetails });
    } catch (e) {
      layoutService.error(e);
    }
  }

  async handleResetSubmit() {
    try {
      await Auth.forgotPasswordSubmit(
        this.getUsernameFromInput(),
        this.inputs.code,
        this.inputs.password
      );
      this.changeState("signIn");
      this.setState({ delivery: null });
    } catch (e) {
      layoutService.error(e);
    }
  }

  showComponent(theme: any) {
    const forgot = !this.state.delivery;
    return (
      <AuthLayout variant={forgot ? "underwater" : "bottle"}>
        <Meta title={forgot ? "Forgot Password" : "Reset Password"} />
        <h2>{forgot ? "Forgot Password" : "Reset Password"}</h2>
        <p>
          {forgot
            ? "To reset your password, please enter your email below"
            : "Please enter the code you received from your email, and your new password"}
        </p>
        {forgot && (
          <ForgotPasswordForm
            onSubmit={this.handleFormSend.bind(this)}
            values={this.inputs}
            errorMessage={this.forgotPasswordError}
            changeState={this.changeState}
          />
        )}
        {!forgot && (
          <RequireNewPasswordForm
            onSubmit={this.handleResetSubmit.bind(this)}
            values={this.inputs}
            errorMessage={this.resetPasswordError}
            changeState={this.changeState}
            isResetCode
          />
        )}
      </AuthLayout>
    );
  }
}
