import React, { FC } from "react";
import cx from "classnames";
import styles from "./icon.module.scss";

export interface TIcon extends DefaultProps {
  color?: string;
  size?: string;
}
const createIcon = (content: React.ReactNode, name: string) => {
  let Icon: FC<TIcon> = ({ className, style, color, size, ...rest }) => (
    <svg
      className={cx(styles.root, className)}
      viewBox="0 0 24 24"
      style={{ fill: color, fontSize: size, ...style }}
      {...rest}
    >
      {content}
    </svg>
  );
  Icon.displayName = `${name}Icon`;

  return React.memo(Icon);
};

export default createIcon;
