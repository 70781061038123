export const ROOT = ``;
export const ADMIN_ROOT = `${ROOT}/admin`;

export enum ROUTE_PARAMS {
  USER = "id",
  PLAN = "planId",
  FACILTIY = "facilityId",
  PEERGROUP = "peergroupId",
  MEETING = "meetingId",
  SUPERVISOR = "supervisorId",
  SUPERVISEE = "superviseeId",
  // FACILTIY = "id"
}

export type TRouteParamOptions = Partial<Record<ROUTE_PARAMS, string>>;
