import { ADMIN_ROOT, ROUTE_PARAMS } from "constants/routes/common";
import { PermissionGroups } from "models/user/Permissions";

const ADMIN_FACILITIES = {
  path: `${ADMIN_ROOT}/facilities`,
  requiredRole: PermissionGroups.adminRead,
};

const ADMIN_CREATE_FACILITY = {
  path: `${ADMIN_FACILITIES.path}/create`,
  requiredRole: PermissionGroups.adminEdit,
};

const ADMIN_FACILITY_DETAIL = {
  path: `${ADMIN_FACILITIES.path}/detail/:${ROUTE_PARAMS.FACILTIY}`,
  requiredRole: ADMIN_FACILITIES.requiredRole,
};

const ADMIN_EDIT_FACILITY = {
  // path: `${ADMIN_FACILITIES.path}/:${ROUTE_PARAMS.FACILTIY}/edit`,
  path: `${ADMIN_FACILITY_DETAIL.path}/edit`,
  requiredRole: PermissionGroups.adminEdit,
};

export const FACILITY_ROUTES = {
  ADMIN_FACILITIES,
  ADMIN_CREATE_FACILITY,
  ADMIN_FACILITY_DETAIL,
  ADMIN_EDIT_FACILITY,
};
