import React from "react";
import createIcon from "components/common/icons/common/createIcon";

const IconLineChart = createIcon(
  <React.Fragment>
    <path d="M3 20.0058V16.2958L10.78 11.8058L14.55 14.0058L21 10.2558V20.0058H3ZM21 7.94581L14.55 11.6758L10.78 9.50581L3 14.0058V11.7958L10.78 7.30581L14.55 9.50581L21 5.75581V7.94581Z" />
  </React.Fragment>,
  "LineChart"
);

export default IconLineChart;
