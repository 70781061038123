import React from "react";
import Modal, { IModalProps } from "components/common/ui/modal/Modal";
import styles from "./Alert.module.scss";
import cx from "classnames";
import Spacer from "components/common/ui/spacer/Spacer";
import FormActions from "components/common/ui/form-elements/form-layout/FormActions";
import Button from "components/common/ui/button/Button";

interface Props extends IModalProps {
  heading: React.ReactNode;
  onAgree: () => void;
  cancelText?: React.ReactNode;
  agreeText?: React.ReactNode;
}

const Alert = ({
  heading,
  cancelText = "Cancel",
  agreeText = "Yes, I agree",
  onAgree,
  children,
  className,
  ...rest
}: Props) => (
  <Modal
    role="alertdialog"
    aria-describedby="alert-dialog-id-019827364"
    {...rest}
    className={cx(className, styles.root)}
    showCloseButton={false}
    disableOverlayClick
  >
    <h2 className={styles.heading} id="alert-dialog-id-019827364">
      {heading}
    </h2>
    {children}
    <Spacer />
    <FormActions>
      <Button variant="empty" onClick={rest.onClose}>
        {cancelText}
      </Button>
      <Button theme="danger" onClick={onAgree}>
        {agreeText}
      </Button>
    </FormActions>
  </Modal>
);

export default Alert;
