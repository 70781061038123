import { peergroupValidations } from "models/peergroup/attributes";
import { peergroupMeetingValidations } from "models/peergroup/meeting";
import { getSchema } from "utils/formUtils";

export const peerGroupFormSchema = getSchema({
  ...peergroupValidations,
});

export const peerGroupMeetingFormSchema = getSchema({
  ...peergroupMeetingValidations,
});
