// Modified from https://github.com/jeetiss/tabs
import React, { useContext, cloneElement, useRef } from "react";
import cx from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { TabsState } from "components/common/ui/tabs/Tabs";
import { usePrevious } from "hooks/useHasChanged";
import styles from "./Tabs.module.scss";

interface PanelListProps {
  className?: string;
  children: React.ReactElement[];
}
const forwardAnimation = {
  initial: { opacity: 0, x: -32 },
  animate: {
    opacity: 1,
    x: 0,
    transition: { delay: 0.1, ease: "easeInOut", duration: 0.2 },
  },
  exit: {
    opacity: 0,
    // x: 32,
    transition: { ease: "easeInOut", duration: 0.1 },
  },
};
const backwardAnimation = {
  initial: { opacity: 0, x: 32 },
  animate: {
    opacity: 1,
    x: 0,
    transition: { delay: 0.1, ease: "easeInOut", duration: 0.2 },
  },
  exit: {
    opacity: 0,
    // x: -32,
    transition: { ease: "easeInOut", duration: 0.1 },
  },
};

export const PanelList = ({ className, children }: PanelListProps) => {
  const panelRef = useRef<HTMLDivElement>(null);
  const innerState = useContext(TabsState);
  const [activeIndex] = innerState;
  const previousIndex = usePrevious(activeIndex);
  const goingBack = previousIndex && previousIndex > activeIndex;
  const animation = goingBack ? backwardAnimation : forwardAnimation;
  const activeChild = children[activeIndex];

  return (
    <motion.div className={cx(styles.panelList, className)}>
      <AnimatePresence initial={false} exitBeforeEnter>
        <motion.div
          ref={panelRef}
          className={styles.panel}
          key={activeIndex}
          {...animation}
        >
          {cloneElement(activeChild, { active: true })}
        </motion.div>
      </AnimatePresence>
    </motion.div>
  );
};
