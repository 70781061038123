import React from "react";
import Container from "components/common/layouts/Container";
import Button from "components/common/ui/button/Button";
import { coreService } from "services/CoreService";
import { PEERGROUP_ROUTES } from "constants/routes/peergroup";
import PeerGroupMeetingsList from "components/peergroup/meetings/PeerGroupMeetingsList";
import { usePeerGroupMeetings } from "components/peergroup/hooks/usePeerGroupMeetings";
import { useParams } from "react-router";
import { ROUTE_PARAMS } from "constants/routes/common";
import MiniLoader from "components/common/ui/loader/MiniLoader";
import FormActions from "components/common/ui/form-elements/form-layout/FormActions";
import Spacer from "components/common/ui/spacer/Spacer";

interface Props {
  canEditPeerGroup?: boolean;
}

const MeetingTab = ({ canEditPeerGroup }: Props) => {
  const { peergroupId } = useParams<{
    [ROUTE_PARAMS.PEERGROUP]: string;
    [ROUTE_PARAMS.MEETING]: string;
  }>();
  const { isLoading, data } = usePeerGroupMeetings(peergroupId);

  return (
    <Container>
      <Spacer height={10} />
      {canEditPeerGroup && (
        <FormActions>
          <Button
            theme="primary"
            to={`${coreService.getRoute(
              PEERGROUP_ROUTES.CREATE_PEERGROUP_MEETING,
              {
                peergroupId: peergroupId,
              }
            )}`}
            rounded
          >
            Create Meeting
          </Button>
        </FormActions>
      )}
      {isLoading ? (
        <MiniLoader theme="secondary" />
      ) : (
        <PeerGroupMeetingsList meetings={data?.meetings || []} />
      )}
    </Container>
  );
};

export default MeetingTab;
