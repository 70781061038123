import React from "react";
import Chip from "components/common/ui/chip/Chip";
import {
  IconDanger,
  IconError,
  IconSuccess,
  IconWarning,
} from "components/common/icons/twoToneIcons";
import { OVERSIGHT_SEVERITY_OPTIONS } from "models/user/attributes/office";
import styles from "components/users/common/detail/UserHeader.module.scss";

export const OversightLevelChip = ({
  oversight: oversightVal,
  showLabel = true,
  condensed = true,
}: {
  oversight?: string;
  showLabel?: boolean;
  condensed?: boolean;
}) => {
  const oversight = OVERSIGHT_SEVERITY_OPTIONS.find(
    (t) => t.value === oversightVal
  );
  if (oversight) {
    let icon = <></>;
    let theme = undefined;
    switch (oversight.value) {
      case "yellow":
        icon = <IconWarning className={styles.yellow} />;
        theme = "warning";
        break;
      case "orange":
        icon = <IconError className={styles.warning} />;
        theme = "warning";
        break;
      case "red":
        icon = <IconDanger className={styles.danger} />;
        theme = "danger";
        break;
      case "green":
        icon = <IconSuccess className={styles.success} />;
        theme = "success";
        break;
      case "purple":
        icon = <IconWarning className={styles.primary} />;
        theme = "primary";
        break;
      case "blue":
        icon = <IconWarning className={styles.info} />;
        theme = "info";
        break;
    }
    return (
      <Chip
        {...defaultChipProps}
        icon={icon}
        theme={theme as ThemeVariants}
        condensed={condensed}
      >
        {showLabel && (
          <>
            <i>Monitor status: </i>&nbsp;
          </>
        )}
        &nbsp;{oversight.label}
      </Chip>
    );
  }
  return null;
};

const defaultChipProps = {
  variant: "lightFilled",
  theme: "primary",
  iconVariant: "empty",
} as {
  variant: FillVariants;
  theme: ThemeVariants;
};
