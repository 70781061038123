import * as React from "react";
import { PRIVATE_ROUTE_DETAILS as ROUTES, TRoute } from "constants/routes";
import { CPD_ROUTES } from "constants/routes/cpd";
import { USER_ROUTES } from "constants/routes/user";
import { FACILITY_ROUTES } from "constants/routes/facility";
import IconHome from "components/common/icons/IconHome";
import IconSettings from "components/common/icons/IconSettings";
import IconUser from "components/common/icons/IconUser";
import IconNotebook from "components/common/icons/IconNotebook";
import IconLineChart from "components/common/icons/IconLineChart";
import IconSchool from "components/common/icons/IconSchool";
import IconFacility from "components/common/icons/IconFacility";
import { PEERGROUP_ROUTES } from "constants/routes/peergroup";
import IconGroup from "components/common/icons/IconGroup";
import { SUPERVISORS_ROUTES } from "constants/routes/supervisors";
import IconSupervisor from "components/common/icons/IconSupervisor";

export interface TSidebarNavItem {
  name: string;
  icon: any;
  // to?: string;
  href?: string;
  target?: string;
  // requiredRole?: string | string[];
  route?: TRoute;
}

export interface TSidebarNavSection {
  sectionName?: string;
  items: TSidebarNavItem[];
}

export type TSidebarNav = TSidebarNavSection[];

export const sidebarNav: TSidebarNav = [
  {
    items: [
      {
        name: "Home",
        icon: <IconHome />,
        route: ROUTES.HOME,
      },
    ],
  },
  {
    sectionName: "UCCIS",
    items: [
      {
        name: "My Profile",
        icon: <IconUser />,
        route: USER_ROUTES.MY_PROFILE,
      },
    ],
  },
  {
    sectionName: "CPD",
    items: [
      {
        name: "CPD",
        icon: <IconNotebook />,
        route: CPD_ROUTES.MY_PLANS,
      },
      {
        name: "Training Programme",
        icon: <IconNotebook />,
        route: CPD_ROUTES.MY_TPS,
      },

      // {
      //   name: "My Potential Supervisors",
      //   icon: <IconSupervisor />,
      //   route: SUPERVISORS_ROUTES.MY_POTENTIAL_SUPERVISORS,
      // },
      {
        name: "Supervision",
        icon: <IconSupervisor />,
        route: SUPERVISORS_ROUTES.MY_SUPERVISEES,
      },
      {
        name: "Peer groups",
        icon: <IconGroup />,
        route: PEERGROUP_ROUTES.USER_PEERGROUPS,
      },
      {
        name: "Learning Resources",
        icon: <IconSchool />,
        route: ROUTES.LEARNING_RESOURCES,
      },
    ],
  },
  {
    sectionName: "Admin",
    items: [
      {
        name: "Users",
        icon: <IconSettings />,
        route: USER_ROUTES.ADMIN_USERS,
      },
      {
        name: "Facilities",
        icon: <IconFacility />,
        route: FACILITY_ROUTES.ADMIN_FACILITIES,
      },
      {
        name: "Admin Peer groups",
        icon: <IconGroup />,
        route: PEERGROUP_ROUTES.ADMIN_PEERGROUPS,
      },

      // {
      //   name: "Admin CPD",
      //   icon: <IconNotebook />,
      //   route: ROUTES.ADMIN_PLANS_LIST,
      // },

      {
        name: "Admin Reports",
        icon: <IconLineChart />,
        route: ROUTES.ADMIN_REPORTS,
      },
    ],
  },
];
