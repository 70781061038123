import { TBucket } from "models/Dimension";
import { request } from "utils/http/Request";
import {
  MY_SUPERVISEES,
  getApiUrl,
  SUPERVISEE_DETAILS,
  SUPERVISEE_PLAN_DETAIL,
  SUPERVISOR,
} from "constants/paths";
import { Supervisee } from "models/supervisors/Supervisee";
import { formatPlan, Plan } from "models/cpd/Plan";
import { IServerActivity } from "models/cpd/Activity";
import { formattedActivities } from "components/cpd/utils/utils";

export const fetchManySupervisees = async (
  filter: Record<string, TBucket>,
  supervisorId: string
): Promise<Supervisee> => {
  const data = await request.get(
    getApiUrl(MY_SUPERVISEES, { supervisorId }),
    filter
  );
  return data.supervisees;
};

export const fetchSuperviseeDetails = async (
  supervisorId: string,
  superviseeId: string
) => {
  const data = await request.get(
    getApiUrl(SUPERVISEE_DETAILS, { supervisorId, superviseeId })
  );

  const activePlan = ((data.supervisee.plans.map(formatPlan) as Plan[])?.filter(
    (p) => p.plan_status === "active"
  ) ?? [])[0];

  return {
    ...data.supervisee,
    plan: activePlan,
    activities: data.supervisee.activities
      ? formattedActivities(
          data.supervisee.activities.filter(
            (a: IServerActivity) => a.plan_id === activePlan.plan_id
          ),
          activePlan.progress?.years
        )
      : [],
    goals: data.supervisee.goals ?? [],
  };
};

export const fetchOneSuperviseePlan = async (
  supervisorId: string,
  superviseeId: string,
  planId: string
) => {
  const data = await request.get(
    getApiUrl(SUPERVISEE_PLAN_DETAIL, { supervisorId, superviseeId, planId })
  );
  const plan = formatPlan(data.supervisee.plans[0]);
  // const formatted = data.supervisee.plans.map(formatPlan) as Plan[];
  return {
    plan,
    activities: formattedActivities(
      data.supervisee.activities,
      plan.progress?.years
    ),
    goals: data.supervisee.goals,
  };
};

export const updateSupervisor = async (
  id: string,
  supervisor_id: string,
  supervisor_status: string
): Promise<{}> =>
  await request.put(getApiUrl(SUPERVISOR, {}), {
    id,
    supervisor_id,
    supervisor_status,
  });
