import { TUser, USER_STATUS } from "models/user/User";
import { useMutateResendInvitation } from "components/users/hooks/useMutateResendInvitationUser";
import Chip from "components/common/ui/chip/Chip";
import styles from "components/users/common/User.module.scss";
import Tooltip from "components/common/ui/tooltip/Tooltip";
import ActionButton from "components/common/ui/button/ActionButton";
import IconResendEmail from "components/common/icons/IconResendEmail";
import IconResetLock from "components/common/icons/IconResetLock";
import React from "react";
import { useMutateReset } from "components/users/hooks/useMutateResetUserPassword";

interface StatusActionProps {
  user: TUser;
  canEdit: boolean;
  actionOnly?: boolean;
}

export const StatusAction = ({
  user,
  canEdit,
  actionOnly,
}: StatusActionProps) => {
  const resendInviteMutation = useMutateResendInvitation();
  const resetPasswordMutation = useMutateReset();

  const triggerResendInvite = (user: Partial<TUser>) => {
    // console.log("triggerResendInvite", user);
    resendInviteMutation.mutate({
      user,
    });
  };

  const resetPassword = async (user: Partial<TUser>) => {
    await resetPasswordMutation.mutate({
      user,
    });
  };
  if (!user.active) {
    return (
      <Chip theme="danger" className={styles.status} variant="outline">
        <span>System Status: </span> Suspended
      </Chip>
    );
  }

  if (!user.status) {
    return null;
  }
  const content = (
    <>
      <span>System Status: </span>
      {USER_STATUS[user.status]}
    </>
  );
  switch (user.status) {
    case "FORCE_CHANGE_PASSWORD":
      return (
        <>
          {!actionOnly && (
            <Chip theme="warning" className={styles.status} variant="outline">
              {content}
            </Chip>
          )}

          {canEdit && (
            <Tooltip content={"Resend Invite Email"} offset={8}>
              <ActionButton
                icon={<IconResendEmail />}
                theme="warning"
                variant="lightFilled"
                rounded
                // onClick={() => userService.resendInvitation(user)}
                onClick={(e) => {
                  e.preventDefault();
                  triggerResendInvite(user);
                }}
              />
            </Tooltip>
          )}
        </>
      );
    case "RESET_REQUIRED":
    case "CONFIRMED":
      return (
        <>
          {!actionOnly && (
            <Chip
              theme={user.status === "CONFIRMED" ? "info" : "danger"}
              className={styles.status}
              variant="outline"
            >
              {content}
            </Chip>
          )}
          {canEdit && (
            <Tooltip content={"Send Password Reset"} offset={8}>
              <ActionButton
                icon={<IconResetLock />}
                theme="info"
                variant="lightFilled"
                rounded
                // onClick={() => userService.resetUserPassword(user)}
                onClick={() => resetPassword(user)}
              />
            </Tooltip>
          )}
        </>
      );
    default:
      return (
        <Chip theme="info" className={styles.status} variant="outline">
          {content}
        </Chip>
      );
  }
};
