import React from "react";
import { useController } from "react-hook-form";
import {
  FormQuestionProps,
  TError,
} from "components/common/ui/form-elements/formElements";
import RichTextEditor from "components/common/ui/form-elements/rich-text-editor/RichTextEditor";

interface RteHookForm extends Omit<FormQuestionProps, "errors"> {
  error?: TError;
}

export const RichTextEditorHookForm = ({
  control,
  register,
  defaultValue,
  question,
  ...props
}: RteHookForm) => {
  const {
    field: { onChange, value },
    // fieldState: { invalid, isTouched, isDirty },
    // formState: { touchedFields, dirtyFields },
  } = useController({
    name: question.name,
    control: control,
    rules: { required: true },
    defaultValue: defaultValue || "",
  });

  return (
    <RichTextEditor
      {...props}
      {...question}
      label={question.label ?? question.name}
      value={value}
      setValue={onChange}
    />
  );
};
