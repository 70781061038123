import React from "react";
import { UseSelectStateChange } from "downshift";
import { TError } from "components/common/ui/form-elements/formElements";
import ResizeObserver from "resize-observer-polyfill";
import { PlacementType } from "react-laag/dist/PlacementType";
import { isObject } from "utils/common";

export interface SelectProps<Item> extends DefaultProps {
  name: string;
  label?: string;
  description?: string;
  disabled?: boolean;
  placeholder?: string;

  items: Item[];
  selected?: Item;
  onSelect: (changes: UseSelectStateChange<Item>) => void;
  error?: TError;

  children?: (provided: { items: Item[] }) => React.ReactNode;
  // children?: React.ReactNode;
  className?: string;
  /** The direction of the tooltip relative to the element (will move if there isn't enough space) */
  direction?:
    | "top-center"
    | "top-start"
    | "top-end"
    | "left-start"
    | "left-center"
    | "left-end"
    | "right-start"
    | "right-center"
    | "right-end"
    | "bottom-start"
    | "bottom-center"
    | "bottom-end"
    | "center";

  /** Should the arrow show */
  arrow?: boolean;

  /** How much should the tooltip be offset from the element */
  offset?: number;

  /** Override hover trigger and force open */
  // open?: boolean;

  /** Framer motion animation object */
  animation?: {
    initial: object;
    animate: object;
    exit: object;
    transition: object;
  };
}

export const itemToString = (d: any) => (isObject(d) ? d?.value : d);

export const defaultLayerProps = {
  overflowContainer: true, // we (don't?) want the menu to stay within its scroll-container
  auto: true, // auto find a placement when required
  snap: true, // snap to the possible placements (not in between)
  placement: "bottom-center", // we prefer placement on the bottom-side
  possiblePlacements: ["top-start", "bottom-start"] as PlacementType[], // stick with bottom and top
  triggerOffset: 0, // place the menu directly to the trigger
  containerOffset: 8, // make sure the menu gets a bit of space with respect to the containers edges
  ResizeObserver,
  // onOutsideClick: toggle,
  // onDisappear: toggle,
};
