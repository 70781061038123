import { ADMIN_ROOT, ROOT, ROUTE_PARAMS } from "constants/routes/common";
import { PermissionGroups } from "models/user/Permissions";

// const MY_PLANS = {
//   path: `${ROOT}/cpd`,
//   requiredRole: PermissionGroups.clinicianMember,
// };

const MY_PLANS = {
  path: `${ROOT}/cpd`,
  requiredRole: PermissionGroups.exceptRegistrar,
};
const MY_TPS = {
  path: `${ROOT}/training-programme`,
  requiredRole: PermissionGroups.registrar,
};
const MY_PLANS_ALL = {
  path: `${MY_PLANS.path}/all`,
  requiredRole: MY_PLANS.requiredRole,
};
const MY_TPS_ALL = {
  path: `${MY_TPS.path}/all`,
  // requiredRole: MY_PLANS.requiredRole,
  requiredRole: MY_TPS.requiredRole,
};
const MY_PLAN_DETAIL = {
  path: `${MY_PLANS.path}/plans/:${ROUTE_PARAMS.PLAN}`,
  requiredRole: MY_PLANS.requiredRole,
};
const MY_TPS_DETAIL = {
  path: `${MY_TPS.path}/plans/:${ROUTE_PARAMS.PLAN}`,
  requiredRole: MY_TPS.requiredRole,
};

const MY_GOALS = {
  path: `${MY_PLANS.path}/goals`,
  requiredRole: MY_PLANS.requiredRole,
};
const MY_GOALS_TPS = {
  path: `${MY_TPS.path}/goals`,
  requiredRole: MY_TPS.requiredRole,
};

const ADMIN_PLANS_LIST = {
  path: `${ADMIN_ROOT}/cpd`,
  // ADMIN READ
  requiredRole: PermissionGroups.adminRead,
};

const ADMIN_PLANS_LIST_TPS = {
  path: `${ADMIN_ROOT}/training-programme`,
  // ADMIN READ
  requiredRole: PermissionGroups.adminRead,
};

const ADMIN_USER_PLANS = {
  path: `${ADMIN_PLANS_LIST.path}/:${ROUTE_PARAMS.USER}/plans`,
  requiredRole: ADMIN_PLANS_LIST.requiredRole,
};

const ADMIN_USER_TPS = {
  path: `${ADMIN_PLANS_LIST_TPS.path}/:${ROUTE_PARAMS.USER}/plans`,
  requiredRole: ADMIN_PLANS_LIST.requiredRole,
};

const ADMIN_CREATE_PLAN = {
  path: `${ADMIN_USER_PLANS.path}/create`,
  // ADMIN EDIT
  requiredRole: PermissionGroups.adminEdit,
};
const ADMIN_CREATE_PLAN_TPS = {
  path: `${ADMIN_USER_TPS.path}/create`,
  // ADMIN EDIT
  requiredRole: PermissionGroups.adminEdit,
};

const ADMIN_PLAN_DETAIL = {
  path: `${ADMIN_USER_PLANS.path}/detail/:${ROUTE_PARAMS.PLAN}`,
  requiredRole: ADMIN_PLANS_LIST.requiredRole,
};
const ADMIN_PLAN_DETAIL_TPS = {
  path: `${ADMIN_USER_TPS.path}/detail/:${ROUTE_PARAMS.PLAN}`,
  requiredRole: ADMIN_PLANS_LIST.requiredRole,
};

const ADMIN_EDIT_PLAN = {
  path: `${ADMIN_PLAN_DETAIL.path}/edit`,
  requiredRole: ADMIN_CREATE_PLAN.requiredRole,
};

const ADMIN_EDIT_PLAN_TPS = {
  path: `${ADMIN_PLAN_DETAIL_TPS.path}/edit`,
  requiredRole: ADMIN_CREATE_PLAN_TPS.requiredRole,
};

const ADMIN_USER_GOALS = {
  path: `${ADMIN_PLANS_LIST.path}/:${ROUTE_PARAMS.USER}/goals`,
  requiredRole: ADMIN_PLANS_LIST.requiredRole,
};

export const CPD_ROUTES = {
  MY_PLANS,
  MY_TPS,
  MY_PLAN_DETAIL,
  MY_TPS_DETAIL,
  MY_PLANS_ALL,
  MY_TPS_ALL,
  MY_GOALS,
  MY_GOALS_TPS,

  ADMIN_PLANS_LIST,
  ADMIN_USER_PLANS,
  ADMIN_USER_TPS,
  ADMIN_PLAN_DETAIL,
  ADMIN_PLAN_DETAIL_TPS,
  ADMIN_CREATE_PLAN,
  ADMIN_CREATE_PLAN_TPS,
  ADMIN_EDIT_PLAN,
  ADMIN_EDIT_PLAN_TPS,

  ADMIN_USER_GOALS,
};
