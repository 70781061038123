import React, { useEffect, useState } from "react";
import { useFieldArray } from "react-hook-form";
import { Control } from "react-hook-form/dist/types/form";
import cx from "classnames";
import styles from "components/common/ui/form-elements/field-array/FieldArray.module.scss";
import { IMenuItem } from "components/common/ui/form-elements/autocomplete/MenuItem";
import { UseMultipleSelectionStateChange } from "downshift";
import MultiSelectCombobox from "components/common/ui/form-elements/autocomplete/MulitSelectCombobox";
import { PeerGroupMember } from "models/peergroup/member";
import { PeerGroupMeetingAttendees } from "models/peergroup/meeting";
import { usePeerGroupMembers } from "components/peergroup/hooks/usePeerGroupMembers";
import { SearchFunction } from "components/common/ui/form-elements/autocomplete/utils";
import { fuzzyMatch } from "utils/dimensions/matchUtils";

type AttendeeMenuItem = IMenuItem<string, PeerGroupMeetingAttendees>;
type MemberMenuItem = IMenuItem<
  string,
  // SetRequired<PeerGroupMeetingAttendees, "user_id">
  PeerGroupMember
>;

interface Props extends DefaultProps {
  peergroupId: string;
  // peergroup_users: PeerGroupMember[];
  defaultValue?: PeerGroupMember[];
  // peergroup_users: PeerGroupMeetingAttendees[];
  // defaultValue?: PeerGroupMeetingAttendees[];
  control: Control;
  errors: Record<string, any>;
  disabled?: boolean;
  attendees?: PeerGroupMember[];
}

const PeerGroupMeetingAttendeesBox = ({
  peergroupId,
  className,
  errors,
  children,
  control,
  attendees,
  ...props
}: Props) => {
  const { data: members, isLoading } = usePeerGroupMembers(peergroupId);
  const formattedMemberItems = formatUsersToMenuOption(members?.accepted || []);
  const [memberState, setMemberState] =
    useState<MemberMenuItem[]>(formattedMemberItems);

  const { fields, replace }: { fields: any[]; replace: (u: any[]) => void } =
    useFieldArray({
      control: control,
      name: "peergroup_meeting_attendees", // unique name for your Field Array
    });

  useEffect(() => {
    if (members?.accepted) {
      setMemberState(formatUsersToMenuOption(members?.accepted));
    }
  }, [members]);

  const onSearch: SearchFunction<MemberMenuItem> = (inputValue) => {
    setMemberState(
      inputValue
        ? memberState.filter((m) => fuzzyMatch(inputValue, m.label ?? ""))
        : formattedMemberItems
    );
  };

  const onSelect = (
    changes: UseMultipleSelectionStateChange<AttendeeMenuItem>
  ) => {
    replace(changes.selectedItems?.map((u) => u.metadata) || []);
  };

  return (
    <div className={cx(styles.root, className)}>
      <MultiSelectCombobox
        items={memberState}
        selected={formatUsersToMenuOption(fields)}
        // selected={formatUsersToMenuOption(attendees)}
        onSearch={onSearch}
        onSelect={onSelect}
        name={"users"}
        label={"Members attending"}
        disabled={isLoading}
        // description={question.description}
        {...props}
      />
    </div>
  );
};

// const formatUsersToMenuOption = (
//   peergroup_users: PeerGroupMember[] = []
// ): MemberMenuItem[] =>
//   peergroup_users?.map((u) => ({
//     value: u.user_id || "",
//     label: u?.given_name || "It shows this because the user name is null",
//     metadata: u,
//   }));

const formatUsersToMenuOption = (
  peergroup_attendees: (PeerGroupMember | PeerGroupMeetingAttendees)[] = []
): AttendeeMenuItem[] =>
  peergroup_attendees.map((u) => {
    const attendee: PeerGroupMeetingAttendees =
      "given_name" in u
        ? {
            user_id: u.user_id,
            given_name: u.given_name,
            family_name: u.family_name,
            email: u.email,
          }
        : u;
    return {
      value: attendee.user_id || "",
      label: `${attendee.given_name} ${attendee.family_name} ${
        attendee.email ? ` (${attendee.email})` : ""
      }`,
      metadata: attendee,
    };
  });

export default PeerGroupMeetingAttendeesBox;
