import { useQuery } from "react-query";
import { fetchMany } from "api/FacilityApi";
import { facilityQueryKeys } from "queries/constants";
import { extractFiltersFromDimensions } from "components/users/utils/userUtils";
import { IFacilityListDimensions } from "models/facility/Facility";
import { QueryOptions } from "queries/queries";
import { useToast } from "components/common/ui/toast/useToast";

export const useFacilityList = (
  dimensions: IFacilityListDimensions,
  options?: QueryOptions
) => {
  const { addErrorToast } = useToast();
  const dim = extractFiltersFromDimensions(dimensions);
  return useQuery(
    facilityQueryKeys.list(Object.keys(dim).length > 0 ? dim : undefined),
    () => fetchMany(dim),
    {
      onError: (e) =>
        addErrorToast(e, "Unable to load facilities", {
          id: facilityQueryKeys.list().join("::"),
        }),
      // staleTime: 60 * 1000,
      ...options,
    }
  );
};
