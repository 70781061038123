import React, { useEffect, useState } from "react";
import { TUser } from "models/user/User";
import { IconDanger } from "components/common/icons/twoToneIcons";
import Container from "components/common/layouts/Container";

import { PERMISSIONS } from "models/user/Permissions";
import {
  Panel,
  PanelList,
  Tab,
  TabList,
  Tabs,
} from "components/common/ui/tabs/Tabs";
import UserHeader from "components/users/common/detail/UserHeader";
import styles from "./UserDetail.module.scss";
import FormattedQuestion from "components/common/ui/form-elements/display-elements/FormattedQuestion";
import { userAttributesMap } from "models/user/attributes";
import { userPages } from "models/user/UserForm";
import { checkConditionals } from "utils/formUtils";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router";
import { PageTab, getTabs } from "components/users/utils/userUtils";
import PlanIllustration from "components/common/images/PlanIllustration";
import HomeCard from "components/common/ui/card/HomeCard";
import { CPD_ROUTES } from "constants/routes/cpd";
import { coreService } from "services/CoreService";
import { ROUTE_PARAMS } from "constants/routes/common";
import { isRegistrar } from "components/users/utils/userTypeUtils";
import FormattedSupervisor from "components/supervisors/detail/FormattedSupervisor";
import Chip from "components/common/ui/chip/Chip";

interface Props {
  user: TUser;
  canChangeUserAttributes: boolean;
  canChangePermissions: boolean;
  onUpdateAttributes: (attributes: Partial<TUser>) => void;
  onUpdatePermission: (permissions: PERMISSIONS[]) => void;
  editLink: string;
  isSubmitting?: boolean;
  isAdmin?: boolean;
}

const initialState = {
  index: 0,
  ...userPages[0],
};

const UserDetail = ({ user, editLink, isAdmin }: Props) => {
  const [tabState, setTabs] = useState<PageTab>(initialState);
  const history = useHistory();
  const { hash, pathname } = useLocation();
  const isReg = isRegistrar(user);
  const tabs = getTabs(user);
  const cpdRoute = isAdmin
    ? isReg
      ? {
          ...CPD_ROUTES.ADMIN_USER_TPS,
          path: `${coreService.getRoute(CPD_ROUTES.ADMIN_USER_TPS, {
            [ROUTE_PARAMS.USER]: user.id,
          })}`,
        }
      : {
          ...CPD_ROUTES.ADMIN_USER_PLANS,
          path: `${coreService.getRoute(CPD_ROUTES.ADMIN_USER_PLANS, {
            [ROUTE_PARAMS.USER]: user.id,
          })}`,
        }
    : isReg
    ? CPD_ROUTES.MY_TPS
    : CPD_ROUTES.MY_PLANS;
  const panels = tabs.map((t) => (
    <Panel key={t.id}>
      <h3 className={styles.sectionTitle}>{t.title}</h3>
      {t.sections.map((s, i) =>
        checkConditionals(user, s.conditional) ? (
          <div key={i}>
            <h4 className={styles.subSectionTitle}>{s.title || " "}</h4>
            {s.questions.map((q) => (
              <Question key={q} name={q} questions={user} />
            ))}
          </div>
        ) : null
      )}
      {hash === "#cpd" &&
        user.peergroups_coordinating &&
        user.peergroups_coordinating?.length > 0 && (
          <FormattedQuestion
            def={{
              name: "peergroups_coordinating",
              type: "string",
              label: "Peergroups coordinating",
            }}
            questions={{
              peergroups_coordinating: user.peergroups_coordinating.map((p) => (
                <Chip style={{ marginRight: "10px" }}>{p.peergroup_name} </Chip>
              )),
            }}
            loading={false}
          />
        )}

      {hash === "#cpd" && (isRegistrar(user) || !!user.supervisor?.email) && (
        <FormattedSupervisor supervisor={user.supervisor} />
      )}

      {hash === "#cpd" && (
        <HomeCard
          className={styles.card}
          title={
            <div className={styles.cardTitle}>
              {isReg
                ? "Training Programme Summary"
                : "Continuing Professional Development"}
            </div>
          }
          route={cpdRoute}
          svg={PlanIllustration}
          col={4}
        />
      )}
    </Panel>
  ));
  const setHashState = async (newState: number) => {
    //always get the fresh version of currentIndex within setIndicatorState
    setTabs((s) => {
      if (newState !== s.index) {
        const newHash = tabs[newState]?.id;
        history.push(`${pathname}#${newHash}`);
      }
      return s;
    });
  };
  useEffect(() => {
    const slug = hash.replace("#", "");
    const newSection = tabs.find((t) => t.id === slug);
    if (newSection && tabState.id !== newSection.id) {
      setTabs(newSection);
    }
  }, [hash, history, tabs, tabState]);
  // panels.push(
  //   <Panel key="access">
  //     <div>Permissions</div>
  //   </Panel>
  // );

  // const date = new Date().toLocaleDateString();
  // const date = new Date();

  return (
    <div className={styles.root}>
      {!user.active && (
        <Container spacing="none" className={styles.deletedUser}>
          <h4>
            <IconDanger /> Suspended User
          </h4>
        </Container>
      )}
      <UserHeader user={user} isAdmin={isAdmin} editLink={editLink} />
      <Tabs state={[tabState.index, setHashState]}>
        <TabList decorator="none" tabBackground>
          {tabs.map((t) => (
            <Tab key={t.id}>
              <span className={styles.icon}>{t.icon}</span>
              {t.title}
            </Tab>
          ))}
          {/*<Tab>Access and Permissions</Tab>*/}
        </TabList>
        <div className={styles.content}>
          <Container>
            <br />
            <PanelList>{panels}</PanelList>
            <br />
          </Container>
        </div>
      </Tabs>
    </div>
  );
};

interface QProps {
  name: string;
  questions: TUser;
}

const Question = ({ name, questions }: QProps) => {
  const q = userAttributesMap[name];
  return q ? <FormattedQuestion def={q} questions={questions} /> : null;
};

export default UserDetail;
