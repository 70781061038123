import React from "react";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { store, history } from "store";
import Meta from "components/common/Meta";
import { ToastProvider } from "components/common/ui/toast/Toast";
import { ErrorBoundary } from "components/common/ErrorBoundary";
import Routes from "components/Routes";
import queryClient from "queries/queryClient";

const App: React.FC = () => (
  <Provider store={store}>
    <Meta />
    <ErrorBoundary>
      <ConnectedRouter history={history}>
        <ToastProvider>
          <QueryClientProvider client={queryClient}>
            <Routes />
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </ToastProvider>
      </ConnectedRouter>
    </ErrorBoundary>
  </Provider>
);

export default App;
