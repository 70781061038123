import React from "react";
import cx from "classnames";
import styles from "./CheckboxGroup.module.scss";
import InputFeedback, {
  TError,
} from "components/common/ui/form-elements/common/InputFeedback";
import Checkbox from "components/common/ui/form-elements/checkbox/Checkbox";
import { UseFormRegister } from "react-hook-form/dist/types/form";

interface IRadioGroupProps {
  name: string;
  label: string | React.ReactNode;
  description?: string;
  error?: TError;
  className?: string;
  options: { label?: string; value: string }[];
  register: UseFormRegister<any>;
  required?: boolean;
  disabled?: boolean;
  defaultValue?: string;
}

const CheckboxGroup = ({
  name,
  label,
  description,
  error,
  className,
  options,
  register,
  required,
  disabled,
  defaultValue,
}: IRadioGroupProps) => (
  <fieldset
    className={cx(
      styles.group,
      {
        [styles.error]: error,
        [styles.disabled]: disabled,
      },
      className
    )}
    role="group"
    aria-labelledby={`checkbox-group-legend-${name}`}
    aria-describedby={error && `error-${name}-${error?.type}`}
    // aria-invalid={!!error}

    // @ts-ignore
    required={required}
    disabled={required}
  >
    <legend className={styles.legend} id={`checkbox-group-legend-${name}`}>
      {label}
      {description && (
        <span>
          <br />
          {description}
        </span>
      )}
    </legend>
    {options.map((r) => (
      <Checkbox
        {...register(name)}
        key={r.value}
        name={name}
        value={r.value}
        label={r.label || r.value}
        disabled={disabled}
        defaultValue={defaultValue}
      />
    ))}
    <InputFeedback error={error} visible={!!error} name={name} />
  </fieldset>
);

export default CheckboxGroup;
