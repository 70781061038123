import { useEffect } from "react";
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import styles from "./RichTextEditor.module.scss";

interface props {
  text: string;
}
const DisplayRichText = ({ text }: props) => {
  const editor = useEditor({
    extensions: [StarterKit],
    content: text ?? "",
    editable: false,
  });

  useEffect(() => {
    if (editor && editor.getHTML() !== text) {
      editor.commands.setContent(text);
    }
  }, [text, editor]);

  return <EditorContent editor={editor} className={styles.editor} />;
};

export default DisplayRichText;
