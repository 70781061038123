import React from "react";
import styles from "./ToastErrorContent.module.scss";

interface Props {
  title?: string;
  content: string;
}
const ToastErrorContent = ({ title, content }: Props) =>
  title ? (
    <>
      <div className={styles.title}>{title}</div>
      <div className={styles.content}>{content}</div>
    </>
  ) : (
    <>{content}</>
  );

export default ToastErrorContent;
