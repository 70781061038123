import cx from "classnames";
import styles from "components/common/ui/form-elements/file-upload/FilePreview.module.scss";

import FileIcon from "components/common/ui/form-elements/file-upload/FileIcon";

import ActionButton from "components/common/ui/button/ActionButton";
import IconDelete from "components/common/icons/IconDelete";

import { motion } from "framer-motion";
import React, { useState } from "react";
import { fetchActivityUploadDoc } from "api/CpdApi";
import { IconDownload } from "components/common/icons/IconUpload";
import { useMutateDeleteActivityDoc } from "components/cpd/hooks/useMutateUploadActivityDoc";
import Alert from "components/common/ui/modal/Alert";
import Tooltip from "components/common/ui/tooltip/Tooltip";
import { fromS3SafeName } from "components/cpd/utils/fileUtils";

interface Props extends DefaultProps {
  file: string;
  activityId: string;
  userId: string;
  removeFile?: boolean;
}

const FileItem = ({ file, activityId, userId, removeFile }: Props) => {
  const mutate = useMutateDeleteActivityDoc();
  const [isDeleting, setIsDeleting] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const downloadFile = async () => {
    setSubmitting(true);
    await fetchActivityUploadDoc(activityId, userId, file);
    setSubmitting(false);
  };

  const deleteFile = async () => {
    try {
      setIsDeleting(false);
      setSubmitting(true);
      await mutate.mutateAsync({ activityId, userId, file });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <motion.div
        layout
        initial={{ opacity: 0, y: 10, height: 0, marginBottom: 0 }}
        animate={{ opacity: 1, y: 0, height: "auto", marginBottom: 12 }}
        exit={{ opacity: 0, height: 0, marginBottom: 0 }}
        className={cx(styles.root)}
      >
        <div className={styles.avatar}>
          <div className={styles.icon}>
            <FileIcon filename={file} />
          </div>
        </div>
        <div className={styles.content}>
          <p className={styles.path}>{fromS3SafeName(file)}</p>
        </div>
        <div style={{ display: "flex" }}>
          <Tooltip content={"Download File"}>
            <ActionButton
              icon={<IconDownload />}
              onClick={() => downloadFile()}
              theme="primary"
              variant="lightFilled"
              rounded
              aria-label={`download ${file}`}
              submitting={submitting}
            />
          </Tooltip>

          {removeFile && (
            <Tooltip content={"Delete File"}>
              <ActionButton
                icon={<IconDelete />}
                onClick={() => setIsDeleting(true)}
                theme="danger"
                rounded
                aria-label={`remove ${file}`}
                submitting={submitting}
              />
            </Tooltip>
          )}
        </div>
      </motion.div>
      <Alert
        open={isDeleting}
        onClose={() => setIsDeleting(false)}
        heading={"Are you sure you want to delete this file?"}
        onAgree={() => deleteFile()}
        agreeText="Delete File"
      >
        This is irreversible - once a file has been deleted it can't be
        recovered.
      </Alert>
    </>
  );
};

export default FileItem;
