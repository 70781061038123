import React, { useEffect, useState } from "react";
import cx from "classnames";
import ReactModal from "react-modal";
import { motion } from "framer-motion";
import styles from "./Modal.module.scss";
import IconClose from "components/common/icons/IconClose";
import Button from "components/common/ui/button/Button";

ReactModal.setAppElement("#root");

const overlayAnimation = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 0.6,
  },
};

const scaleInAnimation = {
  hidden: {
    opacity: 0,
    scale: 1.3,
  },
  visible: {
    opacity: 1,
    scale: 1,
  },
};

const slideUpAnimation = {
  hidden: {
    y: "100vh",
  },
  visible: {
    y: "0%",
    transition: {
      y: { type: "spring", stiffness: 180, damping: 18 },
    },
  },
};

const animationDef = {
  scale: scaleInAnimation,
  slideuUp: slideUpAnimation,
};
const transition = {
  type: "spring",
  delay: 0,
  stiffness: 500,
  damping: 60,
  mass: 1,
};

export interface IModalProps
  extends Omit<
    ReactModal.Props,
    | "isOpen"
    | "onRequestClose"
    | "overlayClassName"
    | "shouldCloseOnOverlayClick"
  > {
  children: any;
  disableOverlayClick?: boolean;
  animation?: "scale" | "slideuUp";
  className?: string;
  open: boolean;
  onClose: () => void;
  showCloseButton?: boolean;
}

const Modal = ({
  children,
  animation = "scale",
  className,
  open: controlledOpen,
  onClose,
  showCloseButton = true,
  disableOverlayClick,
  ...rest
}: IModalProps) => {
  const [visible, setVisible] = useState(controlledOpen);
  const [open, setOpen] = useState(controlledOpen);

  useEffect(() => {
    if (!controlledOpen) {
      setVisible(false);

      //Delaying actual close to let animation finish
      setTimeout(() => {
        setOpen(false);
      }, 200);
    } else {
      setOpen(true);
      setVisible(true);
    }
  }, [controlledOpen]);

  return (
    <ReactModal
      isOpen={open}
      onRequestClose={onClose}
      overlayClassName={styles.baseOverlay}
      className={styles.baseContent}
      shouldCloseOnOverlayClick={!disableOverlayClick}
      {...rest}
    >
      <motion.div
        variants={overlayAnimation}
        animate={visible ? "visible" : "hidden"}
        className={styles.overlay}
        transition={transition}
        initial="hidden"
      />

      <motion.div
        animate={visible ? "visible" : "hidden"}
        variants={animationDef[animation]}
        className={cx(styles.content, className)}
        transition={transition}
        initial="hidden"
      >
        {showCloseButton && (
          <Button
            aria-label="close modal"
            className={styles.close}
            onClick={onClose}
            variant="empty"
          >
            <IconClose className={styles.closeIcon} />
          </Button>
        )}

        {children}
      </motion.div>
    </ReactModal>
  );
};

export default Modal;
