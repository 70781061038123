import React from "react";
import { useForm } from "react-hook-form";
import FormSection from "components/common/ui/form-elements/form-layout/FormSection";
import { createFacility, Facility } from "models/facility/Facility";
import {
  facilityFormSchema,
  facilityPages,
} from "models/facility/FacilityForm";
import { facilityAttributesMap } from "models/facility/attributes";
import FormActions from "components/common/ui/form-elements/form-layout/FormActions";

interface Props {
  facility?: Facility;
  onSubmit: (attributes: Partial<Facility>) => void;
  onCancel: (attributes: Partial<Facility>) => void;
  title?: string;
  saveText?: string;
  isSubmitting?: boolean;
  isAdmin?: boolean;
  children?: React.ReactNode;
}

const FacilityEditForm = ({
  facility = createFacility(),
  onSubmit,
  onCancel,
  title = "Edit Facility Details",
  saveText = "Update Facility",
  isSubmitting,
  isAdmin,
}: Props) => {
  const {
    handleSubmit,
    formState: { errors },
    register,
    control,
  } = useForm({
    mode: "onSubmit",
    // defaultValues: { [userTypeKey]: user[userTypeKey] } as Record<string, any>,
    defaultValues: facility as Record<string, any>,
    resolver: facilityFormSchema,
  });
  const handleSave = async (attributes: Partial<Facility>) => {
    await onSubmit({ ...attributes, facility_id: facility?.facility_id });
  };

  return (
    <form onSubmit={handleSubmit(handleSave)} noValidate>
      {facilityPages.map((p, i) => (
        <FormSection
          key={i}
          {...p}
          hookForm={{
            register,
            control,
            errors,
          }}
          questionMap={facilityAttributesMap}
          isAdmin={isAdmin}
        />
      ))}
      <FormActions
        onCancel={onCancel}
        isSubmitting={isSubmitting}
        submitText={saveText}
      />
    </form>
  );
};

export default FacilityEditForm;
