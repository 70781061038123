import React from "react";
import { PLAN_STATUS } from "models/cpd/Plan";
import HintBox from "components/common/ui/hint-box/HintBox";
import Container from "components/common/layouts/Container";
import {
  IconDanger,
  IconError,
  IconSuccess,
  IconWarning,
} from "components/common/icons/twoToneIcons";

interface Props extends DefaultProps {
  status: PLAN_STATUS;
  isTPS: boolean;
}

const PlanStatus = ({ status, isTPS }: Props) => {
  if (status === PLAN_STATUS.active) {
    return null;
  }
  const pathwayLabel = isTPS ? "TPS" : "CPD plan";
  let children = <></>;
  switch (status) {
    case PLAN_STATUS.finished:
      children = (
        <HintBox theme="success" icon={<IconSuccess />}>
          <b>Closed</b>
          <br />
          This {pathwayLabel} is closed. No more changes are possible
        </HintBox>
      );
      break;
    case PLAN_STATUS.unfinished:
      children = (
        <HintBox theme="danger" icon={<IconDanger />}>
          <b>Closed</b>
          <br />
          This {pathwayLabel} is closed. No more changes are possible
        </HintBox>
      );
      break;
    case PLAN_STATUS.pause:
      children = (
        <HintBox theme="warning" icon={<IconError />}>
          <b>Paused</b>
          <br />
          This {pathwayLabel} is currently paused. It cannot be changed until is
          is reactivated.
        </HintBox>
      );
      break;
    case PLAN_STATUS.pending:
      children = (
        <HintBox theme="info" icon={<IconWarning />}>
          <b>Pending</b>
          <br />
          This {pathwayLabel} is currently pending & should be used for planning
          out the next period. You shouldn't be completing activity in this{" "}
          {pathwayLabel} yet.
        </HintBox>
      );
      break;
  }
  return <Container>{children}</Container>;
};

export default PlanStatus;
