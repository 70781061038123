import React from "react";
import Meta from "components/common/Meta";
import { AppContentContainer } from "components/common/layouts/appLayout/AppContentContainer";
import Container from "components/common/layouts/Container";
import { coreService } from "services/CoreService";
import { useHistory, useParams } from "react-router";
import { PEERGROUP_ROUTES } from "constants/routes/peergroup";
import {
  createPeerGroupMeeting as blankPeerGroupMeeting,
  PeerGroupMeeting,
} from "models/peergroup/meeting";
import { useMutatePeerGroupMeeting } from "components/peergroup/hooks/useMutatePeerGroupMeeting";
import PeerGroupMeetingEditForm from "components/peergroup/edit/PeerGroupMeetingEditForm";

const ViewCreatePeerGroupMeeting = () => {
  const { peergroupId } = useParams<{ peergroupId: string }>();
  const history = useHistory();
  const { isLoading: loadingMutate, mutateAsync } = useMutatePeerGroupMeeting();

  const onSave = async (newPeerGroupMeeting: Partial<PeerGroupMeeting>) => {
    await mutateAsync({
      newPeerGroupMeeting,
      oldPeerGroupMeeting: blankPeerGroupMeeting(),
      peergroupId,
    });
    onCancel();
  };

  const onCancel = () => {
    history.push(
      coreService.getRoute(PEERGROUP_ROUTES.PEERGROUP_DETAIL, {
        peergroupId: peergroupId,
      })
    );
  };

  return (
    <AppContentContainer
      spacing="none"
      backButton={{
        to: coreService.getRoute(PEERGROUP_ROUTES.PEERGROUP_DETAIL, {
          peergroupId: peergroupId,
        }),
        label: "Back to Peer Group Detail",
      }}
    >
      <Meta title="Create Peer Group Meeting" />
      <br />
      <br />
      <br />
      <Container>
        <h1>Create New Peer Group Meeting</h1>
        <PeerGroupMeetingEditForm
          peergroupId={peergroupId}
          onSubmit={onSave}
          onCancel={onCancel}
          saveText={"Create Peer Group Meeting"}
          isSubmitting={loadingMutate}
        />
      </Container>
    </AppContentContainer>
  );
};

export default ViewCreatePeerGroupMeeting;
