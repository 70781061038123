import React from "react";
import createIcon from "components/common/icons/common/createIcon";

const IconFacility = createIcon(
  <React.Fragment>
    <path
      fill="currentColor"
      d="M18,15H16V17H18M18,11H16V13H18M20,19H12V17H14V15H12V13H14V11H12V9H20M10,7H8V5H10M10,11H8V9H10M10,15H8V13H10M10,19H8V17H10M6,7H4V5H6M6,11H4V9H6M6,15H4V13H6M6,19H4V17H6M12,7V3H2V21H22V7H12Z"
    />
  </React.Fragment>,
  "Warning"
);

export default IconFacility;
