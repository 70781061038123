import React from "react";
import cx from "classnames";
import { motion } from "framer-motion";
import useConstant from "use-constant";
import styles from "components/common/ui/skeleton/Skeleton.module.scss";

export interface ISkeleton extends DefaultProps {
  swipe?: "straight" | "angled" | "none";
  variant?: "base" | "dark" | "light" | "inverse";
}

interface ISkeletonTextWrapper extends ISkeleton {
  skeletonClassName?: string;
}

interface TextProps extends ISkeletonTextWrapper {
  randomWidth?: boolean;
  lines?: number;
}

export const SkeletonText = ({
  randomWidth = false,
  className,
  style: parentStyle,
  lines = 1,
  ...rest
}: TextProps) => {
  const rWidth = useConstant<number>(() => 50 + Math.random() * 50);
  const style = randomWidth
    ? {
        width: `${rWidth}%`,
        ...parentStyle,
      }
    : parentStyle;
  return (
    <>
      {[...Array(lines)].map((e, i) => (
        <SkeletonTextWrapper
          key={i}
          {...rest}
          style={style}
          className={cx(styles.text, className)}
        />
      ))}
    </>
  );
};

export const SkeletonIcon = ({ className, ...rest }: ISkeletonTextWrapper) => (
  <SkeletonTextWrapper {...rest} className={cx(styles.icon, className)} />
);

const SkeletonTextWrapper = ({
  className,
  skeletonClassName,
  variant,
  swipe,
  children,
  ...rest
}: ISkeletonTextWrapper) => (
  <motion.div className={cx(styles.textWrap, className)} {...anim} {...rest}>
    &nbsp;{children}
    <SkeletonBase
      className={skeletonClassName}
      swipe={swipe}
      variant={variant}
    />
  </motion.div>
);

export const SkeletonBase = ({
  swipe = "straight",
  variant = "base",
  className,
  children,
  ...rest
}: ISkeleton) => (
  <div
    className={cx(styles.skeleton, styles[variant], styles[swipe], className)}
    {...rest}
  >
    {children}
  </div>
);

const anim = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: { duration: 0.3, type: "easeOut" },
  },
  exit: {
    opacity: 0,
    transition: { duration: 0.2, ease: "easeIn" },
  },
};
