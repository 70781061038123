import { IDimension, IDimensions } from "models/Dimension";
import { removeUndefinedFromObject } from "utils/common";
import { facilityTypeOptions } from "models/facility/attributes";

const facilityType = facilityTypeOptions.map((t) => t.value);
export type TFacilityType = typeof facilityType[number] | undefined;

export interface FacilityUser {
  useraccount_id: string;
  link_created_at: string;
}

const blankFacility: Facility = {
  facility_id: "",
  facility_name: "",
  facility_alias: "",
  hpi_facility_id: undefined,
  hpi_organisation_id: undefined,
  facility_type: "",
  services_provided: "",
  facility_status: "",
  ucc_workplace: "",
  approved_training: "",
  review_date: undefined,
  users: [],
};

// export const create = (data: Partial<Facility> = {}): Facility => {
//   //strip out undefined attributes to they can be replaced with defaults from initialData
//   const cleanedData = removeUndefinedFromObject(data);
//   const search_field = [
//   ].join("::");
//   return {
//     ...blankFacility,
//     ...cleanedData,
//     // search_field,
//   };
// };

export interface Facility {
  facility_id: string;
  facility_name?: string;
  facility_alias?: string;
  hpi_facility_id?: string;
  hpi_organisation_id?: string;
  facility_type?: TFacilityType;
  services_provided?: string;
  facility_status?: string;
  approved_training?: string;
  review_date?: string;
  ucc_workplace?: string;
  users?: Array<FacilityUser>;
}

export interface IServerFacility
  extends Omit<
    Facility,
    "facility_id" | "approved_training" | "ucc_workplace"
  > {
  id: string;
  approved_training?: number;
  ucc_workplace?: number;
}

export const formatFacility = ({
  id,
  approved_training,
  ucc_workplace,
  ...facility
}: IServerFacility) => {
  return createFacility({
    facility_id: id,
    ...facility,
    approved_training: approved_training === 1 ? "true" : "false",
    ucc_workplace: ucc_workplace === 1 ? "true" : "false",
  });
};

export const createFacility = (data: Partial<Facility> = {}): Facility => {
  const cleanedData = removeUndefinedFromObject(data);
  return {
    ...blankFacility,
    ...cleanedData,
  };
};

export const toServerObject = ({
  facility_id,
  approved_training,
  ucc_workplace,
  users,
  ...rest
}: Partial<Facility>): Partial<IServerFacility> => {
  return {
    id: facility_id,
    approved_training: approved_training === "true" ? 1 : 0,
    ucc_workplace: ucc_workplace === "true" ? 1 : 0,
    ...rest,
  };
};

export interface IFacilityListDimensions extends IDimensions {}

const facilityListDimArray = [] as IDimension[];

export const facilityListDimensions = facilityListDimArray.reduce(
  (s, d) => ({ ...s, [d.key]: d }),
  {}
) as IFacilityListDimensions;
