import React, { useContext, useEffect } from "react";
import { TabElements, TabsState } from "components/common/ui/tabs/Tabs";
import styles from "components/cpd/activity/edit/ActivityEditForm.module.scss";
import StepProgress from "components/common/ui/form-elements/step-progress/StepProgress";
import Container from "components/common/layouts/Container";

export const ActivityEditProgressStepper = ({ stops }: { stops: string[] }) => {
  const [activeIndex] = useContext(TabsState);
  const elements = useContext(TabElements);

  // Hacky way of setting # of stops for use in ActivityFormActions
  // Because we're using headless Tabs (no actual Tab elements), this is safe to do
  useEffect(() => {
    elements.tabs = stops.length;
  }, [stops.length, elements]);

  return (
    <Container>
      <StepProgress
        className={styles.stepProgress}
        stops={stops.length}
        labels={stops}
        current={activeIndex}
      />
    </Container>
  );
};
