import React from "react";

interface Props {
  className?: string;
}

const Kraken = () => (
  <>
    <KrakenTentacle />
    <KrakenBackground />
  </>
);

export const KrakenTentacle = ({ className }: Props) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 726 726"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M537.406 505.362C514.527 542.709 472.459 641.132 463.5 684L407 689.5C414.369 669.505 409.718 584.107 426.086 555.364C476.082 467.878 542.44 391.742 417.464 304.256C416.966 303.884 397.744 302.345 414.541 266.852C412.664 265.599 404.02 263.94 402.143 262.687C355.774 232.068 322.632 194.366 267.264 191.74C210.025 188.863 276.429 214.67 224.562 238.047C217.937 241.044 109.746 230.146 78.3378 224.162C73.5893 221.032 68.961 217.783 64.4642 214.161C49.6525 203.772 37.0376 190.568 27.3461 175.292C21.8482 164.92 24.9719 159.92 36.843 162.792C41.3856 164.056 45.7908 165.773 49.99 167.918C86.7363 184.915 125.977 175.67 161.224 159.92C211.947 135.331 267.847 123.323 324.194 124.924C379.82 127.51 433.735 144.988 480.292 175.544L485.916 179.292L487.415 180.419C612.391 267.899 587.396 417.876 537.406 505.362Z"
      fill="#D75A88"
    />
    <path
      d="M468.5 567C453.5 604 436.1 661.093 426.5 686.5L111.5 695.5L137.5 611.5C151 576.5 162.662 555 173 540C180.926 528.5 188.892 519.355 198.5 510C217.5 491.5 229.111 480.304 249.958 467.866C253.831 465.618 257.459 463.364 260.954 461.116C315.447 427.121 337.444 401.502 337.444 342.89C336.22 315.315 321.74 290.034 298.576 275.027C273.077 257.281 241.583 258.528 212.091 258.78C165.047 260.931 118.449 248.871 78.3613 224.162C94.3459 223.458 110.233 222.708 126.103 221.209C142.471 219.116 158.41 214.51 173.371 207.548C198.589 194.486 225.844 185.796 253.969 181.854C280.777 180.356 307.632 183.748 333.228 191.849C363.212 200.986 391.703 214.436 417.802 231.782L423.426 235.529L424.925 236.656C549.907 324.142 505.392 476 468.5 567Z"
      fill="#C34185"
    />
    <path
      d="M361.267 476.689C343.56 460.252 310.172 467.432 286.693 492.725C263.214 518.017 258.534 551.846 276.241 568.282C293.947 584.719 327.335 577.539 350.815 552.246C374.294 526.948 378.974 493.119 361.267 476.689Z"
      fill="#683079"
    />
    <path
      opacity="0.6"
      d="M416.92 318.805C399.923 315.31 381.536 334.847 375.855 362.451C370.174 390.055 379.344 415.268 396.336 418.769C413.333 422.265 431.72 402.727 437.401 375.123C443.088 347.519 433.917 322.306 416.92 318.805Z"
      fill="#2B2572"
    />
    <path
      d="M353.812 220.592C339.744 210.672 322.833 210.443 316.036 220.077C309.245 229.717 315.143 245.564 329.211 255.485C343.285 265.405 360.197 265.634 366.988 255.999C373.778 246.365 367.88 230.512 353.812 220.592Z"
      fill="#683079"
    />
    <path
      d="M512.462 467.867C508.263 467.867 504.338 465.761 502.027 462.254C499.71 458.753 499.309 454.319 500.962 450.457C501.323 449.634 536.095 366.896 513.349 310.046C511.689 305.898 512.364 301.178 515.122 297.671C517.885 294.159 522.308 292.385 526.73 293.02C531.147 293.655 534.894 296.602 536.559 300.744C563.162 367.285 525.557 456.533 523.944 460.292C521.976 464.886 517.456 467.867 512.462 467.867V467.867Z"
      fill="#DF90AD"
    />
    <path
      d="M496.511 263.016C491.151 263.016 486.379 259.6 484.663 254.514C477.214 235.154 461.916 219.85 442.562 212.384C438.317 210.971 435.153 207.407 434.255 203.025C433.357 198.642 434.867 194.117 438.208 191.154C441.555 188.184 446.229 187.229 450.469 188.642C477.317 198.528 498.479 219.696 508.36 246.545C509.641 250.355 509.006 254.548 506.655 257.815C504.309 261.082 500.533 263.016 496.511 263.016V263.016Z"
      fill="#DF90AD"
    />
  </svg>
);

export const KrakenBackground = ({ className }: Props) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 755 672"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M675.344 328.593C666.603 348.508 655.435 367.267 642.099 384.453C641.728 384.956 641.35 385.328 640.978 385.832L603.357 384.207L517.375 380.454L553.24 286.097C553.738 285.588 554.196 285.044 554.619 284.472C566.965 268.573 577.646 251.444 586.485 233.354L608.357 163.747C611.217 145.039 611.509 126.039 609.232 107.251C607.624 89.6305 602.973 72.4216 595.484 56.3855C591.245 48.6964 585.804 41.7339 579.363 35.7669C573.739 30.2689 563.738 24.3934 562.743 16.0178C562.348 13.7981 562.566 11.5096 563.366 9.39858C567.114 -1.97485 587.115 -0.475936 596.857 1.02298C664.349 11.2694 696.97 78.1369 705.843 139.375C713.784 204.063 703.212 269.672 675.344 328.593V328.593Z"
      fill="#ED72A6"
    />
    <path
      d="M675.344 328.593C666.603 348.508 575.342 530.075 563.342 549.575C562.97 550.079 517.342 662.075 517.842 668.575H455.842L517.375 380.454L553.24 286.097C553.738 285.588 554.196 285.044 554.619 284.472C566.965 268.573 577.646 251.444 586.485 233.354L608.357 163.747C611.217 145.039 611.509 126.039 609.232 107.251C607.624 89.6305 602.973 72.4216 595.484 56.3855C591.245 48.6964 585.804 41.7339 579.363 35.7669C573.739 30.2689 563.738 24.3934 562.743 16.0178C562.348 13.7981 562.566 11.5096 563.366 9.39858C567.114 -1.97485 587.115 -0.475936 596.857 1.02298C664.349 11.2694 696.97 78.1369 705.843 139.375C713.784 204.063 703.212 269.672 675.344 328.593Z"
      fill="#ED72A6"
    />
    <path
      d="M631.602 334.84C623.918 352.226 495.435 653.134 484.342 668.575L300.842 671.575C300.842 671.575 279.45 562.075 333.341 503.075C387.233 444.075 497.842 339.575 497.842 339.575C503.342 333.575 544.832 297.075 554.619 284.472C566.965 268.573 577.646 251.444 586.485 233.355L608.357 163.747C611.218 145.039 611.509 126.039 609.232 107.251C607.625 89.6306 602.974 72.4217 595.485 56.3856C591.245 48.6965 585.805 41.734 579.363 35.767C573.739 30.269 563.739 24.3935 562.743 16.0179C562.348 13.7982 562.566 11.5097 563.367 9.39868C623.981 24.5194 653.725 87.6339 662.1 145.622C670.041 210.31 659.469 275.925 631.602 334.84Z"
      fill="#AE417C"
    />
    <path
      d="M621.984 162.11C607.945 158.174 591.377 173.484 584.98 196.299C578.584 219.115 584.786 240.803 598.831 244.739C612.871 248.675 629.439 233.372 635.835 210.55C642.231 187.735 636.029 166.046 621.984 162.11V162.11Z"
      fill="#683079"
    />
    <path
      d="M755 594V655.659L534.246 667.158C533.994 665.156 533.622 663.159 533.245 661.031L616.108 655.659V539.052C616.108 508.931 599.02 525.516 584.86 498.896C556.867 446.034 526.3 390.368 470.308 369.744L174.842 324.575C200.842 265.075 322.842 273.575 346.342 273.575C369.842 273.575 453.936 284.789 548.842 324.575C604.568 347.937 669.362 403.854 709.5 461C739 503 755.132 563.879 755 594Z"
      fill="#B44E9E"
    />
    <path
      d="M627.979 564.763L636.189 660.996H533.245C515.126 555.426 448.971 464.073 416.342 437.075C383.712 410.077 318.036 371.334 318.036 371.334C311.932 368.834 304.535 365.733 295.839 362.284C280.987 356.608 265.792 351.883 250.345 348.135C237.936 345.326 222.489 342.872 214.176 352.712C211.476 356.477 210.235 361.094 210.675 365.711C210.801 372.833 212.803 380.454 212.803 386.329C213.616 393.069 209.439 399.407 202.929 401.33C198.072 402.257 193.06 400.918 189.307 397.703C181.063 391.135 174.753 382.456 171.057 372.581C168.196 365.636 166.635 358.222 166.434 350.71C166.188 343.421 167.825 336.19 171.183 329.714C179.598 315.474 193.009 304.873 208.804 299.97C218.679 296.663 228.845 294.318 239.172 292.973C313.597 283.825 467.882 332.214 467.882 332.214C523.874 352.838 566.987 399.705 594.986 452.567C603.253 467.986 610.175 484.09 615.679 500.698C622.253 521.5 626.377 543 627.979 564.763Z"
      fill="#993783"
    />
    <ellipse
      cx="539.131"
      cy="530.393"
      rx="28.9847"
      ry="38.5"
      transform="rotate(-17.9721 539.131 530.393)"
      fill="#683079"
    />
    <ellipse
      cx="372.215"
      cy="363.242"
      rx="19.4665"
      ry="28.8959"
      transform="rotate(113.098 372.215 363.242)"
      fill="#683079"
    />
    <path
      d="M180.309 510.808L237 668H85.2021C73.514 652.147 30.9609 592.109 21.3381 574.923C15.6113 564.774 11.0803 553.996 7.83645 542.8C2.08108 522.959 -0.533438 502.34 0.0901554 481.687C1.09134 440.57 13.9637 393.578 50.0806 369.584C61.8316 361.711 78.4513 358.084 85.2021 344.588C88.875 333.839 90.8202 322.574 90.9517 311.218C91.3236 308.592 92.9484 294.844 98.2003 299.97C105.294 308.128 110.804 317.539 114.442 327.717C124.94 350.984 123.539 377.891 110.695 399.951C85.6998 437.447 83.8233 462.442 108.819 487.437C123.693 502.312 156.315 508.307 180.309 510.808Z"
      fill="#B44E9E"
    />
    <path
      d="M244.342 516.075L237.5 667H114.441L80.5 625C68.8119 609.147 55.955 592.109 46.3322 574.923C40.6055 564.774 36.0744 553.996 32.8306 542.8C27.0752 522.959 24.4607 502.34 25.0843 481.687C26.0855 440.57 38.9578 393.578 75.0748 369.584C86.8258 361.711 103.445 358.084 110.196 344.588C112.588 339.256 114.024 333.547 114.441 327.717C124.939 350.985 123.538 377.891 110.694 399.951C85.6987 437.447 83.8222 462.442 108.817 487.437C123.692 502.312 162.842 513.075 244.342 516.075Z"
      fill="#983783"
    />
    <circle cx="108.842" cy="562.575" r="25" fill="#683079" />
  </svg>
);

export default Kraken;
