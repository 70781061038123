import React, { useState } from "react";
import { Activity, ActivityListShape } from "models/cpd/Activity";
import { Plan } from "models/cpd/Plan";
import { Goal } from "models/cpd/Goal";
import ActivityItem from "components/cpd/activity/ActivityItem";
import { ReadOnlyActivityDetail } from "components/supervisors/detail/ReadOnlyActivityDetail";

interface Props extends DefaultProps {
  activities: ActivityListShape;
  year: number;
  userId: string;
  plan?: Plan;
  goals?: Goal[];
}

const SupervisionMeetings = ({ activities, userId, year, goals }: Props) => {
  const [activeActivity, setActiveActivity] = useState<Activity | undefined>(
    undefined
  );
  const supervisionMeetings = (
    activities.keyByYearByCategory[year]?.["[tp]_supervision"] ?? []
  )
    .map((a) => activities.map[a])
    .filter((a) => a.subcategory === "supervision_meeting");

  return (
    <div>
      <ul style={{ listStyle: "none" }}>
        {supervisionMeetings.map((a) => (
          <ActivityItem
            key={a.id}
            activity={a}
            editActivity={() => setActiveActivity(a)}
            locked={false}
          />
        ))}
      </ul>
      {supervisionMeetings.length === 0 && <b>No Meetings recorded</b>}

      <ReadOnlyActivityDetail
        userId={userId}
        resetActivty={() => setActiveActivity(undefined)}
        activity={activeActivity}
        goals={goals}
      />
      <br />
    </div>
  );
};

export default SupervisionMeetings;
