import { useQuery } from "react-query";
import { fetchOnePlanById } from "api/CpdApi";
import { planQueryKeys } from "queries/constants";
import { QueryOptions } from "queries/queries";
import { useToast } from "components/common/ui/toast/useToast";

export const usePlanDetail = (
  planId: string,
  userId?: string,
  options?: QueryOptions
) => {
  const { addErrorToast } = useToast();
  return useQuery(
    planQueryKeys.detail(planId),
    () => fetchOnePlanById(planId, userId),
    {
      onError: (e) =>
        addErrorToast(e, "Unable to load plan details", {
          id: planQueryKeys.detail(planId).join("::"),
        }),
      ...options,
    }
  );
};
