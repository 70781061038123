import React, { useState } from "react";
import { PeerGroup } from "models/peergroup/PeerGroup";
import PageHeader from "components/common/layouts/appLayout/PageHeader";
import Button from "components/common/ui/button/Button";
import { Tab, TabList, Tabs } from "components/common/ui/tabs/Tabs";
import styles from "components/users/common/detail/UserDetail.module.scss";
import IconGroup from "components/common/icons/IconGroup";
import Tooltip from "components/common/ui/tooltip/Tooltip";
import ActionButton from "components/common/ui/button/ActionButton";
import IconDelete, { IconRestore } from "components/common/icons/IconDelete";
import { useSelector } from "react-redux";
import { permissionsSelector } from "store/selectors/auth.selectors";
import { canAdminEdit } from "utils/authUtils";
import Modal from "components/common/ui/modal/Modal";
import { useMutateDelete } from "components/peergroup/hooks/useMutateDeletePeerGroup";
import MemberTab from "components/peergroup/detail/peergroup-detail-tabs/MemberTab";
import MeetingTab from "components/peergroup/detail/peergroup-detail-tabs/MeetingTab";
import Spacer from "components/common/ui/spacer/Spacer";
import HintBox from "components/common/ui/hint-box/HintBox";
import { usePeerGroupMembers } from "components/peergroup/hooks/usePeerGroupMembers";
import { TUser } from "models/user/User";
import { coreService } from "services/CoreService";
import { PEERGROUP_ROUTES } from "constants/routes/peergroup";
import { ROUTE_PARAMS } from "constants/routes/common";
import DisplayRichText from "components/common/ui/form-elements/rich-text-editor/DisplayRichText";
import Container from "components/common/layouts/Container";
import { IconProgressClock } from "components/common/icons/IconProgress";
import { isCurrentUserPeerGroupCoordinator } from "components/peergroup/utils/checkPeerGroupMembership";

interface Props {
  peergroupDetail: PeerGroup;
  editLink: string;
  currentUser: TUser;
}

const tabs = [
  { name: "Peer Group Meetings", icon: <IconProgressClock /> },
  { name: "Peer Group Members", icon: <IconGroup /> },
];

const PeerGroupDetail = ({ peergroupDetail, currentUser }: Props) => {
  const [openSuspendModal, setSuspendModalOpen] = useState(false);
  const permissions = useSelector(permissionsSelector);
  const isAdmin = canAdminEdit(permissions);
  const [tabState, setTabState] = useState<number>(0);
  const { data: members } = usePeerGroupMembers(peergroupDetail.id);

  const canEditPeerGroup =
    isCurrentUserPeerGroupCoordinator(members?.accepted, currentUser.id) ||
    isAdmin;

  const deletePeerGroupMutation = useMutateDelete({
    onSettled: () => setSuspendModalOpen(false),
  });

  const deletePeerGroup = () => {
    deletePeerGroupMutation.mutate({
      peergroup: peergroupDetail,
      accepted: !peergroupDetail.deleted,
    });
  };

  return (
    <div>
      <PageHeader
        spacing="extra"
        spacingTopOnly
        header={peergroupDetail.peergroup_name}
        actions={[
          <>
            {canEditPeerGroup && (
              <Button
                theme="primary"
                variant="outline"
                rounded
                to={coreService.getRoute(
                  PEERGROUP_ROUTES.ADMIN_EDIT_PEERGROUP,
                  {
                    [ROUTE_PARAMS.PEERGROUP]: peergroupDetail?.id,
                  }
                )}
              >
                Edit Peer Group
              </Button>
            )}
            {isAdmin && (
              <Tooltip
                content={
                  !peergroupDetail.peergroup_deleted
                    ? "Delete Peer Group"
                    : "Restore Peer Group"
                }
                offset={8}
              >
                <ActionButton
                  icon={
                    !peergroupDetail.peergroup_deleted ? (
                      <IconDelete />
                    ) : (
                      <IconRestore />
                    )
                  }
                  theme={!peergroupDetail.peergroup_deleted ? "danger" : "info"}
                  variant="lightFilled"
                  rounded
                  onClick={() => setSuspendModalOpen(true)}
                />
              </Tooltip>
            )}
          </>,

          <Modal
            open={openSuspendModal}
            onClose={() => setSuspendModalOpen(false)}
          >
            <div className={styles.modalContent}>
              <h1>
                {!peergroupDetail.peergroup_deleted ? "Delete" : "Restore"} Peer
                Group
              </h1>
              {!peergroupDetail.peergroup_deleted ? (
                <HintBox theme="danger">
                  Are you sure you want to delete "
                  {peergroupDetail.peergroup_name}" from the list?
                  <br />
                  This process is reversible, and a deleted peer group can be
                  restored in the future.
                </HintBox>
              ) : (
                <HintBox theme="success">
                  A restored peer group will be put back to the list again.
                </HintBox>
              )}
              {/*Are you sure you want to{" "}*/}
              {/*{!peergroupDetail.peergroup_deleted ? "delete" : "restore"}{" "}*/}
              {/*<b></b>*/}
              {/*{" this peer group ?"}*/}
            </div>{" "}
            <Spacer />
            <div className={styles.modalActions}>
              <Button
                onClick={() => setSuspendModalOpen(false)}
                variant="empty"
              >
                Cancel
              </Button>
              <Button
                onClick={deletePeerGroup}
                theme={peergroupDetail.peergroup_deleted ? "success" : "danger"}
                rounded
                submitting={deletePeerGroupMutation.isLoading}
              >
                {peergroupDetail.peergroup_deleted ? "Restore" : "Delete"} Peer
                Group
              </Button>
            </div>
          </Modal>,
        ]}
      />
      {peergroupDetail.peergroup_summary && (
        <Container>
          <DisplayRichText text={peergroupDetail.peergroup_summary} />
          <Spacer height={8} />
        </Container>
      )}

      <Tabs state={[tabState, setTabState]}>
        <TabList decorator="none" tabBackground>
          {tabs.map((t) => (
            <Tab className={styles.tab} key={t.name}>
              <div className={styles.tabIcon}>{t.icon}</div>
              <div>{t.name}</div>
            </Tab>
          ))}
        </TabList>
      </Tabs>

      {tabState === 0 && <MeetingTab canEditPeerGroup={canEditPeerGroup} />}

      {tabState === 1 && <MemberTab canEditPeerGroup={canEditPeerGroup} />}
    </div>
  );
};

export default PeerGroupDetail;
