import React from "react";
import cx from "classnames";
import InputFeedback from "components/common/ui/form-elements/common/InputFeedback";
import { IInput } from "components/common/ui/form-elements/formElements";
import styles from "components/common/ui/form-elements/common/Input.module.scss";

interface InputConfig {
  noInputError?: boolean;
  rounded?: boolean;
  condensed?: boolean;
}

export interface InputWrapperProps extends IInput, DefaultProps {
  description?: string;
  hint?: string;
  config?: InputConfig;
  icon?: React.ComponentType<any>;
  labelProps?: TObjectAny;
  inputWrapperProps?: TObjectAny;
}

export const InputWrapper = ({
  name,
  label,
  description,
  disabled,
  className,
  error,
  hint,
  config = {},
  icon: Icon,
  labelProps,
  inputWrapperProps,
  children,
}: InputWrapperProps) => (
  <div
    {...inputWrapperProps}
    className={cx(
      className,
      styles.root,
      error && styles.error,
      config.rounded && styles.rounded,
      config.condensed && styles.condensed,
      disabled && styles.disabled,
      Icon && styles.iconInput
    )}
  >
    <label
      htmlFor={name}
      style={{ display: "block" }}
      id={`text-input-label-${name}`}
      {...labelProps}
      className={styles.label}
    >
      {label}
      {label && description && <br />}
      {description && <span>{description}</span>}
    </label>
    {children}
    {Icon && (
      <span className={styles.icon}>
        <Icon />
      </span>
    )}
    {hint && <div className={styles.hint}>{hint}</div>}
    {!config.noInputError && (
      <InputFeedback error={error} visible={!!error} name={name} />
    )}
  </div>
);
