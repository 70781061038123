import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import { peerGroupQueryKeys } from "queries/constants";
import { MutationOptions } from "queries/queries";
import { useToast } from "components/common/ui/toast/useToast";
import { PeerGroup } from "models/peergroup/PeerGroup";
import { savePeerGroup } from "api/PeerGroupApi";

export const useMutatePeerGroup = (options: MutationOptions = {}) => {
  const queryClient = useQueryClient();
  const { addErrorToast, addToast } = useToast();

  return useMutation(
    ({ newPeerGroup, oldPeerGroup }: useMutatePeerGroupProps) =>
      savePeerGroup(newPeerGroup, oldPeerGroup),
    {
      onSuccess: (result, inputs) => {
        addToast(
          inputs.oldPeerGroup.id
            ? "Peer group was successfully updated!"
            : "Peer group was successfully created!",
          {
            theme: "success",
          }
        );

        queryClient.invalidateQueries(
          peerGroupQueryKeys.detail(inputs.oldPeerGroup.id)
        );
        queryClient.invalidateQueries(peerGroupQueryKeys.list());
      },
      onError: (e) => {
        if (axios.isAxiosError(e) && e.response?.status === 500) {
          addToast(`Error: Unable to create peer group`, {
            theme: "warning",
            delay: 6000,
          });
        } else {
          addErrorToast(e, "Error: Unable to save peer group details");
        }
      },
      ...options,
    }
  );
};

interface useMutatePeerGroupProps {
  newPeerGroup: Partial<PeerGroup>;
  oldPeerGroup: PeerGroup;
}
