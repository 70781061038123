import { useQuery } from "react-query";
import { fetchManyGoals } from "api/CpdApi";
import { goalQueryKeys } from "queries/constants";
import { QueryOptions } from "queries/queries";
import { useToast } from "components/common/ui/toast/useToast";

export const useGoalList = (userId: string, options?: QueryOptions) => {
  const { addErrorToast } = useToast();
  const qk = goalQueryKeys.list({ userId });
  return useQuery(qk, () => fetchManyGoals(userId), {
    onError: (e) =>
      addErrorToast(e, `Unable to load goals`, {
        id: qk.join("::"),
      }),
    ...options,
  });
};
