import React from "react";
import HintBox from "components/common/ui/hint-box/HintBox";
import Spacer from "components/common/ui/spacer/Spacer";
import Button from "components/common/ui/button/Button";
import Modal from "components/common/ui/modal/Modal";
import { useMutateUpdateMemberInPeerGroup } from "components/peergroup/hooks/useMutateUpdateMemberInPeerGroup";
import { PeerGroupMember } from "models/peergroup/member";

interface Props {
  member?: PeerGroupMember;
  peergroupId: string;
  onClose: () => void;
}
export const DeleteMemberModal = ({ member, peergroupId, onClose }: Props) => {
  const { mutateAsync, isLoading } = useMutateUpdateMemberInPeerGroup();
  const deletePeerGroupMember = async (currentMember?: PeerGroupMember) => {
    if (currentMember) {
      await mutateAsync({
        currentMember,
        user_id: currentMember.user_id,
        peergroupId,
        deleted: !currentMember?.deleted,
      });
    }
    onClose();
  };
  return (
    <Modal open={!!member} onClose={onClose}>
      <div>
        <h1>
          {!member?.peergroup_member_deleted ? "Delete" : "Restore"} Member
        </h1>
        {!member?.peergroup_member_deleted ? (
          <HintBox theme="danger">
            Are you sure you want to delete {member?.given_name}
            {member?.family_name} from peer group member list?
            <br />
            This process is reversible, and a deleted user can be restored in
            the future.
          </HintBox>
        ) : (
          <HintBox theme="success">
            A restored user will be put back to peer group member list again.
          </HintBox>
        )}
      </div>{" "}
      <Spacer />
      <div>
        <Button onClick={() => onClose} variant="empty">
          Cancel
        </Button>
        <Button
          onClick={() => deletePeerGroupMember(member)}
          theme={member?.peergroup_member_deleted ? "success" : "danger"}
          rounded
          submitting={isLoading}
        >
          {member?.peergroup_member_deleted ? "Restore" : "Delete"} Member
        </Button>
      </div>
    </Modal>
  );
};
