import React from "react";
import cx from "classnames";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import NavBar from "components/common/layouts/appLayout/navBar/NavBar";
import AppWaveBackground from "./appLayout/AppWaveBackground";
import { layoutService } from "services/LayoutService";
import AppSettingBar from "components/common/layouts/appLayout/appSettingBar/AppSettingBar";
import MobileAppBar from "components/common/layouts/appLayout/mobileAppMenu/MobileAppBar";
import styles from "components/common/layouts/BaseLayout.module.scss";
import {
  isDesktopSelector,
  isMobileOpenedSelector,
} from "store/selectors/layout.selectors";

interface Props {
  children: any;
  useFullScreenLayout?: boolean;
}

const body = document.body;

const BaseLayout = ({ children, useFullScreenLayout = false }: Props) => {
  const isDesktop = useSelector(isDesktopSelector);
  const isMobileOpened = useSelector(isMobileOpenedSelector);

  const toggleMobileNav = () => {
    layoutService.expandMobileSidebarToggle(!isMobileOpened);
    body.classList.toggle("noscroll", !isMobileOpened);
  };

  return (
    <motion.div
      className={cx(
        styles.root,
        isMobileOpened && styles.mobileOpened,
        useFullScreenLayout && styles.fullscreenLayout
      )}
      variants={variants}
      initial="hidden"
      animate={isMobileOpened && !isDesktop ? "visible" : "hidden"}
      exit={{ opacity: 0 }}
    >
      <AppWaveBackground />
      <motion.div
        className={styles.contentWrapper}
        variants={containerVariants}
      >
        {isMobileOpened && !isDesktop && (
          <button className={styles.contentOverlay} onClick={toggleMobileNav} />
        )}
        {!isDesktop ? (
          <MobileAppBar
            toggleMobileNav={toggleMobileNav}
            isMobileOpened={isMobileOpened}
          />
        ) : (
          <AppSettingBar />
        )}
        {children}
      </motion.div>

      <NavBar
        className={styles.nav}
        isDesktop={isDesktop}
        isMobileOpened={isMobileOpened}
        toggleMobileNav={toggleMobileNav}
      />
    </motion.div>
  );
};

const transition = { duration: 0.5, ease: "easeInOut" };

const variants = {
  visible: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  hidden: {
    transition: {
      staggerChildren: 0.05,
      staggerDirection: 1 as -1,
      delayChildren: 0.2,
    },
  },
  initial: { scale: 0.9, opacity: 0 },
  enter: { scale: 1, opacity: 1, transition },
  exit: {
    scale: 0.5,
    opacity: 0,
    transition: { ...transition, duration: 1.5 },
  },
};

const containerVariants = {
  visible: {
    transform: "translateX(250px) translateY(50px)",
  },
  hidden: {
    transform: "translateX(0px) translateY(0px)",
  },
};

export default React.memo(BaseLayout);
