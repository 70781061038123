import { useQuery } from "react-query";
import { fetchManyPlans } from "api/CpdApi";
import { planQueryKeys } from "queries/constants";
import { QueryOptions } from "queries/queries";
import { useToast } from "components/common/ui/toast/useToast";
import { extractFiltersFromDimensions } from "components/users/utils/userUtils";
import { IPlanListDimensions } from "models/cpd/Plan";

export const usePlanList = (
  dimensions: IPlanListDimensions,
  userId?: string,
  options?: QueryOptions
) => {
  const { addErrorToast } = useToast();
  const dim = extractFiltersFromDimensions(dimensions);
  let filters = Object.keys(dim).length > 0 ? dim : undefined;
  if (userId) {
    filters = { ...filters, userId };
  }
  const qk = planQueryKeys.list(filters);
  return useQuery(qk, () => fetchManyPlans(dim, userId), {
    onError: (e) =>
      addErrorToast(e, `Unable to load plans`, {
        id: qk.join("::"),
      }),
    ...options,
  });
};
