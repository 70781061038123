import React from "react";
import createIcon from "./common/createIcon";

export const IconFile = createIcon(
  <React.Fragment>
    <path d="M13,9V3.5L18.5,9M6,2C4.89,2 4,2.89 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2H6Z" />
  </React.Fragment>,
  "File"
);

export const IconImageFile = createIcon(
  <React.Fragment>
    <path d="M16.75 10.8333C17.4031 10.8333 17.9375 10.3083 17.9375 9.66667C17.9375 9.025 17.4031 8.5 16.75 8.5C16.0969 8.5 15.5625 9.025 15.5625 9.66667C15.5625 10.3083 16.0969 10.8333 16.75 10.8333ZM20.3125 5H3.6875C3.03437 5 2.5 5.525 2.5 6.16667V17.8333C2.5 18.475 3.03437 19 3.6875 19H20.3125C20.9656 19 21.5 18.475 21.5 17.8333V6.16667C21.5 5.525 20.9656 5 20.3125 5ZM19.125 15.5L13.1875 12L12 14.3333L8.4375 9.66667L4.875 15.5V7.33333H19.125V15.5Z" />
  </React.Fragment>,
  "ImageFile"
);
export const IconDelimitedFile = createIcon(
  <React.Fragment>
    <path d="M14 2H6C4.9 2 4 2.9 4 4V20C4 21.1 4.9 22 6 22H18C19.1 22 20 21.1 20 20V8L14 2M18 20H6V4H13V9H18V20M10 19L12 15H9V10H15V15L13 19H10" />
  </React.Fragment>,
  "DelimitedFile"
);
export const IconExcelFile = createIcon(
  <React.Fragment>
    <path d="M 12 3 L 2 5 L 2 19 L 12 21 L 12 3 z M 14 5 L 14 7 L 16 7 L 16 9 L 14 9 L 14 11 L 16 11 L 16 13 L 14 13 L 14 15 L 16 15 L 16 17 L 14 17 L 14 19 L 21 19 C 21.552 19 22 18.552 22 18 L 22 6 C 22 5.448 21.552 5 21 5 L 14 5 z M 18 7 L 20 7 L 20 9 L 18 9 L 18 7 z M 4.1757812 8.296875 L 5.953125 8.296875 L 6.8769531 10.511719 C 6.9519531 10.692719 7.0084063 10.902625 7.0664062 11.140625 L 7.0917969 11.140625 C 7.1247969 10.997625 7.1919688 10.779141 7.2929688 10.494141 L 8.3222656 8.296875 L 9.9433594 8.296875 L 8.0078125 11.966797 L 10 15.703125 L 8.2714844 15.703125 L 7.1582031 13.289062 C 7.1162031 13.204062 7.0663906 13.032922 7.0253906 12.794922 L 7.0097656 12.794922 C 6.9847656 12.908922 6.934375 13.079594 6.859375 13.308594 L 5.7363281 15.703125 L 4 15.703125 L 6.0605469 11.996094 L 4.1757812 8.296875 z M 18 11 L 20 11 L 20 13 L 18 13 L 18 11 z M 18 15 L 20 15 L 20 17 L 18 17 L 18 15 z" />
    {/*<path*/}
    {/*  */}
    {/*  d="M21.17 3.25Q21.5 3.25 21.76 3.5 22 3.74 22 4.08V19.92Q22 20.26 21.76 20.5 21.5 20.75 21.17 20.75H7.83Q7.5 20.75 7.24 20.5 7 20.26 7 19.92V17H2.83Q2.5 17 2.24 16.76 2 16.5 2 16.17V7.83Q2 7.5 2.24 7.24 2.5 7 2.83 7H7V4.08Q7 3.74 7.24 3.5 7.5 3.25 7.83 3.25M7 13.06L8.18 15.28H9.97L8 12.06L9.93 8.89H8.22L7.13 10.9L7.09 10.96L7.06 11.03Q6.8 10.5 6.5 9.96 6.25 9.43 5.97 8.89H4.16L6.05 12.08L4 15.28H5.78M13.88 19.5V17H8.25V19.5M13.88 15.75V12.63H12V15.75M13.88 11.38V8.25H12V11.38M13.88 7V4.5H8.25V7M20.75 19.5V17H15.13V19.5M20.75 15.75V12.63H15.13V15.75M20.75 11.38V8.25H15.13V11.38M20.75 7V4.5H15.13V7Z"*/}
    {/*/>*/}
  </React.Fragment>,
  "ExcelFile"
);

export const IconWordFile = createIcon(
  <React.Fragment>
    <path d="M 12 3 L 2 5 L 2 19 L 12 21 L 12 3 z M 14 5 L 14 7 L 20 7 L 20 9 L 14 9 L 14 11 L 20 11 L 20 13 L 14 13 L 14 15 L 20 15 L 20 17 L 14 17 L 14 19 L 21 19 C 21.552 19 22 18.552 22 18 L 22 6 C 22 5.448 21.552 5 21 5 L 14 5 z M 3.2949219 8.2949219 L 4.8300781 8.2949219 L 5.3222656 11.773438 C 5.3572656 11.982437 5.3845781 12.267141 5.3925781 12.619141 L 5.4179688 12.619141 C 5.4259688 12.362141 5.4624375 12.068906 5.5234375 11.753906 L 6.1542969 8.2949219 L 8.0234375 8.2949219 L 8.6074219 11.742188 C 8.6424219 11.923187 8.6673594 12.189203 8.6933594 12.533203 L 8.7128906 12.533203 C 8.7208906 12.266203 8.74725 11.989125 8.78125 11.703125 L 9.2597656 8.2949219 L 10.703125 8.2949219 L 9.3203125 15.705078 L 8.0058594 15.705078 L 7.1269531 11.744141 C 7.0829531 11.497141 7.0588281 11.219062 7.0488281 10.914062 L 7.03125 10.914062 C 7.01325 11.247063 6.9785469 11.524141 6.9355469 11.744141 L 6.0390625 15.705078 L 4.671875 15.705078 L 3.2949219 8.2949219 z" />
  </React.Fragment>,
  "WordFile"
);

export const IconPowerPointFile = createIcon(
  <React.Fragment>
    <path d="M 12 3 L 2 5 L 2 19 L 12 21 L 12 3 z M 14 5 L 14 7 L 16 7 L 18 7 L 20 7 L 20 9 L 20 11 L 20 13 L 20 15 L 20 17 L 18 17 L 16 17 L 14 17 L 14 19 L 21 19 C 21.552 19 22 18.552 22 18 L 22 6 C 22 5.448 21.552 5 21 5 L 14 5 z M 3.9882812 8 L 7.0605469 8 C 7.9575469 8 8.6692656 8.2322656 9.1972656 8.6972656 C 9.7252656 9.1632656 9.9882812 9.780875 9.9882812 10.546875 C 9.9882812 11.331875 9.7318438 11.941906 9.2148438 12.378906 C 8.6988437 12.815906 7.9749219 13.033203 7.0449219 13.033203 L 5.5 13.033203 L 5.5 16 L 3.9882812 16 L 3.9882812 8 z M 16 8 C 14.895 8 14 8.895 14 10 C 14 11.105 14.895 12 16 12 C 17.105 12 18 11.105 18 10 L 16 10 L 16 8 z M 5.5 9.1875 L 5.5 11.736328 L 6.96875 11.736328 C 7.43575 11.736328 7.7911094 11.629969 8.0371094 11.417969 C 8.2831094 11.205969 8.40625 10.899047 8.40625 10.498047 C 8.40625 10.103047 8.28125 9.7887344 8.03125 9.5527344 C 7.78325 9.3157344 7.4418594 9.1935 7.0058594 9.1875 L 5.5 9.1875 z M 14 13 L 14 15 L 18 15 L 18 13 L 14 13 z" />
  </React.Fragment>,
  "PowerPointFile"
);

export const IconPdfFile = createIcon(
  <React.Fragment>
    <path d="M13,9H18.5L13,3.5V9M6,2H14L20,8V20A2,2 0 0,1 18,22H6A2,2 0 0,1 4,20V4A2,2 0 0,1 6,2M10.1,11.4C10.08,11.44 9.81,13.16 8,16.09C8,16.09 4.5,17.91 5.33,19.27C6,20.35 7.65,19.23 9.07,16.59C9.07,16.59 10.89,15.95 13.31,15.77C13.31,15.77 17.17,17.5 17.7,15.66C18.22,13.8 14.64,14.22 14,14.41C14,14.41 12,13.06 11.5,11.2C11.5,11.2 12.64,7.25 10.89,7.3C9.14,7.35 9.8,10.43 10.1,11.4M10.91,12.44C10.94,12.45 11.38,13.65 12.8,14.9C12.8,14.9 10.47,15.36 9.41,15.8C9.41,15.8 10.41,14.07 10.91,12.44M14.84,15.16C15.42,15 17.17,15.31 17.1,15.64C17.04,15.97 14.84,15.16 14.84,15.16M7.77,17C7.24,18.24 6.33,19 6.1,19C5.87,19 6.8,17.4 7.77,17M10.91,10.07C10.91,10 10.55,7.87 10.91,7.92C11.45,8 10.91,10 10.91,10.07Z" />
  </React.Fragment>,
  "PdfFile"
);

export const IconTextFile = createIcon(
  <React.Fragment>
    <path d="M13,9H18.5L13,3.5V9M6,2H14L20,8V20A2,2 0 0,1 18,22H6C4.89,22 4,21.1 4,20V4C4,2.89 4.89,2 6,2M15,18V16H6V18H15M18,14V12H6V14H18Z" />{" "}
  </React.Fragment>,
  "TextFile"
);
export const IconZipFile = createIcon(
  <React.Fragment>
    <path d="M20 6H12L10 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V8C22 6.9 21.1 6 20 6M18 12H16V14H18V16H16V18H14V16H16V14H14V12H16V10H14V8H16V10H18V12Z" />
  </React.Fragment>,
  "ZipFile"
);

export const IconMusicFile = createIcon(
  <React.Fragment>
    <path d="M12 3V13.55C11.41 13.21 10.73 13 10 13C7.79 13 6 14.79 6 17S7.79 21 10 21 14 19.21 14 17V7H18V3H12Z" />
  </React.Fragment>,
  "MusicFile"
);

export const IconVideoFile = createIcon(
  <React.Fragment>
    <path d="M17,10.5V7A1,1 0 0,0 16,6H4A1,1 0 0,0 3,7V17A1,1 0 0,0 4,18H16A1,1 0 0,0 17,17V13.5L21,17.5V6.5L17,10.5Z" />
  </React.Fragment>,
  "VideoFile"
);
export const IconCodeFile = createIcon(
  <React.Fragment>
    <path d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3M11 8H9V10C9 11.1 8.1 12 7 12C8.1 12 9 12.9 9 14V16H11V18H9C7.9 18 7 17.1 7 16V15C7 13.9 6.1 13 5 13V11C6.1 11 7 10.1 7 9V8C7 6.9 7.9 6 9 6H11V8M19 13C17.9 13 17 13.9 17 15V16C17 17.1 16.1 18 15 18H13V16H15V14C15 12.9 15.9 12 17 12C15.9 12 15 11.1 15 10V8H13V6H15C16.1 6 17 6.9 17 8V9C17 10.1 17.9 11 19 11V13Z" />
  </React.Fragment>,
  "CodeFile"
);

export const IconSvgFile = createIcon(
  <React.Fragment>
    <path d="M5.13,10.71H8.87L6.22,8.06C5.21,8.06 4.39,7.24 4.39,6.22A1.83,1.83 0 0,1 6.22,4.39C7.24,4.39 8.06,5.21 8.06,6.22L10.71,8.87V5.13C10,4.41 10,3.25 10.71,2.54C11.42,1.82 12.58,1.82 13.29,2.54C14,3.25 14,4.41 13.29,5.13V8.87L15.95,6.22C15.95,5.21 16.76,4.39 17.78,4.39C18.79,4.39 19.61,5.21 19.61,6.22C19.61,7.24 18.79,8.06 17.78,8.06L15.13,10.71H18.87C19.59,10 20.75,10 21.46,10.71C22.18,11.42 22.18,12.58 21.46,13.29C20.75,14 19.59,14 18.87,13.29H15.13L17.78,15.95C18.79,15.95 19.61,16.76 19.61,17.78A1.83,1.83 0 0,1 17.78,19.61C16.76,19.61 15.95,18.79 15.95,17.78L13.29,15.13V18.87C14,19.59 14,20.75 13.29,21.46C12.58,22.18 11.42,22.18 10.71,21.46C10,20.75 10,19.59 10.71,18.87V15.13L8.06,17.78C8.06,18.79 7.24,19.61 6.22,19.61C5.21,19.61 4.39,18.79 4.39,17.78C4.39,16.76 5.21,15.95 6.22,15.95L8.87,13.29H5.13C4.41,14 3.25,14 2.54,13.29C1.82,12.58 1.82,11.42 2.54,10.71C3.25,10 4.41,10 5.13,10.71Z" />
  </React.Fragment>,
  "SvgFile"
);
export const IconTable = createIcon(
  <React.Fragment>
    <path d="M5,4H19A2,2 0 0,1 21,6V18A2,2 0 0,1 19,20H5A2,2 0 0,1 3,18V6A2,2 0 0,1 5,4M5,8V12H11V8H5M13,8V12H19V8H13M5,14V18H11V14H5M13,14V18H19V14H13Z" />
  </React.Fragment>,
  "Table"
);
