import React from "react";
import cx from "classnames";
import { motion } from "framer-motion";
import styles from "./AuthLayout.module.scss";
import BrandName from "components/common/ui/logo/BrandName";
import WaveBackground from "./authLayout/WaveBackground";
import ContentWaves from "./authLayout/ContentWaves";
import LighthouseAndBirds from "components/common/images/LighthouseAndBirds";
import Clouds from "components/common/images/Clouds";
import MessageBottle from "components/common/images/MessageBottle";
import {
  KrakenBackground,
  KrakenTentacle,
} from "components/common/images/Kraken";
import Diver from "components/common/images/Diver";
import Squid from "components/common/images/Squid";
import Moon from "components/common/images/Moon";
import DataPortal from "components/common/images/Data";
import LetterIllus from "components/common/images/Letter";
import OverlayWaves from "components/common/layouts/authLayout/OverlayWaves";

interface Props {
  children: any;
  variant?:
    | "lighthouse"
    | "underwater"
    | "moon"
    | "bottle"
    | "kraken"
    | "data"
    | "letter";
}

const transition = { duration: 0.5, ease: [0.43, 0.13, 0.23, 0.96] };
const contentAnimation = {
  initial: { opacity: 0.0 },
  animate: { opacity: 1, transition },
  exit: {
    opacity: 0,
    transition: { ...transition, duration: 0.2 },
  },
};

const BgImg = ({ variant }: { variant: string }) => {
  switch (variant) {
    case "diver":
      return (
        <>
          <Diver className={styles.img} />
          <Squid className={styles.imgAlt} />
        </>
      );
    case "kraken":
      return (
        <>
          <KrakenTentacle className={styles.img} />
          <KrakenBackground className={styles.imgAlt} />
          <OverlayWaves className={styles.wavesOverlay} />
          {/*<Clouds className={styles.clouds} />*/}
        </>
      );
    case "bottle":
      return (
        <>
          <OverlayWaves className={styles.wavesOverlay} />
          <MessageBottle className={styles.img} />
        </>
      );
    case "underwater":
      return (
        <>
          <Diver className={styles.img} />
          <Squid className={styles.imgAlt} />
        </>
      );

    case "moon":
      return (
        <>
          <Moon className={styles.img} />
          <Clouds className={styles.clouds} />
        </>
      );
    case "data":
      return <DataPortal className={styles.img} />;
    case "letter":
      return <LetterIllus className={styles.img} />;

    default:
      return (
        <>
          <LighthouseAndBirds className={styles.img} />{" "}
          <Clouds className={styles.clouds} />
        </>
      );
  }
};

const AuthLayout = ({ variant = "lighthouse", children }: Props) => (
  <motion.div className={cx(styles.root, variant && styles[variant])}>
    <BrandName
      className={styles.logo}
      lightClass={styles.lightBrand}
      houseClass={styles.houseBrand}
    />

    <div className={styles.backgroundContainer} aria-hidden="true">
      <WaveBackground className={styles.wavesBackgroundBg} />
      <div className={styles.background} />
      <BgImg variant={variant} />
      <OverlayWaves className={styles.wavesOverlay} />
    </div>

    <div className={styles.contentContainer} role="main">
      <div className={styles.desktopPadder} />
      <motion.div className={styles.content} {...contentAnimation} role="form">
        {children}
      </motion.div>
      <div className={styles.padder} />
      <ContentWaves className={styles.wavesBg} />
    </div>
  </motion.div>
);

export default AuthLayout;
