import React from "react";
import Meta from "components/common/Meta";
import AppContentContainer from "components/common/layouts/appLayout/AppContentContainer";
import { useSelector } from "react-redux";
import {
  isDimensionsLoadingSelector,
  peergroupDimSelector,
} from "store/selectors/dimensions.selectors";
import { isAppLoadedSelector } from "store/selectors/core.selectors";
import Button from "components/common/ui/button/Button";
import { verifyPermisisons } from "utils/authUtils";
import { permissionsSelector } from "store/selectors/auth.selectors";
import { coreService } from "services/CoreService";
import { PEERGROUP_ROUTES } from "constants/routes/peergroup";
import { usePeerGroupListByAdmin } from "components/peergroup/hooks/usePeerGroupListsByAdmin";
import PageHeader from "components/common/layouts/appLayout/PageHeader";
import { PeerGroupList } from "components/peergroup/list/PeerGroupList";

const ViewAdminPeerGroups = () => {
  const isAppLoaded = useSelector(isAppLoadedSelector);
  const isDimLoading = useSelector(isDimensionsLoadingSelector);
  const dimensions = useSelector(peergroupDimSelector);
  const enabled = isAppLoaded && !isDimLoading;
  const permissions = useSelector(permissionsSelector);
  const canEdit = verifyPermisisons(
    permissions,
    PEERGROUP_ROUTES.ADMIN_CREATE_PEERGROUP.requiredRole
  );

  const { isLoading, data: peergroups } = usePeerGroupListByAdmin(dimensions, {
    enabled,
  });

  return (
    <AppContentContainer spacing="none">
      <Meta title={`Admin Peer Groups`} />
      <PageHeader
        spacing="extra"
        spacingTopOnly
        header={"Admin Peer Groups"}
        actions={
          canEdit
            ? [
                <Button
                  rounded
                  theme="primary"
                  to={coreService.getRoute(
                    PEERGROUP_ROUTES.ADMIN_CREATE_PEERGROUP
                  )}
                >
                  Create New Peer Group
                </Button>,
              ]
            : []
        }
      />

      <PeerGroupList
        isLoading={!enabled || isLoading}
        peergroups={peergroups}
      />
    </AppContentContainer>
  );
};

export default ViewAdminPeerGroups;
