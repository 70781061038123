import { appGetState, appUpdateState } from "store";
import { IDimension, ISetBucket, TBucket } from "models/Dimension";
import { updateBuckets } from "utils/dimensions/bucketUtils";
import { IPlanListDimensions } from "models/cpd/Plan";

const comment = (m: string) => `CpdService::${m}`;

class CpdService {
  private appGetState = appGetState;
  // private appNextState = appNextState;
  private appUpdateState = appUpdateState;

  public setDimensions = async ({ key, bucket }: ISetBucket) => {
    const currentDimensions = this.getCurrentDimensions();
    const dimension = currentDimensions[key];
    if (dimension) {
      window.logger.group(comment("setFilter"));
      await this.updateDimensionFilter({ bucket, dimension });
      // await this.loadList();
      window.logger.groupEnd();
    }
    return this.getCurrentDimensions();
  };

  public setManyDimensions = async (
    filters: (ISetBucket | undefined)[],
    refreshData?: boolean
  ) => {
    const currentDimensions = this.getCurrentDimensions();
    if (filters.length > 0) {
      window.logger.group(comment("setManyDimensions"));
      this.setIsLoading(true);
      for (let i = 0; i < filters.length; i++) {
        const f = filters[i];
        if (f) {
          const dimension = currentDimensions[f.key];
          const bucket = f.bucket === undefined ? null : f.bucket;
          await this.updateDimensionFilter({ bucket, dimension });
        }
      }
      this.setIsLoading(false);
      window.logger.groupEnd();
    }

    return this.getCurrentDimensions();
  };

  /**
   */
  private setIsLoading = (isLoading: boolean): void => {
    this.appUpdateState(
      (s) => (s.dimensions.isLoading = isLoading),
      comment("setIsLoading")
    );
  };

  private updateDimensionFilter = async ({
    bucket,
    dimension,
  }: {
    bucket?: TBucket | null;
    dimension?: IDimension;
  }) => {
    if (dimension) {
      const newBuckets = updateBuckets(bucket, dimension);
      this.appUpdateState(
        (s) =>
          (s.dimensions.plans[dimension.key] = {
            ...dimension,
            buckets: newBuckets,
          }),
        comment("updateDimensionFilter")
      );
      return newBuckets;
    }
    return false;
  };

  private getCurrentDimensions = () => {
    const {
      dimensions: { plans },
    } = this.appGetState();
    return plans as IPlanListDimensions;
  };
}

const cpdService = new CpdService();
export { cpdService };
