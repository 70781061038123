import UserPlanList from "components/cpd/common/UserPlanList";
import { useSelector } from "react-redux";
import { currentUserSelector } from "store/selectors/core.selectors";

const ViewMyPlansList = () => {
  const currentUser = useSelector(currentUserSelector);
  return (
    <UserPlanList
      user={currentUser}
      isLoading={!currentUser.id}
      userId={currentUser.id}
    />
  );
};

export default ViewMyPlansList;
