import React from "react";
import { useWatch } from "react-hook-form";
import { Control } from "react-hook-form/dist/types";
import ConditionalLogicWrapper from "components/common/ui/form-elements/common/ConditionalLogicWrapper";
import { checkConditionals, getConditionalKeys } from "utils/formUtils";
import { Conditional } from "components/common/ui/form-elements/formElements";

interface IWrapperProps<F extends string = string> extends DefaultProps {
  control: Control<any>;
  conditional?: Conditional<F>;
  fieldPrefix?: string;
  showOnFalseConditional?: boolean;
}

const ConditionalLogicWrapperHookForm = ({
  control,
  conditional,
  children,
  fieldPrefix,
  showOnFalseConditional,
  ...props
}: IWrapperProps) => {
  const fieldKeys = fieldPrefix
    ? getConditionalKeys(conditional).map((k) => `${fieldPrefix}.${k}`)
    : getConditionalKeys(conditional);

  const watch = useWatch({
    name: fieldKeys,
    control,
  });
  if (conditional) {
    const logic = (v: any[]) => {
      if (!v) {
        return false;
      }
      const isConditionalTrue = checkConditionals(
        fieldKeys.reduce((o, k, i) => ({ ...o, [k]: v[i] }), {}),
        conditional,
        fieldPrefix
      );
      return showOnFalseConditional ? !isConditionalTrue : isConditionalTrue;
    };

    // console.info(conditional);
    return (
      <ConditionalLogicWrapper
        // value={watch(conditional.map((c) => c.field))}
        value={watch}
        logic={(v) => logic(v)}
        {...props}
      >
        {children}
      </ConditionalLogicWrapper>
    );
  }

  return <div {...props}>{children}</div>;
};

export const HookFormShowIf = ConditionalLogicWrapperHookForm;
export default ConditionalLogicWrapperHookForm;
