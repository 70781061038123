import { useQuery } from "react-query";
import { peerGroupQueryKeys } from "queries/constants";
import { QueryOptions } from "queries/queries";
import { useToast } from "components/common/ui/toast/useToast";
import { fetchMembersByPeergroupId } from "api/PeerGroupApi";

export const usePeerGroupMembers = (
  peergroupId: string,
  options?: QueryOptions
) => {
  const { addErrorToast } = useToast();
  return useQuery(
    peerGroupQueryKeys.members(peergroupId),
    () => fetchMembersByPeergroupId(peergroupId),
    {
      onError: (e) =>
        addErrorToast(e, "Unable to load peer group members", {
          id: peerGroupQueryKeys.members(peergroupId).join("::"),
        }),
      staleTime: 60 * 1000,
      ...options,
    }
  );
};
