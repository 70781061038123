import React from "react";
import Meta from "components/common/Meta";
import { AppContentContainer } from "components/common/layouts/appLayout/AppContentContainer";
import { usePlanList } from "components/cpd/hooks/usePlanList";
import { useSelector } from "react-redux";
import {
  currentUserSelector,
  isAppLoadedSelector,
} from "store/selectors/core.selectors";
import { ROUTE_PARAMS } from "constants/routes/common";
import { useParams } from "react-router";
import { userListDimSelector } from "store/selectors/dimensions.selectors";
import MiniLoader from "components/common/ui/loader/MiniLoader";
import ViewCpdPlanDetail from "components/cpd/viewCpdPlanDetail";
import UserPlanList from "components/cpd/common/UserPlanList";

const ViewMyPlans = () => {
  const dimensions = useSelector(userListDimSelector);

  const isAppLoaded = useSelector(isAppLoadedSelector);
  const currentUser = useSelector(currentUserSelector);
  const { [ROUTE_PARAMS.USER]: paramsUserId } =
    useParams<{ [ROUTE_PARAMS.USER]?: string }>();

  const { isLoading: isPlanListLoading, data: planList } = usePlanList(
    dimensions,
    paramsUserId || currentUser.id,
    { enabled: isAppLoaded }
  );

  const isLoading = isPlanListLoading || !isAppLoaded;

  // console.log(planList);
  return (
    <>
      <Meta title={"Plan"} />
      {isLoading && !planList ? (
        <AppContentContainer spacing="none" maxWidth={true}>
          <MiniLoader theme="secondary" />
        </AppContentContainer>
      ) : planList?.active?.length === 1 ? (
        <ViewCpdPlanDetail planId={planList.active[0].plan_id} />
      ) : (
        <UserPlanList
          user={currentUser}
          userId={currentUser.id}
          isLoading={isLoading}
        />
      )}
    </>
  );
};

export default ViewMyPlans;
