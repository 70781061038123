import React, { useEffect } from "react";
import Meta from "components/common/Meta";
import { useHistory, useParams } from "react-router";
import { AppContentContainer } from "components/common/layouts/appLayout/AppContentContainer";
import { TUser } from "models/user/User";
import { USER_ROUTES } from "constants/routes/user";
import Container from "components/common/layouts/Container";
import { coreService } from "services/CoreService";
import { useMutateUser } from "components/users/hooks/useMutateUserDetail";
import UserEdit from "components/users/admin/edit/UserEdit";
import { useUserDetail } from "components/users/hooks/useUserDetail";
import MiniLoader from "components/common/ui/loader/MiniLoader";
import { useUpdateSupervisor } from "components/supervisors/hooks/useMutateUpdateSupervisor";

const ViewAdminEditUser = () => {
  const { id } = useParams<{ id: string; orgId: string }>();
  const history = useHistory();
  const { isLoading, data: user } = useUserDetail(id);
  const { mutate, isSuccess, isLoading: isSubmitting } = useMutateUser();
  const { mutateAsync: updateSupervisor, isLoading: isSupervisorSubmitting } =
    useUpdateSupervisor();
  const formattedName = user
    ? `${user?.nickname || user?.given_name} ${user?.family_name}`
    : "User";

  useEffect(() => {
    if (isSuccess) {
      history.push(
        coreService.getRoute(USER_ROUTES.ADMIN_USER_DETAIL, { id: id })
      );
    }
  }, [isSuccess, id, history]);

  const onSave = async ({
    supervisor_select,
    supervisor_status,
    ...newUser
  }: Partial<TUser>) => {
    await mutate({
      newUser,
      oldUser: user,
    });
    if (supervisor_select) {
      await updateSupervisor({
        status: supervisor_status,
        id: user.id,
        supervisor_id: supervisor_select.value,
      });
    }
  };

  const onCancel = () => {
    history.push(coreService.getRoute(USER_ROUTES.ADMIN_USERS));
  };
  return (
    <AppContentContainer
      spacing="none"
      backButton={{
        to: coreService.getRoute(USER_ROUTES.ADMIN_USER_DETAIL, { id: id }),
        label: "Back to User Details",
      }}
    >
      <Meta title={`Edit - ${formattedName}`} />
      <br />
      <br />
      <br />
      <Container>
        <h1 style={{ marginBottom: 0 }}>Edit User</h1>
        {isLoading || !user ? (
          <MiniLoader theme="secondary" />
        ) : (
          <>
            <h2 style={{ marginTop: 0 }}>{formattedName}</h2>
            <UserEdit
              isAdmin={true}
              onUpdateAttributes={onSave}
              onUpdatePermission={() => {}}
              user={user}
              onCancel={onCancel}
              isSubmitting={isSubmitting || isSupervisorSubmitting}
            />
          </>
        )}
      </Container>
    </AppContentContainer>
  );
};

export default ViewAdminEditUser;
