import React from "react";
import { useForm } from "react-hook-form";
import { TUser } from "models/user/User";
import FormSubSection from "components/common/ui/form-elements/form-layout/FormSubSection";
import {
  coreUserQuestionsShowInCreateUser,
  createUserSchema,
} from "models/user/UserForm";
import { userAttributesMap } from "models/user/attributes";
import { defaultValuesCore } from "models/user/attributes/core";
import FormActions from "components/common/ui/form-elements/form-layout/FormActions";

interface Props {
  user?: TUser;
  onSubmit: (attributes: Partial<TUser>) => void;
  onCancel: (attributes: Partial<TUser>) => void;
  title?: string;
  saveText?: string;
  isSubmitting?: boolean;
  children?: React.ReactNode;
}

const UserCreateForm = ({
  onSubmit,
  onCancel,
  title = "Edit User Details",
  saveText = "Update User",
  isSubmitting,
}: Props) => {
  const {
    handleSubmit,
    formState: { errors },
    register,
    control,
  } = useForm({
    mode: "onSubmit",
    defaultValues: defaultValuesCore,
    resolver: createUserSchema,
    // ...defaultSettings,
  });
  const handleSave = async (attributes: Partial<TUser>) => {
    await onSubmit(attributes);
  };

  return (
    <>
      <form onSubmit={handleSubmit(handleSave)} noValidate>
        <FormSubSection
          questionMap={userAttributesMap}
          hookForm={{
            register,
            control,
            errors,
          }}
          key="create"
          // questions={coreUserQuestions }
          questions={coreUserQuestionsShowInCreateUser}
          isAdmin
        />
        <FormActions
          onCancel={onCancel}
          isSubmitting={isSubmitting}
          submitText={saveText}
        />
      </form>
    </>
  );
};

export default UserCreateForm;
