// Modified from https://github.com/jeetiss/tabs
import React, { useRef } from "react";
import cx from "classnames";
import styles from "./Tabs.module.scss";
import { useTabState } from "components/common/ui/tabs/Tabs";
import { useOnMount } from "hooks/useOnMount";

interface TabProp extends DefaultProps {
  children: React.ReactNode;
  onClick?: () => void;
}

export const Tab = ({ children, onClick, className, ...props }: TabProp) => {
  const ref = useRef<HTMLButtonElement>(null);
  const { isActive, onClick: innerStateClick } = useTabState(ref);

  useOnMount(() => {
    if (isActive) {
      handleClick();
    }
  });
  const handleClick = () => {
    onClick ? onClick() : innerStateClick();
  };

  return (
    <button
      {...props}
      ref={ref}
      className={cx(className, styles.tab, isActive && styles.active)}
      onClick={handleClick}
    >
      {children}
    </button>
  );
};
