import React, { useEffect } from "react";
import FormActions from "components/common/ui/form-elements/form-layout/FormActions";
import styles from "./AddRequirement.module.scss";
import { useForm } from "react-hook-form";
import FormQuestion from "components/common/ui/form-elements/form-layout/FormQuestion";
import {
  PathwayCategoryRequirements,
  requirementAttributes,
} from "models/cpd/common/requirements";
import { Category } from "models/cpd/common/common";

interface Props {
  category: Category;
  requirements?: PathwayCategoryRequirements;
  setRequirements: (
    category: Category,
    categoryRequirements: PathwayCategoryRequirements
  ) => void;
  onCancel: () => void;
}

const AddRequirement = ({
  requirements,
  category,
  setRequirements,
  onCancel,
}: Props) => {
  const {
    handleSubmit,
    formState: { errors },
    register,
    control,
    reset,
  } = useForm({
    mode: "onSubmit",
    defaultValues: requirements,
    // resolver: userFormSchema,
  });

  useEffect(() => {
    reset();
  }, [category]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = (data: PathwayCategoryRequirements) => {
    setRequirements(category, data);
  };
  return (
    <div className={styles.root}>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        {requirementAttributes(category).map((g) => (
          <FormQuestion
            key={g.name}
            question={g}
            errors={errors}
            register={register}
            control={control}
          />
        ))}

        <FormActions
          submitText={"Save Requirements"}
          cancelText={"Cancel"}
          onCancel={onCancel}
        />
      </form>
    </div>
  );
};

export default AddRequirement;
