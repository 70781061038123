import React from "react";
import cx from "classnames";
import styles from "./OverlayWaves.module.scss";

interface Props {
  className?: string;
  variant?: string;
}

const OverlayWaves = ({ className }: Props) => (
  <div className={cx(styles.ocean, className)}>
    <Wave className={styles.front} />
    <Wave className={cx(className, styles.front, styles.second)} />
    <Wave className={cx(styles.swell)} />
    <Wave className={cx(styles.swell, styles.second)} />
  </div>
);

const Wave = ({ className }: Props) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 2456 311"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="none"
    className={cx(styles.wave, className)}
  >
    <path
      d="M2456 140.533C2435.74 144.841 2413.64 147.28 2389.23 147.28C2320.08 143.099 2257.89 112 2195.04 80.5664C2125.89 45.9859 2055.94 11 1975 11C1898.11 11 1837.4 44.7443 1776.4 78.6504C1714.81 112.883 1652.92 147.28 1573.79 147.28C1494.77 147.28 1426.7 110.202 1358.86 73.2528C1291.51 36.5623 1224.38 0 1147 0C1069.96 0 1014.34 36.2406 958.282 72.7676C901.331 109.876 843.927 147.28 763.146 147.28C683.609 147.28 616.861 122.591 550.43 98.0188C482.979 73.0692 415.854 48.2405 336 49.5C254.903 50.7791 197.76 73.6555 139.271 97.0707C96.7888 114.078 53.5965 131.369 0 140.876V311H2456V140.533Z"
      fill="url(#overlay_wave_linear_3242135)"
    />
    <defs>
      <linearGradient
        id="overlay_wave_linear_3242135"
        x1="1156"
        y1="0"
        x2="1156"
        y2="311"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0926681" stopColor="#AAE0F2" stopOpacity="0.7" />
        <stop offset="0.669743" stopColor="#1D98D2" stopOpacity="1" />
        <stop offset="0.999255" stopColor="#0075AC" stopOpacity="1" />
      </linearGradient>
      <linearGradient
        id="overlay_wave_linear_alt_3452345"
        x1="1156"
        y1="0"
        x2="1156"
        y2="311"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0926681" stopColor="#75ECE5" stopOpacity="0.7" />
        <stop offset="0.669743" stopColor="#38BCD3" stopOpacity="1" />
        <stop offset="0.999255" stopColor="#137EB1" stopOpacity="1" />
      </linearGradient>
      <linearGradient
        id="overlay_wave_linear_dark_568731"
        x1="1156"
        y1="0"
        x2="1156"
        y2="311"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0926681" stopColor="#4388c4" stopOpacity="0.7" />
        <stop offset="0.669743" stopColor="#3b4ea4" stopOpacity="1" />
        <stop offset="0.999255" stopColor="#1a1947" stopOpacity="1" />
        {/*<stop offset="0.0926681" stopColor="#4199EA" stopOpacity="0.7"/>*/}
        {/*<stop offset="0.669743" stopColor="#2F45B8" stopOpacity="1"/>*/}
        {/*<stop offset="0.999255" stopColor="#1A155D" stopOpacity="1"/>*/}
      </linearGradient>
    </defs>
  </svg>
);

export default OverlayWaves;
