import React from "react";
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import {
  InputWrapper,
  InputWrapperProps,
} from "components/common/ui/form-elements/common/InputWrapper";
import MenuBar from "components/common/ui/form-elements/rich-text-editor/MenuBar";
import styles from "components/common/ui/form-elements/rich-text-editor/RichTextEditor.module.scss";

export interface IRichTextArea extends InputWrapperProps {
  defaultValue?: string;
  value?: string;
  // setValue?: (
  //   name: string,
  //   value: any,
  //   config?: { shouldValidate?: boolean; shouldDirty?: boolean }
  // ) => void;
  setValue?: (value: string) => void;
  rows?: number;
  question?: string;
}

const RichTextEditor = ({
  name,
  label,
  description,
  disabled,
  required,
  className,
  error,
  placeholder,
  defaultValue,
  value = "",
  setValue,
  icon,
  config,
  hint,
  rows = 6,
  question,
  ...rest
}: IRichTextArea) => {
  // const [editorContent, setEditorContent] = useState("");

  const editor = useEditor({
    extensions: [StarterKit],
    content: value,
    onUpdate({ editor }) {
      // setEditorContent(editor.getHTML());
      if (setValue) {
        setValue(editor.getHTML());
      }
    },
  });

  return (
    <div>
      <InputWrapper
        name={name}
        error={error}
        hint={hint}
        label={label}
        description={description}
        disabled={disabled}
        className={className}
        icon={icon}
        config={config}
      >
        <MenuBar editor={editor} />

        <EditorContent
          {...rest}
          editor={editor}
          name={name}
          id={name}
          className={styles.editor}
        />
      </InputWrapper>
    </div>
  );
};

export default RichTextEditor;
