import React from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { CPD_ROUTES } from "constants/routes/cpd";
import { ROUTE_PARAMS } from "constants/routes/common";
import Meta from "components/common/Meta";
import EditPlan from "components/cpd/plan/EditPlan";
import AppContentContainer from "components/common/layouts/appLayout/AppContentContainer";
import { coreService } from "services/CoreService";
import { useSelector } from "react-redux";
import {
  currentUserSelector,
  isAppLoadedSelector,
} from "store/selectors/core.selectors";
import { usePlanDetail } from "components/cpd/hooks/usePlanDetails";
import MiniLoader from "components/common/ui/loader/MiniLoader";
import { useUserDetail } from "components/users/hooks/useUserDetail";
import { checkTPS } from "components/cpd/utils/utils";

const ViewEditPlan = () => {
  const loc = useLocation();
  const { [ROUTE_PARAMS.PLAN]: planId, [ROUTE_PARAMS.USER]: paramsUserId } =
    useParams<{ [ROUTE_PARAMS.PLAN]: string; [ROUTE_PARAMS.USER]?: string }>();
  const history = useHistory();
  const isAppLoaded = useSelector(isAppLoadedSelector);
  const currentUser = useSelector(currentUserSelector);
  const { isLoading: isUserLoading, data: user } = useUserDetail(
    paramsUserId || currentUser.id,
    { enabled: isAppLoaded }
  );
  const { isLoading, data: plan } = usePlanDetail(
    planId,
    paramsUserId || currentUser.id,
    { enabled: isAppLoaded }
  );
  const { isTPS } = checkTPS(loc, plan);

  const onSuccess = async (planId: string, userId: string) => {
    history.push(
      isTPS
        ? coreService.getRoute(CPD_ROUTES.ADMIN_PLAN_DETAIL_TPS, {
            [ROUTE_PARAMS.PLAN]: planId,
            [ROUTE_PARAMS.USER]: userId,
          })
        : coreService.getRoute(CPD_ROUTES.ADMIN_PLAN_DETAIL, {
            [ROUTE_PARAMS.PLAN]: planId,
            [ROUTE_PARAMS.USER]: userId,
          })
    );
  };

  return (
    <AppContentContainer spacing="extra" maxWidth={true}>
      <Meta
        title={`${planId && planId !== "new" ? "Edit" : "Create new"} Plan`}
      />
      {isLoading || isUserLoading || !plan ? (
        <MiniLoader theme="secondary" />
      ) : (
        <EditPlan plan={plan} user={user} onSuccess={onSuccess} />
      )}
    </AppContentContainer>
  );
};

export default ViewEditPlan;
