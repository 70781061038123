import React from "react";
import cx from "classnames";
import styles from "./Container.module.scss";

export interface ContainerProps extends DefaultProps {
  children: any;
  spacing?: "none" | "small" | "normal" | "large" | "extra" | "fullscreen";
  spacingTopOnly?: boolean;
  fullWidth?: boolean;
}
const Container = ({
  children,
  className,
  spacing,
  spacingTopOnly,
  fullWidth,
  ...rest
}: ContainerProps) => (
  <div
    className={cx(
      className,
      styles.container,
      spacing && styles[`spacing-${spacing}`],
      spacingTopOnly && styles.spacingTopOnly,
      fullWidth && styles.fullWidth
    )}
    {...rest}
  >
    {children}
  </div>
);

export default Container;
