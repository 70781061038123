import React from "react";
import cx from "classnames";
import { Goal } from "models/cpd/Goal";
import {
  IconProgressComplete,
  IconProgressStar,
} from "components/common/icons/IconProgress";
import Chip from "components/common/ui/chip/Chip";
import Button from "components/common/ui/button/Button";
import GoalItemActionList from "components/cpd/goal/GoalItemActionList";
import styles from "components/cpd/goal/GoalItem.module.scss";

interface Props extends DefaultProps {
  goal: Goal;
  onClick?: (g: { goal: Goal; editting?: boolean }) => void;
  editable?: boolean;
}

const GoalItem = ({ goal, onClick = () => {}, editable }: Props) => {
  return (
    <button
      onClick={() => onClick({ goal })}
      className={cx(styles.root, editable && styles.editable)}
      disabled={!editable}
    >
      <div className={styles.column}>
        <h4 className={styles.heading}>
          {/*<IconProgressStar />*/}
          {goal.goal_name}
        </h4>
        <GoalItemActionList items={goal?.dev_goals} />
        {/*<div>{formatDate(goal.created_at)}</div>*/}
      </div>

      <div className={styles.rightColumn}>
        {goal.goal_status === "complete" ? (
          <Chip theme="success" icon={<IconProgressComplete />} condensed>
            Completed
          </Chip>
        ) : (
          <Chip theme="info" icon={<IconProgressStar />} condensed>
            In Progress
          </Chip>
        )}
        {editable && (
          <Button
            onClick={(e) => {
              e.stopPropagation();
              onClick({ goal, editting: true });
            }}
            variant="outline"
            theme="primary"
            rounded
          >
            Edit
          </Button>
        )}
        {/*<ActionButton icon={<Icon/>}/>*/}
      </div>
    </button>
  );
};

export default GoalItem;
