import React from "react";
import cx from "classnames";
import styles from "./HintBox.module.scss";

interface Props {
  className?: string;
  theme?: ThemeVariants;
  children: any;
  spacing?: "none" | "small" | "normal" | "large" | "extra" | "fullscreen";
  icon?: React.ReactNode;
}

const HintBox = ({
  children,
  className,
  spacing = "normal",
  theme = "default",
  icon,
}: Props) => (
  <div
    className={cx(
      className,
      styles.container,
      spacing && styles[`spacing-${spacing}`],
      theme && styles[theme],
      icon && styles.iconContainer
    )}
  >
    {icon ? (
      <>
        <div className={styles.icon}>{icon}</div>
        <div> {children}</div>
      </>
    ) : (
      children
    )}
  </div>
);

export default HintBox;
