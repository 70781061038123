import { useMutation, useQueryClient } from "react-query";
import { MutationOptions } from "queries/queries";
import { useToast } from "components/common/ui/toast/useToast";
import { deleteActivityDoc, uploadActivityDoc } from "api/CpdApi";
import { activityQueryKeys } from "queries/constants";

export const useMutateUploadActivityDoc = (options: MutationOptions = {}) => {
  const queryClient = useQueryClient();
  const { addErrorToast, addToast } = useToast();

  return useMutation(
    ({ file, activityId, userId }: useMutateUploadActivityProps) =>
      uploadActivityDoc(file, activityId, userId),
    {
      onSuccess: (result, inputs) => {
        addToast(`File was successfully uploaded`, {
          theme: "success",
        });

        queryClient.invalidateQueries(activityQueryKeys.list());
        queryClient.invalidateQueries(
          activityQueryKeys.detail(inputs.activityId)
        );
      },
      onError: (e) => {
        addErrorToast(e, "Error: Unable to upload file");
      },
      ...options,
    }
  );
};

export const useMutateDeleteActivityDoc = (options: MutationOptions = {}) => {
  const queryClient = useQueryClient();
  const { addErrorToast, addToast } = useToast();

  return useMutation(
    ({ file, activityId, userId }: useMutateDeleteDocProps) =>
      deleteActivityDoc(file, activityId, userId),
    {
      onSuccess: (result, inputs) => {
        addToast(`File was successfully deleted`, {
          theme: "success",
        });

        queryClient.invalidateQueries(activityQueryKeys.list());
        queryClient.invalidateQueries(
          activityQueryKeys.detail(inputs.activityId)
        );
      },
      onError: (e) => {
        addErrorToast(e, "Error: Unable to delete file");
      },
      ...options,
    }
  );
};

interface useMutateDeleteDocProps {
  file: string;
  activityId: string;
  userId: string;
}
interface useMutateUploadActivityProps {
  file: File;
  activityId: string;
  userId: string;
}
