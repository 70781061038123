import { useQuery } from "react-query";
import { peerGroupQueryKeys } from "queries/constants";
import { QueryOptions } from "queries/queries";
import { useToast } from "components/common/ui/toast/useToast";
import { fetchMeetingById } from "api/PeerGroupApi";

export const usePeerGroupMeetingDetail = (
  meetingId: string,
  peergroupId: string,
  options?: QueryOptions
) => {
  const { addErrorToast } = useToast();
  return useQuery(
    [...peerGroupQueryKeys.meetings(peergroupId), meetingId],
    () => fetchMeetingById(meetingId, peergroupId),
    {
      onError: (e) =>
        addErrorToast(e, "Unable to load peer group meeting", {
          id: peerGroupQueryKeys.meetings(meetingId).join("::"),
        }),
      staleTime: 60 * 1000,
      ...options,
    }
  );
};
