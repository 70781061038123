import React, { useEffect, useState } from "react";
import { useController } from "react-hook-form";
import { Control } from "react-hook-form/dist/types/form";
import { UserSearch } from "models/user/User";
import { useUsersSearch } from "components/users/hooks/useUsersList";
import Combobox from "components/common/ui/form-elements/autocomplete/Combobox";
import useDebounce from "hooks/useDebounce";
import { SearchFunction } from "components/common/ui/form-elements/autocomplete/utils";
import { TError } from "components/common/ui/form-elements/formElements";
import { SkeletonTextInput } from "components/common/ui/form-elements/text-input/TextField";
import InputFeedback from "components/common/ui/form-elements/common/InputFeedback";
import HintBox from "components/common/ui/hint-box/HintBox";
import { CombinedUserTypes } from "models/user/attributes/userType";

interface Props extends DefaultProps {
  name: string;
  label?: string;
  description?: string;
  control: Control;
  error?: TError;
  disabled?: boolean;
  user?: UserSearch;
  setUser?: (value: UserSearch) => void;
  defaultValue?: UserSearch | undefined;
}

const defaultFilter = { type: CombinedUserTypes.potentialSupervisor };

const SearchForSupervisor = ({
  name,
  label,
  control,
  children,
  user,
  setUser,
  defaultValue,
  ...props
}: Props) => {
  const [filter, setFilter] = useState<string>("");
  const debouncedFilter = useDebounce(filter, 200);
  const [users, setUsers] = useState<UserSearch[]>([]);
  const { isLoading, data, isSuccess } = useUsersSearch(
    debouncedFilter
      ? { ...defaultFilter, search: debouncedFilter }
      : defaultFilter,
    true
  );

  const {
    field: { onChange, value },
  } = useController({
    name,
    control,
    defaultValue: defaultValue,
    rules: { required: true },
  });

  const onSelect = (item?: UserSearch) => {
    onChange(item);
    if (setUser) {
      setUser(item ?? { value: "", searchValue: "", label: "" });
    }
  };
  const onSearch: SearchFunction<UserSearch> = (inputValue = "") => {
    setFilter(inputValue);
  };

  const approvedToSupervise = data?.find(
    (d: UserSearch) => d?.value === value?.value
  )?.metadata?.approvedToSupervise;

  useEffect(() => {
    if (!isLoading) {
      setUsers(data);
    }
  }, [data, isLoading, value]);

  return !isSuccess && users.length === 0 ? (
    <>
      <SkeletonTextInput label={label || "Search for person"} />
      <InputFeedback name={name} />
    </>
  ) : (
    <>
      <Combobox
        items={users}
        selected={value}
        onSelect={onSelect}
        onSearch={onSearch}
        name={name}
        label={label || "Search for person"}
        {...props}
      />
      {value?.value && !approvedToSupervise && (
        <HintBox theme="warning">
          <b>Warning:</b> The selected user isn't currently approved to
          supervise
        </HintBox>
      )}
    </>
  );
};

export default SearchForSupervisor;
