import React, { useEffect, useState } from "react";
import { DatePicker } from "components/common/ui/form-elements/date-picker/DatePicker";
import Select from "components/common/ui/select/Select";
import Button from "components/common/ui/button/Button";
import Requirements from "components/cpd/common/Requirements";
import {
  createPlan,
  getPlanYears,
  Plan,
  PLAN_STATUS,
  planPathwayOptions,
  planStatusOptions,
  PlanYear,
} from "models/cpd/Plan";
import { formatDate } from "utils/format/dateUtils";
import {
  PATHWAY,
  pathwayDefaultRequirements,
} from "models/cpd/common/requirements";
import Chip from "components/common/ui/chip/Chip";
import HintBox from "components/common/ui/hint-box/HintBox";
import IconArrow from "components/common/icons/IconArrow";
import FormActions from "components/common/ui/form-elements/form-layout/FormActions";
import { IFacetOption } from "models/Dimension";
import TextInput from "components/common/ui/form-elements/text-input/TextField";
import { ShowIf } from "components/common/ui/form-elements/common/ConditionalLogicWrapper";
import { TUser } from "models/user/User";
import { useMutatePlan } from "components/cpd/hooks/useMutatePlan";
import IconLock from "components/common/icons/IconLock";
import EditCustomRequirements from "components/cpd/plan/requirements/EditCustomRequirements";
import PageHeader from "components/common/layouts/appLayout/PageHeader";
import styles from "./EditPlan.module.scss";

interface Props extends DefaultProps {
  plan?: Plan;
  user: TUser;
  onSuccess: (planId: string, userId: string) => void;
}

const EditPlan = ({ plan, user, onSuccess }: Props) => {
  const [newPlan, setPlan] = useState<Plan>(createPlan(plan));
  const [duration, setDuration] = useState<Duration>(
    getDuration(plan?.duration)
  );
  const { mutate, isSuccess, data, isLoading: isSubmitting } = useMutatePlan();

  const years = mergeYears(
    getPlanYears(newPlan.started_at, newPlan.duration),
    newPlan.progress?.years
  );
  const durationErrors =
    newPlan.duration < 1
      ? { message: "Duration must be at least 1 month" }
      : newPlan.duration % 1
      ? { message: "Duration must be whole months" }
      : undefined;

  const editableRequirements =
    newPlan.pathway === "special_circumstance" || newPlan.pathway === "ITAP";

  const update = (newAttr: TObjectAny) => {
    setPlan((s) => ({ ...s, ...newAttr }));
  };

  const updateDuration = (d: string, custom: "true" | "false") => {
    let value = d;
    // if the `custom` option is selected in the duration select
    // then reuse existing value, but trigger the custom input to open
    if (d === "custom") {
      value = duration.value;
      setDuration({ ...customDuration, value: duration.value, custom: "true" });
    } else {
      const option = durationOptions.find((o) => o.key === d);
      // if option is found then update the duration select,
      // but keep the custom input open if it's the triggering element
      if (option) {
        value = option.value;
        setDuration({ ...option, custom });
      } else {
        //if option not found then use the custom option
        setDuration({ ...customDuration, value: d, custom });
      }
    }
    // regardless of all the other logic, update the actual plan duration with the new value
    update({ duration: +value });
  };

  const handleSubmit = () => {
    if (!durationErrors) {
      mutate({
        newPlan: {
          ...newPlan,
          progress: {
            ...newPlan.progress,
            years,
          },
        },
        oldPlan: plan,
        userId: user.id,
      });
    }
  };

  useEffect(() => {
    const id = plan?.plan_id || data?.plan_id;
    if (isSuccess && id) {
      onSuccess(id, user.id);
    }
  }, [isSuccess, data, plan, user, onSuccess]);

  return (
    <>
      <PageHeader
        header={`${plan?.plan_id ? "Edit" : "Create new"} Plan`}
        children={
          <h2>
            {user?.given_name} {user?.family_name}
          </h2>
        }
        spacing="fullscreen"
        spacingTopOnly
        fullWidth
      />

      <br />
      <form>
        <div className={styles.row}>
          <Select
            name={"pathway"}
            value={newPlan.pathway}
            onSelect={(d, v: PATHWAY) =>
              update({
                pathway: v,
                requirements: pathwayDefaultRequirements[v],
              })
            }
            label={"Pathway"}
            options={planPathwayOptions}
            config={{
              inputChip: true,
              selectedOptionFormat: "chip",
              // chipVariant:"empty"
            }}
          />
          <Select
            name={"plan_status"}
            value={newPlan.plan_status}
            onSelect={(d, v: PLAN_STATUS) => update({ plan_status: v })}
            label={"Plan Status"}
            options={planStatusOptions}
            config={{
              inputChip: true,
              selectedOptionFormat: "chip",
              // chipVariant:"empty"
            }}
          />
          <Select
            name={"duration_option"}
            value={duration.key}
            onSelect={(d, v: string) => updateDuration(v, "false")}
            label={"Duration"}
            options={durationOptions}
            config={{
              inputChip: true,
              selectedOptionFormat: "chip",
              // chipVariant:"empty"
            }}
          />
          <ShowIf value={duration.custom || ""} logic={(v) => v === "true"}>
            <TextInput
              type="number"
              label={"Duration (in months)"}
              name={"duration"}
              value={duration.value}
              onChange={(v: any) => {
                updateDuration(v.target.value, "true");
              }}
              error={durationErrors}
            />
          </ShowIf>
        </div>
        <div className={styles.row}>
          <DatePicker
            name={"started_at"}
            label={"Start Date"}
            // description={"This date should line up with your fellowship start date"}
            value={newPlan.started_at}
            onChange={(d) => update({ started_at: d })}
          />
          <TextInput
            className={styles.endInput}
            label={"End Date"}
            name={"duration"}
            // value={formatDate(
            //   subDays(addMonths(newPlan.started_at, newPlan.duration), 1)
            // )}
            value={formatDate(years[years.length - 1]?.end)}
            disabled
          />
          <TextInput
            className={styles.endInput}
            label={"Annual cycles"}
            name={"annual"}
            value={`${Math.ceil(newPlan.duration / 12)} annual cycles`}
            disabled
          />
        </div>
      </form>

      <br />
      <hr />
      <HintBox theme="primary">
        <b>Annual Cycles:</b>
        <ul>
          {years.map((y) => (
            <li className={styles.yearRow} key={y.year}>
              {y.locked ? (
                <IconLock className={styles.icon} />
              ) : (
                <span className={styles.icon} />
              )}
              <span>
                <b>Year {y.year}</b> : &nbsp;
              </span>
              <Chip condensed>{formatDate(y.start)}</Chip> <IconArrow />
              <Chip condensed>{formatDate(y.end)}</Chip>
            </li>
          ))}
        </ul>
      </HintBox>
      <br />

      <h3>Requirements</h3>
      {editableRequirements && (
        <EditCustomRequirements
          requirements={newPlan.requirements}
          setRequirements={(req) =>
            setPlan((p) => ({ ...p, requirements: req }))
          }
        />
      )}

      <Requirements
        requirements={newPlan.requirements}
        setRequirements={(req) => setPlan((p) => ({ ...p, requirements: req }))}
        editable={editableRequirements}
      />

      <FormActions>
        {/*<Button*/}
        {/*  onClick={() => setTabState((s) => s - 1)}*/}
        {/*  disabled={tabState === 0}*/}
        {/*  variant="empty"*/}
        {/*>*/}
        {/*  Back*/}
        {/*</Button>*/}
        <Button
          onClick={handleSubmit}
          submitting={isSubmitting}
          theme={"success"}
          rounded
        >
          {plan?.plan_id ? "Save" : "Create"} Plan
        </Button>
      </FormActions>
    </>
  );
};

const mergeYears = (newYears: PlanYear[], oldYears: PlanYear[] = []) =>
  newYears.map((y, i) => ({
    ...y,
    locked: oldYears[i]?.locked || false,
    // locked: y.year === 1 ? true : false,
  }));

const getDuration = (
  d: number | string | undefined,
  defaultValue: string = "36"
) => {
  const value = d ? `${d}` : defaultValue;
  const option = durationOptions.find((o) => o.key === value);
  return (
    option || {
      ...customDuration,
      value,
    }
  );
};

interface Duration extends IFacetOption {
  value: string;
  custom?: "true" | "false";
}

const customDuration = { key: "custom", label: "Custom duration", value: `0` };

const durationOptions: Duration[] = [
  { key: "3", label: "3 Months", value: `3` },
  { key: "6", label: "6 Months", value: `6` },
  { key: "12", label: "1 Year", value: `12` },
  { key: "24", label: "2 Years", value: `24` },
  { key: "36", label: "3 Years", value: `36` },
  { key: "48", label: "4 Years", value: `48` },
  customDuration,
];

export default EditPlan;
