import React from "react";
import cx from "classnames";
import { Page as IPage, HookFormProperties } from "models/common/Form";
import { DataAttribute } from "components/common/ui/form-elements/formElements";
import ConditionalLogicWrapper from "components/common/ui/form-elements/hook-form-elements/ConditionalLogicWrapperHookForm";
import { createSubSection } from "components/common/ui/form-elements/form-layout/FormSubSection";
import Accordian from "components/common/ui/accordian/Accordian";
import styles from "components/common/ui/form-elements/form-layout/Section.module.scss";

interface Props<Def extends DataAttribute = DataAttribute>
  extends IPage<Def>,
    DefaultProps {
  isAdmin?: boolean;
  questionMap: Record<string, Def>;
  hookForm: HookFormProperties;
}
const FormSection = <T extends DataAttribute = DataAttribute>({
  title,
  intro,
  sections,
  hookForm,
  questionMap,
  isAdmin,
  conditional,
  className,
  ...rest
}: Props<T>) => {
  const subSectionElements = sections.map((s, i) =>
    createSubSection({ ...s, hookForm, questionMap, isAdmin, key: i })
  );

  return subSectionElements.filter(Boolean).length > 0 ? ( // if no SubSection in Section then don't draw it
    <ConditionalLogicWrapper
      control={hookForm.control}
      conditional={conditional}
      className={cx(styles.root, className)}
      {...rest}
    >
      <Accordian header={<h3 className={styles.title}>{title}</h3>} isOpen>
        {intro && <p>{intro}</p>}
        <div>{subSectionElements}</div>
      </Accordian>
    </ConditionalLogicWrapper>
  ) : null;
};

export default FormSection;
