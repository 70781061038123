import { verifyPermisisons } from "utils/authUtils";
import { CombinedUserTypes, UserType } from "models/user/attributes/userType";

enum CognitoGroups {
  admin = "admin",
  adminReader = "admin_reader",
  superuser = "superadmin",
  disabled = "__disabled__",
}

export type PERMISSIONS = CognitoGroups | UserType;
export const Permissions = { ...CognitoGroups, ...UserType };

const adminEdit = [Permissions.admin, Permissions.superuser];
export const PermissionGroups = {
  adminEdit,
  adminRead: [...adminEdit, Permissions.adminReader],
  ...CombinedUserTypes,
  disabled: [Permissions.disabled],
};

export type TRequiredPermission = PERMISSIONS | PERMISSIONS[];
interface TPermissionOption {
  label: string;
  description: string;
  default?: boolean;
  readonly?: boolean;
}
export interface TPermissionOptionArrayItem extends TPermissionOption {
  value: PERMISSIONS;
}
export interface TPermissionGroup {
  label: string;
  description?: string;
  options: TPermissionOptionArrayItem[];
  readonly?: boolean;
}

//---------------------------------

const options = {
  [CognitoGroups.admin]: {
    label: "Admin",
    description:
      "Admin users have extensive additional access to data & information. They can manage users, facilities, plans",
  },
  [CognitoGroups.adminReader]: {
    label: "Admin Reader",
    description:
      "Admin Readers have read-only access to all the pages that 'Admin' do, but are unable to edit anything",
  },

  [CognitoGroups.superuser]: {
    label: "Superuser",
    description: "Do everything in the platform",
    readonly: true,
  },
} as Record<PERMISSIONS, TPermissionOption>;

export const permissionsTable = [
  // {
  //   label: "Insights Dashboard - All Hospices",
  //   description:
  //     "Grants access to the views across all hospices in the Insights Dashboard",
  //   options: [
  //     getPermission(PERMISSIONS.SUPERUSER_INSIGHTS_QI),
  //     getPermission(PERMISSIONS.SUPERUSER_INSIGHTS_SYSTEM_VOLUME),
  //   ],
  // },
  {
    label: "Admin",
    description:
      "Grants access to the Admin section where users can be created and managed",
    options: [getPermission(CognitoGroups.admin)],
  },
  {
    label: "Super Admin",
    description:
      "Special user type that grants access to all data. This is managed by Synergia & is not modifiable via the UCCIS platform.",

    options: [getPermission(CognitoGroups.superuser)],
    readonly: true,
  },
];

export const permissionsOptions = Object.values(Permissions).map((o) =>
  getPermission(o)
);

//---------------------------------

function getPermission(option: PERMISSIONS): TPermissionOptionArrayItem {
  return { value: option, ...options[option] };
}

export const getDefaultPermissions = (
  roles?: PERMISSIONS[]
): Record<string, boolean> => {
  return permissionsOptions.reduce(
    (obj, p) => ({
      ...obj,
      [p.value]: roles ? verifyPermisisons(roles, p.value) : p.default,
    }),
    {}
  );
};

export const stripReadOnlyPermissions = (permissions?: PERMISSIONS[]) =>
  permissions && permissions.filter((p) => !options[p].readonly);
