import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { deepObjectAccessor, keyBy } from "utils/common";
import {
  ConditionalFieldObject,
  Conditional,
  ConditionalRestriction,
  ConditionalFieldRestriction,
  DataAttribute,
} from "components/common/ui/form-elements/formElements";

type Questions = Record<string, any>;
type Restriction =
  | ConditionalRestriction<string>
  | ConditionalFieldRestriction<string>;

export const getSchema = (questions: Questions) => {
  return yupResolver(Yup.object().shape(questions));
};

const getFieldKey = (field: string, fieldPrefix?: string) =>
  fieldPrefix && !field.includes(fieldPrefix)
    ? `${fieldPrefix}.${field}`
    : field;

export const getConditionalKeys = <F>(conditional?: Conditional<F>) => {
  if (!conditional) {
    return [];
  } else if (Array.isArray(conditional)) {
    return conditional.map((c) => c.field);
  }

  const { OR = [], NOT = {}, AND = {} } = conditional;
  return Array.from(
    new Set([
      ...OR.reduce((k: string[], d) => k.concat(Object.keys(d)), []),
      ...Object.keys(NOT),
      ...Object.keys(AND),
    ])
  );
};

const checkConditional = (q: Questions, c: Restriction, k: string) => {
  const v = q[k] || deepObjectAccessor(q, k);
  const test = conditionalFormLogic(v, c);
  return c.invert ? !test : test;
};

const checkConditionalObject = (
  questions: Questions,
  conditional?: ConditionalFieldObject,
  fieldPrefix?: string
) =>
  !conditional ||
  Object.entries(conditional).every(([k, c]) =>
    checkConditional(questions, c, getFieldKey(k, fieldPrefix))
  );

export const checkConditionals = (
  questions: Questions,
  conditional?: Conditional<string>,
  fieldPrefix?: string
) => {
  if (!conditional) {
    return !conditional;
  } else if (Array.isArray(conditional)) {
    return conditional?.every((c) =>
      checkConditional(questions, c, getFieldKey(c.field, fieldPrefix))
    );
  } else {
    const { OR, NOT, AND } = conditional;

    return (
      checkConditionalObject(questions, AND, fieldPrefix) &&
      (!NOT || !checkConditionalObject(questions, NOT, fieldPrefix)) &&
      (!OR || OR.some((c) => checkConditionalObject(questions, c, fieldPrefix)))
    );
  }

  // !conditional ||
  // conditional?.every((c) => {
  //   const v = deepObjectAccessor(questions, c.field);
  //   return conditionalFormLogic(v, c);
  // });
};

export const conditionalFormLogic = (
  v: any,
  // conditional: ConditionalRestriction<string>
  conditional: Restriction
) => {
  if ("values" in conditional) {
    switch (typeof v) {
      case "boolean":
        return v;
      case "string":
        return conditional.values.some((c) => v === c);
      case "object":
        if (Array.isArray(v) && v.length > 0) {
          return conditional.values.some((c) => v.includes(c));
        }
        return false;
      default:
        return false;
    }
  }
  return conditional.showIfEmpty ? !v : !!v;
};

export const getDefaultValues = <T extends string = string>(
  questions: DataAttribute<T>[],
  parentKey?: string
) => {
  return keyBy(
    questions,
    (d) => (parentKey ? `${parentKey}.${d.name}` : d.name),
    (d) => d.defaultValue
  );
};

export const getValidations = <T extends string = string>(
  questions: DataAttribute<T>[],
  parentKey?: string
) => {
  return keyBy(
    questions,
    (d) => (parentKey ? `${parentKey}.${d.name}` : d.name),
    (d) => d.validation
  );
};
