import React, { useState } from "react";
import Meta from "components/common/Meta";
import { useHistory } from "react-router";
import { AppContentContainer } from "components/common/layouts/appLayout/AppContentContainer";
import { TUser } from "models/user/User";
import { USER_ROUTES } from "constants/routes/user";
import Container from "components/common/layouts/Container";
import { coreService } from "services/CoreService";
import UserEdit from "components/users/admin/edit/UserEdit";
import MiniLoader from "components/common/ui/loader/MiniLoader";
import { useSelector } from "react-redux";
import { currentUserSelector } from "store/selectors/core.selectors";
import { authService } from "services/AuthService";
import {
  userFieldsKey,
  userOfficeOnlyKey,
  userTypeKey,
} from "models/user/attributes/common";
import { updateCurrentUser } from "api/UserApi";

const ViewAdminEditUser = () => {
  const [isSubmitting, setSubmitting] = useState(false);
  const history = useHistory();
  const user = useSelector(currentUserSelector);
  // const { mutate, isSuccess, isLoading: isSubmitting } = useMutateUser();

  const onSave = async ({
    [userOfficeOnlyKey]: officeOnlyFields, //remove office only fields
    approved_to_supervise,
    supervisor_approval_date,
    [userTypeKey]: userType,
    [userFieldsKey]: fields,
    facilities,
    ...newUser
  }: Partial<TUser>) => {
    try {
      setSubmitting(true);
      await authService.updateCurrentUserAttributes(newUser);
      await updateCurrentUser(
        { id: newUser.id, [userFieldsKey]: fields, facilities },
        user
      );
      setSubmitting(false);
      await coreService.getCurrentUser();
      history.push(coreService.getRoute(USER_ROUTES.MY_PROFILE));
    } catch (e) {
      setSubmitting(false);
    }
  };

  const onCancel = () => {
    history.push(coreService.getRoute(USER_ROUTES.MY_PROFILE));
  };
  return (
    <AppContentContainer
      spacing="none"
      backButton={{
        to: coreService.getRoute(USER_ROUTES.MY_PROFILE),
        label: "Back to My Profile",
      }}
    >
      <Meta title={`Edit My Details`} />
      <br />
      <br />
      <br />
      <Container>
        <h1 style={{ marginBottom: 0 }}>Update My Details</h1>
        {!user ? (
          <MiniLoader theme="secondary" />
        ) : (
          <UserEdit
            isAdmin={false}
            onUpdateAttributes={onSave}
            onUpdatePermission={() => {}}
            user={user}
            onCancel={onCancel}
            isSubmitting={isSubmitting}
          />
        )}
      </Container>
    </AppContentContainer>
  );
};

export default ViewAdminEditUser;
