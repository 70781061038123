type KeyAccessor = (d: any, i?: number, v?: any) => string | number;
type Reducer = (d: any, i: number, key?: string, map?: TObjectAny) => any;

export const keyBy = (
  collection: any[] | TObjectAny = [],
  key?: string | KeyAccessor,
  reducerFunction?: string | Reducer
) =>
  Array.isArray(collection)
    ? mapReduce(collection, key, reducerFunction)
    : mapReduce(Object.values(collection), key, reducerFunction);

export const mapReduce = (
  collection: any[],
  keyAccessor?: string | KeyAccessor,
  reducerFunction: string | Reducer = defaultReducer
) =>
  (collection || []).reduce((m, x, i) => {
    const k = keyAccessor
      ? typeof keyAccessor === "string"
        ? x[keyAccessor]
        : keyAccessor(x, i)
      : x;

    const v =
      typeof reducerFunction === "string"
        ? x[reducerFunction]
        : reducerFunction(x, i, k, m);

    return {
      ...m,
      [k]: v,
    };
  }, {});

const defaultReducer: Reducer = (d) => d;

export const cleanOrderedLabel = (label: string | number): string => {
  const s = `${label}`;
  let parts = s.split("@");
  if (parts.length === 1) {
    return s;
  }
  parts.shift();
  return parts.join("@");
};

export const generateUEID = () => {
  let first: string | number = (Math.random() * 46656) | 0;
  let second: string | number = (Math.random() * 46656) | 0;
  first = ("000" + first.toString(36)).slice(-3);
  second = ("000" + second.toString(36)).slice(-3);
  return first + second;
};

export const generateTimestampID = (id?: string, frequency: number = 1500) =>
  `${id || generateUEID()}::${Math.round(Date.now() / frequency)}`; // generates a different timestamp approx every 1.5-2 seconds

export const deepObjectAccessor = (obj: TObjectAny, keyString: string): any => {
  return keyString.split(".").reduce((o, k) => o?.[k], obj);
};

export const deepObjectConstructor = (
  obj: TObjectAny,
  keyString: string,
  nestedValue: any = []
) => {
  const parts = keyString.split(".");
  keyString.split(".").reduce((o, k, i) => {
    if (!o?.[k]) {
      o[k] = i < parts.length - 1 ? {} : nestedValue;
    }
    return o?.[k];
  }, obj);
  return obj;
};

export const flattenDeepObject = (
  obj: TObjectAny,
  parentKey?: string,
  reducerFunction: Reducer = defaultReducer
): Record<string, any> => {
  return Object.keys(obj).reduce((f, k, i) => {
    const item = obj[k];
    const key = parentKey ? `${parentKey}.${k}` : k;
    if (isObject(item)) {
      return {
        ...f,
        ...flattenDeepObject(item, key),
      };
    }
    return { ...f, [key]: reducerFunction(item, i, k, f) };
  }, {} as Record<string, any>);
};

export const isObject = function (a: any): a is TObjectAny {
  return !!a && a.constructor === Object;
};

export const isPlainObject = <T = unknown, K extends string | number = string>(
  v: unknown
): v is Record<K, T> =>
  typeof v === "object" &&
  v !== null &&
  v.constructor === Object &&
  Object.getPrototypeOf(v) === Object.prototype;

export const removeUndefinedFromObject = <Datum extends TObjectAny>(
  obj: Datum
): Partial<Datum> => {
  if (isObject(obj)) {
    const clean = { ...obj };
    let k: keyof typeof clean;
    for (k in clean) {
      if (clean[k] === undefined) {
        delete clean[k];
      }
    }
    return clean;
  }
  return obj;
};

export const getLastItem = <Datum extends any>(
  arr: Datum[] = []
): Datum | undefined => arr[arr.length - 1];

export const nest = <Datum extends TObjectAny>(
  collection: Datum[],
  keys: (keyof Datum | ((d: Datum, i?: number) => string))[],
  reducerFunction: string | ((d: Datum, i: number) => any | keyof Datum) = (
    d
  ) => d,
  undefinedKey: string = "__undefined__"
) =>
  collection.reduce((r: Record<string, any>, d, i) => {
    const nestedKey = keys
      .map(
        (k) =>
          (k
            ? typeof k === "string" ||
              typeof k === "number" ||
              typeof k === "symbol"
              ? d[k]
              : k(d, i)
            : undefinedKey) || undefinedKey
      )
      .join(".");
    const newRecord = deepObjectConstructor(r, nestedKey);
    const list = deepObjectAccessor(newRecord, nestedKey);
    list.push(
      typeof reducerFunction === "string"
        ? d[reducerFunction]
        : reducerFunction(d, i)
    );
    return newRecord;
  }, {});

export const parseAttribute = <A extends unknown>(attr: A | string): A =>
  typeof attr === "string" ? JSON.parse(attr) : attr;
