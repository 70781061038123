import React, { useState } from "react";
import Meta from "components/common/Meta";
import { AppContentContainer } from "components/common/layouts/appLayout/AppContentContainer";
import PlanDetail from "components/cpd/plan/PlanDetail";
import { CPD_ROUTES } from "constants/routes/cpd";
import { ROUTE_PARAMS } from "constants/routes/common";
import { useLocation, useParams } from "react-router";
import { usePlanDetail } from "components/cpd/hooks/usePlanDetails";
import { useSelector } from "react-redux";
import {
  currentUserSelector,
  isAppLoadedSelector,
} from "store/selectors/core.selectors";
import { useActivityList } from "components/cpd/hooks/useActivityList";
import MiniLoader from "components/common/ui/loader/MiniLoader";
import { coreService } from "services/CoreService";
import Button from "components/common/ui/button/Button";
import PageHeader from "components/common/layouts/appLayout/PageHeader";
import Modal from "components/common/ui/modal/Modal";
import { useGoalList } from "components/cpd/hooks/useGoalList";
import Requirements from "components/cpd/common/Requirements";
import { formatDate } from "utils/format/dateUtils";
import Goals from "components/cpd/goal/Goals";
import { EditPlanButton } from "components/cpd/plan/AdminPlanButtons";
import { UserContext } from "components/cpd/context/UserContext";
import { useUserDetail } from "components/users/hooks/useUserDetail";
import IconExternalLink from "components/common/icons/IconExternalLink";
import { planPathwayOptions } from "models/cpd/Plan";
import { checkTPS } from "components/cpd/utils/utils";

interface Props {
  userId?: string;
  planId?: string;
}

const ViewCPDPlanDetail = (props: Props) => {
  const isAppLoaded = useSelector(isAppLoadedSelector);
  const currentUser = useSelector(currentUserSelector);
  const loc = useLocation();
  const {
    [ROUTE_PARAMS.PLAN]: paramsPlanId,
    [ROUTE_PARAMS.USER]: paramsUserId,
  } =
    useParams<{ [ROUTE_PARAMS.PLAN]: string; [ROUTE_PARAMS.USER]?: string }>();

  const { data: paramsUser } = useUserDetail(paramsUserId || "", {
    enabled: !!paramsUserId,
  });

  const [showReq, setShowReq] = useState(false);
  const [showGoals, setShowGoals] = useState(false);

  const userId = props.userId || paramsUserId || currentUser.id;

  const { isLoading: isPlanLoading, data: plan } = usePlanDetail(
    props.planId || paramsPlanId,
    userId,
    { enabled: isAppLoaded }
  );
  const { isLoading: isActivityLoading, data: activity } = useActivityList(
    plan?.plan_id,
    userId,
    plan?.progress?.years,
    { enabled: !!plan?.plan_id }
  );
  const { isLoading: isGoalsLoading, data: goals } = useGoalList(userId, {
    enabled: isAppLoaded,
  });
  const { isPathwayTPS, isTPSLocation } = checkTPS(loc, plan);

  // const { mutate: deleteActivity } = useDeleteActivity();

  const isLoading =
    isPlanLoading || isActivityLoading || isGoalsLoading || !isAppLoaded;

  const pathway = planPathwayOptions.find((p) => p.key === plan?.pathway);

  return (
    <AppContentContainer
      backButton={
        isTPSLocation
          ? {
              to: coreService.getRoute(
                paramsUserId
                  ? CPD_ROUTES.ADMIN_USER_TPS
                  : CPD_ROUTES.MY_TPS_ALL,
                { [ROUTE_PARAMS.USER]: userId }
              ),
              label: "Back to TPS list",
            }
          : {
              to: coreService.getRoute(
                paramsUserId
                  ? CPD_ROUTES.ADMIN_USER_PLANS
                  : CPD_ROUTES.MY_PLANS_ALL,
                { [ROUTE_PARAMS.USER]: userId }
              ),
              label: "Back to Plans",
            }
      }
      spacing="none"
      // maxWidth={true}
    >
      <Meta
        title={isPathwayTPS ? "Training Programme Summary" : "Plan Detail"}
      />
      <PageHeader
        spacing="extra"
        spacingTopOnly
        header={
          isPathwayTPS
            ? "Training Programme Summary"
            : "Continuing Professional Development"
        }
        children={
          paramsUser ? (
            <h2>
              {paramsUser?.given_name} {paramsUser?.family_name}
            </h2>
          ) : (
            ""
          )
        }
        actions={
          isLoading
            ? []
            : [
                <>
                  <EditPlanButton plan={plan} />
                  <Button
                    theme="primary"
                    variant="outline"
                    onClick={() => setShowReq(true)}
                  >
                    View Requirements
                  </Button>
                  <Button
                    theme="secondary"
                    variant="outline"
                    // to={`${coreService.getRoute(CPD_ROUTES.MY_GOALS)}`}
                    onClick={() => setShowGoals(true)}
                  >
                    View Goals
                  </Button>
                </>,
              ]
        }
      />

      {isLoading || !plan ? (
        <MiniLoader theme="secondary" />
      ) : (
        <>
          <UserContext.Provider
            value={{ user: paramsUserId ? paramsUser : currentUser }}
          >
            <PlanDetail
              plan={plan}
              goals={goals}
              activity={activity || []}
              userId={paramsUserId || currentUser.id}
              isPathwayTPS={isPathwayTPS}
            />
          </UserContext.Provider>

          <Modal open={showReq} onClose={() => setShowReq(false)}>
            <h1 style={{ marginBottom: 0 }}>Requirements</h1>
            <b>
              Pathway:{" "}
              <Button
                variant="empty"
                theme="secondary"
                style={{ display: "inline-flex", alignItems: "center" }}
                href={pathway?.url}
                target="_blank"
              >
                {pathway?.label}&nbsp;
                <IconExternalLink />
              </Button>
            </b>
            <i>
              The table below describes your requirements between{" "}
              {formatDate(plan?.started_at)} and{" "}
              {formatDate(
                plan?.progress?.years[plan?.progress?.years?.length - 1]?.end
              )}
              .
              <br />
              Annual requirements must be completed each year, while Programme
              requirements are tracked over the course of the entire CPD cycle
            </i>
            <br />
            <Requirements
              setRequirements={() => undefined}
              requirements={plan?.requirements}
            />
            <p>
              Need to take a break from urgent care clinical practice and pause
              activities?{" "}
              <a
                href={
                  isPathwayTPS
                    ? "https://rnzcuc.org.nz/completing-the-training-programme/pausing-training/"
                    : "https://rnzcuc.org.nz/recertification-2021/pausing-recertification/"
                }
                target="_blank"
                rel="noopener noreferrer"
                className="link"
              >
                Click here for more information{" "}
                <IconExternalLink
                  style={{ fontSize: 16, marginBottom: -2.5 }}
                />
              </a>
            </p>
          </Modal>
          <Modal open={showGoals} onClose={() => setShowGoals(false)}>
            <Goals goals={goals} userId={userId} />
          </Modal>
        </>
      )}
    </AppContentContainer>
  );
};

export default ViewCPDPlanDetail;
