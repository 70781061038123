import Chip from "components/common/ui/chip/Chip";
import React from "react";
import { Activity } from "models/cpd/Activity";
import { ActivityEditState } from "components/cpd/activity/ActivityDetail";
import CategoryIcon from "components/cpd/common/CategoryIcon";
import { formatDate } from "utils/format/dateUtils";
import { getCategoryLabel } from "components/cpd/utils/utils";

interface Props extends DefaultProps {
  activity: Activity[];
  setActiveActivity: (S: ActivityEditState | undefined) => void;
}

const OverdueActivity = ({ activity, setActiveActivity }: Props) => {
  return (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      {activity.map((a) => (
        <Chip
          key={a.id}
          theme={a.completed ? "warning" : "danger"}
          onClick={() =>
            setActiveActivity({
              activity: a,
              editing: false,
            })
          }
          icon={a.category && <CategoryIcon type={a.category} />}
          condensed
          style={{ margin: "0 4px 4px 0" }}
        >
          {a.topic || getCategoryLabel(a)} -&nbsp;
          <i>
            {" "}
            due {formatDate(a.required_by)} (Year {a.year})
          </i>
        </Chip>
      ))}
    </div>
  );
};

export default OverdueActivity;
