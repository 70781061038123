import { ROUTE_PARAMS, TRouteParamOptions } from "constants/routes/common";

export const setRouteParams = (
  path: string,
  defaults: Partial<TRouteParamOptions>,
  match?: Partial<TRouteParamOptions>
) =>
  Object.values(ROUTE_PARAMS).reduce(
    (p, key) => p.replace(`:${key}`, match?.[key] || defaults[key] || ""),
    path
  );
