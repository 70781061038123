import React from "react";
import cx from "classnames";
import styles from "components/common/ui/table/ResponsiveTable.module.scss";

export interface IRow {
  [key: string]: any;
}

export interface IColumn<Datum = IRow> {
  id: string;
  width?: number | string;
  accessor?: (row: Datum, column: IColumn<Datum>) => string;
  label?: React.ReactNode;
  header?: (column: IColumn<Datum>) => React.ReactNode;
  cell?: (cell: string, row: Datum, column: IColumn<Datum>) => React.ReactNode;
  className?: string;
  headerClassname?: string;
  type?: "icon" | "text" | "textEmphasis";
  style?: React.CSSProperties;
  loadingCell?: (column: IColumn<Datum>, index: number) => React.ReactNode;
}
// Create a default prop getter
export const defaultPropGetter = () => ({});
export const defaultColPropGetter = (c: IColumn) => ({ style: c.style });

export const getColumnClasses = (c: IColumn) =>
  cx(
    styles.column,
    c.type === "icon" && styles.iconCol,
    c.type === "textEmphasis" && styles.emphasisCol
  );
