import React, { useState } from "react";
import { createGoal, Goal } from "models/cpd/Goal";
import Modal from "components/common/ui/modal/Modal";
import Button from "components/common/ui/button/Button";
import GoalItem from "components/cpd/goal/GoalItem";
import PageHeader from "components/common/layouts/appLayout/PageHeader";
import GoalDetail from "components/cpd/goal/GoalDetail";
import styles from "components/cpd/goal/Goals.module.scss";

interface Props extends DefaultProps {
  goals: Goal[];
  userId: string;
  editable?: boolean;
  lockCreateGoal?: boolean;
}

const Goals = ({
  goals,
  userId,
  editable = true,
  lockCreateGoal = false,
}: Props) => {
  const [activeGoal, setActiveGoal] = useState<
    { goal: Goal; editting?: boolean } | undefined
  >(undefined);

  return (
    <div className={styles.root}>
      <PageHeader
        header="Professional Development Goals"
        actions={[
          !lockCreateGoal && (
            <Button
              onClick={() =>
                setActiveGoal({ goal: createGoal(), editting: true })
              }
              rounded
              theme="primary"
            >
              Create New Goal
            </Button>
          ),
        ]}
        className={styles.header}
      />

      {goals.length > 0 ? (
        goals.map((g) => (
          <GoalItem
            goal={g}
            onClick={setActiveGoal}
            editable={editable}
            key={g.goal_id}
          />
        ))
      ) : !lockCreateGoal ? (
        <div className={styles.placeholder}>
          Click 'Create New Goal' to get started
        </div>
      ) : (
        <div className={styles.placeholder}>There's no goal</div>
      )}
      <Modal
        open={!!activeGoal}
        onClose={() => setActiveGoal(undefined)}
        disableOverlayClick
      >
        <GoalDetail
          {...activeGoal}
          userId={userId}
          onCancel={() => setActiveGoal(undefined)}
        />
      </Modal>
    </div>
  );
};

export default Goals;
