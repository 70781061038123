import React from "react";
import classNames from "classnames";
import styles from "components/common/layouts/appLayout/footer/Footer.module.scss";
import { CONTACT_EMAIL, SITE_LONG_NAME } from "constants/site";

interface Props {
  absolute?: boolean;
}

const Footer = ({ absolute = false }: Props) => (
  <div className={classNames(styles.root, absolute && styles.absolute)}>
    <div className={styles.links}>
      <button className={classNames(styles.btn, styles.link)}>About</button>
      <button className={classNames(styles.btn, styles.link)}>
        Terms of use
      </button>
      <a
        className={classNames(styles.btn, styles.link)}
        href={`mailto:${CONTACT_EMAIL}`}
      >
        Contact us
      </a>
    </div>
    <div className={styles.copy}>
      © {SITE_LONG_NAME} {new Date().getFullYear()}. All rights reserved
    </div>
  </div>
);

export default Footer;
