import React from "react";
import { useForm } from "react-hook-form";
import { create, TUser } from "models/user/User";
import { userFormSchema, userPages } from "models/user/UserForm";
import { userAttributesMap } from "models/user/attributes";
import FormSection from "components/common/ui/form-elements/form-layout/FormSection";
import FormActions from "components/common/ui/form-elements/form-layout/FormActions";
import ConditionalLogicWrapper from "components/common/ui/form-elements/hook-form-elements/ConditionalLogicWrapperHookForm";
import { userTypeKey } from "models/user/attributes/common";
import SearchForSupervisor from "components/supervisors/add/SearchForSupervisor";
import FormQuestion from "components/common/ui/form-elements/form-layout/FormQuestion";
import { supervisorStatusQuestion } from "components/supervisors/utils/supervisorForm";
import { Supervisor } from "models/supervisors/Supervisor";
import { CombinedUserTypes } from "models/user/attributes/userType";

interface Props {
  user?: TUser;
  onSubmit: (attributes: Partial<TUser>) => void;
  onCancel: (attributes: Partial<TUser>) => void;
  title?: string;
  saveText?: string;
  isSubmitting?: boolean;
  isAdmin?: boolean;
  children?: React.ReactNode;
}

const UserEditForm = ({
  user = create(),
  onSubmit,
  onCancel,
  saveText = "Update User",
  isSubmitting,
  isAdmin,
}: Props) => {
  const {
    handleSubmit,
    formState: { errors },
    register,
    control,
  } = useForm({
    mode: "onSubmit",
    // defaultValues: { [userTypeKey]: user[userTypeKey] } as Record<string, any>,
    defaultValues: user as Record<string, any>,
    resolver: userFormSchema,
  });

  const handleSave = async (attributes: Partial<TUser>) => {
    await onSubmit({ ...attributes, id: user.id });
  };

  return (
    <form key={1} onSubmit={handleSubmit(handleSave)} noValidate>
      {userPages.map((p, i) => (
        <FormSection
          key={i}
          {...p}
          hookForm={{
            register,
            control,
            errors,
          }}
          questionMap={userAttributesMap}
          isAdmin={isAdmin}
        />
      ))}
      {isAdmin && (
        <ConditionalLogicWrapper
          control={control}
          conditional={{
            OR: [
              {
                [userTypeKey]: {
                  field: userTypeKey,
                  values: CombinedUserTypes.registrar,
                },
              },
              {
                supervisor: {
                  showIfEmpty: false,
                },
              },
            ],
          }}
        >
          <div style={{ marginLeft: 22, marginRight: 22 }}>
            <h5>Supervisor</h5>
            <SearchForSupervisor
              name="supervisor_select"
              control={control}
              error={errors?.user_search?.value}
              label={"Current Supervisor"}
              defaultValue={
                user?.supervisor && checkIfEmpty(user?.supervisor)
                  ? {
                      label: `${user.supervisor.given_name} ${user.supervisor.family_name} (${user.supervisor.email})`,
                      value: user.supervisor.id,
                      metadata: user.supervisor,
                    }
                  : undefined
              }
            />
            <FormQuestion
              question={supervisorStatusQuestion}
              errors={errors}
              register={register}
              control={control}
              defaultValue={user?.supervisor?.status ?? "Inactive"}
            />
          </div>
        </ConditionalLogicWrapper>
      )}

      <FormActions
        onCancel={onCancel}
        isSubmitting={isSubmitting}
        submitText={saveText}
      />
    </form>
  );
};

export default UserEditForm;

const checkIfEmpty = (sup: Supervisor) => {
  return !!(sup && sup.email);
};
