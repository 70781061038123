import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import { peerGroupQueryKeys } from "queries/constants";
import { MutationOptions } from "queries/queries";
import { useToast } from "components/common/ui/toast/useToast";
import { deletePeerGroup } from "api/PeerGroupApi";
import { PeerGroup } from "models/peergroup/PeerGroup";

export const useMutateDelete = (options: MutationOptions = {}) => {
  const queryClient = useQueryClient();
  const { addErrorToast, addToast } = useToast();

  return useMutation(
    ({ peergroup, accepted }: useMutateDeletePeergroupProps) => {
      return deletePeerGroup(peergroup, accepted);
    },
    {
      onSuccess: (result, inputs) => {
        addToast(
          `Peer group successfully ${inputs.accepted ? "restored" : "deleted"}`,
          {
            theme: "success",
          }
        );

        queryClient.invalidateQueries(peerGroupQueryKeys.list());
      },
      onError: (e, inputs) => {
        if (axios.isAxiosError(e)) {
          addErrorToast(
            e,
            `Error ${inputs.accepted ? "restoring" : "deleting"} peer group`
          );
        } else {
        }
      },
      ...options,
    }
  );
};

interface useMutateDeletePeergroupProps {
  peergroup: PeerGroup;
  accepted: boolean;
}
