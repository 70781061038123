import * as Sentry from "@sentry/react";
import { SENTRY_DSN, SENTRY_RELSEASE } from "constants/site";
import { BrowserTracing } from "@sentry/tracing";
import { createBrowserHistory } from "history";
import { TUser } from "models/user/User";

const logErrorToSentry = process.env.NODE_ENV !== "development";
const history = createBrowserHistory();

export const errorInit = () => {
  let envSpecificOptions =
    process.env.NODE_ENV === "production"
      ? {}
      : {
          beforeBreadcrumb(breadcrumb: any) {
            //Ignore console logs in development to prevent the payload from getting too large
            return breadcrumb.category === "console" ? null : breadcrumb;
          },
        };

  if (logErrorToSentry) {
    Sentry.init({
      dsn: SENTRY_DSN,
      environment: process.env.REACT_APP_ENVIRONMENT_SLUG,
      release: SENTRY_RELSEASE,
      autoSessionTracking: true,
      normalizeDepth: 8,
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
        }),
      ],
      tracesSampleRate: 1.0,
      ...envSpecificOptions,
    });
  }
};

export const setErrorUser = (user?: Partial<TUser>) => {
  if (user && user?.id !== "") {
    Sentry.setUser(user);
  } else {
    Sentry.configureScope((scope) => scope.setUser(null));
  }
};

export const captureError = (e: Error) => {
  if (logErrorToSentry) {
    Sentry.captureException(e);
  }
};

export const logFeedback = (eventId: string | null) => {
  if (logErrorToSentry && eventId) {
    Sentry.showReportDialog({ eventId: eventId });
  }
};

const stateTransformer = (state: any) => {
  const { app, ...rest } = state;
  // Transform the state to remove sensitive information before logging to Sentry
  const { currentUser, auth, users, ...appState } = app;
  return {
    ...rest,
    app: {
      ...appState,
    },
  };
};

export const errorStoreEnhancer = Sentry.createReduxEnhancer({
  stateTransformer,
  actionTransformer: (action) => {
    //remove state from redux.action breadcrumb
    const { state, ...rest } = action;
    return { ...rest };
  },
});
