import React, { MouseEvent } from "react";
import { Link, NavLink } from "react-router-dom";
import { useRouteSelector } from "hooks/useSelector";

export interface TLinkWrapper extends DefaultProps {
  /** React DOM Link target */
  to?: string;
  /** Normal href attribute */
  href?: string;
  /** Href target attribute (e.g. '_blank') */
  target?: string;
  /** Callback function triggered on click */
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;

  /** Href download attribute  */
  download?: boolean | string;

  children?: any;
}

export const LinkWrapper = (props: TLinkWrapper) => {
  const to = useRouteSelector(props.to);
  if (props.to) {
    return (
      <Link to={to || ""} className={props.className}>
        {props.children}
      </Link>
    );
  }
  if (props.href) {
    return (
      <a
        href={props.href || ""}
        target={props.target || "_blank"}
        download={props.download}
        className={props.className}
      >
        {props.children}
      </a>
    );
  }
  if (props.onClick) {
    return (
      <button className={props.className} onClick={props.onClick}>
        {props.children}
      </button>
    );
  }
  return props.children;
};

interface TNavLinkWrapper extends TLinkWrapper {
  activeClassName: string;
  exact?: boolean;
  isActive?: (match: any, location: any) => boolean;
}

export const NavLinkWrapper = ({
  activeClassName,
  isActive,
  exact,
  ...props
}: TNavLinkWrapper) => {
  const to = useRouteSelector(props.to);
  if (to) {
    return (
      <NavLink
        to={to}
        className={props.className}
        activeClassName={activeClassName}
        isActive={isActive}
        exact={exact}
      >
        {props.children}
      </NavLink>
    );
  } else {
    return <LinkWrapper {...props} />;
  }
};

export default LinkWrapper;
