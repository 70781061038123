import React, { useState } from "react";
import { Activity } from "models/cpd/Activity";
import { Goal } from "models/cpd/Goal";
import { PlanYear } from "models/cpd/Plan";
import Chip, { IconChip } from "components/common/ui/chip/Chip";
import { activityCategoryOptions, Category } from "models/cpd/common/common";
import CategoryIcon from "components/cpd/common/CategoryIcon";
import { IconProgressStar } from "components/common/icons/IconProgress";
import { Tab, TabList, Tabs } from "components/common/ui/tabs/Tabs";
import IconClock from "components/common/icons/IconClock";
import { Facility } from "models/facility/Facility";
import MiniLoader from "components/common/ui/loader/MiniLoader";
import FileItem from "components/cpd/activity/edit/FileItem";
import IconLock from "components/common/icons/IconLock";
import IconPaperclip from "components/common/icons/IconPaperclip";
import AuditResults from "components/cpd/activity/detail/AuditResults";
import WaiverResults from "components/cpd/activity/detail/Waiver";
import DateBox from "components/cpd/activity/detail/DateBox";
import RequiredBox from "components/cpd/activity/detail/RequiredBox";
import Modal from "components/common/ui/modal/Modal";
import Button from "components/common/ui/button/Button";
import PeerGroupMeetingDetail from "components/peergroup/detail/PeerGroupMeetingDetail";
import { getSubcategory } from "components/cpd/utils/utils";
import GradeResults from "components/cpd/activity/detail/GradeResults";
import { IconSupervisorCircle } from "components/common/icons/IconSupervisor";
import styles from "./ActivityDetail.module.scss";

interface Props {
  activity: Activity;
  userId: string;
  goals: Goal[];
  year: PlanYear;
  isLoading: boolean;
  locked?: boolean;
  isAdmin?: boolean;
}

const ActivityDetailFormatted = ({
  activity,
  userId,
  goals = [],
  isLoading,
  locked,
  isAdmin,
}: Props) => {
  const [tabState, setTabState] = useState<number>(0);
  const [open, setOpen] = useState(false);
  const category = activityCategoryOptions.find(
    (c) => c.value === activity?.category
  );

  const subcategory = getSubcategory(activity.category, activity.subcategory);
  const goal = goals.find((g) => g.goal_id === activity?.goal_id);
  const complete = !!activity?.completed_at;

  return isLoading ? (
    <MiniLoader />
  ) : (
    <div className={styles.root}>
      <div>
        <Tabs state={[tabState, setTabState]}>
          <TabList decorator="none" tabBackground>
            <Tab>Details</Tab>
            <Tab>
              <div className={styles.tabRow}>
                Documents{" "}
                {(activity?.hasAttachments ||
                  (activity.supporting_docs || [])?.length > 0) && (
                  <IconChip theme="secondary" condensed variant="empty">
                    <IconPaperclip />
                  </IconChip>
                )}
              </div>
            </Tab>

            <div className={styles.spacer} />
            {/*<Tab>Summary</Tab>*/}
          </TabList>
        </Tabs>
      </div>

      {tabState === 0 && (
        <div>
          {activity?.topic && <h2>{activity?.topic} </h2>}
          {!activity?.topic && <br />}
          <div className={styles.row}>
            {category && (
              <Chip
                icon={<CategoryIcon type={category.value} />}
                className={styles.noShrink}
                theme={complete ? "primary" : "grey"}
              >
                <b>{category?.altLabel || category?.label || ""}</b>
                {subcategory ? (
                  <span>
                    <b>: &nbsp;</b>
                    {subcategory.label}
                  </span>
                ) : (
                  ""
                )}
              </Chip>
            )}
            {activity?.supervisor &&
              activity.category === Category.tp_supervision && (
                <Chip icon={<IconSupervisorCircle />} iconVariant="empty">
                  <span>
                    Supervisor:{" "}
                    <strong>
                      {activity?.supervisor?.given_name}&nbsp;
                      {activity?.supervisor?.family_name}
                    </strong>
                  </span>
                </Chip>
              )}
            {activity?.credits && (
              <Chip icon={<IconClock />} iconVariant="empty">
                <b>Points/Hourly Credits: &nbsp;</b>
                {activity.credits}
              </Chip>
            )}
            {goal && (
              <Chip
                icon={<IconProgressStar />}
                iconVariant="empty"
                className={styles.noShrink}
              >
                <b>Goal: &nbsp;</b> {goal.goal_name}
              </Chip>
            )}
            {(activity.locked || activity.waiver || locked) && (
              <Chip
                icon={<IconLock />}
                theme="danger"
                className={styles.noShrink}
              >
                Locked from editing
              </Chip>
            )}

            {activity.peergroup_meeting_id &&
              activity.peergroup_meeting_details && (
                <Button
                  theme={"secondary"}
                  variant="outline"
                  onClick={() => setOpen(true)}
                >
                  View '
                  <span className={styles.emphasis}>
                    {activity.peergroup_meeting_details.peergroup_meeting_title}
                  </span>
                  ' Meeting
                </Button>
              )}
            <Modal open={open} onClose={() => setOpen(false)}>
              <PeerGroupMeetingDetail
                peergroupId={
                  activity.peergroup_meeting_details?.peergroup_id || ""
                }
                meetingId={activity.peergroup_meeting_id || ""}
                showHeading
              />
              <Button
                theme={"danger"}
                variant="outline"
                onClick={() => setOpen(false)}
                className={styles.widthButton}
              >
                Close Meeting Detail
              </Button>
            </Modal>
          </div>
          {activity?.locations && (
            <div>
              {" "}
              <h5>Facilities where clinical hours were delivered: </h5>
              <div className={styles.row}>
                {activity?.locations?.map((l: Facility) => (
                  <Chip condensed theme={"primary"} key={l.facility_id}>
                    {l.facility_name || ""}
                  </Chip>
                ))}
                {activity?.other_locations?.map((l: Facility) => (
                  <Chip condensed theme={"info"} key={l.facility_id}>
                    {l.facility_name || ""} *
                  </Chip>
                ))}
              </div>
              {activity?.other_locations && (
                <i>*other, non-workplace facilities (e.g. locum work)</i>
              )}
            </div>
          )}
          <br />

          <div className={styles.row}>
            <RequiredBox activity={activity} />
            <DateBox activity={activity} />
            <AuditResults activity={activity} />
            <GradeResults activity={activity} isAdmin={isAdmin} />
            <WaiverResults activity={activity} />
          </div>

          {activity?.learnings && (
            <div className={styles.box}>
              {" "}
              <h5>What was learned: </h5>
              <p className={styles.textBox}>{activity?.learnings || " - "}</p>
            </div>
          )}

          {activity?.benefits && (
            <div className={styles.box}>
              {" "}
              <h5>Patient Benefits: </h5>
              <p className={styles.textBox}>{activity?.benefits || " - "}</p>
            </div>
          )}

          <br />
        </div>
      )}
      <br />

      {tabState === 1 && (
        <>
          <h3>Supporting Documents</h3>
          <br />
          <div className={styles.wrapRow}>
            {!!activity?.supporting_docs &&
            activity.supporting_docs.length > 0 ? (
              activity.supporting_docs.map((d) => (
                <FileItem
                  key={d}
                  file={d}
                  activityId={activity?.id || ""}
                  userId={userId}
                />
              ))
            ) : (
              <i>No documents have been uploaded</i>
            )}
          </div>
        </>
      )}

      {/*<HintBox theme="info">*/}
      {/*  <div>*/}
      {/*    <div>*/}
      {/*      <b>Category:</b> {activity?.category}*/}
      {/*    </div>*/}
      {/*    /!*<div>*!/*/}
      {/*    /!*  <b>Last Updated:</b>{" "}*!/*/}
      {/*    /!*  {goal?.created_at && formatDate(goal?.created_at)}*!/*/}
      {/*    /!*</div>*!/*/}
      {/*    /!*{goal?.completed_at && (*!/*/}
      {/*    /!*  <div>*!/*/}
      {/*    /!*    <b>Completed:</b> {formatDate(goal?.completed_at)}*!/*/}
      {/*    /!*  </div>*!/*/}
      {/*    /!*)}*!/*/}
      {/*  </div>*/}
      {/*</HintBox>*/}
      {/*<br />*/}
    </div>
  );
};

export default ActivityDetailFormatted;
