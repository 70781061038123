import { DataAttribute } from "components/common/ui/form-elements/formElements";
import * as Yup from "yup";
import { getSchema, getValidations } from "utils/formUtils";
import { convertToDate } from "utils/format/dateUtils";

interface BaseGoal {
  goal_name: string;
  goal_status: "in_progress" | "complete";
  notes?: string;
}

export interface Goal extends BaseGoal {
  id?: string; // for supervisors nested goal id is just `id`
  goal_id: string;
  user_id: string;
  dev_goals: DevelopmentAction[];
  actions: DevelopmentAction[];
  created_at?: Date | null;
  updated_at?: Date | null;
  completed_at?: Date | null;
}
export type DevelopmentAction = { text: string; complete: boolean };

export interface IServerGoal extends BaseGoal {
  id: string;
  useraccount_id: string;
  created_at: string | Date;
  updated_at: string | Date;
  completed_at: string | Date | null;
  goal_fields?:
    | string
    | {
        actions: DevelopmentAction[];
        dev_goals: DevelopmentAction[];
      };
}

export const createGoal = (goal: Partial<Goal> = {}): Goal => ({
  goal_id: "",
  goal_name: "",
  user_id: "",
  dev_goals: [],
  actions: [],
  goal_status: "in_progress",
  ...goal,
});

export const formatGoal = ({
  id,
  useraccount_id,
  created_at,
  updated_at,
  completed_at,
  goal_fields,
  ...goal
}: Partial<IServerGoal>) => {
  const fields =
    typeof goal_fields === "string" ? JSON.parse(goal_fields) : goal_fields;
  return createGoal({
    goal_id: id,
    user_id: useraccount_id,
    created_at: convertToDate(created_at),
    updated_at: convertToDate(updated_at),
    completed_at: convertToDate(completed_at),
    dev_goals: fields?.dev_goals || [],
    actions: fields?.actions || [],
    ...goal,
  });
};
export const toServerGoal = ({
  goal_id,
  user_id,
  dev_goals,
  actions,
  created_at,
  updated_at,
  completed_at,
  ...goal
}: Partial<Goal>): Partial<IServerGoal> => {
  return {
    goal_fields: {
      actions: actions || [],
      dev_goals: dev_goals || [],
    },
    completed_at:
      goal.goal_status === "in_progress"
        ? null
        : !completed_at
        ? new Date()
        : completed_at,
    ...goal,
  };
};

type GoalAttribute = DataAttribute<keyof Goal>;

export const goalAttributes: GoalAttribute[] = [
  {
    name: "goal_name",
    type: "string",
    label: "Area for Development",
    required: true,
    validation: Yup.string().required(
      "Please enter an Area or Name for your Goal"
    ),
  },
  {
    name: "dev_goals",
    type: "object[]",
    label: "Development goals",
    options: [
      {
        value: "text",
        type: "string",
        layout: {
          type: "textArea",
        },
      },
      {
        value: "complete",
        type: "boolean",
        label: " ",
        options: [{ value: "Complete" }, { value: "In Progress" }],
      },
      // {value: "status", type:"enum", options:[{value:"Complete"}, {value:"In Progress"}]}
    ],
  },
  {
    name: "actions",
    type: "object[]",
    label: "Actions",
    options: [
      {
        value: "text",
        type: "string",
        layout: {
          type: "textArea",
        },
      },
      {
        value: "complete",
        type: "boolean",
        label: " ",
        options: [{ value: "Complete" }, { value: "In Progress" }],
      },
    ],
  },
  {
    name: "notes",
    type: "string",
    label: "Additional Notes",
    layout: {
      type: "textArea",
    },
  },
  {
    name: "goal_status",
    type: "enum",
    label: "Status",
    // defaultValue: "in_progress",
    options: [
      { value: "in_progress", label: "In Progress" },
      { value: "complete", label: "Complete" },
    ],
    validation: Yup.string().required("Please select a Goal Status"),
  },
];

export const goalFormSchema = getSchema(getValidations(goalAttributes));
