import React from "react";
import { deepObjectAccessor } from "utils/common";
import TextInput from "components/common/ui/form-elements/text-input/TextField";
import CheckboxGroup from "components/common/ui/form-elements/checkbox/CheckboxGroup";
import { DateHookForm } from "components/common/ui/form-elements/hook-form-elements/DateHookForm";
import { Address } from "components/common/ui/form-elements/address/Address";
import HookFormPhoneInput from "components/common/ui/form-elements/phone/HookFormPhoneInput";
import { IconEmail } from "components/common/icons/twoToneIcons";
import TextArea from "components/common/ui/form-elements/text-input/TextArea";
import FieldArrayQuestion from "components/common/ui/form-elements/field-array/FieldArrayQuestion";
import { FormQuestionProps } from "components/common/ui/form-elements/formElements";
import FacilityQuestion from "components/users/common/detail/FacilityQuestion";
import RadioGroup from "components/common/ui/form-elements/radio/RadioGroup";
import ConsentCheckbox from "components/common/ui/form-elements/checkbox/ConsentCheckbox";
import { SelectHookForm } from "components/common/ui/form-elements/hook-form-elements/SelectHookForm";
import { RichTextEditorHookForm } from "components/common/ui/form-elements/hook-form-elements/RichTextEditorHookForm";

const FormQuestion = <F extends string>({
  question,
  errors,
  register,
  control,

  defaultValue,
  ...props
}: FormQuestionProps<F>) => {
  const { type, name, label, description, options, layout } = question;
  const error = deepObjectAccessor(errors, name)
    ? deepObjectAccessor(errors, name)
    : undefined;

  const qType = layout?.type || type;

  switch (qType) {
    case "enum":
    case "select":
      return (
        <SelectHookForm
          question={question}
          error={error}
          register={register}
          control={control}
          multiSelect={type === "enum[]"}
          defaultValue={defaultValue || question.defaultValue}
          {...props}
        />
      );
    case "radio":
      return (
        <RadioGroup
          name={name}
          label={label}
          // description={description}
          options={options || []}
          register={register}
          error={error}
          defaultValue={defaultValue}
          {...props}
        />
      );

    case "textArea":
      return (
        <TextArea
          {...register(name, {
            // required: question.required && `${label} is required`,
          })}
          type={type}
          label={label}
          description={description}
          error={error}
          defaultValue={defaultValue}
          {...props}
        />
      );

    case "boolean":
      return (
        <ConsentCheckbox
          {...register(name, {})}
          label={label || ""}
          error={error}
          {...props}
        />
      );

    case "enum[]":
      // return (
      //   <SelectWrapper
      //     question={question}
      //     errors={error}
      //     register={register}
      //     control={control}
      //     multiSelect={true}
      //   />
      // );
      return (
        <CheckboxGroup
          name={name}
          label={label}
          description={description}
          options={options || []}
          register={register}
          error={error}
          defaultValue={defaultValue}
          {...props}
        />
      );

    case "phone":
      return (
        <HookFormPhoneInput
          name={question.name}
          control={control}
          label={question.label}
          {...props}
        />
      );

    case "email":
      return (
        <TextInput
          {...register(name, {
            // required: question.required && `${label} is required`,
          })}
          type={type}
          label={label}
          description={description}
          error={error}
          defaultValue={defaultValue}
          icon={IconEmail}
          {...props}
        />
      );

    case "date":
    case "datetime":
      return (
        <DateHookForm
          control={control}
          question={question}
          errors={error}
          register={register}
          defaultValue={defaultValue}
          {...props}
        />
      );

    case "address":
      return (
        <Address
          question={question}
          register={register}
          errors={error}
          defaultValue={defaultValue}
          {...props}
        />
      );

    case "object[]":
      return (
        <FieldArrayQuestion
          question={question}
          register={register}
          errors={error}
          control={control}
          {...props}
        />
      );
    case "facility":
      return (
        <FacilityQuestion
          question={question}
          control={control}
          defaultValue={defaultValue}
          errors={error}
          {...props}
        />
      );
    case "richText":
      return (
        // <RichTextEditor
        //   {...register(name, {
        //     required: question.required && `${label} is required`,
        //   })}
        //   type={type}
        //   label={label}
        //   description={description}
        //   error={error}
        //   defaultValue={defaultValue}
        //   {...props}
        // />
        <RichTextEditorHookForm
          question={question}
          error={error}
          register={register}
          control={control}
          defaultValue={defaultValue || question.defaultValue}
          {...props}
        />
      );
    default:
      return (
        <TextInput
          {...register(name, {
            // required: question.required && `${label} is required`,
          })}
          type={type}
          label={label}
          description={description}
          error={error}
          defaultValue={defaultValue}
          {...props}
        />
      );
  }
};

export default FormQuestion;
