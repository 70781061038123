import React from "react";
import cx from "classnames";
import { motion } from "framer-motion";
import {
  NavLinkWrapper,
  TLinkWrapper,
} from "components/common/ui/link-wrapper/LinkWrapper";
import NavTooltip from "components/common/ui/tooltip/NavTooltip";
import styles from "components/common/layouts/appLayout/navBar/NavBar.module.scss";

interface Props extends TLinkWrapper {
  icon: React.ReactNode;
  text: React.ReactNode;
  displayTooltip?: boolean;
}
const NavItem = ({ icon, text, ...props }: Props) => (
  <motion.div variants={variants} exit="hidden">
    <NavLinkWrapper
      to={props.to}
      href={props.href}
      target={props.target}
      activeClassName={styles.active}
      isActive={isItemActive}
    >
      <NavItemWrapper
        content={text}
        displayTooltip={props.displayTooltip}
        {...props}
      >
        <div className={cx(styles.navItem)}>
          <div className={styles.navItemIcon}>{icon}</div>
          <div className={styles.navItemText}>{text}</div>
        </div>
      </NavItemWrapper>
    </NavLinkWrapper>
  </motion.div>
);

interface INavItemWrapper extends Partial<Props> {
  content: React.ReactNode;
  children: React.ReactElement;
  displayTooltip?: boolean;
}

const NavItemWrapper = ({
  content,
  children,
  displayTooltip,
  ...props
}: INavItemWrapper) => (
  <>
    {displayTooltip ? (
      <NavTooltip
        content={
          <NavLinkWrapper
            {...props}
            activeClassName={styles.active}
            isActive={isItemActive}
          >
            {content}
          </NavLinkWrapper>
        }
        className={styles.tooltip}
      >
        {children}
      </NavTooltip>
    ) : (
      children
    )}
  </>
);

const isItemActive = (match: any, location: any): boolean => {
  return !(
    !match ||
    ((match.url === "" || match.url === "/") && location.pathname !== "/")
  );
};

const variants = {
  visible: {
    pointerEvents: "inherit" as "inherit",
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  hidden: {
    pointerEvents: "none" as "none",
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

export default NavItem;
