import {
  clinicalUsersConditional,
  confirmedClinicalConditional,
  exceptFellowUsersConditional,
  fellowConditional,
  fellowEducationUsersConditional,
  memberUsersConditional,
  UserAttribute,
  userOfficeOnlyKey,
} from "models/user/attributes/common";
import { getDefaultValues, getValidations } from "utils/formUtils";
import { FieldOption } from "components/common/ui/form-elements/formElements";

export const OVERSIGHT_SEVERITY_OPTIONS = [
  { value: "green", label: "Green", color: "#20c29d" },
  { value: "yellow", label: "Yellow", color: "#d39700" },
  { value: "orange", label: "Orange", color: "#ea6a21" },
  { value: "red", label: "Red", color: "#ff5674" },
  { value: "purple", label: "Purple", color: "#5c41bb" },
  { value: "blue", label: "Blue", color: "#1e9bd5" },
];

export const MEMBERSHIP_STATUS_OPTIONS: FieldOption[] = [
  { value: "pending", label: "Pending", theme: "info" },
  { value: "active", label: "Active", theme: "primary" },
  { value: "paused", label: "Paused", theme: "warning" },
  { value: "cancelled", label: "Cancelled", theme: "danger" },
];

export const office: UserAttribute[] = [
  // Demographics
  {
    name: "nz_citizen_permanent_resident",
    label: "NZ citizen or permanent resident",
    type: "enum",
    adminOnly: true,
    tracked: true,
    options: [
      { value: "yes", label: "Yes" },
      { value: "no", label: "No" },
    ],
    defaultValue: "yes",
    conditional: clinicalUsersConditional,
  },
  // Education Details
  {
    name: "medical_qualification",
    label: "Medical qualification/s?",
    type: "object[]",
    adminOnly: true,
    conditional: clinicalUsersConditional,
    options: [
      {
        value: "qualification",
        label: "Medical Qualification",
        type: "string",
      },
      { value: "year", label: "Year Completed", type: "string" },
      // { value: "location", label: "Location", type: "string" },
      { value: "institute", label: "Institute", type: "string" },
      {
        value: "country",
        label: "Country",
        type: "string",
        defaultValue: "New Zealand",
      },
    ],
    // defaultValue:[]
  },
  {
    name: "other_qualifications",
    label: "Do you have any other qualifications?",
    type: "object[]",
    adminOnly: true,
    conditional: clinicalUsersConditional,
    options: [
      { value: "qualification", label: "Qualification", type: "string" },
      { value: "year", label: "Year Completed", type: "string" },
      // { value: "location", label: "Location", type: "string" },
      { value: "institute", label: "Institute", type: "string" },
      {
        value: "country",
        label: "Country",
        type: "string",
        defaultValue: "New Zealand",
      },
    ],
  },
  // {
  //   name: "year_of_qualification",
  //   label: "What year did you complete your qualification/s?",
  //   type: "string",
  //   adminOnly: true,
  //   conditional: clinicalUsersConditional,
  // },

  // {
  //   name: "location_of_medical_degree",
  //   label:
  //     "At which educational institution did you complete your Medical Degree?",
  //   type: "string",
  //   adminOnly: true,
  //   conditional: clinicalUsersConditional,
  // },

  // Membership details
  {
    name: "application_date",
    label: "Application received",
    type: "date",
    defaultValue: "",
    adminOnly: true,
    conditional: clinicalUsersConditional,
  },
  {
    name: "training_programme_start_date",
    label: "Training programme start",
    type: "date",
    defaultValue: "",
    adminOnly: true,
    conditional: clinicalUsersConditional,
  },
  {
    name: "fellowship_date",
    label: "Fellowship date",
    type: "date",
    defaultValue: "",
    adminOnly: true,
    conditional: fellowEducationUsersConditional,
  },
  {
    name: "rollover_date",
    label: "Annual compliance check date",
    type: "date",
    defaultValue: "",
    adminOnly: true,
    conditional: memberUsersConditional,
  },
  {
    name: "triennium_date",
    label: "Triennium ends",
    type: "date",
    defaultValue: "",
    adminOnly: true,
    conditional: fellowConditional,
  },
  {
    name: "membership_status",
    label: "Membership Status",
    type: "enum",
    required: true,
    adminOnly: true,
    options: MEMBERSHIP_STATUS_OPTIONS,
    defaultValue: "active",
    conditional: clinicalUsersConditional,
    tracked: true,
  },

  {
    name: "hwnz_date",
    label: "Date HWNZ funding ends, if funded",
    type: "date",
    defaultValue: "",
    adminOnly: true,
    conditional: exceptFellowUsersConditional,
  },

  // CPD details
  // {
  //   name: "additional_oversight_required",
  //   label:
  //     "Are you being monitored by the RNZCUC for training/recertification requirements?",
  //   type: "enum",
  //   adminOnly: true,
  //   options: [
  //     { value: "yes", label: "Yes" },
  //     { value: "no", label: "No" },
  //   ],
  //   defaultValue: "no",
  //   conditional: confirmedClinicalConditional,
  // },
  {
    name: "additional_oversight_severity",
    adminOnly: true,
    label: "Monitor status",
    type: "enum",
    options: OVERSIGHT_SEVERITY_OPTIONS,
    defaultValue: "green",
    conditional: [
      ...memberUsersConditional,
      // {
      //   field: "fields_office_only.additional_oversight_required",
      //   values: ["yes"],
      // },
    ],
  },
  {
    name: "oversight_notes",
    adminOnly: true,
    label: "Monitor notes",
    type: "string",
    defaultValue: "",
    layout: {
      type: "textArea",
    },
  },
  {
    name: "monitor_check_date",
    adminOnly: true,
    label: "Monitor Progress date",
    type: "date",
    defaultValue: "",
    conditional: [
      ...memberUsersConditional,
      {
        field: "fields_office_only.additional_oversight_severity",
        values: ["red", "orange", "yellow", "purple", "blue"],
      },
    ],
  },

  // Subscription details
  {
    name: "website_access",
    label: "Do you have access to the RNZCUC website?",
    type: "enum",
    adminOnly: true,
    options: [
      { value: "yes", label: "Yes" },
      { value: "no", label: "No" },
    ],
    defaultValue: "no",
    conditional: memberUsersConditional,
  },
  {
    name: "ecg_weekly_subscription",
    label: "Do you subscribe to ECG weekly?",
    type: "enum",
    options: [
      { value: "yes", label: "Yes" },
      { value: "no", label: "No" },
    ],
    defaultValue: "no",
    adminOnly: true,
    conditional: memberUsersConditional,
  },
  {
    name: "uc_course_access",
    label: "Do you have access to UC Course?",
    adminOnly: true,
    type: "enum",
    options: [
      { value: "yes", label: "Yes" },
      { value: "no", label: "No" },
    ],
    defaultValue: "no",
    conditional: memberUsersConditional,
  },
  {
    name: "invoice_membership",
    label: "Do you have access to invoice membership?",
    adminOnly: true,
    type: "enum",
    options: [
      { value: "yes", label: "Yes" },
      { value: "no", label: "No" },
    ],
    defaultValue: "no",
    conditional: memberUsersConditional,
  },
  {
    name: "training_database",
    label: "Do you have access to training database?",
    adminOnly: true,
    type: "enum",
    options: [
      { value: "yes", label: "Yes" },
      { value: "no", label: "No" },
    ],
    defaultValue: "no",
    conditional: memberUsersConditional,
  },
  {
    name: "e-portfolio",
    label: "Do you have access to e-portfolio?",
    adminOnly: true,
    type: "enum",
    options: [
      { value: "yes", label: "Yes" },
      { value: "no", label: "No" },
    ],
    defaultValue: "no",
    conditional: memberUsersConditional,
  },
  {
    name: "website_newsletter",
    label: "Do you have access to website newsletter?",
    adminOnly: true,
    type: "enum",
    options: [
      { value: "yes", label: "Yes" },
      { value: "no", label: "No" },
    ],
    defaultValue: "no",
    conditional: memberUsersConditional,
  },

  {
    name: "pause_periods",
    label: "Pauses",
    type: "object[]",
    adminOnly: true,
    // conditional: clinicalUsersConditional,
    conditional: confirmedClinicalConditional,
    options: [
      {
        value: "start_date",
        label: "Pause Start Date",
        type: "date",
      },
      {
        value: "planned_return_date",
        label: "Planned Return Date",
        type: "date",
      },
      {
        value: "actual_return_date",
        label: "Actual Return Date",
        type: "date",
      },
    ],
  },
];

export const defaultValuesOfficeOnly = getDefaultValues(office);
export const userOfficeOnlyValidations = getValidations(
  office,
  userOfficeOnlyKey
);
