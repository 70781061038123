import React from "react";
import {
  IconCodeFile,
  IconExcelFile,
  IconFile,
  IconImageFile,
  IconMusicFile,
  IconPdfFile,
  IconPowerPointFile,
  IconSvgFile,
  IconTable,
  IconTextFile,
  IconVideoFile,
  IconWordFile,
  IconZipFile,
} from "components/common/icons/IconFiles";
import { TIcon } from "components/common/icons/common/createIcon";

interface Props extends TIcon {
  filename: string;
}

const FileIcon = ({ filename, ...rest }: Props) => {
  const fileNameParts = filename.split(".");
  const fileType = fileNameParts[fileNameParts.length - 1].toLowerCase();
  switch (fileType) {
    case "xls":
    case "xlsx":
    case "xlsb":
    case "xlsm":
      return <IconExcelFile {...rest} />;

    case "doc":
    case "docm":
    case "docx":
      return <IconWordFile {...rest} />;

    case "ppt":
    case "pptx":
      return <IconPowerPointFile {...rest} />;

    case "pdf":
      return <IconPdfFile {...rest} />;

    case "img":
    case "png":
    case "jpg":
    case "jpeg":
      return <IconImageFile {...rest} />;

    case "svg":
      return <IconSvgFile {...rest} />;

    case "mp3":
    case "m4a":
    case "flac":
    case "wav":
      return <IconMusicFile {...rest} />;

    case "mov":
    case "mp4":
    case "wmv":
    case "webm":
      return <IconVideoFile {...rest} />;

    case "csv":
    case "tsv":
    case "psv":
      return <IconTable {...rest} />;

    case "txt":
      return <IconTextFile {...rest} />;

    case "zip":
    case "7z":
    case "rar":
      return <IconZipFile {...rest} />;

    case "json":
    case "yml":
    case "yaml":
      return <IconCodeFile {...rest} />;

    default:
      return <IconFile {...rest} />;
  }
};

export default FileIcon;
