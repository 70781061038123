import React from "react";
import createIcon from "components/common/icons/common/createIcon";

const IconArrow = createIcon(
  <React.Fragment>
    <path d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z" />
  </React.Fragment>,
  "Arrow"
);

export default IconArrow;
