import React from "react";
import cx from "classnames";
import { motion } from "framer-motion";
import styles from "components/common/ui/skeleton/SkeletonLoader.module.scss";

interface Props {
  className?: string;
  angle?: "straight" | "angled";
  variant?: "base" | "dark" | "light" | "inverse";
}
const SkeletonLoader = ({
  className,
  angle = "straight",
  variant = "base",
}: Props) => (
  <motion.div
    className={cx(styles.root, styles[variant], styles[angle], className)}
    {...anim}
  />
);

export default SkeletonLoader;
const anim = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: { duration: 0.3, type: "easeOut" },
  },
  exit: {
    opacity: 0,
    transition: { duration: 0.2, ease: "easeIn" },
  },
};
