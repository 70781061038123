// export const checkSignIn = async (user:{challengeName?:"SMS_MFA"}, changeState:(key:string, user:any)=>void)=>{
//   if (user.challengeName === 'SMS_MFA') {
//     changeState('confirmSignIn', user);
//   } else if (user.challengeName === 'MFA_SETUP') {
//     changeState('TOTPSetup', user);
//   } else {
//     checkContact(user);
//   }
//
// }

export const objectWithProperties = (
  obj: Record<string, any>,
  keys: string[]
) => {
  const target = {} as Record<string, any>;
  for (const key in obj) {
    if (keys.indexOf(key) === -1) {
      continue;
    }
    if (!Object.prototype.hasOwnProperty.call(obj, key)) {
      continue;
    }
    target[key] = obj[key];
  }
  return target;
};
