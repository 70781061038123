import React from "react";
import cx from "classnames";
import styles from "./Spacer.module.scss";

interface SpacerProps extends DefaultProps {
  width?: number;
  height?: number;
}

const Spacer = ({ className, height, width, style, ...props }: SpacerProps) => (
  <span
    {...props}
    className={cx(styles.spacer, className)}
    style={{ ...style, width, height }}
  />
);

export default Spacer;
