import React, { useState } from "react";
import cx from "classnames";
import TextInput, {
  ITextInput,
} from "components/common/ui/form-elements/text-input/TextField";
import EyeVisibleIcon from "components/common/icons/IconEyeVisible";
import IconEyeHidden from "components/common/icons/IconEyeHidden";
import styles from "components/common/ui/form-elements/common/Input.module.scss";

interface IPasswordWrap extends ITextInput {
  component: typeof TextInput;
}

const PasswordTextWrapper = React.forwardRef(
  ({ component: Component, ...props }: IPasswordWrap, ref: React.Ref<any>) => {
    const [displayPassword, toggleDisplay] = useState(false);
    return (
      <div className={styles.passwordWrapper}>
        <Component
          {...props}
          type={displayPassword ? "text" : "password"}
          ref={ref}
        />
        <button
          type="button"
          aria-label={`Toggle Password Visibility`}
          className={cx(styles.toggle, props.label && styles.toggleLabel)}
          onClick={() => toggleDisplay(!displayPassword)}
        >
          {displayPassword ? <EyeVisibleIcon /> : <IconEyeHidden />}{" "}
        </button>
      </div>
    );
  }
);

export const PasswordTextInput = React.forwardRef(
  (props: ITextInput, ref: React.Ref<any>) => (
    <PasswordTextWrapper component={TextInput} {...props} ref={ref} />
  )
);

export default PasswordTextInput;
