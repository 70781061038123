import * as React from "react";
import {
  clinicalUsersConditional,
  exceptRegistrarUsersConditional,
  generalUsersConditional,
  registrarConditional,
  // memberUsersConditional,
  UserAttribute,
  userFieldsKey,
  userOfficeOnlyKey,
  userTypeKey,
} from "models/user/attributes/common";
import { attributes } from "models/user/attributes";
import { getSchema } from "utils/formUtils";
import { userCoreValidations } from "models/user/attributes/core";
import { userFieldsValidations } from "models/user/attributes/fields";
import { userOfficeOnlyValidations } from "models/user/attributes/office";
import { Page, Section } from "models/common/Form";
import IconUser from "components/common/icons/IconUser";
import IconSchool from "components/common/icons/IconSchool";
import IconNotebook from "components/common/icons/IconNotebook";
import IconFacility from "components/common/icons/IconFacility";

export interface UserPage extends Page<UserAttribute> {
  sections: ISection[];
}
interface ISection extends Section {
  questions: typeof attributes[number][];
}

export const coreUserQuestions = [
  userTypeKey,
  // `email`,
  `${userFieldsKey}.title`,
  `given_name`,
  `family_name`,
  `nickname`,
  `mcnz`,
  `phone`,
];

//show email form when create user
export const coreUserQuestionsShowInCreateUser = [
  userTypeKey,
  `email`,
  `${userFieldsKey}.title`,
  `given_name`,
  `family_name`,
  `nickname`,
  `mcnz`,
  `phone`,
];

export const coreUserQuestionsShowInCreateFacility = [
  userTypeKey,
  `email`,
  `${userFieldsKey}.title`,
  `given_name`,
  `family_name`,
  `nickname`,
  `mcnz`,
  `phone`,
];

export const userPages: UserPage[] = [
  {
    id: `details`,
    title: `Personal Details`,
    icon: <IconUser />,
    sections: [
      {
        questions: coreUserQuestions,
      },
      {
        title: `Contact Details`,
        questions: [
          // `phone`,
          `${userFieldsKey}.home_phone`,
          `${userFieldsKey}.work_phone`,
          `${userFieldsKey}.address`,
          `email`,
        ],
      },
      {
        title: `About You`,
        conditional: clinicalUsersConditional,
        questions: [
          `${userOfficeOnlyKey}.nz_citizen_permanent_resident`,
          `${userFieldsKey}.birthdate`,
          `${userFieldsKey}.gender`,
          `${userFieldsKey}.ethnicity`,
          `${userFieldsKey}.ethnicity_other`,
          `${userFieldsKey}.maori_whakapapa`,
          `${userFieldsKey}.interests`,
          `${userFieldsKey}.specialisations`,
        ],
      },
    ],
  },
  {
    id: `education-employment`,
    title: `Employment and Education`,
    conditional: clinicalUsersConditional,
    icon: <IconFacility />,
    sections: [
      {
        title: `Workplace`,
        questions: [`facilities`, `${userFieldsKey}.other_workplaces`],
      },
      {
        title: `Education`,
        questions: [
          `${userOfficeOnlyKey}.medical_qualification`,
          `${userOfficeOnlyKey}.other_qualifications`,
          // `${userFieldsKey}.supervisor`,
          `approved_to_supervise`,
          `supervisor_approval_date`,
          // `${userFieldsKey}.supervisor_status`,
        ],
      },
    ],
  },
  {
    id: `supervisor-general`,
    title: `Supervision`,
    conditional: generalUsersConditional,
    icon: <IconFacility />,
    sections: [
      {
        // title: `Supervision`,
        questions: [`approved_to_supervise`, `supervisor_approval_date`],
      },
    ],
  },
  {
    id: `membership`,
    title: `Membership`,
    icon: <IconNotebook />,
    conditional: clinicalUsersConditional,
    sections: [
      {
        questions: [
          `${userOfficeOnlyKey}.membership_status`,
          `${userOfficeOnlyKey}.fellowship_date`,
          `${userOfficeOnlyKey}.hwnz_date`,
          `${userOfficeOnlyKey}.application_date`,
          `${userOfficeOnlyKey}.training_programme_start_date`,
          // `${userOfficeOnlyKey}.pause_status`,
          `${userOfficeOnlyKey}.pause_periods`,
        ],
      },
    ],
  },
  {
    id: `cpd`,
    title: `CPD details`,
    icon: <IconSchool />,
    conditional: exceptRegistrarUsersConditional,
    sections: [
      {
        questions: [
          // `${userFieldsKey}.peer_group`,
          // `${userOfficeOnlyKey}.additional_oversight_required`,
          `${userOfficeOnlyKey}.additional_oversight_severity`,
          `${userOfficeOnlyKey}.oversight_notes`,
          `${userOfficeOnlyKey}.monitor_check_date`,
        ],
      },
    ],
  },
  {
    id: `cpd`,
    title: `TPS details`,
    icon: <IconSchool />,
    conditional: registrarConditional,
    sections: [
      {
        questions: [
          // `${userFieldsKey}.peer_group`,
          // `${userOfficeOnlyKey}.additional_oversight_required`,
          `${userOfficeOnlyKey}.additional_oversight_severity`,
          `${userOfficeOnlyKey}.oversight_notes`,
          `${userOfficeOnlyKey}.monitor_check_date`,
        ],
      },
    ],
  },
  // {
  //   id: `sub`,
  //   title: `Subscriptions`,
  //   conditional: memberUsersConditional,
  //   sections: [
  //     {
  //       questions: [
  //         `${userOfficeOnlyKey}.website_access`,
  //         `${userFieldsKey}.website_login_email`,
  //         `${userOfficeOnlyKey}.ecg_weekly_subscription`,
  //         `${userFieldsKey}.ecg_weekly_email`,
  //         `${userOfficeOnlyKey}.uc_course_access`,
  //         `${userFieldsKey}.uc_course_email`,
  //           `${userOfficeOnlyKey}.training_database`,
  //           `${userOfficeOnlyKey}.e-portfolio`,
  //           `${userOfficeOnlyKey}.website_newsletter`,
  //           `${userOfficeOnlyKey}.invoice_membership`,
  //       ],
  //     },
  //   ],
  // },
];

export const createUserSchema = getSchema(userCoreValidations);

export const userFormSchema = getSchema({
  ...userCoreValidations,
  ...userFieldsValidations,
  ...userOfficeOnlyValidations,
});
