import { IColumn } from "components/common/ui/table/utils";
import { IconEye, IconError } from "components/common/icons/twoToneIcons";
import React from "react";
import { coreService } from "services/CoreService";
import Table from "components/common/ui/table/Table";
import { SUPERVISORS_ROUTES } from "constants/routes/supervisors";
import { Supervisee } from "models/supervisors/Supervisee";
import styles from "./SuperviseesList.module.scss";
import { OversightLevelChip } from "components/users/common/detail/OversightLevelChip";
import Chip from "components/common/ui/chip/Chip";

interface Props extends DefaultProps {
  supervisees?: Supervisee[];
  isLoading?: boolean;
  supervisorId: string;
}

export const SuperviseesList = ({
  supervisees = [],
  isLoading,
  supervisorId,
}: Props) => {
  const columns: IColumn<Supervisee>[] = [
    {
      id: "status_icon",
      label: " ",
      width: "50px",
      type: "icon",
      cell: (c, row) =>
        row.supervision_relationship_status === "Pending" && (
          <IconError className={styles.yellowIcon} />
        ),
    },
    {
      id: "supervisee_name",
      label: "Name",
      type: "textEmphasis",
      cell: (c, row) => `${row.given_name}  ${row.family_name}  `,
    },
    {
      id: "supervisee_email",
      label: "Email",
      cell: (c, row) => `${row.email} `,
    },
    {
      id: "status",
      label: "Supervision Status",
      cell: (c, row) => (
        <Chip
          condensed
          theme={
            row.supervision_relationship_status === "Pending"
              ? "warning"
              : "primary"
          }
        >
          {row.supervision_relationship_status}
        </Chip>
      ),
    },
    {
      id: "oversight",
      label: " ",
      cell: (c, row) => (
        <OversightLevelChip oversight={row.additional_oversight_severity} />
      ),
    },
    {
      id: "link",
      label: " ",
      width: "50px",
      type: "icon",
      cell: (c, row) =>
        row.supervision_relationship_status === "Active" ? <IconEye /> : null,
    },
  ];

  return (
    <>
      <br />
      <Table
        columns={columns}
        data={supervisees || []}
        getRowProps={(row) =>
          row.supervision_relationship_status === "Active"
            ? {
                to: coreService.getRoute(SUPERVISORS_ROUTES.SUPERVISEE_DETAIL, {
                  supervisorId: supervisorId,
                  superviseeId: row.id,
                }),
              }
            : {}
        }
        loading={isLoading}
        placeholder={{ noDataLabel: "No Supervisee Found", loadingRows: 5 }}
      />
    </>
  );
};
