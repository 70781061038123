import React from "react";
import Meta from "components/common/Meta";
import AppContentContainer from "components/common/layouts/appLayout/AppContentContainer";
import { useSelector } from "react-redux";
import {
  isDimensionsLoadingSelector,
  peergroupDimSelector,
} from "store/selectors/dimensions.selectors";
import {
  currentUserSelector,
  isAppLoadedSelector,
} from "store/selectors/core.selectors";
import PageHeader from "components/common/layouts/appLayout/PageHeader";
import { SuperviseesList } from "components/supervisors/list/SuperviseesList";
import { useSuperviseesList } from "components/supervisors/hooks/useSuperviseesList";

const ViewMySupervisees = () => {
  const isAppLoaded = useSelector(isAppLoadedSelector);
  const currentUser = useSelector(currentUserSelector);
  const isDimLoading = useSelector(isDimensionsLoadingSelector);
  const dimensions = useSelector(peergroupDimSelector);
  const enabled = isAppLoaded && !isDimLoading;

  const { isLoading, data: supervisees } = useSuperviseesList(
    currentUser.id,
    dimensions,
    {
      enabled,
    }
  );

  return (
    <AppContentContainer spacing="none">
      <Meta title={`Supervisees`} />
      <PageHeader spacing="extra" spacingTopOnly header={"Supervisees"} />

      <SuperviseesList
        isLoading={!enabled || isLoading}
        supervisees={supervisees}
        supervisorId={currentUser.id}
      />
    </AppContentContainer>
  );
};

export default ViewMySupervisees;
