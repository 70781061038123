import {
  DIMENSION_MATCH,
  IDimension,
  IDimensions,
  TBucket,
} from "models/Dimension";

export const updateBuckets = (bucket: any, dimension: IDimension) => {
  //if dim should only be single text string return bucket
  if (
    dimension.match &&
    [
      DIMENSION_MATCH.EXACT,
      DIMENSION_MATCH.EXACT_ARRAY,
      DIMENSION_MATCH.FUZZY,
    ].includes(dimension.match)
  ) {
    return bucket;
  }
  //if null clear all filters
  if (bucket === null) {
    return [];
    //if bucket is already an array, then just replace
  } else if (Array.isArray(bucket)) {
    return bucket;
  } else {
    let filters =
      dimension.buckets && Array.isArray(dimension.buckets)
        ? [...dimension.buckets]
        : [];
    const index = filters.indexOf(bucket);
    //add if key doesn't exist in filter, or remove if it does
    if (index === -1) {
      filters.push(bucket);
    } else {
      filters.splice(index, 1);
    }
    return filters;
  }
};

export const extractFiltersFromDimensions = (dim: IDimensions) => {
  return Object.values(dim).reduce(
    (o, d) =>
      !checkEmptyBuckets(d.buckets) ? { ...o, [d.key]: d.buckets } : o,
    {}
  ) as Record<string, TBucket>;
};

export const checkEmptyBuckets = (buckets: TBucket) =>
  buckets === null ||
  (Array.isArray(buckets) && buckets.length === 0) ||
  (Array.isArray(buckets) && buckets.every((b) => b === null));
// (Array.isArray(buckets) && buckets.every((b) => b === undefined));
