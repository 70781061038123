import {
  ActivityReqReason,
  Category,
  Grade,
  Waiver,
  GradeResult,
  GradeLetterScale,
} from "models/cpd/common/common";
import { parseAttribute, removeUndefinedFromObject } from "utils/common";
import { convertToDate } from "utils/format/dateUtils";
import { PeerGroupMeeting } from "models/peergroup/meeting";
import { Supervisor } from "models/supervisors/Supervisor";

interface BaseActivity {
  id: string;
  plan_id?: string;
  goal_id?: string;
  peergroup_meeting_id?: string | null;
  peergroup_meeting_details?: PeerGroupMeeting;
  category?: Category;
  subcategory?: string;
  notes?: string;
  planned_at?: Date | null;
  completed_at?: Date | null;
  started_at?: Date | null;
  planned_started_at?: Date | null;
  supporting_docs?: string[];
  waiver?: Waiver;
  waiver_reason?: string;
}
interface OtherFields {
  locations?: [];
  other_locations?: [];
  year?: number;
  topic?: string;
  learnings?: string;
  benefits?: string;
  start_date?: string | Date | null;
  planned_started_at?: string | Date | null;
  subcategory_other?: string;
  hasAttachments?: boolean;
  letter_grade?: GradeLetterScale;
  grade_result?: Grade;
  supervisor?: Supervisor;
}
interface OfficeFields {
  locked?: boolean;

  is_required?: boolean;
  lock_subcategory?: boolean;
  required_credits?: number;
  required_reason?: ActivityReqReason;
  required_by?: string | Date | null;

  exclude_triennium?: boolean;
  exclude_annual?: boolean;
  audit_grade?: Grade;
  admin_grade?: Grade;
  admin_grade_result?: GradeResult;
  admin_grade_score?: number;
  admin_letter_grade?: GradeLetterScale;
  admin_comments?: string;
}

export interface Activity extends BaseActivity, OtherFields, OfficeFields {
  topic: string;
  year: number;
  credits: number;
  completed: boolean;
  planned_started_at?: Date | null;
  started_at?: Date | null;
  created_at?: Date | null;
  required_by?: Date | null;
  waive_req?: boolean;
  // peer_grp_activity_id?: string;
}

// type KeyMap<K=string> = {K:string[]}
type KeyMap<K extends string | number = string, V = string[]> = Record<K, V>;

export interface ActivityListShape {
  map: KeyMap<string, Activity>; //keyBy(activity, "id")
  keys: string[]; //array of id strings activity.map(a=>a.id)
  keyByYear: KeyMap; //{1:[array of id strings], 2:[array of id strings]}
  keyByCategory: KeyMap<Category>; //{1:[array of id strings], 2:[array of id strings]}
  keyByYearByGoal: KeyMap<string, KeyMap>; //{1:[array of id strings], 2:[array of id strings]}
  keyByYearByCategory: KeyMap<string, KeyMap<Category>>; //{1:[array of id strings], 2:[array of id strings]}
}

export interface IServerActivity
  extends Omit<BaseActivity, "completed_at" | "planned_at"> {
  fields_others?: OtherFields;
  fields_office_only?: OfficeFields;
  planned_at: string | Date | null;
  completed_at: string | Date | null;
  points: number;
  wave_req?: string;

  //TODO: SQL/API updates
  // - `year`, `topic` missing
  // - suggest adding a `fields` and `admin_fields` JSON attribute
  // - change `points` to allow decimals

  // API created attributes
  created_at: string;
  updated_at: string;
}

export const createActivity = (data: Partial<Activity> = {}): Activity => {
  const cleanedData: Partial<Activity> = removeUndefinedFromObject(data);
  return {
    id: "",
    topic: "",
    // category: Category.hours,
    year: 1,
    credits: 1,
    planned_at: null,
    completed_at: null,
    ...cleanedData,
    completed: !!data.completed_at,
  };
};

export const formatActivity = ({
  points,
  completed_at,
  planned_at,
  started_at,
  planned_started_at,
  fields_others: rawOthers = {},
  fields_office_only: rawOfficeOnly = {},
  wave_req,
  created_at,
  ...activity
}: IServerActivity): Activity => {
  const fields_others = parseAttribute(rawOthers);
  const fields_office_only = parseAttribute(rawOfficeOnly);

  return createActivity({
    planned_at: convertToDate(planned_at),
    completed_at: convertToDate(completed_at),
    // credits: points ? `${points}` : "",
    credits: points,
    waive_req: wave_req === "true",

    ...fields_others,
    ...fields_office_only,
    started_at: convertToDate(started_at || fields_others?.start_date),
    planned_started_at: convertToDate(
      planned_started_at || fields_others?.planned_started_at
    ),
    required_by: convertToDate(fields_office_only?.required_by),

    ...activity,
  });
};

export const toServerObject = ({
  topic,
  year,
  credits,
  completed,
  locations,
  other_locations,
  waive_req,
  subcategory_other,
  learnings,
  benefits,
  locked,
  hasAttachments,
  supporting_docs,
  audit_grade,
  letter_grade,
  grade_result,
  admin_grade,
  admin_grade_result,
  admin_grade_score,

  admin_letter_grade,
  admin_comments,
  required_by,
  exclude_triennium,
  exclude_annual,
  is_required,
  lock_subcategory,
  required_reason,
  required_credits,

  supervisor,
  peergroup_meeting_details,
  peergroup_meeting_id,
  //@ts-ignore
  admin_gradeScoreOutOf10,
  //@ts-ignore
  overdue_activity,
  created_at, //@ts-ignore
  updated_at,
  start_date,
  goal_id,
  ...rest
}: Partial<Activity>): Partial<IServerActivity> => {
  return {
    points: credits,
    wave_req: waive_req ? "true" : "false",
    goal_id: goal_id || undefined,
    peergroup_meeting_id:
      peergroup_meeting_id !== undefined
        ? peergroup_meeting_id?.length === 0
          ? null
          : peergroup_meeting_id
        : null,
    fields_others: {
      topic,
      year,
      letter_grade,
      grade_result,
      // documents,
      locations,
      other_locations,
      learnings,
      benefits,
      subcategory_other,
      hasAttachments,
      supervisor,
    },
    fields_office_only: {
      locked,
      audit_grade,
      admin_grade,
      admin_grade_result,
      admin_grade_score,
      admin_letter_grade,
      admin_comments,
      required_by,
      exclude_triennium,
      exclude_annual,
      is_required,
      lock_subcategory,
      required_reason,
      required_credits,
    },
    ...rest,
  };
};
