import React from "react";

interface Props {
  className?: string;
}

const Diver = ({ className }: Props) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 383 198"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M343.699 120.436C343.699 120.436 346.432 116.634 347.317 114.51C348.202 112.386 354.928 119.466 351.126 123.36C348.495 125.907 345.456 127.996 342.135 129.541"
      fill="#F9AA8F"
    />
    <path
      d="M364.791 132.854L293.871 61.9336C293.657 61.7195 293.403 61.5497 293.124 61.4338C292.844 61.3179 292.545 61.2583 292.242 61.2583C291.94 61.2583 291.64 61.3179 291.361 61.4338C291.081 61.5497 290.828 61.7195 290.614 61.9336V61.9336C290.4 62.1473 290.23 62.4011 290.114 62.6806C289.998 62.96 289.938 63.2595 289.938 63.562C289.938 63.8645 289.998 64.164 290.114 64.4434C290.23 64.7229 290.4 64.9767 290.614 65.1904L361.534 136.104C362.081 136.65 362.39 137.391 362.391 138.164V138.32C362.39 138.736 362.507 139.144 362.727 139.497C362.947 139.85 363.263 140.134 363.637 140.316L372.083 144.423C372.228 144.494 372.391 144.518 372.551 144.492C372.71 144.466 372.857 144.391 372.971 144.276C373.085 144.162 373.16 144.015 373.187 143.856C373.213 143.697 373.188 143.534 373.117 143.389L369.011 134.943C368.828 134.568 368.545 134.253 368.191 134.033C367.838 133.812 367.43 133.696 367.014 133.696H366.844C366.075 133.697 365.338 133.394 364.791 132.854V132.854Z"
      fill="url(#diver_24654_paint0_linear)"
    />
    <path
      d="M341.872 122.128C341.872 122.128 340.4 117.257 343.331 116.053C346.262 114.85 350.043 117.469 348.365 118.05C347.306 118.406 346.228 118.702 345.136 118.935C345.136 118.935 351.359 124.394 346.113 127.275C340.867 130.157 338.608 126.801 338.608 126.801"
      fill="#F9AA8F"
    />
    <path
      d="M244.289 182.095C244.289 182.095 244.466 182.428 248.792 178.973C252.127 176.304 255.872 182.569 254.69 184.552C253.507 186.534 244.31 187.802 238.222 183.405"
      fill="#F9AA8F"
    />
    <path
      d="M232.395 71.4277H191.317C184.869 71.4277 179.642 76.6548 179.642 83.1026C179.642 89.5505 184.869 94.7776 191.317 94.7776H232.395C238.843 94.7776 244.07 89.5505 244.07 83.1026C244.07 76.6548 238.843 71.4277 232.395 71.4277Z"
      fill="#B1D3EF"
    />
    <path
      d="M179.642 83.0959C179.651 86.1894 180.884 89.1535 183.071 91.341C185.259 93.5284 188.223 94.7615 191.317 94.7708H232.381C234.777 94.7719 237.114 94.0298 239.071 92.6468C238.045 92.5265 237.067 91.8609 237.485 89.5953C238.292 85.2411 232.352 84.9933 232.352 84.9933C232.352 84.9933 202.085 84.8163 189.872 84.9933C178.544 85.1562 180.343 79.2019 180.668 78.3169C179.989 79.8184 179.639 81.4479 179.642 83.0959Z"
      fill="url(#diver_24654_paint1_linear)"
    />
    <path
      d="M217.06 86.4303C217.06 86.4303 237.323 79.6547 294.671 116.832C326.665 137.569 341.873 122.135 341.873 122.135L346.121 127.282C346.121 127.282 318.042 146.398 283.42 129.88C251.497 114.658 238.434 124.74 238.434 124.74"
      fill="#20265D"
    />
    <path
      d="M203.289 117.356C203.289 117.356 113.557 148.989 113.557 43.7236L117.203 44.4316C117.203 44.4316 132.588 87.4072 169.29 85.4956L203.289 117.356Z"
      fill="#20265D"
    />
    <path
      d="M181.525 97.716L180.704 96.1371L169.284 85.4038C163.724 85.7184 158.157 84.9341 152.901 83.0958V83.0958C140.511 81.8143 153.021 90.2749 133.31 84.0232C113.6 77.7716 113.6 43.8584 113.6 43.8584C113.6 78.2035 123.228 97.9425 136.128 108.994L166.7 114.864L178.969 106.368L181.525 97.716Z"
      fill="url(#diver_24654_paint2_linear)"
    />
    <path
      d="M182.636 97.5528C182.636 97.5528 110.272 100.293 72.7336 58.2021L70.6875 62.2377C70.6875 62.2377 91.4107 127.501 177.079 124.351L182.636 97.5528Z"
      fill="#20265D"
    />
    <path
      d="M166.246 85.786C166.246 85.786 221.279 77.1626 259.412 106.318L257.054 120.974C257.054 120.974 186.197 127.346 156.985 123.806L166.246 85.786Z"
      fill="#20265D"
    />
    <path
      d="M203.168 85.5666C199.572 85.1702 196.088 84.9153 192.853 84.7666C189.731 90.551 182.941 106.771 192.74 124.726C196.28 124.641 199.89 124.528 203.473 124.393C200.938 118.581 193.688 99.2381 203.168 85.5666Z"
      fill="#384A9E"
    />
    <path
      d="M222.844 89.1279C219.672 88.3066 216.528 87.6269 213.441 87.0605C210.22 94.0485 205.908 108.301 214.05 123.926C217.725 123.742 221.314 123.544 224.755 123.338C222.164 117.115 216.953 101.617 222.844 89.1279Z"
      fill="#384A9E"
    />
    <path
      d="M117.43 45.0266C118.314 43.9556 119.081 42.7925 119.716 41.5574C119.892 41.1963 119.993 40.8038 120.013 40.4029C120.034 40.0021 119.974 39.6012 119.836 39.2241C119.699 38.847 119.487 38.5014 119.214 38.2077C118.94 37.914 118.61 37.6784 118.244 37.5147C112.396 34.8597 96.2391 29.4577 79.8702 41.1821C79.7214 41.2593 79.5893 41.365 79.4814 41.4933C79.3735 41.6215 79.2919 41.7697 79.2413 41.9295C79.1906 42.0892 79.172 42.2574 79.1863 42.4243C79.2007 42.5913 79.2478 42.7538 79.325 42.9026C79.4022 43.0513 79.5079 43.1834 79.6361 43.2914C79.7643 43.3993 79.9125 43.4809 80.0723 43.5315C80.2321 43.5821 80.4002 43.6008 80.5672 43.5864C80.7342 43.572 80.8967 43.5249 81.0454 43.4477C90.4123 40.106 106.385 36.0421 113.557 43.8867L117.43 45.0266Z"
      fill="#00A887"
    />
    <path
      d="M73.123 58.6554C72.2036 56.8688 70.9765 55.2583 69.498 53.8977C69.3848 53.8008 69.2519 53.7295 69.1085 53.6888C68.9652 53.648 68.8147 53.6388 68.6674 53.6617C68.5201 53.6845 68.3796 53.739 68.2553 53.8212C68.131 53.9035 68.026 54.0117 67.9475 54.1384C65.7173 57.7563 56.7328 70.3728 39.9461 69.7639C39.661 69.7485 39.3808 69.8434 39.1638 70.0289C38.9467 70.2145 38.8094 70.4764 38.7802 70.7605C38.7511 71.0445 38.8324 71.3289 39.0073 71.5546C39.1821 71.7804 39.4372 71.9301 39.7195 71.9729C48.697 73.573 60.0108 72.4402 70.7299 62.4786L73.123 58.6554Z"
      fill="#00A887"
    />
    <path
      d="M236.26 106.233C236.26 106.233 202.184 117.363 208.188 149.457C214.192 181.55 244.7 185.968 244.7 185.968V182.025C244.7 182.025 219.381 164.325 224.812 142.61C230.242 120.896 251.489 121.455 251.489 121.455L241.096 106.233H236.26Z"
      fill="#20265D"
    />
    <path
      d="M271.844 86.6357C271.214 86.5551 270.574 86.6099 269.967 86.7962C269.36 86.9826 268.8 87.2963 268.323 87.7164C267.847 88.1365 267.465 88.6533 267.205 89.2325C266.944 89.8116 266.809 90.4397 266.811 91.0748V97.8079C266.808 99.1846 266.297 100.512 265.376 101.535C264.455 102.558 263.188 103.205 261.819 103.352C260.803 103.463 259.782 103.52 258.761 103.521C252.389 103.521 252.488 116.435 260.623 116.973C260.934 116.973 261.239 117.016 261.55 117.065C264.651 117.582 282.153 119.529 284.617 116.011C286.174 113.78 286.217 113.016 286.174 110.12C286.166 109.55 286.373 108.997 286.753 108.572C287.134 108.148 287.66 107.881 288.228 107.826C289.693 107.684 291.406 107.175 291.435 105.575C291.668 91.2235 279.087 87.5419 271.844 86.6357Z"
      fill="#F9AA8F"
    />
    <path
      d="M290.741 106.941C290.146 107.125 289.042 107.288 288.78 106.665C288.518 106.042 289.212 104.279 288.914 103.479C288.747 102.858 288.747 102.203 288.914 101.582L273.098 95.1318L266.79 96.208V97.7868C266.787 99.1635 266.276 100.491 265.355 101.514C264.434 102.537 263.167 103.184 261.798 103.33C260.782 103.442 259.761 103.498 258.74 103.5C255.66 103.5 254.095 106.516 254.215 109.695C255.214 106.283 260.481 107.338 263.901 111.267C266.528 114.29 274.415 116.528 277.926 117.398C275.93 116.188 275.59 112.485 275.732 109.221C275.909 105.178 281.219 106.007 284.383 105.313C287.258 104.683 287.484 107.479 287.498 107.996C287.723 107.895 287.962 107.83 288.206 107.805C289.117 107.765 289.996 107.466 290.741 106.941V106.941Z"
      fill="url(#diver_24654_paint3_linear)"
    />
    <path
      d="M260.29 103.529C260.29 103.529 267.604 104.237 268.963 102.311C269.363 101.622 269.679 100.887 269.905 100.123C270.379 98.6648 274.209 98.8134 273.855 102.311C273.756 103.171 273.704 104.036 273.7 104.902C273.705 105.143 273.805 105.371 273.977 105.539C274.149 105.707 274.379 105.801 274.62 105.801C274.838 105.8 275.048 105.721 275.213 105.58C275.379 105.439 275.49 105.244 275.526 105.03C275.56 104.762 275.662 104.508 275.824 104.292C275.985 104.076 276.2 103.906 276.447 103.798C277.615 103.316 278.351 99.656 278.351 99.656L275.328 96.3071L266.775 97.1638C265.938 97.7867 265.219 98.5539 264.651 99.4294C263.745 100.945 260.29 103.529 260.29 103.529Z"
      fill="#F16C52"
    />
    <path
      d="M266.782 97.1569C266.782 97.1569 266.428 90.7849 265.72 87.6838C265.012 84.5828 285.403 83.7049 290.118 96.5763C290.125 96.548 276.17 101.143 266.782 97.1569Z"
      fill="#20265D"
    />
    <path
      d="M289.041 96.0523H280.156C279.083 96.0541 278.055 96.479 277.294 97.2346C276.533 97.9902 276.101 99.0155 276.092 100.088V100.088C276.092 101.168 276.521 102.203 277.284 102.966C278.048 103.73 279.083 104.159 280.163 104.159H283.88C284.856 104.159 285.793 103.772 286.485 103.083L287.002 102.566C287.676 101.891 288.59 101.509 289.544 101.504H290.719C290.86 101.504 290.999 101.474 291.128 101.416C291.257 101.358 291.371 101.273 291.464 101.167C291.558 101.061 291.627 100.937 291.668 100.802C291.709 100.667 291.721 100.525 291.703 100.385V100.329C291.521 98.9566 290.984 97.6558 290.146 96.5549V96.5549C290.013 96.3909 289.845 96.26 289.653 96.1727C289.461 96.0855 289.252 96.0442 289.041 96.0523V96.0523Z"
      fill="#00A887"
    />
    <path
      d="M286.061 101.298C286.813 100.548 287.831 100.125 288.893 100.123H291.668C291.527 99.2561 291.242 98.4187 290.826 97.645H281.395C281.041 97.6447 280.689 97.6923 280.347 97.7866L279.859 97.9211C279.423 98.0421 279.038 98.3029 278.764 98.6636C278.491 99.0243 278.343 99.4649 278.344 99.9177V100.265C278.344 100.603 278.428 100.936 278.587 101.235C278.746 101.534 278.976 101.789 279.257 101.978C280.135 102.573 281.17 102.891 282.231 102.891C283.668 102.889 285.046 102.316 286.061 101.298V101.298Z"
      fill="url(#diver_24654_paint4_linear)"
    />
    <path
      d="M282.217 108.931L281.672 109.476C281.315 109.833 281.031 110.257 280.838 110.723C280.645 111.19 280.546 111.69 280.546 112.194V114.425C280.546 115.288 280.889 116.117 281.5 116.728C282.111 117.338 282.939 117.681 283.803 117.681V117.681C284.579 117.688 285.333 117.417 285.927 116.917V116.917C286.65 116.31 287.232 115.552 287.629 114.695C288.027 113.839 288.231 112.905 288.228 111.961V107.805H284.971C284.46 107.8 283.953 107.897 283.481 108.09C283.008 108.284 282.578 108.569 282.217 108.931V108.931Z"
      fill="#00A887"
    />
    <path
      d="M282.217 108.931L281.672 109.476C281.315 109.833 281.031 110.257 280.838 110.723C280.645 111.19 280.546 111.69 280.546 112.194V114.425C280.546 115.288 280.889 116.117 281.5 116.728C282.111 117.338 282.939 117.681 283.803 117.681V117.681C284.579 117.688 285.333 117.417 285.927 116.917V116.917C286.65 116.31 287.232 115.552 287.629 114.695C288.027 113.839 288.231 112.905 288.228 111.961V107.805H284.971C284.46 107.8 283.953 107.897 283.481 108.09C283.008 108.284 282.578 108.569 282.217 108.931V108.931Z"
      fill="url(#diver_24654_paint5_linear)"
    />
    <path
      d="M285.297 110L284.921 110.375C284.674 110.623 284.478 110.916 284.345 111.239C284.211 111.563 284.142 111.909 284.143 112.259V113.802C284.143 114.399 284.38 114.972 284.802 115.394C285.224 115.816 285.797 116.053 286.394 116.053V116.053C286.912 116.047 287.412 115.862 287.81 115.529C288.309 115.112 288.71 114.589 288.985 114C289.26 113.41 289.403 112.768 289.403 112.117V110.701C289.403 110.305 289.246 109.925 288.965 109.645C288.685 109.364 288.305 109.207 287.909 109.207H287.152C286.453 109.218 285.787 109.503 285.297 110V110Z"
      fill="#00A887"
    />
    <path
      d="M285.297 110L284.921 110.375C284.674 110.623 284.478 110.916 284.345 111.239C284.211 111.563 284.142 111.909 284.143 112.259V113.802C284.143 114.399 284.38 114.972 284.802 115.394C285.224 115.816 285.797 116.053 286.394 116.053V116.053C286.912 116.047 287.412 115.862 287.81 115.529C288.309 115.112 288.71 114.589 288.985 114C289.26 113.41 289.403 112.768 289.403 112.117V110.701C289.403 110.305 289.246 109.925 288.965 109.645C288.685 109.364 288.305 109.207 287.909 109.207H287.152C286.453 109.218 285.787 109.503 285.297 110V110Z"
      fill="url(#diver_24654_paint6_linear)"
    />
    <path
      d="M216.11 67.98H175.032C168.584 67.98 163.357 73.207 163.357 79.6549C163.357 86.1028 168.584 91.3298 175.032 91.3298H216.11C222.558 91.3298 227.785 86.1028 227.785 79.6549C227.785 73.207 222.558 67.98 216.11 67.98Z"
      fill="#D7E8F9"
    />
    <path
      d="M163.357 79.655C163.367 82.7485 164.6 85.7126 166.787 87.9001C168.975 90.0875 171.939 91.3206 175.032 91.3299H216.096C218.493 91.331 220.83 90.5889 222.787 89.2059C221.76 89.0855 220.783 88.42 221.201 86.1544C222.008 81.8002 216.068 81.5524 216.068 81.5524C216.068 81.5524 185.801 81.3754 173.588 81.5524C162.26 81.7153 164.058 75.761 164.384 74.876C163.705 76.3775 163.355 78.007 163.357 79.655Z"
      fill="url(#diver_24654_paint7_linear)"
    />
    <path
      d="M227.12 84.5966C228.43 84.5966 229.492 82.831 229.492 80.653C229.492 78.4751 228.43 76.7095 227.12 76.7095C225.81 76.7095 224.748 78.4751 224.748 80.653C224.748 82.831 225.81 84.5966 227.12 84.5966Z"
      fill="#00A887"
    />
    <path
      d="M244.069 87.4643C245.379 87.4643 246.441 85.6987 246.441 83.5207C246.441 81.3428 245.379 79.5771 244.069 79.5771C242.759 79.5771 241.697 81.3428 241.697 83.5207C241.697 85.6987 242.759 87.4643 244.069 87.4643Z"
      fill="#00A887"
    />
    <path
      d="M227.785 80.6532C227.785 80.6532 251.206 79.5558 249.613 94.7778C248.02 110 248.02 130.624 275.101 136.592C302.182 142.561 299.449 115.274 287.774 112.747"
      stroke="#00A887"
      strokeWidth="4"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M379.892 3.66064C379.63 3.90756 379.283 4.04503 378.922 4.04503C378.562 4.04503 378.215 3.90756 377.952 3.66064C376.699 2.4375 375.21 1.4829 373.575 0.855252C371.94 0.227604 370.195 -0.0598882 368.445 0.0103634C366.695 0.080615 364.979 0.50713 363.399 1.26382C361.82 2.02051 360.412 3.09145 359.261 4.41112C359.093 4.60852 359 4.85956 359 5.11912C359 5.37868 359.093 5.62973 359.261 5.82712C360.412 7.14679 361.82 8.21774 363.399 8.97443C364.979 9.73112 366.695 10.1576 368.445 10.2279C370.195 10.2981 371.94 10.0106 373.575 9.383C375.21 8.75535 376.699 7.80075 377.952 6.5776C378.215 6.33069 378.562 6.19322 378.922 6.19322C379.283 6.19322 379.63 6.33069 379.892 6.5776C380.383 7.05749 380.911 7.49783 381.471 7.89448C381.593 7.98354 381.736 8.03717 381.886 8.04939C382.037 8.06162 382.187 8.03196 382.321 7.96372C382.456 7.89549 382.568 7.79135 382.647 7.66289C382.726 7.53442 382.767 7.38666 382.767 7.23605V2.98805C382.767 2.83743 382.726 2.68968 382.647 2.56121C382.568 2.43274 382.456 2.3286 382.321 2.26036C382.187 2.19213 382.037 2.16248 381.886 2.1747C381.736 2.18693 381.593 2.24054 381.471 2.3296C380.91 2.73078 380.382 3.17586 379.892 3.66064Z"
      fill="#CDDFEC"
    />
    <path
      d="M20.8923 190.661C20.6297 190.908 20.2828 191.045 19.9223 191.045C19.5618 191.045 19.215 190.908 18.9523 190.661C17.6993 189.438 16.2098 188.483 14.5751 187.855C12.9404 187.228 11.1948 186.94 9.44512 187.01C7.69546 187.081 5.97853 187.507 4.39939 188.264C2.82025 189.021 1.41212 190.091 0.261146 191.411C0.0926005 191.609 0 191.86 0 192.119C0 192.379 0.0926005 192.63 0.261146 192.827C1.41212 194.147 2.82025 195.218 4.39939 195.974C5.97853 196.731 7.69546 197.158 9.44512 197.228C11.1948 197.298 12.9404 197.011 14.5751 196.383C16.2098 195.755 17.6993 194.801 18.9523 193.578C19.215 193.331 19.5618 193.193 19.9223 193.193C20.2828 193.193 20.6297 193.331 20.8923 193.578C21.383 194.058 21.9109 194.498 22.4711 194.895C22.5926 194.984 22.7364 195.037 22.8865 195.049C23.0366 195.062 23.1872 195.032 23.3214 194.964C23.4557 194.896 23.5684 194.791 23.647 194.663C23.7256 194.534 23.7671 194.387 23.7668 194.236V189.988C23.7671 189.837 23.7256 189.69 23.647 189.561C23.5684 189.433 23.4557 189.329 23.3214 189.26C23.1872 189.192 23.0366 189.162 22.8865 189.175C22.7364 189.187 22.5926 189.241 22.4711 189.33C21.9104 189.731 21.3825 190.176 20.8923 190.661V190.661Z"
      fill="#CDDFEC"
    />
    <path
      d="M201.894 43.0865C202.069 43.2544 202.302 43.3481 202.545 43.3481C202.788 43.3481 203.021 43.2544 203.196 43.0865C204.037 42.2665 205.035 41.6265 206.131 41.2057C207.227 40.785 208.398 40.5922 209.571 40.6393C210.744 40.6863 211.895 40.9722 212.953 41.4794C214.012 41.9866 214.956 42.7045 215.728 43.5892C215.852 43.7205 215.92 43.8939 215.92 44.0741C215.92 44.2544 215.852 44.4278 215.728 44.5591C214.956 45.4438 214.012 46.1616 212.953 46.6689C211.895 47.1761 210.744 47.462 209.571 47.509C208.398 47.5561 207.227 47.3633 206.131 46.9425C205.035 46.5217 204.037 45.8818 203.196 45.0618C203.021 44.8939 202.788 44.8002 202.545 44.8002C202.302 44.8002 202.069 44.8939 201.894 45.0618C201.564 45.3844 201.209 45.6803 200.832 45.9468C200.751 46.0073 200.654 46.044 200.553 46.0527C200.452 46.0615 200.351 46.042 200.261 45.9964C200.17 45.9509 200.094 45.881 200.041 45.7947C199.988 45.7084 199.961 45.6091 199.961 45.5078V42.6758C199.961 42.5746 199.988 42.4753 200.041 42.389C200.094 42.3027 200.17 42.2328 200.261 42.1873C200.351 42.1417 200.452 42.1222 200.553 42.1309C200.654 42.1397 200.751 42.1764 200.832 42.2369C201.207 42.492 201.562 42.7761 201.894 43.0865Z"
      fill="#CDDFEC"
    />
    <path
      d="M323.933 174.454C324.108 174.622 324.341 174.716 324.584 174.716C324.827 174.716 325.06 174.622 325.236 174.454C326.076 173.634 327.074 172.994 328.17 172.573C329.266 172.153 330.437 171.96 331.61 172.007C332.783 172.054 333.934 172.34 334.992 172.847C336.051 173.354 336.995 174.072 337.767 174.957C337.891 175.088 337.959 175.262 337.959 175.442C337.959 175.622 337.891 175.795 337.767 175.927C336.995 176.811 336.051 177.529 334.992 178.037C333.934 178.544 332.783 178.83 331.61 178.877C330.437 178.924 329.266 178.731 328.17 178.31C327.074 177.889 326.076 177.249 325.236 176.429C325.06 176.262 324.827 176.168 324.584 176.168C324.341 176.168 324.108 176.262 323.933 176.429C323.603 176.752 323.248 177.048 322.871 177.314C322.79 177.375 322.693 177.412 322.592 177.42C322.492 177.429 322.39 177.41 322.3 177.364C322.209 177.319 322.133 177.249 322.081 177.162C322.028 177.076 322 176.977 322 176.876V174.044C322 173.942 322.028 173.843 322.081 173.757C322.133 173.67 322.209 173.6 322.3 173.555C322.39 173.509 322.492 173.49 322.592 173.499C322.693 173.507 322.79 173.544 322.871 173.605C323.246 173.86 323.601 174.144 323.933 174.454Z"
      fill="#CDDFEC"
    />
    <defs>
      <linearGradient
        id="diver_24654_paint0_linear"
        x1="328.979"
        y1="63.6825"
        x2="338.271"
        y2="206.843"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="0.84" stopColor="#CDDFEC" />
      </linearGradient>
      <linearGradient
        id="diver_24654_paint1_linear"
        x1="179.642"
        y1="86.5509"
        x2="239.085"
        y2="86.5509"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B0DFE2" />
        <stop offset="0.2" stopColor="#B3E0E2" />
        <stop offset="1" stopColor="#75C4D9" />
      </linearGradient>
      <linearGradient
        id="diver_24654_paint2_linear"
        x1="149.743"
        y1="71.1164"
        x2="107.263"
        y2="119.494"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1C1651" />
        <stop offset="1" stopColor="#130F38" />
      </linearGradient>
      <linearGradient
        id="diver_24654_paint3_linear"
        x1="273.31"
        y1="119.522"
        x2="270.216"
        y2="78.1044"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F9AA8F" />
        <stop offset="1" stopColor="#F78E6B" />
      </linearGradient>
      <linearGradient
        id="diver_24654_paint4_linear"
        x1="284.992"
        y1="104.548"
        x2="284.992"
        y2="90.7137"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00A887" />
        <stop offset="1" stopColor="#256351" />
      </linearGradient>
      <linearGradient
        id="diver_24654_paint5_linear"
        x1="278.422"
        y1="109.575"
        x2="288.419"
        y2="113.95"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00A887" />
        <stop offset="1" stopColor="#256351" />
      </linearGradient>
      <linearGradient
        id="diver_24654_paint6_linear"
        x1="276.227"
        y1="114.793"
        x2="295.754"
        y2="110.786"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00A887" />
        <stop offset="1" stopColor="#256351" />
      </linearGradient>
      <linearGradient
        id="diver_24654_paint7_linear"
        x1="163.357"
        y1="83.1029"
        x2="222.801"
        y2="83.1029"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D7E8F9" />
        <stop offset="1" stopColor="#B0DFE2" />
      </linearGradient>
    </defs>
  </svg>
);

export default Diver;
