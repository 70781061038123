import React from "react";
import { Activity } from "models/cpd/Activity";
import { formatDate } from "utils/format/dateUtils";
import HintBox from "components/common/ui/hint-box/HintBox";

interface Props {
  activity: Activity;
}

const DateBox = ({ activity }: Props) => (
  <HintBox theme={activity.completed ? "success" : "warning"}>
    <div>
      {activity.started_at ? (
        <div>
          <b>Started: &nbsp;</b>{" "}
          {activity?.started_at && formatDate(activity?.started_at)}
        </div>
      ) : (
        !activity.completed_at &&
        activity.planned_started_at && (
          <div>
            <b>Planned start: &nbsp;</b>{" "}
            <i>{formatDate(activity?.planned_started_at)}</i>
          </div>
        )
      )}

      {activity.completed_at ? (
        <div>
          <b>Completed: &nbsp;</b> {formatDate(activity?.completed_at)}
        </div>
      ) : (
        <div>
          <b>Planned Complete: &nbsp;</b>{" "}
          <i>{formatDate(activity?.planned_at)}</i>
        </div>
      )}
    </div>
  </HintBox>
);

export default DateBox;
