import React from "react";
import createIcon from "components/common/icons/common/createIcon";

const IconAdd = createIcon(
  <React.Fragment>
    <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
  </React.Fragment>,
  "Add"
);

export default IconAdd;
