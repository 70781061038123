import React, { memo, useState, useEffect } from "react";
import cx from "classnames";
import { motion } from "framer-motion";
import styles from "./Accordian.module.scss";
import IconExpandArrow from "components/common/icons/IconExpandArrow";

interface IAccordian {
  header?: any;
  children: any;
  height?: string;
  headerClassName?: string;
  className?: string;
  isOpen?: boolean;
  bottomBorder?: boolean;
  disabled?: boolean;
}

const Accordian = memo(
  ({
    children,
    header,
    height = "auto",
    headerClassName,
    className,
    bottomBorder = false,
    isOpen = false,
    disabled,
  }: IAccordian) => {
    const [expand, setExpand] = useState(isOpen);
    useEffect(() => {
      setExpand(isOpen);
    }, [isOpen]);

    return (
      <>
        {header && (
          <button
            className={cx(
              styles.header,
              bottomBorder && styles.bottomBorder,
              headerClassName
            )}
            onClick={() => !disabled && setExpand(!expand)}
            type="button"
          >
            <div className={styles.headerContent}>{header}</div>

            <IconExpandArrow
              className={cx(styles.expandArrow, expand && styles.expanded)}
            />
          </button>
        )}

        <motion.div
          className={cx(styles.content, className)}
          initial="hidden"
          variants={{
            visible: { opacity: 1, height: height },
            hidden: { opacity: 0, height: 0 },
          }}
          animate={expand ? "visible" : "hidden"}
          layout
          transition={{
            height: { type: "tween", duration: 0.3 },
            opacity: { duration: 0.15 },
          }}
          style={{ overflowY: "hidden" }}
          aria-hidden={!expand}
        >
          <div>{children}</div>
        </motion.div>
      </>
    );
  }
);

export default Accordian;
