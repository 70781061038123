import React, { Children, cloneElement, useCallback, useEffect } from "react";

type KeyboardType = (
  event: React.KeyboardEvent<HTMLElement>,
  ...rest: any[]
) => void;

interface IKeyboardCallbacks {
  onBackspace?: KeyboardType;
  onComma?: KeyboardType;
  onDown?: KeyboardType;
  onEnter?: KeyboardType;
  onEsc?: KeyboardType;
  onLeft?: KeyboardType;
  onRight?: KeyboardType;
  onShift?: KeyboardType;
  onSpace?: KeyboardType;
  onTab?: KeyboardType;
  onUp?: KeyboardType;
}

const KEYS = {
  8: "onBackspace",
  9: "onTab",
  13: "onEnter",
  27: "onEsc",
  32: "onSpace",
  37: "onLeft",
  38: "onUp",
  39: "onRight",
  40: "onDown",
  188: "onComma",
  16: "onShift",
} as {
  [key: number]: keyof IKeyboardCallbacks;
};

interface Props extends IKeyboardCallbacks {
  target?: "component" | "document";
  onKeyDown?: KeyboardType;
  preventDefaultKeys?: string[];
  children: React.ReactElement;
}

const Keyboard = ({
  target,
  children,
  onKeyDown,
  preventDefaultKeys,
  ...restProps
}: Props) => {
  const onKeyDownHandler = useCallback(
    (event, ...rest) => {
      const key: keyof typeof KEYS = event.keyCode
        ? event.keyCode
        : event.which;
      const callbackName = KEYS[key] as keyof IKeyboardCallbacks | undefined;

      if (callbackName && restProps[callbackName]) {
        preventDefaultKeys &&
          preventDefaultKeys?.includes(callbackName) &&
          event.preventDefault();
        //@ts-ignore
        restProps[callbackName](event, ...rest);
      }

      if (onKeyDown) {
        onKeyDown(event, ...rest);
      }
    },
    [onKeyDown, restProps, preventDefaultKeys]
  );

  useEffect(() => {
    if (target === "document") {
      document.addEventListener("keydown", onKeyDownHandler);
    }

    return () => {
      if (target === "document") {
        document.removeEventListener("keydown", onKeyDownHandler);
      }
    };
  }, [onKeyDownHandler, target]);

  return target === "document"
    ? children
    : cloneElement(Children.only(children), {
        onKeyDown: onKeyDownHandler,
      });
};

export { Keyboard };
